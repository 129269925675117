import React, { useContext } from 'react';
import './ChatShellComponent.css';
import '../../../custom.css';
import { ThemeContext } from "../../../Contexts/ThemeContext"
import "../../../darkcustom.css"

//eslint-disable-next-line
interface IChatShellComponent {
    children: any;
    customClass?: string;
}
const ChatShellComponent = (props: any) => {
    const { theme } = useContext(ThemeContext)
    return <>
        {theme === "light" ? (
            <div className={`background-FAFAFA chat-shell-config d-flex flex-column border-shadow-EAEAEA ${props.customClass}`}>
                {props.children}</div>
        ) : <div style={{ backgroundColor: "#243138" }} className={`chat-shell-config d-flex flex-column border-shadow-EAEAEA ${props.customClass}`}>
            {props.children}</div>}

    </>
}
export default ChatShellComponent;