import React from "react";
import { Offcanvas } from "react-bootstrap";
import { Headers } from "../../../Utils/Enums";
import HeaderComponent from "../Header/HeaderComponent";

function SignoutNotificationComponent(props: any) {
    return (
        <>
            <Offcanvas show={props.show} placement='end' backdropClassName="off-cnavas" onHide={props.onHide}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        MindNavigator
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="d-flex justify-content-center mt-3" style={{ borderBottom: "1px solid orange" }}>
                    <HeaderComponent
                        title={"Please login to view notification(s)!"}
                        size={Headers.H3}
                    />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default SignoutNotificationComponent;