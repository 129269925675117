import React, { useContext, useEffect, useState } from "react";
import { IAddUpdatePredefinedQuestionRequest, IPredefineSurveyQuestion, IProgramSurveyAddEditQuestion, IProgramSurveyDetailQuestion, IProgramSurveyQuestion } from "../../../interfaces/ProgramSurvey/IProgramSurveyQuestion";
import { useHistory } from "react-router";
import { isClientAdmin, isMNFacilitator } from '../../../Utils/Utils';
import Survey from "../../../Services/Survey.service";
import LoaderComponent from "../../FunctionalComponents/Loader/LoaderComponent";
import { Accordion, Button, Form, Modal, Spinner, Table } from "react-bootstrap";
import { TagsInput } from "react-tag-input-component";
import { createNotification } from "../../../Utils/Toast";
import { UserContext } from "../../../Contexts/UserContext";
import HeaderComponent from "../../FunctionalComponents/Header/HeaderComponent";
import { Headers } from "../../../Utils/Enums";
import { ThemeContext } from "../../../Contexts/ThemeContext"

const ProgramSurveyQuestionsComponent = (props: any) => {

    const surveyId = props?.location?.state?.surveyId;
    let history = useHistory();
    const survey = new Survey();
    const [surveyDetail, setSurveyDetail] = useState<IProgramSurveyDetailQuestion[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showAddQuestion, setShowAddQuestion] = useState<boolean>(false);
    const [showQuestionList, setShowQuestionList] = useState<boolean>(false);
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [question, setQuestion] = useState<IProgramSurveyQuestion>({
        id: 0,
        question: "",
        questionType: 1,
        options: []
    });
    const [predefinedQuestions, setPredefinedQuestions] = useState<IPredefineSurveyQuestion[]>([]);
    const [questionListSpinner, setQuestionListSpinner] = useState<boolean>(false);
    const [selectedQuestions, setSelectedQuestions] = useState<number[]>([]);
    const userContext = useContext(UserContext);
    const { theme } = useContext(ThemeContext);
    useEffect(() => {
        userContext.dispatch('');
        if (surveyId > 0) {
            getProgramSurveyQuestions();
        }
    }, []);

    const handleRedirection = () => {
        if (isMNFacilitator()) {
            history.push("/mnfacilitatordashboard");
        }
        else if (isClientAdmin()) {
            history.push("/clientadmindashboard");
        }
        else {
            history.push("/facilitordashboard");
        }
    }

    const getProgramSurveyQuestions = async () => {
        if (!isLoading) setIsLoading(true);
        let response = await survey.getProgramSurveyQuestions(surveyId);
        if (response) {
            if (response.status) {
                if (response.data) {
                    setSurveyDetail(response.data);
                }
                else {

                }
            }
            else {

            }
        }
        setIsLoading(false);
    }

    const handleAddQuestionClose = () => {
        setShowAddQuestion(false);
    }

    const handleQuestionListClose = () => {
        setShowQuestionList(false);
    }

    const handleAddQuestion = async () => {
        setShowSpinner(true);
        const obj: IProgramSurveyAddEditQuestion = {
            surveyId: surveyId,
            question: question
        }
        let response = await survey.addProgramSurveyQuestion(obj);
        if (response) {
            if (response.status && response.data > 0) {
                createNotification('success', response.message);
            }
            else {
                createNotification('error', response.message);
            }
        }
        else {
            createNotification('error', "Something went wrong !!");
        }
        setShowSpinner(false);
        handleCloseModal();
        await getProgramSurveyQuestions();
    }

    const handleUpdateQuestion = async () => {
        setShowSpinner(true);
        const obj: IProgramSurveyAddEditQuestion = {
            surveyId: surveyId,
            question: question
        }
        let response = await survey.updateProgramSurveyQuestion(obj);
        if (response) {
            if (response.status && response.data > 0) {
                createNotification('success', response.message);
            }
            else {
                createNotification('error', response.message);
            }
        }
        else {
            createNotification('error', "Something went wrong !!");
        }
        setShowSpinner(false);
        handleCloseModal();
        await getProgramSurveyQuestions();
    }

    const handleDeleteQuestion = async (id: number) => {
        setShowSpinner(true);
        let response = await survey.deleteProgramSurveyQuestion(id, surveyId);
        if (response) {
            if (response.status && response.data) {
                createNotification('success', response.message);
            }
            else {
                createNotification('error', response.message);
            }
        }
        else {
            createNotification('error', "Something went wrong !!");
        }
        setShowSpinner(false);
        await getProgramSurveyQuestions();
    }

    const showAddQuestionModal = () => {
        setQuestion({
            id: 0,
            question: "",
            questionType: 1,
            options: []
        });
        setShowAddQuestion(true);
    }

    const showEditQuestionModal = (obj: IProgramSurveyDetailQuestion) => {
        setQuestion({
            id: obj.id,
            question: obj.question,
            questionType: obj.questionType.id,
            options: obj.options
        });
        setShowAddQuestion(true);
    }

    const handleCloseModal = () => {
        setQuestion({
            id: 0,
            question: "",
            questionType: 1,
            options: []
        });
        setShowAddQuestion(false);
    }

    const showQuestionListModal = () => {
        setPredefinedQuestions([]);
        setShowQuestionList(true);
        getPredefinedQuestionList();
    }

    const getPredefinedQuestionList = async () => {
        setQuestionListSpinner(true);
        const response = await survey.getPredefinedQuestionList();
        if (response) {
            if (response.status) {
                if (response.data && response.data.length > 0) {
                    if (surveyDetail && surveyDetail.length > 0) {
                        let objs = response.data;
                        if (objs) {
                            let intersection = surveyDetail.filter((x: IProgramSurveyDetailQuestion) => {
                                let arr = objs.filter(obj => obj.id == x.questionId);
                                return !(arr.length === 0)
                            });
                            let ids = intersection.filter(x => x.questionId !== 0).map(x => x.questionId);
                            setSelectedQuestions(ids);
                        }
                    }
                    setPredefinedQuestions(response.data);
                }
            }
        }
        setQuestionListSpinner(false);
    }

    const updatePredefineQuestion = (e: any) => {
        let val = Number(e.target.value);
        let objs = selectedQuestions;
        if (objs) {
            let index = objs.findIndex(x => x == val);
            if (e.target.checked) {
                if (index === -1) {
                    objs.push(val);
                }
                else {
                    objs[index] = val;
                }
            }
            else {
                if (index > -1) {
                    objs.splice(index, 1);
                }
            }
            setSelectedQuestions(objs);
        }
    }

    const addRemovePredefinedQuestion = async () => {
        setShowSpinner(true);
        let obj: IAddUpdatePredefinedQuestionRequest = {
            questionIds: selectedQuestions,
            surveyId: surveyId
        }
        const response = await survey.addRemovePredefinedQuestion(obj);
        if (response) {
            if (response.status) {
                handleQuestionListClose();
                await getProgramSurveyQuestions();
            }
            else {
                createNotification("error", "Something went wrong !!");
            }
        }
        setShowSpinner(false);
    }

    return (
        <>
            <div className="d-flex flex-column">
                <div className="d-flex">
                    <div className='d-flex justify-content-between cursor-pointer' onClick={() => handleRedirection()}>
                        {theme === "light" ? (
                            <HeaderComponent
                                title={"Questions"}
                                size={Headers.H1}
                                icon={'chevron-left.svg'}
                            />
                        ) : <HeaderComponent
                                customClassName={"imageText"}
                                title={"Questions"}
                                size={Headers.H1}
                                icon={'left-chevron-dark.svg'}
                            />}
                    </div>
                    <hr className="horizontal-rule" />
                </div>
                <div className="container">
                    {isLoading ? <LoaderComponent /> :
                        <div>
                            <div className="row mt-3">
                                <div className="mb-3">
                                    <div className="row mb-3">
                                        <div className="col-8"><h4>Manage Questions</h4></div>
                                        {
                                            (surveyDetail && surveyDetail.length > 0) ?
                                                surveyDetail.every(x => !x.isSurveyLocked) &&
                                                <div className="col-4 d-flex flex-row justify-content-end">
                                                    <Button className="font-weight-600 text-color-182C34 mx-3" onClick={showQuestionListModal}>Select from list</Button>
                                                    <Button className="font-weight-600 text-color-182C34" onClick={showAddQuestionModal}>Add new question</Button>
                                                </div>
                                                : <div className="d-flex flex-column justify-content-center text-center">
                                                    <h5>No data found !!</h5>
                                                </div>

                                        }
                                    </div>
                                    <div className="d-flex flex-column justify-content-center">
                                        <Accordion>
                                            {
                                                surveyDetail?.map((data: IProgramSurveyDetailQuestion, index: number) => {
                                                    return <Accordion.Item key={index} eventKey={index + ""}>
                                                        <Accordion.Header >
                                                            {`${(index + 1)}. ${data.question}`}
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="d-flex">
                                                                <div className="d-flex justify-content-space-between">
                                                                    <p>
                                                                        <b>Type: </b>{`${data.questionType.name}`}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            {
                                                                data.options.length > 0 && data.options.map(x => {
                                                                    return <div className="d-flex">
                                                                        <div className="d-flex justify-content-space-between">
                                                                            <p>{`${x}`}</p>
                                                                        </div>
                                                                    </div>
                                                                })
                                                            }
                                                            {
                                                                data.isSurveyLocked ? <div className="d-flex justify-content-end md-6"><em><b>Survey is locked</b></em></div>
                                                                    :
                                                                    !data.isRequired ?
                                                                        <div className="d-flex justify-content-end md-6">
                                                                            <div className="d-flex justify-content-space-between">
                                                                                {
                                                                                    (!data.isRequired && !data.isPredefine) &&
                                                                                    <Button variant="primary" className="font-weight-600 text-color-182C34 mx-2" type="button" onClick={() => showEditQuestionModal(data)}>Edit</Button>
                                                                                }
                                                                                {
                                                                                    showSpinner ? <Button variant="secondary" className="text-secondary" disabled>
                                                                                        <span className="mx-2 text-secondary">
                                                                                            <Spinner
                                                                                                as="span"
                                                                                                animation="border"
                                                                                                size="sm"
                                                                                                role="status"
                                                                                                aria-hidden="true"
                                                                                            />
                                                                                        </span>
                                                                                        Deleting... </Button> :
                                                                                        <Button variant="secondary" className="font-weight-600 text-color-182C34" type="button" onClick={() => handleDeleteQuestion(data.id)}>Delete</Button>
                                                                                }
                                                                            </div>
                                                                        </div> :
                                                                        <div className="d-flex justify-content-end md-6"><em><b>System generated. Can't be deleted or edited</b></em></div>
                                                            }
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                })
                                            }
                                        </Accordion>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {
                showAddQuestion && <Modal
                    show={showAddQuestion}
                    onHide={handleAddQuestionClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title className="header-text">{question.id > 0 ? "Edit " : "Add "}Question</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Question:</Form.Label>
                                <Form.Control value={question.question} type="text" placeholder="Enter question" onChange={(e) => { setQuestion((pre: IProgramSurveyQuestion) => ({ ...pre, question: e.target.value })) }} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Select Type</Form.Label>
                                <Form.Select value={question.questionType} onChange={(e) => {
                                    Number(e.target.value) === 1 ?
                                        setQuestion((pre: IProgramSurveyQuestion) => ({ ...pre, questionType: Number(e.target.value), options: [] })) :
                                        setQuestion((pre: IProgramSurveyQuestion) => ({ ...pre, questionType: Number(e.target.value) }))
                                }}>
                                    <option value={1}>Free Text</option>
                                    <option value={2}>Radio</option>
                                    <option value={3}>Multi Select</option>
                                </Form.Select>
                            </Form.Group>
                            {
                                (question.questionType === 2 || question.questionType === 3) && <Form.Group className="mb-3">
                                    <Form.Label>Options</Form.Label>
                                    <div>
                                        <TagsInput
                                            value={question.options}
                                            onChange={(val) => setQuestion((pre: IProgramSurveyQuestion) => ({ ...pre, options: val }))}
                                            name="options"
                                            placeHolder="Enter option"
                                        />
                                        <em>Press enter to add new option. if already added then it won't work</em>
                                    </div>
                                </Form.Group>
                            }
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            showSpinner ? <Button variant="primary" className="text-secondary" disabled>
                                <span className="mx-2 text-secondary">
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </span>
                                Saving... </Button> :
                                <Button role="button" variant="primary" className="font-weight-600 text-color-182C34"
                                    onClick={question.id > 0 ? handleUpdateQuestion : handleAddQuestion}
                                    disabled={(question.questionType == 2 || question.questionType == 3) && question.options.length <= 0}>
                                    Save
                                 </Button>
                        }
                        <Button role="button" variant="secondary" className="font-weight-600 text-color-182C34"
                            onClick={handleCloseModal}>
                            Close
                    </Button>
                    </Modal.Footer>
                </Modal>
            }
            {
                showQuestionList && <Modal
                    show={showQuestionList}
                    onHide={handleQuestionListClose}
                    size="lg"
                    scrollable={true}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header>
                        <Modal.Title className="header-text">Select from list</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            questionListSpinner && <LoaderComponent />
                        }
                        {
                            predefinedQuestions.length > 0 && predefinedQuestions.map((x, i) => {
                                return <Form.Group className="mb-3">
                                    <div className="row">
                                        <b><Form.Check value={x.id} inline
                                            onChange={(e) => updatePredefineQuestion(e)}
                                            className="question"
                                            type={"checkbox"}
                                            defaultChecked={selectedQuestions.includes(x.id)}
                                            id={x.id.toString()} label={x.question}></Form.Check></b>
                                    </div>
                                    <div className="row">
                                        <p><b>Type: </b>{`${x.questionType}`}</p>
                                        {
                                            x.options.length > 0 && x.options.map(x => {
                                                return <p>{x}</p>
                                            })
                                        }
                                    </div>
                                    <hr className="horizontal-rule" />
                                </Form.Group>
                            })
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {showSpinner ? <Button variant="primary" className="text-secondary" disabled>
                            <span className="mx-2 text-secondary">
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </span>
                            Adding... </Button> :
                            <Button role="button" variant="primary" onClick={addRemovePredefinedQuestion} className="font-weight-600 text-color-182C34">
                                Add Questions
                        </Button>
                        }
                        <Button role="button" variant="secondary" className="font-weight-600 text-color-182C34"
                            onClick={handleQuestionListClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    )
}

export default ProgramSurveyQuestionsComponent