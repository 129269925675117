import React, { useContext, useEffect, useReducer, useState } from 'react';
import HeaderComponent from '../../FunctionalComponents/Header/HeaderComponent';
import "./ModuleComponent.css";
import { Headers, Errors } from '../../../Utils/Enums';
import { useHistory } from 'react-router';
import ContentShellComponent from '../../FunctionalComponents/ContentShell/ContentShellComponent';
import TextComponent from '../../FunctionalComponents/Text/TextComponent';
import { Link } from 'react-router-dom';
import Module from '../../../Services/Module.service';
import LoaderComponent from '../../FunctionalComponents/Loader/LoaderComponent';
import ConceptItemComponent, { IConceptItem } from '../../FunctionalComponents/ConceptItem/ConceptItemComponent';
import SubmitButtonComponent from '../../FunctionalComponents/SubmitButton/SubmitButtonComponent';
import TEDModalComponent from '../../FunctionalComponents/TEDModal/TEDModalComponent';
import General from '../../../Services/General.service';
import { isDesktop, isMobileOnly, isTablet } from 'react-device-detect';
import { logOutIfTokenExpire } from '../../../Utils/Utils';
import { UserContext } from '../../../Contexts/UserContext';
import { ThemeContext } from '../../../Contexts/ThemeContext';

const okReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'SetOKSuccess':
            return action.okSuccsess;
        case 'SetOKFailure':
            return action.okFailure;
        default:
            return { value: "loading", dataModule: null }
    }
}
const ModuleComponent = (props: any) => {
    const generalService = new General();
    let history = useHistory();
    sessionStorage.removeItem("listOfConcepts");
    const moduleStepId = props.match.params.moduleStepId;
    const moduleId = props.match.params.moduleId;
    const module = new Module();
    const [okState, dispatchOK] = useReducer(okReducer, { value: "loading", dataModule: null });
    const [link, setLink] = useState('');
    const userContext = useContext(UserContext);
    const { theme } = useContext(ThemeContext);

    useEffect(() => {
        logOutIfTokenExpire();
        userContext.dispatch('');
        const getData = async () => {
            let result: any = await module.getModule(moduleId, moduleStepId);
            let inProgress = result.data.userModuleStepConceptWithStatuses.filter((x: any) => x.status === 'InProgress');
            if (inProgress.length > 1) {
                let maxInProgress = inProgress.reduce((a: any, b: any) => a.value > b.value ? a : b);
                setLink(`${"/concept/" + maxInProgress.id + "/" + moduleStepId + "/" + moduleId}`);
            } else if (inProgress.length === 1) {
                setLink(`${"/concept/" + inProgress[0].id + "/" + moduleStepId + "/" + moduleId}`);
            } else if (inProgress.length === 0) {
                let notStarted = result.data.userModuleStepConceptWithStatuses.filter((x: any) => x.status === 'NotStarted');
                if (notStarted.length > 0) {
                    let minNotStarted = notStarted.reduce((a: any, b: any) => a.value < b.value ? b : a);
                    setLink(`${"/concept/" + minNotStarted.id + "/" + moduleStepId + "/" + moduleId}`);
                } else {
                    setLink("/conceptsummary/" + moduleId);
                }
            }


            if (!result) return dispatchOK({ type: 'SetOKFailure', okFailure: { value: 'failed' } });
            dispatchOK({ type: 'SetOKSuccess', okSuccsess: { value: 'success', data: result } });
            let completedConcepts = result?.data?.userModuleStepConceptWithStatuses?.filter((x: IConceptItem) => x.userConceptStatusId > 0)
            sessionStorage.setItem("listOfConcepts", JSON.stringify(completedConcepts));
            let moduleStepDescription: any = document.getElementById("moduleStepDescription");
            // const moduleStepDescription = document.getElementById("moduleStepDescription") as HTMLElement;
            if (moduleStepDescription) moduleStepDescription.innerHTML = result.data.moduleOverView;
            generalService.getIcons();
        }
        getData();
        //eslint-disable-next-line
    }, []);

    // This method is abendoned for now
    // const [isQuoteSelected, setIsQuoteSelected] = useState<boolean>(false);
    // const saveModuleQuote = async (id: number, userResponse: string, messageType: string) => {
    //     let response: any = await module.saveUserModuleQuote(id, userResponse);
    //     if (response.status) {
    //         setIsQuoteSelected(true);
    //         createNotification("success", response.message);
    //     } else {
    //         createNotification("success", response.message || "Something went wrong!");
    //     }
    // }

    return <>
        {okState.value === 'loading' && <LoaderComponent />}
        {okState.value === 'failed' && <div>
            <div className="mb-4">
                <div className="alert alert-warning" role="alert">
                    <TextComponent text={Errors.SomethingWentWrong} />
                </div>
            </div>
        </div>}
        {okState.value === 'success' && <div>
            <div className='d-flex'>
                <span onClick={() => history.push("/dashboard")} className="text-decoration-none cursor-pointer">
                    {theme === "light" ? (
                        <HeaderComponent
                            title={okState.data.data.moduleHeading}
                            size={Headers.H1}
                            icon={'chevron-left.svg'}
                        />
                    ) : <HeaderComponent
                            customClassName={"imageText"}
                            title={okState.data.data.moduleHeading}
                            size={Headers.H1}
                            icon={'left-chevron-dark.svg'}
                        />}
                </span>
            </div>
            <div className="margin-bottom-78">
                <ContentShellComponent>
                    <div className={`d-xl-inline-flex justify-content-between module-out-config`}>
                        <ContentShellComponent customClass={'leftSideConfig2'}>
                            <div className="margin-bottom-32">
                                <TextComponent text={okState.data.data.shortDescription} />
                            </div>
                            <div className="margin-bottom-24 border-bottom-EBEBEB-thin">
                                <HeaderComponent
                                    title={"MODULE OVERVIEW"}
                                    size={Headers.H3}
                                />
                            </div>
                            <div id="moduleStepDescription"></div>
                            {/* <ChatShellComponent customClass={"additionaslResourcesCustomConfig"}>
                                <div className={`h-100 position-relative chat-screen-config`}>
                                    <div className={`position-absolute chatmessageShellConfig`}>
                                        {okState.data.data.userModuleQuoteModels.map((data: any, index: number) => {
                                            return <ChatMessageShellComponent
                                                key={index}
                                                id={data.id}
                                                icon={"Ellipse 306.svg"}
                                                text={data.quoteText}
                                                badge={isQuoteSelected ? 'favourite-filled.svg' : 'favourite-empty.svg'}
                                                onTedShellHeartResponseHandler={saveModuleQuote}
                                            />
                                        })}

                                    </div>
                                </div>
                            </ChatShellComponent> */}
                        </ContentShellComponent>
                        <ContentShellComponent customClass={'rightSideConfig position-relative'}>
                            <div className={`${isTablet && 'margin-bottom-24'} ${isMobileOnly && 'margin-bottom-40'} ${isDesktop && 'margin-bottom-48'}`}>
                                <HeaderComponent
                                    title={"MODULE CONCEPTS"}
                                    size={Headers.H3}
                                />
                                {okState?.data?.data?.userModuleStepConceptWithStatuses?.map((item: IConceptItem, index: string) =>
                                    <div key={index} >
                                        <ConceptItemComponent
                                            index={index}
                                            item={item}
                                            customClass={"background-E0E7EB text-color-182C34 font-size-14 line-height-20"}
                                            moduleStepId={props.match.params.moduleStepId}
                                            moduleId={props.match.params.moduleId}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={`${isDesktop && 'position-absolute'} bottom-0 w-100`}>
                                <Link
                                    to={link}>
                                    <SubmitButtonComponent
                                        label={"Up Next"}
                                        customClassName={'btn-primary-custom'}
                                        id={'thinkIntroSubmit'}
                                    />
                                </Link>
                            </div>
                        </ContentShellComponent>
                    </div>
                </ContentShellComponent>
            </div>
        </div>}
        <div>
            <TEDModalComponent />
        </div>
    </>
}
export default ModuleComponent;