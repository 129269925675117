import { CLIENT_ADMIN, CLIENT_ADMIN_LABEL, MNFACILITATOR } from "./Roles";

export function hasLowerCase(str: string) {
  return /[a-z]/.test(str);
}
export function hasUpperCase(str: string) {
  return /[A-Z]/.test(str);
}
export function hasNumber(str: string) {
  return /\d/.test(str);
}
export function specialRgx(str: string) {
  return /[^A-Za-z0-9]/.test(str);
}

export function clearLocalStorage() {
  localStorage.clear();
}

export function clearLocalStorageAndRedirect() {
  localStorage.clear();
  window.location.href = "/login";
}

export function getUserId() {
  let userDetailsLocalStorage = localStorage.getItem("userConfig");
  if (userDetailsLocalStorage) {
    let parsedUserDetails = JSON.parse(userDetailsLocalStorage);
    if (parsedUserDetails) {
      var token = parsedUserDetails?.token;
      const decode = JSON.parse(atob(token.split(".")[1]));
      if (decode.exp * 1000 < new Date().getTime()) {
        clearLocalStorage();
        window.location.href = "/login";
      } else {
        return decode.Id;
      }
    } else {
      clearLocalStorage();
      window.location.href = "/login";
    }
  } else {
    clearLocalStorage();
    window.location.href = "/login";
  }
}

export function logOutIfTokenExpire() {
  let userDetailsLocalStorage = localStorage.getItem("userConfig");
  if (userDetailsLocalStorage) {
    let parsedUserDetails = JSON.parse(userDetailsLocalStorage);
    if (parsedUserDetails) {
      var token = parsedUserDetails?.token;
      const decode = JSON.parse(atob(token.split(".")[1]));
      if (decode.exp * 1000 < new Date().getTime()) {
        clearLocalStorage();
        window.location.href = "/login";
      }
    } else {
      clearLocalStorage();
      window.location.href = "/login";
    }
  } else {
    clearLocalStorage();
    window.location.href = "/login";
  }
}
export function isTokenExpired() {
  let userDetailsLocalStorage = localStorage.getItem("userConfig");
  if (userDetailsLocalStorage) {
    let parsedUserDetails = JSON.parse(userDetailsLocalStorage);
    if (parsedUserDetails) {
      var token = parsedUserDetails?.token;
      const decode = JSON.parse(atob(token.split(".")[1]));
      if (decode.exp * 1000 < new Date().getTime()) {
        return true;
      } else {
        return false;
      }
    } else {
      clearLocalStorage();
      window.location.href = "/login";
    }
  } else {
    clearLocalStorage();
    window.location.href = "/login";
  }
}

export function isUserLoggedIn(): boolean {
  if (localStorage.getItem("API_Token")) {
    return true;
  } else {
    return false;
  }
}

export function getUserRoles(): string | undefined {
  let userDetailsLocalStorage = localStorage.getItem("userConfig");
  if (userDetailsLocalStorage) {
    let parsedUserDetails = JSON.parse(userDetailsLocalStorage);
    if (parsedUserDetails) {
      var token = parsedUserDetails?.token;
      const decode = JSON.parse(atob(token.split(".")[1]));
      return decode.role;
    } else {
      clearLocalStorage();
      window.location.href = "/login";
    }
  } else {
    clearLocalStorage();
    window.location.href = "/login";
  }
}

export function isMNFacilitator(): boolean {
  if (isTokenExpired() === false) {
    const roles = getUserRoles();
    if (roles !== undefined) {
      let usrRoles = roles.split(",");
      if (usrRoles.length == 1 && usrRoles[0] === MNFACILITATOR) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function isClientAdmin(): boolean {
  if (isTokenExpired() === false) {
    const roles = getUserRoles();
    if (roles) {
      if (roles.split(",").includes(CLIENT_ADMIN_LABEL)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function getAuthToken(): string {
  return "Bearer " + localStorage.getItem("API_Token");
}

export function getUserOrgnization() {
  if (isTokenExpired() === false) {
    let userDetailsLocalStorage = localStorage.getItem("userConfig");
    if (userDetailsLocalStorage) {
      let parsedUserDetails = JSON.parse(userDetailsLocalStorage);
      if (parsedUserDetails) {
        var token = parsedUserDetails?.token;
        const decode = JSON.parse(atob(token.split(".")[1]));
        return decode.OrganisationName;
      } else {
        clearLocalStorage();
        window.location.href = "/login";
      }
    } else {
      clearLocalStorage();
      window.location.href = "/login";
    }
  }
}

export const getQuerySringPreparedUrl = (
  url: string,
  keyValueObj: any = null
) => {
  let urlStr = new URL(url);
  if (keyValueObj) {
    Object.entries(keyValueObj).forEach(([key, value]) => {
      if (keyValueObj[key]) {
        let str = keyValueObj[key].toString();
        if (str && str !== "") {
          urlStr.searchParams.append(key, str);
        }
      }
    });
  }
  return urlStr.toString();
};

export const getRequestOptions = (method: string, body: any = null) => {
  if (method.toUpperCase() === "GET") {
    return {
      method: "GET",
      headers: {
        accept: "text/plain",
        "Content-Type": "application/json",
        Authorization: getAuthToken(),
      },
    };
  } else if (method.toUpperCase() === "POST") {
    return {
      method: "POST",
      body: body !== null ? JSON.stringify(body) : "",
      headers: {
        accept: "text/plain",
        "Content-Type": "application/json",
        Authorization: getAuthToken(),
      },
    };
  } else if (method.toUpperCase() === "PUT") {
    return {
      method: "PUT",
      body: body !== null ? JSON.stringify(body) : "",
      headers: {
        accept: "text/plain",
        "Content-Type": "application/json",
        Authorization: getAuthToken(),
      },
    };
  } else if (method.toUpperCase() === "DELETE") {
    return {
      method: "DELETE",
      headers: {
        accept: "text/plain",
        "Content-Type": "application/json",
        Authorization: getAuthToken(),
      },
    };
  }
};

export function getParagraphs(htmlString: any) {
  const div = document.createElement("div");
  div.insertAdjacentHTML("beforeend", htmlString);

  return Array.from(div.querySelectorAll("p"))
    .filter((p) => p.textContent !== "") // because of the lonely </p> at the end - optional
    .map((p) => p.outerHTML);
}
