import React, { useState } from 'react';
import PasswordInputComponent, { IPasswordData } from '../FunctionalComponents/PasswordInput/PasswordInputComponent';
import SubmitButtonComponent from "../FunctionalComponents/SubmitButton/SubmitButtonComponent";
import RegisterUser from "../../Services/RegisterUser.service";
import ErrorInputComponent from '../FunctionalComponents/ErrorInput/ErrorInputComponent';
import TextInputFieldComponent, { IInputFieldData } from '../FunctionalComponents/TextInputField/TextInputFieldComponent';
import EmailInputComponent from "../FunctionalComponents/EmailInput/EmailInputComponent";
import { IEmailData } from "../FunctionalComponents/EmailInput/EmailInputComponent";


const queryString = require('query-string');
const UserRegistrationComponent = () => {
    const [ok, setOK] = useState(false);
    const parsed = queryString.parse(window.location.search);
    const [errorForm, setErrorForm] = useState('');
    let isFormValid: boolean = false;
    const registerUserService = new RegisterUser();
    let submitted: boolean = false;
    let password: string = "";
    let confirmPassword: string = "";
    let emailAddress: string;
    let firstName: string = "";
    let lastName: string = "";

    const SubmitFormHandler = (event: any) => {
        event.preventDefault();
        if (password !== confirmPassword) {
            setErrorForm("Passwords don't match");
            isFormValid = false;
            return;
        }
        if (isFormValid) {
            setErrorForm('');
            let data = {
                firstName: firstName,
                lastName: lastName,
                email: emailAddress,
                password: password,
                confirmPassword: confirmPassword,
                code: parsed.code
            }
            if (!parsed.code) {
                setErrorForm("No valid code");
                return;
            }
            //eslint-disable-next-line
            const getData = async () => {
                let result = await registerUserService.registerUser(data);
                setOK(result.ok);
                submitted = true;
            };
            getData();
        } else {
            setErrorForm("Invalid form");
        }
    }

    const SetPasswordHandler = (data: IPasswordData): void => {
        if (data.isStrongPasswordState) {
            isFormValid = true;
            password = String(data.value);
        } else {
            isFormValid = false;
        }
    };

    const setFirstNameHandler = (data: IInputFieldData): void => {
        if (data.isEmptyState) {
            isFormValid = false;
        } else {
            isFormValid = true;
            firstName = String(data.value);
        }
    };

    const setLastNameHandler = (data: IInputFieldData): void => {
        if (data.isEmptyState) {
            isFormValid = false;
        } else {
            isFormValid = true;
            lastName = String(data.value);
        }
    };

    const SetConfirmPasswordHandler = (data: IPasswordData): void => {
        if (data.isStrongPasswordState) {
            isFormValid = true;
            confirmPassword = String(data.value);
        } else {
            isFormValid = false;
        }
    };

    const SetEmailHandler = (data: IEmailData): void => {
        if (data.isEmailState) {
            isFormValid = true;
            emailAddress = String(data.value);
        } else {
            isFormValid = false;
        }
    };
    return <>
        <div className="row justify-content-center">
            <div className="">
                <div className="main-title mb-4">User Registration Component</div>
                <div className="mb-4">
                    {errorForm && <ErrorInputComponent error={errorForm} />}
                    {ok && <div className="text-success">Please, confirm your email before login</div>}
                </div>
                <div className="mb-5">
                    <ul>
                        <li>Use at least 8 characters.</li>
                        <li>Use a mix of letters (uppercase and lowercase), numbers and symbols.</li>
                        <li>Don't use your name, username or account name.</li>
                        <li>Avoid predictable passwords such as "password", "12345" or "talkdesk"</li>
                    </ul>
                </div>
                <section>
                    <form id="account" method="get" onSubmit={SubmitFormHandler} autoComplete="off">
                        <TextInputFieldComponent placeholder={'First name'}
                            label={'First name'}
                            onValidationHandler={setFirstNameHandler} />
                        <TextInputFieldComponent placeholder={'Last name'}
                            label={'Last name'}
                            onValidationHandler={setLastNameHandler} />
                        <EmailInputComponent
                            label={'Email'}
                            onValidationHandler={SetEmailHandler}
                            error={submitted} />
                        <PasswordInputComponent
                            placeholder={'New password'}
                            label={'New password'}
                            onValidationHandler={SetPasswordHandler} />
                        <PasswordInputComponent
                            placeholder={'Confirm password'}
                            label={'Confirm password'}
                            onValidationHandler={SetConfirmPasswordHandler} />
                        <div className="form-group mb-3 mt-3">
                            <SubmitButtonComponent label={'Send'} customClassName={'btn-primary-custom'} />
                        </div>
                    </form>
                </section>
            </div>
        </div>
    </>
};

export default UserRegistrationComponent;