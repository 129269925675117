import React from 'react';
import './LoaderComponent.css';

const LoaderComponent = () => {
    return <>
    <div className="w-100 d-flex justify-content-center">
    <div className="spinner-border loader" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    </div>
    </>
}

export default LoaderComponent;