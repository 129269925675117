import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import HeaderComponent from '../Header/HeaderComponent';
import TextComponent from '../Text/TextComponent';
import { Headers } from '../../../Utils/Enums';
import TedJournal from '../../../Services/TedModule.service';
import ErrorInputComponent from '../ErrorInput/ErrorInputComponent';
import { ItedLogBookData } from '../TEDJournalComponents/TEDJournalEntryTabelComponent';
import "./PreviewComponent.css";
import LoaderComponent from '../Loader/LoaderComponent';
import Concept from '../../../Services/Concept.service';
import { ThemeContext } from '../../../Contexts/ThemeContext';

interface ILogbookModalComponent {
    row: ItedLogBookData;
}

const PreviewComponent = (props: ILogbookModalComponent) => {
    const { theme } = useContext(ThemeContext)
    const conceptService = new Concept();
    const tedJournal = new TedJournal();
    const [modal, setModal] = useState(false);
    const [ok, setOK] = useState('loading');
    const [resultData, setResultData] = useState<any>([]);
    const [conceptData, setConceptData] = useState<any>([]);

    const toggle = async () => {
        setModal(!modal);
        await getData();
        await getConceptData();
    }

    const getConceptData = async () => {
        let str = props.row.id;
        let ConceptId: number = parseInt(str.substring(0,str.indexOf('$')));
        const resultConcept = await conceptService.getConcept(ConceptId);
        setConceptData(resultConcept.data.content)
    }

    const getData = async () => {
        const result = await tedJournal.getTedActivityLogById(props.row);
        if (!result) return setOK('failed');
        setOK('success');
    }


    return <>
        <div>
            <div data-toggle="modal" data-target="#exampleModal" onClick={toggle}>
                <div className="mt-1 mb-1">
                    <div className="title">
                        {props.row.category.category === "Concepts" ? "Preview" : ""}

                    </div>
                </div>
            </div>
            <Modal isOpen={modal} centered>
                {theme === "light" ? (
                    <>
                        <ModalHeader>
                            {ok === 'failed' && <div>
                                <div className="mb-4">
                                    <ErrorInputComponent error={"An error occurred"} />
                                </div>
                            </div>}
                            {resultData && <HeaderComponent
                                title={props.row.title}
                                size={Headers.H2}
                            />}
                        </ModalHeader>
                        <ModalBody className='modal-body-config'>
                            <div>
                                {ok === 'loading' && <LoaderComponent />}
                                {ok === 'success' && <div>
                                    <div className="description margin-bottom-24" data-tag="allowRowEvents">
                                        <div dangerouslySetInnerHTML={{ __html: String(conceptData) }} />
                                    </div>
                                </div>}
                                {
                                    ok === 'failed' && <div>
                                        <div className="mb-4">
                                            <ErrorInputComponent error={"An error occurred"} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" className="font-weight-600" onClick={() => { setModal(false) }}>
                                <TextComponent text={'Close'} customClassName="text-color-182C34" />
                            </Button>
                        </ModalFooter></>
                ) :
                    <>
                        <ModalHeader style={{ background: "#28373E" }}>
                            {ok === 'failed' && <div>
                                <div className="mb-4">
                                    <ErrorInputComponent error={"An error occurred"} />
                                </div>
                            </div>}
                            {resultData && <HeaderComponent
                                title={props.row.title}
                                size={Headers.H2}
                            />}
                        </ModalHeader>
                        <ModalBody style={{ background: "#28373E", color: "white" }} className='modal-body-config'>
                            <div>
                                {ok === 'loading' && <LoaderComponent />}
                                {ok === 'success' && <div>
                                    <div className="description margin-bottom-24" data-tag="allowRowEvents">
                                        <div dangerouslySetInnerHTML={{ __html: String(conceptData) }} />
                                    </div>
                                </div>}
                                {
                                    ok === 'failed' && <div>
                                        <div className="mb-4">
                                            <ErrorInputComponent error={"An error occurred"} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ background: "#28373E" }}>
                            <Button color="secondary" className="font-weight-600" onClick={() => { setModal(false) }}>
                                <TextComponent text={'Close'} customClassName="text-color-182C34 buttonText" />
                            </Button>
                        </ModalFooter></>
                }
            </Modal>
        </div>
    </>

}
export default PreviewComponent;