import constant from "../Utils/Config";
import {isTokenExpired,clearLocalStorageAndRedirect, getAuthToken} from "../Utils/Utils";
class Lookup {
    async getGroups() {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Lookup/Groups`, {
                method: 'GET',
                headers: {
                    "accept": '*/*',
                    'Content-Type': 'application/json',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    }

    async getCountries() {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Lookup/Countries`, {
                method: 'GET',
                headers: {
                    "accept": '*/*',
                    'Content-Type': 'application/json',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    }

    async getStates(countryId: number) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Lookup/States?CountryId=${countryId}`, {
                method: 'GET',
                headers: {
                    "accept": '*/*',
                    'Content-Type': 'application/json',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    }

    async getLicenceTypes() {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Lookup/LicenceTypes`, {
                method: 'GET',
                headers: {
                    "accept": '*/*',
                    'Content-Type': 'application/json',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    if (response.status) {
                        return response;
                    }
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    }

    async getParticipantByOrgnisation(term: string) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Lookup/ParticipantByOrgnisation?term=${term}`, {
                method: 'GET',
                headers: {
                    "accept": '*/*',
                    'Content-Type': 'application/json',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    if (response.status) {
                        return response;
                    }
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    }

    async getFacilatorByOrgnisation(term: string, groupId: number) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            let params = "";
            if (groupId !== 0) {
                params += "&groupId=" + groupId
            }
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Lookup/FacilatorByOrgnisation?term=${term}${params
                }`, {
                method: 'GET',
                headers: {
                    "accept": '*/*',
                    'Content-Type': 'application/json',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    if (response.status) {
                        return response;
                    }
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    }

    async getProgramsList() {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Lookup/ProgramsList`, {
                method: 'GET',
                headers: {
                    "accept": '*/*',
                    'Content-Type': 'application/json',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    if (response.status) {
                        return response;
                    }
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    }

    async getProgramModule(selectedProgram: number) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Lookup/ProgramModules?programId=${selectedProgram}`, {
                method: 'GET',
                headers: {
                    "accept": '*/*',
                    'Content-Type': 'application/json',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    if (response.status) {
                        return response;
                    }
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    }

    async getProgramModuleSteps(moduleSteps: number) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Lookup/ModuleSteps?moduleId=${moduleSteps}`, {
                method: 'GET',
                headers: {
                    "accept": '*/*',
                    'Content-Type': 'application/json',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    if (response.status) {
                        return response;
                    }
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    }

    async getProgramsFaciltorGroup(facilitatorId: string) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Lookup/FaciltorGroup?facilatorId=${facilitatorId}`, {
                method: 'GET',
                headers: {
                    "accept": '*/*',
                    'Content-Type': 'application/json',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    if (response.status) {
                        return response;
                    }
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    }

    async getAttendanceStatus() {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Lookup/AttendanceStatus`, {
                method: 'GET',
                headers: {
                    "accept": '*/*',
                    'Content-Type': 'application/json',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    if (response.status) {
                        return response;
                    }
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    }

    async getDecryptedText() {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            let decodedCookie = decodeURIComponent(document?.cookie);
            let getDecodedCookies = decodedCookie?.split('; ');
            let specificCookie = getDecodedCookies?.filter((data: any) => data?.startsWith("PSID"));
            let encKeyFromCookie = specificCookie[0]?.split(/=(.+)/)[1]; //get cookie value of PSID
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Lookup/DecryptText/${encKeyFromCookie
                }`, {
                method: 'GET',
                headers: {
                    "accept": '*/*',
                    'Content-Type': 'application/json',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    if (response.status) {
                        return response;
                    }
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    }

    async getProgramMeetingUsers(groupId: string) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Lookup/ProgramMeetingUsersByGroups/${groupId}`, {
                method: 'GET',
                headers: {
                    "accept": '*/*',
                    'Content-Type': 'application/json',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    if (response.status) {
                        return response;
                    }
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    }

    async getUserList(groupIds:string) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            let params = '';
            if(groupIds && (groupIds !== '' || groupIds !== undefined)){
                params = "?groupIds=" + groupIds;                
            }
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Lookup/GetUserList`+params, {
                method: 'GET',
                headers: {
                    "accept": '*/*',
                    'Content-Type': 'application/json',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    if (response.status) {
                        return response;
                    }
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    }

    async getProgramStages(programId: number) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Lookup/GetProgramStage?programId=${programId}`, {
                method: 'GET',
                headers: {
                    "accept": '*/*',
                    'Content-Type': 'application/json',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    if (response.status) {
                        return response;
                    }
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    }

    async getProgramSurveyList() {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Lookup/GetProgramSurveyList`, {
                method: 'GET',
                headers: {
                    "accept": '*/*',
                    'Content-Type': 'application/json',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    if (response.status) {
                        return response;
                    }
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    }
}

export default Lookup;