import {Chart as ChartJS} from 'chart.js';
import { Bar, Pie, Radar } from "react-chartjs-2";
import { Card, Spinner } from "react-bootstrap";
import React, { useCallback, useRef } from "react";
import { chartType } from "../../../Utils/Enums";
import { IChartComponent } from "../../../interfaces/Charts/IChartComponent";
import { Button } from "reactstrap";

const ChartComponent = (props:IChartComponent) => {  
    let barChartRef = useRef<ChartJS<"bar", number[], string>>(null);
    let pieChartRef = useRef<ChartJS<"pie", number[], string>>(null);
    let radarChartRef = useRef<ChartJS<"radar", number[], string>>(null);
    const handleDownloadChart = useCallback(() => {
      const link = document.createElement("a");
        link.download = "chart.png";
      if(barChartRef.current){        
        link.href = barChartRef.current.toBase64Image();        
      }
      else if(pieChartRef.current){
        link.href = pieChartRef.current.toBase64Image();
      }
      else if(radarChartRef.current){
        link.href = radarChartRef.current.toBase64Image();
      }     
      link.click(); 
      link.remove();
    },[]) 

    return (<>
    <div>
        <Card>
              <Card.Body>                     
                  <div className="position-relative mb-1 p-1">                  
                 {
                  !props.spinner && props.data && props.data.labels.length > 0 && props.data.datasets.length > 0 && <Button
                  variant="primary"
                  className="font-weight-600 text-color-182C34 btn btn-primary float-end"
                  type="button"
                  onClick={handleDownloadChart}
              >
                  Download
              </Button>
                 } 
                    {                                          
                      props.spinner ? <div className="d-flex align-items-center justify-content-center">
                            <Spinner animation="border" variant="info"></Spinner>
                        </div> : (props.ChartType == chartType.Bar && props.data && props.data.labels.length > 0 && props.data.datasets.length > 0) ? 
                                 <Bar key={props.uKey} className="chart" options={props.options} data={props.data} ref={barChartRef} /> :  
                                 (props.ChartType == chartType.Pie && props.data && props.data.labels.length > 0 && props.data.datasets.length > 0) ? 
                                 <Pie key={props.uKey} className="chart" options={props.options} data={props.data} ref={pieChartRef} /> : 
                                 (props.ChartType == chartType.Radar && props.data && props.data.labels.length > 0 && props.data.datasets.length > 0) ?
                                 <Radar key={props.uKey} className="chart" options={props.options} data={props.data} ref={radarChartRef} /> :
                                 <div className="d-flex align-items-center justify-content-center">
                                    <h5>No Data For Chart</h5>
                                 </div>  
                    }                                                                          
                  </div>
              </Card.Body>
            </Card>
    </div>
    </>)
}

export default ChartComponent;