import React, { useContext } from 'react';
import { IContact } from '../../../Services/Engage.service';
import CircleImageComponent from '../CircleImage/CircleImageComponent';
import PopoverComponent, { IRole } from '../Popover/PopoverComponent';
import TextComponent from '../Text/TextComponent';
import './ContactItemComponent.css';
import { ThemeContext } from '../../../Contexts/ThemeContext';

interface IContactItemComponent {
    item: IContact;
    roles: String[];
    onAddScorerHandler?: (data: IRole) => void;
    onDeleteExternalHandler?: (data: string) => void;
    onDeleteContactHandler?: (data: string) => void;
    search?: string;
    numOfScorers: number;
}

const ContactItemComponent = (props: IContactItemComponent) => {
    const { theme } = useContext(ThemeContext);
    const onCallbackScorer = (data: IRole) => {
        props.onAddScorerHandler && props.onAddScorerHandler(data);
    }
    const onCallbackDeleteExternal = (data: string) => {
        props.onDeleteExternalHandler && props.onDeleteExternalHandler(data)
    }
    const onCallbackDeleteContact = (data: string) => {
        props.onDeleteContactHandler && props.onDeleteContactHandler(data)
    }
    return <>
        <div className="listItemConfig w-100 border-bottom-EBEBEB-thin d-flex flex-column justify-content-center">
            <div className="d-flex justify-content-between">
                <div className="d-flex">
                    <div className="margin-left-8">
                        <CircleImageComponent size={'imageSize49'} image={props.item.user?.profilePictureLocation ? props.item.user?.profilePictureLocation.replace("/api", "") : props.item.user?.profilePictureLocation} />
                    </div>
                    <div className={`d-flex flex-column align-items-center justify-content-center margin-left-8`}>
                        <TextComponent text={props.item.user?.firstName + " " + props.item.user?.lastName} />
                    </div>
                </div>
                <div className="d-flex">
                    {props.roles.length > 0 && <div className="margin-right-20 d-flex flex-column justify-content-center">
                        {theme === "light" ? (
                            <img src={window.location.origin + '/images/shield.svg'} alt="shield" />
                        ) : <img src={window.location.origin + '/images/shield-dark.svg'} alt="shield" />}
                    </div>}
                    <div className="d-flex flex-column justify-content-center">
                        <PopoverComponent
                            numOfScorers={props.numOfScorers}
                            item={props.item}
                            roles={props.roles}
                            onAddScorerHandler={onCallbackScorer}
                            onDeleteExternalHandler={onCallbackDeleteExternal}
                            onDeleteContactHandler={onCallbackDeleteContact}
                            search={props.search} />
                    </div>
                </div>
            </div>
        </div>
    </>
}
export default ContactItemComponent;