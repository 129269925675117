import React from "react";
import { Container } from 'reactstrap';
import '../custom.css';
import NavigationBar from "./FunctionalComponents/NavMenu/NavigationBar";

const MNLayout = (props:any) => {
    return(
        <div>
        <NavigationBar />        
        <Container className="container-custom">
          <section>
            <article>
              {props.children}
            </article>
          </section>
          <footer></footer>
        </Container>
      </div>
    );
}

export default MNLayout;