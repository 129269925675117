import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import HeaderComponent from '../Header/HeaderComponent';
import TextComponent from '../Text/TextComponent';
import { Headers } from '../../../Utils/Enums';
import TedJournal from '../../../Services/TedModule.service';
import ErrorInputComponent from '../ErrorInput/ErrorInputComponent';
import { ItedLogBookData } from '../TEDJournalComponents/TEDJournalEntryTabelComponent';
import "./LogbookModalComponent.css";
import LoaderComponent from '../Loader/LoaderComponent';
import Concept from '../../../Services/Concept.service';
import { ThemeContext } from '../../../Contexts/ThemeContext';

interface ILogbookModalComponent {
    row: ItedLogBookData;
}

const LogbookModalComponent = (props: ILogbookModalComponent) => {
    const { theme } = useContext(ThemeContext)
    const tedJournal = new TedJournal();
    const conceptService = new Concept();
    const [modal, setModal] = useState(false);
    const [show, setShow] = useState(false);
    const [ok, setOK] = useState('loading');
    const [resultData, setResultData] = useState<any>([]);
    const [conceptData, setConceptData] = useState<any>([]);

    const toggle = async () => {
        setModal(!modal);
        await getData();
    }

    const model = async () => {
        setShow(true);
        await getConceptData();
    }
    const getConceptData = async () => {
        let str = props.row.id;
        let conceptId = str.substring(0,str.indexOf('$'));
        let id: number = parseInt(conceptId);
        const resultData = await conceptService.getConcept(id);
        setConceptData(resultData.data.content)
    }
    const getData = async () => {
        const result = await tedJournal.getTedActivityLogById(props.row);
        if (!result) return setOK("failed");
        setOK('success');
        if (props.row.category.category === "Practices") {
            setResultData(result.data.data ? result.data.data.userActivity : []);
        } else if (props.row.category.category === "ModuleSummary") {
            setResultData(result.data.data ? result.data.data.userActivity : []);
        } else {
            setResultData(result.data.data ? result.data.data.userActivity.filter((x: any) => x.questionType === 'TedForms') : []);
        }

    }
    
    const modelOnClose = () => {
        setModal(false);
        setShow(false);
        setConceptData([]);
    }

    return <>
        <div>
            <div data-toggle="modal" data-target="#exampleModal" onClick={toggle}>
                <div className="mt-1 mb-1">
                    <div className="title">
                        {props.row.title}
                    </div>
                </div>
            </div>
            <Modal isOpen={modal} centered onClosed={() => setConceptData([])}>
                {theme === "light" ? (
                    <>
                        <ModalHeader>
                            {ok === 'failed' && <div>
                                <div className="mb-4">
                                    <ErrorInputComponent error={"An error occurred"} />
                                </div>
                            </div>}

                            {resultData && <HeaderComponent
                                title={props.row.title}
                                size={Headers.H2}
                            />}
                        </ModalHeader>
                        <ModalBody className='modal-body-config'>
                            <div>
                                {ok === 'loading' && <LoaderComponent />}

                                {ok === 'success' && <div>
                                    {show ?
                                        <div>
                                            <div className="description margin-bottom-24" data-tag="allowRowEvents">
                                                <div dangerouslySetInnerHTML={{ __html: String(conceptData) }} />
                                            </div>
                                        </div>
                                        :
                                        <>
                                            <div className="description margin-bottom-24" data-tag="allowRowEvents">
                                                {props.row.description}
                                            </div>
                                            {resultData && resultData.length > 0 &&
                                                resultData.map((x: any, index: number) =>
                                                    <div className="margin-bottom-24" key={index}>
                                                        {
                                                            props.row.category.category !== "Practices" && <TextComponent text={x.question} customClassName="text-color-2D5362 fw-bolder" />
                                                        }

                                                        <TextComponent text={x.response} customClassName="text-color-2D5362" />
                                                    </div>
                                                )}
                                        </>
                                    }

                                </div>}
                                {
                                    ok === 'failed' && <div>
                                        <div className="mb-4">
                                            <ErrorInputComponent error={"An error occurred"} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" className="font-weight-600" onClick={modelOnClose}>
                                <TextComponent text={'Close'} customClassName="text-color-182C34" />
                            </Button>

                            {props.row.category.category === "Concepts" ?
                                <Button color="secondary" className="font-weight-600" onClick={model}>
                                    <TextComponent text={'Preview'} customClassName="text-color-182C34" />
                                </Button> : ""}
                        </ModalFooter>
                    </>
                ) :
                    <>
                        <ModalHeader style={{ background: "#28373E" }}>
                            {ok === 'failed' && <div>
                                <div className="mb-4">
                                    <ErrorInputComponent error={"An error occurred"} />
                                </div>
                            </div>}

                            {resultData && <HeaderComponent
                                title={props.row.title}
                                size={Headers.H2}
                            />}
                        </ModalHeader>
                        <ModalBody style={{ background: "#28373E", color: "white" }} className='modal-body-config'>
                            <div>
                                {ok === 'loading' && <LoaderComponent />}

                                {ok === 'success' && <div>
                                    {show ?
                                        <div>
                                            <div className="description margin-bottom-24" data-tag="allowRowEvents">
                                                <div dangerouslySetInnerHTML={{ __html: String(conceptData) }} />
                                            </div>
                                        </div>
                                        :
                                        <>
                                            <div className="description margin-bottom-24" data-tag="allowRowEvents">
                                                {props.row.description}
                                            </div>
                                            {resultData && resultData.length > 0 &&
                                                resultData.map((x: any, index: number) =>
                                                    <div className="margin-bottom-24" key={index}>
                                                        {
                                                            props.row.category.category !== "Practices" && <TextComponent text={x.question} customClassName="text-color-2D5362 fw-bolder" />
                                                        }

                                                        <TextComponent text={x.response} customClassName="text-color-2D5362" />
                                                    </div>
                                                )}
                                        </>
                                    }

                                </div>}
                                {
                                    ok === 'failed' && <div>
                                        <div className="mb-4">
                                            <ErrorInputComponent error={"An error occurred"} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ background: "#28373E" }}>
                            <Button color="secondary" className="font-weight-600" onClick={() => { setModal(false) }}>
                                <TextComponent text={'Close'} customClassName="text-color-182C34 buttonText" />
                            </Button>

                            {props.row.category.category === "Concepts" ?
                                <Button color="secondary" className="font-weight-600" onClick={model}>
                                    <TextComponent text={'Preview'} customClassName="text-color-182C34 buttonText" />
                                </Button> : ""}
                        </ModalFooter>
                    </>
                }

            </Modal>

        </div>
    </>

}
export default LogbookModalComponent;