import React, { useState, useEffect, useContext } from 'react';
import { Form, Col, Button, Spinner } from 'react-bootstrap';
import { createNotification } from "../../../Utils/Toast";
import ErrorInputComponent from '../../FunctionalComponents/ErrorInput/ErrorInputComponent';
import Lookup from '../../../Services/Lookup.service';
import RegisterOrganisationUser from "../../../Services/RegisterOrganisationUser.service";
import General from '../../../Services/General.service';
import { useHistory } from 'react-router';
import { logOutIfTokenExpire } from '../../../Utils/Utils';
import { ThemeContext } from "../../../Contexts/ThemeContext";
import "../../../darkcustom.css"

interface Igroup {
    id: number;
    name: string;
}

interface Icountry {
    id: number;
    name: string;
    countryCode: string;
}

interface Istate {
    id: number;
    name: string;
    stateCode: string;
    countryId: number;
}

interface Iprops {
    userDetails: any;
    editUserDetails: any;
    getUserData: any;
    setEditData: (data: boolean) => void;
    setModalState: (data: boolean) => void;
    editData: boolean;
}

const OrganisationalUserComponent = (props: Iprops) => {
    let history = useHistory();
    const generalService = new General();
    generalService.getIcons();
    const {
        userDetails,
        editUserDetails,
        setEditData,
        setModalState,
        editData
    } = props;

    const lookup = new Lookup();
    const registerOrganisationUserService = new RegisterOrganisationUser();
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [groups, setGroups] = useState<any[]>([]);
    const [countries, setCountries] = useState<any[]>([]);
    const [states, setStates] = useState<any[]>([]);
    //eslint-disable-next-line
    const [licenseTypeIds, setLicenseTypeIds] = useState<number[]>([1]);
    //eslint-disable-next-line
    const [roleIds, setRoleIds] = useState<string[]>([""]);
    const [selectedGroup, setSelectedGroup] = useState<number>(0);
    const [selectedCountry, setSelectedCountry] = useState<number>(0);
    const [selectedState, setSelectedState] = useState<number>(0);
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const { theme } = useContext(ThemeContext)

    const [errorToggel, setErrorToggel] = useState<any>({
        firstName: "",
        lastName: "",
        email: "",
        selectedGroup: "",
        selectedCountry: "",
        selectedState: "",
    });

    const [valueUpdated, setValueUpdated] = useState<any>({
        firstName: false,
        lastName: false,
        email: false,
        selectedGroup: false,
        selectedCountry: false,
        selectedState: false,
    });


    useEffect(() => {
        logOutIfTokenExpire();
        if (editData) {
            setFirstName(editUserDetails.firstName);
            setLastName(editUserDetails.lastName);
            setEmail(editUserDetails.userEmail);
            setSelectedGroup(editUserDetails.groupId);
            setSelectedCountry(editUserDetails.countryId);
            setSelectedState(editUserDetails.stateId);
            setRoleIds(editUserDetails.roles)
        }
        //eslint-disable-next-line
    }, [editData]);

    useEffect(() => {
        getGroups();
        getCountries();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (selectedCountry) {
            getStates();
        }
        //eslint-disable-next-line
    }, [selectedCountry]);

    const getGroups = async () => {
        if (userDetails.organisationId) {
            let groupResult: any;
            groupResult = await lookup.getGroups();
            setGroups(groupResult);
        }
    };

    const getCountries = async () => {
        let countryResult: any;
        countryResult = await lookup.getCountries();
        setCountries(countryResult);
    }

    const getStates = async () => {
        let stateResult: any;
        stateResult = await lookup.getStates(selectedCountry);
        setStates(stateResult);
    }

    const validateForm = async () => {
        //eslint-disable-next-line
        let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (firstName.length < 1 || firstName.length === 0) {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    firstName: "First name must be of atleast 1 character"
                }
            })
            return false;
        } else {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    firstName: ""
                }
            })
        }

        if (lastName.length < 1 || lastName.length === 0) {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    lastName: "Last name must be of atleast 1 character"
                }
            })
            return false;
        } else {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    lastName: ""
                }
            })
        }

        if (!email.match(mailFormat)) {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    email: "Please enter valid email address"
                }
            })
            return false;
        } else {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    email: ""
                }
            })
        }
        if (editData) {
            return true;
        }
        return true;
    }



    const isValueUpdated = async () => {
        if (valueUpdated.firstName || valueUpdated.lastName || valueUpdated.email || valueUpdated.selectedGroup || valueUpdated.selectedCountry || valueUpdated.selectedState) {
            return true;
        } else {
            return false;
        }
    }

    const SubmitFormHandler = async (event: any) => {
        event.preventDefault();
        if (await validateForm()) {
            setShowSpinner(true);
            if (editData) {
                let userEditData: any = {
                    userId: editUserDetails.userId,
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    country: { id: selectedCountry },
                    groupId: selectedGroup,
                    state: { id: selectedState },
                    roleIds: roleIds,
                    licenseTypeIds: licenseTypeIds
                }
                if (await isValueUpdated()) {
                    let response = await registerOrganisationUserService.updateOrganisationUser(userEditData);
                    if (response.status) {
                        setShowSpinner(false);
                        createNotification("success", response.message)
                        setEditData(false);
                        setModalState(false);
                        props.getUserData();
                    } else {
                        setShowSpinner(false);
                        setEditData(false);
                        setModalState(false);
                        createNotification("error", response.message)
                    }
                } else {
                    setEditData(false);
                    setModalState(false);
                    setShowSpinner(false);
                    createNotification("info", "You have not updated anything!");
                }
            } else {
                let userCreateData: any = {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    country: { id: selectedCountry },
                    groupId: selectedGroup,
                    state: { id: selectedState },
                    roleIds: roleIds,
                    licenseTypeIds: licenseTypeIds
                }

                let response = await registerOrganisationUserService.registerUser(userCreateData);

                if (response.status) {
                    setShowSpinner(false);
                    createNotification("success", response.message)
                    setModalState(false);
                    props.getUserData();
                } else {
                    setShowSpinner(false);
                    setModalState(false);
                    setModalState(false);
                    createNotification("error", response.message || "Something went wrong!")
                }
            }
        }
    }

    return <>
        <div className="row justify-content-center">
            <div>
                <section>
                    {theme === "light" ? (
                        <form id="account" autoComplete="off">
                            <Form.Group className="mb-3">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={firstName || ""}
                                    placeholder="Enter Firstname"
                                    onChange={(e) => {
                                        setFirstName(e.target.value);
                                        setValueUpdated((states: any) => {
                                            return {
                                                ...states,
                                                firstName: true
                                            }
                                        })
                                    }}
                                />
                                {errorToggel.firstName && <ErrorInputComponent error={errorToggel.firstName} />}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={lastName || ""}
                                    placeholder="Enter Lastname"
                                    onChange={(e) => {
                                        setLastName(e.target.value);
                                        setValueUpdated((states: any) => {
                                            return {
                                                ...states,
                                                lastName: true
                                            }
                                        })
                                    }}
                                />
                                {errorToggel.lastName && <ErrorInputComponent error={errorToggel.lastName} />}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    placeholder="Enter email"
                                    value={email || ""}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        setValueUpdated((states: any) => {
                                            return {
                                                ...states,
                                                email: true
                                            }
                                        })
                                    }}
                                />
                                {errorToggel.email && <ErrorInputComponent error={errorToggel.email} />}
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridGroup" className="mb-3">
                                <Form.Label>Group</Form.Label>
                                <Form.Select value={selectedGroup}
                                    onChange={(e: any) => {
                                        setSelectedGroup(parseInt(e.target.value))
                                        e.preventDefault();
                                        setValueUpdated((states: any) => {
                                            return {
                                                ...states,
                                                selectedGroup: true
                                            }
                                        })
                                    }}
                                >
                                    <option value={0}>{"Select Group"}</option>
                                    {groups.map((group: Igroup) => <option key={group.id} value={group.id}>{group.name}</option>)}
                                </Form.Select>
                                {errorToggel.selectedGroup && <ErrorInputComponent error={errorToggel.selectedGroup} />}
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridCountry" className="mb-3">
                                <Form.Label>Country</Form.Label>
                                <Form.Select value={selectedCountry}
                                    onChange={(e: any) => {
                                        setSelectedCountry(parseInt(e.target.value))
                                        e.preventDefault();
                                        setValueUpdated((states: any) => {
                                            return {
                                                ...states,
                                                selectedCountry: true
                                            }
                                        })
                                    }}
                                >
                                    <option value={0}>{"Select Country"}</option>
                                    {countries.map((country: Icountry) =>
                                        <option key={country.id} value={country.id}>{country.name}</option>)}
                                </Form.Select>
                                {errorToggel.selectedCountry && <ErrorInputComponent error={errorToggel.selectedCountry} />}
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridState" className="mb-3">
                                <Form.Label>State</Form.Label>
                                <Form.Select value={selectedState}
                                    onChange={(e: any) => {
                                        setSelectedState(parseInt(e.target.value))
                                        e.preventDefault();
                                        setValueUpdated((states: any) => {
                                            return {
                                                ...states,
                                                selectedState: true
                                            }
                                        })
                                    }}
                                >
                                    <option value={0}>Select State</option>
                                    {states.map((state: Istate) => <option key={state.id} value={state.id}>{state.name}</option>)}
                                </Form.Select>
                                {errorToggel.selectedState && <ErrorInputComponent error={errorToggel.selectedState} />}
                            </Form.Group>

                            <div className="d-grid gap-2 mt-1">
                                {
                                    showSpinner ?
                                        <Button variant="primary" className="text-secondary" disabled>
                                            <span className="mx-2 text-secondary">
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                            {editData ? 'Editing user data...' : 'Creating user...'}
                                        </Button>
                                        :
                                        <Button
                                            variant="primary"
                                            type="button"
                                            onClick={(e: any) => SubmitFormHandler(e)}
                                        >
                                            {editData ? 'Edit User' : 'Create User'}
                                        </Button>
                                }
                            </div>
                        </form>
                    ) :
                        <form id="account" autoComplete="off">
                            <Form.Group className="mb-3">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={firstName || ""}
                                    placeholder="Enter Firstname"
                                    className="userSearchBox"
                                    onChange={(e) => {
                                        setFirstName(e.target.value);
                                        setValueUpdated((states: any) => {
                                            return {
                                                ...states,
                                                firstName: true
                                            }
                                        })
                                    }}
                                />
                                {errorToggel.firstName && <ErrorInputComponent error={errorToggel.firstName} />}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={lastName || ""}
                                    placeholder="Enter Lastname"
                                    className="userSearchBox"
                                    onChange={(e) => {
                                        setLastName(e.target.value);
                                        setValueUpdated((states: any) => {
                                            return {
                                                ...states,
                                                lastName: true
                                            }
                                        })
                                    }}
                                />
                                {errorToggel.lastName && <ErrorInputComponent error={errorToggel.lastName} />}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    placeholder="Enter email"
                                    value={email || ""}
                                    className="userSearchBox"
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        setValueUpdated((states: any) => {
                                            return {
                                                ...states,
                                                email: true
                                            }
                                        })
                                    }}
                                />
                                {errorToggel.email && <ErrorInputComponent error={errorToggel.email} />}
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridGroup" className="mb-3">
                                <Form.Label>Group</Form.Label>
                                <Form.Select className="userSearchBox" value={selectedGroup}
                                    onChange={(e: any) => {
                                        setSelectedGroup(parseInt(e.target.value))
                                        e.preventDefault();
                                        setValueUpdated((states: any) => {
                                            return {
                                                ...states,
                                                selectedGroup: true
                                            }
                                        })
                                    }}
                                >
                                    <option value={0}>{"Select Group"}</option>
                                    {groups.map((group: Igroup) => <option key={group.id} value={group.id}>{group.name}</option>)}
                                </Form.Select>
                                {errorToggel.selectedGroup && <ErrorInputComponent error={errorToggel.selectedGroup} />}
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridCountry" className="mb-3">
                                <Form.Label>Country</Form.Label>
                                <Form.Select className="userSearchBox" value={selectedCountry}
                                    onChange={(e: any) => {
                                        setSelectedCountry(parseInt(e.target.value))
                                        e.preventDefault();
                                        setValueUpdated((states: any) => {
                                            return {
                                                ...states,
                                                selectedCountry: true
                                            }
                                        })
                                    }}
                                >
                                    <option value={0}>{"Select Country"}</option>
                                    {countries.map((country: Icountry) =>
                                        <option key={country.id} value={country.id}>{country.name}</option>)}
                                </Form.Select>
                                {errorToggel.selectedCountry && <ErrorInputComponent error={errorToggel.selectedCountry} />}
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridState" className="mb-3">
                                <Form.Label>State</Form.Label>
                                <Form.Select className="userSearchBox" value={selectedState}
                                    onChange={(e: any) => {
                                        setSelectedState(parseInt(e.target.value))
                                        e.preventDefault();
                                        setValueUpdated((states: any) => {
                                            return {
                                                ...states,
                                                selectedState: true
                                            }
                                        })
                                    }}
                                >
                                    <option value={0}>Select State</option>
                                    {states.map((state: Istate) => <option key={state.id} value={state.id}>{state.name}</option>)}
                                </Form.Select>
                                {errorToggel.selectedState && <ErrorInputComponent error={errorToggel.selectedState} />}
                            </Form.Group>

                            <div className="d-grid gap-2 mt-1">
                                {
                                    showSpinner ?
                                        <Button variant="primary" className="text-secondary" disabled>
                                            <span className="mx-2 text-secondary">
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                            {editData ? 'Editing user data...' : 'Creating user...'}
                                        </Button>
                                        :
                                        <Button
                                            variant="primary"
                                            type="button"
                                            onClick={(e: any) => SubmitFormHandler(e)}
                                        >
                                            {editData ? 'Edit User' : 'Create User'}
                                        </Button>
                                }
                            </div>
                        </form>}
                </section>
            </div>
        </div>
    </>
};

export default OrganisationalUserComponent;