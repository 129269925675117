import React, { useState } from "react";
import { useHistory } from "react-router";
import { createNotification } from "../../../Utils/Toast";
import "./TEDModalComponent.css";

function TEDModalComponent() {
    const [clickedTedModal, setClickedTedModal] = useState<boolean>(false);
    const [firstName, setFirstName] = useState<string>('');

    let history = useHistory();


    const handleNewJournalEntryClick = () => {
        history.push("/newjournalentry")
    }

    const handleViewAll = () => {
        history.push("/tedlogbook");
    }

    const handleTEDClick = (e: any) => {
        let userDetails = localStorage.getItem("userConfig");
        let userFirstName;
        if (userDetails) {
            let parsedUserDetails = JSON.parse(userDetails);
            setFirstName(parsedUserDetails.firstName);
        }
        setClickedTedModal((prevState: boolean) => !prevState);
        const tedElement = window.document.getElementById("wrapper");
        if (tedElement === null) {
            createNotification("error", "Something went wrong!");
        } else {
            if (clickedTedModal) {
                return tedElement.style.visibility = 'hidden';
            } else {
                return tedElement.style.visibility = 'visible';

            }
        }
    }

    return (
        <div className="main-wrapper d-flex justify-content-end">
            <input type="checkbox" id="check" onClick={(e: any) => handleTEDClick(e)} />
            <label className="chat-btn chat-res margin-left-18 cursor-pointer" htmlFor="check">
                <div className="circle user-icon-font comment">TED</div>
                <i className="fa fa-close close"></i>
            </label>
            <div id="wrapper" className='wrapper shadow-sm justify-content-between d-flex p-3'>
                <div className="ted-chat shadow-sm user-icon-font">TED</div>
                <div id="actions" className="actions shadow-sm px-3 py-1">
                    <div className="actions-font">{`Hey ${firstName}, what would you like to do?`}</div>
                    <div className="pb-2 mt-2">
                        <button
                            id="button"
                            type="button"
                            role="button"
                            className="btn w-100 mb-2 button-text btn-primary btn-block"
                            onClick={() => handleNewJournalEntryClick()}
                        >
                            New Journal Entry
                        </button>

                        <button
                            type="button"
                            role="button"
                            id="button"
                            className="btn w-100 button-text mb-2 btn-secondary btn-block"
                            onClick={() => handleViewAll()}
                        >
                            View All
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TEDModalComponent;