import React from 'react';
import TextComponent from '../Text/TextComponent';
import './EmailLinkComponent.css';

interface IEmailLinkComponent{
    mail: string;
    title: string;
}
const EmailLinkComponent = (props: IEmailLinkComponent) => {
    return <>
        <a href={`mailto:${props.mail}`} className="text-decoration-none" role="navigation"><TextComponent text={props.title}/></a>
    </>
}

export default EmailLinkComponent;