import React, { useContext } from "react";
import { Form } from "react-bootstrap";
import { Col } from "reactstrap";
import "./UserPracticeComponentsStyle/PracticeTableComponent.css";
import "../../../darkcustom.css"
import { ThemeContext } from "../../../Contexts/ThemeContext"
interface Iprops {
    searchText: string;
    handleSearchText: (value: string) => void;
    handleSearch: () => void;
    moduleData: any;
    handleSelectedModule: (value: number) => void;
    isResorceLibrary?: boolean;
}

function PracticeLibraryFilterComponent(props: Iprops) {
    let { theme } = useContext(ThemeContext)
    const { searchText, handleSearchText, handleSearch, moduleData, handleSelectedModule, isResorceLibrary } = props;

    const handleModuleSelect = (value: number) => {
        handleSelectedModule(value);
    }

    return (
        <div className="my-3 practice-library-wrapper">

            <div className="d-flex flex-column">
                <label htmlFor="basic-url" className="form-label">Search for a word or phrase</label>
                {theme === "light" ? (
                    <div className="input-group mb-3">
                        <input
                            type="text"
                            placeholder="Search"
                            className="form-control"
                            id="basic-url"
                            value={searchText || ""}
                            aria-describedby="basic-addon3"
                            onChange={(e: any) => handleSearchText(e.target.value)}
                        />
                        <span onClick={() => handleSearch()} className="input-group-text cursor-pointer" id="basic-addon3">Search</span>
                    </div>
                ) : <div className="input-group mb-3">
                        <input
                            type="text"
                            placeholder="Search"
                            className="form-control searchBox"
                            id="basic-url"
                            value={searchText || ""}
                            aria-describedby="basic-addon3"
                            onChange={(e: any) => handleSearchText(e.target.value)}
                        />
                        <span onClick={() => handleSearch()} className="input-group-text cursor-pointer" id="basic-addon3">Search</span>
                    </div>}

            </div>
            {isResorceLibrary ? null :
                <div className="d-flex flex-column">
                    <label htmlFor="basic-url" className="form-label">Filters</label>
                    <div className="input-group mb-3 ">
                        {theme === "light" ? (
                            <Form.Group as={Col} xs={12} md={4} lg={2} controlId="formGridGroup" className="w-100" >
                                <Form.Select onChange={(e: any) =>
                                    handleModuleSelect(e.target.value)
                                }>
                                    <option value={0}>{"Select Module"}</option>
                                    {moduleData?.map((data: any) => <option key={data.id} value={data.id}>{data.title}</option>)}
                                </Form.Select>
                            </Form.Group>
                        ) : <Form.Group as={Col} xs={12} md={4} lg={2} controlId="formGridGroup" className="w-100" >
                                <Form.Select style={{ color: "white" }} className="searchBox" onChange={(e: any) =>
                                    handleModuleSelect(e.target.value)
                                }>
                                    <option value={0}>{"Select Module"}</option>
                                    {moduleData?.map((data: any) => <option key={data.id} value={data.id}>{data.title}</option>)}
                                </Form.Select>
                            </Form.Group>}


                    </div>
                </div>
            }
        </div>
    );
}

export default PracticeLibraryFilterComponent;