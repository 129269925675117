import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import General from "../../../Services/General.service";
import Quotes from "../../../Services/Quotes.service";
import { Headers } from "../../../Utils/Enums";
import HeaderComponent from "../../FunctionalComponents/Header/HeaderComponent";
import LibraryFilterComponent from "../../FunctionalComponents/LibraryFilterComponent/LibraryFilterComponent";
import LibraryTableComponent from "../../FunctionalComponents/LibraryTableComponent/LibraryTableComponent";
import PaginationComponent from "../../FunctionalComponents/Paginator/PaginationComponent";
import TEDModalComponent from "../../FunctionalComponents/TEDModal/TEDModalComponent";
import TextComponent from "../../FunctionalComponents/Text/TextComponent";
import { logOutIfTokenExpire } from '../../../Utils/Utils';
import { UserContext } from "../../../Contexts/UserContext";
import { ThemeContext } from "../../../Contexts/ThemeContext";
import "../../../darkcustom.css"
interface IuserLikedQuote {
    author: string;
    dateAdded: string;
    isFavourite: boolean;
    quoteText: string;
}

function QuotesComponent() {
    const generalService = new General();
    generalService.getIcons();
    const quote = new Quotes();
    let history = useHistory();
    const [searchParam, setSearchParam] = useState<string>("");
    const [pageSize, setPageSize] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);

    const [quoteTableData, setQuoteTableData] = useState<IuserLikedQuote[]>([]);
    const userContext = useContext(UserContext);
    const { theme } = useContext(ThemeContext)
    useEffect(() => {
        userContext.dispatch('');
    }, [])
    useEffect(() => {
        logOutIfTokenExpire();
        getUserLikedQuote(false);
        //eslint-disable-next-line
    }, [currentPage, pageSize]);

    const getUserLikedQuote = async (isFilter: boolean) => {
        let response: any = await quote.getLikedQuote(searchParam, isFilter ? 1 : currentPage, pageSize);
        if (response.status) {
            setPageSize(response.data.pagination.pageSize);
            setCurrentPage(response.data.pagination.currentPage);
            setTotalItems(response.data.pagination.totalItems);
            setTotalPages(response.data.pagination.totalPages);
            let userLikedQuotes: IuserLikedQuote[] = response.data.items.map((data: any) => {
                return {
                    author: data.author,
                    dateAdded: data.dateAdded.split("T")[0],
                    isFavourite: true,
                    quoteText: data.quote,
                }
            });
            setQuoteTableData(userLikedQuotes);
        } else {
            setQuoteTableData([]);
        }
    }

    const onPageHandler = (id: number) => {
        setCurrentPage(id);
    }

    const handlegetUserLikedQuoteSearch = async () => {
        if (currentPage == 1) {
            await getUserLikedQuote(true);
        }
        else {
            setCurrentPage(1);
        }
    }

    return (
        <>
            <div className="container-fluid row col-sm-12">
                {theme === "light" ? (
                    <div className="d-flex align-items-center">
                        <img onClick={() => history.push("/dashboard")}
                            className="text-decoration-none cursor-pointer"
                            alt="chevron-left" src={window.location.origin + "/images/chevron-left.svg"}
                        />
                        <span onClick={() => history.push("/dashboard")}
                            className="text-decoration-none cursor-pointer">
                            <HeaderComponent
                                title="Quotes"
                                size={Headers.H2}
                            />
                        </span>
                    </div>
                ) : <div className="d-flex align-items-center">
                        <img onClick={() => history.push("/dashboard")}
                            className="text-decoration-none cursor-pointer"
                            alt="chevron-left" src={window.location.origin + "/images/left-chevron-dark.svg"}
                        />
                        <span onClick={() => history.push("/dashboard")}
                            className="text-decoration-none cursor-pointer">
                            <div style={{ marginLeft: "15px" }}>
                                <HeaderComponent
                                    title="Quotes"
                                    size={Headers.H2}
                                />
                            </div>
                        </span>
                    </div>}


                <div className="mt-3 intro-text">
                    <TextComponent
                        text="This is where quotes you have ‘liked’ are stored."
                    />
                </div>

                <div className="mt-3 d-flex flex-row justify-content-between align-items-center col-xl-6 col-lg-8 col-md-12">
                    <LibraryFilterComponent
                        setSearchParam={setSearchParam}
                        handlegetUserLikedQuoteSearch={handlegetUserLikedQuoteSearch}
                        isUserLikedQuote={true}
                        page={"quote"}
                    />
                </div>
                <div className="mt-3">
                    <div className="d-flex flex-row justify-content-between w-100">
                        <HeaderComponent
                            title="Favourites"
                            size={Headers.H2}
                        />
                        {theme === "light" ? (
                            <>
                                <Link to={"/quoteslibrary"} className="text-decoration-none border-bottom border-dark">
                                    <HeaderComponent
                                        title="View All"
                                        size={Headers.H3}
                                    />
                                </Link>
                            </>
                        ) : <>
                                <Link to={"/quoteslibrary"} style={{ borderColor: "#fff !important", borderBottom: "1px solid #fff" }} className="text-decoration-none">
                                    <HeaderComponent
                                        title="View All"
                                        size={Headers.H3}
                                    />
                                </Link>
                            </>}

                    </div>
                    <LibraryTableComponent
                        quoteTableData={quoteTableData}
                        resourceTableData={[]}
                    />
                </div>
                {quoteTableData.length > 0 &&
                    <div className='d-flex justify-content-end mb-3'>
                        <PaginationComponent
                            currentPage={currentPage}
                            pageSize={pageSize}
                            totalItems={totalItems}
                            totalPages={totalPages}
                            onPageHandler={onPageHandler}
                        />
                    </div>
                }
            </div>
            <div>
                <TEDModalComponent />
            </div>
        </>
    );
}

export default QuotesComponent;