import React, { useEffect, useState, useContext } from "react";
import "./HamburgerMenu.css";
import { Link } from "react-router-dom";
import { NavbarToggler } from "reactstrap";
import { Offcanvas } from 'react-bootstrap';
import { LoginMenu } from "../../api-authorization/LoginMenu";
import {
  CLIENT_ADMIN_LABEL,
  SUB_CLIENT_ADMIN_LABEL,
  FACILITOR_LABEL,
  PARTICIPANT_LABEL,
  MINDNAVIGATOR_ADMIN_LABEL,
  SUB_CLIENT_ADMIN,
  MNFACILITATOR
} from "../../../Utils/Roles";
import { getUserOrgnization } from '../../../Utils/Utils';
import General from '../../../Services/General.service';
import { ThemeContext } from "../../../Contexts/ThemeContext";
import "../../../darkcustom.css"
//related to meaning making modal
// import BootstrapModal from "../BootstrapModal/BootstrapModal";
interface IHamburgerMenu {
  role: string
};

function HamburgerMenu(props: IHamburgerMenu) {
  const generalService = new General();
  const [show, setShow] = useState<boolean>(false);
  const [role, setRole] = useState<string>("");
  const [passedIntro, setPassedIntro] = useState<boolean>(false);
  const [, setUrl] = useState(window.location.pathname.toLowerCase());
  const { theme } = useContext(ThemeContext)

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const [showModal, setShowModal] = useState<boolean>(false);

  let roles: any = props.role.split(",");

  useEffect(() => {
    findUserRole();
    getUrl();
    //eslint-disable-next-line
  }, []);

  const findUserRole = async () => {
    if (roles.length === 1) {
      setRole(roles[0]);
    } else {
      if (roles.includes(CLIENT_ADMIN_LABEL) || (roles.includes(CLIENT_ADMIN_LABEL) && role.includes(PARTICIPANT_LABEL))) {
        setRole(CLIENT_ADMIN_LABEL);
      } else if (roles.includes(FACILITOR_LABEL) || (role.includes(FACILITOR_LABEL) && role.includes(PARTICIPANT_LABEL))) {
        setRole(FACILITOR_LABEL);
      } else if (roles.includes(SUB_CLIENT_ADMIN_LABEL) || (role.includes(SUB_CLIENT_ADMIN_LABEL) && role.includes(PARTICIPANT_LABEL))) {
        setRole(SUB_CLIENT_ADMIN_LABEL);
      } else {
        setRole(PARTICIPANT_LABEL);
      }
    }
  }

  const checkIntro = async () => {
    let result: any = await generalService.getProfileIntention();
    setPassedIntro(result?.data?.introCompleted);
  }

  const handleAdminRedirection = () => {
    window.location.href = "/Admin";
  }

  const handleSidebarClick = () => {
    setShow(false);
  }

  const getUrl = () => {
    let url = window.location.pathname;
    setUrl(url.toLowerCase());
  }

  return (
    <>
      <NavbarToggler variant="primary" onClick={handleShow}  >
      </NavbarToggler>
      {theme === "light" ? (
        <Offcanvas backdropClassName="off-cnavas" show={show} onHide={handleClose} onShow={role !== MNFACILITATOR && checkIntro}>
          <Offcanvas.Header closeButton>
          </Offcanvas.Header>
          <Offcanvas.Body >
            <div className="d-flex justify-content-center w-100">
              <div className="align-self-center">
                <h5>{getUserOrgnization()}</h5>
              </div>
            </div>
            {
              role === MNFACILITATOR ? <ul>
                <Link
                  to={"/mnfacilitatordashboard"}
                  className="lnk-pointer"
                  onClick={() => handleSidebarClick()}
                >
                  Dashboard for MN Facilitator
                  </Link>
              </ul> : (passedIntro || role === CLIENT_ADMIN_LABEL) && <ul>
                <li className="nvlinks-1">
                  <Link
                    to={"/dashboard"}
                    onClick={() => handleSidebarClick()}
                    className="lnk-pointer"
                  >
                    Dashboard
                </Link>

                  <Link
                    to={"/mypractices"}
                    onClick={() => handleSidebarClick()}
                    className="lnk-pointer"
                  >
                    Practices
                </Link>

                  <Link
                    to={"/myresources"}
                    onClick={() => handleSidebarClick()}
                    className="lnk-pointer"
                  >
                    Resources
                </Link>

                  <Link
                    to={"/myquotes"}
                    onClick={() => handleSidebarClick()}
                    className="lnk-pointer"
                  >
                    Quotes
                </Link>

                  <Link
                    to={"/engage/-1?status=complete"}
                    onClick={() => handleSidebarClick()}
                    className="lnk-pointer"
                  >
                    My Network
                </Link>

                  <Link
                    to={"/account"}
                    onClick={() => handleSidebarClick()}
                    className="lnk-pointer"
                  >
                    Account
                </Link>

                  {(role === CLIENT_ADMIN_LABEL) &&
                    <Link
                      to={"/clientadmindashboard"}
                      className="lnk-pointer"
                      onClick={() => handleSidebarClick()}
                    >
                      Dashboard for Client Admin
                  </Link>
                  }

                  {(role === FACILITOR_LABEL) &&
                    <Link
                      to={"/facilitordashboard"}
                      className="lnk-pointer"
                      onClick={() => handleSidebarClick()}
                    >
                      Dashboard for Facilitor
                  </Link>
                  }

                  {(role === SUB_CLIENT_ADMIN) &&
                    <Link
                      to={"/clientadmindashboard"}
                      className="lnk-pointer"
                      onClick={() => handleSidebarClick()}
                    >
                      Dashboard for Sub Client Admin
                  </Link>
                  }

                  {(role === MINDNAVIGATOR_ADMIN_LABEL) &&
                    <p className="lnk-pointer cursor-pointer" onClick={() => { handleAdminRedirection(); handleSidebarClick() }}>
                      Mindnavigator Admin Panel
                  </p>
                  }

                </li>
              </ul>}
            <ul>
              <p className="link-pointer cursor-pointer" onClick={() => { handleSidebarClick() }}>
                <LoginMenu />
              </p>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>
      ) :
        <Offcanvas backdropClassName="off-canvas" show={show} onHide={handleClose} onShow={role !== MNFACILITATOR && checkIntro}>
          <Offcanvas.Header className="canvasBody" closeVariant="white" closeButton>
          </Offcanvas.Header>
          <Offcanvas.Body className="canvasBody">
            <div className="d-flex justify-content-center w-100">
              <div className="align-self-center">
                <h5>{getUserOrgnization()}</h5>
              </div>
            </div>
            {
              role === MNFACILITATOR ? <ul>
                <Link
                  to={"/mnfacilitatordashboard"}
                  className="canvasText"
                  onClick={() => handleSidebarClick()}
                >
                  Dashboard for MN Facilitator
                </Link>
              </ul> : (passedIntro || role === CLIENT_ADMIN_LABEL) && <ul>
                <li className="nvlinks-1">
                  <Link
                    to={"/dashboard"}
                    onClick={() => handleSidebarClick()}
                    className="canvasText"
                  >
                    Dashboard
                  </Link>

                  <Link
                    to={"/mypractices"}
                    onClick={() => handleSidebarClick()}
                    className="canvasText"
                  >
                    Practices
                  </Link>

                  <Link
                    to={"/myresources"}
                    onClick={() => handleSidebarClick()}
                    className="canvasText"
                  >
                    Resources
                  </Link>

                  <Link
                    to={"/myquotes"}
                    onClick={() => handleSidebarClick()}
                    className="canvasText"
                  >
                    Quotes
                  </Link>

                  <Link
                    to={"/engage/-1?status=complete"}
                    onClick={() => handleSidebarClick()}
                    className="canvasText"
                  >
                    My Network
                  </Link>

                  <Link
                    to={"/account"}
                    onClick={() => handleSidebarClick()}
                    className="canvasText"
                  >
                    Account
                  </Link>

                  {(role === CLIENT_ADMIN_LABEL) &&
                    <Link
                      to={"/clientadmindashboard"}
                      className="canvasText"
                      onClick={() => handleSidebarClick()}
                    >
                      Dashboard for Client Admin
                    </Link>
                  }

                  {(role === FACILITOR_LABEL) &&
                    <Link
                      to={"/facilitordashboard"}
                      className="canvasText"
                      onClick={() => handleSidebarClick()}
                    >
                      Dashboard for Facilitor
                    </Link>
                  }

                  {(role === SUB_CLIENT_ADMIN) &&
                    <Link
                      to={"/clientadmindashboard"}
                      className="canvasText"
                      onClick={() => handleSidebarClick()}
                    >
                      Dashboard for Sub Client Admin
                    </Link>
                  }

                  {(role === MINDNAVIGATOR_ADMIN_LABEL) &&
                    <p className="canvasText cursor-pointer" onClick={() => { handleAdminRedirection(); handleSidebarClick() }}>
                      Mindnavigator Admin Panel
                    </p>
                  }

                </li>
              </ul>}
            <ul>
              <p className="link-pointer cursor-pointer" onClick={() => { handleSidebarClick() }}>
                <LoginMenu />
              </p>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>}
    </>
  );
}

export default HamburgerMenu;