import React, { useState, useContext } from 'react';
import { Button, Collapse, Card, CardBody } from 'reactstrap';
import stylesCollapseComponent from './CollapseComponent.module.css';
import { ThemeContext } from "../../../Contexts/ThemeContext";
import "../../../darkcustom.css"

interface ICollapseComponent {
    text: string;
    customClassName?: string;
    onConfirm?: () => void;
}

const CollapseComponent = (props: ICollapseComponent) => {
    const [collapse, setCollapse] = useState(false);
    const [status, setStatus] = useState('Expand transcript');
    const [chkBoxDisabled, setChkBoxDisabled] = useState(false);
    const { theme } = useContext(ThemeContext)


    const onEntering = () => setStatus('Expanding transcript...');

    const onEntered = () => setStatus('Collapse transcript');

    const onExiting = () => setStatus('Collapsing transcript...');

    const onExited = () => setStatus('Expand transcript');

    const toggle = () => setCollapse(!collapse);
    const collapseText = document.getElementById("myText");
    if (collapseText) collapseText.innerHTML = props.text;
    const enableButton = () => {
        if (props.onConfirm) {
            setChkBoxDisabled(true);
            props.onConfirm();
        }

    }
    return <>

        {theme === "light" ? (
            <div className="collapse-config">
                <div>
                    <Button
                        color="primary"
                        className="collupse-button"
                        onClick={toggle}
                    >
                        <span
                            className={`${stylesCollapseComponent.collapsTitleConfig} text-color-182C34`}
                        >
                            {status}
                        </span>
                        {collapse ? (
                            <img
                                src={window.location.origin + "/images/ArrowUp.png"}
                                alt="Arrow Up"
                                className="margin-left-6"
                            />
                        ) : (
                            <img
                                src={window.location.origin + "/images/ArrowDown.png"}
                                alt="Arrow Down"
                                className="margin-left-6"
                            />
                        )}
                    </Button>
                    <Collapse
                        isOpen={collapse}
                        onEntering={onEntering}
                        onEntered={onEntered}
                        onExiting={onExiting}
                        onExited={onExited}
                    >
                        <Card>
                            <CardBody>
                                <span id="myText"></span>
                            </CardBody>
                        </Card>
                        <div className="col-4 read-transcript-adjusment">
                            {!chkBoxDisabled && (
                                <input
                                    type="checkbox"
                                    name="mycheckbox"
                                    onClick={enableButton}
                                    disabled={chkBoxDisabled}
                                />
                            )}
                            {chkBoxDisabled && (
                                <img
                                    src={window.location.origin + "/images/checked.svg"}
                                    alt="right-icon"
                                    className={`${stylesCollapseComponent.checkedDisabledCheckbox}`}
                                />
                            )}
                            &nbsp;
                            <label>I have read the transcription</label>
                        </div>
                    </Collapse>
                </div>
            </div>
        ) : (
            <div className="collapseButton">
                <div>
                    <Button
                        color="primary"
                        className="collupse-button"
                        onClick={toggle}
                    >
                        <span
                            style={{ color: "#fff !important" }}
                            className={`${stylesCollapseComponent.collapsTitleConfig}`}
                        >
                            {status}
                        </span>

                        {collapse ? (
                            <img
                                src={window.location.origin + "/images/ArrowUp-dark.svg"}
                                alt="Arrow Up"
                                className="margin-left-6"
                            />
                        ) : (
                            <img
                                src={window.location.origin + "/images/ArrowDown-dark.svg"}
                                alt="Arrow Down"
                                className="margin-left-6"
                            />
                        )}
                    </Button>
                </div>
                <Collapse
                    isOpen={collapse}
                    onEntering={onEntering}
                    onEntered={onEntered}
                    onExiting={onExiting}
                    onExited={onExited}
                >
                    <div style={{ color: "#fff" }}>
                        <Card className="collapseCard">
                            <CardBody>
                                <span
                                    style={{ color: "#fff" }}
                                    id="myText"
                                ></span>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col-4 read-transcript-adjusment">
                        {!chkBoxDisabled && (
                            <input
                                type="checkbox"
                                name="mycheckbox"
                                onClick={enableButton}
                                disabled={chkBoxDisabled}
                            />
                        )}
                        {chkBoxDisabled && (
                            <img
                                src={window.location.origin + "/images/checked.svg"}
                                alt="right-icon"
                                className={`${stylesCollapseComponent.checkedDisabledCheckbox}`}
                            />
                        )}
                        &nbsp;
                        <label>I have read the transcription</label>
                    </div>
                </Collapse>
            </div>
        )}

    </>
}
export default CollapseComponent;