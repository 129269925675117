import {isTokenExpired,clearLocalStorageAndRedirect, getAuthToken} from "../Utils/Utils";

class DoService {

    async addIntention(data: string) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Profile/intention`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            });
            if (!response.ok) {
                throw new Error('Error!')
            } else {
                return { ok: true };
            }
        } catch (error) {
            return { ok: false };
        }
    };

    async introComplete() {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Program/introComplete`, {
                method: 'POST',
                headers: {
                    "Authorization": getAuthToken()
                }
            });
            if (!response.ok) {
                throw new Error('Error!')
            } else {
                return { ok: true };
            }
        } catch (error) {
            return { ok: false };
        }
    };
}
export default DoService;
