import React, { CSSProperties, useEffect, useState } from 'react';
import { Errors } from '../../../Utils/Enums';
import ChatMessageShellComponent from '../ChatMessageShell/ChatMessageShellComponent';
import ChatShellComponent from '../ChatShell/ChatShellComponent';
import IconDotsComponent from '../IconDots/IconDotsComponent';
import InputGroupComponent from '../InputGroup/InputGroupComponent';
import SubmitButtonComponent from '../SubmitButton/SubmitButtonComponent';
import TextComponent from '../Text/TextComponent';
import generalChatStyles from './GeneralChatMessagesComponent.module.css'
import ChatMessage from '../../../Services/ChatMessage.service';
import { useHistory } from "react-router";
import ConceptSummary from '../../../Services/ConceptSummary.service';
import Concept from '../../../Services/Concept.service';
import { isDesktop, isMobileOnly } from 'react-device-detect';
import ScorerModalComponent from "../ScorerMadal/ScorerModalComponent";
import { Modal, ModalHeader } from 'react-bootstrap';

export interface IChatQuestion {
    id: number;
    index: number;
    moduleId: number;
    text: string;
    userId: number;
    userAnswer: string;
    height?: CSSProperties | undefined;
    isLeft?: boolean;
    image?: string;
    enableContinue: boolean
};

export interface IChatAnswer {
    moduleQuestionId: number;
    moduleQuestionAnswer: string;
    data: IChatQuestion;
    image?: string;
}

interface IQuestionResult {
    ok: boolean;
    data: IChatQuestion;
};

interface IGeneralChatMessagesComponent {
    moduleId: number;
    buttonTitle?: string;
    buttonClass: string;
    messageLength: number;
    source: string;
}

const GeneralChatMessagesComponent = (props: IGeneralChatMessagesComponent) => {

    const conceptSummaryService = new ConceptSummary();
    const conceptService = new Concept();
    let history = useHistory();
    let chatMessage = new ChatMessage();
    let chatQuestion: IQuestionResult;
    // const conceptService = new Concept();

    const [ok, setOK] = useState('loading');
    const [question, setQuestion] = useState<IQuestionResult>();
    const [askQuestion, setAskQuestion] = useState(true);
    const [messageCounter, setMessageCounter] = useState(0);
    const [answer, setAnswer] = useState<IChatAnswer>();
    const [data, setData] = useState<any>(null);


    useEffect(() => {
        if ((!question?.data.enableContinue) || messageCounter === 0) {
            getData();
        }
        //eslint-disable-next-line
    }, [messageCounter]);

    const getData = async () => {
        let messageCounterValidator: number = 0;
        let dotsIndicator: any;
        dotsIndicator = document.getElementById("dotsIndicator");
        dotsIndicator && dotsIndicator.classList.remove('d-none');
        chatQuestion = await chatMessage.GetModuleQuestionByModuleId(props.moduleId, messageCounter);
        let moduleId: any = props.moduleId;
        let result = await conceptSummaryService.getModuleSummaryWithResource(moduleId);
        setData(result.data)
        setTimeout(function () {
            //eslint-disable-next-line
            messageCounterValidator = messageCounter;
            let itemHeight;
            let userPicture: string;
            setQuestion(chatQuestion);
            if (!chatQuestion.ok) {
                return setOK('failed');
            }
            setOK('success');

            const getUserDetails = async () => {
                let userDetails = localStorage.getItem("userConfig");
                if (userDetails) {
                    let parsedUserDetails = JSON.parse(userDetails);
                    userPicture = parsedUserDetails.profile;
                    chatQuestion.data.image = userPicture;
                }
            }
            getUserDetails();

            chatQuestion.data.isLeft = false;
            setTedFormNode('.chatQuestionClass');
            var clonnedElement = document.querySelectorAll(".chatQuestionClass:last-child")[0];
            clonnedElement.id = "chatFormQuestion" + chatQuestion?.data?.id;
            clonnedElement.classList.remove('d-none');
            itemHeight = calculateFreeTextHight(chatQuestion.data.text);
            let elQuestion = document.getElementById("chatFormQuestion" + chatQuestion.data.id) as HTMLElement;
            if (elQuestion) {
                let chileEl = elQuestion.children[0] as HTMLElement;
                if (chileEl) chileEl.style.height = itemHeight * 16 + 'px';
            };
            setinnerWindowHeight(itemHeight);
            setAskQuestion(false);
            dotsIndicator && dotsIndicator.classList.add('d-none');
        }, 3000);
    }

    //eslint-disable-next-line
    const disableElement = (elName: string) => {
        let elToDisable = document.querySelectorAll(elName);
        if (elToDisable) elToDisable.forEach((x: any) => {
            x.style.pointerEvents = "none";
            x.classList.remove('cursor-pointer');
        })
    }

    const completeHandler = () => {

    }


    /* Set dynamic height of inner window */
    const setinnerWindowHeight = (itemHeight: number | undefined) => {
        const innerWindow = document.getElementById('inner-window');
        let currentHeight = innerWindow?.offsetHeight;
        if (innerWindow && itemHeight && currentHeight) {
            if (currentHeight > 534)
                innerWindow.style.height = currentHeight + itemHeight * 16 + "px";
            setTimeout(function () {
                innerWindow.scrollIntoView({ behavior: "smooth", block: "end" });
            }, 2000);
        }
    }

    /* Crete free text node */
    const setTedFormNode = (className1: string) => {
        const tedFormElement = document.querySelector(className1);
        if (tedFormElement) {
            let newTedForm: Node = tedFormElement.cloneNode(true);
            let innerWindow: any;
            innerWindow = document.getElementById('inner-window');
            innerWindow.appendChild(newTedForm);
        }

    }

    /* Adding free text answer */
    const sendDataHandler = async (data: string) => {
        let userPicture: any;
        let userDetails = localStorage.getItem("userConfig");
        if (userDetails) {
            let parsedUserDetails = JSON.parse(userDetails);
            userPicture = parsedUserDetails.profileImg;
            //chatQuestion.data.image = userPicture;
        }
        if (question) {
            if (props.source === "summary") {
                let setoduleQuestionUserResponse = await conceptSummaryService.setModuleQuestionUserResponse(question.data.id, data);
                if (!setoduleQuestionUserResponse.ok) {
                    return setOK('failed');
                }
            } else {
                let quizAnserAPI = await conceptService.tedUserResponse(question.data.id, data);
                if (!quizAnserAPI.ok) {
                    return setOK('failed');
                }
            }
            let tempAnswerItem: any = { ...question.data };
            tempAnswerItem.isLeft = true;
            tempAnswerItem.text = data;
            tempAnswerItem.image = userPicture;

            setAnswer({
                moduleQuestionId: question.data.id,
                moduleQuestionAnswer: data,
                data: tempAnswerItem,
                image: userPicture ? userPicture.replace("/api", "") : ""
            });
            setTimeout(function () {
                if (tempAnswerItem) {

                    setTedFormNode('.chatFormAnswer');
                    var clonnedElement = document.querySelectorAll(".chatFormAnswer:last-child")[0];
                    clonnedElement.classList.remove('d-none');
                    clonnedElement.id = "chatFormAnswer" + question.data.id;
                    let itemHeight = calculateFreeTextHight(data);
                    let questionEl = document.getElementById("chatFormQuestion" + question.data.id);
                    let elAnswer = document.getElementById("chatFormAnswer" + String(question.data.id));
                    if (elAnswer) {
                        questionEl?.after(elAnswer)
                        let chileEl = elAnswer.children[0] as HTMLElement;
                        if (chileEl) chileEl.style.height = itemHeight * 16 + 'px';
                    };
                    setinnerWindowHeight(itemHeight);
                    setAskQuestion(true);
                    setMessageCounter(messageCounter + 1);
                }
            }, 2000);
        }
    };

    const finishChat = async () => {
        if (props.source === 'summary') {
            //     //await conceptSummaryService.finishModule(props.moduleId);
        }
        //history.push("/dashboard");
    }

    /* Calculate free text hight */
    const calculateFreeTextHight = (text: string) => {
        var textToCalc: string = text;
        var spacesHight = 48 //paddings from top and bottom (16*2) and one margin (16*1)
        var numberOfCharsPerLine: number;
        var stringForTest = "";
        var splittedExpression = text.split(' ');
        var sumPerLine: number = 0;
        var numberOfLines: number = 1;

        if (isMobileOnly && (window.innerHeight > window.innerWidth)) {
            numberOfCharsPerLine = 25
            spacesHight = 64
            for (var x = 0; x < splittedExpression.length; x++) {
                if (splittedExpression[x].length > 15) numberOfLines += 1;
                if (((sumPerLine + splittedExpression[x].length) > numberOfCharsPerLine) && x < (splittedExpression.length - 1)) {
                    numberOfLines += 1;
                    sumPerLine = splittedExpression[x].trim().length + 1;
                    stringForTest = splittedExpression[x] + " ";
                } else {
                    if ((sumPerLine + splittedExpression[x].length) <= numberOfCharsPerLine) {
                        sumPerLine += splittedExpression[x].length + 1;
                        stringForTest += splittedExpression[x] + " ";
                    } else {
                        sumPerLine += (splittedExpression[x].length);
                        stringForTest += (splittedExpression[x]);
                    }
                }
            }
            if (sumPerLine <= numberOfCharsPerLine) {
                numberOfLines += 1;
            }
        } else {
            numberOfCharsPerLine = 34;
            for (var x = 0; x < splittedExpression.length; x++) {
                if (splittedExpression[x].length > 15) numberOfLines += 1;
                if (((sumPerLine + splittedExpression[x].length) > numberOfCharsPerLine) && x < (splittedExpression.length - 1)) {
                    numberOfLines += 1;
                    sumPerLine = splittedExpression[x].trim().length + 1;
                    stringForTest = splittedExpression[x] + " ";
                } else {
                    if ((sumPerLine + splittedExpression[x].length) <= numberOfCharsPerLine) {
                        sumPerLine += splittedExpression[x].length + 1;
                        stringForTest += splittedExpression[x] + " ";
                    } else {
                        sumPerLine += (splittedExpression[x].length);
                        stringForTest += (splittedExpression[x]);
                    }
                }
            }
            if (sumPerLine <= numberOfCharsPerLine) {
                numberOfLines += 1;
            }
        }

        const textLineHeight = 26;
        const numberOfSpaces = (textToCalc.match(/<p>/g) || []).length; //Number of free lines <p></p>
        return (numberOfLines * textLineHeight + spacesHight + numberOfSpaces) / 16 + 1; //to make it em


    }

    return <>
        <ChatShellComponent customClass={'position-relative'}>
            <div className={`${generalChatStyles.warningHeight} ${ok === 'failed' && "alert alert-warning"} position-absolute top-0 w-100`} role="alert">
                {ok === 'failed' && <TextComponent text={Errors.SomethingWentWrong} />}
            </div>
            {!data?.isScoreGiven ? (
                <>
                    {question?.ok && <div className={`${isDesktop && "position-absolute"} w-100 overflow-y-auto ${generalChatStyles.marginTopShellMain}`}>
                        <div id='inner-window'
                            className={`min-height-540 d-flex justify-content-end flex-column ${generalChatStyles.chatScreenConfigThinkIntro}`}

                        >
                            <div
                                className={`position-relative context-quote chatQuestionClass d-none`}>
                                <div className="position-relative">
                                    <div className={`position-absolute chatmessageShellConfig`}>
                                        <ChatMessageShellComponent
                                            text={question.data?.text}
                                            icon={'Ted.png'}
                                            id={question.data.id}
                                            left={false} />
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`position-relative context-quote chatFormAnswer d-none`}
                            >
                                <div className="position-relative">
                                    <div className={`position-absolute chatmessageShellConfig`}>
                                        <ChatMessageShellComponent
                                            text={answer && answer.data?.text}
                                            icon={answer && answer?.image}
                                            id={answer && answer.data.id}
                                            left={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}</>
            ) : ''}
            <div className="position-absolute bottom-0 w-100" id="shell-footer">
                <div className="height-34 margin-bottom-4">
                    {!data?.isScoreGiven ? <div id="dotsIndicator" className={`d-flex justify-content-center w-100`} >
                        <IconDotsComponent />
                    </div> : ''}
                </div>
                {<InputGroupComponent
                    placeholder={askQuestion ? '' : 'Answer'}
                    disabled={askQuestion}
                    onValidationHandler={sendDataHandler} />}
            </div>
        </ChatShellComponent>
        <div onClick={completeHandler} className={props.buttonClass}>
            <div onClick={finishChat}>
                {!data?.isScoreGiven ? (
                    <div>
                        <ScorerModalComponent
                            title={props.buttonTitle}
                            moduleStepId={data?.engageStepId}
                            isFromEngageCard={true}
                            disabled={!question?.data.enableContinue || !askQuestion}
                            customClassName={"moduleSummery"}
                        />
                    </div>
                ) : (
                    <ScorerModalComponent
                        title={props.buttonTitle}
                        moduleStepId={data?.engageStepId}
                        isFromEngageCard={true}
                        disabled={!question?.data.enableContinue || !askQuestion}
                        customClassName={"moduleSummery"}
                    />
                )}
                {/* <SubmitButtonComponent
                    label={props.buttonTitle}
                    customClassName={!data?.isScoreGiven ? 'btn-primary-custom' : 'btn-secondary-custom'}
                    id={'tedConceptSubmit'}
                    disabled={!question?.data.enableContinue || !askQuestion}
                /> */}
            </div>

        </div>


    </>
}
export default GeneralChatMessagesComponent
