import { ISurveyChartResponse } from "../interfaces/Charts/ISurveyCharts";
import Config from "../Utils/Config";
import {isTokenExpired,clearLocalStorageAndRedirect, getAuthToken} from "../Utils/Utils";

class Chartservices {
    async getSurveyChartData(surveyId:number){
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            try{
                const response = await fetch(Config.surveyCharts+"?surveyId="+surveyId,{
                    method:'GET',
                    headers:{
                        "accept": "text/plain",
                        'Content-Type': 'application/json',
                        "Authorization": getAuthToken()
                    }
                });
                if(!response.ok){
                    return {data:null,message:"Some thing is wrong",status:false}
                }
                else{
                    const result = await response.json();
                    if(result){
                        return result as ISurveyChartResponse;
                    }
                    else{
                        return {data:null,message:"Some thing is wrong",status:false}
                    }
                }
            }
            catch(error){
                return {data:null,message:"Some thing is wrong",status:false}
            }
        }
    }
}

export default Chartservices;