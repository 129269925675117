const constant = {

    //authtoken - from localStorage
    authToken: "Bearer " + localStorage.getItem("API_Token"),

    //user-login
    login: "/Account/AuthenticateUserUpdated",

    //user
    userDetails: `${process.env.REACT_APP_BASE_URL}/user`,
    organisationUser: `${process.env.REACT_APP_BASE_URL}/User/OrganisationUser`,
    resendEmailNotification: `${process.env.REACT_APP_BASE_URL}/Account/ResendEmailConfirmation`,    
    userOrganisations:`${process.env.REACT_APP_BASE_URL}/User/GetUserOrganisations`, 
    updateUserSelectedOrganisation:`${process.env.REACT_APP_BASE_URL}/User/UpdateUserSelectedOrganisation`,

    //chat message service
    getModuleQuestionByModuleId: `${process.env.REACT_APP_BASE_URL}/Module/GetModuleQuestionByModuleId`,
    moduleQuestionUserResponse: `${process.env.REACT_APP_BASE_URL}/Module/ModuleQuestionUserResponse`,

    //concept 
    getConcept: `${process.env.REACT_APP_BASE_URL}/Concept`,
    getTed: `${process.env.REACT_APP_BASE_URL}/Ted`,
    getTedUserResponse: `${process.env.REACT_APP_BASE_URL}/Ted/TedUserResponseForPractice`,
    tedUserResponseForPractice: `${process.env.REACT_APP_BASE_URL}/Ted/TedUserResponseForPractice`,
    tedUserResponseForCharacteristic: `${process.env.REACT_APP_BASE_URL}/Ted/TedUserResponseForCharacteristic`,

    //lookup
    lookupGroups: `${process.env.REACT_APP_BASE_URL}/Lookup/Groups`,
    lookupCountries: `${process.env.REACT_APP_BASE_URL}/Lookup/Countries`,
    lookupStates: `${process.env.REACT_APP_BASE_URL}/Lookup/States`,
    lookupLicenceTypes: `${process.env.REACT_APP_BASE_URL}/Lookup/LicenceTypes`,
    lookupParticipantByOrgnisation: `${process.env.REACT_APP_BASE_URL}/Lookup/ParticipantByOrgnisation`,
    lookupFacilatorByOrgnisation: `${process.env.REACT_APP_BASE_URL}/Lookup/FacilatorByOrgnisation`,
    lookupProgramsList: `${process.env.REACT_APP_BASE_URL}/Lookup/ProgramsList`,
    lookupProgramsModule: `${process.env.REACT_APP_BASE_URL}/Lookup/ProgramModules`,
    lookupProgramsModuleSteps: `${process.env.REACT_APP_BASE_URL}/Lookup/ModuleSteps`,
    lookupProgramsFaciltorGroup: `${process.env.REACT_APP_BASE_URL}/Lookup/FaciltorGroup`,
    lookupAttendanceStatus: `${process.env.REACT_APP_BASE_URL}/Lookup/AttendanceStatus`,
    lookupDecryptText: `${process.env.REACT_APP_BASE_URL}/Lookup/DecryptText`,
    lookupGetRelatedCategories: `${process.env.REACT_APP_BASE_URL}/Lookup/GetRelatedCategories`,
    lookupGetTedActivityCategory: `${process.env.REACT_APP_BASE_URL}/Lookup/GetTedActivityLogCategory`,
    lookupGetProgramMeetingUser: `${process.env.REACT_APP_BASE_URL}/Lookup/ProgramMeetingUsersByGroups`,
    lookupUserList: `${process.env.REACT_APP_BASE_URL}/Lookup/GetUserList`,
    lookupProgramStages: `${process.env.REACT_APP_BASE_URL}/Lookup/GetProgramStage`,
    lookupProgramSurveyList: `${process.env.REACT_APP_BASE_URL}/Lookup/GetProgramSurveyList`,

    //file upload
    bulkFileUpload: `${process.env.REACT_APP_BASE_URL}/User/UploadBulkUsers`,

    //groups
    getTotalGroups: `${process.env.REACT_APP_BASE_URL}/Group/OrgnisationGroups`,
    getGroupsByGroupsId: `${process.env.REACT_APP_BASE_URL}/Group/OrgnisationGroup`,
    createGroup: `${process.env.REACT_APP_BASE_URL}/Group/OrgnisationGroup`,
    deleteGroup: `${process.env.REACT_APP_BASE_URL}/Group/OrgnisationGroup`,
    updateGroup: `${process.env.REACT_APP_BASE_URL}/Group/OrganisationGroup`,

    //program meeting
    getProgramMeeting: `${process.env.REACT_APP_BASE_URL}/ProgramMeeting/ProgramMeetings`,
    createProgramMeeting: `${process.env.REACT_APP_BASE_URL}/ProgramMeeting/ProgramMeetings`,
    deleteProgramMeeting: `${process.env.REACT_APP_BASE_URL}/ProgramMeeting/ProgramMeeting`,
    updateProgramMeeting: `${process.env.REACT_APP_BASE_URL}/ProgramMeeting/ProgramMeetings`,
    getProgramMeetingAttendance: `${process.env.REACT_APP_BASE_URL}/ProgramMeeting/ProgramMeetingAttendance`,
    updateProgramMeetingAttendance: `${process.env.REACT_APP_BASE_URL}/ProgramMeeting/ProgramMeetingAttendance`,
    userMeetings: `${process.env.REACT_APP_BASE_URL}/ProgramMeeting/UserProgramMeetings`,

    //survey
    getSurveyStatus: `${process.env.REACT_APP_BASE_URL}/Survey/IsSurveyCompleted`,
    getSurevyQuestions: `${process.env.REACT_APP_BASE_URL}/Survey/SurveyQuestions`,
    createProgramSurvey: `${process.env.REACT_APP_BASE_URL}/Survey/ProgramSurvey`,
    getProgramSurvey: `${process.env.REACT_APP_BASE_URL}/Survey/ProgramSurvey`,
    getProgramSurveyById: `${process.env.REACT_APP_BASE_URL}/Survey/ProgramSurvey`,
    updateProgramSurvey: `${process.env.REACT_APP_BASE_URL}/Survey/ProgramSurvey`,
    deleteProgramSurvey: `${process.env.REACT_APP_BASE_URL}/Survey/ProgramSurvey`,
    submitProgramSurveyAnswer: `${process.env.REACT_APP_BASE_URL}/Survey/ProgramSurveyAnswer`,
    getProgramSurveyAnswerById: `${process.env.REACT_APP_BASE_URL}/Survey/ProgramSurveyAnswer`,
    updateProgramSurveyAnswer: `${process.env.REACT_APP_BASE_URL}/Survey/ProgramSurveyAnswer`,
    lockProgramSurvey: `${process.env.REACT_APP_BASE_URL}/Survey/LockProgramSurvey`,
    isSurveyCompleted: `${process.env.REACT_APP_BASE_URL}/Survey/IsSurveyCompleted`,
    submitSurvey: `${process.env.REACT_APP_BASE_URL}/Survey/SubmitProgramSurvey`,
    deleteProgramSurveyQuestion:`${process.env.REACT_APP_BASE_URL}/Survey/DeleteProgramSurveyQuestion`,
    editProgramSurveyQuestion:`${process.env.REACT_APP_BASE_URL}/Survey/EditProgramSurveyQuestion`,
    addProgramSurveyQuestion:`${process.env.REACT_APP_BASE_URL}/Survey/AddProgramSurveyQuestion`,
    programSurveyQuestions:`${process.env.REACT_APP_BASE_URL}/Survey/ProgramSurveyQuestions`,
    predefinedQuestionList:`${process.env.REACT_APP_BASE_URL}/Survey/PredefinedQuestionList`,
    addRemovePredefinedQuestion:`${process.env.REACT_APP_BASE_URL}/Survey/AddRemovePredefinedQuestion`,

    //notification
    getNotification: `${process.env.REACT_APP_BASE_URL}/Notification`,
    deleteNotification: `${process.env.REACT_APP_BASE_URL}/Notification`,
    unreadNotification: `${process.env.REACT_APP_BASE_URL}/Notification/unread`,
    readNotification: `${process.env.REACT_APP_BASE_URL}/Notification/read`,
    getAllReadNotifications: `${process.env.REACT_APP_BASE_URL}/Notification/getallread`,

    //UserPractice
    getUserPractice: `${process.env.REACT_APP_BASE_URL}/Practice/GetUserPracticeForProfile`,
    getPracticeList: `${process.env.REACT_APP_BASE_URL}/Practice/GetPracticeList`,
    addPractice: `${process.env.REACT_APP_BASE_URL}/Practice/AddPractice`,
    removePractice: `${process.env.REACT_APP_BASE_URL}/Practice/RemovePractice`,
    ratePractice: `${process.env.REACT_APP_BASE_URL}/Practice/ratePractice`,
    getPracticeLibraryList: `${process.env.REACT_APP_BASE_URL}/Practice/GetPracticeLibraryList`,
    setCurrentPractice: `${process.env.REACT_APP_BASE_URL}/Practice/setCurrent`,
    userModuleForPractice: `${process.env.REACT_APP_BASE_URL}/Practice/UserModuleListForPractice`,
    getClickedPractice: `${process.env.REACT_APP_BASE_URL}/Practice`,
    completePractice: `${process.env.REACT_APP_BASE_URL}/Practice/CompletePractice`,

    //score
    getPersonalScore: `${process.env.REACT_APP_BASE_URL}/Score/PersonalScore`,
    getProviderScore: `${process.env.REACT_APP_BASE_URL}/Score/ProviderScore`,
    isValidLink: `${process.env.REACT_APP_BASE_URL}/Score/IsValidLink`,
    postProviderScore: `${process.env.REACT_APP_BASE_URL}/Score/SendProviderScore`,
    scorerInvitation: `${process.env.REACT_APP_BASE_URL}api/Score/ScorerInvitation`,

    //TEDJournalEntry
    getJournalEntry: `${process.env.REACT_APP_BASE_URL}/TedJournalEntry/TedJournalEntryList`,
    addJournalEntery: `${process.env.REACT_APP_BASE_URL}/TedJournalEntry/TedJournalEntry`,
    getTedActivity: `${process.env.REACT_APP_BASE_URL}/TedJournalEntry/TedActivityLogById`,

    //quote
    saveUserQuote: `${process.env.REACT_APP_BASE_URL}/Quote/SaveUserQuote`,
    getLikedQuote: `${process.env.REACT_APP_BASE_URL}/Quote/UserLikedQuotes`,
    getQuoteLibraryList: `${process.env.REACT_APP_BASE_URL}/Quote/GetQuoteLibraryList`,
    saveConceptQuote: `${process.env.REACT_APP_BASE_URL}/Ted/TedUserResponse`,

    //moduleQuote
    saveModuleQuote: `${process.env.REACT_APP_BASE_URL}/Module/ModuleQuoteUserResponse`,

    //agreement-EULA
    acceptEULA: `${process.env.REACT_APP_BASE_URL}/User/SetEULA`,
    isEULAAccepted: `${process.env.REACT_APP_BASE_URL}/User/IsEULAAccepted`,

    //resource library
    resourceLibraryList: `${process.env.REACT_APP_BASE_URL}/Resource/GetAllResourceForUser`,
    userResourceList: `${process.env.REACT_APP_BASE_URL}/Resource/GetUserResourceList`,
    addResourceToFav: `${process.env.REACT_APP_BASE_URL}/Resource/AddResource`,

    //report 
    getMeetingAttendence: `${process.env.REACT_APP_BASE_URL}/Report/MeetingAttendance`,
    getIndividualModuleProgress: `${process.env.REACT_APP_BASE_URL}/Report/IndividualModuleProgress`,
    moduleProgressReport: `${process.env.REACT_APP_BASE_URL}/Report/ModuleProgress`,
    userScoreReport: `${process.env.REACT_APP_BASE_URL}/Report/UserScoreReport`,
    surveyCompletionReport: `${process.env.REACT_APP_BASE_URL}/Report/SurveyCompletionReport`,
    exportMeeting: `${process.env.REACT_APP_BASE_URL}/Report/ExportMeetingAttendance`,
    exportModuleProgress: `${process.env.REACT_APP_BASE_URL}/Report/ExportModuleProgress`,
    exportIndividualModuleProgress: `${process.env.REACT_APP_BASE_URL}/Report/ExportIndividualModuleProgress`,
    exportUserScoreReport: `${process.env.REACT_APP_BASE_URL}/Report/ExportUserScoreReport`,
    exportSurveyCompletionReport: `${process.env.REACT_APP_BASE_URL}/Report/ExportSurveyCompletionReport`,
    selectionPreferenceReport:`${process.env.REACT_APP_BASE_URL}/Report/SelectionPreferenceReport`,
    exportselectionPreferenceReport:`${process.env.REACT_APP_BASE_URL}/Report/ExportSelectionPreferenceReport`,
    getCoreValuesReport:`${process.env.REACT_APP_BASE_URL}/Report/CoreValues`,

    //user module step status
    userModuleStepsStatus: `${process.env.REACT_APP_BASE_URL}/Program/UserModuleStepsStatus`,
    
    //Notification hub Url
    notificationUserHub:`${process.env.REACT_APP_BASE_URL}/NotificationUserHub`,

    //Dashboard API Urls
    dashboardProgramStatus:`${process.env.REACT_APP_BASE_URL}/Dashboard/GetDashboardProgramStatus`,
    scoreChartData:`${process.env.REACT_APP_BASE_URL}/Dashboard/GetScoreChartData`,
    moduleChartData:`${process.env.REACT_APP_BASE_URL}/Dashboard/GetModuleChartData`,
    practiceData:`${process.env.REACT_APP_BASE_URL}/Dashboard/GetPracticeData`,

    changePasswordAndConfirmEmail:`${process.env.REACT_APP_BASE_URL}/User/ChangePasswordAndConfirmedUser`,

    //Charts
    surveyCharts:`${process.env.REACT_APP_BASE_URL}/Survey/ProgramSurveyChartData`,

    //OpenSurvey
    openSurvey:`${process.env.REACT_APP_BASE_URL}/OpenSurvey/OpenSurvey`,
    startSurvey:`${process.env.REACT_APP_BASE_URL}/OpenSurvey/StartSurvey`,
    completeSurvey:`${process.env.REACT_APP_BASE_URL}/OpenSurvey/CompleteSurvey`,

    //User account and other settings
    userAccountDetails:`${process.env.REACT_APP_BASE_URL}/Account/AccountDetails`,
    changePassword:`${process.env.REACT_APP_BASE_URL}/Account/ChangePassword`,
    changeNotification:`${process.env.REACT_APP_BASE_URL}/User/ChangeUserNotification`,
    ChangeConceptAudioPreference:`${process.env.REACT_APP_BASE_URL}/User/ChangeConceptAudioPreference`,

    //User Public API
    IPv4:`https://api.ipify.org?format=json`,
    UniversalIP:`https://api64.ipify.org?format=json`, // Fallback to IPv4
    //url where icons need to be hidden
    url: ["/", "/thinkintro", "/programoverview", "/do", "/resetpassword", "/forgotpassword", "/engage/-1"],

    //export type for report
    type: ['Excel', 'Csv'],

    //ted log book category filter
    logBookFilter: [
        {
            id: 0,
            name: "All"
        },
        {
            id: 1,
            name: "Concepts"
        },
        {
            id: 2,
            name: "Practices"
        },
        {
            id: 3,
            name: "Reflections"
        },
        {
            id: 4,
            name: "Module Summary"
        },
    ],

    //report types for reporting
    reportTypes: [
        {
            id: 1,
            name: "Meeting Attendance Report"
        },
        {
            id: 2,
            name: "Individual Module progress Report"
        },
        {
            id: 3,
            name: "Module Progress Report"
        },
        {
            id: 4,
            name: "User Score Report"
        },
        {
            id: 5,
            name: "Survey Completion Report"
        },
        {
            id:6,
            name:"Selection Preference Report"
        },
        {
            id:7,
            name:"User Core Values Report"
        }
    ],    
}

export default constant;