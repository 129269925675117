import React, { useState, useContext } from 'react';
import CircleImageComponent from '../CircleImage/CircleImageComponent';
import { IPracticeViewModels } from '../Practices/PracticesComponent';
import TextComponent from '../Text/TextComponent';
import chatMessageStyles from './ChatMessageShellComponent.module.css';
import { ThemeContext } from "../../../Contexts/ThemeContext";
import Concept from '../../../Services/Concept.service';
import "../../../darkcustom.css";


interface IChatMessageShellComponent {
    icon?: string;
    left?: boolean;
    badge?: string;
    text?: string;
    id?: number;
    index?: number;
    conceptId?: number;
    messageType?: string;
    practiceId?: number;
    practiceViewModels?: IPracticeViewModels[];
    requireResponse?: boolean;
    enableContinue?: boolean;
    show?: boolean;
    author?: string;
    onTedShellHeartResponseHandler?: (id: number, userResponse: string, messageType: string) => void;
}
const ChatMessageShellComponent = (props: IChatMessageShellComponent) => {
    let tempNumber = Number(props.id);
    const conceptService = new Concept();
    const [clicked, setClicked] = useState(false);
    const { theme } = useContext(ThemeContext)

    const sendTedResponse = async (event: any) => {
        await conceptService.tedUserResponse(tempNumber, 'true');
        // props.onTedShellHeartResponseHandler && props.onTedShellHeartResponseHandler(tempNumber, 'true', "Quote");
        setClicked(true);
    }

    return <>
        {theme === "light" ? (
            <>
                {!props.left && !props.show &&
                    <div className={`filter-156-156-156-025 text-color-2D5362 background-FFFFFF d-flex flex-column margin-bottom-16 right-message`}>
                        <div className={`${chatMessageStyles['arrowLeft']} position-absolute color-FFFFFF`}>
                            <div className="position-relative">
                                {props.icon &&
                                    <div className={`${chatMessageStyles['tedConfigRight']} position-absolute`}>
                                        <CircleImageComponent size={'imageSize36'} image={'Ted.png'} />
                                    </div>}
                            </div>
                        </div>
                        {props.text && <TextComponent text={props.text} customClassName={'chatMessageText'} />}
                        {props.author && <TextComponent text={`- ${props.author}`} customClassName={'chatMessageText'} />}
                        <div className="d-flex cursor-pointer justify-content-end">
                            {!clicked && props.badge && <img id={props.text} src={window.location.origin + '/images/' + props.badge} className="cursor-pointer" onClick={sendTedResponse} alt="badge" />}
                            {clicked && <img src={window.location.origin + '/images/favourite-filled.svg'} alt="favourite-filled" />}
                        </div>
                    </div>}
                {props.left &&
                    <div className={`filter-156-156-156-025 text-color-2D5362 background-FFFFFF d-flex flex-column margin-bottom-16 left-message`}>

                        {props.text && <TextComponent text={props.text} customClassName={'chatMessageText'} />}
                        <div className={`${chatMessageStyles['arrowRight']} position-absolute color-FFFFFF`}>
                            <div className="position-relative">
                                <div className={`${chatMessageStyles['tedConfigLeft']} position-absolute`}>
                                    <CircleImageComponent size={'imageSize36'} image={String(props.icon)} />
                                </div>
                            </div>
                        </div>
                    </div>}
            </>
        ) : <>
            {!props.left
                && !props.show &&
                <div style={{ background: "#1c2930" }}
                    className={`filter-dark filter-156-156-156-025 text-color-2D5362 background-FFFFFF d-flex flex-column margin-bottom-16 right-message`}>
                    <div
                        style={{ color: "#1c2930" }} className={`${chatMessageStyles['arrowLeft']} position-absolute color-FFFFFF`}>
                        <div className="position-relative">
                            {props.icon &&
                                <div className={`${chatMessageStyles['tedConfigRight']} position-absolute`}>
                                    <CircleImageComponent size={'imageSize36'} image={'Ted.png'} />
                                </div>}
                        </div>
                    </div>
                    {props.text && <TextComponent text={props.text} customClassName={'chatMessageText'} />}
                    {props.author && <TextComponent text={`- ${props.author}`} customClassName={'chatMessageText'} />}
                    <div className="d-flex cursor-pointer justify-content-end">
                        {!clicked && props.badge && <img id={props.text} src={window.location.origin + '/images/' + props.badge} className="cursor-pointer" onClick={sendTedResponse} alt="badge" />}
                        {clicked && <img src={window.location.origin + '/images/favourite-filled.svg'} alt="favourite-filled" />}
                    </div>
                </div>}
            {props.left &&
                <div style={{ background: "#1c2930" }}
                    className={`filter-156-156-156-025 text-color-2D5362 background-FFFFFF d-flex flex-column margin-bottom-16 left-message`}>
                    {props.text &&
                        <TextComponent text={props.text} customClassName={'chatMessageText'} />}
                    <div style={{ color: "#1c2930" }} className={`${chatMessageStyles['arrowRight ']} position-absolute color-FFFFFF`}>
                        <div className="position-relative">
                            <div style={{ marginLeft: "18rem" }} className={`${chatMessageStyles['tedConfigLeft']} position-absolute`}>
                                <CircleImageComponent size={'imageSize36'} image={String(props.icon)} />
                            </div>
                        </div>
                    </div>
                </div>}
        </>}
    </>
}
export default ChatMessageShellComponent;