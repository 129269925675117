import React, { useEffect, useState, useContext } from "react";
import { TableColumn } from "react-data-table-component";
import TableComponent from "../Table/TableComponent";
import "./UserPracticeComponentsStyle/PracticeTableComponent.css";
import BootstrapModal from "../BootstrapModal/BootstrapModal";
import TextComponent from "../Text/TextComponent";
import PracticeLibraryExpandableRowComponent from "./PracticeLibraryExpandableRowComponent";
import { isMobileOnly } from 'react-device-detect';
import { ThemeContext } from "../../../Contexts/ThemeContext"
import "../../../darkcustom.css"

interface IpracticesData {
    practiceId: number
    title: string;
    summary: string;
    otherCurrent: number;
    otherCompleted: number;
    rating: number;
    isAdded: boolean;
    practiceStatus: string
}

interface Iprops {
    practiceData: any[];
    actionOnPractice: (id: number) => Promise<void>;
    currentPractice: (id: number) => Promise<void>;
    deletePractice?: any;
    getClickedPractice?: (practiceId: number) => Promise<void>
    selectedPractice?: any;
    setSelectedPractice?: any
}

function PracticeLibraryTableComponent(props: Iprops) {
    const { theme } = useContext(ThemeContext)
    const {
        practiceData,
        actionOnPractice,
        currentPractice,
        deletePractice,
        getClickedPractice,
        selectedPractice,
        setSelectedPractice
    } = props;

    const [url, setUrl] = useState<string>("");
    const [practiceTitleClicked, setPracticeTitleClicked] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);

    useEffect(() => {
        const getUrl: string = window.location.pathname;
        setUrl(getUrl);
        //eslint-disable-next-line
    }, []);

    let selectedPracticeModalBody: string = `<p><strong><u>Summary</u></strong></p>${selectedPractice?.summary || "<p>No Data Available</p>"}<p><strong><u>Instructions</u></strong></p>${selectedPractice?.instructions || "<p>No Data Available</p>"}`;
    const PracticesColumns: TableColumn<IpracticesData>[] = [
        {
            name: 'Description',
            selector: row => row.title,
            width: isMobileOnly ? 'auto' : '50%',
            cell: (row) => (
                <div className="mt-1 mb-1">
                    <div className="title" onClick={() => handlePracticeTitleClick(row)}>
                        {row.title}
                    </div>
                    <div className="description" data-tag="allowRowEvents">
                        {row.summary !== ""
                            ? <div dangerouslySetInnerHTML={{ __html: row.summary }}></div>
                            : "No Summary Found"
                        }
                    </div>
                </div>
            )
        },
        {
            name: "Others-Current",
            selector: (row) => row.otherCurrent,
            omit: isMobileOnly,
            cell: (row) => (
                <div className="numbers">
                    {row.otherCurrent}
                </div>
            )
        },
        {
            name: "Others-Completed",
            selector: (row) => row.otherCompleted,
            omit: isMobileOnly,
            cell: (row) => (
                <div className="numbers">
                    {row.otherCompleted}
                </div>
            )
        },
        {
            name: "Rating",
            selector: (row) => row.rating,
            sortable: true,
            omit: isMobileOnly,
            cell: (row) => (
                <div className="numbers">
                    {row.rating === null ? "NA" : row.rating}
                </div>
            )
        },
        {
            name: "Action",
            width: "87px",
            omit: isMobileOnly,
            cell: (row) => (
                <>
                    {url === "/practicelibrary" && (
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <div onClick={() => handleActionClick(row)}>
                                <img
                                    alt="favourite"
                                    src={
                                        theme === "light" ? (
                                            row.isAdded ? window.location.origin + "/images/favourite-filled.svg"
                                                : "/images/favourite-empty.svg"
                                        ) : row.isAdded ? window.location.origin + "/images/favourite-filled.svg"
                                                : "/images/favourite-empty-dark.svg"
                                    }
                                    width={"16px"}
                                    height={"16px"}
                                />
                            </div>
                            {theme === "light" ? (<div className="border-bottom" onClick={() => handleActionClick(row)}>
                                <TextComponent text="Add" customClassName="font-weight-600 added-text text-black" />
                            </div>
                            ) : <div className="border-bottom" onClick={() => handleActionClick(row)}>
                                    <TextComponent text="Add" customClassName="font-weight-600 added-text textColor" />
                                </div>}


                        </div>
                    )}
                </>
            ),
        },
    ];

    const handleActionClick = (row: any) => {
        actionOnPractice(row.practiceId);
    }

    const handlePracticeTitleClick = (row: any) => {
        setPracticeTitleClicked(true);
        setShow(true);
        if (getClickedPractice) {
            getClickedPractice(row.practiceId);
        }
    }

    const handleClose = () => {
        setShow(false);
        setSelectedPractice({
            id: 0,
            details: "",
            instructions: "",
            summary: "",
            title: ""
        });
    }

    const practiceLibraryExpandableRowComponent = PracticeLibraryExpandableRowComponent(theme, url, props);

    return (
        <>
            <div>
                <TableComponent
                    data={practiceData}
                    columns={PracticesColumns}
                    tableTitle={""}
                    totalRows={5}
                    rowsPerpage={10}
                    handlePageChange={<></>}
                    handleRowsPerPage={<></>}
                    pageAction={<></>}
                    isPagination={false}
                    expandableRows={isMobileOnly}
                    expandableRowsComponent={practiceLibraryExpandableRowComponent}
                    expandableRowsComponentProps={{ "url": url }}
                />
            </div>
            {
                practiceTitleClicked && <BootstrapModal
                    handleClose={handleClose}
                    show={show}
                    modalTitle={selectedPractice.title}
                    modalBody={selectedPracticeModalBody}
                    closeButtonText={"Close"}
                />
            }
        </>
    );
}

export default PracticeLibraryTableComponent;