import React, { useContext, useEffect, useReducer, useState } from 'react';
import HeaderComponent from '../../FunctionalComponents/Header/HeaderComponent';
import { Errors, Headers } from '../../../Utils/Enums';
import ContentShellComponent from '../../FunctionalComponents/ContentShell/ContentShellComponent';
import TextComponent from '../../FunctionalComponents/Text/TextComponent';
import Concept from '../../../Services/Concept.service';
import LoaderComponent from '../../FunctionalComponents/Loader/LoaderComponent';
import { IConceptItem } from '../../FunctionalComponents/ConceptItem/ConceptItemComponent';
import VideoPlayerComponent from '../../FunctionalComponents/VideoPlayer/VideoPlayerComponent';
import CollapseComponent from '../../FunctionalComponents/Collapse/CollapseComponent';
import conceptStyles from './ConceptComponent.module.css';
import GeneralChatComponent from '../../FunctionalComponents/GeneralChat/GeneralChatComponent';
import { useHistory } from 'react-router-dom';
import TEDModalComponent from '../../FunctionalComponents/TEDModal/TEDModalComponent';
import General from '../../../Services/General.service';
import { isMobileOnly } from 'react-device-detect';
import { IPracticeViewModels } from '../../FunctionalComponents/Practices/PracticesComponent';
import { logOutIfTokenExpire } from '../../../Utils/Utils';
import { UserContext } from '../../../Contexts/UserContext';
import ReactAudioPlayer from 'react-audio-player';
import { ThemeContext } from '../../../Contexts/ThemeContext';

const okReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'SetOKSuccess':
            return action.okSuccsess;
        case 'SetOKFailure':
            return action.okFailure;
        default:
            return { value: "loading", dataModule: null }
    }
}
interface IResult {
    ok: boolean;
    data: {
        id: number;
        title: string;
        image: string;
        video: string;
        darkThemeVideo: string;
        description: string;
        isQuiz: boolean;
        contentTitle: string;
        content: string;
        cardTitle: string;
        isCompleted: boolean;
        conceptWithIndexes: any[];
        tedMessages: [
            {
                id: number;
                text: string;
                messageType: string;
                answerOptions: [
                    string
                ];
                answer: string;
            }
        ]
    }
};


const ConceptComponent = (props: any) => {
    const generalService = new General();
    let history = useHistory();
    const conceptDetails = props.location.state;
    let tempString: string = String(sessionStorage.getItem("listOfConcepts"));
    let conceptsList: IConceptItem[] = JSON.parse(tempString);
    let result: IResult;
    const conceptId = props.match.params.id;
    const moduleStepId = props.match.params.moduleStepId;
    const moduleId = props.match.params.moduleId;
    const conceptService = new Concept();

    const [concepts, setConcepts] = useState<IConceptItem[]>(conceptsList);
    const [startTEDWindow, setStartTEDWindow] = useState(false);
    const [isVideo, setIsVideo] = useState(true);
    const [link, setLink] = useState('');
    const [okState, dispatchOK] = useReducer(okReducer, { value: "loading", dataModule: null });
    const [conceptParamId, setConceptParamId] = useState(props.match.params.id);
    const [practiceDetails, setPracticeDeatils] = useState<IPracticeViewModels>();
    const userContext = useContext(UserContext);
    const { theme } = useContext(ThemeContext);

    if (conceptParamId !== conceptId) {
        setConceptParamId(conceptId);
        window.location.reload();
    }
    useEffect(() => {
        logOutIfTokenExpire();
        userContext.dispatch('');
        getData();
    }, []);
    const getData = async () => {
        result = await conceptService.getConcept(conceptId);
        let currentIndex = result.data.conceptWithIndexes.findIndex((x: any) => x.id === Number(conceptId));
        let upNextIndex = result.data.conceptWithIndexes[Number(currentIndex) + 1];
        if (!upNextIndex) {
            setLink(`${"/conceptsummary/" + moduleId}`)
        } else {
            setLink(`${"/concept/" + upNextIndex.id + "/" + moduleStepId + "/" + moduleId}`);
        }

        if (!result) {
            return dispatchOK({ type: 'SetOKFailure', okFailure: { value: 'failed' } })
        };
        dispatchOK({ type: 'SetOKSuccess', okSuccsess: { value: 'success', data: result } });
        generalService.getIcons();

    }

    const returnVideo = () => {
        setIsVideo(true);
    }
    const manageVideoWindow = (tedMessageId: number, item: IPracticeViewModels, ticked: boolean) => {
        setIsVideo(false);
        setPracticeDeatils(item);
    }
    const startTedWindow = () => {
        if (isMobileOnly) {
            var myDiv = document.getElementById("concept-component-id");
            if (myDiv) {
                const scrollToBottom = (node: any) => {
                    node.scrollTop = node.scrollHeight;
                }
                scrollToBottom(myDiv);
            }
        } else {
            window.scrollTo(0, 0);
        }

        setStartTEDWindow(true);
    }
    const onUpNextHandler = () => {
        setStartTEDWindow(true);
        return history.push(link);
    }

    const handleRedirection = () => {
        window.history.back();
    }

    return <>
        {okState.value === 'loading' && <LoaderComponent />}
        <div>
            {okState.value === 'failed' &&
                <div className="mb-4">
                    <div className="alert alert-warning" role="alert">
                        <TextComponent text={Errors.SomethingWentWrong} />
                    </div>
                </div>}
        </div>
        {okState.value === 'success' && <div className="concept-component" id='concept-component-id'>
            <div className='d-flex justify-content-between border-bottom-EBEBEB-thin cursor-pointer' onClick={() => handleRedirection()}>
                {theme === "light" ? (
                    <HeaderComponent
                        title={okState.data.data.moduleHeading}
                        size={Headers.H1}
                        icon={'chevron-left.svg'}
                    />
                ) : <HeaderComponent
                    customClassName={"imageText"}
                    title={okState.data.data.moduleHeading}
                    size={Headers.H1}
                    icon={'left-chevron-dark.svg'}
                />}
            </div>

            <div className="d-flex justify-content-start padding-8 concept-select-config border-bottom-EBEBEB-thin margin-bottom-24">
                {okState.data.data.image && <img src={`${process.env.REACT_APP_BASE_URL_DOMAIN}` + okState.data.data.image} alt={"Concept Icon"} />}
                <div className="h5 align-self-center mb-0 margin-left-12">{conceptDetails?.conceptName || okState.data.data.title}</div>
            </div>
            <div className="margin-bottom-78">
                {!okState.data.data.isQuiz &&
                    <ContentShellComponent>
                        <div className={`d-xl-inline-flex justify-content-between ${conceptStyles.contentShellLeftConfig}`}>
                            <ContentShellComponent customClass={'leftSideConfig'}>
                                <div className="me-2 fw-bolder">
                                    <TextComponent text={`${okState.data.data.shortDescription}`} customClassName={"Tuvia text-color-2D5362"} />
                                </div>
                                <div className="margin-bottom-24 margin-top-24 overflow-y-auto">
                                    {isVideo &&                                         
                                        <VideoPlayerComponent
                                            playing={!startTEDWindow}
                                            local={true}
                                            muted={userContext.state.currentUser.isConceptAudioOn == true ? false : true}
                                            source={theme === "light" ? `${okState.data.data.video}` : `${okState.data.data.darkThemeVideo === null ? okState.data.data.video : okState.data.data.darkThemeVideo}`}
                                            onEndednHandler={startTedWindow} />}
                                    {!isVideo && <div>
                                        <div className={`${conceptStyles.detailsInstructionsConfig} w-100`}>
                                            <div dangerouslySetInnerHTML={{ __html: String(practiceDetails?.title) }} className="fw-bolder">
                                            </div>
                                            <div dangerouslySetInnerHTML={{ __html: String(practiceDetails?.summary) }}>
                                            </div>
                                            <div dangerouslySetInnerHTML={{ __html: String(practiceDetails?.instructions) }}>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </div>
                                {!isVideo && <div className="d-flex cursor-pointer margin-bottom-24">
                                    <img src={window.location.origin + "/images/Video.svg"} alt="right-icon" onClick={returnVideo} />
                                </div>}

                                <div className={`text-transform-uppercase margin-bottom-8 ${conceptStyles.transcriptConfig}`}>
                                    <div className="d-flex justify-content-between">
                                        <HeaderComponent
                                            title={'Transcript'}
                                            size={Headers.H3}
                                        />
                                    </div>
                                </div>

                                <CollapseComponent text={okState.data.data.content} customClassName={'margin-top-8'} onConfirm={startTedWindow} />

                            </ContentShellComponent>
                            <ContentShellComponent customClass={'rightSideConfig'}>
                                <GeneralChatComponent
                                    isQuiz={false}
                                    onUpNextHandler={onUpNextHandler}
                                    okState={okState}
                                    conceptDetails={conceptDetails}
                                    conceptId={conceptParamId}
                                    startTEDWindow={startTEDWindow}
                                    link={link}
                                    onTedPracticeDetailsHandler={manageVideoWindow}></GeneralChatComponent>
                            </ContentShellComponent>
                        </div>
                    </ContentShellComponent>}
                {okState.data.data.isQuiz &&
                    <ContentShellComponent>
                        <div className={`d-xl-inline-flex justify-content-between ${conceptStyles.contentShellLeftConfig}`}>
                            <ContentShellComponent customClass={'leftSideConfig'} >
                                <div className="margin-bottom-24">
                                    <span dangerouslySetInnerHTML={{ __html: okState.data.data.content }} />
                                </div>
                                <div>
                                    <div className="d-flex margin-top-16 margin-bottom-24">
                                        <div className='d-flex flex-column' dangerouslySetInnerHTML={{ __html: okState.data.data.description }} />
                                    </div>
                                </div>
                                {theme === "light" ? (
                                    <div className="padding-16 background-EFF8F7 border-radius-4">
                                        <div className="margin-bottom-8" >
                                            <span dangerouslySetInnerHTML={{ __html: okState.data.data.cardTitle }} />
                                        </div>
                                    </div>
                                ) : <div style={{ backgroundColor: "#28373E" }} className="padding-16 background-EFF8F7 border-radius-4">
                                    <div className="margin-bottom-8" >
                                        <span dangerouslySetInnerHTML={{ __html: okState.data.data.cardTitle }} />
                                    </div>
                                </div>}

                            </ContentShellComponent>
                            <ContentShellComponent customClass={'rightSideConfig'}>
                                <GeneralChatComponent
                                    isQuiz={true}
                                    onUpNextHandler={onUpNextHandler}
                                    okState={okState}
                                    conceptDetails={conceptDetails}
                                    conceptId={conceptParamId}
                                    startTEDWindow={true}
                                    link={link}
                                ></GeneralChatComponent>
                            </ContentShellComponent>
                        </div>
                    </ContentShellComponent>}
            </div>
        </div>
        }
        <div>
            <TEDModalComponent />
        </div>
    </>
}
export default ConceptComponent;