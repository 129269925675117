import React from "react";
import TextComponent from "../Text/TextComponent";
import "./ProfileCharacteristicComponent.css";

interface IProfileCharacteristicComponent {
    item: any;
}
const ProfileCharacteristicComponent = (props: IProfileCharacteristicComponent) => {
    return <>
        <div className="d-flex flex-column margin-bottom-24">
            <div className="width-140">
                <div className="badge rounded-pill bg-info d-flex">
                    <TextComponent text={props.item.characteristicOption} customClassName={"text-color-182C34 font-size-14 align-self-center"} />
                </div>
            </div>
        </div>
    </>
}
export default ProfileCharacteristicComponent;