interface IResetPassword {
    emailAddress: string;
    password: string;
    confirmPassword: string;
    code: string;
};
class ForgotPassword {

    async forgotPassword(emailAddress: string) {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Account/forgotpassword?emailAddress=` + emailAddress, {
            method: 'POST',
            headers: {
                "accept": '*/*',
                'Content-Type': 'application/json',
            }
        }).then((response: any) => response.json())
            .then((response: any) => {
                return response;
            }).catch((error: any) => {
                return error;
            });
        return response;
    }

    async resetPassword(dataReset: IResetPassword) {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Account/resetPassword`, {
            method: 'POST',
            body: JSON.stringify(dataReset),
            headers: {
                'Content-Type': 'application/json',
                'accept': '*/*'
            }
        }).then((response: any) => response.json())
            .then((response: any) => {
                return response;
            }).catch((error: any) => {
                return error;
            });
        return response;
    };
}
export default ForgotPassword;