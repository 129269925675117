import React, { useContext } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import "./TableComponent.css";
import { ThemeContext } from "../../../Contexts/ThemeContext";

interface IProps {
    data: any[];
    columns: TableColumn<any>[];
    tableTitle: string;
    pageAction: any;
    totalRows: number;
    handlePageChange: any;
    handleRowsPerPage: any;
    rowsPerpage: number;
    isPagination: boolean;
    paginationDefaultPage?: boolean;
    expandableRows?: boolean;
    expandableRowsComponent?: any;
    expandableRowsComponentProps?: any;
}

const paginationComponentOptions = {
    noRowsPerPage: false
};
const customStyles = {
    header: {
        style: {
            backgroundColor: '#1C2930',
            color: 'white',

        },
    },
    headRow: {
        style: {
            backgroundColor: '#28373E !important',
            color: 'white',
        },
    },
    cells: {
        style: {
            backgroundColor: '#1C2930',
            color: 'white',
        },
    },
    pagination: {
        style: {
            backgroundColor: "#1C2930",
            color: 'white',
        }
    }
}
const TableComponent = (props: IProps) => {
    let { theme } = useContext(ThemeContext)
    return (
        <div>
            {theme === "light" ? (
                <DataTable
                    title={props.tableTitle}
                    columns={props.columns}
                    data={props.data}
                    actions={props.pageAction}
                    pagination={props.isPagination}
                    responsive
                    paginationServer
                    selectableRows={false}
                    highlightOnHover
                    pointerOnHover
                    paginationResetDefaultPage={props.paginationDefaultPage == null ? false : props.paginationDefaultPage}
                    paginationTotalRows={props.totalRows}
                    onChangeRowsPerPage={(e) => props.handleRowsPerPage(e)}
                    onChangePage={(e) => props.handlePageChange(e)}
                    paginationComponentOptions={paginationComponentOptions}
                    paginationPerPage={props.rowsPerpage}
                    expandableRows={props.expandableRows}
                    expandableRowsComponent={props.expandableRowsComponent}
                    expandableRowsComponentProps={{ "header": "Header" }}
                />
            ) : <DataTable
                    customStyles={customStyles}
                    title={props.tableTitle}
                    columns={props.columns}
                    data={props.data}
                    actions={props.pageAction}
                    pagination={props.isPagination}
                    responsive
                    paginationServer
                    selectableRows={false}
                    highlightOnHover
                    pointerOnHover
                    paginationResetDefaultPage={props.paginationDefaultPage == null ? false : props.paginationDefaultPage}
                    paginationTotalRows={props.totalRows}
                    onChangeRowsPerPage={(e) => props.handleRowsPerPage(e)}
                    onChangePage={(e) => props.handlePageChange(e)}
                    paginationComponentOptions={paginationComponentOptions}
                    paginationPerPage={props.rowsPerpage}
                    expandableRows={props.expandableRows}
                    expandableRowsComponent={props.expandableRowsComponent}
                    expandableRowsComponentProps={{ "header": "Header" }}
                />}

        </div>

    );
};

export default TableComponent;
