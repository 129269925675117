import React, { useContext } from "react";
import { Headers } from "../../../Utils/Enums";
import HeaderComponent from "../Header/HeaderComponent";
import { Button, Dropdown, Form } from "react-bootstrap";
import {
    Card,
    CardBody,
    CardText,
    CardTitle,
    Col,
} from "reactstrap";
import { useHistory } from "react-router";
import "./PracticeCardComponent.css";
import { ThemeContext } from "../../../Contexts/ThemeContext"
import "../../../darkcustom.css"
interface Iprops {
    currentPracticeData: any;
    completePractice: (id: number) => Promise<void>;
    isCurrentPractice: boolean;
}
function PracticeCardComponent(props: Iprops) {
    let { theme } = useContext(ThemeContext)
    const { currentPracticeData, isCurrentPractice, completePractice } = props;

    let history = useHistory();

    const setPracticeText = "<p>Set a current practice from your playlist and press the continue button to access instructions and capture ongoing reflections on your practice. If you want to change your practice you can </p> <ul><li>Use the set function to swap your practice (return your current practice to your playlist)</li><li>Change your practice without scoring (your current practice will be marked as completed)</li><li>Complete and score your practice (score your practice and complete the Do step of your module)</li></ul>"

    const handleCardDropDown = (value: any, id: number) => {
        if (value === "Finish practice first") {
            history.push(`/singlepractice/${id}`);
        } else if (value === "Change without Finishing") {
            completePractice(id);
        }
    }

    const handleRedirection = (id: number) => {
        history.push(`/singlepractice/${id}`);
    }

    return (
        <>
            {currentPracticeData.length > 0 &&
                <div className="mb-3 d-flex flex-row justify-content-between align-items-center">
                    <HeaderComponent
                        title="CURRENT"
                        size={Headers.H3}
                    />
                    <div className="flex-row">
                        <Form.Group as={Col} xs={12} md={4} lg={2} controlId="formGridGroup" className="mt-1 w-100">
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary" className="text-black">
                                    Change
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => handleCardDropDown("Finish practice first", currentPracticeData[0]?.practiceId)}
                                    >
                                        Finish practice first
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={(e: any) => handleCardDropDown("Change without Finishing", currentPracticeData[0]?.practiceId)}
                                    >
                                        Change without Finishing
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form.Group>
                    </div>
                </div>
            }
            <div className="shadow-sm">
                <Col>

                    {theme === "light" ? (
                        <Card>
                            <CardBody>
                                <CardTitle>
                                    {
                                        currentPracticeData.length > 0
                                            ? currentPracticeData[0]?.title
                                            : "Set a current practice"
                                    }
                                </CardTitle>
                                <CardText>
                                    {
                                        currentPracticeData.length > 0
                                            ? <span dangerouslySetInnerHTML={{ __html: currentPracticeData[0]?.summary }} />
                                            : <span dangerouslySetInnerHTML={{ __html: setPracticeText }} />
                                    }
                                </CardText>
                                {
                                    (isCurrentPractice || currentPracticeData.length > 0) &&
                                    <div>
                                        <Button variant="primary" onClick={() => handleRedirection(currentPracticeData[0]?.practiceId)} type="button" role="button" className="font-weight-600 text-color-182C34">
                                            Continue
                                    </Button>
                                    </div>

                                }
                            </CardBody>
                        </Card>
                    ) :
                        <Card style={{ borderColor: "#28373E" }}>
                            <CardBody className="practiceCardBox" >
                                <CardTitle>
                                    {
                                        currentPracticeData.length > 0
                                            ? currentPracticeData[0]?.title
                                            : "Set a current practice"
                                    }
                                </CardTitle>
                                <CardText>
                                    {
                                        currentPracticeData.length > 0
                                            ? <span dangerouslySetInnerHTML={{ __html: currentPracticeData[0]?.summary }} />
                                            : <span dangerouslySetInnerHTML={{ __html: setPracticeText }} />
                                    }
                                </CardText>
                                {
                                    (isCurrentPractice || currentPracticeData.length > 0) &&
                                    <div>
                                        <Button variant="primary" onClick={() => handleRedirection(currentPracticeData[0]?.practiceId)} type="button" role="button" className="font-weight-600 text-color-182C34">
                                            Continue
                                    </Button>
                                    </div>

                                }
                            </CardBody>
                        </Card>
                    }

                </Col>
            </div>
        </>
    );
}

export default PracticeCardComponent;