import React, { useContext, useEffect, useState } from "react";
import ProgramMeeting from "../../../Services/ProgramMeeting.service";
import { Button } from 'react-bootstrap';
import Lookup from "../../../Services/Lookup.service";
import { Headers } from "../../../Utils/Enums";
import { Table, Form, Col } from 'react-bootstrap';
import HeaderComponent from "../../FunctionalComponents/Header/HeaderComponent";
import { createNotification } from "../../../Utils/Toast";
import "./MeetingAttendanceComponent.css"
import General from "../../../Services/General.service";
import { useHistory } from "react-router";
import { isClientAdmin, isMNFacilitator, logOutIfTokenExpire } from '../../../Utils/Utils';
import { UserContext } from "../../../Contexts/UserContext";
import { ThemeContext } from "../../../Contexts/ThemeContext";
import "../../../darkcustom.css"

interface IattendanceStatus {
    id: number;
    name: string
}

function MeetingAttendanceComponent(props: any) {

    const generalService = new General();
    generalService.getIcons();
    const meetingDetails = props?.location?.state?.row;
    const attendee: any[] = props?.location?.state?.data;

    const lookup = new Lookup();
    let history = useHistory();
    const programMeeting = new ProgramMeeting();
    const [attendanceStatus, setAttendanceStatus] = useState<any[]>([]);
    const [attendanceSubmitData, setAttendanceSubmitData] = useState<any[]>([]);
    const userContext = useContext(UserContext);
    const { theme } = useContext(ThemeContext)
    useEffect(() => {
        logOutIfTokenExpire();
        userContext.dispatch('');
        getAttendacenStatus();
        //eslint-disable-next-line
    }, []);

    const getAttendacenStatus = async () => {
        let response: any = await lookup.getAttendanceStatus();
        if (response.status) {
            setAttendanceStatus(response.data);
        }
    }
    const handleAttendanceStatus = async (value: string, data: any) => {
        let tempUser = [...attendee];
        //eslint-disable-next-line
        tempUser.map((item: any) => {
            if (item.userId === data.userId) {
                item.attendanceStatus = value;
            }
        });
        setAttendanceSubmitData(tempUser);
    }

    const handleLockAttendence = async () => {
        let requestData: any[] = [];
        if (attendanceSubmitData.length !== 0) {
            requestData = attendanceSubmitData;
        } else {
            requestData = attendee;
        }
        let response: any = await programMeeting.updateProgramMeetingAttendance(meetingDetails?.meetingId.toString(), requestData);
        if (response.status) {
            createNotification("success", response.message);
            history.push("/clientadmindashboard");
        } else {
            createNotification("error", response.message);
        }
    }
    const handleRedirection = () => {
        if (isMNFacilitator()) {
            history.push("/mnfacilitatordashboard");
        }
        else if (isClientAdmin()) {
            history.push("/clientadmindashboard");
        }
        else {
            history.push("/facilitordashboard");
        }
    }

    return (
        <>
            <div className="d-flex flex-column">
                <div >
                    {theme === "light" ? (
                        <p className="font-weight-bold font-style header-margin">
                            <img onClick={() => handleRedirection()}
                                className="cursor-pointer" alt="redirection-back"
                                src={window.location.origin + "/images/chevron-left.svg"}
                            />
                            Meeting Attendance
                    </p>
                    ) : <p className="font-weight-bold font-style header-margin">
                            <img onClick={() => handleRedirection()}
                                className="cursor-pointer" alt="redirection-back"
                                src={window.location.origin + "/images/left-chevron-dark.svg"}
                            />
                            Meeting Attendance
                        </p>}
                    <hr className="horizontal-rule" />
                </div>
                <div className="meeting-details-wrapper">
                    {theme === "light" ? (
                        <>
                            <div className="mb-1 mt-3 meeting-name shadow-sm p-3 mb-5 bg-white rounded">
                                <HeaderComponent
                                    title={`Meeting Name: ${meetingDetails?.meetingName}`}
                                    size={Headers.H3}
                                />
                            </div>
                            <div className="mb-1 mt-3 meeting-date shadow-sm p-3 mb-5 bg-white rounded">
                                <HeaderComponent
                                    title={`Meeting Date: ${meetingDetails?.meetingDate}`}
                                    size={Headers.H3}
                                />
                            </div>
                        </>
                    ) : <>
                            <div className="mb-1 mt-3 meeting-name shadow-sm p-3 mb-5 bg-rgb(40,55,62) rounded meetingCard">
                                <HeaderComponent
                                    title={`Meeting Name: ${meetingDetails?.meetingName}`}
                                    size={Headers.H3}
                                />
                            </div>
                            <div className="mb-1 mt-3 meeting-date shadow-sm p-3 mb-5 bg-rgb(40, 55, 62) rounded meetingCard">
                                <HeaderComponent
                                    title={`Meeting Date: ${meetingDetails?.meetingDate}`}
                                    size={Headers.H3}
                                />
                            </div></>}
                </div>
            </div>
            <div>
                <div className="mb-3">
                    {theme === "light" ? (
                        <>
                            <p className="font-weight-normal user-header">List of Users</p>
                            <div>
                                <Table hover responsive>
                                    <thead style={{ borderBottom: "2px solid #FFFFFF" }}>
                                        <tr className="header-style">
                                            <th>Name</th>
                                            <th>Group</th>
                                            <th>Module</th>
                                            <th>Module Step</th>
                                            <th>Attendence</th>
                                        </tr>
                                    </thead>
                                    <tbody className="cell-style">
                                        {
                                            attendee?.map((data: any, index: number) => {
                                                return <tr key={index}>
                                                    <td>{data?.name}</td>
                                                    <td>{meetingDetails?.meetingGroupName}</td>
                                                    <td>{meetingDetails?.meetingModuleName}</td>
                                                    <td>{meetingDetails?.meetingModuleStepName}</td>
                                                    <td style={{ padding: "4px" }}>
                                                        <Form.Group as={Col} controlId="formGridGroup">
                                                            <Form.Select
                                                                className="cell-style"
                                                                onChange={(e: any) => {
                                                                    handleAttendanceStatus(e.target.value, data);
                                                                }}
                                                            >
                                                                {attendanceStatus?.map((attendanceStatus: IattendanceStatus, index: number) =>
                                                                    <option key={attendanceStatus.id} value={attendanceStatus.name}>{attendanceStatus.name === 'NotAnswerd' ? 'Not yet marked' : attendanceStatus.name}</option>
                                                                )}
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </Table>
                                <div className="d-flex justify-content-end">
                                    <Button onClick={() => handleLockAttendence()} variant="success">
                                        Submit Attendance
                        </Button>
                                </div>
                            </div>
                        </>
                    ) : <>
                            <p style={{ color: "#fff" }} className="font-weight-normal user-header">List of Users</p>
                            <div>
                                <Table hover responsive>
                                    <thead style={{ borderBottom: "2px solid #FFFFFF" }}>

                                        <tr className="meetingTable header-style">
                                            <th>Name</th>
                                            <th>Group</th>
                                            <th>Module</th>
                                            <th>Module Step</th>
                                            <th>Attendence</th>
                                        </tr>
                                    </thead>
                                    <tbody className="cell-style">
                                        {
                                            attendee?.map((data: any, index: number) => {
                                                return <tr style={{ color: "#fff" }} key={index}>
                                                    <td>{data?.name}</td>
                                                    <td>{meetingDetails?.meetingGroupName}</td>
                                                    <td>{meetingDetails?.meetingModuleName}</td>
                                                    <td>{meetingDetails?.meetingModuleStepName}</td>
                                                    <td style={{ padding: "4px" }}>
                                                        <Form.Group as={Col} controlId="formGridGroup">
                                                            <Form.Select
                                                                className="cell-style"
                                                                onChange={(e: any) => {
                                                                    handleAttendanceStatus(e.target.value, data);
                                                                }}
                                                            >
                                                                {attendanceStatus?.map((attendanceStatus: IattendanceStatus, index: number) =>
                                                                    <option key={attendanceStatus.id} value={attendanceStatus.name}>{attendanceStatus.name === 'NotAnswerd' ? 'Not yet marked' : attendanceStatus.name}</option>
                                                                )}
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </Table>
                                <div className="d-flex justify-content-end">
                                    <Button onClick={() => handleLockAttendence()} variant="success">
                                        Submit Attendance
                        </Button>
                                </div>
                            </div>
                        </>}
                </div>

            </div>
        </>
    );
}

export default MeetingAttendanceComponent;