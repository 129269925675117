import React, { useContext } from "react";
import { ThemeContext } from "../../../Contexts/ThemeContext";
import "../../../darkcustom.css"

function TEDJournalSearchComponent(props: any) {
    const { handleInput, userInput, handleSearch } = props;
    const { theme } = useContext(ThemeContext);

    const handleSearchClick = () => {
        handleSearch();
    }
    return (
        <>
            <label htmlFor="basic-url" className="form-label">Search for a word or phrase</label>
            <div className="input-group mb-3">
                {theme === "light" ? (
                    <input
                        role="textbox"
                        type="text"
                        placeholder="Search"
                        className="form-control"
                        id="basic-url"
                        aria-describedby="basic-addon3"
                        value={userInput || ""}
                        onChange={(e: any) => handleInput(e.target.value)}
                    />
                ) :
                    <input
                        role="textbox"
                        type="text"
                        placeholder="Search"
                        className="form-control searchBox"
                        id="basic-url"
                        aria-describedby="basic-addon3"
                        value={userInput || ""}
                        onChange={(e: any) => handleInput(e.target.value)}
                    />}
                <span className="input-group-text cursor-pointer" id="basic-addon3" onClick={() => handleSearchClick()}>Search</span>
            </div>
        </>
    );
}

export default TEDJournalSearchComponent;

