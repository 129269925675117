import React, { useContext, useEffect, useReducer } from 'react';
import { UserContext } from '../../../Contexts/UserContext';
import ConceptSummary from '../../../Services/ConceptSummary.service';
import { Errors } from '../../../Utils/Enums';
import LoaderComponent from '../Loader/LoaderComponent';
import TextComponent from '../Text/TextComponent';
import './ContentComponent.css';
import { ThemeContext } from "../../../Contexts/ThemeContext";

const okReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'SetOKSuccess':
            return action.okSuccsess;
        case 'SetOKFailure':
            return action.okFailure;
        default:
            return { value: "loading", dataModule: null }
    }
}
const ContentComponent = (props: any) => {
    const [okState, dispatchOK] = useReducer(okReducer, { value: "loading", dataModule: null });
    const contentId = props.match.params.id;
    const conceptSummaryService = new ConceptSummary();
    const userContext = useContext(UserContext);
    const { theme } = useContext(ThemeContext);

    useEffect(() => {
        userContext.dispatch('');
        getData();
        //eslint-disable-next-line
    }, []);
    const getData = async () => {
        let result = await conceptSummaryService.getContent(contentId);
        if (!result) {
            return dispatchOK({ type: 'SetOKFailure', okFailure: { value: 'failed' } })
        };
        dispatchOK({ type: 'SetOKSuccess', okSuccsess: { value: 'success', data: result } });
    }
    return <>
        {okState.value === 'loading' && <LoaderComponent />}
        <div>
            {okState.value === 'failed' &&
                <div className="mb-4">
                    <div className="alert alert-warning" role="alert">
                        <TextComponent text={Errors.SomethingWentWrong} />
                    </div>
                </div>}
        </div>
        {okState.value === 'success' &&

            <div className="concept-component">
                <div dangerouslySetInnerHTML={{ __html: okState.data.data }}></div>
            </div>

        }

    </>
}
export default ContentComponent;