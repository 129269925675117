
import React, { useEffect, useState, useReducer, useContext } from 'react';
import Dashboard from '../../../Services/Dashboard.service';
import { useHistory } from 'react-router-dom';
import ContentShellComponent from '../../FunctionalComponents/ContentShell/ContentShellComponent';
import TextComponent from '../../FunctionalComponents/Text/TextComponent';
import { Errors, Headers } from '../../../Utils/Enums';
import EasyCardComponent from '../../FunctionalComponents/EasyCard/EasyCardComponent';
import HeaderComponent from '../../FunctionalComponents/Header/HeaderComponent';
import BreadcrumbComponent, { IStages } from '../../FunctionalComponents/Breadcrumb/BreadcrumbComponent';
import TabsComponent, { ITabsComponent } from '../../FunctionalComponents/Tabs/TabsComponent';
import ProgramOverview from '../ProgramOverview/ProgramOverviewComponent';
import DashboardCardsListComponent from '../../FunctionalComponents/DashboardCardsList/DashboardCardsListComponent';
import LoaderComponent from '../../FunctionalComponents/Loader/LoaderComponent';
import General from '../../../Services/General.service';
import TEDModalComponent from '../../FunctionalComponents/TEDModal/TEDModalComponent';
import { createNotification } from '../../../Utils/Toast';
import Quotes from '../../../Services/Quotes.service';
import "./DashboardComponent.css";
import UserPractice from '../../../Services/UserPractice.service';
import Survey from '../../../Services/Survey.service';
import { logOutIfTokenExpire, isMNFacilitator } from '../../../Utils/Utils';
import { UserContext, } from '../../../Contexts/UserContext';
import { ThemeContext } from '../../../Contexts/ThemeContext';

export interface ICollectedDashBoardData {
    intention: string;
    quote: string;
}

interface IquoteData {
    tedQuoteId: any,
    isLike: boolean
}
const okReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'SetOKSuccess':
            return action.okSuccsess;
        case 'SetOKFailure':
            return action.okFailure;
        default:
            return { value: "loading", dataModule: null }
    }
}
const DashboardComponent = () => {

    let history = useHistory();
    const { theme } = useContext(ThemeContext);
    const userContext = useContext(UserContext);
    if (isMNFacilitator()) {
        history.push("/mnfacilitatordashboard");
    }
    let seltab: string = "Overview";
    const survey = new Survey();
    const practice = new UserPractice();
    const dashboard = new Dashboard();
    const generalService = new General();
    const quote = new Quotes();
    let pathname = window.location.pathname.slice(1);
    if (localStorage.getItem("selectedTab") !== '') {
        seltab = String(localStorage.getItem("selectedTab"));
    }
    const [selectedTab, setSelectedTab] = useState(seltab);
    const [intentionAndQuote, setIntentionAndQuote] = useState({ intention: "", quote: "", quoteId: 0, isUserLikedQuote: false, quoteAuthor: "" });
    const [stages, setStages] = useState<IStages[]>([]);
    const [stage, setStage] = useState<string>(String(localStorage.getItem('selectedStage')));
    const [okState, dispatchOK] = useReducer(okReducer, { value: "loading", dataModule: null });
    const [quoteNotFound, setQuoteNotFound] = useState<boolean>(false);
    const [quoteLiked, setQuoteLiked] = useState<boolean>(false);
    const [isPracticeLibraryHasData, setIsPracticeLibraryHasData] = useState<boolean>(false);
    let dashboardStages = []
    let stage1Tabs = okState?.data?.data?.currentProgram?.stages?.find((x: ITabsComponent) => x.title === "Stage 1")?.modules;
    let stage2Tabs = okState?.data?.data?.currentProgram?.stages?.find((x: ITabsComponent) => x.title === "Stage 2")?.modules;
    dashboardStages.push(stage1Tabs, stage2Tabs);
    let arr = Array.prototype.concat.apply([], dashboardStages);
    let newUser = okState?.data?.data?.isIntroCompleted;

    useEffect(() => {
        userContext.dispatch('');
        return () => {
        }
    }, [])

    useEffect(() => {
        logOutIfTokenExpire();
        if (isMNFacilitator()) {
            //history.push("/mnfacilitatordashboard");
        }
        else {
            const surveyId = localStorage.getItem("SurveyId");
            getData();
            getPracticeLibrary();
            TabsClickHandler(String(localStorage.getItem("selectedTab")));
            if (Number(surveyId) !== 0) {
                history.push({
                    pathname: "/takesurvey",
                    search: `?s=${surveyId}`
                });
            }
        }
    }, [selectedTab]);

    const getData = async () => {
        let result: any = await dashboard.getProgramState();
        if (!result) return dispatchOK({ type: 'SetOKFailure', okFailure: { value: 'failed' } });
        var isStageExistInLocalStorage = await localStorage.getItem("selectedStage");
        if (!isStageExistInLocalStorage) {
            localStorage.setItem("selectedStage", 'Stage 1');
        }
        var isTabExistInLocalStorage = await localStorage.getItem("selectedTab");
        if (!isTabExistInLocalStorage) {
            localStorage.setItem("selectedTab", 'Awareness');
        }
        if (result.data.surveyId > 0) {
            let response: any = await survey.isSurveyCompleted(Number(result.data.surveyId));
            if (!response.status) {
                history.push({
                    pathname: "/takesurvey",
                    search: `?s=${result.data.surveyId}`
                });
            }
        }

        setStages(result.data.currentProgram?.stages);
        if (result) {
            dispatchOK({ type: 'SetOKSuccess', okSuccsess: { value: 'success', data: result } });
        }

        if (!result.data.isIntroCompleted || !result.data.isEmailConfirmed || !result.data.isEmailConfirmed) {
            history.push({
                pathname: '/programoverview',
                state: {
                    isDashboard: true,
                    isEmailConfirmed: result.data.isEmailConfirmed,
                    isAgreementAccepted: result.data.isEULAAccepted,
                    isIntroCompleted: result.data.isIntroCompleted
                }
            });
        } else {
            await generalService.getIcons();

            let intentionResult = pathname === "dashboard" && await generalService.getProfileIntention();
            let quoteResult = pathname === "dashboard" && await dashboard.getQuote(pathname);
            let quoteAndIntententionData = { intention: "", quote: "", quoteId: 0, isUserLikedQuote: false, quoteAuthor: "" };
            if (!intentionResult || !intentionResult.ok) {
                quoteAndIntententionData.intention = Errors.IntentionIsNotAvailable;
            } else {
                quoteAndIntententionData.intention = String(intentionResult?.data?.intention);
            };
            if (!quoteResult || !quoteResult.ok) {
                setQuoteNotFound(true);
                quoteAndIntententionData.quote = Errors.QuotesIsNotAvailable;
            } else {
                quoteAndIntententionData.quote = String(quoteResult.data.text);
                quoteAndIntententionData.quoteId = (quoteResult.data.id);
                quoteAndIntententionData.isUserLikedQuote = (quoteResult.data.isUserLiked);
                quoteAndIntententionData.quoteAuthor = (quoteResult.data.author);
            };
            setIntentionAndQuote(quoteAndIntententionData);
        }
    }

    /* Exeption error */
    const ErrorItemHandler = () => {
        return dispatchOK({ type: 'SetOKFailure', okFailure: { value: 'failed' } });
    }

    const TabsClickHandler = (tab: string) => {
        if (okState.value == 'success') {
            let moduleId = arr?.find((x: ITabsComponent) => x.title === tab)?.id;
            //let moduleId = okState?.data?.data?.currentProgram?.stages?.find((x: ITabsComponent) => x.title === String(localStorage.getItem('selectedStage') ?? 'Stage 1')).modules.find((x: ITabsComponent) => x.title === tab)?.id;
            localStorage.setItem("moduleId", moduleId);
        }
        localStorage.setItem("selectedTab", tab);
        setSelectedTab(tab);
    }

    const likeQuote = async (text: string, id: any) => {
        let quoteData: IquoteData = {
            tedQuoteId: id,
            isLike: true
        }
        if (intentionAndQuote.isUserLikedQuote) {
            return;
        } else {
            let response: any = await quote.saveQuote(quoteData);
            if (response.status) {
                createNotification("success", response.message);
                setQuoteLiked(true);
            } else {
                createNotification("error", response.message || "Something went wrong");
            }
        }
    }

    const getPracticeLibrary = async () => {
        let response: any = pathname === "dashboard" && await practice.getUserPracticeLibrary("", 0, 1, 10);
        if (response.status) {
            if (response.data.items.length > 0) {
                return setIsPracticeLibraryHasData(true);
            } else {
                return setIsPracticeLibraryHasData(false);
            }
        }
    }

    const clickedStage = async (stageName: string) => {
        localStorage.setItem("selectedStage", stageName);
        setStage(stageName);
        if (stageName === 'Stage 1') {
            TabsClickHandler('Awareness');
        } else {
            TabsClickHandler('Agility');
        }
    }

    return <>
        {okState.value === 'loading' && <LoaderComponent />}
        {okState.value === 'failed' && <div className="mb-4">
            <div className="alert alert-warning" role="alert">
                <TextComponent text={Errors.SomethingWentWrong} />
            </div>
        </div>}
        {okState.value === 'success' &&
            <div>
                <div className="margin-bottom-62">
                    <ContentShellComponent customClass={'d-xl-inline-flex justify-content-between mainShellConfig'}>
                        <ContentShellComponent customClass={'rightSideConfig'}>
                            <EasyCardComponent
                                currentUser={true}
                                title={"Inspirational Intention"}
                                text={intentionAndQuote.intention}
                                badge={theme === "light" ? "edit.svg" : "edit-dark.svg"}
                                link={'/do'}
                            />
                        </ContentShellComponent>
                        <ContentShellComponent customClass={'leftSideConfig'}>
                            <EasyCardComponent
                                currentUser={true}
                                title={"Quote of the week"}
                                text={intentionAndQuote.quote}
                                id={intentionAndQuote.quoteId}
                                badge={theme === "light" ? (
                                    quoteNotFound ? ""
                                        : quoteLiked ? "favourite-filled.svg"
                                            : intentionAndQuote.isUserLikedQuote ? "favourite-filled.svg"
                                                : "favourite-empty.svg"
                                ) : quoteNotFound ? ""
                                    : quoteLiked ? "favourite-filled.svg"
                                        : intentionAndQuote.isUserLikedQuote ? "favourite-filled.svg"
                                            : "favourite-empty-dark.svg"
                                }
                                likeQuote={likeQuote}
                                quoteAuthor={intentionAndQuote.quoteAuthor}
                            />
                        </ContentShellComponent>
                    </ContentShellComponent>
                </div>
                <div className="d-flex justify-content-between custom-program-title-flex">
                    <div>
                        <HeaderComponent
                            title={okState?.data?.data?.currentProgram?.title}
                            size={Headers.H1}
                        />
                    </div>
                </div>
                <div className="margin-bottom-24">
                    <div className="custom-tab-overflow">
                        <TabsComponent
                            tabs={newUser === true ? (arr) : (arr.find((x) => x?.title === String(localStorage.getItem('selectedStage'))))}
                            //tabs={okState?.data?.data?.currentProgram?.stages?.find((x: ITabsComponent) => x.title === String(localStorage.getItem('selectedStage')))?.modules}
                            customClass={'tabItemConfigBig'}
                            myTab={localStorage.getItem('selectedTab') ? String(localStorage.getItem('selectedTab')) : ''}
                            onTabSelectedHandler={TabsClickHandler}
                        />

                    </div>
                </div>

                {
                    (selectedTab === 'Overview') && <div>
                        <ProgramOverview />
                    </div>
                }

                {
                    selectedTab !== 'Overview' && <div>
                        <div className="margin-bottom-24">
                            <TextComponent text={'Think, Engage and Do are the 3 essential steps in the process for navigating your mind for success.'} />
                        </div>

                        <DashboardCardsListComponent
                            moduleId={Number(localStorage.getItem('moduleId'))}
                            selectedTab={String(localStorage.getItem("selectedTab"))}
                            //items={okState?.data?.data?.currentProgram?.stages?.find((x: ITabsComponent) => x.title === String(localStorage.getItem('selectedStage') ?? 'Stage 1')).modules.find((x: ITabsComponent) => x.title === selectedTab)?.moduleSteps}
                            items={arr.find((x: ITabsComponent) => x?.title === selectedTab)?.moduleSteps}
                            onErrorItemHandler={ErrorItemHandler}
                            isPracticeLibraryHasData={isPracticeLibraryHasData}
                        />
                    </div>
                }
            </div>}
        <div>
            <TEDModalComponent />
        </div>
    </>
}
export default DashboardComponent;