import { IAddUpdatePredefinedQuestionRequest, IPredefineSurveyQuestionResponse, IProgramSurveyAddEditQuestion, IProgramSurveyDetailResponse } from "../interfaces/ProgramSurvey/IProgramSurveyQuestion";
import {isTokenExpired,clearLocalStorageAndRedirect, getAuthToken, getQuerySringPreparedUrl, getRequestOptions} from "../Utils/Utils";
import constant from "../Utils/Config";
interface IcreateSurveyData {
    name: string;
    openDate: string;
    groupId: number;
    questions: any[];
}

interface IeditSurveyData {
    name: string;
    openDate: string;
    groupId: number;
    questions: any[];
}

interface IdeleteProgramSurvey {
    id: string
}

interface IgetProgramSurveyById {
    id: string
}

interface IsubmitProgramSurveyAnswer {
    userId: string,
    surveyProgramId: number,
    questionId: number,
    freeTextAnswer: string,
    optionsIds: any[],
}

interface IupdateProgramSurveyAnswer {
    userId: string,
    surveyProgramId: number,
    questionId: number,
    freeTextAnswer: string,
    optionsIds: any[],
}
class Survey {
    async getSurveyQuestions() {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Survey/SurveyQuestions`, {
                method: 'GET',
                headers: {
                    'accept': 'text/plain',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                })
                .catch((error: any) => {
                    return error;
                })
            return response;
        }        
    };

    async createSurvey(createSurveyData: IcreateSurveyData) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Survey/ProgramSurvey`, {
                method: 'POST',
                body: JSON.stringify(createSurveyData),
                headers: {
                    "accept": "text/plain",
                    "content-type": "application/ json",
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                })
                .catch((error: any) => {
                    return error;
                })
            return response;
        }        
    }

    async getProgramSurvey(pageNumber: number, pageSize: number,searchTerm:string) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const obj = {
                pageNumber:pageNumber,
                pageSize:pageSize,
                searchTerm:searchTerm
            }
            const response = await fetch(getQuerySringPreparedUrl(`${process.env.REACT_APP_BASE_URL}/Survey/ProgramSurvey`,obj), {
                method: 'GET',
                headers: {
                    'accept': 'text/plain',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                })
                .catch((error: any) => {
                    return error;
                })
            return response;
        }        
    }

    async getProgramSurveyById(getProgramSurveyById: IgetProgramSurveyById) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Survey/ProgramSurvey/${getProgramSurveyById}`, {
                method: 'GET',
                headers: {
                    'accept': 'text/plain',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                })
                .catch((error: any) => {
                    return error;
                })
            return response;
        }        
    }

    async editProgramSurvey(editSurveyData: IeditSurveyData) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Survey/ProgramSurvey`, {
                method: 'PUT',
                body: JSON.stringify(editSurveyData),
                headers: {
                    'accept': 'text/plain',
                    "content-type": "application/json",
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                })
                .catch((error: any) => {
                    return error;
                })
            return response;
        }        
    }

    async deleteProgramSurvey(deleteProgramSurveyId: IdeleteProgramSurvey) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Survey/ProgramSurvey/${deleteProgramSurveyId}`, {
                method: 'DELETE',
                headers: {
                    'accept': 'text/plain',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                })
                .catch((error: any) => {
                    return error;
                })
            return response;
        }        
    }

    async submitProgramSurveyAnswer(submitProgramSurveyAnswer: IsubmitProgramSurveyAnswer) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Survey/ProgramSurveyAnswer`, {
                method: 'POST',
                body: JSON.stringify(submitProgramSurveyAnswer),
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                })
                .catch((error: any) => {
                    return error;
                })
            return response;
        }        
    }

    async getProgramSurveyAnswerById(surveyId: number) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Survey/ProgramSurveyAnswer?programSurveyId=${surveyId}`, {
                method: 'GET',
                headers: {
                    'accept': 'text/plain',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                })
                .catch((error: any) => {
                    return error;
                })
            return response;
        }        
    }

    async updateProgramSurveyAnswer(updateProgramSurveyAnswer: IupdateProgramSurveyAnswer) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Survey/ProgramSurveyAnswer`, {
                method: 'PUT',
                body: JSON.stringify(updateProgramSurveyAnswer),
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                })
                .catch((error: any) => {
                    return error;
                })
            return response;
        }        
    }

    async lockSurvey(surveyId: number) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Survey/LockProgramSurvey/${surveyId}`, {
                method: 'PUT',
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                })
                .catch((error: any) => {
                    return error;
                })
            return response;
        }        
    }

    async getSurveyStatus(surveyId: number, userId: string) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Survey/IsSurveyCompleted?userId=${userId}&programSurveyId=${surveyId}`, {
                method: 'GET',
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                })
                .catch((error: any) => {
                    return error;
                })
            return response;
        }        
    }

    async isSurveyCompleted(surveyId: number) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Survey/IsSurveyCompleted?programSurveyId=${surveyId}`, {
                method: 'GET',
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                })
                .catch((error: any) => {
                    return error;
                })
            return response;
        }        
    }

    async submitSurvey(surveyId: number) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Survey/SubmitProgramSurvey?programSurveyId=${surveyId}`, {
                method: 'PUT',
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                })
                .catch((error: any) => {
                    return error;
                })
            return response;
        }        
    }

    async getProgramSurveyQuestions(id:number){
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(getQuerySringPreparedUrl(constant.programSurveyQuestions,{Id:id}),getRequestOptions("GET"));
            
            if(response.ok){
                const result = await response.json();
                if(result){
                    return result as IProgramSurveyDetailResponse;
                }
            }
            return {data:null,message:'Something went wrong',status:false}
        }
    }

    async addProgramSurveyQuestion(obj:IProgramSurveyAddEditQuestion){
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(constant.addProgramSurveyQuestion,getRequestOptions("POST",obj));
            if(response.ok){
                const result = await response.json();
                if(result){
                    return result;
                }
            }    
            return {data:null,message:'Something went wrong',status:false}                                 
        }
    }

    async updateProgramSurveyQuestion(obj:IProgramSurveyAddEditQuestion){
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(constant.editProgramSurveyQuestion,getRequestOptions("PUT",obj));
            if(response.ok){
                const result = await response.json();
                if(result){
                    return result;
                }
            }    
            return {data:null,message:'Something went wrong',status:false}                                 
        }
    }

    async deleteProgramSurveyQuestion(id:number,surveyId:number){
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(getQuerySringPreparedUrl(constant.deleteProgramSurveyQuestion,{Id:id,surveyId:surveyId}),getRequestOptions("DELETE"));
            if(response.ok){
                const result = await response.json();
                if(result){
                    return result;
                }
            }
            return {data:null,message:'Something went wrong',status:false}
        }
    }

    async getPredefinedQuestionList(){
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(getQuerySringPreparedUrl(constant.predefinedQuestionList),getRequestOptions("GET"));
            if(response.ok){
                const result = await response.json();
                if(result){
                    return result as IPredefineSurveyQuestionResponse;
                }
            }
            return {data:null,message:'Something went wrong',status:false}
        }
    }

    async addRemovePredefinedQuestion(req:IAddUpdatePredefinedQuestionRequest){
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(getQuerySringPreparedUrl(constant.addRemovePredefinedQuestion),getRequestOptions("POST",req));
            if(response.ok){
                const result = await response.json();
                if(result){
                    return result;
                }
            }
            return {data:null,message:'Something went wrong',status:false};
        }
    }

}
export default Survey;