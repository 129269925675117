import Config from "../Utils/Config";
class UtilServices {
    async getPublicIPv4() {
        const response  =  await fetch(Config.IPv4, { method: 'GET',}).
                                 then((response: any) => response.json()).
                                 then((response: any) =>  { 
                                    if(response.ip !== undefined){
                                        return {status:true,message:"",data:response.ip};
                                    }                                        
                                    else{
                                        return {status:false,message:"IP not found",data:""};
                                    }
                                }).
                                    catch((error: any) => {
                                        return {status:false,message:"IP not found",data:""};
                                });
        return response;
    }    

    //fall back to ipv4
    async getPublicIP(){
        const response  =  await fetch(Config.UniversalIP, { method: 'GET',}).
                                 then((response: any) => response.json()).
                                 then((response: any) =>  { 
                                        if(response.ip !== undefined){
                                            return {status:true,message:"",data:response.ip};
                                        }                                        
                                        else{
                                            return {status:false,message:"IP not found",data:""};
                                        }
                                    }).
                                    catch((error: any) => {
                                        return {status:false,message:"IP not found",data:""};
                                });
        return response;
    }
}

export default UtilServices;