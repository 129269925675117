import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import ForgotPassword from "../../Services/ForgotPassword.service";
import ErrorInputComponent from '../FunctionalComponents/ErrorInput/ErrorInputComponent';
import { createNotification } from '../../Utils/Toast';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Headers } from '../../Utils/Enums';
import HeaderComponent from '../FunctionalComponents/Header/HeaderComponent';
import { Link } from 'react-router-dom';
import "./ForgotResetComponent.css"
import { hasLowerCase, hasNumber, hasUpperCase, specialRgx } from '../../Utils/Utils';
const queryString = require('query-string');

const ResetPasswordComponent = () => {

    const parsed = queryString.parse(window.location.search);

    const forgotPasswordService = new ForgotPassword();

    let history = useHistory();

    const [passwordData, setPasswordData] = useState<any>({
        password: "",
        confirmPassword: ""
    });
    const [errorToggel, setErrorToggel] = useState<any>({
        notMatched: "",
        password: "",
        confirmPassword: "",
    });
    const [showSpinner, setShowSpinner] = useState<boolean>(false);

    useEffect(() => {
        if (!parsed.emailAddress || !parsed.code) {
            createNotification("error", "Not valid URL!");
            history.push("/login");
            return;
        }
        //eslint-disable-next-line
    }, [parsed]);

    const validateForm = async () => {
        if (passwordData.password.length === 0) {
            setErrorToggel((states: any) => {
                return {
                    ...states,
                    password: "Please enter password"
                }
            });
            return false;
        } else {
            setErrorToggel((states: any) => {
                return {
                    ...states,
                    password: ""
                }
            });
        }

        if (passwordData.confirmPassword.length === 0) {
            setErrorToggel((states: any) => {
                return {
                    ...states,
                    confirmPassword: "Please enter confirm password"
                }
            });
            return false;
        } else {
            setErrorToggel((states: any) => {
                return {
                    ...states,
                    confirmPassword: ""
                }
            });
        }

        if (passwordData.password !== passwordData.confirmPassword) {
            setErrorToggel((states: any) => {
                return {
                    ...states,
                    notMatched: "Password and confirm password do not match"
                }
            });
            return false;
        } else {
            setErrorToggel((states: any) => {
                return {
                    ...states,
                    notMatched: ""
                }
            });
        }

        if(passwordData.password.length < 6){
            setErrorToggel((states: any) => {
                return {
                    ...states,
                    password: "Password length must be six character at least."
                }
            });
            return false;   
        }
        else {
            setErrorToggel((states: any) => {
                return {
                    ...states,
                    password: ""
                }
            });
        }

        if(!hasLowerCase(passwordData.password) || !hasUpperCase(passwordData.password)
        || !hasNumber(passwordData.password) || !specialRgx(passwordData.password)){
            setErrorToggel((states: any) => {
                return {
                    ...states,
                    password: "Use a mix of letters (uppercase and lowercase), numbers and symbols."
                }
            });
            return false;
        } else {
            setErrorToggel((states: any) => {
                return {
                    ...states,
                    password: ""
                }
            });
        }
        

        return true;
    }

    const handleResetPassword = async () => {
        if (await validateForm()) {
            setShowSpinner(true);
            let data: any = {
                emailAddress: parsed.emailAddress,
                password: passwordData.password,
                confirmPassword: passwordData.confirmPassword,
                code: parsed.code
            }
            let response: any = await forgotPasswordService.resetPassword(data);
            if (response.status) {
                setShowSpinner(false);
                createNotification("success", response.message);
                history.push("/login");
            } else {
                setShowSpinner(false);
                if(response.data){
                    createNotification("error", response.data);
                }
                else{
                    createNotification("error", response.message);
                }    
            }
        }
    }

    return (
        <>
            <div className="d-flex justify-content-center my-5">
                <Link to={"/login"} className="text-decoration-none cursor-pointer">
                    <img alt="Mind Navigator latest" src={window.location.origin + "/images/MindNavigator_latest.svg"} style={{ width: "8.125rem", height: "1.813rem" }} />
                </Link>
            </div>
            <div className="justify-content-center d-flex flex-column align-items-center mx-4 py-5">
                <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5">
                    <HeaderComponent size={Headers.H2} title="Reset Password" />
                    <div className="mb-4">
                        {errorToggel.notMatched && <ErrorInputComponent error={errorToggel.notMatched} />}
                    </div>
                    <div className="mb-5 title-font">
                        <ul>
                            <li>Use at least 6 characters.</li>
                            <li>Use a mix of letters (uppercase and lowercase), numbers and symbols.</li>
                            <li>Don't use your name, username or account name.</li>
                            <li>Avoid predictable passwords such as "password", "12345" or "talkdesk"</li>
                        </ul>
                    </div>
                    <section>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label className="title-font">Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Password"
                                    className="input-font"
                                    onChange={(e: any) => setPasswordData((states: any) => {
                                        return {
                                            ...states,
                                            password: e.target.value
                                        }
                                    })}

                                />
                                {errorToggel.password && <ErrorInputComponent error={errorToggel.password} />}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label className="title-font">Confirm Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Confirm Password"
                                    className="input-font"
                                    onChange={(e: any) => setPasswordData((states: any) => {
                                        return {
                                            ...states,
                                            confirmPassword: e.target.value
                                        }
                                    })}
                                />
                                {errorToggel.confirmPassword && <ErrorInputComponent error={errorToggel.confirmPassword} />}
                            </Form.Group>

                            <div className="d-grid gap-2 my-5">
                                {
                                    showSpinner ?
                                        <Button variant="primary" className="text-secondary" disabled>
                                            <span className="mx-2 text-secondary">
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                            Resetting Password...
                                        </Button>
                                        :
                                        <Button variant="primary" className="font-weight-600 text-color-182C34" type="button" onClick={() => handleResetPassword()}>
                                            Reset Password
                                        </Button>
                                }
                            </div>
                        </Form>
                    </section>
                </div>
            </div>
        </>
    );
};

export default ResetPasswordComponent;