import React from "react";
import './LogoComponent.css'

const LogoComponent=()=>{
    return (<>
          <div className="d-flex justify-content-center logo">
        <img src={window.location.origin + '/images/MindNavigator_latest.svg'} width={"130px"} height={"28.9px"} alt="Logo"/>
    </div>
    </>)
}

export default LogoComponent;