import React, { useEffect, useState, useContext } from 'react';
import {
    Form,
    Col,
    Button,
    Spinner
} from 'react-bootstrap';
import makeAnimated from 'react-select/animated';
import AsyncSelect from 'react-select/async';
import { createNotification } from "../../../Utils/Toast";
import Group from "../../../Services/Group.services";
import Lookup from '../../../Services/Lookup.service';
import ErrorInputComponent from '../../FunctionalComponents/ErrorInput/ErrorInputComponent';
import General from '../../../Services/General.service';
import { ThemeContext } from "../../../Contexts/ThemeContext";
import "../../../darkcustom.css"

interface Iprops {
    editGroupData: any;
    currentPage: number;
    rowsPerpage: number;
    userOrganisationId: number;
    getTotalGroupData: any;
    setEditData: (data: boolean) => void;
    setModalState: (data: boolean) => void;
    editData: boolean
}

const animatedComponents = makeAnimated();

const OrganisationGroup = (props: Iprops) => {
    const generalService = new General();
    generalService.getIcons();
    const {
        editGroupData,
        getTotalGroupData,
        setEditData,
        setModalState,
        editData
    } = props;

    const group = new Group();
    const lookup = new Lookup();

    const [groupName, setGroupName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [maxUser, setMaxUser] = useState<number>(0);
    const [facilator, setFacilator] = useState<any[]>([]);
    const [programList, setProgramList] = useState<any[]>([]);
    const [memberQuery, setMemberQuery] = useState<string>("");
    const [facilatorQuery, setFacilatorQuery] = useState<string>("");
    const [selectedMember, setSelectedMember] = useState<any[]>([]);
    const [, setSelectedFacilator] = useState<string>("");
    const [selectedFacilatorId, setSelectedFacilatorId] = useState<string>("");
    const [selectedGroupProgram, setSelectedGroupProgram] = useState<string>("");
    const [selectedGroupProgramId, setSelectedGroupProgramId] = useState<number>(0);
    const [showSpinner, setShowSpinner] = useState<boolean>(false);

    //for editing the group
    const [, setSelectedEditFacilator] = useState<string>("");
    const [, setSelectedEditFacilatorId] = useState<string>("");
    const [, setSelectedEditGroupProgramId] = useState<number>(0);
    const { theme } = useContext(ThemeContext)

    const [errorToggel, setErrorToggel] = useState<any>({
        groupName: "",
        description: "",
        organisationId: "",
        maxUser: "",
        selectedMember: "",
        selectedFacilator: "",
        selectedGroupProgram: ""
    });

    const [valueUpdated, setValueUpdated] = useState<any>({
        groupName: false,
        description: false,
        maxUser: false,
        selectedMember: false,
        selectedFacilator: false,
        selectedGroupProgram: false,
    });

    useEffect(() => {
        if (editData) {
            setGroupName(editGroupData.groupName);
            setDescription(editGroupData.description);
            setMaxUser(editGroupData.maxUser);
            setSelectedMember(editGroupData.members);
            setSelectedEditFacilator(editGroupData.groupFacilator);
            setSelectedEditFacilatorId(editGroupData.groupFacilatorId);
            setSelectedGroupProgram(editGroupData.groupProgram);
            setSelectedGroupProgramId(editGroupData.groupProgramId);
            setSelectedEditGroupProgramId(editGroupData.groupProgramId);
        }
        //eslint-disable-next-line
    }, [editData]);

    useEffect(() => {
        loadProgramsListOptions();
        //eslint-disable-next-line
    }, []);

    const colorStyle = {
        control: (styles: any) => ({ ...styles, backgroundColor: '#28373E', borderColor: "#1c2930" }),
        multiValueLabel: (styles: any) => ({
            ...styles,
            color: "white",
        }),
        multiValue: (styles: any) => {
            return {
                ...styles,
                backgroundColor: "#1c2930",
            };
        },
    }

    const loadParticipantOptions = async () => {
        let result: any;
        if (memberQuery.length >= 1) {
            result = await lookup.getParticipantByOrgnisation(memberQuery);
            if (result.status) {
                let members: any[] = [];
                members = result.data.map((value: any) => {
                    return {
                        value: value.guid,
                        label: value.name
                    }
                })
                return members;
            } else {
                createNotification("error", result.message);
            }
        }
    };

    const loadFacilatorOptions = async () => {
        let result: any;
        if (facilatorQuery.length >= 1) {
            result = editData ? await lookup.getFacilatorByOrgnisation(facilatorQuery, Number(editGroupData.groupId))
                : await lookup.getFacilatorByOrgnisation(facilatorQuery, 0)
            if (result.status) {
                let facilators: any[] = [];
                facilators = result.data.map((value: any) => {
                    return {
                        value: value.guid,
                        label: value.name
                    }
                })
                setFacilator(facilators);
                return facilators;
            } else {
                createNotification("error", result.message);
            }
        }
    };

    const loadProgramsListOptions = async () => {
        let result: any = await lookup.getProgramsList();
        if (result.status) {
            let programLists: any[] = [];
            programLists = result.data.map((value: any) => {
                return {
                    value: value.id,
                    label: value.name
                }
            })
            setProgramList(programLists);
            return programLists;
        } else {
            createNotification("error", result.message);
        }
    };

    const validateForm = async () => {
        if (groupName.length === 0) {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    groupName: "Please enter group name"
                }
            })
            return false;
        } else {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    groupName: ""
                }
            })
        }

        if (maxUser === 0) {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    maxUser: "Please enter maximum user for the group"
                }
            })
            return false;
        } else {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    maxUser: ""
                }
            })
        }

        if (selectedGroupProgramId === 0) {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    selectedGroupProgram: "Please select group program"
                }
            })
            return false;
        } else {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    selectedGroupProgram: ""
                }
            })
        }
        return true;
    }

    const handleFacilator = (e: any, selectedOption: any) => {
        if (selectedOption?.removedValues?.length === 1) {
            setSelectedFacilator("");
            setSelectedFacilatorId("")
        } else {
            setSelectedFacilatorId(e?.value);
            setSelectedFacilator(e?.label);
            setValueUpdated((states: any) => {
                return {
                    ...states,
                    selectedFacilator: true
                }
            });
        }
    }

    const handleMembers = (e: any, selectedOption: any) => {
        let selectedMembers: any[] = [];
        if (selectedOption.removedValue) {
            let remMember: any[];
            remMember = selectedMember.filter((item: any) =>
                item.value !== selectedOption.removedValue.value
            );
            remMember.map((data: any) => {
                return selectedMembers.push({ value: data.value, label: data.label });
            });
            setSelectedMember(selectedMembers);
        } else {
            if (selectedMember.length < maxUser) {
                e.map((item: any) => {
                    return selectedMembers.push({ value: item.value, label: item.label });
                })
                setSelectedMember(selectedMembers);
                setValueUpdated((states: any) => {
                    return {
                        ...states,
                        selectedMember: true
                    }
                })
            } else {
                setSelectedMember([]);
            }
        }
    }

    const isValueUpdated = async () => {
        if (valueUpdated.groupName || valueUpdated.description || valueUpdated.maxUser || valueUpdated.selectedMember || valueUpdated.selectedFacilator || valueUpdated.selectedGroupProgram) {
            return true;
        } else {
            return false;
        }
    }

    const SubmitFormHandler = async (event: any) => {

        event.preventDefault();
        if (await validateForm()) {
            setShowSpinner(true);
            let result: any;
            let memberData: any[] = [];
            selectedMember.map((data: any) => {
                return memberData.push({
                    guid: data.value
                })
            });

            if (editData) {
                let editGroupValue: any = {
                    id: editGroupData.groupId,
                    groupName: groupName,
                    description: description,
                    maximumUsers: maxUser,
                    members: memberData,
                    groupFacilator: {
                        guid: selectedFacilatorId || editGroupData.groupFacilatorId
                    },
                    groupProgram: {
                        id: selectedGroupProgramId || editGroupData.groupProgramId
                    }
                }

                if (await isValueUpdated()) {
                    result = await group.updateGroup(editGroupValue);
                    if (result.status) {
                        setShowSpinner(false);
                        createNotification("success", result.message);
                        getTotalGroupData();
                        setEditData(false);
                        setModalState(false);
                    } else {
                        setShowSpinner(false);
                        setModalState(false);
                        createNotification("error", result.message);
                    }
                } else {
                    setShowSpinner(false);
                    setEditData(false);
                    setModalState(false);
                    createNotification("info", "You have not updated anything!");
                }
            } else {
                let createGroupData: any = {
                    groupName: groupName,
                    description: description,
                    maximumUsers: maxUser,
                    members: memberData,
                    groupFacilator: {
                        guid: selectedFacilatorId
                    },
                    groupProgram: {
                        id: selectedGroupProgramId
                    }
                }
                result = await group.createGroup(createGroupData);
                if (result.status) {
                    setShowSpinner(false);
                    createNotification("success", result.message);
                    getTotalGroupData();
                    setModalState(false);
                } else {
                    setShowSpinner(false);
                    setModalState(false);
                    createNotification("error", result.message);
                }
            }
        }
    }

    return (
        <>
            <div className="row justify-content-center">
                <div>
                    <section>
                        {theme === "light" ? (
                            <form id="account" autoComplete="off">
                                <Form.Group className="mb-3">
                                    <Form.Label>Group Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={groupName || ""}
                                        placeholder="Enter Group Name"
                                        onChange={(e) => {
                                            setGroupName(e.target.value);
                                            setValueUpdated((states: any) => {
                                                return {
                                                    ...states,
                                                    groupName: true
                                                }
                                            })
                                        }}
                                    />
                                    {errorToggel.groupName && <ErrorInputComponent error={errorToggel.groupName} />}
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={description || ""}
                                        placeholder="Enter Description"
                                        onChange={(e) => {
                                            setDescription(e.target.value);
                                            setValueUpdated((states: any) => {
                                                return {
                                                    ...states,
                                                    description: true
                                                }
                                            })
                                        }}
                                    />
                                    {errorToggel.description && <ErrorInputComponent error={errorToggel.description} />}
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Maximum User</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={maxUser || ""}
                                        placeholder="Enter Maximum User"
                                        onChange={(e) => {
                                            let inputValue = e.target.value.replace(/[^0-9.]/g, '');
                                            setMaxUser(parseInt(inputValue));
                                            setValueUpdated((states: any) => {
                                                return {
                                                    ...states,
                                                    maxUser: true
                                                }
                                            })
                                        }}
                                        maxLength={2}
                                    />
                                    {errorToggel.maxUser && <ErrorInputComponent error={errorToggel.maxUser} />}
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridState" className="mb-3">
                                    <Form.Label>Members</Form.Label>
                                    <AsyncSelect
                                        isMulti
                                        id={"select"}
                                        cacheOptions
                                        components={animatedComponents}
                                        isOptionDisabled={() => (selectedMember.length) === maxUser}
                                        defaultValue={(editData && editGroupData.members)
                                            ? editGroupData.members
                                            : null
                                        }
                                        onInputChange={(value) => {
                                            setMemberQuery(value);
                                            setValueUpdated((states: any) => {
                                                return {
                                                    ...states,
                                                    selectedMember: true
                                                }
                                            })
                                        }}
                                        loadOptions={loadParticipantOptions}
                                        onChange={(e: any, selectedOption: any) => { handleMembers(e, selectedOption) }}
                                    />
                                    {errorToggel.selectedMember && <ErrorInputComponent error={errorToggel.selectedMember} />}
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridState" className="mb-3">
                                    <Form.Label>Group Facilator</Form.Label>
                                    <AsyncSelect
                                        cacheOptions
                                        isClearable={true}
                                        options={facilator}
                                        components={animatedComponents}
                                        defaultValue={(editData && editGroupData.groupFacilatorId && editGroupData.groupFacilator)
                                            ? {
                                                value: parseInt(editGroupData.groupFacilatorId),
                                                label: editGroupData.groupFacilator
                                            }
                                            : null
                                        }
                                        onInputChange={(value) => {
                                            setFacilatorQuery(value);
                                            setValueUpdated((states: any) => {
                                                return {
                                                    ...states,
                                                    selectedFacilator: true
                                                }
                                            })
                                        }}
                                        loadOptions={loadFacilatorOptions}
                                        onChange={(e: any, selectedOption: any) => { handleFacilator(e, selectedOption) }}
                                    />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridState" className="mb-3">
                                    <Form.Label>Group Program</Form.Label>
                                    <Form.Select value={selectedGroupProgramId}
                                        onChange={(e: any) => {
                                            setSelectedGroupProgramId(Number(e.target.value))
                                            setValueUpdated((states: any) => {
                                                return {
                                                    ...states,
                                                    selectedGroupProgram: true
                                                }
                                            })
                                        }}
                                    >
                                        <option value={"0"}>{"Select Group Program"}</option>
                                        {programList.map((data: any) => <option key={data.value} value={data.value}>{data.label}</option>)
                                        }
                                    </Form.Select>
                                    {errorToggel.selectedGroupProgram && <ErrorInputComponent error={errorToggel.selectedGroupProgram} />}
                                </Form.Group>

                                <div className="d-grid gap-2 mt-1">
                                    {
                                        showSpinner ?
                                            <Button variant="primary" className="text-secondary" disabled>
                                                <span className="mx-2 text-secondary">
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                                {editData ? 'Editing group data..' : 'Creating group...'}
                                            </Button>
                                            :
                                            <Button
                                                variant="primary"
                                                type="button"
                                                onClick={(e: any) => SubmitFormHandler(e)}
                                            >
                                                {editData ? 'Edit Group' : 'Create Group'}
                                            </Button>
                                    }
                                </div>
                            </form>
                        ) : <form id="account" autoComplete="off">
                                <Form.Group className="mb-3">
                                    <Form.Label>Group Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={groupName || ""}
                                        placeholder="Enter Group Name"
                                        className="userSearchBox"
                                        onChange={(e) => {
                                            setGroupName(e.target.value);
                                            setValueUpdated((states: any) => {
                                                return {
                                                    ...states,
                                                    groupName: true
                                                }
                                            })
                                        }}
                                    />
                                    {errorToggel.groupName && <ErrorInputComponent error={errorToggel.groupName} />}
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={description || ""}
                                        placeholder="Enter Description"
                                        className="userSearchBox"
                                        onChange={(e) => {
                                            setDescription(e.target.value);
                                            setValueUpdated((states: any) => {
                                                return {
                                                    ...states,
                                                    description: true
                                                }
                                            })
                                        }}
                                    />
                                    {errorToggel.description && <ErrorInputComponent error={errorToggel.description} />}
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Maximum User</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={maxUser || ""}
                                        placeholder="Enter Maximum User"
                                        className="userSearchBox"
                                        onChange={(e) => {
                                            let inputValue = e.target.value.replace(/[^0-9.]/g, '');
                                            setMaxUser(parseInt(inputValue));
                                            setValueUpdated((states: any) => {
                                                return {
                                                    ...states,
                                                    maxUser: true
                                                }
                                            })
                                        }}
                                        maxLength={2}
                                    />
                                    {errorToggel.maxUser && <ErrorInputComponent error={errorToggel.maxUser} />}
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridState" className="mb-3">
                                    <Form.Label>Members</Form.Label>
                                    <AsyncSelect
                                        isMulti
                                        id={"select"}
                                        styles={colorStyle}
                                        cacheOptions
                                        components={animatedComponents}
                                        isOptionDisabled={() => (selectedMember.length) === maxUser}
                                        defaultValue={(editData && editGroupData.members)
                                            ? editGroupData.members
                                            : null
                                        }
                                        onInputChange={(value) => {
                                            setMemberQuery(value);
                                            setValueUpdated((states: any) => {
                                                return {
                                                    ...states,
                                                    selectedMember: true
                                                }
                                            })
                                        }}
                                        loadOptions={loadParticipantOptions}
                                        onChange={(e: any, selectedOption: any) => { handleMembers(e, selectedOption) }}
                                    />
                                    {errorToggel.selectedMember && <ErrorInputComponent error={errorToggel.selectedMember} />}
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridState" className="mb-3">
                                    <Form.Label>Group Facilator</Form.Label>
                                    <AsyncSelect
                                        styles={colorStyle}
                                        cacheOptions
                                        isClearable={true}
                                        options={facilator}
                                        components={animatedComponents}
                                        defaultValue={(editData && editGroupData.groupFacilatorId && editGroupData.groupFacilator)
                                            ? {
                                                value: parseInt(editGroupData.groupFacilatorId),
                                                label: editGroupData.groupFacilator
                                            }
                                            : null
                                        }
                                        onInputChange={(value) => {
                                            setFacilatorQuery(value);
                                            setValueUpdated((states: any) => {
                                                return {
                                                    ...states,
                                                    selectedFacilator: true
                                                }
                                            })
                                        }}
                                        loadOptions={loadFacilatorOptions}
                                        onChange={(e: any, selectedOption: any) => { handleFacilator(e, selectedOption) }}
                                    />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridState" className="mb-3">
                                    <Form.Label>Group Program</Form.Label>
                                    <Form.Select className="userSearchBox" value={selectedGroupProgramId}
                                        onChange={(e: any) => {
                                            setSelectedGroupProgramId(Number(e.target.value))
                                            setValueUpdated((states: any) => {
                                                return {
                                                    ...states,
                                                    selectedGroupProgram: true
                                                }
                                            })
                                        }}
                                    >
                                        <option value={"0"}>{"Select Group Program"}</option>
                                        {programList.map((data: any) => <option key={data.value} value={data.value}>{data.label}</option>)
                                        }
                                    </Form.Select>
                                    {errorToggel.selectedGroupProgram && <ErrorInputComponent error={errorToggel.selectedGroupProgram} />}
                                </Form.Group>

                                <div className="d-grid gap-2 mt-1">
                                    {
                                        showSpinner ?
                                            <Button variant="primary" className="text-secondary" disabled>
                                                <span className="mx-2 text-secondary">
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                                {editData ? 'Editing group data..' : 'Creating group...'}
                                            </Button>
                                            :
                                            <Button
                                                variant="primary"
                                                type="button"
                                                onClick={(e: any) => SubmitFormHandler(e)}
                                            >
                                                {editData ? 'Edit Group' : 'Create Group'}
                                            </Button>
                                    }
                                </div>
                            </form>}
                    </section>
                </div>
            </div>
        </>
    );

};

export default OrganisationGroup;