import {IChangePasswordRequest, IChangePasswordResponse, IChangeUserNotificationResponse, IUserAccountInfoResponse, IUserAccountInfoSave, IUserAccountInfoSaveResponse } from "../interfaces/User/IUserAccount";
import constant from "../Utils/Config";
import {isTokenExpired,clearLocalStorageAndRedirect, getAuthToken, getQuerySringPreparedUrl, getRequestOptions} from "../Utils/Utils";
class User {

    async getUsersByOrganizationId(pageNumber: number, pageSize: number,searchTerm:string,groupIds:string) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const obj = {
                pageNumber:pageNumber.toString(),
                pageSize:pageSize.toString(),
                searchTerm:searchTerm,
                groupIds:groupIds
            }

            const response = await fetch(getQuerySringPreparedUrl(`${process.env.REACT_APP_BASE_URL}/user`,obj), {
                method: "GET",
                headers: {
                    "accept": '*/*',
                    'Content-Type': 'application/json',
                    "Authorization": getAuthToken()
                }
            }).then(async (response: any) => {
                const result: any = await response.json();
                if (response.ok) {
                    return {
                        data: result,
                        ok: true
                    }
                }
            }).catch((error: any) => {
                return {
                    data: error,
                    ok: false
                }
            });
            return response;
        }        
    }

    async getUserAccountDetails(){
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(constant.userAccountDetails,getRequestOptions('GET'));
            if(response.ok){
                const result = await response.json();
                if(result){
                    return result as IUserAccountInfoResponse;
                }                
            }
            return {data:null,message:'Something went wrong',status:false}
        }
    }

    async saveUserAccountDetails(data:IUserAccountInfoSave){
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else {
            const response = await fetch(constant.userAccountDetails,getRequestOptions('POST',data));
            if(response.ok){
                const result = await response.json();
                if(result){
                    return result as IUserAccountInfoSaveResponse;
                }                
            }
            return {data:null,message:'Something went wrong',status:false}
        }
    }

    async ChangePassword(data:IChangePasswordRequest) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else {
            const response = await fetch(constant.changePassword,getRequestOptions('POST',data));
            if(response.ok){
                const result = await response.json();
                if(result){
                    return result as IChangePasswordResponse;
                }                
            }
            return {data:null,message:'Something went wrong',status:false}
        }
    }

    async ChangeNotification() {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(constant.changeNotification,getRequestOptions('GET'));
            if(response.ok){
                const result = await response.json();
                if(result){
                    return result as IChangeUserNotificationResponse;
                }                
            }
            return {data:null,message:'Something went wrong',status:false}
        }
    }

    async ChangePlayModeForConcept() {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(constant.ChangeConceptAudioPreference,getRequestOptions('PUT'));
            if(response.ok){
                const result = await response.json();
                if(result){
                    return result as IChangeUserNotificationResponse;
                }
            }
            return {data:null,message:'Something went wrong',status:false}
        }
    }
}
export default User;