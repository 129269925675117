import React, { useEffect, useState, useContext } from "react";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import Lookup from "../../../Services/Lookup.service";
import AdminDashboard from "../../../Services/AdminDashboard.service";
import { logOutIfTokenExpire } from "../../../Utils/Utils";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from "chart.js";
import { isArray } from "lodash";
import { IAdminDashboardFilter } from "../../../interfaces/AdminDashboard/IAdminDashboardFilter";
import { IBarChart, IBarChartDataset } from "../../../interfaces/Charts/IBarChart";
import { IPracticeDataRequest } from "../../../interfaces/AdminDashboard/IAdminDashboardPracticeData";
import ChartComponent from "../../FunctionalComponents/Chart/ChartComponent";
import { chartType } from "../../../Utils/Enums";
import "../../../darkcustom.css";
import { ThemeContext } from "../../../Contexts/ThemeContext";
import "../../../darkcustom.css"

interface Iprops {

}

const DashboardComponent = (props: Iprops) => {
  const lookup = new Lookup();
  const colors = [
    '#00C9A7',
    '#354B45',
    '#98B0A9',
    '#4ABAFF',
    '#0085D3',
    '#398B77',
  ];
  const randomColorNoRepeats = () => {
    let array = colors;
    var copy = array.slice(0);
    if (copy.length < 1) { copy = array.slice(0); }
    var index = Math.floor(Math.random() * copy.length);
    var item = copy[index];
    copy.splice(index, 1);
    return item;
  }
  const adminDashboardServices = new AdminDashboard();
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [programChartSpinner, setProgramChartSpinner] = useState<boolean>(false);
  const [scoreChartSpinner, setScoreChartSpinner] = useState<boolean>(false);
  const [moduleChartSpinner, setModuleChartSpinner] = useState<boolean>(false);
  const [practiceChartSpinner, setPracticeChartSpinner] = useState<boolean>(false);
  const [programList, setProgramList] = useState<any[]>([]);
  const [statusList, setStatusList] = useState<any[]>([]);
  const [countryList, setCountryList] = useState<any[]>([]);
  const [selectedProgram, setSelectedProgram] = useState<any[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<any[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<any[]>([]);
  const [programChartData, setprogramChartData] = useState<IBarChart>();
  const [scoreChartData, setScoreChartData] = useState<IBarChart>();
  const [moduleChartData, setModuleChartData] = useState<IBarChart>();
  const [practiceData, setPracticeData] = useState<IBarChart>();
  const [practiceFilter, setPracticeFilter] = useState<number>(0);
  const { theme } = useContext(ThemeContext)

  ChartJS.register(ArcElement, Tooltip, Legend);
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  const programChartOption = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const
      },
      title: {
        display: true,
        text: "Program chart"
      }
    },
    maintainAspectRatio: true,
  };

  const scoreChartOption = {
    responsive: true,
    maintainAspectRatio: true,
    indexAxis: "y" as const,
    plugins: {
      legend: {
        position: "top" as const
      },
      title: {
        display: true,
        text: "Score chart"
      }
    }

  }

  const moduleChartOption = {
    responsive: true,
    maintainAspectRatio: true,
    indexAxis: "y" as const,
    plugins: {
      legend: {
        position: "top" as const
      },
      title: {
        display: true,
        text: "Module chart"
      }
    }
  };

  const practiceChartOption = {
    responsive: true,
    maintainAspectRatio: true,
    indexAxis: "y" as const,
    plugins: {
      legend: {
        position: "top" as const
      },
      title: {
        display: true,
        text: "Practice chart"
      }
    }
  }


  useEffect(() => {
    logOutIfTokenExpire();
    programsListOptions();
    statusListOptions();
    getCountryListOptions();
  }, []);

  const programsListOptions = async () => {
    let result: any = await lookup.getProgramsList();
    if (result.status) {
      let programLists: any[] = result.data.map((value: any) => {
        return {
          label: value.name,
          value: value.id
        }
      })
      setProgramList(programLists);
    }
  };

  const statusListOptions = () => {
    let statusList: any[] = [{ value: 0, label: 'Not Started' }, { value: 1, label: 'In-Progress' }, { value: 2, label: 'Completed' }];
    setStatusList(statusList);
  }

  const getCountryListOptions = async () => {
    let result: any = await lookup.getCountries();
    if (isArray(result)) {
      let countryList: any[] = result.map((value: any) => {
        return {
          label: value.name,
          value: value.id,
        }
      });
      setCountryList(countryList);
    }
  }

  const getProgramChartData = async () => {
    let countries = selectedCountry.length > 0 ? selectedCountry.map(x => x.value) : [];
    let programs = selectedProgram.length > 0 ? selectedProgram.map(x => x.value) : [];
    let statuses = selectedStatus.length > 0 ? selectedStatus.map(x => x.value) : [];
    let filters: IAdminDashboardFilter = { countries: countries, programs: programs, userProgramStatusEnum: statuses };
    const response = await adminDashboardServices.getDashboardProgramStatus(filters);
    if (response) {
      if (response.status && response.data) {
        let chartData: IBarChart = {
          labels: response.data.dashboardProgramStaus ? response.data.dashboardProgramStaus.map(x => x.displayValue) : [],
          datasets: response.data.dashboardProgramStausData ? response.data.dashboardProgramStausData.map((item, i) => {
            let obj: IBarChartDataset = {
              label: item.countryName,
              data: item.userCountWithStaus.map(x => x.userCount),
              backgroundColor: colors[i] ? colors[i] : randomColorNoRepeats()
            }
            return obj;
          }) : [{ label: '', data: [], backgroundColor: '' }]
        }
        setprogramChartData(chartData);
      }
      else {
        setprogramChartData({ labels: [], datasets: [] });
      }
    }
    setProgramChartSpinner(false);
  }

  const getScoreChartData = async () => {
    let programs = selectedProgram.length > 0 ? selectedProgram.map(x => x.value) : [];
    const response = await adminDashboardServices.getScoreChartData(programs);
    if (response) {
      if (response.status && response.data) {
        response.data.datasets.forEach((item, i) => {
          item.backgroundColor = colors[i] ? colors[i] : randomColorNoRepeats()
        });
        let chartData: IBarChart = {
          labels: response.data.labels,
          datasets: response.data.datasets,
        }
        setScoreChartData(chartData);
      }
      else {
        setScoreChartData({ labels: [], datasets: [] });
      }
    }
    else {
      setScoreChartData({ labels: [], datasets: [] });
    }
    setScoreChartSpinner(false);
  }

  const getModuleChartData = async () => {
    let programs = selectedProgram.length > 0 ? selectedProgram.map(x => x.value) : [];
    const response = await adminDashboardServices.getModuleChartData(programs);
    if (response) {
      if (response.status && response.data) {
        response.data.datasets.forEach((item, i) => {
          item.backgroundColor = colors[i] ? colors[i] : randomColorNoRepeats()
        });
        let chartData: IBarChart = {
          labels: response.data.labels,
          datasets: response.data.datasets,
        }
        setModuleChartData(chartData);
      }
      else {
        setModuleChartData({ labels: [], datasets: [] });
      }
    }
    else {
      setModuleChartData({ labels: [], datasets: [] });
    }
    setModuleChartSpinner(false);
  }

  const getPracticeData = async (selceted: number) => {
    let request: IPracticeDataRequest = {
      programs: selectedProgram.length > 0 ? selectedProgram.map(x => x.value) : [],
      practiceFilter: selceted
    };
    const response = await adminDashboardServices.getPracticeData(request);
    if (response) {
      if (response.status && response.data) {
        response.data.datasets.forEach((item, i) => {
          item.backgroundColor = colors[i] ? colors[i] : randomColorNoRepeats()
        });
        let chartData: IBarChart = {
          labels: response.data.labels,
          datasets: response.data.datasets,
        }
        setPracticeData(chartData);
      }
      else {
        setPracticeData({ labels: [], datasets: [] });
      }
    }
    else {
      setPracticeData({ labels: [], datasets: [] });
    }
    setPracticeChartSpinner(false);
  }

  const handlePracticeFilterChange = (selceted: number) => {
    setPracticeFilter(selceted);
  }

  const refreshCharts = async () => {
    setShowSpinner(true);
    setProgramChartSpinner(true);
    setScoreChartSpinner(true);
    setModuleChartSpinner(true);
    setPracticeChartSpinner(true);
    await getProgramChartData();
    await getScoreChartData();
    await getModuleChartData();
    await getPracticeData(practiceFilter);
    setShowSpinner(false);
  }

  return (
    <>
      {theme === "light" ? (
        <div className="row g-3 align-items-center">
          <Form.Group className="mb-3 col-3">
            <Form.Label>Locations</Form.Label>
            <MultiSelect
              options={countryList}
              value={selectedCountry}
              onChange={setSelectedCountry}
              labelledBy="Select Locations"
            />

          </Form.Group>
          <Form.Group className="mb-3 col-3">
            <Form.Label>Program Name</Form.Label>
            <MultiSelect
              options={programList}
              value={selectedProgram}
              onChange={setSelectedProgram}
              labelledBy="Select Programs"
            />
          </Form.Group>
          <Form.Group className="mb-3 col-3">
            <Form.Label>Status</Form.Label>
            <MultiSelect
              options={statusList}
              value={selectedStatus}
              onChange={setSelectedStatus}
              labelledBy="Select Status"
            />
          </Form.Group>
          <Form.Group className="mb-3 col-3">
            <Form.Label>Practice</Form.Label>
            <Form.Select value={practiceFilter} onChange={(e: any) => {
              handlePracticeFilterChange(Number(e.target.value));
            }}>
              <option value={0}>Select..</option>
              <option value={1}>Most Used</option>
              <option value={2}>Top Rated</option>
            </Form.Select>
          </Form.Group>
        </div>
      ) : <div className="row g-3 align-items-center">
          <Form.Group className="mb-3 col-3">
            <Form.Label>Locations</Form.Label>
            <MultiSelect
              className="mutltiBox"
              options={countryList}
              value={selectedCountry}
              onChange={setSelectedCountry}
              labelledBy="Select Locations"
            />

          </Form.Group>
          <Form.Group className="mb-3 col-3">
            <Form.Label>Program Name</Form.Label>
            <MultiSelect
              className="mutltiBox"
              options={programList}
              value={selectedProgram}
              onChange={setSelectedProgram}
              labelledBy="Select Programs"
            />
          </Form.Group>
          <Form.Group className="mb-3 col-3">
            <Form.Label>Status</Form.Label>
            <MultiSelect
              className="mutltiBox"
              options={statusList}
              value={selectedStatus}
              onChange={setSelectedStatus}
              labelledBy="Select Status"
            />
          </Form.Group>
          <Form.Group className="mb-3 col-3">
            <Form.Label>Practice</Form.Label>
            <Form.Select className="clientSearchBox" value={practiceFilter} onChange={(e: any) => {
              handlePracticeFilterChange(Number(e.target.value));
            }}>
              <option value={0}>Select..</option>
              <option value={1}>Most Used</option>
              <option value={2}>Top Rated</option>
            </Form.Select>
          </Form.Group>
        </div>}
      <div className="row g-3 align-items-center">
        <div className="mt-3 col-4">
          {
            showSpinner ? <Button variant="primary" className="text-secondary" disabled>
              <span className="mx-2 text-secondary">
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </span>
              Refreshing...
                  </Button> : <Button
                variant="primary"
                className="font-weight-600 text-color-182C34"
                type="button"
                onClick={() => refreshCharts()}
              >
                Refresh
                </Button>
          }
        </div>
      </div>
      <br />
      <div className="row">
        <div className="mb-3 col-6">
          <ChartComponent key={1} ChartType={chartType.Bar} spinner={programChartSpinner} data={programChartData} options={programChartOption} />
        </div>
        <div className="mb-3 col-6">
          <ChartComponent key={2} ChartType={chartType.Bar} spinner={practiceChartSpinner} data={practiceData} options={practiceChartOption} />
        </div>
      </div>
      <div className="row">
        <div className="mb-3 col-6">
          <ChartComponent key={3} ChartType={chartType.Bar} spinner={moduleChartSpinner} data={moduleChartData} options={moduleChartOption} />
        </div>
        <div className="mb-3 col-6">
          <ChartComponent key={4} ChartType={chartType.Bar} spinner={scoreChartSpinner} data={scoreChartData} options={scoreChartOption} />
        </div>
      </div>
    </>
  )
}

export default DashboardComponent;