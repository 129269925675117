import React from 'react';
import { Redirect } from 'react-router';

//public routes - auth not required
import LoginComponent from './components/BusinessComponents/LoginComponent/LoginComponent';
import ForgotPasswordComponent from './components/api-authorization/ForgotPasswordComponent';
import ResetPasswordComponent from './components/api-authorization/ResetPasswordComponent';
import UserRegistrationComponent from './components/api-authorization/UserRegistrationComponent';
import ExternalUserScoreComponent from './components/BusinessComponents/ExternalUserScoreComponent/ExternalUserScoreComponent';
import ErrorPageComponent from './components/FunctionalComponents/ErrorPageComponent/ErrorPageComponent';

//private routes - auth required
import ProgramOverview from './components/BusinessComponents/ProgramOverview/ProgramOverviewComponent';
import ProfileComponent from './components/BusinessComponents/Profile/ProfileComponent';
import ThinkIntroComponent from './components/BusinessComponents/ThinkIntro/ThinkIntroComponent';
import EngageComponent from './components/BusinessComponents/Engage/EngageComponent';
import DoComponent from './components/BusinessComponents/Do/DoComponent';
import DashboardComponent from './components/BusinessComponents/Dashboard/DashboardComponent';
import ModuleComponent from './components/BusinessComponents/Module/ModuleComponent';
import ConceptComponent from './components/BusinessComponents/Concept/ConceptComponent';
import ClientAdminDashboardComponent from './components/BusinessComponents/ClientAdminDashboard/ClientAdminDashboard';
import Survey from './components/BusinessComponents/UserSurvey/CreateSurvey';
import TakeSurvey from './components/BusinessComponents/UserSurvey/TakeSurvey';
import MeetingAttendanceComponent from './components/BusinessComponents/MeetingAttendence/MeetingAttendenceComponent';
import ConceptSummaryComponent from './components/BusinessComponents/ConceptSummary/ConceptSummaryComponent';
import QuizComponent from './components/BusinessComponents/Quiz/QuizComponent';
import PracticeInProgress from './components/BusinessComponents/PracticeInProgress/PracticeInProgressComponent';
import UserScoreComponent from './components/BusinessComponents/UserScoreComponent/UserScoreComponent';
import JournalEntryComponent from './components/BusinessComponents/TEDJournalEntries/TEDJournalNewEntryComponent';
import TEDJournalEntries from './components/BusinessComponents/TEDJournalEntries/TEDJournalListEntriesComponent';
import UserPracticeComponent from './components/BusinessComponents/UserPracticeComponent/UserPracticeComponent';
import PracticeLibraryComponent from './components/BusinessComponents/UserPracticeComponent/PracticeLibraryComponent';
import ResourceLibraryComponent from './components/BusinessComponents/ResourceLibrary/ResourceLibraryComponent';
import QuotesLibraryComponent from './components/BusinessComponents/QuotesLibrary/QuotesLibraryComponent';
import ResourcesComponent from './components/BusinessComponents/ResourceLibrary/Resources';
import QuotesComponent from './components/BusinessComponents/QuotesLibrary/Quotes';
import ContentComponent from './components/FunctionalComponents/Content/ContentComponent';
import OpenSurveyComponent from './components/BusinessComponents/OpenSurvey/OpenSurveyComponent';
import ProgramSurveyQuestionsComponent from './components/BusinessComponents/ProgramSurveyComponent/ProgramSurveyQustionsComponent';
import UserAccountComponent from './components/BusinessComponents/UserAccount/UserAccountComponent';

// Setting screen is in backlog now - enable this route while working
// import SettingsComponent from './components/BusinessComponents/SettingsComponent/SettingsComponent';

const privateRoutes = [
    {
        path: "/submitScore",
        exact: true,
        component: ExternalUserScoreComponent
    },
    {
        path: "/dashboard",
        exact: true,
        component: DashboardComponent
    },
    {
        path: "/programoverview",
        exact: true,
        component: ProgramOverview
    },
    {
        path: "/thinkintro",
        exact: true,
        component: ThinkIntroComponent
    },
    {
        path: "/content/:id",
        exact: true,
        component: ContentComponent,
    },
    {
        path: "/profile/:user",
        exact: true,
        component: ProfileComponent
    },
    {
        path: "/profile/:user/:search/:referrer/:additionalparam",
        exact: true,
        component: ProfileComponent
    },
    {
        path: "/profile/:user/:search/:referrer",
        exact: true,
        component: ProfileComponent
    },
    {
        path: "/profile/:user/:referrer",
        exact: true,
        component: ProfileComponent
    },
    {
        path: "/do",
        exact: true,
        component: DoComponent
    },
    {
        path: "/engage/:search",
        exact: true,
        component: EngageComponent
    },
    {
        path: "/module/:moduleId/:moduleStepId",
        exact: true,
        component: ModuleComponent
    },
    {
        path: "/concept/:id/:moduleStepId/:moduleId",
        exact: true,
        component: ConceptComponent
    },
    {
        path: "/quiz/:id/:moduleStepId/:moduleId",
        exact: true,
        component: QuizComponent
    },
    {
        path: "/clientadmindashboard",
        exact: true,
        component: ClientAdminDashboardComponent
    },
    {
        path: "/facilitordashboard",
        exact: true,
        component: ClientAdminDashboardComponent
    },
    {
        path: "/mnfacilitatordashboard",
        exact: true,
        component: ClientAdminDashboardComponent
    },
    {
        path: "/createsurvey",
        exact: true,
        component: Survey,
    },
    {
        path: "/conceptsummary/:moduleId",
        exact: true,
        component: ConceptSummaryComponent
    },
    {
        path: "/singlepractice/:id",
        exact: true,
        component: PracticeInProgress
    },
    {
        path: "/takesurvey",
        exact: true,
        component: TakeSurvey
    },
    {
        path: "/newjournalentry",
        exact: true,
        component: JournalEntryComponent
    },
    {
        path: "/tedlogbook",
        exact: true,
        component: TEDJournalEntries,
    },
    {
        path: "/mypractices",
        exact: true,
        component: UserPracticeComponent
    },
    {
        path: "/practicelibrary",
        exact: true,
        component: PracticeLibraryComponent
    },
    {
        path: "/meetingattendance",
        exact: true,
        component: MeetingAttendanceComponent
    },
    {
        path: "/userscore",
        exact: true,
        component: UserScoreComponent
    },
    {
        path: "/resourcelibrary",
        exact: true,
        component: ResourceLibraryComponent
    },
    {
        path: "/quoteslibrary",
        exact: true,
        component: QuotesLibraryComponent
    },
    {
        path: "/myresources",
        exact: true,
        component: ResourcesComponent
    },
    {
        path: "/myquotes",
        exact: true,
        component: QuotesComponent
    },
    {
        path:"/opensurvey",
        exact:true,
        component:OpenSurveyComponent
    },
    // route for the setting is in backlog now
    // {
    //     path: "/settings",
    //     exact: true,
    //     component: SettingsComponent
    // },
    {
        path: "/pagenotfound",
        exact: true,
        component: ErrorPageComponent
    },
    {
        path: "/",
        exact: true,
        component: () => <Redirect to="/dashboard" />
    },
    {
        path:"/programsurveyquestions",
        exact:true,
        component:ProgramSurveyQuestionsComponent
    },
    {
        path:"/account",
        exact:true,
        component:UserAccountComponent
    }
];

const publicRoutes = [
    {
        path: "/login",
        exact: true,
        component: LoginComponent,
    },
    {
        path: "/forgotPassword",
        exact: true,
        component: ForgotPasswordComponent
    },
    {
        path: "/register",
        exact: true,
        component: UserRegistrationComponent
    },
    {
        path: "/submitScore",
        exact: true,
        component: ExternalUserScoreComponent
    },
    {
        path:"/opensurvey",
        exact:true,
        component:OpenSurveyComponent
    },
    {
        path: "/pagenotfound",
        exact: true,
        component: ErrorPageComponent
    },
    {
        path: "/resetPassword",
        exact: true,
        component: ResetPasswordComponent
    },
    {
        path: "/",
        exact: true,
        component: () => <Redirect to="/login" />
    }
];

export { privateRoutes, publicRoutes }

