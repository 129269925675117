import React, { useContext } from 'react';
import TextComponent from '../Text/TextComponent';
import './PaginationComponent.css';
import { ThemeContext } from "../../../Contexts/ThemeContext";
import "../../../darkcustom.css"


export interface IPaginationComponent {
    currentPage: number;
    pageSize: number;
    totalItems: number;
    totalPages: number;
    onPageHandler?: (data: number) => void;
};

const PaginationComponent = (props: IPaginationComponent) => {
    const { theme } = useContext(ThemeContext);

    const {
        currentPage,
        totalPages,
        onPageHandler
    } = props;
    const pageHandler = (event: any) => {
        onPageHandler && onPageHandler(Number(event.currentTarget.id))
    };

    return <>
        {theme === "light" ? (
            <nav aria-label="Page navigation example">
                <ul className="pagination pagination-wrapper">
                    {(() => {
                        const rows = [];
                        let j = 1;
                        while (j <= totalPages) {
                            if (j <= 2 ||
                                j >= totalPages - 3 ||
                                j >= currentPage - 1 && j <= currentPage + 1) {
                                rows.push(<li id={String(j)}
                                    className={`page-item cursor-pointer ${currentPage === j && "active"}`}
                                    key={j}
                                    onClick={pageHandler}><span className={`page-link`} >
                                        <TextComponent text={String(j)} /></span></li>)
                                j++;
                            }
                            else {
                                rows.push(<li className='page-item' key={j}><span>...</span></li>);
                                j = j < currentPage ? currentPage - 1 : totalPages - 2;
                            }
                        }
                        return rows;
                    })()}
                </ul>
            </nav>
        ) : <nav aria-label="Page navigation example">
            <ul className="pagination pagination-wrapper">
                {(() => {
                    const rows = [];
                    let j = 1;
                    while (j <= totalPages) {
                        if (j <= 2 ||
                            j >= totalPages - 3 ||
                            j >= currentPage - 1 && j <= currentPage + 1) {
                            rows.push(<li id={String(j)}
                                className={`page-item cursor-pointer ${currentPage === j && "active"}`}
                                key={j}
                                onClick={pageHandler}>
                                <span style={{ backgroundColor: "#1c2930", color: "#fff", border: "0px solid" }} className={`page-link`} >
                                    <TextComponent text={String(j)} /></span></li>)
                            j++;
                        }
                        else {
                            rows.push(<li className='page-item' key={j}><span>...</span></li>);
                            j = j < currentPage ? currentPage - 1 : totalPages - 2;
                        }
                    }
                    return rows;
                })()}
            </ul>
        </nav>}
    </>
}

export default PaginationComponent;