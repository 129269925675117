import React, { useEffect, useState, useContext } from "react";
import { Headers } from "../../../Utils/Enums";
import { TableColumn } from "react-data-table-component";
import HeaderComponent from "../Header/HeaderComponent";
import TableComponent from "../Table/TableComponent";
import "./UserPracticeComponentsStyle/PracticeTableComponent.css";
import BootstrapModal from "../BootstrapModal/BootstrapModal";
import TextComponent from "../Text/TextComponent";
import { Row } from "react-bootstrap";
import { isMobileOnly } from 'react-device-detect';
import PracticeTableExpandableRowComponent from "./PracticeTableExpandableRowComponent";
import { ThemeContext } from "../../../Contexts/ThemeContext"
import "../../../darkcustom.css"
interface IpracticesData {
    practiceId: number
    title: string;
    summary: string;
    otherCurrent: number;
    otherCompleted: number;
    rating: number;
    isAdded: boolean;
    practiceStatus: string
}

interface Iprops {
    practiceData: any[];
    actionOnPractice: (id: number) => Promise<void>;
    currentPractice: (id: number) => Promise<void>;
    deletePractice?: any;
    getClickedPractice?: (practiceId: number) => Promise<void>
    selectedPractice?: any;
    setSelectedPractice?: any;
}

function PracticeTableComponent(props: Iprops) {
    let { theme } = useContext(ThemeContext)
    const {
        practiceData,
        actionOnPractice,
        currentPractice,
        deletePractice,
        getClickedPractice,
        selectedPractice,
        setSelectedPractice
    } = props;

    const [url, setUrl] = useState<string>("");
    const [practiceTitleClicked, setPracticeTitleClicked] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);

    useEffect(() => {
        const getUrl: string = window.location.pathname;
        setUrl(getUrl);
        //eslint-disable-next-line
    }, []);
    const hideBreakPoint = 400;//This is screenSize in pixel below which columns will be hidded
    let selectedPracticeModalBody: string = `<p><strong><u>Summary</u></strong></p>${selectedPractice?.summary || "<p>No Data Available</p>"}<p><strong><u>Instructions</u></strong></p>${selectedPractice?.instructions || "<p>No Data Available</p>"}`;

    const PracticesColumns: TableColumn<IpracticesData>[] = [
        {
            name: 'Description',
            selector: row => row.title,
            width: isMobileOnly ? 'auto' : '350px',
            cell: (row) => (
                <div className="mt-1 mb-1">
                    <div className="title" onClick={() => handlePracticeTitleClick(row)}>
                        {row.title}
                    </div>
                    <div className="description" data-tag="allowRowEvents">
                        {row.summary !== ""
                            ? <div dangerouslySetInnerHTML={{ __html: row.summary }}></div>
                            : "No Summary Found"
                        }
                    </div>
                </div>
            )
        },
        {
            name: "Others-Current",
            selector: (row) => row.otherCurrent,
            hide: hideBreakPoint,
            cell: (row) => (
                <div className="numbers">
                    {row.otherCurrent}
                </div>
            )
        },
        {
            name: "Others-Completed",
            selector: (row) => row.otherCompleted,
            hide: hideBreakPoint,
            cell: (row) => (
                <div className="numbers">
                    {row.otherCompleted}
                </div>
            )
        },
        {
            name: "Rating",
            selector: (row) => row.rating,
            sortable: true,
            hide: hideBreakPoint,
            width: "100px",
            cell: (row) => (
                <div className="numbers">
                    {row.rating === null ? "NA" : row.rating}
                </div>
            )
        },
        {
            name: "Status",
            hide: hideBreakPoint,
            width: url === "/practicelibrary" ? "87px" : "135px",
            cell: (row) => (
                <>
                    {url === "/practicelibrary" && (
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <div onClick={() => handleActionClick(row)}>
                                <img
                                    alt="favourite"
                                    src={
                                        theme === "light" ? (
                                            row.isAdded ? window.location.origin + "/images/favourite-filled.svg"
                                                : "/images/favourite-empty.svg"
                                        ) : row.isAdded ? window.location.origin + "/images/favourite-filled.svg"
                                                : "/images/favourite-empty-dark.svg"
                                    }
                                    width={"16px"}
                                    height={"16px"}
                                />
                            </div>
                            {theme === "light" ? (
                                <div className="border-bottom border-dark" onClick={() => handleActionClick(row)}>
                                    <TextComponent text="Add" customClassName="font-weight-600 added-text text-black" />
                                </div>
                            ) : <div className="tableTextColor" onClick={() => handleActionClick(row)}>
                                    <TextComponent text="Add" customClassName="font-weight-600 added-text text-#fff" />
                                </div>}

                        </div>
                    )}
                    {url === "/mypractices" && (
                        row.practiceStatus === "Completed" ?
                            <div>
                                <HeaderComponent title={"Completed"} size={Headers.H3} />
                            </div>
                            : row.practiceStatus === "InProgress"
                                ? <div>
                                    <HeaderComponent title={"In Progress"} size={Headers.H3} /> </div> :
                                <div>
                                    <HeaderComponent title={"Not Started"} size={Headers.H3} />
                                </div>
                    )}
                </>
            ),
        },
        {
            name: "Set",
            width: "70px",
            hide: hideBreakPoint,
            cell: (row) => (<>
                {
                    url === "/mypractices" && (
                        row.practiceStatus !== "InProgress" ?
                            <div>
                                {theme === "light" ? (
                                    <div className="border-bottom border-dark">
                                        <div data-bs-toggle="tooltip" data-bs-placement="bottom" title="Set as current practice" onClick={() => handleSetPractice(row)}>
                                            <TextComponent text="Set" customClassName="font-weight-600 text-dark" />
                                        </div>
                                    </div>
                                ) : <div className="tableTextColor">
                                        <div data-bs-toggle="tooltip" data-bs-placement="bottom" title="Set as current practice" onClick={() => handleSetPractice(row)}>
                                            <TextComponent text="Set" customClassName="font-weight-600 textColor" />
                                        </div>
                                    </div>}

                            </div> : <div></div>
                    )
                }
            </>)
        },
        {
            name: "Remove",
            hide: hideBreakPoint,
            width: "100px",
            cell: (row) => (
                <>
                    {url === "/mypractices" && (
                        <div>
                            {theme === "light" ? (
                                <div className="border-bottom border-dark">
                                    <div data-bs-toggle="tooltip" data-bs-placement="bottom" title="Remove practice" onClick={() => handleRemovePractice(row)}>
                                        <TextComponent text="Remove" customClassName="font-weight-600 text-dark " />
                                    </div>
                                </div>
                            ) : <div className="tableTextColor">
                                    <div data-bs-toggle="tooltip" data-bs-placement="bottom" title="Remove practice" onClick={() => handleRemovePractice(row)}>
                                        <TextComponent text="Remove" customClassName="font-weight-600 textColor " />
                                    </div>
                                </div>}

                        </div>
                    )}
                </>
            ),
        },
    ];

    const handleActionClick = (row: any) => {
        actionOnPractice(row.practiceId);
    }

    const handleSetPractice = (row: any) => {
        currentPractice(row.practiceId);
    }

    const handleRemovePractice = (row: any) => {
        deletePractice(row.practiceId);
    }

    const handlePracticeTitleClick = (row: any) => {
        setPracticeTitleClicked(true);
        setShow(true);
        if (getClickedPractice) {
            getClickedPractice(row.practiceId);
        }
    }

    const handleClose = () => {
        setShow(false);
        setSelectedPractice({
            id: 0,
            details: "",
            instructions: "",
            summary: "",
            title: ""
        });
    }

    const practiceLibraryExpandableRowComponent = PracticeTableExpandableRowComponent(theme, url, setShow, setPracticeTitleClicked, props);
    return (
        <>
            <div>
                <TableComponent
                    data={practiceData}
                    columns={PracticesColumns}
                    tableTitle={""}
                    totalRows={5}
                    rowsPerpage={10}
                    handlePageChange={<></>}
                    handleRowsPerPage={<></>}
                    pageAction={<></>}
                    isPagination={false}
                    expandableRows={isMobileOnly}
                    expandableRowsComponent={practiceLibraryExpandableRowComponent}
                    expandableRowsComponentProps={{ "url": url }}
                />
            </div>
            {
                practiceTitleClicked && <BootstrapModal
                    handleClose={handleClose}
                    show={show}
                    modalTitle={selectedPractice.title}
                    modalBody={selectedPracticeModalBody}
                    closeButtonText={"Close"}
                />
            }
        </>
    );
}

export default PracticeTableComponent;