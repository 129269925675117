import constant from "../Utils/Config";
import {isTokenExpired,clearLocalStorageAndRedirect, getAuthToken} from "../Utils/Utils";

class ChatMessage {
    async GetModuleQuestionByModuleId(moduleId: number, index: number) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        try {            
            const response = await fetch(`${constant.getModuleQuestionByModuleId}?moduleId=${moduleId}&index=${index}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            });
            if (!response.ok) {
                return { data: "error", ok: false };
            } else {
                const result = await response.json();
                if (result) {
                    return { data: result, ok: true };
                } else {
                    return { data: "error", ok: false };
                }
            }
        } catch (error) {
            return { data: error, ok: false };
        }
    };

    async moduleQuestionUserResponse(moduleQuestionId: number, moduleQuestionAnswer: string) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        try {
            const response = await fetch(`${constant.moduleQuestionUserResponse}?moduleQuestionId=${moduleQuestionId}&moduleQuestionAnswer=${moduleQuestionAnswer}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            });
            if (!response.ok) {
                return { data: "error", ok: false };
            } else {
                const result = await response.json();
                if (result) {
                    return { data: result, ok: true };
                } else {
                    return { data: "error", ok: false };
                }
            }
        } catch (error) {
            return { ok: false };
        }
    };
}
export default ChatMessage;