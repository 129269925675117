import React, { useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useHistory } from "react-router";
import UserAuth from "../../../Services/Login";
import { Headers } from "../../../Utils/Enums";
import { createNotification } from "../../../Utils/Toast";
import ErrorInputComponent from "../../FunctionalComponents/ErrorInput/ErrorInputComponent";
import HeaderComponent from "../../FunctionalComponents/Header/HeaderComponent";
import "./LoginComponent.css";
import ReCAPTCHA from "react-google-recaptcha";
import { isMNFacilitator } from "../../../Utils/Utils";
interface IuserData {
    email: string;
    password: string
}
const LoginComponent = () => {

    let history = useHistory();
    const login = new UserAuth();
    const [, setIsRememberMe] = useState<boolean>(false);
    const [isVerified, setIsVerified] = useState<boolean>(false);
    const [userData, setUserData] = useState<IuserData>({
        email: "",
        password: ""
    });
    const [errorToggel, setErrorToggel] = useState<any>({
        email: "",
        password: "",
    });
    const [showSpinner, setShowSpinner] = useState<boolean>(false);

    const validateForm = async () => {
        //eslint-disable-next-line
        let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!userData.email.match(mailFormat)) {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    email: "Please enter valid email address"
                }
            })
            return false;
        } else {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    email: ""
                }
            })
        }
        if (userData.password.length === 0 || userData.password.length < 6) {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    password: "Please enter valid password"
                }
            })
            return false;
        } else {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    password: ""
                }
            })
        }
        return true;
    }

    const handleLoginClick = async () => {
        let reqBody = {
            username: userData.email,
            password: userData.password,
            rememberMe: true
        }
        if (!isVerified) return setIsVerified(false);
        if (await validateForm()) {
            setShowSpinner(true);
            let response: any = await login.userLogin(reqBody);
            if (response.status) {
                setShowSpinner(false);
                if (response.data.profileImg && response.data.profileImg.length > 0) {
                    response.data.profileImg = response.data.profileImg.replace('/api', '');
                }
                localStorage.setItem("userConfig", JSON.stringify(response.data));
                localStorage.setItem("API_Token", response.data.token);
                localStorage.setItem("SurveyId", response.data.surveyId);
                localStorage.setItem("selectedTab", response.data.selectedTab);
                localStorage.setItem("selectedStage", response.data.selectedStage);
                localStorage.setItem("moduleId", response.data.moduleId);
                localStorage.setItem("stageId", response.data.stageId);
                if (isMNFacilitator()) {
                    window.location.href = "/mnfacilitatordashboard";
                }
                else {
                    window.location.href = "/dashboard"
                }
            } else {
                setShowSpinner(false);
                createNotification("error", response.message);
            }
        }
    }
    const onHandlecAPTCHA = (value: string | null) => {
        setIsVerified(true);
    }
    return (
        <>
            <div className="position-relative min-vh-100">
                <div className="row d-flex flex-column justify-content-center mx-3 pb-5">
                    <div className="d-flex justify-content-center my-5 mb-0">
                        <img alt="login" className="cursor-pointer mn-image" onClick={() => history.push("/login")} src={window.location.origin + "/images/MindNavigator_latest.svg"} />
                    </div>
                    <div className="container d-flex flex-column justify-content-center mt-5 col-md-8 col-lg-6 col-xl-4">
                        <div className="d-flex justify-content-center">
                            <div className="login-panel-config">
                                <HeaderComponent title="Login" size={Headers.H1} customClassName={"font-weight-600"} />
                            </div>
                        </div>
                        <section className="row d-flex justify-content-center">
                            <Form className="login-panel-config">
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label className="title-font">Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter email"
                                        className="input-font"
                                        onChange={(e: any) => setUserData((states: any) => {
                                            return {
                                                ...states,
                                                email: e.target.value
                                            }
                                        })}
                                    />
                                    {<ErrorInputComponent error={errorToggel.email} />}
                                </Form.Group>
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label className="title-font">Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Password"
                                        className="input-font"
                                        onChange={(e: any) => setUserData((states: any) => {
                                            return {
                                                ...states,
                                                password: e.target.value
                                            }
                                        })}
                                    />
                                    {< ErrorInputComponent error={errorToggel.password} />}
                                </Form.Group>
                                <div className="d-flex justify-content-between mb-3 flex-row">
                                    <Form.Group controlId="formBasicCheckbox">
                                        <Form.Check
                                            type="checkbox"
                                            label="Remember me?"
                                            className="title-font"
                                            onClick={(states) => setIsRememberMe((prevState: boolean) => !prevState)}
                                        />
                                    </Form.Group>
                                    <div>
                                        <p
                                            tabIndex={0}
                                            role="button"
                                            aria-pressed="false"
                                            className="forgot-password text-black cursor-pointer title-font"
                                            onClick={() => history.push("/forgotPassword")}
                                        >
                                            Forgot Password
                                        </p>
                                    </div>
                                </div>
                                <ReCAPTCHA className="mb-3"
                                    sitekey="6LdgT38fAAAAAMeymbgFHZH-AeuFf9ySh2q31BnS"
                                    onChange={onHandlecAPTCHA}
                                />
                                <div className="d-grid gap-2">
                                    {
                                        showSpinner ?
                                            <Button variant="primary" className="text-secondary" disabled>
                                                <span className="mx-2 text-secondary">
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                                Processing Login...
                                            </Button>
                                            :
                                            <Button variant="primary"
                                                className="font-weight-600 text-color-182C34" type="button"
                                                onClick={() => handleLoginClick()}
                                                disabled={!isVerified}
                                            >
                                                Login
                                            </Button>
                                    }
                                </div>
                            </Form>
                        </section>
                    </div>
                </div>
                <footer
                    className="d-flex border-top footer text-muted footer-font position-absolute bottom-0 w-100 h-2.5">
                    <div className="container my-2">
                        &copy; 2021 - MindNavigator
                    </div>
                </footer>
            </div>

        </>
    );
}

export default LoginComponent;