import React, { useState, useEffect, useRef, useContext } from "react";
import { TableColumn } from 'react-data-table-component';
import Style from "./FileUploadComponent.module.css";
import { ProgressBar, Form, Col } from "react-bootstrap";
import { ThemeContext } from "../../../Contexts/ThemeContext";
import "../../../darkcustom.css";

interface IdataRow {
    title: string;
};

interface IselectedFiles {
    title: string
}

interface Iprops {
    data: IselectedFiles[];
    columns: TableColumn<IdataRow>[];
    setFiles: any;
    files: any;
    bulkFileUpload: any;
    bulkUploadData: string;
    bulkUploadSuccess: boolean;
    setSelectedDelemeter: any
}

const FileUploadComponent = (props: Iprops) => {

    const { bulkUploadSuccess, setFiles, bulkFileUpload, setSelectedDelemeter } = props;
    const ref = useRef<HTMLInputElement>(null);;
    const [value, setValue] = useState<number>(0)
    const [isFileSelected, setIsFileSelected] = useState<boolean>(false);
    const [isProgress, setIsProgress] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<any>();
    const [isDisable, setIsDisable] = useState<boolean>(true);
    const [isButtonEnable, setIsButtonDisable] = useState<any>({
        fileSelected: false,
        delemeterSelected: false
    });
    const { theme } = useContext(ThemeContext);

    useEffect(() => {
        validateForm();
        //eslint-disable-next-line
    }, [isButtonEnable])

    useEffect(() => {
        showProgress();
    }, [isProgress, submitted]);

    useEffect(() => {
        selectedFileCleanup();
        //eslint-disable-next-line
    }, [bulkUploadSuccess])

    const showProgress = () => {
        const interval: any = setInterval(() => {
            setValue((oldValue: number) => {
                let newValue: number = oldValue + 25;
                if (newValue === 100) {
                    clearInterval(interval);
                    setIsProgress(false)
                    newValue = 0;
                }
                return newValue;
            })
        }, 1000);
    }

    const fileSelectedHandler = (e: any) => {
        setFiles(Array.from(e.target.files));
        setSelectedFile(e.target.files[0].name)
        setIsFileSelected(true);

    };

    const buttonClickHnadler = (e: any) => {
        e.preventDefault();
        if (isFileSelected) {
            showProgress();
            setIsProgress(true)
            bulkFileUpload();
        }
    }

    const deleteSelectedFile = () => {
        setSelectedFile('');
        if (ref && ref.current) {
            ref.current.value = "";
        }
        setIsFileSelected(false);
        setIsButtonDisable((states: any) => {
            return {
                fileSelected: false,
                delemeterSelected: false
            }
        });
        setIsDisable(true);
    }

    const selectedFileCleanup = () => {
        if (bulkUploadSuccess) {
            if (ref && ref.current) {
                ref.current.value = "";
                setSelectedFile('');
                setIsFileSelected(false);
            }
        }
    }

    const validateForm = () => {
        if (isButtonEnable.fileSelected && isButtonEnable.delemeterSelected) {
            setIsDisable(false)
            return true;
        }
        return false;
    }

    const handleDelemeterChange = (value: string) => {
        if (value === "1") {
            setSelectedDelemeter("%2C");
        } else {
            setSelectedDelemeter("%3B");
        }
    }

    return (
        <>
            <div className={Style.fileUpload}>
                <input
                    type="file"
                    accept=".csv"
                    id="inputFile"
                    ref={ref}
                    onChange={(e) => {
                        fileSelectedHandler(e);
                        setIsButtonDisable((states: any) => {
                            return {
                                ...states,
                                fileSelected: true
                            }
                        })
                    }}
                />

                <p>Drag your files here or click in this area</p>

                <div style={{ height: "30px" }}>
                    {submitted && isProgress && (<ProgressBar
                        now={value}
                        label={`${value}%`}
                        max={100}
                        variant="warning"

                    />)}
                </div>

                <Form.Group as={Col} controlId="formGridCountry" className="col-3">
                    <Form.Label>Select Delimiter</Form.Label>
                    {theme === "light" ? (
                        <>
                            <Form.Select
                                onChange={(e: any) => {
                                    handleDelemeterChange(e.target.value);
                                    setIsButtonDisable((states: any) => {
                                        return {
                                            ...states,
                                            delemeterSelected: true
                                        }
                                    })
                                }}
                            >
                                <option value={0}>{"Select Delimiter"}</option>
                                <option value={1}>{"Comma (,)"}</option>
                                <option value={2}>{"Semicolon (;)"}</option>
                            </Form.Select>
                        </>
                    ) :
                        <>
                            <Form.Select
                                className="clientSearchBox"
                                onChange={(e: any) => {
                                    handleDelemeterChange(e.target.value);
                                    setIsButtonDisable((states: any) => {
                                        return {
                                            ...states,
                                            delemeterSelected: true
                                        }
                                    })
                                }}
                            >
                                <option value={0}>{"Select Delimiter"}</option>
                                <option value={1}>{"Comma (,)"}</option>
                                <option value={2}>{"Semicolon (;)"}</option>
                            </Form.Select>
                        </>}
                    <button
                        type="button"
                        className="my-4"
                        disabled={isDisable}
                        onClick={(e) => { buttonClickHnadler(e); setSubmitted(true) }}
                    >
                        Upload
                    </button>
                </Form.Group>

                {isFileSelected
                    ? <>
                        <span> {selectedFile} </span>
                        <span>
                            {theme === "light" ? (
                                <img
                                    alt="delete"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => deleteSelectedFile()} src={window.location.origin + "/images/close.svg"}
                                />
                            ) : <img
                                    alt="delete"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => deleteSelectedFile()} src={window.location.origin + "/images/close-dark.svg"}
                                />}
                        </span>
                    </>
                    : null
                }
            </div>
        </>
    );
};

export default FileUploadComponent;

