import React, { useContext } from "react";
import { ICharacteristicViewModel } from "../Characteristics/CharacteristicsComponent";
import CircleImageComponent from "../CircleImage/CircleImageComponent";
import TextComponent from "../Text/TextComponent";
import multiChecksStyles from "./CharacteristicsRadioComponent.module.css";
import { ThemeContext } from "../../../Contexts/ThemeContext";
import "../../../darkcustom.css"

const CharacteristicsRadioComponent = (props: ICharacteristicViewModel) => {
    const { theme } = useContext(ThemeContext);
    const clickHandler = () => {
        /* Disable button after submi */
        const elSubmit = document.getElementById('charsSubmit');
        if (elSubmit) elSubmit.style.pointerEvents = "none";

        /* Disable all the checkboxes after submit */
        let arrSelected: number[] = [];
        let checkboxes = document.querySelectorAll("input[type=radio]");
        checkboxes.forEach((x: any) => {
            x.style.pointerEvents = "none";
            if (x.checked)
                arrSelected.push(Number(x.id));

        });
        let tempComponent: any;
        tempComponent = document.getElementById('charsRadioSubmit');
        tempComponent.disabled = true;
        props.onTedCharsHandler(arrSelected, Number(props.tedMessageId));
    }
    return <>
        {theme === "light" ? (
            <>
                <div className={`filter-156-156-156-025 text-color-2D5362 background-FFFFFF d-flex flex-column right-message margin-bottom-16`}>
                    <div className={`${multiChecksStyles['arrowLeft']} position-absolute color-FFFFFF`}>
                        <div className="position-relative">
                            <div className={`${multiChecksStyles['tedConfigRight']} position-absolute`}>
                                <CircleImageComponent size={'imageSize36'} image={"Ted.png"} />
                            </div>
                        </div>
                    </div>
                    {props.text && <TextComponent text={props.text} customClassName={'chatMessageText'} />}
                </div>

                <div
                    className={`filter-156-156-156-025 text-color-2D5362 background-FFFFFF d-flex flex-column left-message margin-bottom-16`}>
                    {props.characteristicOptionViewModel && <div className='d-flex flex-column margin-top-8'>
                        {props.characteristicOptionViewModel.map((item, index: number) =>
                            <div key={index} className="d-flex margin-top-16">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio" name="characteristicsOptionChoice"
                                        id={String(item.id)}
                                        role="radio"
                                    />
                                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        {item.value}
                                    </label>
                                </div>
                            </div>
                        )}
                    </div>}
                    <button
                        id="charsRadioSubmit"
                        className={`btn btn-primary background-26CAC1 font-weight-600 border-E0E7EB w-75 margin-top-16`}
                        type="button"
                        onClick={clickHandler}
                        role="button">
                        <TextComponent text={"Submit"} customClassName={'text-color-182C34'} />
                    </button>
                    <div className={`${multiChecksStyles['arrowRight']} position-absolute color-FFFFFF`}>
                        <div className="position-relative">
                            <div className={`${multiChecksStyles['tedConfigLeft']} position-absolute`}>
                                <CircleImageComponent size={'imageSize36'} image={'Ted.png'} />
                            </div>
                        </div>
                    </div>
                </div></>
        ) : <>
            <div style={{ backgroundColor: "#1c2930" }} className={`filter-156-156-156-025 text-color-2D5362 background-FFFFFF d-flex flex-column right-message margin-bottom-16`}>
                <div style={{ color: "rgb(28, 41, 48)" }} className={`${multiChecksStyles['arrowLeft']} position-absolute color-FFFFFF`}>
                    <div className="position-relative">
                        <div className={`${multiChecksStyles['tedConfigRight']} position-absolute`}>
                            <CircleImageComponent size={'imageSize36'} image={"Ted.png"} />
                        </div>
                    </div>
                </div>
                {props.text && <TextComponent text={props.text} customClassName={'chatMessageText'} />}
            </div>

            <div style={{ backgroundColor: "#1c2930", color: "#fff" }}
                className={`filter-156-156-156-025 text-color-2D5362 background-FFFFFF d-flex flex-column left-message margin-bottom-16`}>
                {props.characteristicOptionViewModel && <div className='d-flex flex-column margin-top-8'>
                    {props.characteristicOptionViewModel.map((item, index: number) =>
                        <div key={index} className="d-flex margin-top-16">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio" name="characteristicsOptionChoice"
                                    id={String(item.id)}
                                    role="radio"
                                />
                                <label className="form-check-label" htmlFor="flexRadioDefault2">
                                    {item.value}
                                </label>
                            </div>
                        </div>
                    )}
                </div>}
                <button
                    id="charsRadioSubmit"
                    className={`btn btn-primary background-26CAC1 font-weight-600 border-E0E7EB w-75 margin-top-16`}
                    type="button"
                    onClick={clickHandler}
                    role="button">
                    <TextComponent text={"Submit"} customClassName={'text-color-182C34'} />
                </button>
                <div style={{ color: "rgb(28, 41, 48)" }} className={`${multiChecksStyles['arrowRight']} position-absolute color-FFFFFF`}>
                    <div className="position-relative">
                        <div className={`${multiChecksStyles['tedConfigLeft']} position-absolute`}>
                            <CircleImageComponent size={'imageSize36'} image={'Ted.png'} />
                        </div>
                    </div>
                </div>
            </div></>}
    </>
}
export default CharacteristicsRadioComponent;