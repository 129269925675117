import React from 'react';
import TextComponent from '../Text/TextComponent';
import buttonStyles from './ButtonComponent.module.css'

interface ISubmitButtonComponent{
    label: string;
    customClassName?: string;
    id?: string;
    disabled?: boolean;
};

const ButtonComponent=(props: ISubmitButtonComponent)=>{
    return <>
        <button 
        type="submit" 
        id={props.id}
        role="button"
        className={`btn btn-primary ${props.customClassName&&buttonStyles[props.customClassName]}`}
        disabled={props.disabled}>
            <div><TextComponent text={props.label}/></div>
            </button>
    </>
}

export default ButtonComponent;