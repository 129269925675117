import "./DashboardCardsListComponent.css";
import React, { useEffect, useState, useContext } from 'react';
import DashboardCardItemComponent, { IDashboardCardItemComponent, IStepData } from "../DashboardCardItem/DashboardCardItemComponent";
import Dashboard from "../../../Services/Dashboard.service";
import { ThemeContext } from "../../../Contexts/ThemeContext";
export interface IDashboardCardsListComponents {
    items: IDashboardCardItemComponent[];
    moduleId: number;
    onErrorItemHandler?: () => void;
    isPracticeLibraryHasData?: boolean;
    selectedTab: string;
}
const DashboardCardsListComponent = (props: IDashboardCardsListComponents) => {
    let { theme } = useContext(ThemeContext);
    const [items, setItems] = useState(props.items);
    const [inProgress, setInProgress] = useState<any>({
        think: false,
        engage: false,
        do: false
    });
    const [completed, setCompleted] = useState<any>({
        think: false,
        engage: false,
        do: false
    });

    const dashboard = new Dashboard();

    useEffect(() => {
        setItems(props.items)
    }, [props.items]);

    useEffect(() => {
        setButtonStatus();
        //eslint-disable-next-line
    }, [props.selectedTab]);

    const ChangeStatusHandler = async (data: IStepData) => {
        let updatedModuleStep = await dashboard.updateModuleStep(data);
        if (!updatedModuleStep.ok) onErrorItemHandler();
        setItems(updatedModuleStep.data);
    }

    const setButtonStatus = () => {
        //eslint-disable-next-line
        props.items?.map((data: any) => {
            if (data.title === "Think") {
                data.userModuleStepStatus === "InProgress"
                    ? setInProgress((states: any) => {
                        return {
                            ...states,
                            think: true
                        }
                    })
                    : data.userModuleStepStatus === "NotStarted" ?
                        setCompleted((states: any) => {
                            return {
                                ...states,
                                think: false
                            }
                        })
                        : setCompleted((states: any) => {
                            return {
                                ...states,
                                think: true
                            }
                        })
            }
            if (data.title === "Engage") {
                data.userModuleStepStatus === "InProgress"
                    ? setInProgress((states: any) => {
                        return {
                            ...states,
                            engage: true
                        }
                    })
                    : data.userModuleStepStatus === "NotStarted" ?
                        setCompleted((states: any) => {
                            return {
                                ...states,
                                engage: false
                            }
                        })
                        : setCompleted((states: any) => {
                            return {
                                ...states,
                                engage: true
                            }
                        })
            }
            if (data.title === "Do") {
                data.userModuleStepStatus === "InProgress"
                    ? setInProgress((states: any) => {
                        return {
                            ...states,
                            do: true
                        }
                    })
                    : setCompleted((states: any) => {
                        return {
                            ...states,
                            do: true
                        }
                    })
            }
        });
    }

    return <>
        {theme === "light" ? <div className="d-flex justify-content-between list-config custom-flex-row">
            {items?.map((item: any, index: any) =>
                <div>
                    <div key={index} className="dashboard-card-shadow">
                        <DashboardCardItemComponent
                            item={item}
                            moduleId={props.moduleId}
                            onChangeStatusHandler={ChangeStatusHandler}
                            inProgress={inProgress}
                            completed={completed}
                            isPracticeLibraryHasData={props.isPracticeLibraryHasData}
                        />
                    </div>
                </div>
            )}
        </div> : <div className="dark-config d-flex justify-content-between list-config custom-flex-row">
            {items?.map((item: any, index: any) =>
                <div>
                    <div style={{ backgroundColor: "#1c2930" }} key={index} className="dashboard-card-shadow">
                        <DashboardCardItemComponent
                            item={item}
                            moduleId={props.moduleId}
                            onChangeStatusHandler={ChangeStatusHandler}
                            inProgress={inProgress}
                            completed={completed}
                            isPracticeLibraryHasData={props.isPracticeLibraryHasData}
                        />
                    </div>


                </div>
            )}
        </div>}
    </>
}

export default DashboardCardsListComponent;

function onErrorItemHandler() {
    throw new Error("Function not implemented.");
}
