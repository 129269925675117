import React, { useContext } from 'react';
import TextComponent from '../Text/TextComponent';
import "./BadgeComponent.css";
import { ThemeContext } from '../../../Contexts/ThemeContext';
interface IBadgeComponent {
    title: string;
    customClass: string;
}
const BadgeComponent = (props: IBadgeComponent) => {
    const { theme } = useContext(ThemeContext)
    return <>
        <div className="badge badge-pill badge-secondary">
            {theme === "light" ? (
                <TextComponent
                    text={props.title}
                    customClassName={props.customClass} />
            ) : <TextComponent
                    text={props.title}
                    customClassName={"text-config text-color-2D5362 background-E0E7EB text-color-182C34 font-size-14 line-height-20 buttonText"} />
            }
        </div>
    </>
}
export default BadgeComponent;