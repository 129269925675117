import React, { useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import ForgotPassword from "../../Services/ForgotPassword.service";
import { Button, Form, Spinner } from "react-bootstrap";
import HeaderComponent from "../FunctionalComponents/Header/HeaderComponent";
import { Headers } from "../../Utils/Enums";
import ErrorInputComponent from "../FunctionalComponents/ErrorInput/ErrorInputComponent";
import { createNotification } from "../../Utils/Toast";
import "./ForgotResetComponent.css"

const ForgotPassowrdComponent = () => {

    const forgotPasswordService = new ForgotPassword();

    let history = useHistory();
    const [userEmail, setUserEmail] = useState<string>("");
    const [errorToggel, setErrorToggel] = useState<any>({
        email: "",
    });
    const [showSpinner, setShowSpinner] = useState<boolean>(false);

    const validateForm = async () => {
        //eslint-disable-next-line
        let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!userEmail.match(mailFormat)) {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    email: "Please enter valid email address"
                }
            });
            return false;
        } else {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    email: ""
                }
            });
        }
        return true;
    }

    const handleForgotPassword = async () => {
        if (await validateForm()) {
            setShowSpinner(true);
            let response: any = await forgotPasswordService.forgotPassword(userEmail);
            if (response.status) {
                setShowSpinner(false);
                createNotification("success", response.message);
                history.push("/login");
            } else {
                setShowSpinner(false);
                createNotification("error", response.message);
            }
        }
    }

    return (
        <div className="row d-flex flex-column justify-content-center mx-3">
            <div className="d-flex justify-content-center my-5">
                <Link to={"/login"} className="text-decoration-none cursor-pointer">
                    <img alt="Mind Navigator latest" src={window.location.origin + "/images/MindNavigator_latest.svg"} className="mn-image" />
                </Link>
            </div>
            <div className="container d-flex flex-column justify-content-center mt-5 col-md-8 col-lg-6 col-xl-4">
                <div>
                    <HeaderComponent title="Forgot Password" size={Headers.H1} customClassName={"font-weight-600"} />
                </div>
                <section className="row">
                    <Form>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label className="title-font">Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                className="input-font"
                                onChange={(e: any) => setUserEmail(e.target.value)}
                            />
                            {<ErrorInputComponent error={errorToggel.email} />}
                        </Form.Group>

                        <div className="d-grid gap-2 mt-1">
                            {
                                showSpinner ?
                                    <Button variant="primary" className="text-secondary" disabled>
                                        <span className="mx-2 text-secondary">
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        </span>
                                        Hold on...
                                    </Button>
                                    :
                                    <Button
                                        variant="primary"
                                        className="font-weight-600 text-color-182C34"
                                        disabled={userEmail.length === 0}
                                        type="button"
                                        onClick={() => handleForgotPassword()}
                                    >
                                        Forgot Password
                                    </Button>
                            }
                        </div>

                    </Form>
                </section>
            </div>
        </div>
    )
}
export default ForgotPassowrdComponent;