import React, { useState, useContext } from 'react';
import './InputGroupComponent.css';
import { BrowserTypes, isMobileOnly } from 'react-device-detect';
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import "../../../darkcustom.css";
import { ThemeContext } from "../../../Contexts/ThemeContext"

interface IInputGroup {
    placeholder?: string;
    disabled?: boolean;
    maxLength?: number;
    onValidationHandler?: (data: string) => void;
}
const InputGroupComponent = (props: IInputGroup) => {
    const { theme } = useContext(ThemeContext)
    const { transcript, resetTranscript } = useSpeechRecognition();
    const [isListening, setIsListening] = useState(false);
    const handleKeypress = (e: any) => {
        if (e.code === 'Enter' || e.key === 'Enter' || e.keyCode === 13) {
            clickHandler(e.target.value);
            resetTranscript();
        }
    };
    /* Temp */
    const clickHandler = (dataVal: string) => {
        if (dataVal && dataVal.length > 0) {
            props.onValidationHandler && props.onValidationHandler(dataVal);
        }

        let el: any;
        el = document.getElementsByClassName('inputComponent')[0];
        el.value = '';
        props.disabled && el.setAttribute('disabled', '');
    }
    const speechRecognitionStart = () => {
        setIsListening(true);
        SpeechRecognition.startListening({
            continuous: false,
        });
    }
    const speechRecognitionStop = () => {
        setIsListening(false);
        SpeechRecognition.stopListening();
        clickHandler(transcript);
    }

    return <>
        <div className="input-group align-self-end input-group-config">
            {theme === "light" ? (<input
                type="text"
                className="form-control text-color-848A8C inputComponent"
                placeholder={props.placeholder}
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                disabled={props.disabled ? props.disabled : false}
                onKeyUp={handleKeypress}
                maxLength={props.maxLength} />
            ) : <input
                    type="text"
                    className="form-control text-color-848A8C inputComponent searchBox"
                    placeholder={props.placeholder}
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    disabled={props.disabled ? props.disabled : false}
                    onKeyUp={handleKeypress}
                    maxLength={props.maxLength} />}

            {SpeechRecognition.browserSupportsSpeechRecognition() && !isListening && BrowserTypes.Chrome && <span className="input-group-text background-E0E7EB border-CED4DA cursor-pointer" id="microphoneGeneralChatStart" onClick={speechRecognitionStart}>
                <img src={window.location.origin + '/images/Microphone.png'} alt="Microphone start" id="" />
            </span>}
            {SpeechRecognition.browserSupportsSpeechRecognition() && isListening && BrowserTypes.Chrome && <span className="input-group-text background-10B9F4 border-CED4DA cursor-pointer" id="microphoneGeneralChatEnd" onClick={speechRecognitionStop}>
                <img src={window.location.origin + '/images/Microphone.png'} alt="Microphone stopped" />
            </span>}
        </div>
    </>
}

export default InputGroupComponent;
