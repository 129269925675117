import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { ISearchItem } from '../../../Services/Engage.service';
import TextComponent from '../Text/TextComponent';
import './PopoverSearchComponent.css';
import { ThemeContext } from '../../../Contexts/ThemeContext';

export interface IRole {
    id: string;
    role: string;
    type: string;
}
export interface IPopoverSearchComponent {
    item: ISearchItem;
    roles?: String[];
    canAddContacts: boolean;
    url: string;
    onAddScorerHandler?: (data: IRole) => void;
    onDeleteExternalHandler?: (data: string) => void;
    onDeleteContactHandler?: (data: string) => void;
    onAddContactHandler?: (data: string) => void;
}
const PopoverSearchComponent = (props: IPopoverSearchComponent) => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const { theme } = useContext(ThemeContext);
    const ProfileHandler = () => {
        let selectedElements = document.querySelectorAll('.popover-item-search-config');
        for (var i = 0; i < selectedElements.length; i++) {
            selectedElements[i].classList.remove('d-block');
            selectedElements[i].classList.add('d-none');
        }
        var popoverSearchOpenCoockie = localStorage.getItem("popoverSearchOpen");
        if (popoverSearchOpenCoockie) {
            setPopoverOpen(false);
            localStorage.removeItem("popoverSearchOpenCoockie");
        }
        if (popoverOpen) {
            setPopoverOpen(false);
        } else {
            setPopoverOpen(true);
        }
    };
    //eslint-disable-next-line
    const target = document.querySelector('#myTarget')
    const AddContactHandler = () => {
        props.onAddContactHandler && props.onAddContactHandler(props.item.id);
    };
    const closePopup = () => {
        setPopoverOpen(false);
    }

    return (
        <div className="position-relative">
            {theme === "light" ? (
                <button id={"props"} type="button" className="border-FFFFFF background-FFFFFF mr-1" onClick={ProfileHandler}>
                    <img src={window.location.origin + '/images/chevron-right.svg'}
                        alt="chevron-right" className="iconPopover" />
                </button>
            ) :
                <button style={{ backgroundColor: "#1c2930", border: "0px solid" }} id={"props"} type="button" className="border-FFFFFF background-FFFFFF mr-1" onClick={ProfileHandler}>
                    <img src={window.location.origin + '/images/right-chevron-dark.svg'}
                        alt="chevron-right" className="iconPopover rightImg" />
                </button>}
            <div className={`position-absolute popover-item-search-config ${popoverOpen ? "d-block" : "d-none"}`} onMouseLeave={closePopup}>
                {theme === "light" ? (
                    <div className="d-flex flex-column">
                        <Link to={props.url} className={`text-decoration-none ${(!props.canAddContacts || props.item.isContact) && 'last-item'}`}>
                            <div className={`popOverItem cursor-pointer ${(!props.canAddContacts || props.item.isContact) && 'last-item'}`}>
                                <TextComponent text={'View profile'} customClassName={'text-color-182C34'} />
                            </div>
                        </Link>
                        {!props.item.isContact && props.canAddContacts && <div className="popOverItem cursor-pointer last-item" onClick={AddContactHandler}>
                            <TextComponent text={'Add to network'} />
                        </div>}
                    </div>
                ) :
                    <>
                        <div className="d-flex flex-column">
                            <div style={{ backgroundColor: "#28373E" }}>
                                <Link to={props.url} className={`text-decoration-none ${(!props.canAddContacts || props.item.isContact) && 'last-item'}`}>
                                    <div className={`popOverItem cursor-pointer ${(!props.canAddContacts || props.item.isContact) && 'last-item'}`}>
                                        <TextComponent text={'View profile'} customClassName={'text-color-182C34'} />
                                    </div>
                                </Link>
                                {!props.item.isContact && props.canAddContacts && <div className="popOverItem cursor-pointer last-item" onClick={AddContactHandler}>
                                    <TextComponent text={'Add to network'} />
                                </div>}
                            </div>
                        </div>
                    </>}
            </div>
        </div>
    );
}

export default PopoverSearchComponent;
