import React, { useRef, useContext } from 'react';
import './SearchInputComponent.css'
import { ThemeContext } from "../../../Contexts/ThemeContext";
import "../../../darkcustom.css"

interface ISearchInputComponent {
    customClass?: string;
    onValidationHandler: (data: string, page: number) => void;
};

const SearchInputComponent = (props: ISearchInputComponent) => {
    let { theme } = useContext(ThemeContext)

    const handleKeypress = (e: any) => {
        if (e.charCode === 13) {
            clickHandler();
        }
    };
    const searchInputRef = useRef<HTMLInputElement | null>(null);
    const clickHandler = () => {
        let data = searchInputRef.current?.value ? searchInputRef.current?.value : "";
        props.onValidationHandler(data, 1);
        if (searchInputRef.current)
            searchInputRef.current.value = "";
    }
    return <>
        {theme === "light" ? (
            <div className="input-group mb-3">
                <input type="text"
                    ref={searchInputRef}
                    className="form-control searchControl"
                    placeholder="Type here"
                    aria-label="Search" aria-describedby="basic-addon2"
                    onKeyPress={handleKeypress} />
                <button
                    className={`btn text-color-182C34 search-button btn-secondary background-E0E7EB font-weight-600 border-E0E7EB ${props.customClass}`}
                    type="button"
                    id="button-addon2"
                    onClick={clickHandler}>
                    Search
            </button>
            </div>
        ) : <div className="input-group mb-3">
                <input type="text"
                    ref={searchInputRef}
                    className="form-control searchControl searchBox"
                    placeholder="Type here"
                    aria-label="Search" aria-describedby="basic-addon2"
                    onKeyPress={handleKeypress} />
                <button
                    className={`btn text-color-182C34 search-button btn-secondary background-E0E7EB font-weight-600 border-E0E7EB ${props.customClass}`}
                    type="button"
                    id="button-addon2"
                    onClick={clickHandler}>
                    Search
            </button>
            </div>}

    </>
}

export default SearchInputComponent;