import React, { useContext, useEffect, useReducer, useState } from 'react';
import HeaderComponent from '../../FunctionalComponents/Header/HeaderComponent';
import { Errors, Headers } from '../../../Utils/Enums';
import TextComponent from '../../FunctionalComponents/Text/TextComponent';
import SinglePractice from '../../../Services/SinglePractice.service';
import LoaderComponent from '../../FunctionalComponents/Loader/LoaderComponent';
import ContentShellComponent from '../../FunctionalComponents/ContentShell/ContentShellComponent';
import SearchInputComponent from '../../FunctionalComponents/SearchInput/SearchInputComponent';
import { Link, useHistory } from 'react-router-dom';
import PaginationComponent, { IPaginationComponent } from '../../FunctionalComponents/Paginator/PaginationComponent';
import GeneralChatAnswersComponent from '../../FunctionalComponents/GeneralChatAnswers/GeneralChatAnswersComponent';
import './PracticeInProgressComponent.css'
import ContactPracticeItemComponent from '../../FunctionalComponents/ContactPracticeItem/ContactPracticeItemComponent';
import General from '../../../Services/General.service';
import UserPractice from '../../../Services/UserPractice.service';
import VideoPlayerComponent from '../../FunctionalComponents/VideoPlayer/VideoPlayerComponent';
import TEDModalComponent from '../../FunctionalComponents/TEDModal/TEDModalComponent';
import Dashboard from '../../../Services/Dashboard.service';
import { Button } from 'react-bootstrap';
import { logOutIfTokenExpire } from '../../../Utils/Utils';
import { UserContext } from '../../../Contexts/UserContext';
import { ThemeContext } from '../../../Contexts/ThemeContext';

const okReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'SetOKSuccess':
            return action.okSuccsess;
        case 'SetOKFailure':
            return action.okFailure;
        default:
            return { value: "loading", dataModule: null }
    }
}
interface IPracticeComponent {
    firstName: string;
    lastName: string;
    practiceStatus: string;
    profilePic: string;
    userId: string;
}
const PracticeInProgress = (props: any) => {

    let history = useHistory();
    const generalService = new General();
    generalService.getIcons();

    const singlePractice = new SinglePractice();
    const userPractice = new UserPractice();
    const userModule = new Dashboard();
    const practiceId = props.match.params.id;
    const [okState, dispatchOK] = useReducer(okReducer, { value: "loading", dataModule: null });
    const [searchData, setSearchData] = useState<IPracticeComponent[]>([]);
    const [searchDataString, setSearchDataString] = useState('');
    const [pagination, setPagination] = useState<IPaginationComponent>();
    const [dataSend, setDataSend] = useState(false);
    const [scoreMetaData, setScoreMetaData] = useState<any>({
        isThinkInProgress: true,
        doModuleStepId: 0,
    });
    const userContext = useContext(UserContext);
    const { theme } = useContext(ThemeContext);

    useEffect(() => {
        logOutIfTokenExpire();
        userContext.dispatch('');
        const getData = async () => {
            let result: any = await singlePractice.getPractice(practiceId);
            if (!result) return dispatchOK({ type: 'SetOKFailure', okFailure: { value: 'failed' } });
            dispatchOK({ type: 'SetOKSuccess', okSuccsess: { value: 'success', data: result } });
        }
        getData();
        if (!props.match.params.search)
            searchHandler("", 1);
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        userModuleStepStatus();
        //eslint-disable-next-line
    }, []);

    const userModuleStepStatus = async () => {
        let response: any = await userModule.getUserModuleStepsStatus(practiceId, "Practice");
        if (response.status) {
            //eslint-disable-next-line
            response.data.map((data: any) => {
                if (data.moduleStepTitle === "Think") {
                    if (data.status === "Completed") {
                        setScoreMetaData((states: any) => {
                            return {
                                ...states,
                                isThinkInProgress: true
                            }
                        });
                    }
                }

                if (data.moduleStepTitle === "Do") {
                    setScoreMetaData((states: any) => {
                        return {
                            ...states,
                            doModuleStepId: data.moduleStepId
                        }
                    });
                }
            });
        }
    }

    const selectStar = (event: any) => {
        const indicator = event.target.id.split('-')[0];
        const index = event.target.id.split('-')[1];
        let selectedHeart = document.getElementById(event.target.id) as HTMLImageElement;
        for (let x = Number(index); x <= 5; x++) {
            let filled: any = document.getElementById("empty-" + x);// as HTMLImageElement;
            if (filled) filled.src = window.location.origin + '/images/star-empty.svg';
        }
        if (selectedHeart.src === window.location.origin + '/images/star-empty.svg') {
            for (let x = 0; x <= Number(index); x++) {
                let selected: any = document.getElementById(indicator + "-" + x);// as HTMLImageElement;
                if (selected) selected.src = window.location.origin + '/images/star-filled.svg';
            }
        }
        //eslint-disable-next-line
        let setRating = singlePractice.ratePractice(practiceId, index);
    }

    const navigateTo = () => {
        return window.history.back();
    }

    const searchHandler = async (data: string, page: number) => {
        setSearchDataString(data);
        let objSearchResult: any = await singlePractice.getCurrentPracticeUsers(props.match.params.id, data ? data : "", 5, 1);

        if (objSearchResult) {
            setPagination({
                currentPage: 1,
                pageSize: 5,
                totalItems: objSearchResult.data.data,
                totalPages: Math.ceil(searchData.length / 5)
            });
            setSearchData(objSearchResult.data.data);
        }
    }

    if (props.match.params.search && !dataSend) {
        searchHandler(props.match.params.search, 1);
        setDataSend(true)
    }

    const pageHandler = (data: number) => {
        setPagination({
            currentPage: data,
            pageSize: 5,
            totalItems: Number(pagination?.totalItems),
            totalPages: Math.ceil(searchData.length / 5)
        })
    };

    const saveReflections = async () => {
        let singlePracticeCompleted = await singlePractice.saveAndExit(practiceId, okState.data.data.guid);
        if (!singlePracticeCompleted.ok) return dispatchOK({ type: 'SetOKFailure', okFailure: { value: 'failed' } });
        return history.push('/dashboard');
    }

    const completePractice = async () => {
        let response: any = await userPractice.completePractice(props.match.params.id, okState.data.data.guid);
        if (response.status) {
            return history.push('/dashboard');
        } else {
            return dispatchOK({ type: 'SetOKFailure', okFailure: { value: 'failed' } });
        }
    }

    return <>
        {okState && okState.value === 'loading' && <LoaderComponent />}
        {okState && okState.value === 'failed' && <div>
            <div className="mb-4">
                <div className="alert alert-warning" role="alert">
                    <TextComponent text={Errors.SomethingWentWrong} />
                </div>
            </div>
        </div>}

        {okState.value === 'success' &&
            <div>
                <div className="margin-bottom-40">
                    <div className='d-flex justify-content-between margin-bottom-24'>
                        <span onClick={navigateTo} className="cursor-pointer">
                            {theme === "light" ? (
                                <HeaderComponent
                                    title={okState.data.data.title}
                                    size={Headers.H1}
                                    icon={'chevron-left.svg'}
                                />
                            ) : <HeaderComponent
                                title={okState.data.data.title}
                                size={Headers.H1}
                                icon={'left-chevron-dark.svg'}
                            />}
                        </span>
                    </div>
                    <div className="margin-bottom-8">
                        <TextComponent text={"How would you rate this practice?"} />
                    </div>
                    <div>
                        {[1, 2, 3, 4, 5].map((x) =>
                            <img
                                src={x <= okState.data.data.rating ? window.location.origin + '/images/star-filled.svg' : window.location.origin + '/images/star-empty.svg'} alt="star-empty"
                                className='margin-right-30 width-24 cursor-pointer'
                                key={x}
                                id={"empty-" + String(x)}
                                onClick={selectStar}
                            />
                        )}
                    </div>
                </div>
                <ContentShellComponent customClass={'d-xl-inline-flex justify-content-between w-100 gap-3'}>
                    {theme === "light" ? (
                        <div className={`d-xl-inline-flex justify-content-between module-out-config`}>
                            <ContentShellComponent customClass={'rightSideConfig w-100'}>
                                <div className="margin-bottom-16">
                                    <HeaderComponent
                                        title={`PRACTICE DETAILS`}
                                        size={Headers.H3}
                                    />
                                </div>
                                <div className="margin-bottom-16">
                                    <TextComponent text={okState.data.data.summary} />
                                </div>
                                <div className="margin-bottom-8">
                                    <HeaderComponent
                                        title={`Instructions`}
                                        size={Headers.H4}
                                    />
                                </div>
                                <div className="margin-bottom-24">
                                    <TextComponent text={okState.data.data.instructions} />
                                </div>
                                {okState.data.data.practiceResourceViewModel && okState.data.data.practiceResourceViewModel[0] && <div className="padding-16 background-EFF8F7 margin-bottom-24">
                                    <div className="margin-bottom-8">
                                        <HeaderComponent
                                            title={okState.data.data.practiceResourceViewModel[0].name}
                                            size={Headers.H4} />
                                    </div>
                                    {okState.data.data.practiceResourceViewModel[0].description && <div className=" margin-bottom-8">{okState.data.data.practiceResourceViewModel[0].description}</div>}
                                    <div className="">
                                        {okState.data.data.practiceResourceViewModel[0].type === 'Webpage' &&
                                            <div className="d-flex justify-content-between margin-bottom-8">
                                                {!okState.data.data.practiceResourceViewModel[0].isExternalResource && <Link className="cursor-pointer page-link" to={"/content/" + okState.data.data.practiceResourceViewModel[0].resourceContentId} target="_blank">
                                                    <div>More</div>
                                                </Link>}
                                                {okState.data.data.practiceResourceViewModel[0].isExternalResource && <a href={okState.data.data.practiceResourceViewModel[0].link} target="_blank" className="cursor-pointer page-link" rel="noreferrer">
                                                    More
                                                </a>}
                                            </div>}
                                        {okState.data.data.practiceResourceViewModel[0].type === 'Image' &&
                                            <div className="margin-bottom-16">
                                                <img src={okState.data.data.practiceResourceViewModel[0].fileName} alt="file" />
                                            </div>}

                                        {okState.data.data.practiceResourceViewModel[0].type === 'Video' &&
                                            <div>
                                                <VideoPlayerComponent
                                                    playing={false}
                                                    local={true}
                                                    source={okState.data.data.practiceResourceViewModel[0].fileName}
                                                />
                                            </div>
                                        }
                                        {okState.data.data.practiceResourceViewModel[0].type === 'Audio' &&
                                            <div>
                                                <VideoPlayerComponent
                                                    playing={false}
                                                    local={true}
                                                    source={okState.data.data.practiceResourceViewModel[0].fileName}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>}
                                {okState.data.data.practiceQuestionViewModel &&
                                    <div className="padding-16 background-FAFAFA">
                                        <div className="margin-bottom-8">
                                            <HeaderComponent
                                                title={`How is your practice going?`}
                                                size={Headers.H4} />
                                        </div>
                                        <div className="">
                                            <TextComponent text={okState.data.data.practiceQuestionViewModel[0].questionContent} />
                                        </div>
                                    </div>}

                            </ContentShellComponent>
                        </div>
                    ) : <div className={`d-xl-inline-flex justify-content-between module-out-config practiceCard`}>
                        <ContentShellComponent customClass={'rightSideConfig w-100'}>
                            <div className="margin-bottom-16">
                                <HeaderComponent
                                    title={`PRACTICE DETAILS`}
                                    size={Headers.H3}
                                />
                            </div>
                            <div className="margin-bottom-16">
                                <TextComponent text={okState.data.data.summary} />
                            </div>
                            <div className="margin-bottom-8">
                                <HeaderComponent
                                    title={`Instructions`}
                                    size={Headers.H4}
                                />
                            </div>
                            <div className="margin-bottom-24">
                                <TextComponent text={okState.data.data.instructions} />
                            </div>
                            {okState.data.data.practiceResourceViewModel && okState.data.data.practiceResourceViewModel[0] && <div className="padding-16 margin-bottom-24">
                                <div className="margin-bottom-8">
                                    <HeaderComponent
                                        title={okState.data.data.practiceResourceViewModel[0].name}
                                        size={Headers.H4} />
                                </div>
                                {okState.data.data.practiceResourceViewModel[0].description && <div className=" margin-bottom-8">{okState.data.data.practiceResourceViewModel[0].description}</div>}
                                <div className="">
                                    {okState.data.data.practiceResourceViewModel[0].type === 'Webpage' &&
                                        <div className="d-flex justify-content-between margin-bottom-8">
                                            {!okState.data.data.practiceResourceViewModel[0].isExternalResource && <Link className="cursor-pointer page-link" to={"/content/" + okState.data.data.practiceResourceViewModel[0].resourceContentId} target="_blank">
                                                <div>More</div>
                                            </Link>}
                                            {okState.data.data.practiceResourceViewModel[0].isExternalResource && <a href={okState.data.data.practiceResourceViewModel[0].link} target="_blank" className="cursor-pointer page-link" rel="noreferrer">
                                                More
                                            </a>}
                                        </div>}
                                    {okState.data.data.practiceResourceViewModel[0].type === 'Image' &&
                                        <div className="margin-bottom-16">
                                            <img src={okState.data.data.practiceResourceViewModel[0].fileName} alt="file" />
                                        </div>}

                                    {okState.data.data.practiceResourceViewModel[0].type === 'Video' &&
                                        <div>
                                            <VideoPlayerComponent
                                                playing={false}
                                                local={true}
                                                source={okState.data.data.practiceResourceViewModel[0].fileName}
                                            />
                                        </div>
                                    }
                                    {okState.data.data.practiceResourceViewModel[0].type === 'Audio' &&
                                        <div>
                                            <VideoPlayerComponent
                                                playing={false}
                                                local={true}
                                                source={okState.data.data.practiceResourceViewModel[0].fileName}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>}
                            {okState.data.data.practiceQuestionViewModel &&
                                <div className="padding-16 background-FAFAFA practiceCard">
                                    <div className="margin-bottom-8">
                                        <HeaderComponent
                                            title={`How is your practice going?`}
                                            size={Headers.H4} />
                                    </div>
                                    <div className="">
                                        <TextComponent text={okState.data.data.practiceQuestionViewModel[0].questionContent} />
                                    </div>
                                </div>}

                        </ContentShellComponent>
                    </div>}
                    <div className={`d-xl-inline-flex justify-content-between module-out-config`}>
                        { }
                        <ContentShellComponent customClass={'rightSideConfig w-100'}>
                            <GeneralChatAnswersComponent practiceQuestionId={okState.data.data.practiceQuestionViewModel[0].id ? okState.data.data.practiceQuestionViewModel[0].id : -1} guId={okState.data.data.guid}></GeneralChatAnswersComponent>
                        </ContentShellComponent>
                    </div>
                    <div className={`d-xl-inline-flex justify-content-between module-out-config`}>
                        <ContentShellComponent customClass={'rightSideConfig w-100'} >
                            <div className="margin-bottom-16 header-margin-adjustment">
                                <HeaderComponent
                                    title={`OTHERS DOING THIS PRACTICE`}
                                    size={Headers.H3}
                                />
                            </div>
                            {searchData && searchData.length === 0 && pagination && <div>
                                <div className="mb-4">
                                    <div className="alert alert-warning" role="alert">
                                        <TextComponent text={Errors.NoData} />
                                    </div>
                                </div>
                            </div>}
                            <div className="margin-bottom-24">
                                <TextComponent text={'These are others in the company who are doing or have previously done this pratice. View their profile or engage in a meeting.'} />
                            </div>
                            <div>
                                <SearchInputComponent onValidationHandler={searchHandler} />
                            </div>
                            <div className="margin-bottom-24">
                                {searchData.length > 0 &&
                                    searchData.slice(Number(pagination?.currentPage) * 5 - 5, Number(pagination?.currentPage) * 5).map((item, i) =>
                                        <div key={item.userId}><ContactPracticeItemComponent
                                            additionalParam={practiceId}
                                            searchData={searchDataString}
                                            firstName={item.firstName}
                                            lastName={item.lastName}
                                            profilePictureLocation={item.profilePic}
                                            contactId={item.userId}
                                        />
                                        </div>)}
                            </div>
                            {searchData.length > 0 && <div className="margin-top-16 d-flex justify-content-end margin-bottom-48">
                                <PaginationComponent
                                    currentPage={pagination ? pagination?.currentPage : 1}
                                    pageSize={5}
                                    totalItems={searchData.length}
                                    totalPages={Math.ceil(searchData.length / 5)}
                                    onPageHandler={pageHandler} />
                            </div>}
                            <div className="margin-bottom-16">
                                <button
                                    type="button"
                                    onClick={saveReflections}
                                    className={'btn-secondary w-100'}
                                    id={'saveexitPractice'}
                                >
                                    {theme === "light" ? (
                                        <TextComponent text={'Save & Exit'} />
                                    ) : <TextComponent text={'Save & Exit'} customClassName={"buttonText"} />}
                                </button>
                            </div>
                            <div>
                                <Button
                                    variant="primary"
                                    className="btn-primary w-100 text-center cursor-pointer font-weight-600 text-color-182C34"
                                    size="sm" onClick={completePractice}>
                                    Finalise
                                </Button>
                            </div>
                        </ContentShellComponent>
                    </div>
                </ContentShellComponent>

            </div>}
        <div>
            <TEDModalComponent />
        </div>
    </>
}
export default PracticeInProgress;