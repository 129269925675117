import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useHistory } from "react-router";


interface Iprops {
    modalBody: string;
    show: boolean;
    handleClose: () => void;
    hasPractices: any
    cardName: string;
}
function DashboardCardModalComponent(props: Iprops) {

    const {
        handleClose,
        show,
        modalBody,
        hasPractices,
        cardName
    } = props;

    let history = useHistory();
    const handleAction = (status: boolean) => {
        handleClose();
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard
            restoreFocus
            scrollable
            animation
        >

            <Modal.Body>
                <span dangerouslySetInnerHTML={{ __html: modalBody }}></span>
                <div className="d-flex mt-4 justify-content-end">
                    {
                        (hasPractices && cardName === "Do") &&
                        <Button variant="primary" className="text-black text-bold mx-2" size="sm" onClick={() => history.push("/mypractices")}>
                            Continue
                        </Button>
                    }
                    <Button variant="secondary" className="text-black" size="sm" onClick={() => handleAction(false)}>
                        Close
                    </Button>
                </div>
            </Modal.Body>
        </Modal >
    );
}

export default DashboardCardModalComponent;