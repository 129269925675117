import React from "react";
import { TableColumn } from "react-data-table-component";
import TableComponent from "../Table/TableComponent";
import "./UserScoreTable.css";

interface IpersonalScore {
    moduleName: string;
    learningScore: string;
    applicationScore: string;
}

interface Iscorer {
    scorerName: string;
    score: string;
    feedback: string;
}

interface IproviderScore {
    moduleName: string;
    scorer: Iscorer[];
}

interface Iprops {
    providerScoreData?: any[];
    personalScoreData?: any[];
    personalScore: boolean;
}
function UserScoreTableComponent(props: Iprops) {

    const { personalScoreData, providerScoreData, personalScore } = props;

    const providerScorerName1: string = providerScoreData && (providerScoreData[0]?.scorer[0]?.scorerName ?? "");
    const providerScorerName2: string = providerScoreData && (providerScoreData[0]?.scorer[1]?.scorerName ?? "");
    const providerScorerName3: string = providerScoreData && (providerScoreData[0]?.scorer[2]?.scorerName ?? "");

    const providerScore1: string = providerScoreData && (providerScoreData[0]?.scorer[0]?.score);
    const providerScore2: string = providerScoreData && (providerScoreData[0]?.scorer[1]?.score);
    const providerScore3: string = providerScoreData && (providerScoreData[0]?.scorer[2]?.score);


    //columns for personl score
    const personalScoreColumns: TableColumn<IpersonalScore>[] = [
        {
            name: 'Module',
            selector: row => row.moduleName,
            sortable: true,
            width: "400px",
            cell: (row) => (
                <div className="data">
                    {row.moduleName}
                </div>
            )
        },
        {
            name: "Learning Score",
            selector: (row) => row.learningScore,
            sortable: true,
            //width: "500px",
            cell: (row) => (
                <div className="scoreData">
                    {row.learningScore}
                </div>
            )
        },
        {
            name: "Application Score",
            selector: (row) => row.applicationScore === null ? "No Data Available" : row.applicationScore,
            //width: "2500px",
            cell: (row) => (
                <div className="scoreData">
                    {row.applicationScore === null ? "No Data Available" : row.applicationScore}
                </div>
            )
        },
    ];

    //columns for provider score
    const providerScoreColumns: TableColumn<IproviderScore>[] = [
        {
            name: 'Module',
            selector: row => row.moduleName,
            sortable: true,
            width: "400px",
            cell: (row) => (
                <div className="data">
                    {row.moduleName}
                </div>
            )
        },
        {
            name: `${providerScorerName1}`,
            selector: (row) => row.scorer[0]?.score,
            cell: (row) => (
                <div className="scoreData">
                    {row.scorer[0]?.score === null ? "No data found" : row.scorer[0]?.score}
                </div>
            ),
            omit: `${providerScorerName1}` === "" || `${providerScore1}` === ""
        },
        {
            name: `${providerScorerName2}`,
            selector: (row) => row.scorer[1]?.score,
            cell: (row) => (
                <div className="scoreData">
                    {row.scorer[1]?.score === null ? "No data found" : row.scorer[1]?.score}
                </div>
            ),
            omit: `${providerScorerName2}` === "" || `${providerScore2}` === ""

        },
        {
            name: `${providerScorerName3}`,
            selector: (row) => row.scorer[2]?.score,
            cell: (row) => (
                <div className="scoreData">
                    {row.scorer[2]?.score === null ? "No data found" : row.scorer[2]?.score}
                </div>
            ),
            omit: `${providerScorerName3}` === "" || `${providerScore3}` === ""

        },
        {
            name: 'Feedback',
            selector: row => 'Feedback',
            sortable: true,
            cell: (row) => (
                <div className="scoreData">
                    {row.scorer[0]?.feedback === null ? "No data found" : row.scorer[0]?.feedback},
                    {row.scorer[2]?.feedback === null ? "No data found" : row.scorer[2]?.feedback},
                    {row.scorer[1]?.feedback === null ? "No data found" : row.scorer[1]?.feedback}
                </div>
            )
        },
    ];

    return (
        <>
            <div>
                <TableComponent
                    data={personalScore ? personalScoreData! : providerScoreData!}
                    columns={personalScore ? personalScoreColumns : providerScoreColumns}
                    tableTitle={""}
                    totalRows={1}
                    rowsPerpage={10}
                    handlePageChange={<></>}
                    handleRowsPerPage={<></>}
                    pageAction={<></>}
                    isPagination={false}
                />
            </div>
        </>
    );
}

export default UserScoreTableComponent;