import React, { useEffect, useLayoutEffect, useState } from "react";
import { useHistory } from "react-router";
import General from "../../../Services/General.service";
import Scores from "../../../Services/Scores.service";
import { isUserLoggedIn } from "../../../Utils/Utils";
import ScorerModalComponent from "../../FunctionalComponents/ScorerMadal/ScorerModalComponent";
import "./ExternalUserScoreComponent.css";
import { Button } from 'reactstrap';
import TextComponent from '../../FunctionalComponents/Text/TextComponent';
import LoaderComponent from "../../FunctionalComponents/Loader/LoaderComponent";

interface IproviderScoreData {
    token: string;
    score: number;
    feedback: string;
}

function ExternalUserScoreComponent() {
    let history = useHistory();
    const generalService = new General();
    const score = new Scores();
    const [userCode, setUserCode] = useState<string>("");
    const [validLink, setValidLink] = useState<boolean>(true);
    const [successMessage, setSuccessMessage] = useState<string>("");
    const [userData, setUserData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(true);

    useLayoutEffect(() => {
        let url = window.location.href;
        let tempCode: any = url.split("?")[1]?.slice(5);
        setUserCode(tempCode);
    }, []);

    useEffect(() => {
        if (userCode) {
            validateUserCode();
        }
        //eslint-disable-next-line
    }, [userCode]);

    const validateUserCode = async () => {
        let response: any = await score.scorerInvitation(userCode);
        if (response.data.isTokenValid) {
            setUserData(response.data)
            setValidLink(true);
        } else {
            setValidLink(false);
        }
        setLoading(false);
    }

    const sendProviderScore = async (selectedScore: number, scoreText: string) => {
        let providerScoreData: IproviderScoreData = {
            token: userCode,
            score: selectedScore,
            feedback: scoreText
        }
        let response: any = await score.sendProviderScore(providerScoreData);
        if (response.status) {
            setSuccessMessage(response.message);
        }
    }

    const RedirectToDashboard = () => {
        history.push("/dashboard")
    }

    return (
        <>
            {
                <div className="loader w-100 d-flex justify-content-center">
                    {loading && <LoaderComponent />}
                </div>
            }
            {!loading && <div>
                {
                    !isUserLoggedIn() && <div className="d-flex justify-content-center my-5">
                        <img alt="MindNavigator_latest" src={window.location.origin + "/images/MindNavigator_latest.svg"} style={{ width: "8.125rem", height: "1.813rem" }} />
                    </div>
                }
                {
                    validLink ? null : isUserLoggedIn() ? <div className='container col-sm-12 text-center not-valid-link'>
                        <span className='alert alert-danger'>This link is not valid now!</span>
                        <div className="mt-4">
                            <Button className='mt-2'
                                color="primary background-26CAC1 font-weight-600"
                                onClick={RedirectToDashboard}>
                                <TextComponent text={'Go to Dashboard'} customClassName="text-color-182C34" />
                            </Button>
                            </div>
                </div> : <div className='container col-sm-12 text-center not-valid-link'>
                    <span className='alert alert-danger'>Score is already applied</span>
                </div> 
            }

                {
                    validLink ? <ScorerModalComponent
                        title={""}
                        moduleStepId={0}
                        isFromEngageCard={false}
                        validLink={validLink}
                        sendProviderScore={sendProviderScore}
                        successMessage={successMessage}
                        userData={userData}
                    />
                        : null
                }
            </div>
            }
        </>
    );
}

export default ExternalUserScoreComponent;