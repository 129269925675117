import React, { useEffect, useState, useContext } from "react";
import { TableColumn } from "react-data-table-component";
import { Button } from "reactstrap";
import TableComponent from "../../FunctionalComponents/Table/TableComponent";
import Group from "../../../Services/Group.services";
import CommonModalComponent from "../../FunctionalComponents/CommonModal/CommonModalComponent";
import OrganisationGroup from "../OrganisationGroup/OrganisationGroup";
import TextComponent from "../../FunctionalComponents/Text/TextComponent";
import { createNotification } from "../../../Utils/Toast";
import BootstrapModal from "../../FunctionalComponents/BootstrapModal/BootstrapModal";
import "./ClientAdminDashboard.module.css";
import { CLIENT_ADMIN, MNFACILITATOR } from "../../../Utils/Roles";
import { Col, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { logOutIfTokenExpire } from '../../../Utils/Utils';
import { ThemeContext } from "../../../Contexts/ThemeContext";
import "../../../darkcustom.css"
interface IGroupColumnInformation {
    groupName: string;
    description: string;
    groupFacilator: any;
    groupProgram: any;
}

interface IGroupInformation {
    groupName: string;
    description: string;
    groupFacilator: any;
    groupProgram: any;
}

interface Iprops {
    setLoading: (data: boolean) => void;
    role: any;
}

const GroupComponent = (props: Iprops) => {

    const { setLoading, role } = props;
    const group = new Group();
    const [tableData, setTableData] = useState<IGroupInformation[]>([]);
    const [editGroupData, setEditGroupData] = useState<any>({
        groupName: "",
        groupId: 0,
        description: "",
        groupFacilator: "",
        groupFacilatorId: "",
        groupProgram: "",
        groupProgramId: 0,
        maxUser: 0,
        members: [],
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerpage, setRowsPerpage] = useState(10);
    const [userOrganisationId,] = useState<number>(0);
    const [totalPages, setTotalPages] = useState(0);
    const [modalState, setModalState] = useState(false);
    const [editData, setEditData] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
    const [yesDeleteIt, setYesDeleteIt] = useState<boolean>(false);
    const [deleteGroupData, setDeleteGroupData] = useState<any>();
    const [searchStr, setSearchStr] = useState<string>("");
    const { theme } = useContext(ThemeContext)

    useEffect(() => {
        logOutIfTokenExpire();
        getTotalGroupData();
        //eslint-disable-next-line
    }, [currentPage, rowsPerpage]);

    const groupColumns: TableColumn<IGroupColumnInformation>[] = [
        {
            name: "Group Name",
            selector: (row) => row.groupName,
            sortable: true,
            cell: (row) => (
                <div className="text">
                    {row.groupName}
                </div>
            )
        },
        {
            name: "Description",
            selector: (row) => row.description,
            wrap: true,
            cell: (row) => (
                <div className="text">
                    {row.description}
                </div>
            )
        },
        {
            name: "Facilator Name",
            selector: (row) => row.groupFacilator,
            sortable: true,
            cell: (row) => (
                <div className="text">
                    {row.groupFacilator}
                </div>
            )
        },
        {
            name: "Group Program",
            selector: (row) => row.groupProgram,
            cell: (row) => (
                <div className="text">
                    {row.groupProgram}
                </div>
            )
        },
        {
            name: "Action",
            cell: (row) => (
                <>
                    <OverlayTrigger
                        placement={"top"}
                        overlay={
                            <Tooltip id={`tooltip-right`}>
                                Edit group
                            </Tooltip>
                        }
                    >
                        {theme === "light" ? (
                            <img alt="edit" onClick={() => handleEditClick(row)} src={window.location.origin + "/images/edit.svg"} />
                        ) : <img alt="edit" onClick={() => handleEditClick(row)} src={window.location.origin + "/images/edit-dark.svg"} />}
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement={"top"}
                        overlay={
                            <Tooltip id={`tooltip-right`}>
                                Delete group
                            </Tooltip>
                        }
                    >
                        {theme === "light" ? (
                            <img alt="delete" onClick={() => handleDeleteClick(row)}
                                src={window.location.origin + "/images/close.svg"} />
                        ) : <img alt="delete" onClick={() => handleDeleteClick(row)}
                            src={window.location.origin + "/images/close-dark.svg"} />}
                    </OverlayTrigger>
                </>
            ),
        },
    ];


    const getTotalGroupData = async () => {
        setLoading(true);
        let result: any = await group.getTotalGroupsByOrganizationId(
            currentPage,
            rowsPerpage,
            searchStr
        );

        if (result.status) {
            setLoading(false);
            let groupInformation: IGroupColumnInformation[] = [];
            setTotalPages(result?.pagination?.totalItems);
            groupInformation = result.data.items.map((value: any) => {
                return {
                    groupName: value.groupName,
                    groupId: value.id,
                    description: value.description,
                    groupFacilator: value.groupFacilator.name,
                    groupFacilatorId: value.groupFacilator.guid,
                    groupProgram: value.groupProgram.name,
                    groupProgramId: value.groupProgram.id,
                    maxUser: value.maximumUsers,
                    members: value.members,
                    organisationId: value.organisationId
                }
            });
            setTableData(groupInformation);
        }
    };

    const handlePageChange = (e: any): void => {
        setCurrentPage(e);
    };

    const handleRowsPerPage = (e: any): void => {
        setRowsPerpage(e);
    };

    const handleEditClick = (row: any) => {
        let members: any = row.members.map((data: any) => {
            return {
                value: data.guid,
                label: data.name
            }
        });
        setModalState(true);
        setEditData(true);
        setEditGroupData({
            groupName: row.groupName,
            groupId: row.groupId,
            description: row.description,
            groupFacilator: row.groupFacilator,
            groupFacilatorId: row.groupFacilatorId,
            groupProgram: row.groupProgram,
            groupProgramId: row.groupProgramId,
            maxUser: row.maxUser,
            members: members,
        });
    }

    const handleDeleteClick = async (row: any) => {
        setLoading(true);
        setShow(true);
        setYesDeleteIt(true);
        setDeleteGroupData(row);
        if (yesDeleteIt) {
            setShow(false);
            let response: any = await group.deleteGroupsByGroupId(deleteGroupData.groupId.toString());
            if (response.status) {
                setLoading(false);
                createNotification("success", response.message);
                getTotalGroupData();
            } else {
                setLoading(false);
                createNotification("error", response.message);
            }
        }
    }

    const handleClose = () => {
        setShow(false);
        setLoading(false);
        setYesDeleteIt(false);
    }

    const handleUsersSearch = (event: any) => {
        setSearchStr(event.target.value.toString());
        if (event.keyCode === 13) {
            getTotalGroupData();
        }
    }

    return (
        <>
            <div style={{ position: "relative" }}>
                <TableComponent
                    data={tableData}
                    columns={groupColumns}
                    tableTitle={"Group List"}
                    totalRows={totalPages}
                    rowsPerpage={rowsPerpage}
                    handlePageChange={(e: any) => handlePageChange(e)}
                    handleRowsPerPage={(e: any) => handleRowsPerPage(e)}
                    isPagination={true}
                    pageAction={
                        (role === CLIENT_ADMIN || role === MNFACILITATOR) &&
                        <div className="d-flex">
                            <div>
                                {theme === "light" ? (
                                    <>
                                        <div className="px-1">
                                            <Form.Group as={Col} xs={12} md={4} lg={2} controlId="formGridGroup" className="mt-1 w-100">
                                                <Form.Control type="text" placeholder="Group or Facilitator" onKeyUp={handleUsersSearch} />
                                            </Form.Group>
                                        </div>

                                    </>
                                ) : <>
                                    <div className="px-1">
                                        <Form.Group as={Col} xs={12} md={4} lg={2} controlId="formGridGroup" className="mt-1 w-100">
                                            <Form.Control className="clientSearchBox" type="text" placeholder="Group or Facilitator" onKeyUp={handleUsersSearch} />
                                        </Form.Group>
                                    </div>

                                </>}
                            </div>
                            <div className="px-1">
                                <Button color="secondary" className="font-weight-600" onClick={() => {
                                    getTotalGroupData();
                                }}>
                                    {theme === "light" ? (
                                        <TextComponent text={"Search"} customClassName="text-color-182C34" />
                                    ) : <TextComponent text={"Search"} customClassName="buttonText" />}
                                </Button>
                            </div>
                            <div className="px-1">
                                <CommonModalComponent
                                    label={"Add Group"}
                                    title={editData ? "Edit Group" : "Add Group"}
                                    onModalHandler={() => console.log("TEST")}
                                    setModalState={setModalState}
                                    modalState={modalState}
                                    modalBody={<OrganisationGroup
                                        editGroupData={editGroupData}
                                        setModalState={setModalState}
                                        editData={editData}
                                        setEditData={setEditData}
                                        currentPage={currentPage}
                                        rowsPerpage={rowsPerpage}
                                        userOrganisationId={userOrganisationId}
                                        getTotalGroupData={getTotalGroupData}
                                    />}
                                    modalFooter={
                                        <Button
                                            color="secondary"
                                            className="font-weight-600"
                                            onClick={() => {
                                                setModalState(false);
                                                setEditData(false)
                                            }}
                                        >
                                            {theme === "light" ? (
                                                <TextComponent text={"Close"} customClassName="text-color-182C34" />
                                            ) : <TextComponent text={"Close"} customClassName="buttonText" />}
                                        </Button>
                                    }
                                />
                            </div>
                        </div>
                    }
                />
            </div>
            <BootstrapModal
                modalTitle="Your are about to delete the group"
                modalBody="Once you delete the group, every user within the group will get deleted. Are you sure to delete the group?"
                successButtonText="Delete"
                closeButtonText="Cancel"
                show={show}
                handleClose={handleClose}
                setYesDeleteIt={setYesDeleteIt}
                handleDeleteClick={handleDeleteClick}
            />
        </>
    );
};
export default GroupComponent;


