export const MINDNAVIGATOR_ADMIN_LABEL: string = "MindNavigator Admin"
export const CLIENT_ADMIN_LABEL: string = "Client Admin";
export const SUB_CLIENT_ADMIN_LABEL: string = "Sub-Client Admin";
export const FACILITOR_LABEL: string = "Facilitor";
export const PARTICIPANT_LABEL: string = "Participant";
export const MNFACILITATOR_LABEL:string = "MN Facilitator";
export const MNFACILITATOR:string = "MNFacilitator";

export const CLIENT_ADMIN: string = "de59ec7b-e54a-4ac3-9c47-b4b2bd2a8964";
export const SUB_CLIENT_ADMIN: string = "2bd569d1-c925-40ff-80b3-d6e27aac80a4";
export const FACILITOR: string = "623ed85e-873d-4ab2-adb6-cab4b9cedfef";
export const PARTICIPANT: string = "206e341f-ebc1-44d3-bbea-8c70e22f45af";

export const CLIENT_ADMIN_MAPPING: string[] = [CLIENT_ADMIN, SUB_CLIENT_ADMIN, FACILITOR, PARTICIPANT];
export const SUB_CLIENT_ADMIN_MAPPING: string[] = [SUB_CLIENT_ADMIN, FACILITOR]
export const FACILITOR_MAPPING: string[] = [FACILITOR]

export const CLIENT_ADMIN_DROPDOWN_MAPPING: any[] = [
    { value: CLIENT_ADMIN, label: CLIENT_ADMIN_LABEL },
    { value: SUB_CLIENT_ADMIN, label: SUB_CLIENT_ADMIN_LABEL },
    { value: FACILITOR, label: FACILITOR_LABEL },
    { value: PARTICIPANT, label: PARTICIPANT_LABEL }
];
export const SUB_CLIENT_ADMIN_DROPDOWN_MAPPING: any[] = [
    { value: SUB_CLIENT_ADMIN, label: SUB_CLIENT_ADMIN_LABEL },
    { value: FACILITOR, label: FACILITOR_LABEL }
];
export const FACILITOR_DROPDOWN_MAPPING: any = []
