import React,{createContext,useReducer, ReactNode} from "react";
import Connector from "../Utils/NotificatonConnector";
import { isTokenExpired } from "../Utils/Utils";

type Props = {
    children: ReactNode;
};

export enum Types {
    UPDATE_PROFILE_IMG = 'UPDATE_PROFILE_IMAGE',
    CHANGE_THEME = 'CHANGE_THEME',
    REMOVE_PROFILE_IMG = 'REMOVE_PROFILE_IMG',
    CHANGE_NOTIFICATION = 'CHANGE_NOTIFICATION',
    HAS_NOTIFICATION = 'HAS_NOTIFICATION', 
    CHANGE_CONCEPT_AUDIO = 'CHANGE_CONCEPT_AUDIO',
}

export type UserContextAction = | {type:Types.CHANGE_THEME,value:boolean} 
                                | {type:Types.UPDATE_PROFILE_IMG,value:string | null}  
                                | {type:Types.REMOVE_PROFILE_IMG}
                                | {type:Types.CHANGE_NOTIFICATION,value:boolean}
                                | {type:Types.HAS_NOTIFICATION,value:boolean}
                                | {type:Types.CHANGE_CONCEPT_AUDIO,value:boolean}

export interface IUserContext {
    isDarkMode:boolean,   
    isTokenExpired:boolean,
    hasNotifications:boolean,
    currentUser:ICurrentUser,
}

export interface ICurrentUser {
    id:string,
    userName:string,
    roles:string,
    profileImage:string | null,
    name:string,
    orgId:number,
    org:string,
    isNotificationOn:boolean,
    isConceptAudioOn:boolean
}

const initialtUserContext:IUserContext = {isDarkMode:false,hasNotifications:false,isTokenExpired:true,currentUser:{id:'',name:'',org:'',orgId:0,profileImage:'',roles:'',userName:'',isNotificationOn:false,isConceptAudioOn:true}};
type state = typeof initialtUserContext;
const userContextReducer = (state:any,action:UserContextAction) => {
    let localStorageData: any = localStorage.getItem("userConfig");
    switch(action.type){
        case Types.UPDATE_PROFILE_IMG: {
            if (localStorageData && localStorageData !== "") {
                let parsedLocalStorageData: any = JSON.parse(localStorageData);
                parsedLocalStorageData["profileImg"] = action.value;
                localStorage.setItem("userConfig", JSON.stringify(parsedLocalStorageData));                                        
            }
            return {
                ...state,
                currentUser:{...state.currentUser,profileImage:action.value}
            }
        }        
        case Types.CHANGE_THEME :{            
            return {
                ...state,
                isDarkMode:action.value
            }
        }            
        case Types.REMOVE_PROFILE_IMG : {
            if (localStorageData && localStorageData !== "") {
                let parsedLocalStorageData: any = JSON.parse(localStorageData);
                parsedLocalStorageData["profileImg"] = null;
                localStorage.setItem("userConfig", JSON.stringify(parsedLocalStorageData)); 
            }
            return {
                ...state,
                currentUser:{...state.currentUser,profileImage:null}
            };
        }
        case Types.HAS_NOTIFICATION : {
            if(action.value){
                localStorage.setItem("isNotification",'true');                
            }
            else {
                localStorage.setItem("isNotification",'false');
            }
            return {
                ...state,
                hasNotifications:action.value
            }
        }
        case Types.CHANGE_NOTIFICATION : {
            if(localStorageData && localStorageData !== ""){
                let parsedLocalStorageData: any = JSON.parse(localStorageData);
                parsedLocalStorageData["isNotificationOn"] = action.value;
                localStorage.setItem("userConfig", JSON.stringify(parsedLocalStorageData));
                if(!action.value){
                    Connector.closeConnection();
                }
            }            
            return {
                ...state,
                currentUser:{...state.currentUser,isNotificationOn:action.value}
            }
        }
        case Types.CHANGE_CONCEPT_AUDIO : {
            let parsedLocalStorageData: any = JSON.parse(localStorageData);
                parsedLocalStorageData["isConceptAudioOn"] = action.value;
                localStorage.setItem("userConfig", JSON.stringify(parsedLocalStorageData));
            return {
                ...state,
                currentUser:{...state.currentUser,isConceptAudioOn:action.value}
            }
        }
        default: {
            const user = localStorage.getItem("userConfig");
            if(user){
                const userDetail = JSON.parse(user);
                return {
                    ...state,
                    isDarkMode:false,
                    isTokenExpired:isTokenExpired(),
                    currentUser:{
                        id:userDetail.id,
                        userName:userDetail.username,
                        roles:userDetail.roles,
                        profileImage:userDetail.profileImg,
                        name:userDetail.firstName + ' ' + userDetail.lastName,
                        orgId:userDetail.organisationId,
                        org:userDetail.orgnisationName,
                        isNotificationOn:userDetail.isNotificationOn,
                        isConceptAudioOn:userDetail.isConceptAudioOn
                    }
                };                                  
            }
            else {
                return state;
            }
        }            
    }
}

export const UserContext = createContext<{state:state;dispatch:React.Dispatch<any>}>({
    state:initialtUserContext,
    dispatch:() => ('')
});

export const UserContextProvider = ({children}:Props) => {    
    const [state,dispatch] = useReducer(userContextReducer,initialtUserContext);
    return (
        <UserContext.Provider value={{state,dispatch}}>            
            {children}        
        </UserContext.Provider>
    );
}