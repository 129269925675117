import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import General from "../../../Services/General.service";
import TedJournal from "../../../Services/TedModule.service";
import { Headers } from "../../../Utils/Enums";
import { createNotification } from "../../../Utils/Toast";
import HeaderComponent from "../../FunctionalComponents/Header/HeaderComponent";
import TEDModalComponent from "../../FunctionalComponents/TEDModal/TEDModalComponent";
import { Button, Spinner } from "react-bootstrap";
import "./TEDJournalNewEntery.css";
import { logOutIfTokenExpire } from '../../../Utils/Utils'
import { UserContext } from "../../../Contexts/UserContext";
import { ThemeContext } from "../../../Contexts/ThemeContext"
import "../../../darkcustom.css"

interface IjournalData {
    programStageId: number,
    title: string,
    relatedCategory: {
        relatedCategoryId: number,
        relatedCategoryType: string,
        relatedCategoryName: string,
    },
    text: string
}

function JournalEntryComponent(props: any) {
    const { theme } = useContext(ThemeContext)
    const generalService = new General();
    generalService.getIcons();
    let history = useHistory();
    let redirection: any = props?.location?.state;
    const ted = new TedJournal();
    const [journalTitle, setJournalTitle] = useState<string>("");
    const [journalEntry, setJournalEntry] = useState<string>("");
    const [isDisable, setIsDisable] = useState<boolean>(true);
    const [dataInserted, setDataInserted] = useState<any>({
        journalTitle: false,
        journalEntry: false
    });
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const userContext = useContext(UserContext);

    useEffect(() => {
        userContext.dispatch('');
    }, [])

    useEffect(() => {
        logOutIfTokenExpire();
        validateForm();
        //eslint-disable-next-line
    }, [dataInserted]);

    const handleTitleChange = (value: string) => {
        setJournalTitle(value);
    }

    const handleEntryChange = (value: string) => {
        setJournalEntry(value);
    }

    const validateForm = async () => {
        if (journalTitle.length === 0) {
            setIsDisable(true);
            return false;
        }
        if (journalEntry.length === 0) {
            setIsDisable(true);
            return false;
        }
        if (dataInserted.journalTitle && dataInserted.journalEntry) {
            setIsDisable(false);
            return true;
        }
    }

    const handleSubmit = async () => {
        let journalData: IjournalData = {
            programStageId: 1,
            title: journalTitle,
            relatedCategory: {
                relatedCategoryId: -1,
                relatedCategoryType: "Reflection",
                relatedCategoryName: "Reflection",
            },
            text: journalEntry
        }

        if (await validateForm()) {
            setShowSpinner(true);
            let response: any = await ted.tedJournalEntry(journalData);
            if (response.status) {
                setShowSpinner(false);
                createNotification("success", response.message);
                setJournalTitle("");
                setJournalEntry("");
                history.push({
                    pathname: "/tedlogbook",
                    state: { isNewEntry: true }
                });
            } else {
                createNotification("error", response.message);
            }
        }
    }

    const handleRedirection = () => {
        if (redirection === undefined) {
            history.push("/dashboard");
        } else {
            history.push("/tedlogbook");
        }
    }

    return (
        <>
            <div className="container">
                {theme === "light" ? (
                    <div className="d-flex align-items-center">
                        <img className="cursor-pointer" onClick={() => handleRedirection()} alt="redirection-back" src={window.location.origin + "/images/chevron-left.svg"} />
                        <span className="cursor-pointer" onClick={() => handleRedirection()}>
                            <HeaderComponent
                                title="New Journal Entry"
                                size={Headers.H2}
                            />
                        </span>
                    </div>
                ) : <div className="d-flex align-items-center">
                        <img className="cursor-pointer"
                            onClick={() => handleRedirection()} alt="redirection-back"
                            src={window.location.origin + "/images/left-chevron-dark.svg"} />
                        <span className="cursor-pointer" onClick={() => handleRedirection()}>
                            <div style={{ marginLeft: "15px" }}>
                                <HeaderComponent
                                    title="New Journal Entry"
                                    size={Headers.H2}
                                /></div>
                        </span>
                    </div>}
                <div className="mt-3 row">
                    <div className="d-flex flex-column col-xs-12 col-md-8 col-lg-12">
                        <label htmlFor="basic-url" className="form-label">Type a title</label>
                        <div className="input-group mb-3">
                            {theme === "light" ? (
                                <input
                                    type="text"
                                    placeholder="Title of new journal entry"
                                    className="form-control"
                                    id="basic-url"
                                    aria-describedby="basic-addon3"
                                    value={journalTitle || ""}
                                    onChange={(e: any) => {
                                        handleTitleChange(e.target.value);
                                        setDataInserted((states: any) => {
                                            return {
                                                ...states,
                                                journalTitle: true
                                            }
                                        });
                                    }}
                                />
                            ) : <input
                                    type="text"
                                    placeholder="Title of new journal entry"
                                    className="form-control searchBox"
                                    id="basic-url"
                                    aria-describedby="basic-addon3"
                                    value={journalTitle || ""}
                                    onChange={(e: any) => {
                                        handleTitleChange(e.target.value);
                                        setDataInserted((states: any) => {
                                            return {
                                                ...states,
                                                journalTitle: true
                                            }
                                        });
                                    }}
                                />}
                        </div>
                    </div>
                    <div className="mb-3 form-group">
                        <label htmlFor="basic-url" className="form-label">Type your entry below</label>
                        {theme === "light" ? (<textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            value={journalEntry || ""}
                            style={{ height: "300px" }}
                            onChange={(e: any) => {
                                handleEntryChange(e.target.value);
                                setDataInserted((states: any) => {
                                    return {
                                        ...states,
                                        journalEntry: true
                                    }
                                });
                            }}>
                        </textarea>
                        ) : <textarea
                            className="form-control searchBox"
                            id="exampleFormControlTextarea1"
                            value={journalEntry || ""}
                            style={{ height: "300px" }}
                            onChange={(e: any) => {
                                handleEntryChange(e.target.value);
                                setDataInserted((states: any) => {
                                    return {
                                        ...states,
                                        journalEntry: true
                                    }
                                });
                            }}>
                            </textarea>}

                    </div>

                    <div className="d-flex justify-content-end mt-2">
                        {
                            showSpinner ?
                                <Button variant="primary" className="text-secondary" disabled>
                                    <span className="mx-2 text-secondary">
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </span>
                                    Saving entry...
                                </Button>
                                :
                                <Button
                                    variant="primary"
                                    className="font-weight-600 text-color-182C34"
                                    disabled={isDisable}
                                    type="button"
                                    onClick={() => handleSubmit()}
                                >
                                    Save Entry
                                </Button>
                        }
                    </div>
                </div>
            </div>
            <div>
                <TEDModalComponent />
            </div>
        </>
    );
}

export default JournalEntryComponent;