export enum Headers {
    H1 = 'size-26',
    H2 = 'size-18',
    H3 = 'size-16',
    H4 = 'size-14'
};

export enum QuestionAnswerType{
    FreeText, 
    SingleChoice, 
    MultiChoice    
}

export enum Errors {
    SomethingWentWrong = 'Something went wrong',
    IntentionIsNotAvailable='We are unable to display your intention at the moment',
    QuotesIsNotAvailable="We are unable to display this week's quote at the moment.",
    NoData='No Data'
};

export enum moduleStepsState {
    NotStarted = 'NotStarted',
    InProccess='InProgress',
    Completed='Completed'
};
export enum chartType {
    Bar = 'Bar',
    Pie = 'Pie',
    Radar = 'Radar'
}
export enum IsInMyContactResponeEnum {
    LOGIN_USER_REQUESTID_SAME = 3,
    USER_NOT_FOUND = 4,
    USER_IN_CONTACT = 1,
    CONTACT_LIMIT_REACHED = 2,
    ERROR = 5,
    USER_NOT_IN_CONTACT = 0
}