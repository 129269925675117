import React, { useContext, useEffect, useState } from 'react';
import ErrorInputComponent from '../../FunctionalComponents/ErrorInput/ErrorInputComponent';
import LoaderComponent from '../../FunctionalComponents/Loader/LoaderComponent';
import HeaderComponent from '../../FunctionalComponents/Header/HeaderComponent';
import { Headers } from '../../../Utils/Enums';
import TabsComponent from '../../FunctionalComponents/Tabs/TabsComponent';
import ContentShellComponent from '../../FunctionalComponents/ContentShell/ContentShellComponent';
import TextComponent from '../../FunctionalComponents/Text/TextComponent';
import SearchInputComponent from '../../FunctionalComponents/SearchInput/SearchInputComponent';
import Engage, { IContact, ISearchItem, ISearchResult } from '../../../Services/Engage.service';
import SearchContactsListComponent from '../../FunctionalComponents/SearchContactsList/SearchContactsListComponent';
import { IRole } from '../../FunctionalComponents/Popover/PopoverComponent';
import "./EngageComponent.css";
import ContactsListComponent from '../../FunctionalComponents/ContactsList/ContactsListComponent';
import ModalComponent, { IModalData } from '../../FunctionalComponents/Modal/ModalComponent';
import { Link, useHistory } from 'react-router-dom';
import SubmitButtonComponent from '../../FunctionalComponents/SubmitButton/SubmitButtonComponent';
import { createNotification } from '../../../Utils/Toast';
import MeetingComponent from '../ClientAdminDashboard/MeetingComponent';
import TEDModalComponent from '../../FunctionalComponents/TEDModal/TEDModalComponent';
import General from '../../../Services/General.service';
import { isMobileOnly } from 'react-device-detect';
import { logOutIfTokenExpire } from '../../../Utils/Utils';
import { UserContext } from '../../../Contexts/UserContext';
import { ThemeContext } from '../../../Contexts/ThemeContext';

interface IMaxNumbers {
    maxNumOfExternals: number;
    numOfExternals: number;
    numOfScorers: number;
}

const EngageComponent = (props: any) => {
    let history = useHistory();
    const generalService = new General();
    let result: any;
    let objSearchResult: any;
    let engage = new Engage();
    let contactUsers: IContact[];

    // const [numOfExternals, setNumOfExternals] = useState(0);
    // const [maxNumOfExternals, setMaxNumOfExternals] = useState(3);
    const [ok, setOK] = useState('loading' as string);
    const [maxNaumbers, setMaxNumbers] = useState({ maxNumOfExternals: 3, numOfExternals: 0, numOfScorers: 0 } as IMaxNumbers);
    const [canAddContacts, setCanAddContacts] = useState(true);
    const [okAddRole, setOKAddRole] = useState('loading');
    const [okAddContact, setAddContact] = useState('loading');
    const [okAddExternalUser, setOKAddExternalUser] = useState('loading');
    const [okDeleteExternal, setOKDeleteExternal] = useState('loading');
    const [okDeleteContact, setDeleteContact] = useState('loading');
    const userContext = useContext(UserContext);
    const { theme } = useContext(ThemeContext)

    const [myResult, setMyResult] = useState({
        dataList: [], filteredList: [], pagination:
        {
            currentPage: 0,
            pageSize: 0,
            totalItems: 0,
            totalPages: 0
        }
    });
    const [searchData, setSearchData] = useState('');
    const [searchResult, setSearchResult] = useState<ISearchResult>();
    const [selectedTab, setSelectedTab] = useState<any>("Network");
    const [engageStatus, setEngageStatus] = useState<any>("");
    const [tabName, setTabName] = useState<any>("");
    const [introCompleted, setIntroCompleted] = useState<boolean>(false);

    useEffect(() => {
        logOutIfTokenExpire();
        userContext.dispatch('');
        getUrlParam();
        //eslint-disable-next-line
    }, []);

    const getUrlParam = async () => {
        let intentionResult = await generalService.getProfileIntention();
        if (intentionResult.data.introCompleted) {
            generalService.getIcons();
        } else {
            let iconsPanel = document.getElementById('icons-panel');
            iconsPanel!!.classList.add("d-none");
        }
        let url_string = window.location.href;
        let url = new URL(url_string);
        setEngageStatus(url.searchParams.get("status"));
        setTabName(url.searchParams.get("s"));
        setIntroCompleted(intentionResult.data.introCompleted);
        if (url.searchParams.get("s") === "Meetings") {
            setSelectedTab("Meetings")
        }
    }

    const engageTabs = [
        {
            id: 0,
            title: 'Network',
            heading: 'Network',
            userModuleStatusId: 1,
            programStageId: 0,
            isActive: true
        },
        {
            id: 1,
            title: 'Meetings',
            heading: 'Meetings',
            userModuleStatusId: engageStatus === "complete" ? 1 : 0,
            programStageId: 0,
            isActive: false
        },
    ];

    const searchHandler = async (data: string, page: number) => {
        setSearchData(data);
        if (!data) return;
        let pageNumber: number;
        if (page) {
            pageNumber = page;
        } else {
            pageNumber = 1;
        }
        objSearchResult = await engage.getSearchByName(data, pageNumber);
        if (objSearchResult) {
            let searchItems: ISearchItem[] = objSearchResult?.data?.result?.items;

            let userSearch: string;
            //eslint-disable-next-line
            searchItems.length > 0 && searchItems.map((x: ISearchItem) => {
                userSearch = x.id;
                if (myResult.dataList && myResult.dataList.length > 0) {
                    contactUsers = myResult.dataList;
                }
                let contactUser = contactUsers && contactUsers.find((item) =>
                    item.user.id === userSearch
                );
                if (contactUser) {
                    x.isContact = true;
                } else {
                    x.isContact = false;
                }
            });
            objSearchResult.data.result.items = searchItems;
            setSearchResult(objSearchResult);
        }
    }
    useEffect(() => {
        getData();
    }, [okAddRole, okDeleteContact, okDeleteExternal, okAddContact, okAddExternalUser]);

    const getData = async () => {
        let result: any = await engage.getContacts();
        if (result.status) {
            setOK('success');
            setMaxNumbers({
                maxNumOfExternals: result.data.maxExternalContacts,
                numOfExternals: result?.data?.contacts?.filter((x: IContact) => x?.user?.isExternal)?.length,
                numOfScorers: result?.data?.contacts?.filter((x: IContact) => x?.roles[0] === 'Scorer')?.length
            });
            if (result.data.contacts.length >= result.data.maxContacts) {
                setCanAddContacts(false);
            }
            setMyResult({
                dataList: result.data.contacts,
                filteredList: result.data.contacts.length > 3 ? result.data.contacts.slice(0, 10) : result.data.contacts,
                pagination: {
                    currentPage: 1,
                    pageSize: 10,
                    totalItems: result.data.contacts.length,
                    totalPages: result.data.contacts.length > 10 ? 2 : 1
                }
            });
            if (props.match.params.search && props.match.params.search !== '-1') {
                searchHandler(props.match.params.search, 1);
            }
            else if (searchData) {
                searchHandler(searchData, 1);
            };
            //eslint-disable-next-line
            contactUsers = result.data.contacts;

        } else {
            setOK('failed');
        }

        setOKAddExternalUser('loading');
        setOKDeleteExternal('loading');
        setDeleteContact('loading');
        setAddContact('loading');
        setOKAddRole('loading');

    };

    const onCallbackScorer = async (data: IRole) => {
        if (data.type === 'add') {
            result = await engage.addRoleToContact(data);
        } else {
            result = await engage.deleteRoleToContact(data);
        }

        if (result.status && result.message !== "Role removed") {
            setOKAddRole('true');
        } else {
            setOKAddRole('false');
        }
    }
    const onCallbackDeleteExternal = async (data: string) => {
        result = await engage.deleteExternalUser(data);
        if (result.status) {
            createNotification("success", "User Deleted!");
            setOKDeleteExternal('true');
        } else {
            createNotification("error", "You have already reached the maximum number of network contacts. Remove contacts first!");
            setOKDeleteExternal('false');
        }
    }
    const onCallbackDeleteContact = async (data: string) => {
        result = await engage.deleteContact(data);
        if (result.status) {
            setDeleteContact('true');
            searchHandler(searchData, 1);
        } else {
            setDeleteContact('false');
        }
    }
    const addExternalUser = async (data: IModalData) => {
        let users: any = myResult.dataList
        for (let x = 0; x < users.length; x++) {
            if (users[x].user.email === data.emailAddress) {
                return setOKAddExternalUser('userExists');
            }
        }
        result = await engage.addExternalUser(data);
        if (result.status) {
            createNotification("success", result.message);
            setOKAddExternalUser('true');
            setMyResult({
                dataList: users.push(result.data),
                filteredList: users.length > 3 ? users.slice(0, 10) : users,
                pagination: {
                    currentPage: 1,
                    pageSize: 10,
                    totalItems: users.length,
                    totalPages: users.length > 10 ? 2 : 1
                }
            });
        } else {
            createNotification("error", result.message);
            setOKAddExternalUser('false');
        }
    }
    const addContactHandler = async (data: string) => {
        result = await engage.addContact(data);
        if (result.status) {
            setAddContact('true');
        } else {
            setAddContact('false');
        }
    };
    const pageHandler = (data: number) => {
        searchHandler(searchData, data);
    }
    const contactHandler = (data: number) => {
        setMyResult({
            dataList: myResult.dataList,
            filteredList: data === 1 ? myResult.dataList.slice(0, 10) : myResult.dataList.slice(10),
            pagination: {
                currentPage: data,
                pageSize: 10,
                totalItems: myResult.dataList.length,
                totalPages: myResult.dataList.length > 10 ? 2 : 1
            }
        });
    }

    const TabsClickHandler = (tab: string) => {
        setSelectedTab(tab);
    };

    return <>
        {ok === 'loading' && <LoaderComponent />}
        {ok === 'failed' && <div>
            <div className="mb-4">
                <ErrorInputComponent error={"An error occurred"} />
            </div>
        </div>}

        {ok === 'success' &&
            <div>
                <div className={introCompleted ? 'd-flex margin-bottom-4' : 'd-flex margin-bottom-32'}>
                    <Link to={introCompleted ? "/dashboard" : "/thinkintro"} className="text-decoration-none">
                        {theme === "light" ? (

                            <HeaderComponent
                                title={introCompleted ? 'Engage' : 'Setting up Support'}
                                size={Headers.H1}
                                icon={'chevron-left.svg'}
                                prefix={introCompleted ? '' : 'Introduction: Engage'}
                            />

                        ) :
                            <HeaderComponent
                                customClassName={"imageText"}
                                title={introCompleted ? 'Engage' : 'Setting up Support'}
                                size={Headers.H1}
                                icon={'left-chevron-dark.svg'}
                                prefix={introCompleted ? '' : 'Introduction: Engage'}
                            />

                        }
                    </Link>
                </div>
                <div className="margin-bottom-32">
                    <TabsComponent
                        tabs={engageTabs}
                        onTabSelectedHandler={TabsClickHandler}
                        shouldEnableMeetingTab={tabName === "Meetings"}
                    />
                </div>

                {selectedTab === "Network" &&
                    <div>
                        <ContentShellComponent customClass={'d-xl-inline-flex justify-content-between mainShellConfig'}>
                            <ContentShellComponent customClass={'rightSideConfig'}>
                                <div className="text-transform-uppercase margin-bottom-24">
                                    <HeaderComponent
                                        title={'Contacts'}
                                        size={Headers.H3}
                                    />
                                </div>
                                <div className="margin-bottom-24">
                                    <TextComponent text={'Create your network to support your personal growth.  If you are part of a group, they’ve been added for you. You can also select up to 3 Scorers to provide you with feedback on your practices.'} />
                                </div>
                                <div className="margin-bottom-4">
                                    <TextComponent text={'Search contacts'} />
                                </div>
                                <div>
                                    <SearchInputComponent onValidationHandler={searchHandler} />
                                </div>
                                {isMobileOnly && <ContentShellComponent customClass={'leftSideConfig'}>
                                    <div className="padding-left-30 left-side-engage-inner">
                                        <div className="text-transform-uppercase margin-bottom-24">
                                            <HeaderComponent
                                                title={'DIRECTORY'}
                                                size={Headers.H3}
                                            />
                                        </div>
                                        <div className="margin-bottom-24 d-flex font-weight-600">
                                            <TextComponent text={'Search results'} />
                                        </div>
                                        <div className="margin-bottom-24">
                                            {searchResult && searchResult.data?.result?.items.length > 0 && <SearchContactsListComponent
                                                dataList={searchResult.data?.result?.items}
                                                search={searchData}
                                                pagination={searchResult.data.result.pagination}
                                                onPageHandler={pageHandler}
                                                onAddContactHandler={addContactHandler}
                                                canAddContacts={canAddContacts}
                                            />}
                                            {searchResult && searchResult.data?.result?.items.length === 0 && <div className="mb-4">
                                                <div className="alert alert-warning" role="alert">
                                                    <TextComponent text={'No results found'} />
                                                </div>
                                            </div>}
                                            {(searchResult && !searchResult.data) && <div>
                                                <TextComponent text={'It’s important top have yourself a strong network. To ensure you’re greatess personal development in this program, you should have.'} /></div>}
                                        </div>
                                    </div>
                                    {!introCompleted && <div className="d-flex justify-content-end row button-config">
                                        <div className="col-md-4">
                                            {!introCompleted &&
                                                <Link to={`/do`}>
                                                    <SubmitButtonComponent label={"Continue"} customClassName={'btn-primary-custom'} />
                                                </Link>
                                            }
                                        </div>
                                    </div>}
                                </ContentShellComponent>}
                                {okAddExternalUser === 'userExists' && <div>
                                    <div className="mb-4">
                                        <div className="alert alert-danger" role="alert">
                                            This user already exists!
                                        </div>
                                    </div>
                                </div>}
                                {(maxNaumbers.numOfExternals < maxNaumbers.maxNumOfExternals) && maxNaumbers.numOfScorers < 3 ?
                                    <div className="margin-bottom-24">
                                        <div className="margin-bottom-8">
                                            <TextComponent text={'Want to add someone outside of MindNavigator as a scorer?'} />
                                        </div>
                                        <div>
                                            <ModalComponent
                                                label={'Add external scorer'}
                                                title={'Add External Scorer'}
                                                text={'Enter the email address of the person you would like to add as a scorer. They will receive email notifications when they need to provide you with feedback on your practice.'}
                                                onModalHandler={addExternalUser}
                                            />
                                        </div>
                                    </div> : null}
                                {(!okAddRole || !okDeleteContact || !okDeleteExternal || !okAddContact) && <div className="margin-bottom-24">
                                    <ErrorInputComponent error={"Something went wrong"} />
                                </div>}
                                <div className="margin-bottom-24 contact-wrap-config">
                                    <div className="">
                                        {myResult && <ContactsListComponent
                                            contactsDataStructure={myResult}
                                            onAddScorerHandler={onCallbackScorer}
                                            onDeleteExternalHandler={onCallbackDeleteExternal}
                                            onDeleteContactHandler={onCallbackDeleteContact}
                                            onContactsHandler={contactHandler}
                                            onPageHandler={contactHandler}
                                            search={searchData}
                                            numOfScorers={maxNaumbers.numOfScorers}
                                        />}
                                    </div>
                                </div>
                            </ContentShellComponent>
                            {!isMobileOnly && <ContentShellComponent customClass={'leftSideConfig'}>
                                <div className="padding-left-30 left-side-engage-inner">
                                    <div className="text-transform-uppercase margin-bottom-24">
                                        <HeaderComponent
                                            title={'DIRECTORY'}
                                            size={Headers.H3}
                                        />
                                    </div>
                                    <div className="margin-bottom-24 d-flex font-weight-600">
                                        <TextComponent text={'Search results'} />
                                    </div>
                                    <div className="margin-bottom-24">
                                        {searchResult && searchResult.data?.result?.items.length > 0 && <SearchContactsListComponent
                                            dataList={searchResult.data?.result?.items}
                                            search={searchData}
                                            pagination={searchResult.data.result.pagination}
                                            onPageHandler={pageHandler}
                                            onAddContactHandler={addContactHandler}
                                            canAddContacts={canAddContacts}
                                        />}
                                        {searchResult && searchResult.data?.result?.items.length === 0 && <div className="mb-4">
                                            <div className="alert alert-warning" role="alert">
                                                <TextComponent text={'No results found'} />
                                            </div>
                                        </div>}
                                        {(searchResult && !searchResult.data) && <div>
                                            <TextComponent text={'It’s important top have yourself a strong network. To ensure you’re greatess personal development in this program, you should have.'} /></div>}
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end row button-config">
                                    <div className="col-md-4">
                                        {!introCompleted &&
                                            <Link to={`/do`}>
                                                <SubmitButtonComponent label={"Continue"} customClassName={'btn-primary-custom'} />
                                            </Link>
                                        }
                                    </div>

                                </div>
                            </ContentShellComponent>}
                        </ContentShellComponent>
                    </div>
                }
                {selectedTab === "Meetings" &&
                    <MeetingComponent
                        engageStatus={true}
                    />
                }
            </div>
        }
        {engageStatus === "complete" ?
            <div>
                <TEDModalComponent />
            </div>
            : null
        }
    </>
}
export default EngageComponent;
