import React, { useContext } from "react";
import { ExpanderComponentProps } from "react-data-table-component";
import TextComponent from "../Text/TextComponent";
import { Iprops, IquotesData } from "./LibraryTableComponent";
import { ThemeContext } from "../../../Contexts/ThemeContext";


interface Props extends ExpanderComponentProps<IquotesData> {
    // currently, props that extend ExpanderComponentProps must be set to optional.
    url?: string;
}

const QuoteLibraryTableExpandableRowComponent: (theme: any, url: string, likeQuote: any, props: Iprops) =>

    React.FC<Props> = (theme, url, likeQuote, props) => ({ data }) => {

        const addFavouritesQuote = (row: any) => {
            if (likeQuote) {
                if (row.source === "Concept") {
                    return likeQuote(row.id.split("$")[0], "Concept", row.quoteText);
                } else {
                    return likeQuote(row.id.split("$")[0], "notConcept", row.quoteText);
                }

            }
        }
        // const { theme } = React.useContext(ThemeContext);
        return (
            <>
                <div className="p-3 background-FAFAFA" >
                    <div><b>Quote</b></div>
                    <div className="mt-2 mb-1">
                        <div >
                            {data.quoteText}
                        </div>
                    </div>
                    <div className="mt-2"><b>Author</b></div>
                    <div className="numbers">
                        {data.author}
                    </div>
                    <div className="mt-2"><b>isFavourite</b></div>
                    <div className="numbers">
                        <>
                            {url === "/myquotes" ? <> <div className="mx-2">
                                <img
                                    alt="favourite"
                                    src={window.location.origin + "/images/favourite-filled.svg"}
                                    width={"16px"}
                                    height={"16px"}
                                />
                                <TextComponent text="Added" customClassName="font-weight-600 added-text text-black d-inline-block ps-2 align-middle" />
                            </div>
                            </> : <>
                                    <div onClick={() => addFavouritesQuote(data)}>
                                        <img
                                            alt="favourite"
                                            src={
                                                theme === "light" ? (
                                                    data.isFavourite ?
                                                        window.location.origin + "/images/favourite-filled.svg"
                                                        : "/images/favourite-empty.svg"
                                                ) : data.isFavourite ?
                                                        window.location.origin + "/images/favourite-filled.svg"
                                                        : "/images/favourite-empty-dark.svg"
                                            }
                                            width={"16px"}
                                            height={"16px"}
                                        />
                                        <TextComponent text="Add" customClassName="font-weight-600 added-text text-black d-inline-block ps-2 align-middle" />
                                    </div>
                                </>}
                        </>
                    </div>
                </div>
            </>
        );
    };


export default QuoteLibraryTableExpandableRowComponent;