import constant from "../Utils/Config";
import {isTokenExpired,clearLocalStorageAndRedirect, getAuthToken} from "../Utils/Utils";
import {ISelectionPreferenceResponse} from "../interfaces/selectionPreference/ISelectionPreference"; 
import { ICoreValueChartResponse } from "../interfaces/Charts/ICoreValueChart";

class Reporting {

    private isGuid = (stringToTest : string) => {        
        var regexGuid = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        return regexGuid.test(stringToTest);            
    }

    async getMeetingAttendence(pageNumber: number, pageSize: number, selectedGroup: string, selectedProgram: number, selectedModule: number, selectedUser: string) {
        let params = "";
        if (selectedGroup !== "" && selectedGroup !== undefined) {
            params += "&GroupIds=" + selectedGroup
        }
        if (selectedProgram > 0  && selectedProgram !== undefined) {
            params += "&ProgramId=" + selectedProgram
        }
        if (selectedModule > 0 && selectedModule !== undefined) {
            params += "&ModuleId=" + selectedModule
        }
        if (selectedUser !== "" && selectedUser !== undefined) {
            params += "&ParticipantIds=" + selectedUser
        }
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Report/MeetingAttendance?pageNumber=${pageNumber}&pageSize=${pageSize}${params}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    };

    async getIndividualModuleProgress(participantId: string, programStageId: number) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Report/IndividualModuleProgress?participantId=${participantId}&programStageId=${programStageId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    };

    async getModuleProgress(page: number, pageSize: number, searchTerm: string, selectedModule: number, selectedGroup: string) {
        let params = "";
        if (searchTerm !== "" && searchTerm !== undefined) {
            params += "&searchTerm=" + searchTerm
        }
        if (selectedGroup !== "" && selectedGroup !== undefined) {
            params += "&groupIds=" + selectedGroup
        }
        if (selectedModule > 0 && selectedModule !== undefined) {
            params += "&moduleId=" + selectedModule
        }
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Report/ModuleProgress?page=${page}&pageSize=${pageSize}${params}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    };

    async getSurveyCompletion(pageNumber: number, pageSize: number, selectedSurvey: number, selectedProgram: number, selectedUser: string) {
        let params = "";
        if (selectedProgram > 0 && selectedProgram !== undefined) {
            params += "&programId=" + selectedProgram
        }
        if (selectedSurvey > 0 && selectedSurvey !== undefined) {
            params += "&surveyProgramId=" + selectedSurvey
        }
        if (selectedUser !== "" && selectedUser !== undefined) {
            params += "&participantIds=" + selectedUser
        }
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Report/SurveyCompletionReport?page=${pageNumber}&pageSize=${pageSize}${params}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    };

    async getUserScoreReport(page: number, pageSize: number, selectedGroup: string, selectedProgram: number, selectedModule: number, selectedUser: string) {
        let params = "";
        if (selectedGroup !== "" && selectedGroup !== undefined) {
            params += "&GroupIds=" + selectedGroup
        }
        if (selectedModule > 0 && selectedModule !== undefined) {
            params += "&ModuleId=" + selectedModule
        }
        if (selectedUser !== ""  && selectedUser !== undefined) {
            params += "&ParticipantIds=" + selectedUser
        }
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Report/UserScoreReport?page=${page}&pageSize=${pageSize}${params}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    };

    async getExportMeeting(selectedGroup: string, selectedProgram: number, selectedModule: number, selectedUser: string, selectedExportType: string) {
        let params = "";
        if (selectedGroup !== "" && selectedGroup !== undefined) {
            params += "&GroupIds=" + selectedGroup
        }
        if (selectedProgram > 0 && selectedProgram !== undefined) {
            params += "&ProgramId=" + selectedProgram
        }
        if (selectedModule > 0 && selectedModule !== undefined) {
            params += "&ModuleId=" + selectedModule
        }
        if (selectedUser !== "" && selectedUser !== undefined) {
            params += "&ParticipantIds=" + selectedUser
        }
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response: any = await fetch(`${process.env.REACT_APP_BASE_URL}/Report/ExportMeetingAttendance?exportType=${selectedExportType}${params}`, {
                method: 'GET',
                headers: {
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response) => response.blob()).then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `Meeting_Progress_Report.${selectedExportType === "Excel" ? "xlsx" : "csv"}`,
                );
                document.body.appendChild(link);
                link.click();
            });
            return response;
        }        
    };

    async getExportModuleProgress(selectedModule: number, selectedGroup: string,searchTerm: string, selectedExportType: string) {
        let params = "";
        if (searchTerm !== "" && searchTerm !== undefined) {
            params += "&searchTerm=" + searchTerm
        }
        if(selectedModule > 0 && selectedModule !== undefined){
            params += "&moduleId=" + selectedModule 
        }
        if (selectedGroup !== "" && selectedGroup !== undefined) {
            params += "&groupIds=" + selectedGroup
        }
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response: any = await fetch(`${process.env.REACT_APP_BASE_URL}/Report/ExportModuleProgress?exportType=${selectedExportType}${params}`, {
                method: 'GET',
                headers: {
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response) => response.blob()).then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `Module_Progress_Report.${selectedExportType === "Excel" ? "xlsx" : "csv"}`,
                );
                document.body.appendChild(link);
                link.click();
            });
            return response;
        }        
    };

    async getExportIndividualModuleProgress(participantId: string, programStageId: number, selectedExportType: string) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response: any = await fetch(`${process.env.REACT_APP_BASE_URL}/Report/ExportIndividualModuleProgress?participantId=${participantId}&programStageId=${programStageId}&exportType=${selectedExportType}`, {
                method: 'GET',
                headers: {
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response) => response.blob()).then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `Individual_Module_Progress_Report.${selectedExportType === "Excel" ? "xlsx" : "csv"}`,
                );
                document.body.appendChild(link);
                link.click();
            });
            return response;
        }        
    };

    async getExportUserScore(selectedGroup: string, selectedModule: number, selectedUser: string, selectedExportType: string) {
        let params = "";
        if (selectedGroup !== "" && selectedGroup !== undefined) {
            params += "&groupIds=" + selectedGroup
        }
        if (selectedModule !== 0 && selectedModule !== undefined) {
            params += "&moduleId=" + selectedModule
        }
        if (selectedUser !== "" && selectedUser !== undefined) {
            params += "&participantIds=" + selectedUser
        }
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response: any = await fetch(`${process.env.REACT_APP_BASE_URL}/Report/ExportUserScoreReport?exportType=${selectedExportType}${params}`, {
                method: 'GET',
                headers: {
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response) => response.blob()).then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `User_Score_Report.${selectedExportType === "Excel" ? "xlsx" : "csv"}`,
                );
                document.body.appendChild(link);
                link.click();
            });
            return response;
        }        
    };

    async getExportProgramSurvey(selectedSurvey: number, selectedProgram: number, selectedUser: string, selectedExportType: string) {
        let params = "";
        if (selectedSurvey > 0 && selectedSurvey !== undefined) {
            params += "&surveyProgramId=" + selectedSurvey
        }
        if (selectedProgram > 0 && selectedProgram !== undefined) {
            params += "&programId=" + selectedProgram
        }
        if (selectedUser !== "" && selectedUser !== undefined) {
            params += "&ParticipantIds=" + selectedUser
        }
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response: any = await fetch(`${process.env.REACT_APP_BASE_URL}/Report/ExportSurveyCompletionReport?exportType=${selectedExportType}${params}`, {
                method: 'GET',
                headers: {
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response) => response.blob()).then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `Survey_Completion_Report.${selectedExportType === "Excel" ? "xlsx" : "csv"}`,
                );
                document.body.appendChild(link);
                link.click();
            });
            return response;
        }        
    };

    async getSelectionPreferenceReport(groupIds:string,page:number,pageSize:number){
        let params = `?groupIds=${groupIds}&page=${page}&pageSize=${pageSize}`;
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const url = constant.selectionPreferenceReport+params;
            const requestOptions = {
                method:'GET',
                headers: {
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            };
            try{
                const response = await fetch(url,requestOptions);
                if(response.ok){
                    const result = await response.json();
                    if(result){
                        return result as ISelectionPreferenceResponse;
                    }
                    else{
                       return {data:null,message:'Something went wrong',status:false}
                    }
                }
            }catch(error){
                return {data:null,message:'Something went wrong',status:false};
            }
        }
    }

    async getExportSelectionPreferenceReport(groupIds:string,selectedExportType: string){
        let params = `?groupIds=${groupIds}&exportType=${selectedExportType}`;
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const url = constant.exportselectionPreferenceReport+params;
            const requestOptions = {
                method:'GET',
                headers: {
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            };
            const response = fetch(url,requestOptions).then((response) => response.blob()).then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `Selection_Preference_Report.${selectedExportType === "Excel" ? "xlsx" : "csv"}`,
                );
                document.body.appendChild(link);
                link.click();
            });
            return response;
        }
    }

    async getCoreValueReport(groupIds:string,participantIds:string){
        let params = "?";
        if (groupIds !== "" && groupIds !== undefined) {
            params += "&groupIds=" + groupIds
        }
        if (participantIds !== "" && participantIds !== undefined) {
            params += "&participantIds=" + participantIds
        }
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const url = constant.getCoreValuesReport+params;
            const requestOptions = {
                method:'GET',
                headers: {
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            };
            try{
                const response = await fetch(url,requestOptions);
                if(response.ok){
                    const result = await response.json();
                    if(result){
                        return result as ICoreValueChartResponse;
                    }
                    else{
                       return {data:null,message:'Something went wrong',status:false}
                    }
                }
            }catch(error){
                return {data:null,message:'Something went wrong',status:false};
            }
        }
    }
}
export default Reporting;