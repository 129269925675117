import React, { CSSProperties, useContext } from 'react';
import HeaderComponent from '../Header/HeaderComponent';
import { Headers } from '../../../Utils/Enums';
import practicesStyles from "./PracticesComponent.module.css";
import TextComponent from '../Text/TextComponent';
import CircleImageComponent from "../CircleImage/CircleImageComponent"
import { isDesktop } from 'react-device-detect';
import { ThemeContext } from '../../../Contexts/ThemeContext';

export interface IPracticeStepViewModels {
    id?: number;
    practiceId?: number;
    title?: string;
}
export interface IPracticeViewModels {
    details?: string;
    id?: number;
    instructions?: string;
    summary?: string;
    title?: string;
    height?: CSSProperties | undefined;
    practiceStepViewModels?: IPracticeStepViewModels[] //once the content is filled need to remove the question mark as all practices should have details
}
interface IPractices {
    title: string;
    icon?: string;
    left?: boolean;
    badge?: string;
    text?: string;
    id?: number;
    index?: number;
    conceptId?: number;
    messageType?: string;
    practiceId?: number;
    practiceViewModels?: IPracticeViewModels[];
    requireResponse?: boolean;
    enableContinue?: boolean;
    instructions?: string;
    ticked?: boolean;
    onTedPracticeHandler?: (tedMessageId: number, practiceId: number, messageType: string, ticked: boolean) => void;
    onTedPracticeDetailsHandler?: (tedMessageId: number, item: IPracticeViewModels, ticked: boolean) => void;
}
const PracticesComponent = (props: IPractices) => {
    const { theme } = useContext(ThemeContext);
    const sendTedResponse = (event: any) => {
        let ticked: boolean = false;
        const id = event.currentTarget.id.split('-')[1];
        let selectedHeart: any = document.getElementById(event.target.id);
        if (theme === "light") {
            if (selectedHeart.src === window.location.origin + '/images/favourite-empty.svg') {
                selectedHeart.src = window.location.origin + '/images/favourite-filled.svg';
                ticked = true;
            } else {
                selectedHeart.src = window.location.origin + '/images/favourite-empty.svg';
                ticked = false;
            }
        } else {
            if (selectedHeart.src === window.location.origin + '/images/favourite-empty-dark.svg') {
                selectedHeart.src = window.location.origin + '/images/favourite-filled.svg';
                ticked = true;
            } else {
                selectedHeart.src = window.location.origin + '/images/favourite-empty-dark.svg';
                ticked = false;
            }
        }

        props.onTedPracticeHandler && props.onTedPracticeHandler(Number(props.id), Number(id), 'Practice', ticked);
    }
    const practiceDescriptionHandler = (event: any) => {
        let ticked: boolean = false;
        const id = Number(event.currentTarget.id.split('-')[1]);

        if (props.practiceViewModels) {
            let practice: IPracticeViewModels | undefined = props.practiceViewModels.find(x => x.id === id);
            if (practice)
                props.onTedPracticeDetailsHandler && props.onTedPracticeDetailsHandler(Number(props.id), practice, ticked);
        }
    }

    return <>
        {theme === "light" ? (
            <><div className={`filter-156-156-156-025 text-color-2D5362 background-FFFFFF d-flex flex-column margin-bottom-16 right-message`}>
                <div className={`${practicesStyles['arrowLeft']} position-absolute color-FFFFFF`}>
                    <div className="position-relative">
                        {props.icon &&
                            <div className={`${practicesStyles['tedConfigRight']} position-absolute`}>
                                <CircleImageComponent size={'imageSize36'} image={'Ted.png'} />
                            </div>}
                    </div>
                </div>
                <div className="padding-16-0">
                    <HeaderComponent
                        title={String(props.title)}
                        size={Headers.H3}
                    />
                </div>
                {props.practiceViewModels && props.practiceViewModels.map((item: any, index: any) =>
                    <div key={index} className="d-flex margin-bottom-8 justify-content-between">
                        <div className={`w-70 practice-link`}>
                            <TextComponent text={item.title} customClassName={'practice-link chatMessageText'} />{/* temporarely text is hardcoded */}
                        </div>
                        <div className="d-flex justify-content-start">
                            {isDesktop && <div>
                                <img src={window.location.origin + '/images/info.svg'} alt="info" className={`cursor-pointer`}
                                    onClick={practiceDescriptionHandler} id={"link-" + item.id} />
                            </div>}
                            <div>

                                <img
                                    src={window.location.origin + '/images/favourite-empty.svg'}
                                    className={`cursor-pointer practice-hearts`} onClick={sendTedResponse}
                                    id={"img-" + item.id} alt="empty-heart"
                                />

                            </div>
                        </div>
                    </div>
                )}
            </div></>
        ) : <>
            <div style={{ backgroundColor: "#1c2930", color: "#fff" }} className={`filter-156-156-156-025 text-color-2D5362 background-FFFFFF d-flex flex-column margin-bottom-16 right-message`}>
                <div style={{ color: "#1c2930" }} className={`${practicesStyles['arrowLeft']} position-absolute color-FFFFFF`}>
                    <div className="position-relative">
                        {props.icon &&
                            <div className={`${practicesStyles['tedConfigRight']} position-absolute`}>
                                <CircleImageComponent size={'imageSize36'} image={'Ted.png'} />
                            </div>}
                    </div>
                </div>
                <div className="padding-16-0">
                    <HeaderComponent
                        title={String(props.title)}
                        size={Headers.H3}
                    />
                </div>
                {props.practiceViewModels && props.practiceViewModels.map((item: any, index: any) =>
                    <div key={index} className="d-flex margin-bottom-8 justify-content-between">
                        <div className={`w-70 practice-link`}>
                            <TextComponent text={item.title} customClassName={'practice-link chatMessageText'} />{/* temporarely text is hardcoded */}
                        </div>
                        <div className="d-flex justify-content-start">
                            {isDesktop && <div>
                                <img src={window.location.origin + '/images/info.svg'} alt="info" className={`cursor-pointer`} onClick={practiceDescriptionHandler} id={"link-" + item.id} />
                            </div>}
                            <div>
                                <img
                                    src={window.location.origin + '/images/favourite-empty-dark.svg'}
                                    className={`cursor-pointer practice-hearts`} onClick={sendTedResponse}
                                    id={"img-" + item.id} alt="empty-heart"
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div></>}
    </>
}
export default PracticesComponent;