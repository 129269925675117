import React from 'react';
import { ISearchItem } from '../../../Services/Engage.service';
import SearchContactItemComponent from '../SearchContactItem/SearchContactItemComponent';
import PaginationComponent, { IPaginationComponent } from '../Paginator/PaginationComponent';
import './SearchContactsListComponent.css';


interface ISearchContactsListComponent {
    dataList: ISearchItem[];
    pagination: IPaginationComponent;
    search?: string;
    onAddContactHandler: (data: string) => void;
    onPageHandler: (data: number) => void;
    canAddContacts: boolean;
}
const SearchContactsListComponent = (props: ISearchContactsListComponent) => {
    const arrContacts = props.dataList;
    const addContactHandler = (data: string) => {
        props.onAddContactHandler(data);
    };
    const pageHandler = (data: number) => {
        props.onPageHandler(data);
    };
    return <>
        {arrContacts && arrContacts.length > 0 && arrContacts.map((item: ISearchItem, index: any) =>
            <div className="" key={index}>
                <SearchContactItemComponent
                    item={item}
                    search={props.search}
                    onAddContactHandler={addContactHandler}
                    canAddContacts={props.canAddContacts} />
            </div>)}
        <div className="margin-top-16 d-flex justify-content-end">
            {props.pagination && <PaginationComponent
                currentPage={props.pagination.currentPage}
                pageSize={props.pagination.pageSize}
                totalItems={props.pagination.totalItems}
                totalPages={props.pagination.totalPages}
                onPageHandler={pageHandler} />}
        </div>

    </>
}
export default SearchContactsListComponent;