import {isTokenExpired,clearLocalStorageAndRedirect, getAuthToken, getQuerySringPreparedUrl} from "../Utils/Utils";
interface IcreateProgramMeeting {
    program: any;
    module: any;
    moduleStep: any;
    group: any;
    name: string;
    description: string;
    location: string;
    room: string;
    meetingDate: any;
    duration: string
}

interface IupdateProgramMeeting {
    program: any;
    module: any;
    moduleStep: any;
    group: any;
    name: string;
    description: string;
    location: string;
    room: string;
    meetingDate: any;
    duration: string
}

class ProgramMeeting {

    async getProgramMeeting(groupId: number, pageNumber: number, pageSize: number,searchStr:string) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            let obj = {
                groupId:groupId,
                pageNo:pageNumber,
                pageSize:pageSize,
                searchTerm:searchStr,
            }
            const response = await fetch(getQuerySringPreparedUrl(`${process.env.REACT_APP_BASE_URL}/ProgramMeeting/ProgramMeetings`,obj), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    };

    async createProgramMeeting(createMeetingData: IcreateProgramMeeting) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ProgramMeeting/ProgramMeetings`, {
                method: 'POST',
                body: JSON.stringify(createMeetingData),
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    };

    async updateProgramMeeting(updateMeetingData: IupdateProgramMeeting) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ProgramMeeting/ProgramMeetings`, {
                method: 'PUT',
                body: JSON.stringify(updateMeetingData),
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    };

    async deleteProgramMeeting(deleteMeetingId: string) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ProgramMeeting/ProgramMeeting?Id=${deleteMeetingId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    };

    async getProgramMeetingAttendance(meetingId: string) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ProgramMeeting/ProgramMeetingAttendance?meetingId=${meetingId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    };

    async updateProgramMeetingAttendance(meetingId: string, updateMeetingAttendanceData: any[]) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ProgramMeeting/ProgramMeetingAttendance?meetingId=${meetingId}`, {
                method: 'PUT',
                body: JSON.stringify(updateMeetingAttendanceData),
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    };

    async getUserProgramMeeting(pageNumber: number, pageSize: number) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/ProgramMeeting/UserProgramMeetings?pageNo=${pageNumber}&pageSize=${pageSize}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    };
}

export default ProgramMeeting;