import React, { useState, useEffect } from "react";

type Theme = "light" | "dark";
type ThemeContext = { theme: Theme; toggleTheme: () => void };

export const ThemeContext = React.createContext<ThemeContext>(
    {} as ThemeContext
);

export const ThemeProvider: React.FC = ({ children }) => {
    const [theme, setTheme] = useState<Theme>("light")

    const toggleTheme = () => {
        setTheme(theme === "light" ? "dark" : "light");
    };
    localStorage.setItem("Theme", theme)
    const color = theme === "light" ? "#1C2930" : "#cecece";
    const backgroundColor = theme === "light" ? "#fff" : "#1C2930";

    document.body.style.color = color;
    document.body.style.backgroundColor = backgroundColor;

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};