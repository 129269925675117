import constant from "../Utils/Config";
import {isTokenExpired,clearLocalStorageAndRedirect, getAuthToken} from "../Utils/Utils";
class Module {
    async getModule(moduleId: number, moduleStepId: number) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Module/GetModuleWithStepConcept?moduleId=` + moduleId + "&moduleStepId=" + moduleStepId, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            });
            if (!response.ok) {
                return { data: "error", ok: false };
            } else {
                const result = await response.json();
                if (result) {
                    return { data: result, ok: true };
                } else {
                    return { data: "error", ok: false };
                }
            }
        } catch (error) {
            return { data: error, ok: false };
        }
    };

    async saveUserModuleQuote(id: number, userResponse: string) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            let isFavourite: boolean = false;
            if (userResponse === 'true') {
                isFavourite = true;
            }
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Module/ModuleQuoteUserResponse?moduleQuoteId=${id}&isFavourite=${isFavourite}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    };
}
export default Module;