import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router";
import { TableColumn } from "react-data-table-component";
import Survey from "../../../Services/Survey.service";
import { createNotification } from "../../../Utils/Toast";
import TableComponent from "../../FunctionalComponents/Table/TableComponent";
import { FACILITOR } from "../../../Utils/Roles";
import { Button } from "reactstrap";
import "./ProgramSurveyComponent.css";
import TextComponent from "../../FunctionalComponents/Text/TextComponent";
import General from "../../../Services/General.service";
import { Col, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { logOutIfTokenExpire } from '../../../Utils/Utils';
import { ThemeContext } from "../../../Contexts/ThemeContext";
import "../../../darkcustom.css"

interface IsurveyColumnsInformation {
    surveyName: string;
    openDate: string;
    groupName: string;
    locked: boolean;
    action: any;
}

interface Iprops {
    role: string;
    groups: any;
    userId: string;
    reloadSurvey: boolean
    setLoading: (data: boolean) => void;
    setReloadSurvey: (data: boolean) => void;
}

function ProgramSurveyComponent(props: Iprops) {
    let { theme } = useContext(ThemeContext)
    const generalService = new General();
    generalService.getIcons();

    const { setLoading } = props;
    let history = useHistory();
    const survey = new Survey();
    const [tabelData, setTabelData] = useState<any[]>([]);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [rowsPerpage, setRowsPerpage] = useState<number>(10);
    const [searchStr, setSearchStr] = useState<string>("");

    useEffect(() => {
        logOutIfTokenExpire();
        getProgramSurvey();
        //eslint-disable-next-line
    }, [currentPage, rowsPerpage]);

    const getProgramSurvey = async () => {
        setLoading(true);
        let response: any = await survey.getProgramSurvey(currentPage, rowsPerpage, searchStr);
        if (response.status) {
            setLoading(false);
            setTotalPages(response.data.pagination.totalItems);
            let tempTableData: any = response.data.items.map((data: any) => {
                return {
                    id: data.id,
                    surveyName: data.name,
                    groupName: data.groupName,
                    groupId: data.groupId,
                    locked: data.isLock,
                    openDate: new Date(data.openDate).toLocaleDateString('en-GB'),
                    // date: data.openDate,
                    program: data.program.id,
                    programName: data.program.name,
                    programStage: data.programStage.id,
                    programStageName: data.programStage.name
                }
            })
            setTabelData(tempTableData);
        }
    }

    if (props.reloadSurvey) {
        getProgramSurvey();
        props.setReloadSurvey(false);
    }

    const handlePageChange = (e: any): void => {
        setCurrentPage(e);
    };

    const handleRowsPerPage = (e: any): void => {
        setRowsPerpage(e);
    };

    const surveyColumns: TableColumn<IsurveyColumnsInformation>[] = [
        {
            name: "Survey Name",
            selector: (row) => row.surveyName,
            sortable: true,
            cell: (row) => (
                <div className="text">
                    {row.surveyName}
                </div>
            )
        },
        {
            name: "Open Date",
            selector: (row) => row.openDate,
        },
        {
            name: "Group Name",
            selector: (row) => row.groupName,
            cell: (row) => (
                <div className="text">
                    {row.groupName}
                </div>
            )
        },
        {
            name: "Action",
            cell: (row) => (
                <>
                    <OverlayTrigger
                        placement={"top"}
                        overlay={
                            <Tooltip id={`tooltip-right`}>
                                Questions
                                    </Tooltip>
                        }
                    >
                        <img alt="Manage questions" onClick={() => handleQuestionClick(row)} src={window.location.origin + "/images/questionnaire-32.png"} />
                    </OverlayTrigger>
                    {row.locked
                        ? null
                        :
                        <>
                            <OverlayTrigger
                                placement={"top"}
                                overlay={
                                    <Tooltip id={`tooltip-right`}>
                                        Edit survey
                                    </Tooltip>
                                }
                            >
                                {theme === "light" ? (
                                    <img alt="Edit survey" onClick={() => handleEditClick(row)} src={window.location.origin + "/images/edit.svg"} />
                                ) : <img alt="Edit survey" onClick={() => handleEditClick(row)} src={window.location.origin + "/images/edit-dark.svg"} />}
                            </OverlayTrigger>

                            <OverlayTrigger
                                placement={"top"}
                                overlay={
                                    <Tooltip id={`tooltip-right`}>
                                        Delete survey
                                    </Tooltip>
                                }
                            >
                                {theme === "light" ? (
                                    <img alt="Delete survey"
                                        onClick={() => handleDeleteClick(row)} src={window.location.origin + "/images/close.svg"} />
                                ) : <img alt="Delete survey"
                                    onClick={() => handleDeleteClick(row)} src={window.location.origin + "/images/close-dark.svg"} />}
                            </OverlayTrigger>

                            <OverlayTrigger
                                placement={"top"}
                                overlay={
                                    <Tooltip id={`tooltip-right`}>
                                        Lock survey
                                    </Tooltip>
                                }
                            >
                                <img alt="Lock" onClick={() => handleLockClick(row)} height={"30px"} width={"30px"} src={window.location.origin + "/images/lock-line.svg"} />
                            </OverlayTrigger>


                        </>
                    }
                </>
            ),
        },
        {
            name: "Status",
            selector: (row) => row.locked,
            cell: (row) => (
                <>
                    {theme === "light" ? (
                        <TextComponent
                            text={row.locked ? "Locked" : "Available"}
                            customClassName="text-color-182C34 text"
                        />
                    ) :
                        <TextComponent
                            text={row.locked ? "Locked" : "Available"}
                            customClassName="text-color-#fff text"
                        />}

                </>
            )
        },
    ];

    const handleEditClick = async (row: any) => {
        if (!row.locked) {
            let response = await survey.getProgramSurveyById(row.id.toString());
            if (response && response.status) {
                history.push({
                    pathname: "/createsurvey",
                    state: {
                        editData: true,
                        id: row.id,
                        name: response.data.name,
                        date: row.openDate,
                        groupId: row.groupId,
                        groupName: row.groupName,
                        groups: props?.groups,
                        program: response.data.program,
                        programStage: response.data.programStage,
                        questions: response.data.questions,
                    }
                });
            } else {
                createNotification("error", response.message || "Something went wrong!")
            }
        } else {
            createNotification("error", "This survey is locked!")
        }
    }

    const handleDeleteClick = async (row: any) => {
        if (!row.locked) {
            setLoading(true);
            let response: any = await survey.deleteProgramSurvey(row.id);
            if (response.status) {
                setLoading(false);
                getProgramSurvey();
                createNotification("success", response.message);
            } else {
                createNotification("error", response.message);
            }
        } else {
            createNotification("error", "This survey is locked!");
        }
    }

    const handleLockClick = async (row: any) => {
        let response: any = await survey.lockSurvey(Number(row.id));
        if (response.status) {
            createNotification("success", response.message);
            getProgramSurvey();
        } else {
            createNotification("error", response.message);
        }
    }

    const handleSearch = (event: any) => {
        setSearchStr(event.target.value.toString());
        if (event.keyCode === 13) {
            getProgramSurvey();
        }
    }

    const handleQuestionClick = async (row: any) => {
        history.push({
            pathname: "/programsurveyquestions",
            state: {
                surveyId: row.id
            }
        });
    }

    return (
        <TableComponent
            data={tabelData}
            columns={surveyColumns}
            tableTitle={"Survey List"}
            totalRows={totalPages}
            rowsPerpage={rowsPerpage}
            handlePageChange={(e: any) => handlePageChange(e)}
            handleRowsPerPage={(e: any) => handleRowsPerPage(e)}
            isPagination={true}
            pageAction={
                props?.role !== FACILITOR && (
                    <div className="d-flex">
                        <div className="px-1">
                            {theme === "light" ? (
                                <Form.Group as={Col} xs={12} md={4} lg={2} controlId="formGridGroup" className="mt-1 w-100">
                                    <Form.Control type="text" placeholder="Survey name" onKeyUp={handleSearch} />
                                </Form.Group>
                            ) : <Form.Group as={Col} xs={12} md={4} lg={2} controlId="formGridGroup" className="mt-1 w-100">
                                    <Form.Control className="clientSearchBox" type="text" placeholder="Survey name" onKeyUp={handleSearch} />
                                </Form.Group>}
                        </div>

                        <div className="px-1">
                            <Button color="secondary" className="font-weight-600" onClick={() => {
                                getProgramSurvey();
                            }}>
                                {theme === "light" ? (
                                    <TextComponent text={"Search"} customClassName="text-color-182C34" />
                                ) : <TextComponent text={"Search"} customClassName="buttonText" />}
                            </Button>
                        </div>
                        <Button
                            color="secondary"
                            className="button-font-style"
                            onClick={() => {
                                history.push({
                                    pathname: "/createsurvey",
                                    state: {
                                        groups: props?.groups
                                    }
                                })
                            }}
                        >
                            {theme === "light" ? (
                                <TextComponent text={"Create Survey"} customClassName="text-color-182C34" />
                            ) : <TextComponent text={"Create Survey"} customClassName="buttonText" />}
                        </Button>
                    </div>
                )
            }
        />
    );
}

export default ProgramSurveyComponent;