const EULA: string = `<p> A. Please read the following terms and conditions carefully before downloading or using the Mindnavigator product that accompanies this license agreement.</p>

<p>B. The Mindnavigator product is a technology platform offering change solutions focused on optimizing human potential(“the Program”).</p>

<p>C. The Mindnavigator technology platform(“System”) provides online and / or digital services(“the Services”) and materials and content available therein(“Materials”), in order for you to complete the Program.Together, the System, Services and Materials are referred to in this Agreement as “the Product”.</p>

<p>D. The terms and conditions of this End User License Agreement(“License Agreement”) and the Mindnavigator Terms of Business you executed or agreed to(as applicable) in each case governing your license to use the Product(collectively, this “Agreement”) are an agreement between you and Mindnavigator Technologies Pty Ltd ABN 34 600 293 721(“Mindnavigator”, “us”) and govern the use of the Product.</p>

<p>E. For the purpose of this Agreement:</p>

<ol>
<li>“End User” means any person or entity that is permitted by Mindnavigator or its authorised representative to use the Product in accordance with this Agreement; </li>
<li>“Purchase Order” means an order placed by the Purchaser with Mindnavigator or Mindnavigator’s authorized representative for the delivery of the Product and / or Services.The purchase order may be comprised by of a quote and purchase order or a contract of engagement issued by Mindnavigator or Mindnavigator’s authorised representative; </li>
<li>“Purchaser” means the purchaser named in the Terms of Business; and</li>
<li>“Terms of Business” means the Terms of Business and Purchase Order.</li>
</ol>

<p>F. You have been enrolled in the platform and the Purchaser is required to pay in full the applicable fees for your enrolment in the relevant parts of the platform(“Modules”) as specified in the Terms of Business.</p>

<p>G. Mindnavigator is willing to license the Product to you only on the condition that you accept all the terms contained in this Agreement.By clicking to accept where indicated below or by downloading or using the Product, you have indicated that you understand this Agreement and accept all of its terms.If you do not accept all the terms of this Agreement, then Mindnavigator is unwilling to license the Product to you or your company and you will be prohibited from accessing the Product and you or your company will be eligible to request a refund within 30 days of the date of purchase of the product if prior payment has been received by Mindnavigator.If payment has been made to an agent of Mindnavigator(“a Reseller”), your refund must be requested from the relevant Reseller within 30 days of the date of purchase.</p>

<p>H. The following terms and conditions govern your use of the Product which are provided by Mindnavigator and the Service Providers(as defined in clause 6.1).</p>

<p><strong>1.	License and Restrictions on Use</strong></p>

<p><strong>1.1	Trial License</strong></p>
<p>If you or the Purchaser have obtained a trial version of the Product from Mindnavigator or from an authorised Mindnavigator reseller, then conditional upon your compliance with the terms and conditions of this Agreement, Mindnavigator grants you a non - exclusive and non - transferable license use the Product for the period specified in the Terms of Business.The foregoing license permits use of only the number of licenses for end users as is expressly permitted by Mindnavigator with respect to your trial.If no such number of trial licenses for end users is specified by Mindnavigator, the foregoing license permits the trial use for a single end user only.</p>

<p><strong>1.2	Paid License</strong></p>
<p>If you or the Purchaser purchased a license to the Product from Mindnavigator or from an authorised Mindnavigator reseller, then conditioned upon your compliance with the terms and conditions of this Agreement, Mindnavigator grants you a non - exclusive and non - transferable license to use the Product for the number of licenses for end users as specified in the Terms of Business.</p>

<p>1.3	The license granted under this License Agreement applies to the System which is accessible via:</p>

<ol>
<li>a website which is accessible from computer desktops at the URL <u><strong>www.mindnavigator.com</strong></u>;</li>
<li>the Purchaser’s own URL such as <u><strong>www.xyzcompany.mindnavigator.com</strong></u> (if applicable);</li>
(together referred to as “the Website”) and
<li>the mobile telephone application(“Application”), when available.</li>
</ol>

<p>1.4	If the Application is unavailable, this does not constitute a breach of this Agreement.</p>

<p>1.5	You are granted during the term of this Agreement, a non - exclusive, non - transferrable limited license to access and use the Product for the term of this License(as specified in clause 5) for your personal use only.This license is subject to the following limitations:</p>

<ol>
<li>The right to electronically display the Materials retrieved from the Services is limited to the display of such Materials to you only;</li>

<li>The right to obtain a printout of Materials is limited to a printout of a reasonable portion of the Materials obtained using the printing commands of the services of your web browser software and the creation of a single printout of a reasonable portion of the Materials downloaded via downloading commands of the Services or your web browser software(collectively, “Authorized Printouts”); and</li>

<li>The right to retrieve and store machine - readable copies of Materials is limited to the retrieval of a single copy of a reasonable portion of the Materials included in any individual file of the Services or your web browser software, and in respect of Services, storage of that copy in machine - readable form for no more than 90 days for your exclusive use.Insubstantial electronic copies of the Materials may be stored beyond the time restriction referred to in this clause 1.5(c) where the materials have been incorporated into work for the purpose of completing the Modules in the Program.This clause is subject to the overriding obligation upon you not to create your own independently searchable database of the Materials.</li>

</ol>

<p>1.6	To the extent permitted by copyright law, you may make copies of Authorized Printouts and distribute Authorised Printouts and copies to others who are currently enrolled in the Program for training purposes only.<p>

<p>1.7	You may not print or download Materials without using the printing or downloading commands of the Services or your web browser software.</p>

<p>1.8	All access to and use of the Product via mechanical, programmatic, robotic, scripted or any other automated means not provided as part of the Product is strictly prohibited.Use of the Services is permitted only via manually conducted, discrete individual search and retrieval activities.</p>

<p>1.9	Except as expressly specified or permitted in this Agreement you must not:</p>
<ol>
<li>copy(except in the course of downloading or installing) or modify the Product, including adding new features or otherwise making adaptations to alter the functioning of the Product;</li>

<li>download, store, reproduce, transmit, display, print or use the Product;</li>

<li>transfer, sublicense, lease, lend, rent or otherwise distribute the Product to any third party;</li>

<li>make the functionality of the Product available to any third party through any means, including but not limited to, by uploading the Product or part thereof to a network or file - sharing service or through hosting, application services provider or bureau or software delivery service; or</li>

<li>use on behalf of, or make the functionality of the Product available to third parties for any purpose, such as for providing computer repair helpdesk, or troubleshooting service.</li>
</ol>

<p>1.10	You acknowledge and agree that portions of the Product, including but not limited to source code and the specific design and structure of individual modules or programs, constitute or contain trade secrets of Mindnavigator’s related entity and its licensors.Accordingly, you agree not to disassemble, decompile, or reverse engineer the Product, in whole or in part, or permit or authorise a third party to do so, except to the extent such activities are expressly permitted by law notwithstanding this prohibition.  </p>

<p>1.11	You must not add any content to the System:</p>
<ol>
<li>unless it complies with the requirements of the Program you are enrolled in;</li>

<li>that would cause you or Mindnavigator to breach any law, regulation, rule, code or other legal obligation;</li>

<li>that is or could reasonably be considered to be obscene, inappropriate, defamatory, disparaging, indecent, seditious, offensive, pornographic, threatening, abusive, liable to incite racial hatred, discriminatory, blasphemous, in breach of confidence or in breach of privacy;</li>

<li>that would bring Mindnavigator, the Product and / or the Website, into disrepute; or</li>

<li>that infringes the intellectual property rights of any person. </li>
</ol>

<p>1.12	You acknowledge and agree that:</p>

<ol>
<li>Mindnavigator retains complete editorial control over the System and Website and may alter, amend or cease the operation of the System and / or Website at any time in our sole discretion, with the exception that you will be permitted to complete the Modules in which you are enrolled; and</li>

<li>The System and Website will not operate on a continuous basis, and may be unavailable from time to time(including for maintenance purposes). </li>
</ol>
<p>1.13	By posting or adding any content onto the System and / or Website, you grant us a perpetual, royalty - free, irrevocable, worldwide and transferable right and license to use that content in any way(including, without limitation, by reproducing, changing, and communicating the content to the public) and permit us to authorise any other person to do the same thing.</p>

<p>1.14	You consent to any act or omission which would otherwise constitute an infringement of your moral rights, and if you add any content in which any third party has moral rights, you must ensure that the third party also consents in the same manner.</p>

<p>1.15	You represent and warrant to us that you have all necessary rights to grant the licenses set out in clauses 1.13 and 1.14.</p>

<p>1.16	Except as specifically provided under the terms of this License Agreement, you may not use the System, the Services or the Materials retrieved from the System in any fashion that infringes the copyright or intellectual property rights therein.</p>

<p>1.17	You must not remove or obscure the copyright notice or other notices contained in Materials retrieved from the System or the Services.</p>

<p>1.18	At all times during the term of this Agreement, you must:</p>
<ol>
<li>(a)	comply with the requirements of all laws or rules of any kind including Local Laws applying to your use of the Product; and</li>

<li>(b)	comply with Mindnavigator’s reasonable policies and directions, including complying with Mindnavigator’s policies on use of its intellectual property, and any standards, operating principles and procedures that are in effect from time to time which are notified to you.</li>
</ol>

<p>For the purpose of this clause, “Local Laws” means the laws and rules of any state or country in which the Product is supplied to you as the end user.<p>

<p><strong>2	Access</strong></p>

<p>2.1	Only you are entitled to access and use the System, Services and Materials via the Website and Application(“Authorised User”).</p>

<p>2.2	Your identification number will be restricted from accessing certain Services and Materials otherwise available in the Modules for which you have not enrolled.  </p>

<p>2.3	Your identification number and password is for your access to the System, Services and Materials only.If Mindnavigator suspects that your identification number and / or password is being used by an unauthorised user or a different Authorised User to the person to whom it was issued, the identification number and password may be cancelled and you may be liable for additional charges and / or license fees at Mindnavigator’s discretion.</p>

<p>2.4	Materials and features may be added to or withdrawn from the System and / or the Services and these changes may be made without notice to you. </p>

<p>2.5	Subject to clause 1.9, you will be permitted to save your work for the Modules to your personal dashboard on the System for a period of 3 months from the date of completion of your tasks for the Modules in which you are enrolled(“the Saving Period”).If you wish to keep your completed Module tasks for longer than the Saving Period, you must save your work on your own personal data storage device / s.Once the term of this License expires, your access to the Product will be disabled, with the exception of you being permitted access to your completed Module tasks for the Saving Period only.<p>

<p><strong>3	Ownership</strong></p>

<p>3.1	Each copy of the Product is licensed, not sold.For the purposes of this Agreement, the terms “purchase” and “sell” and like terms refers to purchase or sale of a license to use the Product and not to a purchase or sale of title to ownership of any rights or other interests in the Product.You own the media on which the Product is accessed, but you acknowledge that all right title, and interest including all copyright and other Intellectual Property rights in the Product belong to Mindnavigator’s related entity Mindnavigator Pty Ltd(“Mindnavigator’s Intellectual Property”) and is licensed to Mindnavigator.You acquire no ownership or copyright or other Intellectual Property right or proprietary rights in the Product or copies thereof.Intellectual Property includes any copyright, design, trade mark or patent associated in any way with the Product or any rights to register such rights and any Improvements whether created before or after this Agreement.Improvements means all improvements, enhancements, adaptations and extensions to Mindnavigator’s Intellectual Property whether created or suggested by you, Mindnavigator, Mindnavigator Pty Ltd or its agents and all intellectual property associated with such Improvements.</p>

<p>3.2	You shall not:</p>
<ol>
<li>infringe or encourage or permit any breach of the infringement of Mindnavigator’s Intellectual Property;
(b)	challenge the Mindnavigator's rights in or ownership of or right to register, any of Mindnavigator’s Intellectual Property; or</li>
<li>interfere with, apply to revoke, deregister or contest the validity of Mindnavigator’s Intellectual Property in the event that Mindnavigator Pty Ltd applies to register any of Mindnavigator’s Intellectual Property in any jurisdiction.</li>
</ol>

<p><strong>4	Technical Support and Updates</strong></p>

<p>4.1	If you encounter user issues, firstly contact the nominated Mindnavigator administrator in your business.</p>

<p>4.2	Your Mindnavigator administrator should inform <a href="mailto:support@mindnavigator.com">support@mindnavigator.com</a> of any system issues, or call their Account Manager.</p>

<p>4.3	Technical support will be provided by Mindnavigator or its authorized representative in accordance with Mindnavigator’s Terms of Business after a request has been submitted to <a href="mailto:support@mindnavigator.com">support@mindnavigator.com</a> on behalf of the Purchaser or relevant End User.</p>

<p>4.4	From time to time Mindnavigator may, but has no obligation to, provide updates to the Product.You are advised to update the Product regularly, or to set it to update automatically if that feature is available in your version of the Product.If your subscription for your current Modules has been paid in full, Mindnavigator will make available to you at no additional cost the standard updates maintenance and support.Mindnavigator reserves the right to designate any updates, additional content or features requiring separate payment or purchase of a separate Program or Module at any time.Mindnavigator specifically reserves the right to cease providing, updating or maintaining the Product at any time at its sole discretion.</p>

<p><strong>5	Term of License</strong></p>

<p>This License Agreement only operates in respect of the specific Modules of the Program in which you have enrolled and terminates upon the following:</p>
<ol>
<li>12 months after the date of the first login by the Purchaser(or the relevant administrator appointed by the Purchaser) for each specific Module which is licensed according to  the Terms of Business regardless of whether each specific Module has been satisfactorily completed or not by the relevant End User; or</li>

<li>if otherwise terminated in accordance with this License Agreement.</li>
</ol>

<p><strong>6	Warranties and Limitation of Liability</strong></p>

<p>6.1	For the purpose of this clause 6, “Service Provider” means Mindnavigator, our affiliates, and any officer, director, employee, subcontractor, agent, authorised reseller, successor, or assign of us or our affiliates. </p>
<p>6.2	Each party warrants to the other that it has the right and ability to enter into this Agreement and that this Agreement will be legally binding on it.If you are accepting the terms of this Agreement on behalf of a company or other legal entity, you represent and warrant that you have the authority to bind that company or other legal entity.</p>

<p>6.3	You understand and agree that by taking part in this Program, you are fully responsible for your own wellbeing, including your choices and decisions.You warrant that if you encounter any mental health difficulties at any time during the course of the Program, you will seek advice from relevant health professionals.</p>

<p>6.4	You represent and warrant to us that you will comply with clause 1.11.</p>

<p>6.5	MindMavig8or does not warrant that:</p>
<ol>
<li>the Product will be error - free or uninterrupted;</li>
<li>all Product errors will be corrected;</li>
<li>any result or objective can or will be achieved or attained at all or by the completion of the Program by you.</li>
</ol>

<p>6.6	Mindnavigator provides no warranties regarding the following services which are provided by a third party contractor:</p>
<ol>
<li>maintenance and hosting of the System;</li>
<li>security of the System which is provided via cloud technology;</li>
<li>back - up of the System;</li>
<li>provision of full electronic back - up files.</li>
</ol>
<p>6.7	You acknowledge that the Product does not constitute personal advice or executive coaching services.If you wish to obtain additional coaching in person you must purchase client support services through Mindnavigator’s authorized representative or otherwise specifically request and pay for personal coaching services which are available upon request.</p>

<p>6.8	To the maximum extent permitted by law, the Product is provided on an ‘as is’ basis and unless expressly stated to the contrary in this Agreement, Mindnavigator excludes all representations, warranties or guarantees, whether express or implied, by statute, trade or otherwise including without limitation that the Product is or will be complete or free from errors or that information will continue to be available to Mindnavigator to enable us to keep the Product up - to - date. </p>

<p>6.9	To the full extent permitted by law, Mindnavigator excludes all liability in respect of special indirect, incidental or consequential damages of any kind whatsoever, (including, without limitation, legal fees and loss of profits, contracts, business, revenue, goodwill, anticipated savings, business information or data) in any way due to, resulting from, or arising in connection with the goods and services, or the failure of any Service Provider to perform its obligations, regardless of any negligence of any Service Provider, loss of data, interruption of business or any consequential or incidental damages.For the avoidance of doubt, Mindnavigator specifically disclaims and excludes any liability in the following circumstances:</p>
<ol>
<li>you suffering emotional or psychological difficulties; and / or</li>
<li>you deciding to leave your current employment;</li>
</ol>
<p>as a result of your use of the Product.</p>

<p>6.10	To the full extent permitted by law, Mindnavigator excludes all representations, warranties or terms(whether express or implied) other than those expressly set out in this Agreement.  </p>

<p>6.11	Mindnavigator’s total aggregate liability for all claims relating to this Agreement or in tort(including negligence) or for any other common law or statutory cause of action is limited to the license fees payable to Mindnavigator whether through:</p>
<ol>
<li>a direct contract between you and Mindnavigator; or</li>
<li>a contract between a reseller and Mindnavigator;</li>
</ol>
<p>for the license of the Product to you.</p>

<p>6.12	For the avoidance of doubt, MindNavigator’s liability to the Purchaser is limited to the license fees Mindnavigator receives for the affected End Users only, not for the total or a higher number of licenses granted under the  Terms of Business, except in the case where all End Users under the Terms of Business have been affected. </p>

<p>6.13	Either party's liability for any claim relating to this Agreement will be reduced to the extent to which the other party contributed to the damage arising from the claim.</p>

<p>6.14	This Agreement is to be read subject to any legislation which prohibits or restricts the exclusion, restriction or modification of any implied warranties, conditions, guarantees or obligations.If such legislation applies, to the extent possible, Mindnavigator limits its liability in respect of any claim to, at Mindnavigator’s option:</p>
<ol>
<li>in the case of goods, replacement or repair of the goods or payment of the cost of replacing or repairing the goods; and</li>
<li>in the case of services, resupply of the services or payment of the cost of resupplying the services.</li>
</ol>
<p>6.15	To the maximum extent permitted by law a Service Provider shall not be liable for any loss injury, claim, liability or damage of any kind resulting in any way from:</p>
<ol>
<li>any errors or omissions from the System, Services or any Materials available or not included therein;</li>
<li>unavailability or interruption to the supply of the System or Services or any features thereof or any Materials;</li>
<li>your use or misuse of the System, Services or Materials(regardless of whether you received any assistance from a Service Provider in using or misusing the Services);</li>
<li>your use of any equipment in connection with the System or Services;</li>
<li>the content of Materials;</li>
<li>any delay or failure in performance beyond the reasonable control of a Service Provider; or</li>
<li>any negligence of a Service Provider or its employees, contractors or agents in connection with the performance of our obligations under this License Agreement(other than liability for death or personal injury).</li>
</ol>
<p>6.16	For the avoidance of doubt, if any liability is owed under this Agreement by Mindnavigator for any failure, breach or otherwise(“Breach”), there is no right for the Purchaser and End User to make separate claims against Mindnavigator for the same Breach.If an End User wishes to make any claim against Mindnavigator regarding the Product, the claim must be made by the Purchaser only. </p>

<p>6.17	Some jurisdictions do not allow the limitation or exclusion of liability or consequential damages, so the above limitations or exclusions may not apply to you.</p>

<p><strong>7	Termination</strong></p>

<p>7.1	You may terminate this License Agreement at any time by giving at least 28 days’ notice in writing to Mindnavigator. </p>

<p>7.2	Either party may terminate this Agreement by written notice to the other if there is a material breach of this Agreement which is not remediable or if capable of remedy, where the other party fails to remedy within 14 days of written notice. </p>

<p><strong>8	Consequences of Termination</strong></p>

<p>If this Agreement is terminated or expires for any reason, then, in addition and without prejudice to any other rights or remedies available:</p>
<ol>
<li>the parties are immediately released from their obligations under the Agreement except those obligations in clauses 1.13, 1.14, 1.15, 6, 8, 9 and 10 and any other obligations that, by their nature, survive termination;</li>
<li>each party retains the claims it has against the other;</li>
<li>your right to use the Product immediately ceases and the licenses granted under this License Agreement terminate;</li>
<li>Mindnavigator is not obliged to store, maintain, back - up, retrieve or restore any of the Materials or your work for the Modules that has been lost or deleted by you; and</li>
<li>you will not be entitled to a refund of your prepaid fees.</li>
</ol>

<p><strong>9	Confidentiality</strong></p>
<p>9.1	For the purpose of this clause(9), “Confidential Information” means all information provided by the Service Providers in connection with this License and the Product where such information is identified as confidential at the time of its disclosure or ought reasonably be considered confidential based on its content, nature or the manner of its disclosure, but excluding:</p>
<ol>
<li>information that enters the public domain or is disclosed to you by a third party, other than through a breach of this Agreement, and</li>
<li>information developed independently by you.</li>
</ol>

<p>9.2	You will not use, reproduce, or disclose to any third parties the content of this Agreement or the Confidential Information without the prior written consent of Mindnavigator unless expressly permitted by this Agreement or required to do so by law or any regulatory authority.</p>

<p>9.3	You will immediately notify Mindnavigator of any unauthorised disclosure or use of the Confidential Information of which you become aware and will take all steps which Mindnavigator may reasonably require in relation to such unauthorised disclosure or use.</p>

<p><strong>10	Feedback</strong></p>

<p>10.1	If you provide any ideas, suggestions or recommendations regarding the Product or parts thereof(“Feedback”), Mindnavigator will be free to use, disclose, reproduce, license or otherwise distribute, and exploit such Feedback as it sees fit, entirely without obligation or restriction of any kind.</p>
<p>10.2	Any Improvements to Mindnavigator’s Intellectual Property made by Mindnavigator, Mindnavigator Pty Ltd or their agents as a result of Feedback will be owned by Mindnavigator Pty Ltd.  </p>

<p>10.3	By providing feedback, you grant Mindnavigator a worldwide, perpetual, irrevocable, sublicenseable, fully - paid and royalty free license to use and exploit in any manner such Feedback.If you are using Mindnavigator Product in a business or for business purposes, you grant Mindnavigator the right to use our trade name(and the corresponding trade mark or logo) on the Mindnavigator website and marketing materials to identify you as a customer. </p>

<p><strong>11	Notices</strong></p>
<p>All notices or approvals required or permitted under this License Agreement will be in writing and delivered by email(we will email you at the email address you or the Purchaser provided to us when you or the Purchaser purchased your license) and in each instance will be deemed given upon receipt.</p>

<p><strong>12	Privacy Policy</strong></p>
<p>By entering into this Agreement you agree to the terms of Mindnavigator’s privacy policy which is accessible here <a href="https://www.mindnavigator.com/privacy_policy/" target="_blank">Privacy Policy</a></p>

<p><strong>13	General</strong></p>
<p>13.1	You must not assign, sublicense or otherwise deal in any other way with any of its rights under this Agreement without the prior written consent of Mindnavigator.  </p>

<p>13.2	Nothing contained in this Agreement creates any relationship of partnership or agency between the parties. </p>

<p>13.3	If a provision of this Agreement is invalid or unenforceable it is to be read down or severed to the extent necessary without affecting the validity or enforceability of the remaining provisions.</p>

<p>13.4	Each party must at its own expense do everything reasonably necessary to give full effect to this Agreement and the events contemplated by it.</p>

<p>13.5	This Agreement is the complete and exclusive understanding and agreement between the parties regarding its subject matter, and supersedes all other representations, proposals, understandings, arrangements or communications between the parties, oral or written, regarding its subject matter, unless you and Mindnavigator have executed a separate agreement.Other than as expressly set out in this Agreement, no party has relied on any representation made by or on behalf of the other.</p>

<p>13.6	This Agreement may be amended only by a document signed by the parties.</p>

<p>13.7	A provision of or a right under this Agreement may not be waived or varied except in writing signed by the person to be bound.</p>

<p>13.8	A party will not be responsible for a failure to comply with its obligations under this Agreement to the extent that failure is caused by a Force Majeure Event, provided that the party keeps the other closely informed in such circumstances and uses reasonable endeavours to rectify the situation.  “Force Majeure Event” means any event beyond the control of the relevant party.</p>

<p>13.9	Without limiting any other right to terminate under this Agreement, if a Force Majeure Event affects a party's performance under this Agreement for more than thirty (30) consecutive days, the other party may immediately terminate this Agreement by written notice.</p>

<p>13.10	Regardless of the place of performance or otherwise, this Agreement is governed by the laws of the State of New South Wales and the Commonwealth of Australia and each party submits to the exclusive jurisdiction of courts exercising jurisdiction in New South Wales and the Commonwealth of Australia in connection with matters concerning this Agreement.</p>

<p><strong>14	Contact Us</strong></p>
<p>If you have any questions regarding this Agreement, you may contact Mindnavigator at <a href="mailto:info@mindnavigator.com">info@mindnavigator.com</a>.If you wish to send us a legal notice, please start the subject line of your email with “Legal Notice”.</p>

<p>Click Accept to confirm you have read and agree to the MidnNavigator End User License Agreement and Privacy Policy.</p>
`;

export default EULA;