import React, { useEffect, useState, useContext } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { TableColumn } from "react-data-table-component";
import { MultiSelect } from "react-multi-select-component";
import Lookup from "../../../Services/Lookup.service";
import Reporting from "../../../Services/Reporting.service";
import constant from "../../../Utils/Config";
import { createNotification } from "../../../Utils/Toast";
import TableComponent from "../../FunctionalComponents/Table/TableComponent";
import { logOutIfTokenExpire } from '../../../Utils/Utils';
import { IPagination } from '../../../interfaces/selectionPreference/ISelectionPreference';
import { IRadarChart, IRadarChartDataset } from "../../../interfaces/Charts/IBarChart";
import { chartType } from "../../../Utils/Enums";
import ChartComponent from "../../FunctionalComponents/Chart/ChartComponent";
import { ThemeContext } from "../../../Contexts/ThemeContext";
import "../../../darkcustom.css"
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import { IUserScoreReportItem, IUserScoreReportResponse } from "../../../interfaces/Reports/IUserScoreReport";

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);


interface ImeetingReportingTable {
    programMeetingName: string;
    firstName: string;
    lastName: string;
    email: string;
    groupName: string;
    module: string;
    meetingDateTime: string;
    attendanceStatus: string;
    attendanceStatusDescription: string;
}

interface ImoduleProgressReportingTable {
    doStatus: string,
    email: string,
    engageStatus: string,
    enrolledDate: string,
    firstAccess: string,
    id: string,
    name: string,
    profileImg: string,
    thinkStatus: string
}

interface IindividualModuleProgressReport {
    doCompletionDate: string | null,
    engageCompletionDate: string | null,
    id: string,
    moduleTitle: string,
    name: string,
    thinkCompletionDate: string | null
}

interface IuserScoreReport {
    doScore: string,
    engageScore: string,
    groupName: string,
    module: string,
    participant: string,
    scorer1: {
        email: string,
        isEmailSent: boolean,
        name: string,
        score: string,
    },
    scorer2: {
        email: string,
        isEmailSent: boolean,
        name: string,
        score: string,
    },
    scorer3: {
        email: string,
        isEmailSent: boolean,
        name: string,
        score: string
    },
}

interface IprogramSurveyReport {
    surveyName: string,
    date: string,
    userResponse: any[]
}

interface ISelectionPreferenceReport {
    id: string,
    name: string,
    preference: any[]
}

const ReportingComponent = () => {
    const lookup = new Lookup();
    const report = new Reporting();
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [showExportSpinner, setExportShowSpinner] = useState<boolean>(false);
    const [selectedReportType, setSelectedReportType] = useState<any[]>([]);
    const [showOtherFilters, setShowOtherFilters] = useState<boolean>(false);
    const [groups, setGroups] = useState<any>();
    const [programList, setProgramList] = useState<any>();
    const [moduleList, setModuleList] = useState<any>();
    const [userProgramStages, setUserProgramStages] = useState<any[]>([]);
    const [userListData, setUserListData] = useState<any[]>([]);
    const [programSurveyListData, setProgramSurveyListData] = useState<any[]>([]);
    const [updateRecord, setUpdateRecord] = useState<boolean>(false)
    const [selectedMultiGroup, setSelectedMultiGroup] = useState<any[]>([]);
    const [selectedMultiUser, setSelectedMultiUser] = useState<any[]>([]);
    const [selectedProgram, setSelectedProgram] = useState<number>(0);
    const [selectedModule, setSelectedModule] = useState<number>(0);
    const [selectedUser, setSelectedUser] = useState<string>("");
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [selectedExportType, setSelectedExportType] = useState<string>("Excel");
    const [selectedProgramStage, setSelectedProgramStage] = useState<number>(0);
    const [selectedSurvey, setSelectedSurvey] = useState<number>(0)
    const [paginationDefaultPage, setPaginationDefaultPage] = useState<boolean>(false);
    const [attendanceReport, setAttendanceReport] = useState<any[]>([]);
    const [moduleProgressReport, setModuleProgressReport] = useState<any[]>([]);
    const [individualModuleProgressReport, setIndividualModuleProgressReport] = useState<any[]>([]);
    const [userScoreReport, setUserScoreReport] = useState<IUserScoreReportItem[]>([]);
    const [programSurveyData, setProgramSurveyData] = useState<any[]>([]);
    const [selectionPreferenceData, setSelectionPreferenceData] = useState<any[]>([]);
    const [isGenerateButtonEnable, setIsGenerateButtonEnable] = useState<boolean>(true);
    const [isexportButtonEnable, setIsExportButtonEnable] = useState<boolean>(true);
    const [isAllFieldSelected, setIsAllFieldSelected] = useState<any>({
        programName: false,
        groupName: false,
        moduleName: false,
        user: false,
        programStage: false
    });
    const [pagination, setPagination] = useState<IPagination>({
        currentPage: 1,
        pageSize: 10,
        totalPages: 0,
        totalItems: 0
    });
    const [coreValueChartData, setCoreValueChartData] = useState<IRadarChart>();
    const [chartSpinner, setChartSpinner] = useState<boolean>(false);
    const { theme } = useContext(ThemeContext)

    useEffect(() => {
        logOutIfTokenExpire();
        getGroups();
        programsListOptions();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (selectedProgram === 0) {
            setModuleList([]);
            setUserProgramStages([]);
            return;
        } else {
            programModule();
            programStages();
        }
        //eslint-disable-next-line
    }, [selectedProgram]);

    useEffect(() => {
        if (selectedReportType[0]?.name === constant?.reportTypes[1]?.name ||
            selectedReportType[0]?.name === constant?.reportTypes[0]?.name ||
            selectedReportType[0]?.name === constant?.reportTypes[3]?.name ||
            selectedReportType[0]?.name === constant?.reportTypes[6]?.name ||
            selectedReportType[0]?.name === constant?.reportTypes[4]?.name) {
            userList();
        }
        if (selectedReportType[0]?.name === constant?.reportTypes[4]?.name) {
            programSurveyList();
        }
        //eslint-disable-next-line
    }, [selectedMultiGroup, selectedReportType]);

    useEffect(() => {
        if (searchTerm.length !== 0) {
            getReportAndResetPagination(selectedReportType[0]?.name, true)
        }
        //eslint-disable-next-line
    }, [searchTerm]);

    useEffect(() => {
        validateGenerateButton()
        //eslint-disable-next-line
    }, [isAllFieldSelected, selectedMultiGroup]);

    useEffect(() => {
        setIndividualModuleProgressReport([]);
        //eslint-disable-next-line
    }, [selectedUser]);

    useEffect(() => {
        scrollToBottom();
        //eslint-disable-next-line
    }, [attendanceReport, moduleProgressReport, individualModuleProgressReport, userScoreReport, programSurveyData]);


    useEffect(() => {
        if (updateRecord) {
            getReportAndResetPagination(selectedReportType[0]?.name, false);
        }
        //eslint-disable-next-line
    }, [pagination.currentPage, pagination.pageSize]);

    const scrollToBottom = () => {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: "smooth"
        });
    };

    const validateGenerateButton = () => {
        if (selectedReportType[0]?.name === constant?.reportTypes[0].name) {
            setIsGenerateButtonEnable(false);
            return true;
        }
        if (selectedReportType[0]?.name === constant?.reportTypes[1].name) {
            if (isAllFieldSelected.programName && isAllFieldSelected.user && isAllFieldSelected.programStage) {
                setIsGenerateButtonEnable(false)
                return true;
            } else {
                return false;
            }
        }
        if (selectedReportType[0]?.name === constant?.reportTypes[2]?.name) {
            if (isAllFieldSelected.moduleName && selectedModule > 0 && selectedProgram > 0) {
                setIsGenerateButtonEnable(false);
                setIsExportButtonEnable(false);
                return true;
            }
            else {
                setIsGenerateButtonEnable(true);
                setIsExportButtonEnable(true);
                setModuleProgressReport([]);
                return false;
            }
        }
        if (selectedReportType[0]?.name === constant?.reportTypes[3]?.name) {
            setIsGenerateButtonEnable(false);
            return true;
        }
        if (selectedReportType[0]?.name === constant?.reportTypes[4]?.name) {
            setIsGenerateButtonEnable(false);
            return true;
        }
        if (selectedReportType[0]?.name === constant?.reportTypes[5]?.name) {
            if (selectedMultiGroup.length > 0) {
                setIsGenerateButtonEnable(false);
                setIsExportButtonEnable(false);
                return true;
            }
            else {
                setIsGenerateButtonEnable(true);
                setIsExportButtonEnable(true);
                return false;
            }
        }
        if (selectedReportType[0]?.name === constant?.reportTypes[6]?.name) {
            setIsGenerateButtonEnable(false);
            return true;
        }
    }

    //columns for meeting attandance report
    const attendanceReportColumn: TableColumn<ImeetingReportingTable>[] = [
        {
            name: 'Name',
            selector: row => `${row.firstName} ${row.lastName}`,
            sortable: true,
            minWidth: "100px",
            maxWidth: "100%",
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            minWidth: "250px",
            maxWidth: "100%",
        },
        {
            name: 'Group Name',
            selector: row => row.groupName,
            minWidth: "100px",
            maxWidth: "100%",
        },
        {
            name: 'Meeting Name',
            selector: row => row.programMeetingName,
            minWidth: "200px",
            maxWidth: "100%",
        },
        {
            name: 'Meeting Date',
            selector: row => row.meetingDateTime,
            minWidth: "50px",
            maxWidth: "100%",
        },
        {
            name: 'Attendance Status',
            selector: row => row.attendanceStatusDescription,
            minWidth: "50px",
            maxWidth: "100%",
        },
    ];

    //columns for individual module progress report
    const individualModuleProgressReportColumn: TableColumn<IindividualModuleProgressReport>[] = [
        {
            name: 'Name',
            selector: row => `${row.name}`,
        },
        {
            name: 'Module',
            selector: row => `${row.moduleTitle}`,
        },
        {
            name: 'Think - Completed on',
            selector: row => `${row.thinkCompletionDate}`,
        },
        {
            name: 'Engage - Completed on',
            selector: row => `${row.engageCompletionDate}`,
        },
        {
            name: 'Do - Completed on',
            selector: row => `${row.doCompletionDate}`,
        }
    ];

    //columns for module progress report
    const moduleProgressReportColumn: TableColumn<ImoduleProgressReportingTable>[] = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            minWidth: "150px",
            maxWidth: "100%"
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            minWidth: "250px",
            maxWidth: "100%"
        },
        {
            name: 'Enroll Date',
            selector: row => row.enrolledDate,
            sortable: true,
            minWidth: "20px",
            maxWidth: "100%"
        },
        {
            name: 'First Access Date',
            selector: row => row.firstAccess,
            sortable: true,
            minWidth: "200px",
            maxWidth: "100%"
        },
        {
            name: 'Think Status',
            selector: row => row.thinkStatus,
            minWidth: "20px",
            maxWidth: "100%"
        },
        {
            name: 'Engage Status',
            selector: row => row.engageStatus,
            minWidth: "20px",
            maxWidth: "100%"
        },
        {
            name: 'Do Status',
            selector: row => row.doStatus,
            minWidth: "20px",
            maxWidth: "100%"
        },
    ];

    //columns for user score report
    const userScoreReportColumn: TableColumn<IUserScoreReportItem>[] = [
        {
            name: 'Name',
            selector: row => row.participant,
            sortable: true,
        },
        {
            name: 'Module',
            cell: (row) => row.module,
            sortable: true,
        },
        {
            name: 'Engage Score',
            selector: row => row.engageScore,
            sortable: true,
        },
        {
            name: 'Do Score',
            selector: row => row.doScore,
            sortable: true,
        }
    ];

    //columns for survey report
    const surveyReportColumn: TableColumn<IprogramSurveyReport>[] = [
        {
            name: 'Survey Name',
            selector: row => row.surveyName,
            sortable: true,
        },
        {
            name: 'Date',
            selector: row => row.date,
            sortable: true,
        },
    ];

    //colums for selection preference report
    const selectionPreferenceReportColumn: TableColumn<ISelectionPreferenceReport>[] = [
        {
            id: 'id',
            selector: row => row.id,
            sortable: false,
            omit: true
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Action level',
            selector: row => row.preference.find(x => x.name === 'Action level')?.value,
            sortable: false
        },
        {
            name: 'Action Direction',
            selector: row => row.preference.find(x => x.name === 'Action Direction')?.value,
            sortable: false
        },
        {
            name: 'Adaptive style',
            selector: row => row.preference.find(x => x.name === 'Adaptive style')?.value,
            sortable: false
        },
        {
            name: 'Approach to change',
            selector: row => row.preference.find(x => x.name === 'Approach to change')?.value,
            sortable: false
        },
        {
            name: 'Data categorisations',
            selector: row => row.preference.find(x => x.name === 'Data categorisations')?.value,
            sortable: false
        },
        {
            name: 'Data scope and size',
            selector: row => row.preference.find(x => x.name === 'Data scope and size')?.value,
            sortable: false
        },
        {
            name: 'Operational Approach',
            selector: row => row.preference.find(x => x.name === 'Operational Approach')?.value,
            sortable: false
        },
        {
            name: 'Scenario response',
            selector: row => row.preference.find(x => x.name === 'Scenario response')?.value,
            sortable: false
        },
        {
            name: 'Source of Authority',
            selector: row => row.preference.find(x => x.name === 'Source of Authority')?.value,
            sortable: false
        },
        {
            name: 'Work style',
            selector: row => row.preference.find(x => x.name === 'Work style')?.value,
            sortable: false
        },

    ]

    const handleReportType = (value: string) => {
        value === "0" ? setShowOtherFilters(false) : setShowOtherFilters(true)
        let tempselectedReportType = constant?.reportTypes?.filter((data: any) => data.id === Number(value));
        setSelectedReportType(tempselectedReportType);
        setAttendanceReport([]);
        setModuleProgressReport([]);
        setIndividualModuleProgressReport([]);
        setUserScoreReport([]);
        setSelectedMultiUser([]);
        setSelectionPreferenceData([]);
        setSearchTerm("");
        setIsAllFieldSelected({
            programName: false,
            groupName: false,
            moduleName: false,
            user: false,
            programStage: false
        });
        if (selectedProgram > 0) {
            setIsAllFieldSelected((states: any) => {
                return {
                    ...states,
                    programName: true
                }
            });
        }
        if (selectedExportType !== "") {
            setIsExportButtonEnable(false);
        }
        else {
            setIsExportButtonEnable(true);
        }
        setIsGenerateButtonEnable(true);
        setPagination({
            currentPage: 1,
            pageSize: 10,
            totalPages: 0,
            totalItems: 0
        });
        setCoreValueChartData({ labels: [], datasets: [] });
        setProgramSurveyData([]);
    }

    const getGroups = async () => {
        let groupResult: any;
        groupResult = await lookup.getGroups();
        let tempGroupData: any = groupResult.map((data: any) => {
            return {
                label: data.name,
                value: data.id
            }
        });
        setGroups(tempGroupData);
    };

    const programsListOptions = async () => {
        let result: any = await lookup.getProgramsList();
        if (result.status) {
            let programLists: any[] = result.data.map((value: any) => {
                return {
                    id: value.id,
                    label: value.name
                }
            })
            setProgramList(programLists);
        }
    };

    const programModule = async () => {
        let result: any = await lookup.getProgramModule(Number(selectedProgram));
        if (result.status) {
            let moduleLists: any[] = result.data.map((value: any) => {
                return {
                    id: value.id,
                    label: value.name
                }
            })
            setModuleList(moduleLists);
        }
        else {
            setModuleList([]);
        }
    };

    const userList = async () => {
        let groupIds: any = [];
        selectedMultiGroup.map((data: any) => groupIds.push(data.value));
        let response: any = await lookup.getUserList(groupIds.toString());
        if (response?.status) {
            let users: any[] = response.data.map((data: any) => {
                return {
                    value: data.guid,
                    label: data.name
                }
            });
            setUserListData(users);
            if (selectedMultiUser.length > 0) {
                let intersection = selectedMultiUser.filter((x: any) => {
                    let arr = users.filter(obj => obj.value == x.value);
                    return !(arr.length === 0)
                });
                setSelectedMultiUser(intersection);
            }
        }
    }


    const programStages = async () => {
        let response: any = await lookup.getProgramStages(selectedProgram);
        if (response.status) {
            let programStages: any = response.data.map((data: any) => {
                return {
                    id: data.id,
                    name: data.name
                }
            });
            setUserProgramStages(programStages);
        }
    }

    const programSurveyList = async () => {
        let response: any = await lookup.getProgramSurveyList();
        if (response?.status) {
            let programSurveyList: any = response.data.map((data: any) => {
                return {
                    id: data.id,
                    name: data.name
                }
            });
            setProgramSurveyListData(programSurveyList);
        }
    }

    const handlePageChange = (e: any): void => {
        setUpdateRecord(true)
        setPagination((states: any) => {
            return {
                ...states,
                currentPage: e,
            }
        });
    };

    const handleRowsPerPage = (e: any): void => {
        setUpdateRecord(true)
        setPagination((states: any) => {
            return {
                ...states,
                pageSize: e,
            }
        });
    };

    const handleSelectedReportType = (value: string) => {
        if (value === "") {
            setSelectedExportType("");
            setIsExportButtonEnable(true)

        } else {
            setSelectedExportType(value);
            setIsExportButtonEnable(false);
        }
    }

    const getMeetingAttendence = async () => {
        setShowSpinner(true);
        let groupIds: any = [];
        selectedMultiGroup.map((data: any) => groupIds.push(data.value));

        let participantIds: any = [];
        selectedMultiUser.map((data: any) => participantIds.push(data.value));

        let response: any = await report.getMeetingAttendence(pagination.currentPage, pagination.pageSize, groupIds.toString(), selectedProgram, selectedModule, participantIds);
        if (response.status) {
            if (response.data.items.length > 0) {
                setUpdateRecord(false);
                setPagination({
                    currentPage: response.data.pagination.currentPage,
                    pageSize: response.data.pagination.pageSize,
                    totalItems: response.data.pagination.totalItems,
                    totalPages: response.data.pagination.totalPages,
                });
                let attendanceReport: any = response.data.items.map((data: any) => {
                    return {
                        attendanceStatus: data.attendanceStatus,
                        attendanceStatusDescription: data.attendanceStatusDescription,
                        email: data.email,
                        firstName: data.firstName,
                        groupName: data.groupName,
                        lastName: data.lastName,
                        meetingDateTime: new Date(data.meetingDateTime).toLocaleDateString('en-GB'),
                        module: data.module,
                        programMeetingName: data.programMeetingName,
                    }
                });
                setAttendanceReport(attendanceReport);
            } else {
                setAttendanceReport([]);
                createNotification("error", "No data found!");
            }
        } else {
            setAttendanceReport([]);
            createNotification("error", "No data found!");
        }
        setPaginationDefaultPage(false);
        setShowSpinner(false);
    }

    const getIndividualModuleProgress = async () => {
        setShowSpinner(true);
        let response: any = await report.getIndividualModuleProgress(selectedUser, selectedProgramStage);
        if (response.status) {
            if (response.data.length > 0) {
                let individualModuleData: any = response.data.map((data: any) => {
                    return {
                        doCompletionDate: data.doCompletionDate === null ? "No Data Available" : new Date(data.doCompletionDate).toLocaleDateString('en-GB'),
                        engageCompletionDate: data.engageCompletionDate === null ? "No Data Available" : new Date(data?.engageCompletionDate).toLocaleDateString('en-GB'),
                        id: data.id,
                        moduleTitle: data.moduleTitle,
                        name: data.name,
                        thinkCompletionDate: data?.thinkCompletionDate === null ? "No Data Available" : new Date(data.thinkCompletionDate).toLocaleDateString('en-GB')
                    }
                });
                setIndividualModuleProgressReport(individualModuleData);
            } else {
                setIndividualModuleProgressReport([]);
                createNotification("error", "No data found!");
            }
        } else {
            setIndividualModuleProgressReport([]);
            createNotification("error", "No data found!");
        }
        setShowSpinner(false);
    }

    const getModuleProgressReport = async () => {
        setShowSpinner(true);
        let groupIds: any = [];
        selectedMultiGroup.map((data: any) => groupIds.push(data.value));
        let response: any = await report.getModuleProgress(pagination.currentPage, pagination.pageSize, searchTerm, selectedModule, groupIds.toString());
        if (response.status) {
            if (response.data.items.length > 0) {
                setUpdateRecord(false);
                setPagination({
                    currentPage: response.data.pagination.currentPage,
                    pageSize: response.data.pagination.pageSize,
                    totalItems: response.data.pagination.totalItems,
                    totalPages: response.data.pagination.totalPages,
                });
                let moduleProgressReportData = response.data.items.map((data: any) => {
                    return {
                        doStatus: data.doStatus,
                        email: data.email,
                        engageStatus: data.engageStatus,
                        enrolledDate: new Date(data.enrolledDate).toLocaleDateString('en-GB') == "01/01/1" ? "" : new Date(data.enrolledDate).toLocaleDateString('en-GB'),
                        firstAccess: new Date(data.firstAccess).toLocaleString('en-GB') == "01/01/1, 00:00:00" ? "" : new Date(data.firstAccess).toLocaleString('en-GB'),
                        id: data.id,
                        name: data.name,
                        profileImg: data.profileImg,
                        thinkStatus: data.thinkStatus
                    }
                });
                setModuleProgressReport(moduleProgressReportData);
            } else {
                setModuleProgressReport([]);
                createNotification("error", "No data found!");
            }
        } else {
            setModuleProgressReport([]);
            createNotification("error", "No data found!");
        }
        setPaginationDefaultPage(false);
        setShowSpinner(false);
    }

    const getUserScoreReport = async () => {
        setShowSpinner(true);
        let groupIds: any = [];
        selectedMultiGroup.map((data: any) => groupIds.push(data.value));
        let participantIds: any = [];
        selectedMultiUser.map((data: any) => participantIds.push(data.value));
        let response = await report.getUserScoreReport(pagination.currentPage, pagination.pageSize, groupIds.toString(), selectedProgram, selectedModule, participantIds) as IUserScoreReportResponse;
        if (response.status) {
            setUpdateRecord(false);
            setPagination({
                currentPage: response.data.pagination.currentPage,
                pageSize: response.data.pagination.pageSize,
                totalItems: response.data.pagination.totalItems,
                totalPages: response.data.pagination.totalPages,
            });
            if (response.data.items.length > 0) {
                setUserScoreReport(response.data.items);
            } else {
                setUserScoreReport([]);
                createNotification("error", "No data found!");
            }
        } else {
            setUserScoreReport([]);
            createNotification("error", "No data found!");
        }
        setPaginationDefaultPage(false);
        setShowSpinner(false);
    }

    const getSurveyCompletionReport = async () => {
        setShowSpinner(true);
        let participantIds: any = [];
        selectedMultiUser.map((data: any) => participantIds.push(data.value));
        let response: any = await report.getSurveyCompletion(pagination.currentPage, pagination.pageSize, selectedSurvey, selectedProgram, participantIds);
        if (response.status) {
            setUpdateRecord(false);
            setPagination({
                currentPage: response.data.pagination.currentPage,
                pageSize: response.data.pagination.pageSize,
                totalItems: response.data.pagination.totalItems,
                totalPages: response.data.pagination.totalPages,
            });
            if (response.data.items.length > 0) {
                let programSurveyData = response.data.items.map((data: any) => {
                    return {
                        date: new Date(data.openDate).toLocaleDateString('en-GB'),
                        surveyName: data.surveyName,
                        userResponses: [...data.userResponses]
                    }
                });
                setProgramSurveyData(programSurveyData);
            } else {
                setProgramSurveyData([]);
                createNotification("error", "No data found!");
            }
        } else {
            setProgramSurveyData([]);
            createNotification("error", "No data found!");
        }
        setPaginationDefaultPage(false);
        setShowSpinner(false);
    }

    const getSelectionPreferenceReport = async () => {
        setShowSpinner(true);
        if (selectedMultiGroup.length > 0) {
            let groupIds: any = [];
            selectedMultiGroup.map((data: any) => groupIds.push(data.value));
            let response = await report.getSelectionPreferenceReport(groupIds.toString(), pagination.currentPage, pagination.pageSize);
            if (response !== undefined) {
                if (response.status) {
                    if (response.data !== null) {
                        setUpdateRecord(false);
                        setPagination(response.data.userPreferences.pagination);
                        if (response.data.preferenceTitles.length > 0 && response.data.userPreferences.items.length > 0) {
                            let data = response.data.userPreferences.items.map(item => {
                                return {
                                    id: item.id,
                                    name: item.name,
                                    preference: item.preferences
                                }
                            })
                            setSelectionPreferenceData(data);
                        }
                    }
                    else {
                        createNotification("error", "No data found!");
                    }
                }
                else {
                    createNotification("error", "No data found!");
                }
            }
        }
        else {
            createNotification("warning", "Please select group");
        }
        setPaginationDefaultPage(false);
        setShowSpinner(false);
    }

    const generateReport = (reportName: string) => {
        getReportAndResetPagination(reportName, true);
        if (selectedExportType !== "") {
            setIsExportButtonEnable(false);
        }
        else {
            setIsExportButtonEnable(true);
        }
    }

    const exportReport = async () => {
        if (!isexportButtonEnable) {
            setExportShowSpinner(true);
            if (selectedReportType[0]?.name === constant.reportTypes[0]?.name) {

                let participantIds: any = [];
                selectedMultiUser.map((data: any) => participantIds.push(data.value));

                let groupIds: any = [];
                selectedMultiGroup.map((data: any) => groupIds.push(data.value));
                await report.getExportMeeting(groupIds.toString(), selectedProgram, selectedModule, participantIds, selectedExportType);
            }
            if (selectedReportType[0]?.name === constant.reportTypes[1]?.name) {
                await report.getExportIndividualModuleProgress(selectedUser, selectedProgramStage, selectedExportType);
            }
            if (selectedReportType[0]?.name === constant.reportTypes[2]?.name) {
                let groupIds: any = [];
                selectedMultiGroup.map((data: any) => groupIds.push(data.value));
                await report.getExportModuleProgress(selectedModule, groupIds.toString(), searchTerm, selectedExportType);
            }
            if (selectedReportType[0]?.name === constant.reportTypes[3]?.name) {
                let groupIds: any = [];
                selectedMultiGroup.map((data: any) => groupIds.push(data.value));
                let participantIds: any = [];
                selectedMultiUser.map((data: any) => participantIds.push(data.value));
                await report.getExportUserScore(groupIds.toString(), selectedModule, participantIds, selectedExportType);
            }
            if (selectedReportType[0]?.name === constant.reportTypes[4]?.name) {
                let participantIds: any = [];
                selectedMultiUser.map((data: any) => participantIds.push(data.value));
                await report.getExportProgramSurvey(selectedSurvey, selectedProgram, participantIds, selectedExportType);
            }
            if (selectedReportType[0]?.name === constant.reportTypes[5]?.name) {
                let groupIds: any = [];
                selectedMultiGroup.map((data: any) => groupIds.push(data.value));
                await report.getExportSelectionPreferenceReport(groupIds, selectedExportType);
            }
            setExportShowSpinner(false);
        }
    }

    const getReportAndResetPagination = (reportName: string, resetPage: boolean) => {
        if (resetPage) {
            setPaginationDefaultPage(true)
        }
        switch (reportName) {
            case constant?.reportTypes[0].name: {
                if (pagination.currentPage > 1 && resetPage) {
                    handlePageChange(1);
                }
                else {
                    getMeetingAttendence();
                }
            }
                break;
            case constant?.reportTypes[1].name: {
                if (selectedUser.length === 0 && selectedProgram === 0) {
                } else {
                    getIndividualModuleProgress();
                }
            }
                break;
            case constant?.reportTypes[2].name: {
                if (pagination.currentPage > 1 && resetPage) {
                    handlePageChange(1);
                }
                else {
                    getModuleProgressReport();
                }
            }
                break;
            case constant?.reportTypes[3].name: {
                if (pagination.currentPage > 1 && resetPage) {
                    handlePageChange(1);
                }
                else {
                    getUserScoreReport();
                }
            }
                break;
            case constant?.reportTypes[4].name: {
                getSurveyCompletionReport();
            }
                break;
            case constant?.reportTypes[5].name: {
                if (pagination.currentPage > 1 && resetPage) {
                    handlePageChange(1);
                }
                else {
                    getSelectionPreferenceReport();
                }
            }
                break;
            case constant?.reportTypes[6].name: {
                getCoreValueChartData();
            }
        }
    }

    const getCoreValueChartData = async () => {
        setChartSpinner(true);
        let groupIds: any = [];
        selectedMultiGroup.map((data: any) => groupIds.push(data.value));
        let participantIds: any = [];
        selectedMultiUser.map((data: any) => participantIds.push(data.value));
        let response = await report.getCoreValueReport(groupIds.toString(), participantIds.toString());
        if (response) {
            if (response.status && response.data) {
                let chartData: IRadarChart = {
                    labels: response.data.labels ? response.data.labels.map(x => x) : [],
                    datasets: response.data.datasets ? response.data.datasets.map((item, i) => {
                        let obj: IRadarChartDataset = {
                            label: item.label,
                            data: item.data.map(x => x),
                            backgroundColor: 'rgba(20, 205, 200, 0.2)',
                            borderColor: 'rgba(20, 205, 200, 1)',
                            borderWidth: 1
                        }
                        return obj;
                    }) : [{ label: '', data: [], backgroundColor: 'rgba(20, 205, 200, 0.2)', borderColor: 'rgba(20, 205, 200, 1)', borderWidth: 1 }]
                }
                setCoreValueChartData(chartData);
            }
            else {
                setCoreValueChartData({ labels: [], datasets: [] });
            }
        }
        setChartSpinner(false);
    }

    const programChartOption = {
        responsive: true,
        plugins: {
            legend: {
                position: "top" as const
            },
            title: {
                display: true,
                text: "Core values"
            }
        },
        aspectRatio: 1,
        maintainAspectRatio: true,
    };

    return (
        <div>
            <div className="my-2">
                <section>
                    {theme === "light" ? (
                        <form id="reporting" autoComplete="off">
                            <Form.Group className="mb-3 col-6">
                                <Form.Label>Generate report for</Form.Label>
                                <Form.Select onChange={(e: any) => handleReportType(e.target.value)}>
                                    <option value={"0"}>{"Select Report Type"}</option>
                                    {constant.reportTypes.map((data: any) => <option key={data.id} value={data.id}>{data.name}</option>)}
                                </Form.Select>
                            </Form.Group>

                            {
                                showOtherFilters &&
                                <>
                                    {
                                        (selectedReportType[0]?.name !== constant.reportTypes[5]?.name &&
                                            selectedReportType[0]?.name !== constant.reportTypes[6]?.name) && <Form.Group className="mb-3 col-6">
                                            <Form.Label>Program Name</Form.Label>
                                            <Form.Select onChange={(e: any) => {
                                                setSelectedProgram(Number(e.target.value));
                                                if (e.target.value <= 0) {
                                                    setSelectedModule(0);
                                                }
                                                setIsAllFieldSelected((states: any) => {
                                                    return {
                                                        ...states,
                                                        programName: true
                                                    }
                                                });
                                            }}>
                                                <option value={"0"}>{"Select Program"}</option>
                                                {programList?.map((data: any) => <option key={data.id} value={data.id}>{data.label}</option>)}
                                            </Form.Select>
                                        </Form.Group>
                                    }

                                    {
                                        (selectedReportType[0]?.name === constant.reportTypes[1]?.name &&
                                            selectedReportType[0]?.name !== constant.reportTypes[6]?.name) &&
                                        <Form.Group className="mb-3 col-6">
                                            <Form.Label>Program Stage</Form.Label>
                                            <Form.Select onChange={(e: any) => {
                                                setSelectedProgramStage(e.target.value);
                                                if (e.target.value <= 0) {
                                                    setSelectedProgram(0);
                                                    setSelectedModule(0);
                                                }
                                                setIsAllFieldSelected((states: any) => {
                                                    return {
                                                        ...states,
                                                        programStage: true
                                                    }
                                                });
                                            }}>
                                                <option value={"0"}>{"Select Program Stage"}</option>
                                                {userProgramStages?.map((data: any) => <option key={data.id} value={data.id}>{data.name}</option>)}
                                            </Form.Select>
                                        </Form.Group>
                                    }

                                    {
                                        (selectedReportType[0]?.name !== constant.reportTypes[1]?.name && selectedReportType[0]?.name !== constant.reportTypes[4]?.name
                                            && selectedReportType[0]?.name !== constant.reportTypes[5]?.name &&
                                            selectedReportType[0]?.name !== constant.reportTypes[6]?.name) &&
                                        <Form.Group className="mb-3 col-6">
                                            <Form.Label>Module Name</Form.Label>
                                            <Form.Select onChange={(e: any) => {
                                                setSelectedModule(Number(e.target.value));
                                                setIsAllFieldSelected((states: any) => {
                                                    return {
                                                        ...states,
                                                        moduleName: true
                                                    }
                                                });
                                            }}>
                                                <option value={"0"}>{"Select Module"}</option>
                                                {moduleList?.map((data: any) => <option key={data.id} value={data.id}>{data.label}</option>)}
                                            </Form.Select>
                                        </Form.Group>
                                    }

                                    {
                                        (selectedReportType[0]?.name === constant.reportTypes[2]?.name &&
                                            selectedReportType[0]?.name !== constant.reportTypes[6]?.name) &&
                                        <Form.Group className="mb-3 col-6">
                                            <Form.Label>Type To Search</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="search"
                                                onChange={(e: any) => setSearchTerm(e.target.value)}
                                            />
                                            <div className="text-left">
                                                <Form.Text id="timeDurationHelpBlock" muted style={{ fontSize: "12px" }}>
                                                    Tip: Enter user name or email address to search
                                            </Form.Text>
                                            </div>
                                        </Form.Group>
                                    }

                                    {
                                        (selectedReportType[0]?.name !== constant.reportTypes[1]?.name &&
                                            selectedReportType[0]?.name !== constant.reportTypes[4]?.name) &&
                                        <Form.Group className="mb-3 col-6">
                                            <Form.Label>Group Name</Form.Label>
                                            <MultiSelect
                                                options={groups}
                                                value={selectedMultiGroup}
                                                onChange={setSelectedMultiGroup}
                                                labelledBy="Select Group"
                                            />
                                        </Form.Group>
                                    }


                                    {(selectedReportType[0]?.name === constant.reportTypes[4]?.name &&
                                        selectedReportType[0]?.name !== constant.reportTypes[6]?.name) &&
                                        <Form.Group className="mb-3 col-6">
                                            <Form.Label>Select Survey</Form.Label>
                                            <Form.Select
                                                onChange={(e: any) => {
                                                    setSelectedSurvey(Number(e.target.value));
                                                }}>
                                                <option value={"0"}>{"Select Survey"}</option>
                                                {programSurveyListData?.map((data: any) => <option key={data.id} value={data.id}>{data.name}</option>)}
                                            </Form.Select>
                                        </Form.Group>

                                    }

                                    {
                                        (selectedReportType[0]?.name !== constant.reportTypes[2]?.name &&
                                            selectedReportType[0]?.name !== constant.reportTypes[5]?.name &&
                                            selectedReportType[0]?.name !== constant.reportTypes[1]?.name) &&
                                        <Form.Group className="mb-3 col-6">
                                            <Form.Label>Select User</Form.Label>
                                            <MultiSelect
                                                options={userListData ?? []}
                                                value={selectedMultiUser}
                                                onChange={(e: any) => {
                                                    setSelectedMultiUser(e);
                                                    setIsAllFieldSelected((states: any) => {
                                                        return {
                                                            ...states,
                                                            user: true
                                                        }
                                                    });
                                                }}
                                                labelledBy="Select User"
                                            />
                                        </Form.Group>
                                    }

                                    {
                                        (selectedReportType[0]?.name === constant.reportTypes[1]?.name) &&
                                        <Form.Group className="mb-3 col-6">
                                            <Form.Label>Select User</Form.Label>
                                            <Form.Select onChange={(e: any) => {
                                                setSelectedUser(e.target.value);
                                                setIsAllFieldSelected((states: any) => {
                                                    return {
                                                        ...states,
                                                        user: true
                                                    }
                                                });
                                            }}>
                                                <option value={"0"}>{"Select User"}</option>
                                                {
                                                    userListData?.map(
                                                        (data: any) => <option key={data.value} value={data.value}>{data.label}</option>
                                                    )
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                    }

                                    {
                                        selectedReportType[0]?.name !== constant.reportTypes[6]?.name &&
                                        <Form.Group className="mb-3 col-6">
                                            <Form.Label>Export Type</Form.Label>
                                            <Form.Select onChange={(e: any) => handleSelectedReportType(e.target.value)}>
                                                {constant.type.map((data: any) => <option key={data} value={data}>{data}</option>)}
                                            </Form.Select>
                                            <div className="text-left">
                                                <Form.Text id="timeDurationHelpBlock" muted style={{ fontSize: "12px" }}>
                                                    Required to export the generated report
                                        </Form.Text>
                                            </div>
                                        </Form.Group>
                                    }
                                </>
                            }
                        </form>
                    ) : <form id="reporting" autoComplete="off">
                            <Form.Group className="mb-3 col-6">
                                <Form.Label>Generate report for</Form.Label>
                                <Form.Select className="clientSearchBox" onChange={(e: any) => handleReportType(e.target.value)}>
                                    <option value={"0"}>{"Select Report Type"}</option>
                                    {constant.reportTypes.map((data: any) => <option key={data.id} value={data.id}>{data.name}</option>)}
                                </Form.Select>
                            </Form.Group>
                            {
                                showOtherFilters &&
                                <>
                                    {
                                        (selectedReportType[0]?.name !== constant.reportTypes[5]?.name &&
                                            selectedReportType[0]?.name !== constant.reportTypes[6]?.name) && <Form.Group className="mb-3 col-6">
                                            <Form.Label>Program Name</Form.Label>
                                            <Form.Select className="clientSearchBox" onChange={(e: any) => {
                                                setSelectedProgram(Number(e.target.value));
                                                if (e.target.value <= 0) {
                                                    setSelectedModule(0);
                                                }
                                                setIsAllFieldSelected((states: any) => {
                                                    return {
                                                        ...states,
                                                        programName: true
                                                    }
                                                });
                                            }}>
                                                <option value={"0"}>{"Select Program"}</option>
                                                {programList?.map((data: any) => <option key={data.id} value={data.id}>{data.label}</option>)}
                                            </Form.Select>
                                        </Form.Group>
                                    }

                                    {
                                        (selectedReportType[0]?.name === constant.reportTypes[1]?.name &&
                                            selectedReportType[0]?.name !== constant.reportTypes[6]?.name) &&
                                        <Form.Group className="mb-3 col-6">
                                            <Form.Label>Program Stage</Form.Label>
                                            <Form.Select className="clientSearchBox" onChange={(e: any) => {
                                                setSelectedProgramStage(e.target.value);
                                                if (e.target.value <= 0) {
                                                    setSelectedProgram(0);
                                                    setSelectedModule(0);
                                                }
                                                setIsAllFieldSelected((states: any) => {
                                                    return {
                                                        ...states,
                                                        programStage: true
                                                    }
                                                });
                                            }}>
                                                <option value={"0"}>{"Select Program Stage"}</option>
                                                {userProgramStages?.map((data: any) => <option key={data.id} value={data.id}>{data.name}</option>)}
                                            </Form.Select>
                                        </Form.Group>
                                    }

                                    {
                                        (selectedReportType[0]?.name !== constant.reportTypes[1]?.name && selectedReportType[0]?.name !== constant.reportTypes[4]?.name
                                            && selectedReportType[0]?.name !== constant.reportTypes[5]?.name &&
                                            selectedReportType[0]?.name !== constant.reportTypes[6]?.name) &&
                                        <Form.Group className="mb-3 col-6">
                                            <Form.Label>Module Name</Form.Label>
                                            <Form.Select className="clientSearchBox" onChange={(e: any) => {
                                                setSelectedModule(Number(e.target.value));
                                                setIsAllFieldSelected((states: any) => {
                                                    return {
                                                        ...states,
                                                        moduleName: true
                                                    }
                                                });
                                            }}>
                                                <option value={"0"}>{"Select Module"}</option>
                                                {moduleList?.map((data: any) => <option key={data.id} value={data.id}>{data.label}</option>)}
                                            </Form.Select>
                                        </Form.Group>
                                    }

                                    {
                                        (selectedReportType[0]?.name === constant.reportTypes[2]?.name &&
                                            selectedReportType[0]?.name !== constant.reportTypes[6]?.name) &&
                                        <Form.Group className="mb-3 col-6">
                                            <Form.Label>Type To Search</Form.Label>
                                            <Form.Control
                                                className="clientSearchBox"
                                                type="text"
                                                placeholder="search"
                                                onChange={(e: any) => setSearchTerm(e.target.value)}
                                            />
                                            <div className="text-left">
                                                <Form.Text id="timeDurationHelpBlock" muted style={{ fontSize: "12px" }}>
                                                    Tip: Enter user name or email address to search
                                            </Form.Text>
                                            </div>
                                        </Form.Group>
                                    }

                                    {
                                        (selectedReportType[0]?.name !== constant.reportTypes[1]?.name &&
                                            selectedReportType[0]?.name !== constant.reportTypes[4]?.name) &&
                                        <Form.Group className="mb-3 col-6">
                                            <Form.Label>Group Name</Form.Label>
                                            <MultiSelect
                                                className="mutltiBox"
                                                options={groups}
                                                value={selectedMultiGroup}
                                                onChange={setSelectedMultiGroup}
                                                labelledBy="Select Group"
                                            />
                                        </Form.Group>
                                    }


                                    {(selectedReportType[0]?.name === constant.reportTypes[4]?.name &&
                                        selectedReportType[0]?.name !== constant.reportTypes[6]?.name) &&
                                        <Form.Group className="mb-3 col-6">
                                            <Form.Label>Select Survey</Form.Label>
                                            <Form.Select
                                                className="clientSearchBox"
                                                onChange={(e: any) => {
                                                    setSelectedSurvey(Number(e.target.value));
                                                }}>
                                                <option value={"0"}>{"Select Survey"}</option>
                                                {programSurveyListData?.map((data: any) => <option key={data.id} value={data.id}>{data.name}</option>)}
                                            </Form.Select>
                                        </Form.Group>

                                    }

                                    {
                                        (selectedReportType[0]?.name !== constant.reportTypes[2]?.name &&
                                            selectedReportType[0]?.name !== constant.reportTypes[5]?.name &&
                                            selectedReportType[0]?.name !== constant.reportTypes[1]?.name) &&
                                        <Form.Group className="mb-3 col-6">
                                            <Form.Label>Select User</Form.Label>
                                            <MultiSelect
                                                className="mutltiBox"
                                                options={userListData ?? []}
                                                value={selectedMultiUser}
                                                onChange={(e: any) => {
                                                    setSelectedMultiUser(e);
                                                    setIsAllFieldSelected((states: any) => {
                                                        return {
                                                            ...states,
                                                            user: true
                                                        }
                                                    });
                                                }}
                                                labelledBy="Select User"
                                            />
                                        </Form.Group>
                                    }

                                    {
                                        (selectedReportType[0]?.name === constant.reportTypes[1]?.name) &&
                                        <Form.Group className="mb-3 col-6">
                                            <Form.Label>Select User</Form.Label>
                                            <Form.Select className="clientSearchBox" onChange={(e: any) => {
                                                setSelectedUser(e.target.value);
                                                setIsAllFieldSelected((states: any) => {
                                                    return {
                                                        ...states,
                                                        user: true
                                                    }
                                                });
                                            }}>
                                                <option value={"0"}>{"Select User"}</option>
                                                {
                                                    userListData?.map(
                                                        (data: any) => <option key={data.value} value={data.value}>{data.label}</option>
                                                    )
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                    }

                                    {
                                        selectedReportType[0]?.name !== constant.reportTypes[6]?.name &&
                                        <Form.Group className="mb-3 col-6">
                                            <Form.Label>Export Type</Form.Label>
                                            <Form.Select className="clientSearchBox" onChange={(e: any) => handleSelectedReportType(e.target.value)}>
                                                {constant.type.map((data: any) => <option key={data} value={data}>{data}</option>)}
                                            </Form.Select>
                                            <div className="text-left">
                                                <Form.Text id="timeDurationHelpBlock" muted style={{ fontSize: "12px" }}>
                                                    Required to export the generated report
                                        </Form.Text>
                                            </div>
                                        </Form.Group>
                                    }
                                </>
                            }
                        </form>}
                    {
                        showOtherFilters &&
                        <div className="d-flex col-6">
                            {showSpinner === true ?
                                <Button variant="primary" className="text-secondary" disabled>
                                    <span className="mx-2 text-secondary">
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </span>
                                    Generating Report...
                                    </Button> :
                                <Button
                                    variant="primary"
                                    className="font-weight-600 text-color-182C34"
                                    disabled={isGenerateButtonEnable}
                                    type="button"
                                    onClick={() => generateReport(selectedReportType[0].name)}
                                >
                                    Generate Report
                                </Button>
                            }
                        </div>
                    }
                </section>
            </div>

            <div>
                {console.log(selectedReportType)}
                {

                    selectedReportType[0]?.name !== constant.reportTypes[6]?.name && (attendanceReport.length > 0 || moduleProgressReport.length > 0 || individualModuleProgressReport.length > 0 || userScoreReport.length > 0 || programSurveyData.length > 0 || selectionPreferenceData.length > 0) &&
                    <TableComponent
                        data={
                            selectedReportType[0].name === constant.reportTypes[0].name
                                ? attendanceReport
                                : selectedReportType[0].name === constant.reportTypes[1].name
                                    ? individualModuleProgressReport
                                    : selectedReportType[0].name === constant.reportTypes[2].name
                                        ? moduleProgressReport
                                        : selectedReportType[0].name === constant.reportTypes[3].name
                                            ? userScoreReport
                                            : selectedReportType[0].name === constant.reportTypes[4].name
                                                ? programSurveyData
                                                : selectionPreferenceData
                        }
                        columns={
                            selectedReportType[0].name === constant.reportTypes[0].name
                                ? attendanceReportColumn
                                : selectedReportType[0].name === constant.reportTypes[1].name
                                    ? individualModuleProgressReportColumn
                                    : selectedReportType[0].name === constant.reportTypes[2].name
                                        ? moduleProgressReportColumn
                                        : selectedReportType[0].name === constant.reportTypes[3].name
                                            ? userScoreReportColumn
                                            : selectedReportType[0].name === constant.reportTypes[4].name
                                                ? surveyReportColumn
                                                : selectionPreferenceReportColumn
                        }
                        tableTitle={
                            selectedReportType[0].name === constant.reportTypes[0].name
                                ? constant.reportTypes[0].name
                                : selectedReportType[0].name === constant.reportTypes[1].name
                                    ? constant.reportTypes[1].name
                                    : selectedReportType[0].name === constant.reportTypes[2].name
                                        ? constant.reportTypes[2].name
                                        : selectedReportType[0].name === constant.reportTypes[3].name
                                            ? constant.reportTypes[3].name
                                            : selectedReportType[0].name === constant.reportTypes[4].name
                                                ? constant.reportTypes[4].name
                                                : constant.reportTypes[5].name
                        }
                        totalRows={pagination.totalItems}
                        rowsPerpage={pagination.pageSize}
                        handlePageChange={(e: any) => handlePageChange(e)}
                        handleRowsPerPage={(e: any) => handleRowsPerPage(e)}
                        pageAction={
                            showExportSpinner === true ?
                                <Button variant="primary" className="text-secondary" disabled>
                                    <span className="mx-2 text-secondary">
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </span>
                                    Exporting Report...
                                </Button> :
                                <Button
                                    variant="primary"
                                    className="font-weight-600 text-color-182C34"
                                    disabled={isexportButtonEnable}
                                    type="button"
                                    onClick={() => exportReport()}
                                >
                                    Export Report
                            </Button>
                        }
                        paginationDefaultPage={paginationDefaultPage}
                        isPagination={
                            (selectedReportType[0].name === constant.reportTypes[1].name || pagination.totalItems < pagination.pageSize)
                                ? false : true}
                    />
                }
                {
                    selectedReportType[0]?.name === constant.reportTypes[6]?.name && <div className="row g-3 align-items-center">
                        <div className="mb-3 col-6">
                            <ChartComponent key={1} ChartType={chartType.Radar} spinner={chartSpinner} data={coreValueChartData} options={programChartOption} />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default ReportingComponent;