import React, { useContext } from "react";
import { Form } from "react-bootstrap";
import { ThemeContext } from "../../../Contexts/ThemeContext";

interface Iprops {
    questionDetail: any;
    currentIndex: number;
    freeTextAnswer: any;
    handleTextAreaChange: any;
    handleMultipleClick: any;
    handleRadioClick: any;
}

function SurveyForm(props: Iprops) {
    const { theme } = useContext(ThemeContext)
    const {
        questionDetail,
        currentIndex,
        freeTextAnswer,
        handleTextAreaChange,
        handleMultipleClick,
        handleRadioClick
    } = props;

    return (
        <>
            <Form.Group
                className="mb-3 mt-3"
                controlId="formPlaintextEmail"
            >
                <Form.Label column>
                    {(currentIndex + 1) + "."} {questionDetail?.question}
                </Form.Label>
                {questionDetail?.options?.length > 0
                    ? <>
                        {questionDetail?.options.map((answer: any, index: number) => {
                            return <Form.Group
                                key={index}
                                className="mb-3 mx-4 mt-3"
                                controlId={'formControl'}
                            >
                                {questionDetail?.questionType === 3
                                    ? <Form.Check
                                        type="checkbox"
                                        key={index}
                                        name={`group${currentIndex}`}
                                        label={answer?.option}
                                        checked={freeTextAnswer?.includes(answer?.id) || answer?.checked}
                                        id={answer?.id}
                                        onChange={(e: any) => handleMultipleClick(answer?.id, questionDetail?.answerID ? "Update" : "Create")}
                                    />
                                    : <Form.Check
                                        key={index}
                                        name={`group${currentIndex}`}
                                        type="radio"
                                        id={answer?.id}
                                        checked={freeTextAnswer?.includes(answer?.id)}
                                        label={answer?.option}
                                        value={answer?.id}
                                        onChange={(e: any) => handleRadioClick(answer?.id, questionDetail?.answerID ? "Update" : "Create")}
                                    />
                                }
                            </Form.Group>
                        })}
                    </>
                    : <Form.Group className="mb-3 mt-3" controlId="Textarea">
                        {theme === "light" ? <Form.Control
                            as="textarea"
                            onChange={(e) => handleTextAreaChange(e.target.value, freeTextAnswer ? "Update" : "Create", currentIndex)}
                            rows={6}
                            value={freeTextAnswer}
                            style={{ height: '100px' }}
                        /> :
                            <Form.Control
                                as="textarea"
                                onChange={(e) => handleTextAreaChange(e.target.value, freeTextAnswer ? "Update" : "Create", currentIndex)}
                                rows={6}
                                value={freeTextAnswer}
                                style={{ height: '100px', background: "#1C2930", color: "white" }}
                            />}
                    </Form.Group>
                }
            </Form.Group>
        </>
    );
}

export default SurveyForm;
