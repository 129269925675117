import React, { useContext, useEffect, useState } from "react";
import TabsComponent from "../../FunctionalComponents/Tabs/TabsComponent";
import { Headers } from "../../../Utils/Enums";
import HeaderComponent from "../../FunctionalComponents/Header/HeaderComponent";
import { useHistory } from 'react-router-dom';
import UserProfileComponent from "./UserProfileComponent";
import UserSecurityCompnent from "./UserSecurityCompnent";
import { UserContext } from "../../../Contexts/UserContext";
import { ThemeContext } from "../../../Contexts/ThemeContext";


const UserAccountComponent = (props: any) => {
    let history = useHistory();
    const [selectedTab, setSelectedTab] = useState("Profile");
    const userContext = useContext(UserContext);
    const { theme } = useContext(ThemeContext);
    let redirection: any = props?.location?.state;
    const tabs = [
        {
            id: 1,
            title: "Profile",
            isActive: true,
        },
        {
            id: 2,
            title: "Security",
            isActive: false,
        },
    ]

    const handleRedirection = () => {
        if (redirection === undefined) {
            history.push("/dashboard");
        } else {
            window.history.back();
        }
    }

    const TabsClickHandler = (tab: string) => {
        setSelectedTab(tab);
    };

    useEffect(() => {
        userContext.dispatch('');
    }, [])

    return (
        <div className="container">
            <div className="row">
                {theme === "light" ? (
                    <div className="d-flex align-items-center margin-bottom-24">
                        <img onClick={() => handleRedirection()} className="cursor-pointer" alt="redirection-back" src={window.location.origin + "/images/chevron-left.svg"} />
                        <span onClick={() => handleRedirection()} className="cursor-pointer">
                            <HeaderComponent
                                title="My Account"
                                size={Headers.H2}
                            />
                        </span>
                    </div>
                ) : <div className="d-flex align-items-center margin-bottom-24">
                        <img onClick={() => handleRedirection()} className="cursor-pointer" alt="redirection-back"
                            src={window.location.origin + "/images/left-chevron-dark.svg"} />
                        <span onClick={() => handleRedirection()} className="cursor-pointer">
                            <div style={{ marginLeft: "15px" }}>
                                <HeaderComponent
                                    title="My Account"
                                    size={Headers.H2}
                                />
                            </div>
                        </span>
                    </div>}
            </div>
            <div className="row">
                <div className="custom-tab-overflow">
                    <TabsComponent
                        tabs={tabs}
                        customClass={"tabItemConfigBig"}
                        onTabSelectedHandler={TabsClickHandler}
                        myTab={selectedTab}
                    />
                </div>
            </div>
            {
                (selectedTab === "Profile") && (
                    <div style={{ position: "relative" }}>
                        <UserProfileComponent />
                    </div>
                )
            }
            {
                (selectedTab === "Security") && (
                    <div style={{ position: "relative" }}>
                        <UserSecurityCompnent />
                    </div>
                )
            }
        </div>
    )
}

export default UserAccountComponent;