import React, { useState, useContext } from "react";
import { Accordion, Button, Form, Stack } from "react-bootstrap";
import { ThemeContext } from "../../../Contexts/ThemeContext";
import { Headers } from "../../../Utils/Enums";
import HeaderComponent from "../Header/HeaderComponent";
import "../../../darkcustom.css"

interface Iprops {
    questionDetail: any;
    freeTextAnswer: string;
    handleTextAreaChange: any;
    handleMultipleClick: any;
    handleRadioClick: any;
    handleUpdateAnswer: any;
    handleCancelAnswer: any;
    handleSubmitSurvey: any
}

function SurveySummary({
    questionDetail,
    handleTextAreaChange,
    handleMultipleClick,
    handleRadioClick,
    handleUpdateAnswer,
    handleCancelAnswer,
    handleSubmitSurvey
}: Iprops) {

    const [editableAnswer, setEdiableAnswer] = useState<boolean>(false);
    const [cancelEditAnswer, setCancelEditAnswer] = useState<boolean>(false);
    const [whichAnswer, setWhichAnswer] = useState<number>(0);
    const { theme } = useContext(ThemeContext)
    const onAccordianButtonClick = (e: any, questionId: number, type: string) => {
        e.preventDefault();
        let matchedQuestion: any = questionDetail.filter((value: any) => value.id === questionId);
        let tempQuestion: any = matchedQuestion?.map((data: any) => {
            return data?.id;
        })
        if (type === "Cancel") {
            if (tempQuestion[0] === questionId) {
                setEdiableAnswer(false);
                setCancelEditAnswer(true);
            }
        } else if (type === "Submit") {
            if (tempQuestion[0] === questionId) {
                setEdiableAnswer(false);
                setCancelEditAnswer(true);
            }
        } else {
            setWhichAnswer(tempQuestion[0]);
            setEdiableAnswer(true);
            setCancelEditAnswer(false);
        }
    }

    return (
        <div style={{ width: "100%" }}>
            <div className="d-flex flex-column justify-content-start mb-3">
                <HeaderComponent title={"Survey Summary"} size={Headers.H3} />
            </div>
            <div className="mb-3">
                {theme === "light" ? <Form.Text id="passwordHelpBlock" muted>
                    Here, you can edit your feedbacks.
                </Form.Text> :
                    <Form.Text style={{ color: "white !important" }} id="passwordHelpBlock" muted>
                        Here, you can edit your feedbacks.
                    </Form.Text>}
            </div>
            <div className="d-flex flex-column justify-content-center">
                {theme === "light" ? <Accordion>
                    {questionDetail?.map((data: any, index: number) => {
                        return <Accordion.Item
                            key={index}
                            eventKey={index + ""}
                        >
                            <Accordion.Header>
                                {`${(index + 1)}. ${data.question}`}
                            </Accordion.Header>

                            {data?.options?.length > 0
                                ? <Accordion.Body>
                                    {data?.options.map((answer: any, index1: number) => {
                                        return <Form.Group
                                            key={index1}
                                            className="mb-3 mx-4 mt-3"
                                            controlId={'formControl'}
                                        >
                                            {data?.questionType === 3
                                                ? <>
                                                    <Form.Check
                                                        key={index}
                                                        name={`group${index}`}
                                                        type="checkbox"
                                                        id={answer?.id}
                                                        checked={data?.answer?.includes(answer?.id)}
                                                        label={answer.option}
                                                        value={answer.id}
                                                        onChange={(e: any) => handleMultipleClick(answer?.id, "Edit", index)}
                                                        disabled={whichAnswer !== data.id || cancelEditAnswer}
                                                    />
                                                </>
                                                : <>
                                                    <Form.Check
                                                        key={index}
                                                        name={`group${index}`}
                                                        type="radio"
                                                        id={answer?.id}
                                                        checked={data?.answer?.includes(answer?.id)}
                                                        label={answer.option}
                                                        value={answer.id}
                                                        onChange={(e: any) => handleRadioClick(answer?.id, "Edit", index)}
                                                        disabled={whichAnswer !== data.id || cancelEditAnswer}
                                                    />
                                                </>
                                            }
                                        </Form.Group>
                                    })}
                                    <div className="d-flex justify-content-end">
                                        <div className="d-flex justify-content-space-between">
                                            {
                                                editableAnswer && whichAnswer === data.id
                                                    ? <Stack direction="horizontal" gap={3}>
                                                        <Button
                                                            variant="primary"
                                                            type="button"
                                                            onClick={async (e: any) => {
                                                                if (await handleUpdateAnswer(index) === true) {
                                                                    onAccordianButtonClick(e, data.id, "Submit");
                                                                }
                                                            }}
                                                        >
                                                            Submit Answer
                                                        </Button>
                                                        <div className="vr" />
                                                        <Button
                                                            variant="primary"
                                                            type="button"
                                                            onClick={(e: any) => {
                                                                onAccordianButtonClick(e, data.id, "Cancel");
                                                                handleCancelAnswer(e, data.id, index);
                                                            }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </Stack>
                                                    :
                                                    <Button
                                                        variant="primary"
                                                        type="button"
                                                        onClick={(e: any) => onAccordianButtonClick(e, data.id, "Edit")}
                                                    >
                                                        Edit Answer
                                                    </Button>
                                            }
                                        </div>
                                    </div>
                                </Accordion.Body>
                                : <Accordion.Body>
                                    <Form.Group className="mb-3 mt-3" controlId="Textarea">
                                        <Form.Control
                                            as="textarea"
                                            onChange={(e) => handleTextAreaChange(e.target.value, "Edit", index)}
                                            rows={6}
                                            value={data?.answer || ""}
                                            readOnly={whichAnswer !== data?.id || cancelEditAnswer}
                                            style={{ height: '100px' }}
                                        />
                                    </Form.Group>
                                    <div className="d-flex justify-content-end md-6">
                                        <div className="d-flex justify-content-space-between">
                                            {
                                                editableAnswer && whichAnswer === data.id
                                                    ? <Stack direction="horizontal" gap={3}>
                                                        <Button
                                                            variant="primary"
                                                            type="button"
                                                            onClick={async (e: any) => {
                                                                if (await handleUpdateAnswer(index) === true) {
                                                                    onAccordianButtonClick(e, data.id, "Submit");
                                                                }
                                                            }}
                                                        >
                                                            Submit Answer
                                                        </Button>
                                                        <div className="vr" />
                                                        <Button
                                                            variant="primary"
                                                            type="button"
                                                            onClick={(e: any) => {
                                                                onAccordianButtonClick(e, data.id, "Cancel");
                                                                handleCancelAnswer(e, data.id, index);
                                                            }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </Stack>
                                                    :
                                                    <Button
                                                        variant="primary"
                                                        type="button"
                                                        onClick={(e: any) => onAccordianButtonClick(e, data.id, "Edit")}
                                                    >
                                                        Edit Answer
                                                    </Button>
                                            }
                                        </div>
                                    </div>
                                </Accordion.Body>
                            }
                        </Accordion.Item>
                    })}
                </Accordion>
                    :
                    <Accordion>
                        {questionDetail?.map((data: any, index: number) => {
                            return <Accordion.Item
                                className="summeryDark"
                                key={index}
                                eventKey={index + ""}
                            >
                                <Accordion.Header>
                                    {`${(index + 1)}. ${data.question}`}
                                </Accordion.Header>

                                {data?.options?.length > 0
                                    ? <Accordion.Body>
                                        {data?.options.map((answer: any, index1: number) => {
                                            return <Form.Group
                                                key={index1}
                                                className="mb-3 mx-4 mt-3"
                                                controlId={'formControl'}
                                                
                                            >
                                                {data?.questionType === 3
                                                    ? <>
                                                        <Form.Check
                                                            key={index}
                                                            name={`group${index}`}
                                                            type="checkbox"

                                                            id={answer?.id}
                                                            checked={data?.answer?.includes(answer?.id)}
                                                            label={answer.option}
                                                            value={answer.id}
                                                            onChange={(e: any) => handleMultipleClick(answer?.id, "Edit", index)}
                                                            disabled={whichAnswer !== data.id || cancelEditAnswer}
                                                        />
                                                    </>
                                                    : <>
                                                        <Form.Check
                                                            key={index}
                                                            name={`group${index}`}
                                                            type="radio"

                                                            id={answer?.id}
                                                            checked={data?.answer?.includes(answer?.id)}
                                                            label={answer.option}
                                                            value={answer.id}
                                                            onChange={(e: any) => handleRadioClick(answer?.id, "Edit", index)}
                                                            disabled={whichAnswer !== data.id || cancelEditAnswer}
                                                        />
                                                    </>
                                                }
                                            </Form.Group>
                                        })}
                                        <div className="d-flex justify-content-end">
                                            <div className="d-flex justify-content-space-between">
                                                {
                                                    editableAnswer && whichAnswer === data.id
                                                        ? <Stack direction="horizontal" gap={3}>
                                                            <Button
                                                                variant="primary"
                                                                type="button"
                                                                onClick={async (e: any) => {
                                                                    if (await handleUpdateAnswer(index) === true) {
                                                                        onAccordianButtonClick(e, data.id, "Submit");
                                                                    }
                                                                }}
                                                            >
                                                                Submit Answer
                                                            </Button>
                                                            <div className="vr" />
                                                            <Button
                                                                variant="primary"
                                                                type="button"
                                                                onClick={(e: any) => {
                                                                    onAccordianButtonClick(e, data.id, "Cancel");
                                                                    handleCancelAnswer(e, data.id, index);
                                                                }}
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </Stack>
                                                        :
                                                        <Button
                                                            variant="primary"
                                                            type="button"
                                                            onClick={(e: any) => onAccordianButtonClick(e, data.id, "Edit")}
                                                        >
                                                            Edit Answer
                                                        </Button>
                                                }
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                    : <Accordion.Body>
                                        <Form.Group className="mb-3 mt-3" controlId="Textarea">
                                            <Form.Control
                                                as="textarea"
                                                onChange={(e) => handleTextAreaChange(e.target.value, "Edit", index)}
                                                rows={6}
                                                value={data?.answer || ""}
                                                readOnly={whichAnswer !== data?.id || cancelEditAnswer}
                                                style={{ height: '100px', background: "#1C2930", color: "white" }}
                                            />
                                        </Form.Group>
                                        <div className="d-flex justify-content-end md-6">
                                            <div className="d-flex justify-content-space-between">
                                                {
                                                    editableAnswer && whichAnswer === data.id
                                                        ? <Stack direction="horizontal" gap={3}>
                                                            <Button
                                                                variant="primary"
                                                                type="button"
                                                                onClick={async (e: any) => {
                                                                    if (await handleUpdateAnswer(index) === true) {
                                                                        onAccordianButtonClick(e, data.id, "Submit");
                                                                    }
                                                                }}
                                                            >
                                                                Submit Answer
                                                            </Button>
                                                            <div className="vr" />
                                                            <Button
                                                                variant="primary"
                                                                type="button"
                                                                onClick={(e: any) => {
                                                                    onAccordianButtonClick(e, data.id, "Cancel");
                                                                    handleCancelAnswer(e, data.id, index);
                                                                }}
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </Stack>
                                                        :
                                                        <Button
                                                            variant="primary"
                                                            type="button"
                                                            onClick={(e: any) => onAccordianButtonClick(e, data.id, "Edit")}
                                                        >
                                                            Edit Answer
                                                        </Button>
                                                }
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                }
                            </Accordion.Item>
                        })}
                    </Accordion>}
            </div>
            <div className="d-flex justify-content-end mt-3 mb-3">
                <Button
                    variant="primary"
                    type="button"
                    onClick={() => handleSubmitSurvey()}
                >
                    Submit Response
                </Button>
            </div>
        </div>
    );
}

export default SurveySummary