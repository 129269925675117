import React, { useContext } from 'react';
import './TextComponent.css';
import '../../../custom.css';
import { ThemeContext } from "../../../Contexts/ThemeContext"

interface ITextComponent {
    text?: string;
    customClassName?: string;
    id?: string;
}
const TextComponent = (props: ITextComponent) => {
    const { theme } = useContext(ThemeContext)
    const createMarkup = (text: string) => {
        return { __html: text };
    };
    return <>
        {theme === "light" ? (
            <div>
                {props.text && <div
                    id={props.id}
                    className={`text-config text-color-2D5362 ${props.customClassName}`} data-url={props.text}
                    dangerouslySetInnerHTML={createMarkup(String(props.text))}></div>}

            </div>
        ) : <div>
                {props.text && <div
                    id={props.id}
                    style={{ color: "#fff" }}
                    className={`text-config text-color-2D5362 ${props.customClassName}`} data-url={props.text}
                    dangerouslySetInnerHTML={createMarkup(String(props.text))}></div>}

            </div>}

    </>
};
export default TextComponent;