import { LoDashImplicitNumberArrayWrapper } from "lodash";
import constant from "../Utils/Config";
import {isTokenExpired,clearLocalStorageAndRedirect, getAuthToken} from "../Utils/Utils";
class ConceptSummary {
    async getModuleSummaryWithResource(moduleId: LoDashImplicitNumberArrayWrapper) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Module/GetModuleSummaryWithResource?moduleId=` + moduleId, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            });
            if (!response.ok) {
                return { data: "error", ok: false };
            } else {
                const result = await response.json();
                if (result) {
                    return { data: result, ok: true };
                } else {
                    return { data: "error", ok: false };
                }
            }
        } catch (error) {
            return { data: error, ok: false };
        }
    };

    async getContent(id: number) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Content?id=` + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            });
            if (!response.ok) {
                return { data: "error", ok: false };
            } else {
                const result = await response.text();
                if (result) {
                    return { data: result, ok: true };
                } else {
                    return { data: "error", ok: false };
                }
            }
        } catch (error) {
            return { data: error, ok: false };
        }
    };

    async resourceUserResponse(resourceId: number, userResponse: string) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Resource/ResourceUserResponse?resourceId=` + resourceId + "&userResponse=" + userResponse, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            });
            if (!response.ok) {
                return { data: "error", ok: false };
            } else {
                const result = await response.json();
                if (result) {
                    return { data: result, ok: true };
                } else {
                    return { data: "error", ok: false };
                }
            }
        } catch (error) {
            return { ok: false };
        }
    };

    async setModuleQuestionUserResponse(moduleQuestionId: number, moduleQuestionAnswer: string) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Module/ModuleQuestionUserResponse?moduleQuestionId=` + moduleQuestionId + "&moduleQuestionAnswer=" + moduleQuestionAnswer, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            });
            if (!response.ok) {
                return { data: "error", ok: false };
            } else {
                const result = response.ok;
                if (result) {
                    return { data: result, ok: true };
                } else {
                    return { data: "error", ok: false };
                }
            }
        } catch (error) {
            return { ok: false };
        }
    };

    async finishModule(moduleId: number) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Module/FinishModule?moduleId=` + moduleId, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            });
            if (!response.ok) {
                return { data: "error", ok: false };
            } else {
                const result = await response.json();
                if (result) {
                    return { data: result, ok: true };
                } else {
                    return { data: "error", ok: false };
                }
            }
        } catch (error) {
            return { ok: false };
        }
    };
}
export default ConceptSummary;