import React, { useState, useContext } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import HeaderComponent from '../Header/HeaderComponent';
import TextComponent from '../Text/TextComponent';
import { Headers } from '../../../Utils/Enums';
import "./ProfileCharacteristicsModalComponent.css";
import { ThemeContext } from '../../../Contexts/ThemeContext';

interface IProfileCharacteristicsModalComponent {
    item: any;
}

const ProfileCharacteristicsModalComponent = (props: IProfileCharacteristicsModalComponent) => {
    const [modal, setModal] = useState(false);
    const { theme } = useContext(ThemeContext);
    const toggle = () => setModal(!modal);
    return <>
        <div>
            <div data-toggle="modal" data-target="#exampleModal" onClick={toggle} className="cursor-pointer">
                {theme === "light" ? (
                    <img
                        src={window.location.origin + '/images/chevron-right.svg'}
                        alt="chevron-right"
                        className="chevron-config align-self-center" />
                ) : <img
                    src={window.location.origin + '/images/right-chevron-dark.svg'}
                    alt="chevron-right"
                    className="chevron-config align-self-center" />}
            </div>
            {theme === "light" ? (
                <Modal isOpen={modal} centered>
                    <ModalHeader>
                        {props.item.title && <HeaderComponent
                            title={props.item.title}
                            size={Headers.H2}
                        />}
                    </ModalHeader>
                    <ModalBody className='modal-body-config'>
                        <div>
                            {props.item.characteristicUserProfile && props.item.characteristicUserProfile.map((item: any, index: number) =>
                                <div key={index}>
                                    <div className="description margin-bottom-24 fw-bold text-uppercase" data-tag="allowRowEvents">
                                        {item.characteristicOption}
                                    </div>
                                    <div className="">
                                        {item.characteristicCategoryDescription &&
                                            <div className="margin-bottom-16">
                                                <TextComponent text={item.characteristicCategoryDescription} />
                                            </div>
                                        }
                                    </div>
                                    {item.characteristicSubCateogryOptionUserProfileViewModels && item.characteristicSubCateogryOptionUserProfileViewModels.map((item: any, index: number) =>
                                        <div className="margin-bottom-24" key={index}>
                                            <div className="description margin-bottom-16 fw-bold text-capitalize" data-tag="allowRowEvents">
                                                {item.characteristicSubCategory}
                                            </div>
                                            <div className="margin-bottom-16" dangerouslySetInnerHTML={{ __html: item.characteristicSubCategoryMappingDescription }} />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" className="font-weight-600" onClick={() => { setModal(false) }}>
                            <TextComponent text={'Close'} customClassName="text-color-182C34" />
                        </Button>
                    </ModalFooter>
                </Modal>
            ) : <Modal style={{ backgroundColor: "#28373E", color: "#fff" }} isOpen={modal} centered>
                <ModalHeader style={{ backgroundColor: "#28373E", color: "#fff" }}>
                    {props.item.title && <HeaderComponent
                        title={props.item.title}
                        size={Headers.H2}
                    />}
                </ModalHeader>
                <ModalBody style={{ backgroundColor: "#28373E", color: "#fff" }} className='modal-body-config'>
                    <div>
                        {props.item.characteristicUserProfile && props.item.characteristicUserProfile.map((item: any, index: number) =>
                            <div key={index}>
                                <div className="description margin-bottom-24 fw-bold text-uppercase" data-tag="allowRowEvents">
                                    {item.characteristicOption}
                                </div>
                                <div className="">
                                    {item.characteristicCategoryDescription &&
                                        <div className="margin-bottom-16">
                                            <TextComponent text={item.characteristicCategoryDescription} />
                                        </div>
                                    }
                                </div>
                                {item.characteristicSubCateogryOptionUserProfileViewModels && item.characteristicSubCateogryOptionUserProfileViewModels.map((item: any, index: number) =>
                                    <div className="margin-bottom-24" key={index}>
                                        <div className="description margin-bottom-16 fw-bold text-capitalize" data-tag="allowRowEvents">
                                            {item.characteristicSubCategory}
                                        </div>
                                        <div className="margin-bottom-16" dangerouslySetInnerHTML={{ __html: item.characteristicSubCategoryMappingDescription }} />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </ModalBody>
                <ModalFooter style={{ backgroundColor: "#28373E", color: "#fff" }}>
                    <Button color="secondary" className="font-weight-600" onClick={() => { setModal(false) }}>
                        <TextComponent text={'Close'} customClassName="text-color-182C34 buttonText" />
                    </Button>
                </ModalFooter>
            </Modal>}
        </div>
    </>

}
export default ProfileCharacteristicsModalComponent;