import React from 'react';
import "./IconDotsComponent.css";

const IconDotsComponent=()=>{
    return <>
        <img 
        src={window.location.origin + '/images/circlesmenu.gif'} 
        alt='typing-animation' 
        className="icon-dots-config filter-156-156-156-025"/> 
    </>
}
export default IconDotsComponent;