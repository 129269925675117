import React, { useContext } from "react";
import { ThemeContext } from "../../../Contexts/ThemeContext";
import "../../../darkcustom.css"

interface Iprops {
    setSearchParam?: any;
    handleQuoteLibraryListSearch?: () => Promise<void>;
    isUserLikedQuote?: boolean;
    handlegetUserLikedQuoteSearch?: () => Promise<void>;
    handleResourceLibrarySearch?: () => Promise<void>;
    handleUserResourceListSearch?: () => Promise<void>;
    page: string
}
function LibraryFilterComponent(props: Iprops) {
    let { theme } = useContext(ThemeContext)

    const { setSearchParam, handleQuoteLibraryListSearch, isUserLikedQuote, handlegetUserLikedQuoteSearch, handleResourceLibrarySearch, page, handleUserResourceListSearch } = props;

    const handleSearch = (value: string) => {
        setSearchParam(value);
    }

    const handleSearchClick = () => {
        if (page === "quote") {
            if (isUserLikedQuote) {
                if (handlegetUserLikedQuoteSearch) { handlegetUserLikedQuoteSearch(); }
            } else {
                if (handleQuoteLibraryListSearch) { handleQuoteLibraryListSearch(); }
            }
        } else {
            if (page === "resourceLibrary") {
                if (handleResourceLibrarySearch) { handleResourceLibrarySearch(); }
            } else {
                if (handleUserResourceListSearch) { handleUserResourceListSearch(); }
            }

        }

    }


    return (
        <>
            <div className="d-flex flex-column">
                <label htmlFor="basic-url" className="form-label">Search for a word or phrase</label>
                {theme === "light" ? (
                    <div className="input-group mb-3">
                        <input
                            type="text"
                            placeholder="Search"
                            className="form-control"
                            id="basic-url"
                            aria-describedby="basic-addon3"
                            onChange={(e: any) => handleSearch(e.target.value)}
                            role="textbox"
                        />
                        <span className="input-group-text cursor-pointer" id="basic-addon3" onClick={() => handleSearchClick()}>Search</span>
                    </div>
                ) : <div className="input-group mb-3">
                        <input
                            type="text"
                            placeholder="Search"
                            className="form-control searchBox"
                            id="basic-url"
                            aria-describedby="basic-addon3"
                            onChange={(e: any) => handleSearch(e.target.value)}
                            role="textbox"
                        />
                        <span className="input-group-text cursor-pointer" id="basic-addon3" onClick={() => handleSearchClick()}>Search</span>
                    </div>}
            </div>
        </>
    );
}

export default LibraryFilterComponent;