import constant from "../Utils/Config";

class UserAuth {
    async userLogin(userData: any) {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}${constant.login}`, {
            method: 'POST',
            body: JSON.stringify(userData),
            headers: {
                'Content-Type': 'application/json',
                'accept': '*/*',
            }
        }).then((response: any) => response.json())
            .then((response: any) => {
                return response;
            }).catch((error: any) => {
                return error;
            });
        return response;
    };
}

export default UserAuth;
