import { toast } from 'react-toastify';

export const timeOut = 3000;

export const createNotification = (type: string, msg: any) => {
    if (type === "info") {
        toast.info(msg);
    } else if (type === "success") {
        toast.success(msg);
    } else if (type === "warning") {
        toast.warn(msg);
    } else {
        toast.error(msg);
    }
}