import React from "react";
import TextComponent from "../Text/TextComponent";

function ErrorPageComponent(){
    return(
        <div className="container d-flex justify-content-center">
            <TextComponent text="404 | Page not found" customClassName="text-black" />
        </div>
    );
}

export default ErrorPageComponent;