import React, { useEffect, useLayoutEffect, useState } from "react";
import { Headers } from "../../../Utils/Enums";
import HeaderComponent from "../../FunctionalComponents/Header/HeaderComponent";
import LoaderComponent from "../../FunctionalComponents/Loader/LoaderComponent";
import UtilServices from "../../../Services/Util.service";
import OpenSurvey from "../../../Services/OpenSurvey.service";
import { IOpenSurvey,IOpenSurveyAnswer, IOpenSurveyRequest } from "../../../interfaces/OpenSurvey/IOpenSurvey";
import { Button, Spinner,ProgressBar } from "react-bootstrap";
import OpenSurveyForm from "../../FunctionalComponents/Survey/OpenSurveyForm";
import { IStartSurveyRequest } from "../../../interfaces/OpenSurvey/IStartSurvey";
import ReCAPTCHA from "react-google-recaptcha";
import { isUserLoggedIn } from "../../../Utils/Utils";
import TextComponent from "../../FunctionalComponents/Text/TextComponent";
import { useHistory } from 'react-router';

const OpenSurveyComponent = () => {
    const utilServices = new UtilServices();
    const openSurvey = new OpenSurvey();
    let history = useHistory();
    const [loading, setLoading] = useState<boolean>(true);
    const [userIPaddress,setUserIPaddress] = useState<string>("");
    const [userCode,setUserCode] = useState<string>("");
    const [surveyData,setSurveyData] = useState<IOpenSurvey>();
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [surveyCompleted,setSurveyCompleted] = useState<boolean>(false);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [userAnswers,setUserAnswers] = useState<IOpenSurveyAnswer[]>([]);
    const [isSubmitDisable,setIsSubmitDisable] = useState<boolean>(true);
    const [statusId,setStatusId] = useState<number>(0);
    const [isError,setIsError] = useState<boolean>(false);  
    const [isVerified, setIsVerified] = useState<boolean>(false);
    const [errorMessage,setErrorMessage] = useState<string>("Something went wrong !!");   

    useLayoutEffect(() => {
        let url = window.location.href;
        let tempCode: any = url.split("?")[1]?.slice(2);
        setUserCode(tempCode);
    }, []);

    useEffect(() => {
        if (userCode) {
            getPublicIPAddress();
            getSurveyDetails();            
        }
    }, [userCode]);

    const getPublicIPAddress = async () => {
        let response = await utilServices.getPublicIPv4();
        if(response && response.status){
            setUserIPaddress(response.data);
        }
        else{
            setIsError(true);
            setErrorMessage("Network Error!! Possibly your network or server is busy");
        }
    }

    const getSurveyDetails = async () => {
        let response = await openSurvey.getSurveyDetail(userCode);
        if(response && response.status && response.data){             
            let objs:IOpenSurveyAnswer[] = response.data.questions.map(x => {
                return {
                    questionId:x.id,
                    freeTextAnswer:"",
                    optionIds:[]
                };
            })    
            setUserAnswers(objs); 
            setSurveyData(response.data); 
        }
        else{            
            setIsError(true);
            setErrorMessage("This link has expired or the survey does not exist");
        }
        setLoading(false);
    }

    const startSurvey = async () => {
        if(surveyData){
            let obj:IStartSurveyRequest = {
                ipAddress:userIPaddress,
                surveyId:surveyData.id
            }
            let response = await openSurvey.startSurvey(obj);
            if(response && response.status && response.data > 0){
                setStatusId(response.data);
                setShowSpinner(false);
            }
            else{
                setIsError(true);
                setErrorMessage("Network Error!! possibly your network or server is busy");
                setShowSpinner(false);
            }
        }    
        else{
            setIsError(true);
            setShowSpinner(false);
        }    
    }

    const completeSurvey = async (obj:IOpenSurveyRequest) => {
        if(surveyData && statusId > 0){                     
          let response =  await openSurvey.completeSurvey(obj);
          if(response && response.status){            
            setSurveyCompleted(true);
            setLoading(false);
          }
          else{
            setIsError(true);
            setErrorMessage("Network Error!! possibly you are not connected to internet or server is busy");
            setLoading(false);
          }
        }
        else{
            setIsError(true);
            setLoading(false);
        }        
    }

    const handleStartSurveyClick = async () => {
        if(!isVerified) return setIsVerified(false);        
        if(surveyData){
            setShowSpinner(true);        
            await startSurvey();
        }                
    }

    const handleMultipleClick = (value:any,qid:number) => {
        if(userAnswers){            
            let index = userAnswers.findIndex(x => x.questionId === qid)
            if(index !== -1){
                let selectedOptions:number[] = userAnswers[index].optionIds || [];
                if(selectedOptions.includes(value)){
                    selectedOptions = selectedOptions.filter((item) => item !== value)
                }else{
                    selectedOptions.push(value)
                }
                let tempValues: number[] = [...new Set(selectedOptions)]
                if(tempValues.length > 0){
                    
                }
                else{
                    setIsSubmitDisable(true);
                }
                updateSelectedQuestions(tempValues,index,false);
                if(userAnswers[index].optionIds.length > 0){
                    setIsSubmitDisable(false);
                }
                else {
                    setIsSubmitDisable(true);
                }             
            }     
        }
    }

    const handleRadioClick = (value: any,qid:number) => {
        if(userAnswers){
            let index = userAnswers.findIndex(x => x.questionId === qid)
            if(index !== -1){
                updateSelectedQuestions([value],index,false);
                if(userAnswers[index].optionIds.length > 0){
                    setIsSubmitDisable(false);
                }
                else {
                    setIsSubmitDisable(true);
                }              
            }                
        }        
    }

    const handleTextAreaChange = (value: string,qid:number) => {
        if(userAnswers){
            let index = userAnswers.findIndex(x => x.questionId === qid)
            if(index !== -1){
                updateSelectedQuestions(value,index,true);
                if(userAnswers[index].freeTextAnswer && userAnswers[index].freeTextAnswer !== ""){
                    setIsSubmitDisable(false);
                }
                else {
                    setIsSubmitDisable(true);
                }                
            }                       
        }
    }

    const updateSelectedQuestions = (value:any,index:number,isFreeText:boolean) => {
        if(userAnswers){
            let answers = [...userAnswers];
            let currentQuestion = answers[index];
            if(isFreeText){                
                currentQuestion.freeTextAnswer = value;                
            }
            else{                
                currentQuestion.optionIds = value;
            }
            answers[index] = currentQuestion;
            setUserAnswers(answers);        
        }        
    }

    const handleSubmitClick = async (e:any) => {
        if(surveyData){
            if(surveyData.questions.length == currentIndex+1){  
                let obj:IOpenSurveyRequest = {
                    surveyId:surveyData.id,
                    openSurveyStatusId: statusId,
                    answers:userAnswers
                }
                 setStatusId(0); 
                 setLoading(true);                                                                                    
                 await completeSurvey(obj);                                                       
            }
            else{
                setCurrentIndex(currentIndex+1);
                setIsSubmitDisable(true);
            }
        }
    }
    
    const onHandlecAPTCHA=(value: string  | null)=>{
        setIsVerified(true);
    }

    const RedirectToDashboard = () => {
        history.push("/dashboard")
    }

    return (
        <>
            <div className="position-relative min-vh-100">
                <div className="row d-flex flex-column justify-content-center mx-3 pb-5">
                    {
                        !isUserLoggedIn() &&  <div className="d-flex justify-content-center my-5 mb-0">
                        <img alt="logo" className="cursor-pointer mn-image" src={window.location.origin + "/images/MindNavigator_latest.svg"} />
                    </div>
                    }
                     { (statusId == 0 && !isError) && <div className="container d-flex flex-column justify-content-center mt-5 col-md-8 col-lg-6 col-xl-4">
                     {
                            loading ? <div className="loader w-100 d-flex justify-content-center">
                                {loading && <LoaderComponent />}
                            </div> : surveyData && <div>                                
                                <section className="row d-flex justify-content-center">
                                  {
                                    !surveyCompleted ? 
                                        <div>
                                            <div className="d-flex justify-content-center">
                                                <HeaderComponent title={surveyData.title} size={Headers.H1} customClassName={"font-weight-600"} />                                    
                                            </div>
                                            <div className="login-panel-config">
                                                {surveyData.welcomeMessage}
                                                <div className="mt-3">
                                                    <p>We are {surveyData.companyName}.</p>
                                                    <p>{surveyData.description}</p> 
                                                </div>
                                            </div>
                                            <ReCAPTCHA className="mb-3"
                                                 sitekey="6LdgT38fAAAAAMeymbgFHZH-AeuFf9ySh2q31BnS"
                                                 onChange={onHandlecAPTCHA}
                                            />
                                            <div className="d-grid gap-3 mt-3">
                                            {
                                                showSpinner ?
                                                    <Button variant="primary" className="text-secondary" disabled>
                                                        <span className="mx-2 text-secondary">
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        </span>
                                                        Processing...
                                                    </Button>
                                                :
                                                    <Button variant="primary" 
                                                        className="font-weight-600 text-color-182C34" type="button" 
                                                        onClick={() => handleStartSurveyClick()}   
                                                        disabled={!isVerified}                                                     
                                                    >
                                                        Start Survey
                                                    </Button>
                                            }   
                                            </div>
                                        </div>  
                                    :     <div className="login-panel-config">  
                                                <div className="d-flex justify-content-center">
                                                    <HeaderComponent title="Thanks !!" size={Headers.H1} customClassName={"font-weight-600"} />                                    
                                                </div>                                          
                                            <div className="mt-3">
                                                <p>Thank you so much for taking the time out of your day to complete our short survey.</p>
                                                <p>We appreciate it so much, as your feedback will directly help us to improve our system.</p>
                                            </div>
                                            {
                                                isUserLoggedIn() && <div className="mt-4 d-flex justify-content-center">
                                                <Button className='mt-2'
                                                    color="primary background-26CAC1 font-weight-600"
                                                    onClick={RedirectToDashboard}>
                                                    <TextComponent text={'Go to Dashboard'} customClassName="text-color-182C34" />
                                                </Button>
                                            </div>
                                            }
                                        </div>                                 
                                  }                                      
                                </section>
                            </div>
                        }                        
                    </div>}
                    {
                        (statusId > 0 && surveyData && !isError) && <div className="container d-flex flex-column justify-content-center mt-5 col-md-8 col-lg-6 col-xl-10">
                        <div className="mb-3">
                            <HeaderComponent
                                title={`Survey Name: ${surveyData.title}`}
                                size={Headers.H3}
                            />
                        </div>
                        <div className="container d-flex justify-content-center mb-3">
                            <HeaderComponent
                                title={`Question ${currentIndex + 1} / ${surveyData.questions.length}`}
                                size={Headers.H3}
                            />
                        </div>
                        <div className="mb-3">
                            <ProgressBar
                                now={currentIndex + 1}
                                max={surveyData.questions.length}
                            />
                        </div>
                        <div className={"d-flex justify-content-center"}>
                            {
                                userAnswers && <OpenSurveyForm 
                                currentIndex={currentIndex} 
                                question={surveyData.questions[currentIndex]} 
                                handleMultipleClick={handleMultipleClick}
                                handleRadioClick={handleRadioClick}
                                handleTextAreaChange={handleTextAreaChange}
                                userAnswers={userAnswers}
                              />
                            }
                        </div>  
                        <div className="d-flex justify-content-end mt-3 mb-3">
                            <Button
                                variant="primary"
                                type="button"
                                disabled={isSubmitDisable}
                                onClick={(e: any) => handleSubmitClick(e)}
                            >
                                Submit Response
                            </Button>
                        </div>                
                        </div>
                    }   
                    {
                        isError && <div className="container d-flex flex-column justify-content-center mt-5 col-md-8 col-lg-6 col-xl-4">
                            <section className="row d-flex justify-content-center">
                                <div className="login-panel-config">  
                                    <div className="d-flex justify-content-center">
                                        <HeaderComponent title="Oops!!" size={Headers.H1} customClassName={"font-weight-600"} />                                    
                                    </div>                                          
                                    <div className="mt-3">
                                        <p>{errorMessage}</p>
                                    </div>
                                </div>
                            </section>
                        </div>
                    }                                     
                </div>
            </div>
        </>               
    );
}

export default OpenSurveyComponent;