import React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";

interface Iprops {
    handleClose: () => void;
    show: boolean;
    modalTitle: string;
    modalBody: string;
    successButtonText: string;
    closeButtonText: string;
    processingText:string;
    handleConfirmClick: () => void;
    isLoading?:boolean;
}

const ConfirmationModal = (props:Iprops) => {

    const {
    handleClose,
    show,
    modalTitle,
    modalBody,
    successButtonText,
    closeButtonText,
    processingText,
    handleConfirmClick,
    isLoading,
    } = props

    return (        
        <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={handleClose}                
            >
                <Modal.Header>
                    <Modal.Title className="header-text">{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <span className="body-text" dangerouslySetInnerHTML={{ __html: modalBody }}></span>
                </Modal.Body>
                <Modal.Footer>
                {
                    (isLoading && isLoading === true) ? <Button variant="primary" className="float-end text-secondary" disabled>
                    <span className="mx-2 text-secondary">
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    </span> 
                    {processingText} </Button> :
                    <Button role="button" variant="primary" className="font-weight-600 text-color-182C34" 
                        onClick={handleConfirmClick}>
                        {successButtonText}
                    </Button>
                }                
                <Button role="button" variant="secondary" className="font-weight-600 text-color-182C34" 
                            onClick={handleClose}>
                        {closeButtonText}
                </Button>
                </Modal.Footer>
        </Modal>
    );
}

export default ConfirmationModal;