import React, { CSSProperties, useEffect, useRef, useState, useContext } from 'react';
import Concept from '../../../Services/Concept.service';
import { Errors } from '../../../Utils/Enums';
import CharacteristicsComponent, { ICharacteristicViewModel } from '../Characteristics/CharacteristicsComponent';
import CharacteristicsRadioComponent from '../CharacteristicsRadio/CharacteristicsRadioComponent';
import ChatMessageShellComponent from '../ChatMessageShell/ChatMessageShellComponent';
import ChatShellComponent from '../ChatShell/ChatShellComponent';
import IconDotsComponent from '../IconDots/IconDotsComponent';
import PracticesComponent, { IPracticeViewModels } from '../Practices/PracticesComponent';
import SubmitButtonComponent from '../SubmitButton/SubmitButtonComponent';
import TextComponent from '../Text/TextComponent';
import generalChatStyles from './GeneralChatComponent.module.css'
import { isDesktop, isMobileOnly, BrowserTypes } from 'react-device-detect';
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { ThemeContext } from '../../../Contexts/ThemeContext';

export interface ITedItem {
    id: number;
    index: number;
    conceptId: number;
    text: string;
    messageType: string;
    practiceId: number;
    practiceViewModels: IPracticeViewModels[];
    requireResponse: boolean;
    enableContinue: boolean;
    characteristicId: number;
    characteristicViewModel: ICharacteristicViewModel;
    height?: CSSProperties | undefined;
    isLeft?: boolean;
    image?: string;
};

interface ITed {
    ok: boolean;
    data: ITedItem;
};

interface ITEDPracticeDetails {
    tedMessageId: number;
    height?: CSSProperties | undefined;
    item: IPracticeViewModels;
    ticked: boolean;
}

interface IGeneralChatComponent {
    startTEDWindow: boolean;
    conceptId: number;
    okState: any;
    conceptDetails: any
    link: string;
    isQuiz?: boolean;
    onUpNextHandler: () => void;
    onTedPracticeDetailsHandler?: (tedMessageId: number, item: IPracticeViewModels, ticked: boolean) => void;
}

const GeneralChatComponent = (props: IGeneralChatComponent) => {

    // const { conceptDetails } = props;
    let arrPracticesLocal: number[] = [];
    let tedCounterValidator: number = 0;
    let tedResult: ITed;
    const conceptService = new Concept();

    const [teds, setTeds] = useState<ITedItem[]>([]);
    const [freeText, setFreeText] = useState(true);
    const [tedParams, setTedParams] = useState({ tedMessageId: 0, userResponse: "", practices: [0], messageType: "", characteristics: [] as number[] });
    const [tedCounter, setTedCounter] = useState(0);
    const [answer, setAnswer] = useState<ITedItem>();
    const [, setArrPractices] = useState<number[]>([]);
    const [tedStarted, setTedStarted] = useState(false);
    const [tedEnded, setTedEnded] = useState(true);

    const { transcript, resetTranscript } = useSpeechRecognition();
    const [isListening, setIsListening] = useState(false);
    const { theme } = useContext(ThemeContext);

    const disableIndicator = () => {
        let dotsIndicator: any;
        dotsIndicator = document.getElementById("dotsIndicator");
        if (dotsIndicator) {
            dotsIndicator && dotsIndicator.classList.add('d-none');
        }
    }

    const enableIndicator = () => {
        let dotsIndicator: any;
        dotsIndicator = document.getElementById("dotsIndicator");
        if (dotsIndicator) {
            dotsIndicator && dotsIndicator.classList.remove('d-none');
        }
    }

    useEffect(() => {
        setIsListening(false);
        //setSpeechRecognition(false);
        getData();
    }, [tedCounter]);

    const upNextHandler = async () => {
        if (teds.find(x => x.enableContinue)) {
            if (teds.find(x => x.messageType === "Practice")) {
                await conceptService.tedUserResponseForPractice(Number(teds.find(x => x.messageType === "Practice")?.id), []);
            }
            props.onUpNextHandler();
        }
    }

    /* Get Teds */
    const getTed = async () => {
        setFreeText(true);
        enableIndicator();
        let itemHeight;
        let userPicture: string;
        tedResult = await conceptService.getTed(props.conceptId, tedCounter);

        if (!tedResult.ok)
            return setTeds([]);

        const getUserDetails = () => {
            let userDetails = localStorage.getItem("userConfig");
            if (userDetails) {
                let parsedUserDetails = JSON.parse(userDetails);
                userPicture = parsedUserDetails.profileImg;
                tedResult.data.image = userPicture;
            }
        }
        getUserDetails();

        if (tedResult.data.messageType === 'Quote') {
            itemHeight = calculateFreeTextHight(tedResult.data.text) + 3.5;
        } else if (tedResult.data.messageType === 'Statement') {
            if (isMobileOnly && (window.innerHeight > window.innerWidth)) {
                itemHeight = calculateFreeTextHight(tedResult.data.text) + 2;
            } else {
                itemHeight = calculateFreeTextHight(tedResult.data.text);
                itemHeight += 2;
            }

            let elStatement = document.querySelectorAll('.statement-category');
            if (elStatement) {
                setTedFormNode('.statement-category', 'statement-category');  //tedCounter
                let elStatementList = Array.from(document.getElementsByClassName('statement-category'));
                elStatementList.forEach((element, idx, array) => {
                    if (idx === array.length - 1) {
                        element.id = "statement-" + String(tedCounter);
                        let elText = element.getElementsByClassName("chatMessageText")[0];
                        elText.innerHTML = tedResult.data.text;
                    }
                });
            }
        } else if (tedResult.data.messageType === 'Practice') {
            setTimeout(function () {
                setTedEnded(false);
            }, 3000);

            let numberOfCharsPerLine;
            let basisPracticeHeight;
            let numberOfCharsPerInnerLine = 0;
            if (isMobileOnly && (window.innerHeight > window.innerWidth)) {
                numberOfCharsPerLine = 14;
                basisPracticeHeight = 9.4375;
                numberOfCharsPerInnerLine = 14;
            } else {
                numberOfCharsPerLine = 30;
                basisPracticeHeight = 7.4375;
                numberOfCharsPerInnerLine = 21;
            }

            const titlePracticeHeight: number = Math.ceil(tedResult.data.text.length / numberOfCharsPerLine);
            const contentHeight = tedResult.data.practiceViewModels.map(x => {
                if (x.title) {
                    return Math.ceil(x.title.length / numberOfCharsPerInnerLine) * 26 / 16;
                }
                return 0;
            })
            if (contentHeight) {
                itemHeight = titlePracticeHeight + contentHeight.reduce((a, b) => a + b, 0) + basisPracticeHeight;
            }

        } else if (tedResult.data.messageType === 'CharacteristicsChoice') {
            const titleHeight: number = calculateFreeTextHight(tedResult.data.text) + 4.625;
            let basisCharsHeight = 6.4375;
            let numberOfpracticeViewModels = tedResult && tedResult.data.characteristicViewModel.characteristicOptionViewModel && tedResult.data.characteristicViewModel.characteristicOptionViewModel.length;
            if (numberOfpracticeViewModels)
                if (isMobileOnly && (window.innerHeight > window.innerWidth)) {
                    itemHeight = basisCharsHeight + titleHeight + numberOfpracticeViewModels * 2.63 * 1.115;
                } else {
                    itemHeight = basisCharsHeight + titleHeight + numberOfpracticeViewModels * 2.63;
                }
        } else if (tedResult.data.messageType === 'CharacteristicsOptionChoice') {
            const titleHeight: number = calculateFreeTextHight(tedResult.data.text) + 2.5;
            let basisPracticeHeight = 3.875;
            let numberOfpracticeViewModels = tedResult && tedResult.data.characteristicViewModel.characteristicOptionViewModel && tedResult.data.characteristicViewModel.characteristicOptionViewModel.length;
            if (numberOfpracticeViewModels)
                itemHeight = basisPracticeHeight + numberOfpracticeViewModels * 3 + titleHeight;
        } else if (tedResult.data.messageType === 'TedForms') {
            tedResult.data.isLeft = false;
            setTedFormNode('.tedformQuestion', 'tedformQuestion');
            itemHeight = calculateFreeTextHight(tedResult.data.text);
            disableIndicator();
            setFreeText(false);
        }

        setinnerWindowHeight(itemHeight);
        tedResult.data.height = { height: itemHeight + 'em' };
        if (tedResult.data.messageType === 'TedForms') {
            doDOMCorrection();
        }
        setTeds(teds => [...teds, tedResult.data]);
        moveTedUp(tedResult.data);

        generalDOMOrderCorrection()
    }

    const pushToDOMArray = (category: string, arrCategories: string[], id: string) => {
        /* Creating utility array of names of Ids of elements in DOM */
        let varCategory = document.getElementsByClassName(category);
        if (varCategory && varCategory[0]) {
            let categoryIndex = Number(varCategory[0].id.split('-')[1]);
            if (categoryIndex !== 0) {
                for (var x = arrCategories.length; x < categoryIndex; x++) {
                    arrCategories.push('tedForm-' + x);
                }
                arrCategories.push(id + categoryIndex);
            } else {
                arrCategories.push(id + arrCategories.length);
            }
        }
        /* Iterating the array and order the elements */
        let ongoingElement = document.getElementById(arrCategories[0]);
        for (let x = 1; x < arrCategories.length; x++) {
            let previousElement = ongoingElement;
            ongoingElement = document.getElementById(arrCategories[x])
            if (ongoingElement) {
                if (arrCategories[x].split('-')[0] === 'tedForm') {
                    let ongoingAnswer = document.getElementById('tedFormAnswer-' + arrCategories[x].split('-')[1]);
                    if (ongoingAnswer) {
                        ongoingElement?.after(ongoingAnswer);
                    }
                } else {
                    if (arrCategories[x - 1].split('-')[0] === 'tedForm') {
                        let previousAnswer = document.getElementById('tedFormAnswer-' + (x - 1));
                        previousAnswer?.after(ongoingElement);
                    } else {
                        previousElement?.after(ongoingElement);
                    }
                }
            }
        }
        varCategory = document.getElementsByClassName("tedformQuestion");
        if (varCategory && varCategory[0] && varCategory.length === 1 && varCategory[0].classList.contains('d-none')) {
            varCategory[0].id = 'tedForm-undefined'
        }
    }
    const generalDOMOrderCorrection = () => {
        var a = document.getElementById('tedFormAnswer-undefined');
        if (tedResult.data.messageType === 'CharacteristicsOptionChoice') {
            let b = document.getElementById('CharacteristicsOptionChoiceBlock');
            if (a && b) a.after(b);
        }
        if (tedResult.data.messageType === 'CharacteristicsChoice') {
            let b = document.getElementById('CharacteristicsChoice');
            if (a && b) a.after(b);
        }

        let innerWindow: any;
        //eslint-disable-next-line
        innerWindow = document.getElementById('inner-window');
        let arrCategories: string[] = [];
        pushToDOMArray('quote-category', arrCategories, 'quote-');
        pushToDOMArray('statement-category', arrCategories, 'statement-');
        pushToDOMArray('CharacteristicsChoice-category', arrCategories, 'characteristicsChoice-');
        pushToDOMArray('CharacteristicsOptionChoiceBlock-category', arrCategories, 'characteristicsOptionChoiceBlock-');
        pushToDOMArray('practice-category', arrCategories, 'practice-');
    }

    if (props.startTEDWindow) {
        if (!tedStarted) {
            getTed();
            setTedStarted(true);
        }
    }

    const getData = async () => {
        if (tedCounter > 0) {
            disableIndicator();
            if (tedParams.messageType === 'Quote' || tedParams.userResponse === "''" || tedParams.messageType === 'TedForms') {
                await conceptService.tedUserResponse(tedParams.tedMessageId, tedParams.userResponse);
                if (teds[teds.length - 1].enableContinue) {
                    return setTedEnded(false);
                }
            } else if (tedParams.messageType === 'Practice' && tedParams.tedMessageId) {

            } else if (tedParams.messageType === 'CharacteristicsChoice') {
                await conceptService.tedUserResponseForCharacteristic(tedParams.characteristics, tedParams.tedMessageId);
                if (teds[teds.length - 1].enableContinue) {
                    setTedEnded(false);
                    return;
                }
            }

            tedCounterValidator = tedCounter;
            if (tedParams.messageType !== 'Practice') getTed();
        }
    }

    /* Post Quote */
    const postTedHandler = async (id: number, userResponse: string, messageType: string) => {
        setTedParams({ tedMessageId: id, userResponse: userResponse, practices: [], messageType: messageType, characteristics: [] });
        setTedCounter(tedCounter + 1);
    }

    /* Post Characteristics */
    const postTedCharsHandler = async (data: number[], tedMessageId: number) => {
        setTedParams({ tedMessageId: tedMessageId, userResponse: "", practices: [], messageType: 'CharacteristicsChoice', characteristics: data });
        setTedCounter(tedCounter + 1);
    }

    /* Post Practice */
    //eslint-disable-next-line
    const postTedPracticeHandler = async (arrPracticesLocal: number[], tedMessageId: number) => {
        await conceptService.tedUserResponseForPractice(tedMessageId, arrPracticesLocal);
    }

    /* add to Practice Array*/
    const addTedPracticeArrayHandler = async (tedMessageId: number, practiceId: number, messageType: string, ticked: boolean) => {
        let selectedHeart: any = document.getElementById("img-" + practiceId);
        if (selectedHeart) {
            if (theme === "light") {
                if (ticked) {
                    selectedHeart.src = window.location.origin + '/images/favourite-filled.svg';
                } else {
                    selectedHeart.src = window.location.origin + '/images/favourite-empty.svg';
                }
            } else {
                if (ticked) {
                    selectedHeart.src = window.location.origin + '/images/favourite-filled.svg';
                } else {
                    selectedHeart.src = window.location.origin + '/images/favourite-empty-dark.svg';
                }
            }
        }

        let selectedDetailHeart: any = document.getElementById("practiceItem" + practiceId);
        if (selectedDetailHeart) {
            if (theme === "light") {
                if (ticked) {
                    selectedDetailHeart.src = window.location.origin + '/images/favourite-filled.svg';
                } else {
                    selectedDetailHeart.src = window.location.origin + '/images/favourite-empty.svg';
                }
            } else {
                if (ticked) {
                    selectedDetailHeart.src = window.location.origin + '/images/favourite-filled.svg';
                } else {
                    selectedDetailHeart.src = window.location.origin + '/images/favourite-empty-dark.svg';
                }
            }
        }


        arrPracticesLocal = [];
        let valPractice = arrPracticesLocal.find(x => x === Number(practiceId));
        if (valPractice) {
            arrPracticesLocal = arrPracticesLocal.filter(x => x !== valPractice);
        } else {
            arrPracticesLocal.push(Number(practiceId))
        }
        setArrPractices(arrPracticesLocal);
        postTedPracticeHandler(arrPracticesLocal, tedMessageId);
    }

    const onTedPracticeDetailsShowHandler = (tedMessageId: number, item: IPracticeViewModels, ticked: boolean) => {
        props.onTedPracticeDetailsHandler && props.onTedPracticeDetailsHandler(tedMessageId, item, ticked);
    }

    /* Set dynamic height of inner window */
    const setinnerWindowHeight = (itemHeight: number | undefined) => {
        const innerWindow = document.getElementById('inner-window');
        let currentHeight = innerWindow?.offsetHeight;
        if (innerWindow && itemHeight && currentHeight) {
            if (currentHeight > 534)
                innerWindow.style.height = currentHeight + itemHeight * 16 + "px";
            setTimeout(function () {
                innerWindow.scrollIntoView({ behavior: "smooth", block: "end" });
                disableIndicator();
            }, 1000);
        }
    }

    /* Crete free text node */
    const setTedFormNode = (className1: string, className2: string) => {
        let tedFormElement = document.querySelector(className1);
        if (tedFormElement) {
            let newTedForm: Node = tedFormElement.cloneNode(true);
            let innerWindow: any;
            innerWindow = document.getElementById('inner-window');
            innerWindow.appendChild(newTedForm);
            var elements = document.getElementsByClassName(className2);
            elements[0].classList.remove('d-none');
        }
    }

    const doDOMCorrection = () => {
        let innerWindow: any;
        innerWindow = document.getElementById('inner-window');
        if (innerWindow) {
            let lastQuestion = innerWindow.lastChild;
            if (lastQuestion.classList.contains('d-none')) {
                lastQuestion.id = "tedForm-undefined";
                if (teds && teds.length > 0) {
                    let currentQuestion = document.getElementById("tedForm-" + String(teds[teds.length - 1].index));
                    if (currentQuestion) {
                        let innerElement = currentQuestion.getElementsByClassName("chatMessageText")[0];
                        if (innerElement) innerElement.innerHTML = tedResult.data.text;
                        currentQuestion.id = "tedForm-" + String(tedResult.data.index);
                    } else {
                        let currentQuestion = document.getElementsByClassName('tedformQuestion');
                        if (currentQuestion && currentQuestion[0]) {
                            let innerElement = currentQuestion[0].getElementsByClassName("chatMessageText")[0];
                            if (innerElement) innerElement.innerHTML = tedResult.data.text;
                            let innerheight = currentQuestion[0].children[0];
                            let numberOfCharsPerLine;
                            let textLineHeight;
                            if (isMobileOnly && (window.innerHeight > window.innerWidth)) {
                                numberOfCharsPerLine = 22;
                                textLineHeight = 32;
                            } else {
                                numberOfCharsPerLine = 36;
                                textLineHeight = 26;
                            }
                            innerheight.setAttribute("style", "height:" + (calculateFreeTextHight(tedResult.data.text) + 2) + 'em');
                            return;
                        }
                    }
                }
            } else {
                let innerElement = innerWindow.lastChild.getElementsByClassName("chatMessageText")[0];
                let numberOfCharsPerLine;
                if (innerElement) innerElement.innerText = tedResult.data.text;
                innerWindow.lastChild.id = tedResult.data.index;
                let innerheight = innerWindow.lastChild.getElementsByClassName("height-question-config");
                if (isMobileOnly && (window.innerHeight > window.innerWidth)) {
                    numberOfCharsPerLine = 22;
                } else {
                    numberOfCharsPerLine = 36;
                }
                var itemHeight = calculateFreeTextHight(tedResult.data.text);
                let addingExtra = tedResult.data.text.length > 400 ? 64 : 32;
                innerheight[0].style.height = String(calculateFreeTextHight(tedResult.data.text) * 16 + addingExtra) + "px";
                return;
            }
        }
    }

    const moveTedUp = (tedData: ITedItem) => {
        setTimeout(function () {
            //enableIndicator();
        }, 3000);

        setTimeout(function () {
            if (tedCounterValidator === tedCounter) {
                if (tedData.messageType === 'Quote' || tedData.messageType === 'Statement') {
                    postTedHandler(tedData.id, "''", tedData.messageType);
                    if (tedData.messageType === 'Quote') {
                        let quateHeart: any = (document.getElementById(tedResult.data.text));
                        if (quateHeart) {
                            //quateHeart.style.pointerEvents = "none";
                            quateHeart.classList.remove('cursor-pointer');
                        }
                    }
                } else if (tedData.messageType === 'Practice' && document.querySelectorAll(".practice-details") === null) {
                    postTedHandler(tedData.id, "''", tedData.messageType);
                }
            }
        }, 6000);
    };

    /* Adding free text answer */
    const sendDataHandler = async (data: string) => {
        let userDetails = localStorage.getItem("userConfig");
        let userPicture;
        if (userDetails) {
            let parsedUserDetails = JSON.parse(userDetails);
            userPicture = parsedUserDetails.profileImg;
        }
        if (teds) {
            let tempTedItem: any = { ...teds.at(-1) };
            if (tempTedItem) {
                var itemHeight = calculateFreeTextHight(data);
                setinnerWindowHeight(itemHeight);
                setTedFormNode('.tedformAnswer', 'tedformAnswer');
                let question = document.getElementById("tedForm-" + String(tempTedItem.index));
                tempTedItem.isLeft = true;
                tempTedItem.text = data;
                tempTedItem.image = userPicture;

                tempTedItem.height = { height: itemHeight + 'em' };
                let innerWindow: any;
                innerWindow = document.getElementById('inner-window');

                if (innerWindow) {
                    let lastQuestion = innerWindow.lastChild;
                    if (lastQuestion.classList.contains('d-none')) {
                        lastQuestion.id = "tedFormAnswer-undefined";
                        if (teds) {
                            if (question) {
                                let currentAnswer = question.nextElementSibling;
                                if (currentAnswer) {
                                    let innerElement = currentAnswer.getElementsByClassName("chatMessageText")[0];
                                    if (innerElement) innerElement.innerHTML = data;
                                    currentAnswer.id = "tedFormAnswer-" + String(tempTedItem.index);
                                }
                            }
                        }
                        setAnswer(tempTedItem);
                    } else {
                        let innerElement = innerWindow.lastChild.getElementsByClassName("chatMessageText")[0];
                        if (innerElement) innerElement.innerText = tempTedItem.text;
                        innerWindow.lastChild.id = "tedFormAnswer-" + tempTedItem.index;
                    }
                }
                if (teds.length < 2) {
                    setAnswer(tempTedItem);
                }

                let answer = document.getElementById("tedForm-" + String(tempTedItem.index));


                if (answer) question?.after(answer);
                postTedHandler(tempTedItem.id, data, tempTedItem.messageType);
                let elAnswer = document.getElementById("tedFormAnswer-" + tempTedItem.index);
                if (elAnswer) {
                    elAnswer.children[0].setAttribute("style", "height:" + itemHeight + 'em');
                }
                moveTedUp(tempTedItem);
                if (tempTedItem.enableContinue) return setFreeText(true);

            }
        }
    };

    /* Calculate free text hight */
    const calculateFreeTextHight = (text: string) => {
        var textToCalc: string = text;
        var spacesHight = 48 //paddings from top and bottom (16*2) and one margin (16*1)
        var numberOfCharsPerLine: number;
        var stringForTest = "";
        var splittedExpression = text.split(' ');
        var sumPerLine: number = 0;
        var numberOfLines: number = 1;

        if (isMobileOnly && (window.innerHeight > window.innerWidth)) {
            numberOfCharsPerLine = 22
            spacesHight = 64
            for (var x = 0; x < splittedExpression.length; x++) {
                if (splittedExpression[x].length > 15) numberOfLines += 1;
                if (((sumPerLine + splittedExpression[x].length) > numberOfCharsPerLine) && x < (splittedExpression.length - 1)) {
                    numberOfLines += 1;
                    sumPerLine = splittedExpression[x].trim().length + 1;
                    stringForTest = splittedExpression[x] + " ";
                } else {
                    if ((sumPerLine + splittedExpression[x].length) <= numberOfCharsPerLine) {
                        sumPerLine += splittedExpression[x].length + 1;
                        stringForTest += splittedExpression[x] + " ";
                    } else {
                        sumPerLine += (splittedExpression[x].length);
                        stringForTest += (splittedExpression[x]);
                    }
                }
            }
            if (sumPerLine <= numberOfCharsPerLine) {
                numberOfLines += 1;
            }
        } else {
            numberOfCharsPerLine = 34;
            for (var x = 0; x < splittedExpression.length; x++) {
                if (splittedExpression[x].length > 15) numberOfLines += 1;
                if (((sumPerLine + splittedExpression[x].length) > numberOfCharsPerLine) && x < (splittedExpression.length - 1)) {
                    numberOfLines += 1;
                    sumPerLine = splittedExpression[x].trim().length + 1;
                    stringForTest = splittedExpression[x] + " ";
                } else {
                    if ((sumPerLine + splittedExpression[x].length) <= numberOfCharsPerLine) {
                        sumPerLine += splittedExpression[x].length + 1;
                        stringForTest += splittedExpression[x] + " ";
                    } else {
                        sumPerLine += (splittedExpression[x].length);
                        stringForTest += (splittedExpression[x]);
                    }
                }
            }
            if (sumPerLine <= numberOfCharsPerLine) {
                numberOfLines += 1;
            }
        }

        const textLineHeight = 26;
        const numberOfSpaces = (textToCalc.match(/<p>/g) || []).length; //Number of free lines <p></p>
        return (numberOfLines * textLineHeight + spacesHight + numberOfSpaces) / 16 + 1; //to make it em


    }

    const handleKeypress = (e: any) => {
        if (e.code === 'Enter' || e.key === 'Enter' || e.keyCode === 13) {
            clickHandler(e.target.value);
        }
        resetTranscript();
    };


    const clickHandler = (dataVal: string) => {
        if (dataVal && dataVal.length > 0) {
            sendDataHandler(dataVal);
            let el: any;
            el = document.getElementsByClassName('inputComponent')[0];
            el.value = '';
            setFreeText(true);
        }
    }

    const speechRecognitionStart = () => {
        setIsListening(true);
        SpeechRecognition.startListening({
            continuous: false,
        });
    }
    const speechRecognitionStop = () => {
        setIsListening(false);
        SpeechRecognition.stopListening();
        clickHandler(transcript);
    }


    return <>
        <ChatShellComponent customClass={'position-relative'}>
            <div className={`${generalChatStyles.warningHeight} ${!props.okState.data && "alert alert-warning"} ${isDesktop && "position-absolute"} top-0 w-100`} role="alert">
                {!props.okState.data && <TextComponent text={Errors.SomethingWentWrong} />}
            </div>
            <div className={`${isDesktop && "position-absolute"} w-100 overflow-y-auto ${generalChatStyles.marginTopShellMain}`}>
                <div id='inner-window'
                    className={`mt-5 d-flex justify-content-end flex-column ${generalChatStyles.chatScreenConfigThinkIntro}`}

                >
                    {teds && teds.find(x => x.messageType === 'Quote') &&
                        <div className={`position-relative context-quote quote-category`}
                            id={`quote-${teds.find(x => x.messageType === 'Quote')?.index}`}>
                            <div className="position-relative" style={teds.find(x => x.messageType === 'Quote')?.height}>
                                <div className={`position-absolute chatmessageShellConfig`} >
                                    <ChatMessageShellComponent
                                        text={teds.find(x => x.messageType === 'Quote')?.text}
                                        icon={'Ted.png'}
                                        badge={theme === "light" ? "Heart.png" : "favourite-empty-dark.svg"}
                                        id={teds.find(x => x.messageType === 'Quote')?.id}
                                        index={teds.find(x => x.messageType === 'Quote')?.index}
                                        conceptId={teds.find(x => x.messageType === 'Quote')?.conceptId}
                                        messageType={teds.find(x => x.messageType === 'Quote')?.messageType}
                                        practiceId={teds.find(x => x.messageType === 'Quote')?.practiceId}
                                        practiceViewModels={teds.find(x => x.messageType === 'Quote')?.practiceViewModels}
                                        requireResponse={teds.find(x => x.messageType === 'Quote')?.requireResponse}
                                        enableContinue={teds.find(x => x.messageType === 'Quote')?.enableContinue}
                                        onTedShellHeartResponseHandler={postTedHandler} />
                                </div>
                            </div>
                        </div>}
                    {teds && teds.find(x => x.messageType === 'Statement') &&
                        <div className={`position-relative statement-category context-quote`}
                            id={`statement-${teds.find(x => x.messageType === 'Statement')?.index}`}>
                            <div className="position-relative" style={teds.find(x => x.messageType === 'Statement')?.height}>
                                <div className={`position-absolute chatmessageShellConfig`} >
                                    <ChatMessageShellComponent
                                        text={teds.find(x => x.messageType === 'Statement')?.text}
                                        icon={'Ted.png'}
                                        id={teds.find(x => x.messageType === 'Statement')?.id}
                                        index={teds.find(x => x.messageType === 'Statement')?.index}
                                        conceptId={teds.find(x => x.messageType === 'Statement')?.conceptId}
                                        messageType={teds.find(x => x.messageType === 'Statement')?.messageType}
                                        practiceId={teds.find(x => x.messageType === 'Statement')?.practiceId}
                                        practiceViewModels={teds.find(x => x.messageType === 'Statement')?.practiceViewModels}
                                        requireResponse={teds.find(x => x.messageType === 'Statement')?.requireResponse}
                                        enableContinue={teds.find(x => x.messageType === 'Statement')?.enableContinue}
                                    />
                                </div>
                            </div>
                        </div>}
                    {teds && teds.find(x => x.messageType === 'CharacteristicsChoice') &&
                        <div
                            id={`characteristicsChoice-${teds.find(x => x.messageType === 'CharacteristicsChoice')?.index}`}
                            className={`position-relative CharacteristicsChoice-category context-quote`}>
                            <div className="position-relative" style={teds.find(x => x.messageType === 'CharacteristicsChoice')?.height}>
                                <div className={`position-absolute chatmessageShellConfig`} >
                                    <CharacteristicsComponent
                                        characteristicOptionViewModel={teds.find(x => x.messageType === 'CharacteristicsChoice')?.characteristicViewModel.characteristicOptionViewModel}
                                        text={teds.find(x => x.messageType === 'CharacteristicsChoice')?.text}
                                        id={teds.find(x => x.messageType === 'CharacteristicsChoice')?.characteristicViewModel.id}
                                        image={'Ted.png'}
                                        tedMessageId={teds.find(x => x.messageType === 'CharacteristicsChoice')?.characteristicViewModel.tedMessageId}
                                        onTedCharsHandler={postTedCharsHandler}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    {teds && teds.find(x => x.messageType === 'CharacteristicsOptionChoice') &&
                        <div
                            id={`characteristicsOptionChoiceBlock-${teds.find(x => x.messageType === 'CharacteristicsOptionChoice')?.characteristicViewModel.id}`}
                            className={`position-relative CharacteristicsOptionChoiceBlock-category context-quote`}>
                            <div className="position-relative" style={teds.find(x => x.messageType === 'CharacteristicsOptionChoice')?.height}>
                                <div className={`position-absolute chatmessageShellConfig`} >
                                    <CharacteristicsRadioComponent
                                        characteristicOptionViewModel={teds.find(x => x.messageType === 'CharacteristicsOptionChoice')?.characteristicViewModel.characteristicOptionViewModel}
                                        text={teds.find(x => x.messageType === 'CharacteristicsOptionChoice')?.characteristicViewModel.text}
                                        image={'Ted.png'}
                                        id={teds.find(x => x.messageType === 'CharacteristicsOptionChoice')?.characteristicViewModel.id}
                                        tedMessageId={teds.find(x => x.messageType === 'CharacteristicsOptionChoice')?.characteristicViewModel.tedMessageId}
                                        onTedCharsHandler={postTedCharsHandler}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    {teds && <div
                        className={`position-relative context-quote tedformQuestion d-none`}
                        id={"tedForm-" + String(teds && teds[0]?.index)}>
                        <div className="position-relative height-question-config" style={teds && teds[0]?.height}>
                            <div className={`position-absolute chatmessageShellConfig`}>
                                <ChatMessageShellComponent
                                    text={teds && teds[0]?.text}
                                    icon={(teds && teds[0]?.image) ?? 'Ted.svg'}
                                    id={teds && teds[0]?.id}
                                    index={teds && teds[0]?.index}
                                    conceptId={teds && teds[0]?.conceptId}
                                    messageType={teds && teds[0]?.messageType}
                                    practiceId={teds && teds[0]?.practiceId}
                                    practiceViewModels={teds && teds[0]?.practiceViewModels}
                                    requireResponse={teds && teds[0]?.requireResponse}
                                    enableContinue={teds && teds[0]?.enableContinue}
                                    onTedShellHeartResponseHandler={postTedHandler}
                                    left={false} />
                            </div>
                        </div>
                    </div>}
                    <div
                        className={`position-relative context-quote tedformAnswer d-none`}
                        id={"tedFormAnswer-" + String(answer && answer.index)}>
                        <div className="position-relative" style={answer && answer.height}>
                            <div className={`position-absolute chatmessageShellConfig`}>
                                <ChatMessageShellComponent
                                    text={answer && answer.text}
                                    icon={answer && answer.image?.replace('/api', '')}
                                    id={answer && answer.id}
                                    index={answer && answer.index}
                                    conceptId={answer && answer.conceptId}
                                    messageType={answer && answer.messageType}
                                    practiceId={answer && answer.practiceId}
                                    practiceViewModels={answer && answer.practiceViewModels}
                                    requireResponse={answer && answer.requireResponse}
                                    enableContinue={answer && answer.enableContinue}
                                    onTedShellHeartResponseHandler={postTedHandler}
                                    left={true} />
                            </div>
                        </div>
                    </div>
                    {teds && teds.find(x => x.messageType === 'Practice') &&
                        <div
                            id="practiceBlock"
                            className={`position-relative practice-category context-quote`}>
                            <div className="position-relative" style={teds.find(x => x.messageType === 'Practice')?.height}>
                                <div className={`position-absolute chatmessageShellConfig`} >
                                    <PracticesComponent
                                        title={String(teds.find(x => x.messageType === 'Practice')?.text)}
                                        text={teds.find(x => x.messageType === 'Practice')?.text}
                                        icon={'Ted.png'}
                                        badge={theme === "light" ? "Heart.png" : "favourite-empty-dark.svg"}
                                        id={teds.find(x => x.messageType === 'Practice')?.id}
                                        index={teds.find(x => x.messageType === 'Practice')?.index}
                                        conceptId={teds.find(x => x.messageType === 'Practice')?.conceptId}
                                        messageType={teds.find(x => x.messageType === 'Practice')?.messageType}
                                        practiceId={teds.find(x => x.messageType === 'Practice')?.practiceId}
                                        practiceViewModels={teds.find(x => x.messageType === 'Practice')?.practiceViewModels}
                                        requireResponse={teds.find(x => x.messageType === 'Practice')?.requireResponse}
                                        enableContinue={teds.find(x => x.messageType === 'Practice')?.enableContinue}
                                        onTedPracticeHandler={addTedPracticeArrayHandler}
                                        onTedPracticeDetailsHandler={onTedPracticeDetailsShowHandler}
                                    />
                                </div>
                            </div>
                        </div>}
                </div>
            </div>
            <div className="position-absolute bottom-0 w-100" id="shell-footer">
                <div className="height-34 margin-bottom-4">
                    <div id="dotsIndicator" className={`d-flex justify-content-center w-100 d-none`} >
                        <IconDotsComponent />
                    </div>
                </div>
                <div className="input-group align-self-end input-group-config">
                    <input
                        type="text"
                        className="form-control text-color-848A8C inputComponent"
                        placeholder={freeText ? '' : 'Answer'}
                        aria-label="Recipient's username"
                        aria-describedby="microphoneGeneralChat"
                        disabled={freeText}
                        onKeyUp={handleKeypress}
                    />



                    {SpeechRecognition.browserSupportsSpeechRecognition() && !isListening && BrowserTypes.Chrome && !freeText && <span className="input-group-text background-E0E7EB border-CED4DA cursor-pointer" id="microphoneGeneralChatStart" onClick={speechRecognitionStart}>
                        <img src={window.location.origin + '/images/Microphone.png'} alt="Microphone start" id="" />
                    </span>}
                    {SpeechRecognition.browserSupportsSpeechRecognition() && isListening && BrowserTypes.Chrome && !freeText && <span className="input-group-text background-10B9F4 border-CED4DA cursor-pointer" id="microphoneGeneralChatEnd" onClick={speechRecognitionStop}>
                        <img src={window.location.origin + '/images/Microphone.png'} alt="Microphone stopped" />
                    </span>}
                </div>
            </div>
        </ChatShellComponent>

        <div onClick={upNextHandler}>
            <SubmitButtonComponent
                label={"Up Next"}
                customClassName={'btn-primary-custom'}
                id={'tedConceptSubmit'}
                disabled={tedEnded} />
        </div>
    </>
}
export default GeneralChatComponent