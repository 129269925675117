import { IOpenSurveyRequest, IOpenSurveyResponse } from "../interfaces/OpenSurvey/IOpenSurvey";
import { IStartSurveyRequest, IStartSurveyResponse } from "../interfaces/OpenSurvey/IStartSurvey";
import Config from "../Utils/Config";
class OpenSurvey {

    async getSurveyDetail(code:string) {
        try{
            const response = await fetch(Config.openSurvey+`?str=`+code,{
                method:'GET',
                headers:{
                    "accept": "text/plain",
                    'Content-Type': 'application/json'
                }
            });
            if(!response.ok){
                return {data:null,message:"Some thing is wrong",status:false}
            }
            else{
                const result = await response.json();
                if(result){
                    return result as IOpenSurveyResponse;
                }
                else{
                    return {data:null,message:"Some thing is wrong",status:false}
                }
            }
        }
        catch(error){
            return {data:null,message:"Some thing is wrong",status:false}
        }
    }

    async startSurvey(request:IStartSurveyRequest){
        try{
            const response = await fetch(Config.startSurvey,{
                method:'POST',
                headers:{
                    "accept": "text/plain",
                    'Content-Type': 'application/json'
                },
                body:JSON.stringify(request)
            });
            if(!response.ok){
                return {data:0,message:"Some thing is wrong",status:false}
            }
            else{
                const result = await response.json();
                if(result){
                    return result as IStartSurveyResponse;
                }
                else{
                    return {data:0,message:"Some thing is wrong",status:false}
                }
            }
        }
        catch(error){
            return {data:0,message:"Some thing is wrong",status:false}
        }
    }

    async completeSurvey(request:IOpenSurveyRequest){
        try{
            const response = await fetch(Config.completeSurvey,{
                method:'POST',
                headers:{
                    "accept": "text/plain",
                    'Content-Type': 'application/json'
                },
                body:JSON.stringify(request)
            });
            if(!response.ok){
                return {data:null,message:"Some thing is wrong",status:false}
            }
            else{
                const result = await response.json();
                if(result){
                    return result as IStartSurveyResponse;
                }
                else{
                    return {data:null,message:"Some thing is wrong",status:false}
                }
            }
        }
        catch(error){
            return {data:null,message:"Some thing is wrong",status:false}
        }
    }
}

export default OpenSurvey