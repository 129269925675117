import React from "react";
import { Headers } from "../../../Utils/Enums";
import HeaderComponent from "../Header/HeaderComponent";
import UserScoreTableComponent from "../UserScoreTableComponent/UserScoreTableComponent";

interface Iprops {
    providerScoreData: any[];
    personalScore: boolean;
}

function ProviderScoreComponent(props: Iprops) {
    const { providerScoreData } = props;
    
    return (
        <>
            <div className="mt-5 mb-5">
                <div className="d-flex flex-sm-row flex-column justify-content-between align-items">
                    <div className="d-flex">
                        <HeaderComponent title="PROVIDERS SCORES" size={Headers.H3} />
                    </div>
                </div>
                <div>
                    <UserScoreTableComponent
                        providerScoreData={providerScoreData}
                        personalScore={false}
                    />
                </div>
            </div>
        </>
    );
}

export default ProviderScoreComponent;