import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import General from "../../../Services/General.service";
import Quotes from "../../../Services/Quotes.service";
import { Headers } from "../../../Utils/Enums";
import { createNotification } from "../../../Utils/Toast";
import HeaderComponent from "../../FunctionalComponents/Header/HeaderComponent";
import LibraryFilterComponent from "../../FunctionalComponents/LibraryFilterComponent/LibraryFilterComponent";
import LibraryTableComponent from "../../FunctionalComponents/LibraryTableComponent/LibraryTableComponent";
import PaginationComponent from "../../FunctionalComponents/Paginator/PaginationComponent";
import TEDModalComponent from "../../FunctionalComponents/TEDModal/TEDModalComponent";
import { logOutIfTokenExpire } from '../../../Utils/Utils';
import { UserContext } from "../../../Contexts/UserContext";
import { ThemeContext } from "../../../Contexts/ThemeContext";
import "../../../darkcustom.css"

interface IquoteLibraryData {
    author: string;
    dateAdded: string;
    id: number;
    isFavourite: boolean;
    quoteText: string;
    source: string
}

interface IquoteData {
    tedQuoteId: number;
    isLike: boolean
}
function QuotesLibraryComponent() {
    const generalService = new General();
    generalService.getIcons();
    const quote = new Quotes();
    let history = useHistory();
    const [quoteTableData, setQuoteTableData] = useState<IquoteLibraryData[]>([]);
    const [searchParam, setSearchParam] = useState<string>("");
    const [pageSize, setPageSize] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const userContext = useContext(UserContext);
    const { theme } = useContext(ThemeContext)

    useEffect(() => {
        userContext.dispatch('');
    }, [])
    useEffect(() => {
        logOutIfTokenExpire();
        getQuoteLibraryList(false);
        //eslint-disable-next-line
    }, [currentPage, pageSize]);

    const getQuoteLibraryList = async (isFilter: boolean) => {
        let response: any = await quote.getQuoteLibraryList(searchParam, isFilter ? 1 : currentPage, pageSize);
        if (response.status) {
            setPageSize(response.data.pagination.pageSize);
            setCurrentPage(response.data.pagination.currentPage);
            setTotalItems(response.data.pagination.totalItems);
            setTotalPages(response.data.pagination.totalPages);
            let libraryQuotes: any = response.data.items.map((data: any) => {
                return {
                    author: data.author,
                    dateAdded: data.dateAdded.split("T")[0],
                    id: data.id,
                    isFavourite: data.isFavourite,
                    quoteText: data.quote,
                    source: data.source,
                }
            });
            setQuoteTableData(libraryQuotes);
        } else {
            setQuoteTableData([]);
        }
    }

    const onPageHandler = (id: number) => {
        setCurrentPage(id);
    }

    const likeQuote = async (id: any, type: string, text: string) => {
        if (type === "Concept") {
            let response: any = await quote.saveConceptQuote(Number(id), 'True');
            if (response.status) {
                createNotification("success", response.message);
                getQuoteLibraryList(false);
            } else {
                createNotification("error", response.message || "Something went wrong");
            }
        } else {
            let quoteData: IquoteData = {
                tedQuoteId: Number(id),
                isLike: true
            }
            let response: any = await quote.saveQuote(quoteData);
            if (response.status) {
                createNotification("success", response.message);
                getQuoteLibraryList(false);
            } else {
                createNotification("error", response.message || "Something went wrong");
            }
        }
    }
    const handleQuoteLibraryListSearch = async () => {
        if (currentPage == 1) {
            await getQuoteLibraryList(true);
        }
        else {
            setCurrentPage(1);
        }
    }

    return (
        <>
            <div className="container-fluid row col-sm-12">
                {theme === "light" ? (
                    <div className="d-flex align-items-center">
                        <img onClick={() => history.push("/dashboard")}
                            className="text-decoration-none cursor-pointer" alt="dashboard"
                            src={window.location.origin + "/images/chevron-left.svg"} />
                        <span onClick={() => history.push("/dashboard")} className="text-decoration-none cursor-pointer">
                            <HeaderComponent
                                title="Quotes Library"
                                size={Headers.H2}
                            />
                        </span>
                    </div>
                ) : <div className="d-flex align-items-center">
                        <img onClick={() => history.push("/dashboard")}
                            className="text-decoration-none cursor-pointer" alt="dashboard"
                            src={window.location.origin + "/images/left-chevron-dark.svg"} />
                        <span onClick={() => history.push("/dashboard")} className="text-decoration-none cursor-pointer">
                            <div style={{ marginLeft: "15px" }}>
                                <HeaderComponent
                                    title="Quotes Library"
                                    size={Headers.H2}
                                />
                            </div>
                        </span>
                    </div>}

                <div className="mt-3 d-flex flex-row justify-content-between align-items-center col-xl-6 col-lg-8 col-md-12">
                    <LibraryFilterComponent
                        setSearchParam={setSearchParam}
                        handleQuoteLibraryListSearch={handleQuoteLibraryListSearch}
                        page={"quote"}
                    />
                </div>
                <div className="mt-3">
                    <div className="d-flex justify-content-between">
                        <HeaderComponent
                            title="All Quotes"
                            size={Headers.H2}
                        />
                        {theme === "light" ? (
                            <Link to={"/myquotes"} className="text-decoration-none border-bottom border-dark">
                                <HeaderComponent
                                    title="Favourites"
                                    size={Headers.H3}
                                />
                            </Link>
                        ) : <Link to={"/myquotes"} className="text-decoration-none practiceLibraryTextColor">
                                <HeaderComponent
                                    title="Favourites"
                                    size={Headers.H3}
                                />
                            </Link>}
                    </div>
                    <LibraryTableComponent
                        quoteTableData={quoteTableData}
                        resourceTableData={[]}
                        likeQuote={likeQuote}
                    />
                </div>
                {quoteTableData.length > 0 &&
                    <div className='d-flex justify-content-end mb-3'>
                        <PaginationComponent
                            currentPage={currentPage}
                            pageSize={pageSize}
                            totalItems={totalItems}
                            totalPages={totalPages}
                            onPageHandler={onPageHandler}
                        />
                    </div>
                }
            </div>
            <div>
                <TEDModalComponent />
            </div>
        </>
    );
}

export default QuotesLibraryComponent;