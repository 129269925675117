import React, { useState, useContext } from "react";
import CircleImageComponent from "../CircleImage/CircleImageComponent";
import TextComponent from "../Text/TextComponent";
import multiChecksStyles from "./CharacteristicsComponent.module.css";
import { ThemeContext } from "../../../Contexts/ThemeContext";

export interface ICharacteristicOptionViewModel {
    characteristicId: number;
    value: string;
    id: number;
}
export interface ICharacteristicViewModel {
    tedMessageId?: number;
    text?: string;
    id?: number;
    image?: string;
    characteristicOptionViewModel?: ICharacteristicOptionViewModel[];
    onTedCharsHandler: (index: number[], tedMessageId: number) => void;
}
const CharacteristicsComponent = (props: ICharacteristicViewModel) => {
    const [checkedCghars, setCheckedChars] = useState(0)
    const { theme } = useContext(ThemeContext);
    const addToListOfChars = (event: any) => {
        let checkboxes = document.querySelectorAll(".charsItems");
        let checkedCount = 0;
        checkboxes.forEach((x: any) => {
            if (x.checked)
                checkedCount++;
        })
        setCheckedChars(checkedCount);
        if (checkedCount > 2) {
            checkboxes.forEach((x: any) => {
                if (x.checked === false)
                    x.style.pointerEvents = "none";
            });
        } else {
            checkboxes.forEach((x: any) => {
                x.style.pointerEvents = "auto";
            });
        }
    }
    const clickHandler = () => {
        /* Disable button after submi */
        let tempObject: any;
        tempObject = document.getElementById('charsSubmit');
        tempObject.disabled = true;

        /* Disable all the checkboxes after submit */
        let arrSelected: number[] = [];
        let checkboxes = document.querySelectorAll(".charsItems");
        checkboxes.forEach((x: any) => {
            x.style.pointerEvents = "none";
            if (x.checked)
                arrSelected.push(Number(x.id));

        });
        props.onTedCharsHandler(arrSelected, Number(props.tedMessageId));
    }
    return <>
        {theme === "light" ? (
            <>
                <div className={`filter-156-156-156-025 text-color-2D5362 background-FFFFFF d-flex flex-column right-message margin-bottom-16`}>
                    <div className={`${multiChecksStyles['arrowLeft']} position-absolute color-FFFFFF`}>
                        <div className="position-relative">
                            <div className={`${multiChecksStyles['tedConfigRight']} position-absolute`}>
                                <CircleImageComponent size={'imageSize36'} image={"Ted.png"} />
                            </div>
                        </div>
                    </div>
                    {props.text && <TextComponent text={props.text} customClassName={'chatMessageText'} />}
                    {props.text && <TextComponent text={'(Select 3 core values)'} customClassName={'chatMessageText'} />}
                </div>

                <div className={`filter-156-156-156-025 text-color-2D5362 background-FFFFFF d-flex flex-column left-message margin-bottom-16`}>

                    {props.text && <TextComponent text={'Select 3 core values'} customClassName={'chatMessageText font-weight-600'} />}
                    {props.characteristicOptionViewModel && <div className='d-flex flex-column margin-top-8'>
                        {props.characteristicOptionViewModel.map((item, index: number) =>
                            <div key={index} className="d-flex margin-top-16">
                                <div>
                                    <input
                                        type="checkbox" id={String(item.id)}
                                        name={String(item.id)}
                                        value={String(item.id)}
                                        onClick={addToListOfChars}
                                        className="charsItems"
                                        role="checkbox"
                                    />
                                </div>
                                <label htmlFor={String(item.id)} className="margin-left-8">
                                    <TextComponent text={item.value} customClassName={'text-color-2D5362'} />
                                </label>
                            </div>
                        )}
                    </div>}
                    <button
                        id="charsSubmit"
                        className={`btn btn-primary background-26CAC1 font-weight-600 border-E0E7EB w-75 margin-top-16`}
                        type="button"
                        onClick={clickHandler}
                        disabled={checkedCghars !== 3}
                        role="button">
                        <TextComponent text={"Submit"} customClassName={'text-color-182C34'} />
                    </button>
                    <div className={`${multiChecksStyles['arrowRight']} position-absolute color-FFFFFF`}>
                        <div className="position-relative">
                            <div className={`${multiChecksStyles['tedConfigLeft']} position-absolute`}>
                                <CircleImageComponent size={'imageSize36'} image={String(props.image)} />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        ) : <>
            <div style={{ background: "rgb(28, 41, 48)" }} className={`filter-156-156-156-025 text-color-2D5362 background-FFFFFF d-flex flex-column right-message margin-bottom-16`}>
                <div style={{ color: "#1c2930" }} className={`${multiChecksStyles['arrowLeft']} position-absolute color-FFFFFF`}>
                    <div className="position-relative">
                        <div className={`${multiChecksStyles['tedConfigRight']} position-absolute`}>
                            <CircleImageComponent size={'imageSize36'} image={"Ted.png"} />
                        </div>
                    </div>
                </div>
                {props.text && <TextComponent text={props.text} customClassName={'chatMessageText'} />}
                {props.text && <TextComponent text={'(Select 3 core values)'} customClassName={'chatMessageText'} />}
            </div>

            <div style={{ backgroundColor: "#1c2930" }} className={`filter-156-156-156-025 text-color-2D5362 background-FFFFFF d-flex flex-column left-message margin-bottom-16`}>

                {props.text && <TextComponent text={'Select 3 core values'} customClassName={'chatMessageText font-weight-600'} />}
                {props.characteristicOptionViewModel && <div className='d-flex flex-column margin-top-8'>
                    {props.characteristicOptionViewModel.map((item, index: number) =>
                        <div key={index} className="d-flex margin-top-16">
                            <div>
                                <input
                                    type="checkbox" id={String(item.id)}
                                    name={String(item.id)}
                                    value={String(item.id)}
                                    onClick={addToListOfChars}
                                    className="charsItems"
                                    role="checkbox"
                                />
                            </div>
                            <label htmlFor={String(item.id)} className="margin-left-8">
                                <TextComponent text={item.value} customClassName={'text-color-2D5362'} />
                            </label>
                        </div>
                    )}
                </div>}
                <button
                    id="charsSubmit"
                    className={`btn btn-primary background-26CAC1 font-weight-600 border-E0E7EB w-75 margin-top-16`}
                    type="button"
                    onClick={clickHandler}
                    disabled={checkedCghars !== 3}
                    role="button">
                    <TextComponent text={"Submit"} customClassName={'text-color-182C34'} />
                </button>
                <div style={{ color: "#1c2930" }} className={`${multiChecksStyles['arrowRight']} position-absolute color-FFFFFF`}>
                    <div className="position-relative">
                        <div className={`${multiChecksStyles['tedConfigLeft']} position-absolute`}>
                            <CircleImageComponent size={'imageSize36'} image={String(props.image)} />
                        </div>
                    </div>
                </div>
            </div>
        </>}
    </>
}
export default CharacteristicsComponent;