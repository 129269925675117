import React from 'react';
import "./CardComponent.css";
import { Headers } from '../../../Utils/Enums';
import HeaderComponent from '../Header/HeaderComponent';
import TextComponent from '../Text/TextComponent';

interface ICard {
    title: string;
    header?: string;
    body?: string;
    footer?: string;
    link?: string;
    image?: string;
}
const CardComponent = (props: ICard) => {
    return <>
        <div className="col card-config">
            <div className=" h-100">
                {props.header && <div className="card-header bg-transparent basic-color fw-bold d-flex justify-content-between">
                    <div>{props.title}</div>
                    <div className="counter">0</div>
                </div>}
                <div>
                    {props.image && <img className="card-img-top" src={`${window.location.origin + '/images/' + props.image}`} alt="card" />}
                    <div className="card-body">
                        <div className="header-config">
                            {props.title && <HeaderComponent
                                title={props.title}
                                size={Headers.H2}
                            />}
                            {props.body && <TextComponent text={props.body} />}
                            {props.link && <div className="text"><small className="text-muted">Last updated 3 mins ago</small></div>}
                        </div>
                    </div>


                </div>
                {props.footer && <div className="card-footer bg-transparent border-success d-none">Footer</div>}
            </div>
        </div>
    </>
}

export default CardComponent;