import React, { useContext, useEffect, useState } from 'react';
import HeaderComponent from '../../FunctionalComponents/Header/HeaderComponent';
import { Headers } from '../../../Utils/Enums';
import ChatMessageShellComponent from '../../FunctionalComponents/ChatMessageShell/ChatMessageShellComponent';
import ChatShellComponent from '../../FunctionalComponents/ChatShell/ChatShellComponent';
import ContentShellComponent from '../../FunctionalComponents/ContentShell/ContentShellComponent';
import InputGroupComponent from '../../FunctionalComponents/InputGroup/InputGroupComponent';
// import SubmitButtonComponent from '../../FunctionalComponents/SubmitButton/SubmitButtonComponent';
import doStyles from './DoComponent.module.css';
import TextComponent from '../../FunctionalComponents/Text/TextComponent';
import DoService from '../../../Services/Do.service';
import { Link, useHistory } from 'react-router-dom';
import IconDotsComponent from '../../FunctionalComponents/IconDots/IconDotsComponent';
import ButtonComponent from '../../FunctionalComponents/Button/ButtonComponent';
import Dashboard from '../../../Services/Dashboard.service';
import General from '../../../Services/General.service';
import { logOutIfTokenExpire } from '../../../Utils/Utils';
import { UserContext } from '../../../Contexts/UserContext';
import { ThemeContext } from "../../../Contexts/ThemeContext";
import "../../../darkcustom.css"

const DoComponent = (props: any) => {
    const generalService = new General();
    const dashboard = new Dashboard();
    const isFromDashboard = props?.location?.state?.isFromDashboard;
    let history = useHistory();
    const doService = new DoService();
    const [disabled, setDisabled] = useState(true);
    const [image, setImage] = useState('');
    const [answer, setAnswer] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [removePlaceHolder, setRemovePlaceHolder] = useState(false);
    const userContext = useContext(UserContext);
    const { theme } = useContext(ThemeContext)
    let dotsIndicator: any;
    let parsedUserDetails: any;

    useEffect(() => {
        logOutIfTokenExpire();
        userContext.dispatch('');
        let startText: any;
        setTimeout(() => {
            //eslint-disable-next-line
            dotsIndicator = document.getElementById("dotsIndicatorDo");
            startText = document.getElementById("startText");
            dotsIndicator && dotsIndicator.classList.add('d-none');
            startText && startText.classList.remove('d-none');
        }, 2000);
        getData();

    }, []);

    const getData = async () => {
        let userImg = localStorage.getItem("ProfileImg") || "";
        let result: any = await dashboard.getProgramState();
        localStorage.setItem("selectedStage", 'Stage 1');
        localStorage.setItem("selectedTab", 'Awareness');
        setImage(userImg);
    }

    const sendDataHandler = async (data: string) => {
        if (data === "") return setErrorMessage('Please, type or tell something');
        if (data !== "") {
            setAnswer(data);
            const result = await doService.addIntention(data);
            if (!result.ok) return setErrorMessage('Something went wrong');
            if (result.ok) {
                setRemovePlaceHolder(true);
                dotsIndicator = document.getElementById("dotsIndicatorDo");
                dotsIndicator && dotsIndicator.classList.remove('d-none');
                setTimeout(() => {
                    dotsIndicator && dotsIndicator.classList.add('d-none');
                    setDisabled(false);
                }, 2000);
                setErrorMessage('');
            }
        }
    };

    const submitHandler = async (event: any) => {
        event.preventDefault();
        const result = await doService.introComplete();
        if (!result.ok) return setErrorMessage('Something went wrong');
        if (result.ok) {
            return history.push('/dashboard');
        }
    }

    return <>
        <div>
            <div className='d-flex justify-content-between'>
                <Link to={isFromDashboard ? "/dashboard" : "/engage/-1"} className="text-decoration-none">
                    {theme === "light" ? (
                        <HeaderComponent
                            title={"Setting your Intention"}
                            size={Headers.H1}
                            prefix={"Introduction: Do"}
                            icon={'chevron-left.svg'}
                        />
                    ) : <HeaderComponent
                            title={"Setting your Intention"}
                            size={Headers.H1}
                            prefix={"Introduction: Do"}
                            icon={'left-chevron-dark.svg'}
                        />}
                </Link>
            </div>
            <div className="margin-top-24 margin-bottom-78">
                <ContentShellComponent>
                    <div className={`d-xl-inline-flex justify-content-between ${doStyles.doOutConfig}`}>
                        <ContentShellComponent customClass={'leftSideConfig'}>
                            <TextComponent text={"An intention is a proactive statement that empowers you to take meaningful action. Your intention is the thing that drives your every day. It’s what you choose to focus on."} />
                            <br />
                            <div className="fw-bold margin-bottom-8">
                                <TextComponent text={"The ingredients of a great intention statement are:"} />
                            </div>
                            <div className="margin-bottom-24">
                                <ul>
                                    <li>
                                        <TextComponent text={"It’s all about you – it hands you the control and responsibility for your life"} />
                                    </li>
                                    <li>
                                        <TextComponent text={"It’s all about now – it’s not about a future you, it’s what you commit to practice now"} />
                                    </li>
                                    <li>
                                        <TextComponent text={"It’s all about process – goals focus on outcomes, intentions focus your mind and guide your commitment to practicing something meaningful"} />
                                    </li>
                                    <li>
                                        <TextComponent text={"It’s always proactive – it's a positive statement that inspires what you do and how you do it "} />
                                    </li>
                                </ul>
                                <div>
                                    Alternatively, you can set a ‘word’ intention.
                                <br />
                                    A word intention acts as a compass or ‘north star’ to guide how you show up, your decisions and actions.  It can also serve to focus your attention in moments of challenge.
                                </div>
                                <br />
                            </div>
                            {theme === "light" ? (<div className="background-EFF8F7 padding-16">
                                <div className="fw-bold margin-bottom-8">
                                    <TextComponent text={"Examples of Intentions"} />
                                </div>
                                <ul>
                                    <li>
                                        <TextComponent text={"I am willing to practice being kind to myself."} />
                                    </li>
                                    <li>
                                        <TextComponent text={"I am committed to creating more simplicity in my day."} />
                                    </li>
                                    <li>
                                        <TextComponent text={"I intend to lead by example."} />
                                    </li>
                                    <li>
                                        <TextComponent text={"I am focused on setting and maintaining healthy boundaries."} />
                                    </li>
                                    <li>
                                        <TextComponent text={"I am willing to practice not taking things personally."} />
                                    </li>
                                    <li>
                                        <TextComponent text={"I am focused on developing deeper relationships."} />
                                    </li>
                                </ul>
                            </div>
                            ) : <div style={{ backgroundColor: "#28373E" }} className="background-EFF8F7 padding-16">
                                    <div className="fw-bold margin-bottom-8">
                                        <TextComponent text={"Examples of Intentions"} />
                                    </div>
                                    <ul>
                                        <li>
                                            <TextComponent text={"I am willing to practice being kind to myself."} />
                                        </li>
                                        <li>
                                            <TextComponent text={"I am committed to creating more simplicity in my day."} />
                                        </li>
                                        <li>
                                            <TextComponent text={"I intend to lead by example."} />
                                        </li>
                                        <li>
                                            <TextComponent text={"I am focused on setting and maintaining healthy boundaries."} />
                                        </li>
                                        <li>
                                            <TextComponent text={"I am willing to practice not taking things personally."} />
                                        </li>
                                        <li>
                                            <TextComponent text={"I am focused on developing deeper relationships."} />
                                        </li>
                                    </ul>
                                </div>}
                        </ContentShellComponent>
                        <ContentShellComponent customClass={'rightSideConfig'}>
                            <ChatShellComponent>
                                {errorMessage !== "" && <div className="mb-4">
                                    <div className="alert alert-warning" role="alert">
                                        <TextComponent text={errorMessage} />
                                    </div>
                                </div>}
                                <div className={`h-100 position-relative chat-screen-config ${doStyles.chatScreenConfigDo}`}>
                                    <div className={`position-absolute chatmessageShellConfig d-none`} id='startText'>
                                        <ChatMessageShellComponent
                                            id={0}
                                            text={`Let’s set your intention. What are you willing to commit to or focus on? `}
                                            icon={"Ellipse 306.svg"} />
                                        {!disabled && <ChatMessageShellComponent
                                            id={0}
                                            text={answer}
                                            left={true}
                                            icon={image} />
                                        }
                                        {!disabled &&
                                            <ChatMessageShellComponent
                                                id={0}
                                                text={`Great! Your intention will be displayed on your Dashboard as a reminder and you can revise it at any time.`}
                                                icon={"Ellipse 306.svg"}
                                            />
                                        }
                                    </div>

                                </div>
                                <div className="height-34 margin-bottom-4">
                                    <div id="dotsIndicatorDo" className={`d-flex justify-content-center w-100`} >
                                        <IconDotsComponent />
                                    </div>
                                </div>
                                {disabled && <InputGroupComponent
                                    placeholder={'Answer'}
                                    disabled={false}
                                    onValidationHandler={sendDataHandler}
                                    maxLength={150} />}
                                {!disabled && <InputGroupComponent
                                    placeholder={removePlaceHolder ? '' : 'Answer'}
                                    disabled={true}
                                    onValidationHandler={sendDataHandler} />}
                            </ChatShellComponent>
                            <form onSubmit={submitHandler}>
                                <ButtonComponent
                                    label={"Continue"}
                                    customClassName={'btn-primary-custom'}
                                    id={'thinkIntroSubmit'}
                                    disabled={disabled}
                                />
                            </form>
                        </ContentShellComponent>
                    </div>
                </ContentShellComponent>
            </div>
        </div>
    </>
}
export default DoComponent;
