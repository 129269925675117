import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import BadgeComponent from '../Badge/BadgeComponent';
import TextComponent from '../Text/TextComponent';
import "./ConceptItemComponent.css";
import { ThemeContext } from "../../../Contexts/ThemeContext";
import "../../../darkcustom.css"

export interface IConceptItem {
    id: number;
    status?: number;
    title: string;
    userConceptStatusId: number;
    shortDescription: string;
    icon: string;
}
interface IConceptItemComponent {
    item: IConceptItem;
    customClass: string;
    moduleId: string;
    moduleStepId: string;
    index: string | number;
}
const ConceptItemComponent = (props: IConceptItemComponent) => {
    const { theme } = useContext(ThemeContext);
    return <>
        <div className="border-bottom-EBEBEB-thin d-flex flex-column justify-content-center item-inner-content-config">
            <div className="d-flex">
                <div className="d-flex flex-column">
                    <div className="wrapBadge">
                        <TextComponent
                            text={props.item.title}
                            customClassName={"fw-bold text-color-212529"} />
                    </div>
                    <div className="d-flex">
                        <div>
                            <img src={`${process.env.REACT_APP_BASE_URL}/file?fileName=` + props.item.icon} alt='concept' className="margin-left-6" />
                        </div>
                        <div className="margin-bottom-8 margin-left-8">
                            <TextComponent
                                text={props.item.shortDescription}
                            />
                        </div>
                        <div className="margin-bottom-8 margin-left-8 width-32 d-flex">
                            {(props.item.userConceptStatusId !== 0 || props.index === "0" || props.index === 0) &&
                                <Link
                                    to={{
                                        pathname: `${"/concept/" + props.item.id + "/" + props.moduleStepId + "/" + props.moduleId}`,
                                        state: {
                                            conceptName: props.item.title,
                                            conceptImage: props.item.icon,
                                            conceptShortDescription: props.item.shortDescription,
                                            conceptId: props.item.id,
                                            moduleId: props.moduleId,
                                            moduleStepId: props.moduleStepId,
                                            isFromModule: true
                                        }
                                    }}
                                    className="text-decoration-none"
                                >
                                {theme === "light" ? (
                                    <img
                                        src={window.location.origin + '/images/chevron-right.svg'}
                                        alt="chevron-right"
                                        className="iconPopover align-self-center" />
                                ) : <img
                                        src={window.location.origin + '/images/right-chevron-dark.svg'}
                                        alt="chevron-right"
                                        className="iconPopover align-self-center" />}
                                </Link>}
                        </div>
                    </div>
                </div>
            </div>
            {props.item.userConceptStatusId === 2 && <div className="wrapBadge">
                <BadgeComponent title={"Complete"} customClass={props.customClass} />
            </div>}
        </div>
    </>
}
export default ConceptItemComponent;
