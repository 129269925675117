import {isTokenExpired,clearLocalStorageAndRedirect, getAuthToken} from "../Utils/Utils";

class General {
    getIcons() {
        let iconsPanel = document.getElementById('icons-panel');
        if (iconsPanel) iconsPanel!!.classList.remove("d-none");
    }
    async getProfileIntention() {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Profile/intention`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            });
            if (!response.ok) {
                return { data: "error", ok: false };
            } else {
                const result = await response.json();
                if (result) {
                    return { data: result, ok: true };
                } else {
                    return { data: "error", ok: false };
                }
            }
        } catch (error) {
            return { data: error, ok: false };
        }
    };
}
export default General;