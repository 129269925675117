import { IChangePasswordAndConfirmEmail } from "../interfaces/User/IChangePasswordAndConfirmEmail";
import constant from "../Utils/Config";
import {isTokenExpired,clearLocalStorageAndRedirect,getAuthToken} from "../Utils/Utils";
class TermNCondition {

    async acceptEULA() {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${constant.acceptEULA}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    };

    async isEULAAccepted() {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${constant.isEULAAccepted}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return window.location.href="/login";
                });
            return response;
        }        
    };

    async changeAndConfirmedEmail(newPassword:string){
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            try{
                const response = await fetch(constant.changePasswordAndConfirmEmail,{
                    method:'POST',
                    headers:{
                        "accept": "text/plain",
                        'Content-Type': 'application/json',
                        "Authorization": getAuthToken()
                    },
                    body:JSON.stringify(newPassword)
                });
                if(!response.ok){
                    return {data:null,message:"Some thing is wrong",status:false}
                }
                else{
                    const result = await response.json();
                    return result as IChangePasswordAndConfirmEmail;
                }
            }
            catch(error){
                return {data:null,message:"Some thing is wrong",status:false}
            }
        }
    }
}

export default TermNCondition;
