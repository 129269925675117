import React, { useContext } from 'react';
import { ISearchItem } from '../../../Services/Engage.service';
import CircleImageComponent from '../CircleImage/CircleImageComponent';
import EmailLinkComponent from '../EmailLink/EmailLinkComponent';
import PopoverSearchComponent from '../PopoverSearch/PopoverSearchComponent';
import TextComponent from '../Text/TextComponent';
import './SearchContactItemComponent.css';
import { ThemeContext } from "../../../Contexts/ThemeContext";

interface ISearchContactItemComponent {
    item: ISearchItem;
    search?: string;
    canAddContacts: boolean;
    onAddContactHandler: (data: string) => void;
}
const SearchContactItemComponent = (props: ISearchContactItemComponent) => {
    const { theme } = useContext(ThemeContext);
    const addContactHandler = (data: string) => {
        props.onAddContactHandler(data);
    };
    return <>
        <div className="listItemConfig w-100 border-bottom-EBEBEB-thin d-flex flex-column justify-content-center">
            <div className="d-flex justify-content-between search-content-config-lg">
                <div className="d-flex">
                    <div className="margin-left-8">
                        <CircleImageComponent size={'imageSize49'} image={props.item.profilePictureLocation ? props.item.profilePictureLocation.replace('/api', "") : props.item.profilePictureLocation} />
                    </div>
                    <div className={`d-flex flex-column justify-content-center margin-left-8 font-weight-600`}>
                        <TextComponent text={props.item.firstName + " " + props.item.lastName} />
                    </div>
                </div>
                <div className="d-flex">
                    <div className="margin-right-20 d-flex flex-column justify-content-center">
                        <div className="width-300">
                            <EmailLinkComponent mail={props.item.email} title={props.item.email} />
                        </div>
                    </div>
                    <div className="margin-right-20 d-flex flex-column justify-content-center width-24">
                        {props.item.isContact &&
                            <div>
                                {theme === "light" ? (
                                    <img src={window.location.origin + '/images/check.svg'} alt="check" />
                                ) : <img src={window.location.origin + '/images/RightMark@2x.svg'} alt="check" />}
                            </div>
                        }
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                        <PopoverSearchComponent
                            url={"/profile/" + props.item.id + "/" + props.search + "/engage"}
                            canAddContacts={props.canAddContacts}
                            item={props.item}
                            onAddContactHandler={addContactHandler}
                        />
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-between search-content-config-md">
                <div className="d-flex">
                    <div className="margin-left-8">
                        <CircleImageComponent size={'imageSize49'} image={props.item.profilePictureLocation} />
                    </div>
                    <div className="d-flex flex-column">
                        <div className={`d-flex justify-content-start margin-left-8 font-weight-600`}>
                            <TextComponent text={props.item.firstName + " " + props.item.lastName} />
                            {props.item.isContact && <div className="margin-left-8">
                                {theme === "light" ? (
                                    <img src={window.location.origin + '/images/check.svg'} alt="check" />
                                ) : <img src={window.location.origin + '/images/RightMark@2x.svg'} alt="check" />}
                            </div>}
                        </div>
                        <div className="margin-left-8 d-flex justify-content-start">
                            <div className="width-200">
                                <EmailLinkComponent mail={props.item.email} title={props.item.email} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex">
                    <div className="d-flex flex-column justify-content-center">
                        <PopoverSearchComponent
                            url={"/profile/" + props.item.id + "/" + props.search + "/engage"}
                            item={props.item}
                            onAddContactHandler={addContactHandler}
                            canAddContacts={props.canAddContacts}
                        />
                    </div>
                </div>
            </div>
        </div>
    </>
}
export default SearchContactItemComponent;