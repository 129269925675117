import { ItedLogBookData } from "../components/FunctionalComponents/TEDJournalComponents/TEDJournalEntryTabelComponent";
import constant from "../Utils/Config";
import {isTokenExpired,clearLocalStorageAndRedirect, getAuthToken} from "../Utils/Utils";
interface IjournalData {
    programStageId: number,
    title: string,
    relatedCategory: {
        relatedCategoryId: number | null,
        relatedCategoryType: string
    },
    text: string
}
class TedJournal {

    //currently hardcoded 1 as a program stage id
    async getRelatedCategories() {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Lookup/GetRelatedCategories/${1}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    };

    //currently hardcoded 1 as a program stage id
    async getTedActivityLogById(row: ItedLogBookData) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        let id = Number(row.id.split('$')[0]);
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/TedJournalEntry/TedActivityLogById?Id=` + id + "&category=" + row.category.category, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            });
            if (!response.ok) {
                return { data: "error", ok: false };
            } else {
                const result = await response.json();
                if (result) {
                    return { data: result, ok: true };
                } else {
                    return { data: "error", ok: false };
                }
            }
        } catch (error) {
            return { data: error, ok: false };
        }
    };


    async tedJournalEntry(journalData: IjournalData) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/TedJournalEntry/TedJournalEntry`, {
                method: 'POST',
                body: JSON.stringify(journalData),
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    };

    async gettedJournalEntry(category: string, searchTerm: string, startDate: string, endDate: string, categoryId: number, currentPage: number, pageSize: number) {
        let params = "";
        let page;
        if (categoryId !== 0 && categoryId !== undefined) {
            if (categoryId === 1) {
                params += "&category=Concepts"
            }
            if (categoryId === 2) {
                params += "&category=Practices"
            }
            if (categoryId === 3) {
                params += "&category=Reflection"
            }
            if (categoryId === 4) {
                params += "&category=ModuleSummary"
            }
        }
        if(currentPage > 0){
            page = currentPage;
        }
        if (searchTerm !== "" && searchTerm !== undefined) {
            params += "&searchTerm=" + searchTerm
        }
        if (startDate !== "" && startDate !== undefined) {
            params += "&startDate=" + startDate
        }
        if (endDate !== "" && endDate !== undefined) {
            params += "&endDate=" + endDate
        }
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/TedJournalEntry/TedJournalEntryList?page=${page}&pageSize=${pageSize}${params}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    };

    async getTedActivityLogCategory() {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Lookup/GetTedActivityLogCategory/${1}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    };

    async getTedActivity(id: number, category: string) {
        let relatedCategory: string = "";
        if (category === "Practice") {
            relatedCategory = "Practices";
        }
        if (category === "Concept") {
            relatedCategory = "Concepts";
        }
        if (category === "Reflection") {
            relatedCategory = "Reflection";
        }
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/TedJournalEntry/TedActivityLogById?Id=${id}&category=${relatedCategory}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    };

}

export default TedJournal;