import React, { useEffect, useState, useContext } from "react";
import { TableColumn } from "react-data-table-component";
import { Button } from "reactstrap";
import TableComponent from "../../FunctionalComponents/Table/TableComponent";
import CommonModalComponent from "../../FunctionalComponents/CommonModal/CommonModalComponent";
import TextComponent from "../../FunctionalComponents/Text/TextComponent";
import { createNotification } from "../../../Utils/Toast";
import OrganisationMeeting from "../OrganisationMeeting/OrganisationMeeting";
import ProgramMeeting from "../../../Services/ProgramMeeting.service";
import {
    Form,
    Col,
    Tooltip,
    OverlayTrigger,
} from 'react-bootstrap';
import { useHistory } from "react-router";
import "./ClientAdminDashboard.module.css";
import { FACILITOR } from "../../../Utils/Roles";
import { logOutIfTokenExpire } from '../../../Utils/Utils';
import { ThemeContext } from "../../../Contexts/ThemeContext";
import "../../../darkcustom.css"

interface IMeetingColumnInformation {
    meetingName: string;
    meetingDescription: string;
    meetingProgramName: string;
    meetingModuleName: string;
    meetingDate: any;
    groupProgram: any;
    meetingGroupName: string;
    meetingDuration: number;
    meetingStartTime: string;
    meetingEndTime: string;
    meetingStatus: string;
    meetingAttendanceRemaining: any;
}

interface IUserMeetingColumnInformation {
    attendanceStatus: string;
    attendanceStatusDescription: string;
    duration: any;
    groupName: string;
    location: string;
    room: string;
    moduleName: string;
    meetingDate: string;
    startTime: string;
    endTime: string
}

interface IMeetingInformation {
    meetingId: number;
    meetingName: string;
    meetingDescription: string;
    meetingGroupId: number;
    meetingGroupName: string;
    meetingModuleId: number;
    meetingModuleName: string;
    meetingModuleStepId: number;
    meetingModuleStepName: string;
    meetingProgramId: number;
    meetingProgramName: string;
    meetingLocation: string;
    meetingRoom: string;
    meetingDate: any;
    meetingDuration: string;
    meetingStartTime: string;
    meetingEndTime: string;
    meetingStatus: string;
    meetingAttendanceRemaining: any;
}

interface IuserMeetingInformation {
    attendanceStatus: string;
    description: string;
    duration: string;
    groupId: number;
    groupName: string;
    meetingId: number;
    location: string;
    meetingDate: string;
    moduleId: number;
    moduleName: string;
    moduleStepId: number;
    moduleStepName: string;
    programId: number;
    programNumber: string;
    room: string;
    meetingStartTime: string;
    meetingEndTime: string
}

interface Igroup {
    id: number,
    name: string
}

interface Iprops {
    setLoading?: (data: boolean) => void;
    userDetails?: any;
    groups?: any;
    engageStatus?: boolean;
    userRole?: any;
}

const MeetingComponent = (props: Iprops) => {
    let { theme } = useContext(ThemeContext)
    const { setLoading, userDetails, groups, engageStatus, userRole } = props;


    let history = useHistory();
    const programMeeting = new ProgramMeeting();
    const [tableData, setTableData] = useState<IMeetingInformation[]>([]);
    const [userMeetingData, setUserMeetingData] = useState<IuserMeetingInformation[]>([]);
    const [editMeetingData, setEditMeetingData] = useState<IMeetingInformation>({
        meetingId: 0,
        meetingName: "",
        meetingDescription: "",
        meetingGroupId: 0,
        meetingGroupName: "",
        meetingModuleId: 0,
        meetingModuleName: "",
        meetingModuleStepId: 0,
        meetingModuleStepName: "",
        meetingProgramId: 0,
        meetingProgramName: "",
        meetingLocation: "",
        meetingRoom: "",
        meetingDate: "",
        meetingDuration: "",
        meetingStartTime: "",
        meetingEndTime: "",
        meetingStatus: "",
        meetingAttendanceRemaining: ""
    });
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [rowsPerpage, setRowsPerpage] = useState<number>(10);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [selectedGroup, setselectedGroup] = useState<number>(0);
    const [modalState, setModalState] = useState<boolean>(false);
    const [editData, setEditData] = useState<boolean>(false);
    const [searchStr, setSearchStr] = useState<string>("");
    // const [today, setToday] = useState<string>("");

    useEffect(() => {
        logOutIfTokenExpire();
        if (engageStatus) {
            getUserMeetings();
        } else {
            getTotalProgramMeetingData();
        }
        //eslint-disable-next-line
    }, [currentPage, rowsPerpage, selectedGroup]);

    const groupColumns: TableColumn<IMeetingColumnInformation>[] = [
        {
            name: "Module Name",
            selector: (row) => row.meetingName,
            sortable: true,
        },
        {
            name: "Group",
            selector: (row) => row.meetingGroupName,
        },
        {
            name: "Date",
            selector: (row) => row.meetingDate,

        },
        {
            name: "Timing",
            selector: (row) => `${row.meetingStartTime} - ${row.meetingEndTime}`,
        },
        {
            name: "Duration",
            selector: (row) => `${row.meetingDuration} Minutes`,
        },
        {
            name: "Action",
            cell: (row) => (
                <>
                    {
                        (row.meetingStatus === "Completed" || row.meetingStatus === "InProgress")
                            ? ((row.meetingStatus === "Completed" || row.meetingStatus == "InProgress") && row.meetingAttendanceRemaining) ?
                                <OverlayTrigger
                                    placement={"top"}
                                    overlay={
                                        <Tooltip id={`tooltip-right`}>
                                            Meeting attendance
                                        </Tooltip>
                                    }
                                >
                                    <img alt="attendence" onClick={() => handleAttendance(row)} src={window.location.origin + "/images/attandence.svg"} height={"30px"} width={"30px"} />
                                </OverlayTrigger>
                                :
                                <>
                                    {theme === "light" ? (
                                        <TextComponent
                                            text={"Completed"}
                                            customClassName="text-color-182C34 text"
                                        />
                                    ) : <TextComponent
                                        text={"Completed"}
                                        customClassName="text-color-fff text"
                                    />}

                                </>
                            : <>
                                <OverlayTrigger
                                    placement={"top"}
                                    overlay={
                                        <Tooltip id={`tooltip-right`}>
                                            Edit metting
                                        </Tooltip>
                                    }
                                >
                                    {theme === "light" ? (
                                        <img alt="edit" onClick={() => handleEditClick(row)} src={window.location.origin + "/images/edit.svg"} />
                                    ) :
                                        <img alt="edit" onClick={() => handleEditClick(row)} src={window.location.origin + "/images/edit-dark.svg"} />}
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement={"top"}
                                    overlay={
                                        <Tooltip id={`tooltip-right`}>
                                            Delete meeting
                                        </Tooltip>
                                    }
                                >
                                    {theme === "light" ? (
                                        <img alt="delete" onClick={() => handleDeleteClick(row)}
                                            src={window.location.origin + "/images/close.svg"} />
                                    ) : <img alt="delete" onClick={() => handleDeleteClick(row)}
                                        src={window.location.origin + "/images/close-dark.svg"} />}
                                </OverlayTrigger>
                            </>
                    }
                </>
            ),
            omit: engageStatus ? true : false
        },
    ];


    const userMeetingColumns: TableColumn<IUserMeetingColumnInformation>[] = [
        {
            name: "Module Name",
            selector: (row) => row.moduleName,
        },
        {
            name: "Date",
            selector: (row) => row.meetingDate,
        },
        {
            name: "Timing",
            selector: (row) => `${row.startTime} - ${row.endTime}`,
        },
        {
            name: "Duration",
            selector: (row) => `${row.duration} Minutes`,
        },

        {
            name: "Attendance Status",
            selector: (row) => row.attendanceStatusDescription,
        },

    ];

    const getTotalProgramMeetingData = async () => {
        let response: any = await programMeeting.getProgramMeeting(
            selectedGroup,
            currentPage,
            rowsPerpage,
            searchStr
        );
        setLoading && setLoading(true);
        if (response.status) {
            setLoading && setLoading(false);
            let meetingInformation: IMeetingInformation[] = [];
            setTotalPages(response.data.pagination.totalItems);
            meetingInformation = response.data.items.map((data: any) => {
                return {
                    meetingId: data.id,
                    meetingName: data.name,
                    meetingDescription: data.description,
                    meetingGroupId: data.group.id,
                    meetingGroupName: data.group.name,
                    meetingModuleId: data.module.id,
                    meetingModuleName: data.module.name,
                    meetingModuleStepId: data.moduleStep.id,
                    meetingModuleStepName: data.moduleStep.name,
                    meetingProgramId: data.program.id,
                    meetingProgramName: data.program.name,
                    meetingLocation: data.location,
                    meetingRoom: data.room,
                    meetingDate: new Date(data.meetingDate).toLocaleDateString('en-GB'),
                    meetingDuration: data.duration,
                    meetingStartTime: data.startTime,
                    meetingEndTime: data.endTime,
                    meetingStatus: data.status,
                    meetingAttendanceRemaining: data.isAttendaceRemaining
                }
            });
            setTableData(meetingInformation);
        } else {
            createNotification("error", response.message);
        }
    }

    const getUserMeetings = async () => {
        let response: any = await programMeeting.getUserProgramMeeting(currentPage, rowsPerpage);
        setLoading && setLoading(true);
        if (response.status) {
            setLoading && setLoading(false);
            setTotalPages(response.data.pagination.totalItems);
            let userMeetingInformation: IuserMeetingInformation[] = response.data.items.map((data: any) => {
                return {
                    attendanceStatus: data.attendanceStatus,
                    attendanceStatusDescription: data.attendanceStatusDescription,
                    description: data.description,
                    duration: data.duration,
                    endTime: data.endTime,
                    startTime: data.startTime,
                    groupId: data.group.id,
                    groupName: data.group.name,
                    meetingId: data.id,
                    location: data.location,
                    meetingDate: new Date(data.meetingDate).toLocaleDateString('en-GB'),
                    moduleId: data.module.id,
                    moduleName: data.module.name,
                    moduleStepId: data.moduleStep.id,
                    moduleStepName: data.moduleStep.name,
                    programId: data.program.id,
                    programNumber: data.program.name,
                    room: data.room,
                }
            });
            setUserMeetingData(userMeetingInformation);
        }
    }

    const handlePageChange = (e: any): void => {
        setCurrentPage(e);
    };

    const handleRowsPerPage = (e: any): void => {
        setRowsPerpage(e);
    };

    const handleEditClick = (row: any) => {
        setModalState(true);
        setEditData(true);
        setEditMeetingData({
            meetingId: row.meetingId,
            meetingName: row.meetingName,
            meetingDescription: row.meetingDescription,
            meetingGroupId: row.meetingGroupId,
            meetingGroupName: row.meetingGroupName,
            meetingModuleId: row.meetingModuleId,
            meetingModuleName: row.meetingModuleName,
            meetingModuleStepId: row.meetingModuleStepId,
            meetingModuleStepName: row.meetingModuleStepName,
            meetingProgramId: row.meetingProgramId,
            meetingProgramName: row.meetingProgramName,
            meetingLocation: row.meetingLocation,
            meetingRoom: row.meetingRoom,
            meetingDate: row.meetingDate,
            meetingDuration: row.meetingDuration,
            meetingStartTime: row.meetingStartTime,
            meetingEndTime: row.meetingEndTime,
            meetingStatus: row.meetingStatus,
            meetingAttendanceRemaining: row.meetingAttendanceRemaining
        });
    }

    const handleDeleteClick = async (row: any) => {
        let response: any = await programMeeting.deleteProgramMeeting(row.meetingId.toString());
        if (response.status) {
            createNotification("success", response.message);
            getTotalProgramMeetingData();
        } else {
            createNotification("error", response.message);
        }

    }

    const handleAttendance = async (row: any) => {
        let response: any = await programMeeting.getProgramMeetingAttendance(row.meetingId.toString());
        if (response.status) {
            history.push({
                pathname: "/meetingattendance",
                state: {
                    row,
                    data: response.data
                }
            });
        } else {
            createNotification("error", response.message);
        }
    }

    const handleUsersSearch = (event: any) => {
        setSearchStr(event.target.value.toString());
        if (event.keyCode === 13) {
            getTotalProgramMeetingData();
        }
    }

    return (
        <>
            <div style={{ position: "relative" }}>
                <TableComponent
                    data={engageStatus ? userMeetingData : tableData}
                    columns={engageStatus ? userMeetingColumns : groupColumns}
                    tableTitle={"Meeting List"}
                    totalRows={totalPages}
                    rowsPerpage={rowsPerpage}
                    handlePageChange={(e: any) => handlePageChange(e)}
                    handleRowsPerPage={(e: any) => handleRowsPerPage(e)}
                    isPagination={true}
                    pageAction={
                        (userRole === FACILITOR || engageStatus) ? null : <div className="d-flex">
                            {theme === "light" ? (
                                <>
                                    <div className="px-1">
                                        <Form.Group as={Col} xs={12} md={4} lg={2} controlId="formGridGroup" className="mt-1 w-100">
                                            <Form.Control type="text" placeholder="Meeting name" onKeyUp={handleUsersSearch} />
                                        </Form.Group>
                                    </div>
                                    <div className="px-1">
                                        <Form.Group as={Col} xs={12} md={4} lg={2} controlId="formGridGroup" className="mt-1 w-100">
                                            <Form.Select value={selectedGroup}
                                                onChange={(e: any) => {
                                                    setselectedGroup(Number(e.target.value));
                                                }}
                                            >
                                                <option value="0">{"Select Group"}</option>
                                                {groups?.map((group: Igroup) => <option key={group.id} value={group.id}>{group.name}</option>)}
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                </>
                            ) : <>
                                <div className="px-1">
                                    <Form.Group as={Col} xs={12} md={4} lg={2} controlId="formGridGroup" className="mt-1 w-100">
                                        <Form.Control className="clientSearchBox" type="text" placeholder="Meeting name" onKeyUp={handleUsersSearch} />
                                    </Form.Group>
                                </div>
                                <div className="px-1">
                                    <Form.Group as={Col} xs={12} md={4} lg={2} controlId="formGridGroup" className="mt-1 w-100">
                                        <Form.Select value={selectedGroup}
                                            className="clientSearchBox"
                                            onChange={(e: any) => {
                                                setselectedGroup(Number(e.target.value));
                                            }}
                                        >
                                            <option value="0">{"Select Group"}</option>
                                            {groups?.map((group: Igroup) => <option key={group.id} value={group.id}>{group.name}</option>)}
                                        </Form.Select>
                                    </Form.Group>
                                </div> </>}
                            <div className="px-1">
                                <Button color="secondary" className="font-weight-600" onClick={() => {
                                    getTotalProgramMeetingData();
                                }}>
                                    {theme === "light" ? (
                                        <TextComponent text={"Search"} customClassName="text-color-182C34" />
                                    ) : <TextComponent text={"Search"} customClassName="buttonText" />}
                                </Button>
                            </div>
                            <div className="px-1">
                                <CommonModalComponent
                                    label={editData ? "Edit Meeting" : "Create Meeting"}
                                    title={editData ? "Edit Meeting" : "Create Meeting"}
                                    onModalHandler={() => console.log("TEST")}
                                    setModalState={setModalState}
                                    modalState={modalState}
                                    modalBody={<OrganisationMeeting
                                        userDetails={userDetails}
                                        setModalState={setModalState}
                                        editData={editData}
                                        setEditData={setEditData}
                                        currentPage={currentPage}
                                        rowsPerpage={rowsPerpage}
                                        editMeetingData={editMeetingData}
                                        getTotalProgramMeetingData={getTotalProgramMeetingData}
                                    />}
                                    modalFooter={
                                        <Button role="close button"
                                            color="secondary"
                                            className="font-weight-600"
                                            onClick={() => {
                                                setModalState(false);
                                                setEditData(false)
                                            }}
                                        >
                                            {theme === "light" ? (
                                                <TextComponent text={"Close"} customClassName="text-color-182C34" />
                                            ) : <TextComponent text={"Close"} customClassName="buttonText" />}
                                        </Button>
                                    }
                                />
                            </div>
                        </div>
                    }
                />
            </div>
        </>
    );
};
export default MeetingComponent;


