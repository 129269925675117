import "./CommonModalComponent.css";
import React, { useContext } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import TextComponent from "../Text/TextComponent";
import HeaderComponent from "../Header/HeaderComponent";
import { Headers } from "../../../Utils/Enums";
import { ThemeContext } from "../../../Contexts/ThemeContext";
import "../../../darkcustom.css"

export interface IModalData {
    emailAddress: string;
}

interface IModal {
    label: string;
    customClass?: string;
    title: string;
    onModalHandler: (data: IModalData) => void;
    modalState: boolean;
    setModalState: (data: boolean) => void;
    modalBody: any;
    modalFooter: any;
}
const ModalComponent = (props: IModal) => {
    const toggle = () => props.setModalState(!props.modalState);
    const { theme } = useContext(ThemeContext)

    return (
        <>
            <div>
                <Button
                    type="button"
                    className={`btn btn-secondary border-0 font-weight-600`}
                    data-toggle="modalState"
                    data-target="#exampleModal"
                    onClick={toggle}
                >
                    <div>
                        {theme === "light" ? (
                            <TextComponent text={props.label} customClassName="text-color-182C34" />
                        ) : <TextComponent text={props.label} customClassName="buttonText" />}
                    </div>
                </Button>
                {theme === "light" ? (
                    <Modal isOpen={props.modalState} className={props.customClass} centered>
                        <ModalHeader>
                            <HeaderComponent title={props.title} size={Headers.H2} />
                        </ModalHeader>
                        <ModalBody>
                            {props.modalBody}
                        </ModalBody>
                        <ModalFooter>
                            {props.modalFooter}
                        </ModalFooter>
                    </Modal>
                ) : <Modal isOpen={props.modalState} className={props.customClass} centered>
                        <div className="modelBox">
                            <ModalHeader>
                                <HeaderComponent title={props.title} size={Headers.H2} />
                            </ModalHeader>
                            <ModalBody>
                                {props.modalBody}
                            </ModalBody>
                            <ModalFooter>
                                {props.modalFooter}
                            </ModalFooter>
                        </div>
                    </Modal>}
            </div>
        </>
    );
};
export default ModalComponent;
