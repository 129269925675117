import React, { useRef, useState } from "react";
import ErrorInputComponent from "../ErrorInput/ErrorInputComponent";
import stylesFunctionalComponents from '../FunctionalComponents.module.css';
import IsEmpty from 'validator/lib/isEmpty';

export interface IInputFieldData {
    value?: string;
    isEmptyState?: boolean;
};

interface ITextInputFieldComponent {
    placeholder?: string;
    label: string;
    error?: string;
    onValidationHandler: (data: IInputFieldData) => void;
};

const TextInputFieldComponent = (props: ITextInputFieldComponent) => {
    //eslint-disable-next-line
    const [inputError, setinputError] = useState('');
    const nameInputRef = useRef<HTMLInputElement | null>(null);

    let data = {
        isEmptyState: true,
        value: nameInputRef.current?.value
    }
    const ChangeEventHandler = (event: any) => {
        const enteredValue = String(nameInputRef.current?.value);
        data.isEmptyState = IsEmpty(enteredValue);
        data.value = enteredValue;
        props.onValidationHandler(data);

    }
    return <>
        <label className="display-none mb-1">{props.label}</label>
        <input type="text"
            role="textbox"
            ref={nameInputRef}
            placeholder={props.placeholder}
            className={`form-control input-field ${inputError.length > 0 && stylesFunctionalComponents.form_control_danger}`}
            onBlur={ChangeEventHandler} />
        <ErrorInputComponent error={inputError} />
    </>
}

export default TextInputFieldComponent;
