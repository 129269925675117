import React from 'react';
import './ErrorInputComponent.css';

interface IErrorInputComponent{
    error?: string;
};

const ErrorInputComponent=(props: IErrorInputComponent)=>{
    return <>
         <div className="text-danger settingsEmail">{props.error}</div>
    </>
}

export default ErrorInputComponent;