import constant from "../Utils/Config";
import {isTokenExpired,clearLocalStorageAndRedirect, getAuthToken} from "../Utils/Utils";
class BulkUserUpload {
    async uploadBulkUser(files: any, delimeter: string) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const formData = new FormData();
            formData.append("file", files[0])
            const response = await fetch(`${constant.bulkFileUpload}?delimeter=${delimeter}`, {
                method: 'POST',
                body: formData,
                headers: {
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                })
                .catch((error: any) => {
                    return error;
                })
            return response;
        }        
    }
}

export default BulkUserUpload;