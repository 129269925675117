import React, { useContext } from 'react';
import './EasyCardComponent.css'
import { Headers } from '../../../Utils/Enums';
import TextComponent from '../Text/TextComponent';
import HeaderComponent from '../Header/HeaderComponent';
import { Link } from 'react-router-dom';
import { ThemeContext } from "../../../Contexts/ThemeContext"

interface IEasyCard {
    title: string;
    text: string;
    badge?: string;
    link?: string;
    id?: number;
    currentUser: boolean;
    likeQuote?: (text: string, id: number) => Promise<void>;
    quoteAuthor?: string;
}
const EasyCardComponent = (props: IEasyCard) => {
    let { theme } = useContext(ThemeContext)
    
    const handleDashboardQuote = (text: string, id: any) => {
        props?.likeQuote && props?.likeQuote(text, id)

    }
    return <>
        {theme === "light" ? (
            <div className="background-EFF8F7 easyCardConfig">
                <div className="d-flex justify-content-between">
                    <HeaderComponent title={props.title} size={Headers.H3} />
                    {props.link && props.currentUser &&
                        <Link to={
                            {
                                pathname: `${props.link}`,
                                state: {
                                    isFromDashboard: true
                                }
                            }
                        }>
                            <img
                                src={window.location.origin + '/images/' + props.badge}
                                className="cursor-pointer"
                                alt="badge"
                            />
                        </Link>}
                    {!props.link &&
                        props.badge !== "" && props.currentUser && !props.title.toLowerCase().includes('intention') &&
                        <img
                            src={window.location.origin + '/images/' + props.badge}
                            className="cursor-pointer"
                            onClick={() => handleDashboardQuote(props.text, props?.id)}
                            alt="badge"
                        />}
                </div>
                <div>
                    <TextComponent text={`${props.text} ${props.quoteAuthor === undefined ? "" : `- ${props.quoteAuthor}`}`} />
                </div>
            </div>

        ) : <div style={{ backgroundColor: "#28373E" }} className="easyCardConfig">
                <div className="d-flex justify-content-between">
                    <HeaderComponent title={props.title} size={Headers.H3} />
                    {props.link && props.currentUser &&
                        <Link to={
                            {
                                pathname: `${props.link}`,
                                state: {
                                    isFromDashboard: true
                                }
                            }
                        }>
                            <img
                                src={window.location.origin + '/images/' + props.badge}
                                className="cursor-pointer"
                                alt="badge"
                            />
                        </Link>}
                    {!props.link &&
                        props.badge !== "" && props.currentUser && !props.title.toLowerCase().includes('intention') &&
                        <img
                            src={window.location.origin + '/images/' + props.badge}
                            className="cursor-pointer"
                            onClick={() => handleDashboardQuote(props.text, props?.id)}
                            alt="badge"
                        />}
                </div>
                <div>
                    <TextComponent text={`${props.text} ${props.quoteAuthor === undefined ? "" : `- ${props.quoteAuthor}`}`} />
                </div>
            </div>
        }
    </>
}
export default EasyCardComponent;