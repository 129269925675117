import { config } from "process";
import { IAuthenticateResponse } from "../interfaces/User/IAuthenticate";
import { IUserOrgnisationsResponse } from "../interfaces/User/IUserOrgnisations";
import constant from "../Utils/Config";
import {
  isTokenExpired,
  clearLocalStorageAndRedirect,
  getAuthToken,
} from "../Utils/Utils";
interface ICreateOrganisationUser {
  firstName: string;
  lastName: string;
  email: string;
  countryId: number;
  stateId: number;
  organisationId: number;
  licenseTypeIds: number[];
  groupId: number;
  roleIds: string[];
}

interface IEditOrganisationUser {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  countryId: number;
  stateId: number;
  organisationId: number;
  licenseTypeIds: number[];
  groupId: number;
  roleIds: string[];
}

interface IDeleteOrganisationUser {
  userId: string;
}
class RegisterOrganisationUser {
  async registerUser(dataRegisterOrganisationUser: ICreateOrganisationUser) {
    if (isTokenExpired()) {
      clearLocalStorageAndRedirect();
    } else {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/User/OrganisationUser`,
        {
          method: "POST",
          body: JSON.stringify(dataRegisterOrganisationUser),
          headers: {
            accept: "*/*",
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
        .then((response: any) => response.json())
        .then((response: any) => {
          return response;
        })
        .catch((error: any) => {
          return error;
        });
      return response;
    }
  }

  async updateOrganisationUser(
    dataUpdateOrganisationUser: IEditOrganisationUser
  ) {
    if (isTokenExpired()) {
      clearLocalStorageAndRedirect();
    } else {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/User/OrganisationUser`,
        {
          method: "PUT",
          body: JSON.stringify(dataUpdateOrganisationUser),
          headers: {
            accept: "*/*",
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
        .then((response: any) => response.json())
        .then((response: any) => {
          return response;
        })
        .catch((error: any) => {
          return error;
        });
      return response;
    }
  }

  async deleteOrganisationUser(
    dataDeleteOrganisationUser: IDeleteOrganisationUser
  ) {
    if (isTokenExpired()) {
      clearLocalStorageAndRedirect();
    } else {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/User/OrganisationUser/${dataDeleteOrganisationUser}`,
        {
          method: "DELETE",
          headers: {
            accept: "text/plain",
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
        .then((response: any) => response.json())
        .then((response: any) => {
          return response;
        })
        .catch((error: any) => {
          return error;
        });
      return response;
    }
  }

  async sendEmailNotification(userId: string) {
    if (isTokenExpired()) {
      clearLocalStorageAndRedirect();
    } else {
      const response = await fetch(
        constant.resendEmailNotification + `?userId=${userId}`,
        {
          method: "POST",
          headers: {
            accept: "text/plain",
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
        .then((response: any) => response.json())
        .then((response: any) => {
          return response;
        })
        .catch((error: any) => {
          return error;
        });
      return response;
    }
  }

  async getUserOrganisations(userId: string) {
    if (isTokenExpired()) {
      clearLocalStorageAndRedirect();
    } else {
      try {
        const response = await fetch(constant.userOrganisations, {
          method: "GET",
          headers: {
            accept: "text/plain",
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        });
        if (!response.ok) {
          return { data: null, message: "Some thing is wrong", status: false };
        } else {
          const result = await response.json();
          if (result) {
            return result as IUserOrgnisationsResponse;
          } else {
            return {
              data: null,
              message: "Some thing is wrong",
              status: false,
            };
          }
        }
      } catch (error) {
        return { data: null, message: "Some thing is wrong", status: false };
      }
    }
  }

  async updateUserSelectedOrganisation(orgId: number) {
    if (isTokenExpired()) {
      clearLocalStorageAndRedirect();
    } else {
      try {
        const response = await fetch(
          constant.updateUserSelectedOrganisation + `?orgId=` + orgId,
          {
            method: "POST",
            headers: {
              accept: "text/plain",
              "Content-Type": "application/json",
              Authorization: getAuthToken(),
            },
          }
        );
        if (!response.ok) {
          return { data: null, message: "Some thing is wrong", status: false };
        } else {
          const result = await response.json();
          if (result) {
            return result as IAuthenticateResponse;
          } else {
            return {
              data: null,
              message: "Some thing is wrong",
              status: false,
            };
          }
        }
      } catch (error) {
        return { data: null, message: "Some thing is wrong", status: false };
      }
    }
  }
  async completedProgram(userId: string) {
    if (isTokenExpired()) {
      clearLocalStorageAndRedirect();
    } else {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/Program/CompleteProgram?userId=${userId}`,
        {
          method: "POST",
          headers: {
            accept: "text/plain",
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
        }
      )
        .then((response: any) => response.json())
        .then((response: any) => {
          return response;
        })
        .catch((error: any) => {
          return error;
        });
      return response;
    }
  }
}
export default RegisterOrganisationUser;
