import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import General from "../../../Services/General.service";
import TedJournal from "../../../Services/TedModule.service";
import { Headers } from "../../../Utils/Enums";
import HeaderComponent from "../../FunctionalComponents/Header/HeaderComponent";
import PaginationComponent from "../../FunctionalComponents/Paginator/PaginationComponent";
import TEDJournalEntryTableCompopnent from "../../FunctionalComponents/TEDJournalComponents/TEDJournalEntryTabelComponent";
import TEDJournalSearchComponent from "../../FunctionalComponents/TEDJournalComponents/TEDJournalSearchComponent";
import TEDModalComponent from "../../FunctionalComponents/TEDModal/TEDModalComponent";
import TextComponent from "../../FunctionalComponents/Text/TextComponent";
import "./TEDJournalEntries.css";
import TEDJournalFilterCompopnent from "./TEDJournalFilterComponent";
import { logOutIfTokenExpire } from '../../../Utils/Utils';
import { UserContext } from "../../../Contexts/UserContext";
import { ThemeContext } from "../../../Contexts/ThemeContext";


interface IselectedTedEntry {
    id: number;
    title: string;
    relatedCategory: string;
}

interface ItedEntries {
    id: number,
    category: string,
    title: string,
    text: string,
    description: string;
    lastResponseToTedQs: string | null,
    idNameID: number | null,
    idName: string | null,
    addedDate: string | null
}

function TEDJournalEntries(props: any) {
    const generalService = new General();
    generalService.getIcons();

    let redirection: any = props?.location?.state;
    let category: string = "";
    const ted = new TedJournal();
    let history = useHistory();

    const [totalPages, setTotalPages] = useState<number>(0);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [userInput, setUserInput] = useState<string>("");
    const [tedEntries, setTedEntries] = useState<ItedEntries[]>([]);
    const [categoryId, setCategoryId] = useState<number>(0);
    const [startDate, setStartDate] = useState<any>("");
    const [endDate, setEndDate] = useState<any>("");
    const [selectedTEDEntry, setSelectedTEDEntry] = useState<IselectedTedEntry>({
        id: 0,
        title: "",
        relatedCategory: ""
    });
    const userContext = useContext(UserContext);
    const { theme } = useContext(ThemeContext);

    useEffect(() => {
        userContext.dispatch('');
    }, []);

    useEffect(() => {
        logOutIfTokenExpire();
        if (categoryId > 0) {
            if (currentPage > 0 && currentPage <= totalPages) {
                getTedJournalEntry(false);
            }
            else {
                getTedJournalEntry(true);
            }
        }
        else {
            getTedJournalEntry(false);
        }
        //eslint-disable-next-line
    }, [categoryId, currentPage, pageSize]);

    const onPageHandler = (currentPageId: number) => {
        setCurrentPage(currentPageId);
    }

    const handleInput = (value: string) => {
        setUserInput(value);
    }

    const handleSearch = async () => {
        //setCurrentPage(1);
        if (currentPage == 1) {
            await getTedJournalEntry(true);
        }
        else {
            setCurrentPage(1);
        }
    }

    const getTedJournalEntry = async (isfilter: boolean) => {

        let response: any = await ted.gettedJournalEntry(category, userInput, startDate, endDate, categoryId, isfilter ? 1 : currentPage, pageSize);
        if (response.status) {
            setTotalPages(response.data.pagination.totalPages);
            setTotalItems(response.data.pagination.totalItems);
            setCurrentPage(response.data.pagination.currentPage);
            setPageSize(response.data.pagination.pageSize);
            let intNo2: number = 2;
            let tedEntries: ItedEntries[] = response?.data?.items?.map((value: any) => {
                intNo2 = intNo2 * 2;
                let idStr = String(value.id) + String(intNo2);
                return {
                    id: idStr,
                    category: value.category,
                    title: value.title,
                    text: value.text,
                    description: value.description,
                    lastResponseToTedQs: value.lastResponseToTedQs,
                    idNameID: value?.idName?.id,
                    idName: value?.idName?.name,
                    addedDate: new Date(value.addedDate).toLocaleDateString('en-GB')
                }
            })
            setTedEntries(tedEntries);
        }
    }

    const handleRedirection = () => {
        if (redirection === undefined) {
            history.push("/dashboard");
        } else {
            history.push("/newjournalentry");
        }
    }

    const handleFilter = (val: number) => {
        setCurrentPage(1);
        setCategoryId(val);
    }

    return (
        <>
            <div className="container-fluid row col-sm-12">
                {theme === "light" ? (
                    <div className="d-flex align-items-center">
                        <img alt="redirection-back" className="cursor-pointer"
                            onClick={() => handleRedirection()}
                            src={window.location.origin + "/images/chevron-left.svg"} />
                        <span className="cursor-pointer" onClick={() => handleRedirection()}>
                            <HeaderComponent
                                title="TED Logbook"
                                size={Headers.H2}
                            />
                        </span>
                    </div>
                ) : <div className="d-flex align-items-center">
                        <img alt="redirection-back" className="cursor-pointer"
                            onClick={() => handleRedirection()}
                            src={window.location.origin + "/images/left-chevron-dark.svg"} />
                        <span className="cursor-pointer" onClick={() => handleRedirection()}>
                            <div style={{ marginLeft: "15px" }}>
                                <HeaderComponent
                                    title="TED Logbook"
                                    size={Headers.H2}
                                />
                            </div>
                        </span>
                    </div>}

                <div className="mt-3 row">
                    <TextComponent
                        text="This is your logbook where submitted reflections are stored."
                        customClassName={"intro-text col-sm-12"}
                    />
                </div>

                {/* search */}
                <div className="d-flex flex-column mt-3 col-sm-12 col-lg-4">
                    <TEDJournalSearchComponent
                        handleInput={handleInput}
                        userInput={userInput}
                        handleSearch={handleSearch}
                    />
                </div>

                {/* filters */}
                <div className="d-flex mt-3 row">
                    <TEDJournalFilterCompopnent
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        handleFilter={handleFilter}
                    />
                </div>

                {/* table */}
                <div className="w-100">
                    <TEDJournalEntryTableCompopnent
                        tedEntries={tedEntries}
                        setSelectedTEDEntry={setSelectedTEDEntry}
                        selectedTEDEntry={selectedTEDEntry}
                    />
                </div>

                {tedEntries.length > 0 && <div className='d-flex justify-content-end'>
                    <PaginationComponent
                        currentPage={currentPage}
                        pageSize={pageSize}
                        totalItems={totalItems}
                        totalPages={totalPages}
                        onPageHandler={onPageHandler}
                    />
                </div>}
            </div>
            <div>
                <TEDModalComponent />
            </div>
        </>
    );
}

export default TEDJournalEntries;