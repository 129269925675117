import React, { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import "./BootstrapModal.css";
import { ThemeContext } from "../../../Contexts/ThemeContext";
import "../../../darkcustom.css"

interface Iprops {
    handleClose: () => void;
    show: boolean;
    modalTitle: string;
    modalBody: string;
    successButtonText?: string;
    closeButtonText?: string;
    setYesDeleteIt?: any;
    handleDeleteClick?: any;
    isAgreementAccepted?: boolean;
    acceptAgreement?: any;
}
function BootstrapModal(props: Iprops) {

    const {
        handleClose,
        show,
        modalTitle,
        modalBody,
        successButtonText,
        closeButtonText,
        setYesDeleteIt,
        handleDeleteClick,
        isAgreementAccepted,
        acceptAgreement
    } = props;
    const { theme } = useContext(ThemeContext);

    const handleAction = (status: boolean) => {
        if (status) {
            if (handleDeleteClick === undefined) {
                if (!isAgreementAccepted) {
                    acceptAgreement();
                    handleClose();
                }
                handleClose();
            } else {
                handleGroupDelete();
                handleClose();
            }
        } else {
            handleClose();
        }
    }

    const handleGroupDelete = () => {
        setYesDeleteIt(true);
        handleDeleteClick();
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
            restoreFocus
            scrollable
            animation
            backdrop={'static'}
        >
            {theme === "light" ? (
                <>
                    <Modal.Header>
                        <Modal.Title className="header-text">{modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <span className="body-text" dangerouslySetInnerHTML={{ __html: modalBody }}></span>
                    </Modal.Body>
                    <Modal.Footer>
                        {closeButtonText !== undefined &&
                            <Button role="button" variant="secondary" className="font-weight-600 text-color-182C34" onClick={() => handleAction(false)}>
                                {closeButtonText}
                            </Button>
                        }
                        {successButtonText !== undefined &&
                            <Button role="button" variant="primary" className="font-weight-600 text-color-182C34" onClick={() => handleAction(true)}>
                                {successButtonText}
                            </Button>
                        }
                    </Modal.Footer>
                </>
            ) : <>
                    <Modal.Header className="modelBox">
                        <Modal.Title className="header-text">{modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modelBox">
                        <span className="body-text" dangerouslySetInnerHTML={{ __html: modalBody }}></span>
                    </Modal.Body>
                    <Modal.Footer className="modelBox">
                        {closeButtonText !== undefined &&
                            <Button role="button" variant="secondary" className="font-weight-600 text-color-182C34" onClick={() => handleAction(false)}>
                                {closeButtonText}
                            </Button>
                        }
                        {successButtonText !== undefined &&
                            <Button role="button" variant="primary" className="font-weight-600 text-color-182C34" onClick={() => handleAction(true)}>
                                {successButtonText}
                            </Button>
                        }
                    </Modal.Footer>
                </>}
        </Modal>
    );
}

export default BootstrapModal;