import React, { useContext, useEffect, useState } from "react";
import { Collapse, Container, Navbar, NavbarToggler } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from '../../api-authorization/LoginMenu';
import LogoComponent from '../Logo/LogoComponent';
import './NavMenu.css';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';
import authService from '../../api-authorization/AuthorizeService';
import CircleImageComponent from '../CircleImage/CircleImageComponent';
import Notification from '../../../Services/Notification.service';
import LoggedinNotification from '../../BusinessComponents/LoggedinNotification/LoggedinNotificationComponent';
import SignoutNotificationComponent from '../SignoutNotification/SignoutNotificationComponent';
import { isMNFacilitator } from '../../../Utils/Utils';
import { useHistory } from "react-router-dom";
import { Types, UserContext, UserContextAction } from "../../../Contexts/UserContext";
import Connector from "../../../Utils/NotificatonConnector";
import { ThemeContext } from "../../../Contexts/ThemeContext";
import TEDModalComponent from "../TEDModal/TEDModalComponent";
const NavigationBar = () => {
  const history = useHistory();
  const notificationService = new Notification();
  const [state, setState] = useState({
    collapsed: true,
    isAuthenticated: false,
    userName: '',
    userRole: '',
    show: false,
    isNotification: false,
    userId: '',
    url: '',
    profileImg: '',
    notificationCount: 0
  });
  const userContext = useContext(UserContext);
  const { theme } = useContext(ThemeContext);


  Connector.getInstance().conection.on('sendToUser', (response) => {
    if (response.status) {
      let obj: UserContextAction = {
        type: Types.HAS_NOTIFICATION,
        value: true
      }
      userContext.dispatch(obj);
    }
  });

  useEffect(() => {
    const _subscription = authService.subscribe(() => populateState());
    const timer = setInterval(() => {
      if (!Connector.isConnected() && userContext.state.currentUser.isNotificationOn) {
        Connector.startConnection();
      }
    }, 10000);
    populateState();
    return () => {
      authService.unsubscribe(_subscription);
      clearInterval(timer);
    }
  }, [])

  useEffect(() => {
    if (userContext.state.currentUser.isNotificationOn) {
      if (!Connector.isConnected()) {
        Connector.startConnection();
      }
      getNotificationCount();
    }
    else if (!userContext.state.currentUser.isNotificationOn) {
      let obj: UserContextAction = {
        type: Types.HAS_NOTIFICATION,
        value: false
      }
      userContext.dispatch(obj);
    }
  }, [userContext.state.currentUser.isNotificationOn]);


  const toggleNavbar = () => {
    setState((pre: any) => ({ ...pre, collapsed: !state.collapsed }));
  }

  const handleShow = () => {
    setState((pre: any) => ({ ...pre, show: !state.show }));
  }

  const handleClose = () => {
    setState((pre: any) => ({ ...pre, show: !state.show }));
  }

  const populateState = async () => {
    const user = localStorage.getItem("userConfig");
    const token = localStorage.getItem("API_Token");
    if (user && token) {
      const userDetail = JSON.parse(user);
      let name = `${userDetail.firstName} ${userDetail.lastName}`;
      setState((pre: any) => ({
        ...pre,
        isAuthenticated: token?.length > 0 ? true : false,
        userName: name,
        userRole: userDetail.roles,
        userId: userDetail.id,
        url: window.location.pathname.toLowerCase(),
        profileImg: userDetail.profileImage
      }));
    }
  }

  const getNotificationCount = async () => {
    const response = await notificationService.getUnReadNotificationCount();
    if (response && response.status) {
      if (response.data !== null) {
        let obj: UserContextAction = {
          type: Types.HAS_NOTIFICATION,
          value: response.data > 0 ? true : false
        }
        userContext.dispatch(obj);
      }
    }
  }

  return (
    <>
      <header className="w-100 top-0 top-distance-menu">

        {theme === "light" ? (
          <Navbar className="navbar-expand-xs navbar-toggleable-xs ng-white margin-bottom-32" light>
            {
              !isMNFacilitator() && <div><TEDModalComponent /></div>
            }
            <Container className="justify-content-between">
              {
                state.isAuthenticated ? <HamburgerMenu role={state.userRole ? state.userRole : ''} /> :
                  <NavbarToggler onClick={toggleNavbar} className="mr-2" />
              }
              <div onClick={() => history.push("/")}><LogoComponent /></div>
              <div>
                <span className="glyphicon glyphicon-user" aria-hidden="true"></span>
                <div className="d-none" id="icons-panel">
                  {!isMNFacilitator() && <div className='row'>
                    <div className='icon-column'>
                      {state.isAuthenticated
                        && <Link to={`/profile/${state.userId}`}>
                          {!userContext?.state.currentUser.profileImage && <img className="cursor-pointer" src={window.location.origin + '/images/user.svg'} alt="user" />}
                          {userContext?.state.currentUser.profileImage &&
                            <CircleImageComponent size={'imageSize36'} image={String(userContext.state.currentUser.profileImage).replace("/api", "")} />
                          }
                        </Link>
                      }
                    </div>
                    <div className='icon-column'>
                      {state.isAuthenticated && userContext.state.hasNotifications
                        ? <img onClick={handleShow} src={window.location.origin + '/images/bell.svg'} alt="notification" className="margin-left-18 cursor-pointer" />
                        : <img onClick={handleShow}
                          width={"25px"} height={"25px"} className="margin-left-18 cursor-pointer" src={window.location.origin + '/images/notification-bell-no-notif.svg'} alt="no-notification" />
                      }
                    </div>
                    <div className='icon-column'>
                      <div className="margin-left-18">
                        <TEDModalComponent />
                      </div>
                    </div>
                  </div>
                  }

                </div>

                {state.show ?
                  state.isAuthenticated
                    ? <LoggedinNotification isNotification={state} show={state.show} onHide={handleClose} />
                    : <SignoutNotificationComponent show={state.show} onHide={handleClose} />
                  : null
                }
              </div>
              <Collapse className="d-xs-inline-flex flex-xs-row-reverse" isOpen={!state.collapsed} navbar>
                <ul className="navbar-nav flex-grow">
                  <LoginMenu></LoginMenu>
                </ul>
              </Collapse>
            </Container>
          </Navbar>
        ) :
          <Navbar style={{ backgroundColor: "#1C2930" }} className="navbar-expand-xs navbar-toggleable-xs ng-white margin-bottom-32">
            {
              !isMNFacilitator() && <div><TEDModalComponent /></div>
            }
            <Container className="justify-content-between">
              {
                state.isAuthenticated ?
                  <div className="imageStyle">
                    <div style={{ marginLeft: "-5px" }}><HamburgerMenu role={state.userRole ? state.userRole : ''} /></div>
                  </div> :
                  <NavbarToggler onClick={toggleNavbar} className="mr-2" />
              }
              <div onClick={() => history.push("/")}><LogoComponent /></div>
              <div>
                <span className="glyphicon glyphicon-user" aria-hidden="true"></span>
                <div className="d-none" id="icons-panel">
                  {!isMNFacilitator() && <div className='row'>
                    <div className='icon-column'>
                      {state.isAuthenticated
                        && <Link to={`/profile/${state.userId}`}>
                          {!userContext?.state.currentUser.profileImage && <img className="cursor-pointer" src={window.location.origin + '/images/UserDark.svg'} alt="user" />}
                          {userContext?.state.currentUser.profileImage &&
                            <CircleImageComponent size={'imageSize36'} image={String(userContext.state.currentUser.profileImage).replace("/api", "")} />
                          }
                        </Link>
                      }
                    </div>
                    <div className='icon-column'>
                      {state.isAuthenticated && userContext.state.hasNotifications
                        ? <img onClick={handleShow} src={window.location.origin + '/images/BellDark.svg'} alt="notification" className="margin-left-18 cursor-pointer" />
                        : <img onClick={handleShow} width={"32px"} height={"32px"} className="margin-left-18 cursor-pointer" src={window.location.origin + '/images/bell1Icon.png'} alt="no-notification" />
                      }
                    </div>
                  </div>
                  }
                </div>
                {state.show ?
                  state.isAuthenticated
                    ? <LoggedinNotification isNotification={state} show={state.show} onHide={handleClose} />
                    : <SignoutNotificationComponent show={state.show} onHide={handleClose} />
                  : null
                }
              </div>
              <Collapse className="d-xs-inline-flex flex-xs-row-reverse" isOpen={!state.collapsed} navbar>
                <ul className="navbar-nav flex-grow">
                  <LoginMenu></LoginMenu>
                </ul>
              </Collapse>
            </Container>
          </Navbar>
        }

      </header>
    </>
  )
}

export default NavigationBar;
