import React, { useContext, useEffect, useState } from "react";
import UserPractice from "../../../Services/UserPractice.service";
import { Headers } from "../../../Utils/Enums";
import { createNotification } from "../../../Utils/Toast";
import HeaderComponent from "../../FunctionalComponents/Header/HeaderComponent";
import PracticeCardComponent from "../../FunctionalComponents/UserPracticeComponents/PracticeCardComponent";
import PracticePlaylistComponent from "../../FunctionalComponents/UserPracticeComponents/PracticePlaylistComponent";
import { useHistory } from "react-router";
import TEDModalComponent from "../../FunctionalComponents/TEDModal/TEDModalComponent";
import General from "../../../Services/General.service";
import { Guid } from "guid-typescript";
import { logOutIfTokenExpire } from '../../../Utils/Utils';
import { UserContext } from "../../../Contexts/UserContext";
import { ThemeContext } from "../../../Contexts/ThemeContext";

interface IpracticesData {
    practiceId: number
    title: string;
    summary: string;
    otherCurrent: number;
    otherCompleted: number;
    rating: number;
    practiceStatus: string;
}

interface IPagination {
    totalPages: number;
    totalItems: number;
    currentPage: number;
    pageSize: number;
}

function UserPracticeComponent(props: any) {
    const guid = Guid.create();
    const generalService = new General();
    generalService.getIcons();
    const practice = new UserPractice();
    let history = useHistory();
    let redirection: any = props?.location?.state;
    const [practiceData, setPracticeData] = useState<IpracticesData[]>([]);
    const [currentPracticeData, setCurrentPracticeData] = useState<IpracticesData[]>([]);
    const [practiceStatus, setPracticeStatus] = useState<string>("");
    const [pagination, setPagination] = useState<IPagination>({ totalPages: 0, totalItems: 0, currentPage: 1, pageSize: 10 });
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize,] = useState<number>(10);
    const [isCurrentPractice, setIsCurrentPractice] = useState<boolean>(false);
    const [selectedPractice, setSelectedPractice] = useState<any>({
        id: 0,
        details: "",
        instructions: "",
        summary: "",
        title: "",
    });
    const userContext = useContext(UserContext);
    const { theme } = useContext(ThemeContext);

    useEffect(() => {
        userContext.dispatch('');
    }, [])
    useEffect(() => {
        logOutIfTokenExpire();
        getPracticeList();
        //eslint-disable-next-line
    }, [practiceStatus, currentPage]);

    const getPracticeList = async (isFilter=false,searchText="",moduleId=0) => {
        if(isFilter){
            setCurrentPage(1);
            setPracticeStatus("");
        }
        let response: any = await practice.getUserPracticeList(currentPage, pageSize, practiceStatus,searchText,moduleId);

        if (response.status) {

            let tempPracticeData: IpracticesData[] = response.data.items.map((data: any) => {
                return {
                    title: data.title,
                    description: data.summary,
                    otherCurrent: data.otherCurrent,
                    otherCompleted: data.otherCompleted,
                    rating: data.rating,
                    practiceId: data.practiceId,
                    practiceStatus: data.practiceStatus
                }
            });

            //eslint-disable-next-line
            let currentPractice: IpracticesData[] = response.data.items.filter((data: any) => {
                if (data.practiceStatus === "InProgress") {
                    return {
                        title: data.title,
                        description: data.summary,
                        otherCurrent: data.otherCurrent,
                        otherCompleted: data.otherCompleted,
                        rating: data.rating,
                        practiceId: data.practiceId,
                        practiceStatus: data.practiceStatus
                    }
                }
            });
            setCurrentPracticeData(currentPractice);
            setPracticeData(tempPracticeData);
            setPagination({
                totalPages: response.data.pagination.totalPages,
                totalItems: response.data.pagination.totalItems,
                currentPage: response.data.pagination.currentPage,
                pageSize: response.data.pagination.pageSize
            });
        }
    }

    const actionOnPractice = async (id: number) => {
        //eslint-disable-next-line
        let response: any = await practice.addUserPractice(id);
    }

    const currentPractice = async (id: number) => {
        //eslint-disable-next-line
        let response: any = await practice.setCurrentUserPractice(id);
        if (response.status) {
            createNotification("success", response.message);
            getPracticeList();
            setIsCurrentPractice(true);
        }
    }

    const deletePractice = async (id: number) => {
        let response: any = await practice.removeUserPractice(id);
        if (response.status) {
            createNotification("success", response.message);
            setCurrentPracticeData([]);
            getPracticeList();
        }
    }

    const handleRedirection = () => {
        if (redirection === undefined) {
            history.push("/dashboard");
        } else {
            history.push("/practicelibrary");
        }
    }

    const getClickedPractice = async (practiceId: number) => {
        let response: any = await practice.getClickedPractice(practiceId);
        if (response) {
            setSelectedPractice({
                id: response.id,
                details: response.details,
                instructions: response.instructions,
                summary: response.summary,
                title: response.title,
            });
        }
    }

    const completePractice = async (id: number) => {
        let response: any = await practice.completePractice(id, String(guid));
        if (response.status) {
            createNotification("success", response.message);
            setCurrentPracticeData([]);
            getPracticeList();
            setIsCurrentPractice(false);
        }
    }

    return (
        <>
            <div className="container-fluid row col-sm-12">
                {theme === "light" ? (
                    <>
                        <div className="d-flex align-items-center">
                            <img
                                onClick={() => handleRedirection()}
                                className="cursor-pointer"
                                alt="redirection-back" src={window.location.origin + "/images/chevron-left.svg"}
                            />
                            <span onClick={() => handleRedirection()} className="cursor-pointer">                                
                                    <HeaderComponent
                                        title="Do"
                                        size={Headers.H2}
                                    />                                
                            </span>
                        </div>
                        </>
                ) :
                    <>
                        <div className="d-flex align-items-center">
                            <img
                                onClick={() => handleRedirection()}
                                className="cursor-pointer"
                                alt="redirection-back" src={window.location.origin + "/images/left-chevron-dark.svg"}
                            />
                            <span onClick={() => handleRedirection()} className="cursor-pointer">
                                <div style={{ marginLeft: "15px" }}>
                                    <HeaderComponent
                                        title="Do"
                                        size={Headers.H2}
                                    />
                                </div>
                            </span>
                        </div>
                    </>}
                <div className="mt-3">
                    <HeaderComponent
                        title="Practices"
                        size={Headers.H3}
                    />
                </div>

                <div className="d-flex justify-content-between mt-4 flex-column col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <PracticeCardComponent
                        currentPracticeData={currentPracticeData}
                        isCurrentPractice={isCurrentPractice}
                        completePractice={completePractice}
                    />
                </div>

                <div className="mt-5">
                    <PracticePlaylistComponent
                        practiceData={practiceData}
                        actionOnPractice={actionOnPractice}
                        setPracticeStatus={setPracticeStatus}
                        totalPages={pagination.totalPages}
                        totalItems={pagination.totalItems}
                        currentPage={pagination.currentPage}
                        pageSize={pagination.pageSize}
                        setCurrentPage={setCurrentPage}
                        currentPractice={currentPractice}
                        deletePractice={deletePractice}
                        selectedPractice={selectedPractice}
                        setSelectedPractice={setSelectedPractice}
                        getClickedPractice={getClickedPractice}
                        getPracticeList={getPracticeList}
                    />
                </div>

                {/* Not require as of now */}
                {/* <div className="mt-5 mb-5">
                <PracticeRelatedComponent
                    practiceData={practiceData}
                    actionOnPractice={actionOnPractice}
                />
            </div> */}
            </div>
            <div>
                <TEDModalComponent />
            </div>
        </>
    );
}

export default UserPracticeComponent;