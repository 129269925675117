import React, { useEffect, useState, useContext } from "react";
import { Card, Form, Row, Spinner } from "react-bootstrap";
import { chartType } from "../../../Utils/Enums";
import ChartComponent from "../../FunctionalComponents/Chart/ChartComponent";
import Lookup from "../../../Services/Lookup.service";
import Chartservices from "../../../Services/Charts.service";
import { IIdName } from "../../../interfaces/User/IUserOrgnisations";
import { IQuestion } from "../../../interfaces/Charts/ISurveyCharts";
import { Button } from "reactstrap";
import { jsPDF } from "jspdf";
import { ThemeContext } from "../../../Contexts/ThemeContext";
import "../../../darkcustom.css"

const ChartsComponent = () => {
    const lookup = new Lookup();
    const chartsServices = new Chartservices();
    const [programSurveyListData, setProgramSurveyListData] = useState<IIdName[]>([]);
    const [showSpinner, setShowSpinner] = useState<boolean>(true);
    const [surveyChartData, setSurveyChartData] = useState<IQuestion[]>([]);
    const [selectedSurvey, setSelectedSurvey] = useState<number>(0);
    const [surveyChartSpinner, setSurveyChartSpinner] = useState<boolean>(false);
    const [isFirstload, setIsFirstload] = useState<boolean>(true);
    const [exportData, setExportData] = useState<any[]>([]);
    let chartCanvas: any[] = [];
    const { theme } = useContext(ThemeContext)

    useEffect(() => {
        programSurveyList();
    }, [])

    useEffect(() => {
        if (selectedSurvey > 0) {
            setSurveyChartSpinner(true);
            setIsFirstload(true);
            chartCanvas = [];
            getSurveyChartData();
        }
    }, [selectedSurvey])

    const programSurveyList = async () => {
        let response: any = await lookup.getProgramSurveyList();
        if (response && response.status) {
            let programSurveyList: IIdName[] = response.data.map((data: any) => {
                return {
                    Id: data.id,
                    Name: data.name
                }
            });
            if (programSurveyList && programSurveyList.length > 0) {
                setSelectedSurvey(programSurveyList[0].Id);
            }
            setProgramSurveyListData(programSurveyList);
        }
        else {
            setShowSpinner(false);
        }
    }

    const getSurveyChartData = async () => {
        let response = await chartsServices.getSurveyChartData(selectedSurvey);
        if (response && response.status && response.data) {
            if (response.data.questions.length > 0) {
                setSurveyChartData(response.data.questions);
            }
            setSurveyChartSpinner(false);
            setShowSpinner(false);
        }
        else {
            setSurveyChartSpinner(false);
            setShowSpinner(false);
        }
    }

    const handleSurveyChange = (selceted: number) => {
        setSelectedSurvey(selceted);
    }

    const handleExportClick = () => {
        if (exportData.length > 0) {
            const pdf = new jsPDF('p', 'mm');
            exportData.forEach((x, i) => {
                const imgData = x.toDataURL('image/png');
                pdf.addImage(imgData, 'png', 20, 40, 180, 150);
                if (i !== exportData.length - 1) {
                    pdf.addPage();
                }
            });
            pdf.save("charts.pdf");
        }
    }

    return (
        <>
            <div className="row g-3 align-items-center">
                <div className="col-8">
                    <h4>Survey Questions Charts</h4>
                </div>
                <div className="col-4">
                    <div className="row g-3 align-items-center">
                        {theme === "light" ? (
                            <Form.Group className="mb-3 col-6">
                                <Form.Select defaultValue={selectedSurvey} onChange={(e: any) => {
                                    handleSurveyChange(Number(e.target.value));
                                }}>
                                    {programSurveyListData?.map((data: IIdName, i) => <option key={i * 2} value={data.Id}>{data.Name}</option>)}
                                </Form.Select>
                            </Form.Group>
                        ) : <Form.Group className="mb-3 col-6">
                                <Form.Select className="clientSearchBox" defaultValue={selectedSurvey} onChange={(e: any) => {
                                    handleSurveyChange(Number(e.target.value));
                                }}>
                                    {programSurveyListData?.map((data: IIdName, i) => <option key={i * 2} value={data.Id}>{data.Name}</option>)}
                                </Form.Select>
                            </Form.Group>}
                        <Button
                            variant="primary"
                            className="font-weight-600 text-color-182C34 mb-3 col-6"
                            type="button"
                            disabled={exportData.length > 0 ? false : true}
                            onClick={() => handleExportClick()}
                        >
                            Export Charts
                    </Button>
                    </div>
                </div>
            </div>
            <br />
            <div className="row">
                {
                    !showSpinner ? (surveyChartData && surveyChartData.length > 0) ? surveyChartData.map((x: IQuestion, i) => {
                        {
                            if (x.questionType === 2 || x.questionType === 3) {
                                x.chartData.datasets.forEach(x => x.backgroundColor = "#00C9A7");
                                const chartOptions = {
                                    responsive: true,
                                    maintainAspectRatio: true,
                                    indexAxis: "y" as const,
                                    animation: {
                                        onComplete: function (e: any) {
                                            if (isFirstload) {
                                                chartCanvas.push(e.chart.canvas);
                                                if ((i + 1) == surveyChartData.length) {
                                                    setIsFirstload(false);
                                                    setExportData(chartCanvas);
                                                }
                                            }
                                        }
                                    },
                                    plugins: {
                                        legend: {
                                            position: "top" as const
                                        },
                                        title: {
                                            display: true,
                                            text: x.question,
                                        }
                                    }
                                };
                                const data = {
                                    labels: x.chartData.labels,
                                    datasets: x.chartData.datasets,
                                };
                                return <div key={i * 4} className="mb-3 col-6">
                                    <ChartComponent uKey={x.questionId} ChartType={chartType.Bar} spinner={surveyChartSpinner} data={data} options={chartOptions} />
                                </div>
                            }
                            else if (x.questionType === 1) {
                                return <div className="mb-3 col-6">
                                    <Card>
                                        <Card.Body>
                                            <div className="position-relative mb-1 p-1" style={{ 'height': '46vh', 'overflow': 'scroll' }}>
                                                {
                                                    !surveyChartSpinner ?
                                                        <div>
                                                            <h5>{x.question}</h5>
                                                            {x.freeTextResponse.length > 0 && x.freeTextResponse.map((res, i) => {
                                                                return <Card.Text key={i * 3}><b>Response {i + 1}:</b> {res}</Card.Text>
                                                            })}
                                                        </div>
                                                        : <div className="d-flex align-items-center justify-content-center">
                                                            <Spinner animation="border" variant="info"></Spinner>
                                                        </div>
                                                }
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            }
                        }
                    }) : <div className="mb-3 col-12 text-center">
                            <h5>No Data found</h5>
                        </div>
                        : <div className="d-flex align-items-center justify-content-center">
                            <Spinner animation="border" variant="info"></Spinner>
                        </div>
                }
            </div>
        </>
    )
}

export default ChartsComponent;