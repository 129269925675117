import { IStepData } from "../components/FunctionalComponents/DashboardCardItem/DashboardCardItemComponent";
import constant from "../Utils/Config";
import {isTokenExpired,clearLocalStorageAndRedirect, getAuthToken} from "../Utils/Utils";
class Dashboard {
    async getProgramState() {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Program/programStateByUser`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            });
            if (!response.ok) {
                return { data: "error", ok: false };
            } else {
                const result = await response.json();
                if (result) {
                    return { data: result, ok: true };
                } else {
                    return { data: "error", ok: false };
                }
            }
        } catch (error) {
            return { data: error, ok: false };
        }
    };

    async getQuote(path: string) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Quote?TEDQuoteType=${path}`, {
                method: 'GET',
                headers: {
                    'accept': 'text/plain',
                    "Authorization": getAuthToken()
                }
            });
            if (!response.ok) {
                return { data: "error", ok: false };
            } else {
                const result = await response.json();
                if (result) {
                    return { data: result, ok: true };
                } else {
                    return { data: "error", ok: false };
                }
            }
        } catch (error) {
            return { data: error, ok: false };
        }
    };

    async updateModuleStep(data: IStepData) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Program/UpdateModuleStep?moduleStepId=` + data.id + "&status=" + data.userModuleStepStatusId, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            });
            if (!response.ok) {
                return { data: "error", ok: false };
            } else {
                const result = await response.json();
                if (result) {
                    return { data: result, ok: true };
                } else {
                    return { data: "error", ok: false };
                }
            }
        } catch (error) {
            return { ok: false };
        }
    };

    async getUserModuleStepsStatus(id: number, idType: string) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Program/UserModuleStepsStatus?Id=${id}&idType=${idType}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'accept': '*/*',
                "Authorization": getAuthToken()
            }
        }).then((response: any) => response.json())
            .then((response: any) => {
                return response;
            }).catch((error: any) => {
                return error;
            });
        return response;
    };
}
export default Dashboard;