import constant from "../Utils/Config";
import {
  isTokenExpired,
  clearLocalStorageAndRedirect,
  getAuthToken,
} from "../Utils/Utils";
interface IscoreData {
  moduleStepId: number;
  scoreId: number;
  stepType: string;
  feedback: string;
}

interface IproviderScoreData {
  token: string;
  score: number;
  feedback: string;
}
class Scores {

  async IsSurveyReady(moduleStepId:number){
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/Score/IsSurveyReady?moduleStepId=` + moduleStepId,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            accept: "*/*",
            Authorization: getAuthToken(),
          },
        }
      );
      if (!response.ok) {
        return { data: "error", ok: false };
      } else {
        const result = await response.json();
        if (result) {
          return { data: result, ok: true };
        } else {
          return { data: "error", ok: false };
        }
      }
    } catch (error) {
      return { data: error, ok: false };
    }
  }

  async getScoreList(type: number) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/Lookup/GetScoreList?type=` + type,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            accept: "*/*",
            Authorization: getAuthToken(),
          },
        }
      );
      if (!response.ok) {
        return { data: "error", ok: false };
      } else {
        const result = await response.json();
        if (result) {
          return { data: result, ok: true };
        } else {
          return { data: "error", ok: false };
        }
      }
    } catch (error) {
      return { data: error, ok: false };
    }
  }

  async postPersonalScorer(scoreData: IscoreData) {
    if (isTokenExpired()) {
      clearLocalStorageAndRedirect();
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/Score/PersonalScore`,
        {
          method: "POST",
          body: JSON.stringify(scoreData),
          headers: {
            "Content-Type": "application/json",
            accept: "*/*",
            Authorization: getAuthToken(),
          },
        }
      );
      if (!response.ok) {
        throw new Error("Error!");
      } else {
        return { ok: true };
      }
    } catch (error) {
      return { ok: false };
    }
  }

  async getProviderScore() {
    if (isTokenExpired()) {
      clearLocalStorageAndRedirect();
    }
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/Score/ProviderScore`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "*/*",
          Authorization: getAuthToken(),
        },
      }
    )
      .then((response: any) => response.json())
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return error;
      });
    return response;
  }

  async getPersonalScore() {
    if (isTokenExpired()) {
      clearLocalStorageAndRedirect();
    }
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/Score/PersonalScore`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "*/*",
          Authorization: getAuthToken(),
        },
      }
    )
      .then((response: any) => response.json())
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return error;
      });
    return response;
  }

  async validateLink(token: string) {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/Score/IsValidLink?token=${token}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "*/*",
          // "Authorization": constant.authToken
        },
      }
    )
      .then((response: any) => response.json())
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return error;
      });
    return response;
  }

  async scorerInvitation(token: string) {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/Score/ScorerInvitation?token=${token}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          accept: "*/*",
          // "Authorization": constant.authToken
        },
      }
    )
      .then((response: any) => response.json())
      .then((response: any) => {
        return response;
        //console.log(response)
      })
      .catch((error: any) => {
        return error;
      });
    return response;
  }

  async sendProviderScore(providerScoreData: IproviderScoreData) {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/Score/SendProviderScore`,
      {
        method: "POST",
        body: JSON.stringify(providerScoreData),
        headers: {
          "Content-Type": "application/json",
          accept: "*/*",
          //"Authorization": constant.authToken
        },
      }
    )
      .then((response: any) => response.json())
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        return error;
      });
    return response;
  }
}
export default Scores;
