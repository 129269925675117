import React, { useEffect } from 'react';
import { Switch, Route } from "react-router-dom";
import WebFont from 'webfontloader';
import { useHistory } from 'react-router';
import { publicRoutes, privateRoutes } from './RoutingComponent';
import './custom.css';
import 'react-toastify/dist/ReactToastify.css';
import { isMNFacilitator } from './Utils/Utils';
import MNLayout from './components/MNLayout';
import { UserContextProvider } from './Contexts/UserContext';
import { ThemeProvider } from "./Contexts/ThemeContext"
const App = () => {
  WebFont.load({
    google: {
      families: ['Nunito', 'Spartan']
    }
  });

  let history = useHistory();
  const publicUrls = [
    "/login",
    "/forgotPassword",
    "/register",
    "/submitScore",
    "/pagenotfound",
    "/resetPassword",
    "/opensurvey"
  ]

  useEffect(() => {
    let url = window.location.pathname;
    //handle multiple tab logout
    window.onstorage = () => {
      let tempToken = window.localStorage.getItem('API_Token');
      if (tempToken === null || tempToken === undefined) {
        window.location.href = "/login";
      }
    };
    // if user is not logged in and trying to acceess private routes the user will get redirect to login page
    if (publicUrls.includes(url) && (localStorage.getItem("API_Token") === undefined || localStorage.getItem("API_Token") === null)) {
      // but user can access some public routes such as put in condition below
      if (url !== "/resetPassword" && url !== "/submitScore" && url !== "/opensurvey") {
        history.push("/login");
      }
    } else {
      // if user is not loggedin and trying to reach to the private routes user won't
      if (localStorage.getItem("API_Token") === undefined || localStorage.getItem("API_Token") === null) {
        if (url !== "/resetPassword" && url !== "/submitScore" && url !== "/opensurvey") {
          history.push("/login");
        }
      } else if (publicUrls.includes(url)) {
        if (url !== "/submitScore" && url !== "/opensurvey") {
          if (isMNFacilitator()) {
            history.push("/mnfacilitatordashboard");
          }
          else {
            history.push("/dashboard");
          }
        }
      }
    }
    //eslint-disable-next-line
  }, [])

  return (
    <ThemeProvider>
      <UserContextProvider>
        <Switch>
          {
            (localStorage.getItem("API_Token") === undefined || localStorage.getItem("API_Token") === null) ?
              publicRoutes.map((data: any, index: number) => {
                return <Route
                  key={index}
                  exact={data.exact}
                  path={data.path}
                  component={data.component}
                />
              })
              : <MNLayout>
                {
                  privateRoutes.map((data: any, index: number) => {
                    return (
                      <Route
                        key={index}
                        exact
                        path={data.path}
                        component={data.component}
                      />
                    )
                  })
                }
              </MNLayout>
          }
        </Switch>
      </UserContextProvider>
    </ThemeProvider>
  );
}
export default App;