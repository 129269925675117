import React, { useEffect, useState, useContext } from "react";
import { Offcanvas, Form, Col } from "react-bootstrap";
import NotificationComponent from "../../FunctionalComponents/NotificationComponent/NotificationComponent";
import Notification from "../../../Services/Notification.service";
import { INotification } from "../../../interfaces/notifications/INotification";
import './LoggedinNotificationComponenet.css';
import { ThemeContext } from "../../../Contexts/ThemeContext";
import "../../../darkcustom.css"
interface Iprops {
    show: boolean;
    isNotification: any
    onHide: any
}
function LoggedinNotification(props: Iprops) {
    const { theme } = useContext(ThemeContext)
    const { show, onHide, isNotification } = props;

    let notification = new Notification();
    const notificationType = ["Unread Notifications", "Read Notifications", "All Notifications"];
    const [notificationData, setNotificationData] = useState<INotification[]>([]);
    const [, setNoUnreadNotif] = useState<boolean>(false);

    useEffect(() => {
        getUnreadNotification();
    }, []);

    const getNotification = async () => {
        let tempNotifData: any[] = [];
        let response: any = await notification.getNotification();
        if (response.status) {
            if (response.data.items.length > 0) {
                localStorage.setItem("isNotification", "false");
                isNotification.isNotification = localStorage.getItem("isNotification") == 'true' ? true : false;
                tempNotifData = response.data.items.map((data: INotification) => {
                    return data
                })
                setNotificationData(tempNotifData);
            } else {
                isNotification.isNotification = localStorage.getItem("isNotification") == 'true' ? true : false;
            }

        }
    }

    const getUnreadNotification = async () => {
        let tempUnreadNotifData: INotification[] = [];
        let response = await notification.unreadNotification();
        if (response.length > 0) {
            tempUnreadNotifData = response.map((data: INotification) => {
                return data
            })
            setNotificationData(tempUnreadNotifData);
        } else {
            setNotificationData(tempUnreadNotifData);
            setNoUnreadNotif(true);
        }
    }

    const getReadNotification = async () => {
        let tempReadNotifications: INotification[] = [];
        tempReadNotifications = await notification.getAllReadNotification();
        setNotificationData(tempReadNotifications);
    }

    const handleNotificationType = async (value: string) => {
        if (value === "Unread Notifications") {
            getUnreadNotification();
        } else if (value === "Read Notifications") {
            getReadNotification();
        } else if (value === "All Notifications") {
            getNotification();
        }

    }

    const handleReadNotifClick = async (id: number) => {
        await notification.readNotification([id]);
        //eslint-disable-next-line
        notificationData.map((data: any) => {
            if (data.id === id) {
                data.read = !data.read;
            }
        });
    }

    const handleDeleteNotification = async (id: number) => {
        await notification.deleteNotification(id);
    }

    return (
        <div>
            {theme === "light" ?
                (
                    <Offcanvas show={show} placement='end' backdropClassName="off-cnavas" onHide={onHide}>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                                <Form.Group as={Col} controlId="formGridGroup">
                                    <Form.Select
                                        onChange={(e: any) => handleNotificationType(e.target.value)}
                                    >
                                        {notificationType.map((data: any, index: number) => {
                                            return <option key={index} label={data} value={data}>{data}</option>
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body bsPrefix="offcanvas-body2">
                            <NotificationComponent
                                notificationData={notificationData}
                                handleDeleteNotification={handleDeleteNotification}
                                handleReadNotifClick={handleReadNotifClick}
                            />
                        </Offcanvas.Body>
                    </Offcanvas>
                ) : <Offcanvas show={show} placement='end' backdropClassName="off-canvas" onHide={onHide}>
                    <Offcanvas.Header style={{ backgroundColor: "#1C2930" }} closeVariant="white" closeButton>
                        <Offcanvas.Title>
                            <Form.Group as={Col} controlId="formGridGroup">
                                <Form.Select style={{ color: "#fff" }} className="searchBox1"
                                    onChange={(e: any) => handleNotificationType(e.target.value)}
                                >
                                    {notificationType.map((data: any, index: number) => {
                                        return <option className="searchBox1" key={index} label={data} value={data}>{data}</option>
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body style={{ backgroundColor: "#1C2930" }} bsPrefix="offcanvas-body2">
                        <NotificationComponent
                            notificationData={notificationData}
                            handleDeleteNotification={handleDeleteNotification}
                            handleReadNotifClick={handleReadNotifClick}
                        />
                    </Offcanvas.Body>
                </Offcanvas>}
        </div>
    );
}

export default LoggedinNotification;