import constant from "../Utils/Config";
import {isTokenExpired,clearLocalStorageAndRedirect, getAuthToken} from "../Utils/Utils";
import {IUserPracticeResponse} from "../../src/interfaces/GeneralChatAnswers/IUserPracticeResponse";

class SinglePractice {
    async ratePractice(practiceId: number, rating: number) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Practice/ratePractice?practiceId=` + practiceId + '&rating=' + rating, {
                method: 'POST',
                headers: {
                    "accept": "text/plain",
                    "content-type": "application/ json",
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                })
                .catch((error: any) => {
                    return error;
                })
            return response;
        }        
    }

    async getPractice(id: number) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Practice?id=` + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            });
            if (!response.ok) {
                return { data: "error", ok: false };
            } else {
                const result = await response.json();
                if (result) {
                    return { data: result, ok: true };
                } else {
                    return { data: "error", ok: false };
                }
            }
        } catch (error) {
            return { data: error, ok: false };
        }
    };
    async getCurrentPracticeUsers(practiceId: number, searchText: string, pageSize: number, pageNum: number) {
        let body = {
            "practiceId": practiceId,
            "searchText": searchText,
            "pageSize": pageSize,
            "pageNum": pageNum
        }
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Practice/getCurrentPracticeUsers`, {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            });
            if (!response.ok) {
                return { data: "error", ok: false };
            } else {
                const result = await response.json();
                if (result) {
                    return { data: result, ok: true };
                } else {
                    return { data: "error", ok: false };
                }
            }
        } catch (error) {
            return { data: error, ok: false };
        }
    }

    async practiceQuestionResponse(userPracticeResponse:IUserPracticeResponse) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        try {
            const url = `${process.env.REACT_APP_BASE_URL}/Practice/PracticeQuestionResponse`;
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json','accept':'*/*',"Authorization": getAuthToken()},
                body: JSON.stringify(userPracticeResponse)
            };
            const response = await fetch(url,requestOptions);
            if (!response.ok) {
                throw new Error('Error!')
            } else {
                return { ok: true };
            }
        } catch (error) {
            return { ok: false };
        }
    };

    async saveAndExit(practiceId: number, guid: string) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Practice/SaveAndExitPractice/` + practiceId + '?guId=' + guid, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            });
            if (!response.ok) {
                throw new Error('Error!')
            } else {
                return { ok: true };
            }
        } catch (error) {
            return { ok: false };
        }
    };

}
export default SinglePractice;