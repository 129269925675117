import React, { useState, useEffect, useContext } from 'react';
import { Col, Button, Modal, FormGroup, Label, Input, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import HeaderComponent from '../Header/HeaderComponent';
import TextComponent from '../Text/TextComponent';
import { Headers } from '../../../Utils/Enums';
import Scores from '../../../Services/Scores.service';
import ErrorInputComponent from '../ErrorInput/ErrorInputComponent';
import { Form } from "react-bootstrap";
import { useHistory } from 'react-router';
import { createNotification } from '../../../Utils/Toast';
import UserPractice from "../../../Services/UserPractice.service";
import { isUserLoggedIn } from '../../../Utils/Utils';
import { ThemeContext } from '../../../Contexts/ThemeContext';
import "../../../darkcustom.css";

interface IScores {
    id: number;
    displayOrder: number;
    description: string;
}
interface IScorerModalComponent {
    children?: React.ReactNode;
    title?: string;
    moduleStepId: number | any;
    isFromEngageCard: boolean;
    validLink?: boolean;
    sendProviderScore?: (score: number, feedback: string) => Promise<void>;
    successMessage?: string;
    practiceId?: number;
    guId?: string;
    scoreMetaData?: any;
    userData?: any;
    disabled?: boolean
    customClassName?: string;
}

interface IscoreData {
    moduleStepId: number;
    scoreId: number;
    stepType: string;
    feedback: string;
}

const ScorerModalComponent = (props: IScorerModalComponent) => {
    const {
        title,
        moduleStepId,
        isFromEngageCard,
        validLink,
        sendProviderScore,
        successMessage,
        scoreMetaData,
        userData,
        disabled
    } = props;
    const practice = new UserPractice();

    let history = useHistory();
    const { theme } = useContext(ThemeContext)
    const scores = new Scores();
    const [modal, setModal] = useState(false);
    const [showSurveyNotReadyModal,setShowSurveyNotReadyModal] = useState(false);
    const [ok, setOK] = useState('loading');
    const [selectedScore, setSelectedScore] = useState(-1);
    const [scoresValues, setScoresValues] = useState<IScores[]>([]);
    const [scoreText, setScoreText] = useState<any>();
    const [surveyReady,setSurveyReady] = useState(true);

    const engageScoreTitle: string = "Review the impact of your learning";
    const engageScoreMessage: string = `<p>Entering your score will complete the Think and Engage steps for this module.</p><p>To what extent have you learnt more about yourself and/or others?</p>`;

    const currentPracticeScoreTitle: string = "Review the impact of your practices"
    const currentPracticeScoreMessage: string = `<p>Entering your score will complete the Do step for this module and notify any Scorers you have nominated. To what extent has there been a positive shift in the way you have responded to recent events and interactions?</p>`;

    const externalScoreTitle: string = "Provide your score for this participant";
    const externalScoreModuleName: string = `<p><strong>Module Name:</strong> ${userData?.moduleName}</p>`;
    const externalScoreUserName: string = `<p>You are going to score for ${userData?.userName},</p>`;
    const externalScoreMessage: string = "To what extent have you observed a positive shift in the way this participant has responded to recent events and interactions?";

    const toggle = () => {
        if(!surveyReady){
            setShowSurveyNotReadyModal(true);
        }
        else{
            setModal(!modal);
        }        
    }

    useEffect(() => {
        isSurveyReady();
        getData();
        //eslint-disable-next-line
    }, []);


    const getData = async () => {
        const result = await scores.getScoreList(1);
        if (!result) return setOK('failed');
        setOK('success');
        setScoresValues(result.data);
    }

    const isSurveyReady = async () => {
        const result = await scores.IsSurveyReady(moduleStepId);
        if(result.ok){
            setSurveyReady(result.data.data);
        }
    }

    const selectScore = (event: any) => {
        setSelectedScore(Number(event.target.value));
    }

    const RedirectToDashboard = () => {
        history.push("/dashboard")
    }

    const SubmitHandler = async () => {
        if (validLink && sendProviderScore) {
            sendProviderScore(selectedScore, scoreText);
        } else {
            let scoreData: IscoreData = {
                moduleStepId: moduleStepId,
                scoreId: selectedScore,
                stepType: isFromEngageCard ? 'Engage' : 'Do',
                feedback: scoreText
            }
            const resultPost = await scores.postPersonalScorer(scoreData);
            if (resultPost.ok) {
                setModal(false);
                createNotification("success", "User score submited.");
                history.push("/dashboard");
                window.location.reload();
            } else {
                setModal(false);
                createNotification("error", "Something went wrong!");
            }
        }
    };

    return (
        <div className={`config-btn-inprocess ${props.customClassName} ${!props.disabled && `btn btn-primary`}`}>
            {
                validLink == undefined && isUserLoggedIn() && <Modal isOpen={showSurveyNotReadyModal} centered >
                    {
                        theme === "light" ? <> <ModalHeader>
                        <HeaderComponent
                                                title={"Information"}
                                                size={Headers.H2}
                                            />
                        </ModalHeader>
                        <ModalBody>
                        <div className='text-center'>
                                        <TextComponent text={"Survey is not created. Please contact your administration."} />
                                    </div>
                        </ModalBody>
                        <ModalFooter>
                                    <>
                                    <Button color="secondary" className="font-weight-600" onClick={() => { setShowSurveyNotReadyModal(false) }}>
                                                <TextComponent text={'OK'} customClassName="text-color-182C34" />
                                            </Button>
                                    </>
                                </ModalFooter></> :
                                <>
                                <ModalHeader style={{ background: "#28373E" }}>
                        <HeaderComponent
                                                title={"Information"}
                                                size={Headers.H2}
                                            />
                        </ModalHeader>
                        <ModalBody style={{ background: "#28373E", color: "white" }}>
                        <div className='text-center'>
                                        <TextComponent text={"Survey is not created. Please contact your administration."} />
                                    </div>
                        </ModalBody>
                        <ModalFooter style={{ background: "#28373E" }}>
                                    <>
                                    <Button color="secondary" className="font-weight-600" onClick={() => { setShowSurveyNotReadyModal(false) }}>
                                                <TextComponent text={'OK'} customClassName="text-color-182C34 buttonText" />
                                            </Button>
                                    </>
                                </ModalFooter>
                                </>
                    } 
                </Modal>
            }
            {theme === "light" ?
                <>
                    {!props.disabled && <div data-toggle="modal" data-target="#exampleModal" onClick={toggle} className={`w-100 ${props.customClassName}`} >
                        <TextComponent text={title} customClassName="text-color-182C34 font-weight-600" />
                    </div>}
                    {props.disabled && <Button
                        data-toggle="modal" data-target="#exampleModal" onClick={toggle} className={`w-100 btn btn-primary ${props.customClassName}`}
                        disabled={props.disabled}>
                        <TextComponent text={title} customClassName="text-color-182C34 font-weight-600" />
                    </Button>}
                </>
                :
                <>
                    {!props.disabled && <div data-toggle="modal" data-target="#exampleModal" onClick={toggle} className="w-100" >
                        <TextComponent text={title} customClassName="text-color-182C34 font-weight-600 buttonText" />
                    </div>}
                    {props.disabled && <Button
                        data-toggle="modal" data-target="#exampleModal" onClick={toggle} className="w-100 btn btn-primary "
                        disabled={props.disabled}>
                        <TextComponent text={title} customClassName="text-color-182C34 font-weight-600 buttonText" />
                    </Button>}</>
            }
            <Modal
                isOpen={validLink ? true : modal}
                centered
                onClosed={() => setScoreText('')}
            >
                {theme === "light" ? (
                    <>
                        {successMessage && successMessage.length > 0 ? null :
                            <ModalHeader>
                                <>
                                    {
                                        ok === 'failed' && <div>
                                            <div className="mb-4">
                                                <ErrorInputComponent error={"An error occurred"} />
                                            </div>
                                        </div>
                                    }
                                    {
                                        ok === 'success' && <HeaderComponent
                                            title={isFromEngageCard ? engageScoreTitle : validLink ? externalScoreTitle : currentPracticeScoreTitle}
                                            size={Headers.H2}
                                        />
                                    }
                                </>
                            </ModalHeader>
                        }
                        <ModalBody>
                            {successMessage && successMessage.length > 0 ?
                                <div className='text-center'>
                                    <TextComponent text={successMessage} />
                                    {
                                        isUserLoggedIn() && <Button className='mt-2'
                                            color="primary background-26CAC1 font-weight-600"
                                            onClick={RedirectToDashboard}>
                                            <TextComponent text={'Go to Dashboard'} customClassName="text-color-182C34" />
                                        </Button>
                                    }
                                </div>
                                : <>
                                    <div className="margin-bottom-16">
                                        {
                                            validLink && <TextComponent
                                                text={externalScoreModuleName}
                                                customClassName="text-color-2D5362"
                                            />
                                        }
                                        {
                                            validLink && <TextComponent
                                                text={externalScoreUserName}
                                                customClassName="text-color-2D5362"
                                            />
                                        }
                                        <TextComponent
                                            text={isFromEngageCard
                                                ? engageScoreMessage
                                                : validLink ? externalScoreMessage : currentPracticeScoreMessage
                                            }
                                            customClassName="text-color-2D5362"
                                        />
                                    </div>
                                    <div>
                                        <Form.Control
                                            as="select"
                                            onChange={selectScore}
                                        >
                                            <option value={-1}>Select score</option>
                                            {scoresValues.map(x =>
                                                <option key={x.id} value={x.id}>{x.description}</option>
                                            )}
                                        </Form.Control>
                                    </div>
                                    <br />
                                    <div>
                                        <FormGroup row>
                                            <Label for="exampleText">Feedback</Label>
                                            <Col style={{ width: "30.6rem" }} sm={10}>
                                                <Input value={scoreText} onChange={(e) => setScoreText(e.target.value)} style={{ height: "95px" }} className='scoretext' cols="60" rows="5" type="textarea" name="text" id="exampleText" />
                                            </Col>
                                        </FormGroup>
                                    </div>
                                </>
                            }
                        </ModalBody>
                        {successMessage && successMessage.length > 0 ? null :
                            <ModalFooter>
                                <>
                                    <Button
                                        color="primary background-26CAC1 font-weight-600"
                                        onClick={SubmitHandler}
                                        disabled={selectedScore === -1}>
                                        <TextComponent text={'Finish'} customClassName="text-color-182C34" />
                                    </Button>
                                    {!validLink &&
                                        <Button color="secondary" className="font-weight-600" onClick={() => { setModal(false) }}>
                                            <TextComponent text={'Cancel'} customClassName="text-color-182C34" />
                                        </Button>
                                    }
                                </>
                            </ModalFooter>
                        }

                    </>
                ) :
                    <>
                        {successMessage && successMessage.length > 0 ? null :
                            <ModalHeader style={{ background: "#28373E" }}>
                                <>
                                    {
                                        ok === 'failed' && <div>
                                            <div className="mb-4">
                                                <ErrorInputComponent error={"An error occurred"} />
                                            </div>
                                        </div>
                                    }
                                    {
                                        ok === 'success' && <HeaderComponent
                                            title={isFromEngageCard ? engageScoreTitle : validLink ? externalScoreTitle : currentPracticeScoreTitle}
                                            size={Headers.H2}
                                        />
                                    }
                                </>
                            </ModalHeader>
                        }
                        <ModalBody style={{ background: "#28373E", color: "white" }}>
                            {successMessage && successMessage.length > 0 ?
                                <div className='text-center'>
                                    <TextComponent text={successMessage} />
                                    {
                                        isUserLoggedIn() && <Button className='mt-2'
                                            color="primary background-26CAC1 font-weight-600"
                                            onClick={RedirectToDashboard}>
                                            <TextComponent text={'Go to Dashboard'} customClassName="text-color-182C34" />
                                        </Button>
                                    }
                                </div>
                                : <>
                                    <div className="margin-bottom-16">
                                        {
                                            validLink && <TextComponent
                                                text={externalScoreModuleName}
                                                customClassName="text-color-2D5362"
                                            />
                                        }
                                        {
                                            validLink && <TextComponent
                                                text={externalScoreUserName}
                                                customClassName="text-color-2D5362"
                                            />
                                        }
                                        <TextComponent
                                            text={isFromEngageCard
                                                ? engageScoreMessage
                                                : validLink ? externalScoreMessage : currentPracticeScoreMessage
                                            }
                                            customClassName="text-color-2D5362"
                                        />
                                    </div>
                                    <div>
                                        <Form.Control
                                            as="select"
                                            onChange={selectScore}
                                            className="searchBox"
                                        >
                                            <option value={-1}>Select score</option>
                                            {scoresValues.map(x =>
                                                <option key={x.id} value={x.id}>{x.description}</option>
                                            )}
                                        </Form.Control>
                                    </div>
                                    <br />
                                    <div>
                                        <FormGroup row>
                                            <Label for="exampleText">Feedback</Label>
                                            <Col style={{ width: "30.6rem" }} sm={10}>
                                                <Input value={scoreText} onChange={(e) => setScoreText(e.target.value)} style={{ height: "95px", background: "#1c2930", color: "white" }} className='scoretext' cols="60" rows="5" type="textarea" name="text" id="exampleText" />
                                            </Col>
                                        </FormGroup>
                                    </div>
                                </>
                            }
                        </ModalBody>
                        {successMessage && successMessage.length > 0 ? null :
                            <ModalFooter style={{ background: "#28373E" }}>
                                <>
                                    <Button
                                        color="primary background-26CAC1 font-weight-600"
                                        onClick={SubmitHandler}
                                        disabled={selectedScore === -1}>
                                        <TextComponent text={'Finish'} customClassName="text-color-182C34 buttonText" />
                                    </Button>
                                    {!validLink &&
                                        <Button color="secondary" className="font-weight-600" onClick={() => { setModal(false) }}>
                                            <TextComponent text={'Cancel'} customClassName="text-color-182C34 buttonText" />
                                        </Button>
                                    }
                                </>
                            </ModalFooter>
                        }
                    </>
                }
            </Modal>
        </div >
    );

}
export default ScorerModalComponent;