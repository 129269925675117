import React, { useContext } from 'react';
import TextComponent from '../Text/TextComponent';
import submitButtonStyles from './SubmitButtonComponent.module.css'
import { ThemeContext } from "../../../Contexts/ThemeContext";
interface ISubmitButtonComponent {
    label?: string;
    customClassName?: string;
    id?: string;
    disabled?: boolean;
};

const ButtonComponent = (props: ISubmitButtonComponent) => {
    const { theme } = useContext(ThemeContext)
    return <>
        <button
            type="button"
            id={props.id}
            className={`btn btn-primary ${props.customClassName && submitButtonStyles[props.customClassName]}`}
            disabled={props.disabled}>
            <div>
                {theme === "light" ? (
                    <TextComponent text={props.label} />
                ) : <TextComponent text={props.label} customClassName={"buttonText"} />}
            </div>
        </button>
    </>
}

export default ButtonComponent;