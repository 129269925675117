import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { IContact } from '../../../Services/Engage.service';
import TextComponent from '../Text/TextComponent';
import './PopoverComponent.css';
import { ThemeContext } from "../../../Contexts/ThemeContext"

export interface IRole {
    id: string;
    role: string;
    type: string;
}
export interface IPopoverComponent {
    item: IContact;
    roles?: String[];
    search?: string;
    onAddScorerHandler?: (data: IRole) => void;
    onDeleteExternalHandler?: (data: string) => void;
    onDeleteContactHandler?: (data: string) => void;
    numOfScorers: number;
}
const PopoverComponent = (props: IPopoverComponent) => {
    let history = useHistory();
    const [popoverOpen, setPopoverOpen] = useState(false);
    const { theme } = useContext(ThemeContext)
    const ProfileHandler = () => {
        let selectedElements = document.querySelectorAll('.popover-item-config');
        for (var i = 0; i < selectedElements.length; i++) {
            selectedElements[i].classList.remove('d-block');
            selectedElements[i].classList.add('d-none');
        }

        var popoverOpenCoockie = localStorage.getItem("popoverOpen");
        if (popoverOpenCoockie) {
            setPopoverOpen(false);
            localStorage.removeItem("popoverOpen");
        }

        if (popoverOpen) {
            setPopoverOpen(false);
        } else {
            setPopoverOpen(true);
        }

    };

    const ViewProfileHandler = () => {
        let url = "";
        if (props.search) {
            url = "/profile/" + props.item.user?.id + "/" + props.search + "/engage";
        } else {
            url = "/profile/" + props.item.user?.id + "/-1/engage";
        }

        history.push(url);
    }

    const AddRoleHandler = () => {
        const data = {
            id: props.item.user.id,
            role: 'scorer',
            type: 'add'
        }
        props.onAddScorerHandler && props.onAddScorerHandler(data);
        setPopoverOpen(false);
    };

    const DeleteRoleHandler = () => {
        const data = {
            id: props.item.user.id,
            role: 'scorer',
            type: 'delete'
        }
        props.onAddScorerHandler && props.onAddScorerHandler(data);
        setPopoverOpen(false);
    };
    const DeleteExternalUserHandler = () => {
        props.onDeleteExternalHandler && props.onDeleteExternalHandler(props.item.user.email);
        setPopoverOpen(false);
    };
    const onDeleteContactHandler = () => {
        props.onDeleteContactHandler && props.onDeleteContactHandler(props.item.user.id);
        setPopoverOpen(false);
    };
    const closePopup = () => {
        setPopoverOpen(false);
    }
    return (
        <div className="position-relative">
            {theme === "light" ? (
                <><button id={"props"} type="button"
                    className="border-FFFFFF background-FFFFFF mr-1 buttonIconPopover" onClick={ProfileHandler}>
                    <img src={window.location.origin + '/images/chevron-right.svg'}
                        alt="chevron-right" className="iconPopover" />
                </button>
                </>
            ) : <>
                <button id={"props"} type="button"
                    className="border-FFFFFF background-FFFFFF mr-1 buttonIconPopover rightImg" onClick={ProfileHandler}>
                    <img src={window.location.origin + '/images/right-chevron-dark.svg'}
                        alt="chevron-right" className="iconPopover " />
                </button>
            </>}

            {theme === "light" ? (
                <div className={`position-absolute popover-item-config ${popoverOpen ? "d-block" : "d-none"}`} onMouseLeave={closePopup}>
                <div className="d-flex flex-column">
                    {!props.item.user?.isExternal &&
                        <div className="popOverItem cursor-pointer test-nav" onClick={ViewProfileHandler}>
                            <TextComponent text={'View profile'} />
                        </div>}
                    {props.numOfScorers < 3 && !props.item.user?.isExternal && props.item.roles && props.item.roles.length === 0 && <div className="popOverItem cursor-pointer" onClick={AddRoleHandler} >
                        <TextComponent text={'Add as scorer'} />
                    </div>}
                    {!props.item.user?.isExternal && props.item.roles && props.item.roles.length > 0 && <div className="popOverItem cursor-pointer" onClick={DeleteRoleHandler}>
                        <TextComponent text={'Undo as scorer'} />
                    </div>}
                    {props.item.user?.isExternal && <div className="popOverItem cursor-pointer last-item" onClick={DeleteExternalUserHandler}>
                        <TextComponent text={'Remove external user'} />
                    </div>}
                    {props.item && !props.item.isGroupMember && !props.item.user?.isExternal && <div className="popOverItem cursor-pointer last-item" onClick={onDeleteContactHandler}>
                        <TextComponent text={'Remove contact'} />
                    </div>}
                </div>
            </div>
            ) : <div className={`position-absolute popover-item-config ${popoverOpen ? "d-block" : "d-none"}`} onMouseLeave={closePopup}>
                <div className="d-flex flex-column">
                    <div style={{ backgroundColor: "#28373E" }}>
                        {!props.item.user?.isExternal &&
                            <div className="popOverItem cursor-pointer test-nav" onClick={ViewProfileHandler}>
                                <TextComponent text={'View profile'} />
                            </div>}
                        {props.numOfScorers < 3 && !props.item.user?.isExternal && props.item.roles && props.item.roles.length === 0 &&
                            <div className="popOverItem cursor-pointer" onClick={AddRoleHandler} >
                                <TextComponent text={'Add as scorer'} />
                            </div>}
                        {!props.item.user?.isExternal && props.item.roles && props.item.roles.length > 0 &&
                            <div className="popOverItem cursor-pointer" onClick={DeleteRoleHandler}>
                                <TextComponent text={'Undo as scorer'} />
                            </div>}
                        {props.item.user?.isExternal &&
                            <div className="popOverItem cursor-pointer last-item" onClick={DeleteExternalUserHandler}>
                                <TextComponent text={'Remove external user'} />
                            </div>}
                        {props.item && !props.item.isGroupMember && !props.item.user?.isExternal &&
                            <div className="popOverItem cursor-pointer last-item" onClick={onDeleteContactHandler}>
                                <TextComponent text={'Remove contact'} />

                            </div>}
                    </div>
                </div>
            </div>}
        </div>
    );
}

export default PopoverComponent;
