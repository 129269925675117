import React from "react";
import { TableColumn } from "react-data-table-component";
import LogbookModalComponent from "../LogbookModal/LogbookModalComponent";
import PreviewComponent from "../PreviewModel/PreviewComponent";
import TableComponent from "../Table/TableComponent";

export interface ItedLogBookData {
    description: string;
    id: string;
    idName: string;
    idNameID: string;
    lastResponseToTedQs: string;
    text: string;
    title: string;
    addedDate: string;
    category: {
        category: string
        name: string;
        id: string;
    }
}

interface Iprops {
    tedEntries: any;
    setSelectedTEDEntry: any;
    selectedTEDEntry: any;
}

function TEDJournalEntryTableCompopnent(props: Iprops) {

    const { tedEntries } = props;

    const TedLogBookColumns: TableColumn<ItedLogBookData>[] = [
        {
            name: 'Description',
            width: "650px",
            selector: row => row.title,
            wrap: true,
            cell: (row) => (
                <LogbookModalComponent row={row} />
            )
        },
        {
            name: "Transcript",
            width:"150px",
            selector: (row) => "Preview",
            cell: (row) => (
                <PreviewComponent row={row} />
            )
        },
        {
            name: "Category",
            selector: (row) => row.category.category,
            cell: (row) => (
                <div className="numbers">
                    <span className="badge text-black badge-secondary">{row.category.category}</span>
                </div>
            )
        },
        {
            name: "Date Added",
            selector: (row) => row.addedDate,
            cell: (row) => (
                <div className="numbers">
                    {row.addedDate ? row.addedDate : "NA"}
                </div>
            )
        },
    ];

    return (
        <>
            <div>
                <TableComponent
                    data={tedEntries}
                    columns={TedLogBookColumns}
                    tableTitle={""}
                    totalRows={tedEntries.length}
                    rowsPerpage={10}
                    handlePageChange={<></>}
                    handleRowsPerPage={<></>}
                    pageAction={<></>}
                    isPagination={false}
                />
            </div>
        </>
    );
}

export default TEDJournalEntryTableCompopnent;