import React, { useEffect, useState, useContext } from 'react';
import {
    Form,
    Col,
    Button,
    Spinner,
} from 'react-bootstrap';
import {
    createNotification
} from "../../../Utils/Toast";
import Lookup from '../../../Services/Lookup.service';
import ProgramMeeting from '../../../Services/ProgramMeeting.service';
import ErrorInputComponent from '../../FunctionalComponents/ErrorInput/ErrorInputComponent';
import General from '../../../Services/General.service';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./OrganisationMeeting.css";
import { useHistory } from 'react-router';
import { logOutIfTokenExpire } from '../../../Utils/Utils';
import { ThemeContext } from "../../../Contexts/ThemeContext";
import "../../../darkcustom.css"

interface Iprops {
    currentPage: number;
    rowsPerpage: number;
    userDetails: any;
    editMeetingData: any;
    getTotalProgramMeetingData: any;
    setEditData: (data: boolean) => void;
    setModalState: (data: boolean) => void;
    editData: boolean
}

interface IprogramList {
    id: number,
    label: string
}

interface ImoduleList {
    id: number,
    label: string
}

interface Igroup {
    id: number,
    name: string
}

const OrganisationMeeting = (props: Iprops) => {
    let history = useHistory();
    const generalService = new General();
    generalService.getIcons();
    const {
        editData,
        userDetails,
        editMeetingData,
        getTotalProgramMeetingData,
        setEditData,
        setModalState
    } = props;
    let dateMeetingStartTimeValue = "";
    let dateMeetingEndTimeValue = "";
    const lookup = new Lookup();
    const programMeeting = new ProgramMeeting();
    const [groups, setGroups] = useState<any[]>([]);
    const [programList, setProgramList] = useState<any[]>([]);
    const [moduleList, setModuleList] = useState<any[]>([]);
    const [moduleStepList, setModuleStepList] = useState<any[]>([]);
    const [selectedProgram, setSelectedProgram] = useState<number>(0);
    const [selectedProgramModule, setSelectedProgramModule] = useState<number>(0);
    const [selectedProgramModuleStep, setSelectedProgramModuleStep] = useState<number>(0);
    const [selectedGroup, setSelectedGroup] = useState<number>(0);
    const [meetingName, setMeetingName] = useState<string>("");
    const [meetingDescription, setMeetingDescription] = useState<string>("");
    const [location, setLocation] = useState<string>("");
    const [room, setRoom] = useState<string>("");
    //eslint-disable-next-line
    const [startDate, setStartDate] = useState(new Date());
    //eslint-disable-next-line
    const [startTimeDate, setStartTimeDate] = useState(new Date());
    //eslint-disable-next-line
    const [endTimeDate, setEndTimeDate] = useState(new Date(new Date().setHours(new Date().getHours() + 2)));
    const [startTime, setStartTime] = useState<string>("");
    const [endTime, setEndTime] = useState<string>("");
    const [validateTime, setValidateTime] = useState<any>({
        startTimeHour: 0,
        startTimeMinutes: 0,
    });
    const [showSpinner, setShowSpinner] = useState<boolean>(false);

    const [errorToggel, setErrorToggel] = useState<any>({
        selectedProgram: "",
        selectedProgramModule: "",
        selectedGroup: "",
        meetingName: "",
        meetingDescription: "",
        location: "",
        time: "",
        meetingDate: "",
        startTime: "",
        endTime: "",
        startTimeOrEndTime: ""
    });

    const [valueUpdated, setValueUpdated] = useState<any>({
        meetingName: false,
        meetingDescription: false,
        selectedProgram: false,
        selectedProgramModule: false,
        selectedGroup: false,
        location: false,
        room: false,
        meetingDate: false,
        meetingStartTime: false,
        meetingEndTime: false
    });

    const { theme } = useContext(ThemeContext);
    useEffect(() => {
        if (editData) {
            setStartDate(new Date(editMeetingData.meetingDate));
            setSelectedProgram(editMeetingData.meetingProgramId);
            setSelectedProgramModule(editMeetingData.meetingModuleId);
            setSelectedProgramModuleStep(editMeetingData.meetingModuleStepId);
            setSelectedGroup(editMeetingData.meetingGroupId);
            setMeetingName(editMeetingData.meetingName);
            setMeetingDescription(editMeetingData.meetingDescription);
            setLocation(editMeetingData.meetingLocation);
            setRoom(editMeetingData.meetingRoom);
            setStartTime(editMeetingData.meetingStartTime);
            setEndTime(editMeetingData.meetingEndTime);
            if (editMeetingData.meetingDate) {
                let tempDate = editMeetingData.meetingDate.split("/");
                let currentDate = new Date(tempDate[2], tempDate[1] - 1, tempDate[0]);
                setStartDate(currentDate);
            }
        }
        //eslint-disable-next-line
    }, [editData]);

    useEffect(() => {
        logOutIfTokenExpire();
        getGroups();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        programsListOptions();
        if (selectedProgram) {
            programModule();
        }

        //eslint-disable-next-line
    }, [selectedProgram]);

    useEffect(() => {
        if (selectedProgramModule) {
            programModuleSteps();
        }
        //eslint-disable-next-line
    }, [selectedProgramModule]);

    const getGroups = async () => {
        if (userDetails?.organisationId) {
            let groupResult: any;
            groupResult = await lookup.getGroups();
            if (groupResult) {
                setGroups(groupResult);
            }
        }
    };

    const programsListOptions = async () => {
        let result: any = await lookup.getProgramsList();
        if (result.status) {
            let programLists: any[] = result.data.map((value: any) => {
                return {
                    id: value.id,
                    label: value.name
                }
            })
            setProgramList(programLists);
        } else {
            createNotification("error", result?.message);
        }
    };

    const programModule = async () => {
        let result: any = await lookup.getProgramModule(selectedProgram);
        if (result.status) {
            let moduleLists: any[] = result.data.map((value: any) => {
                return {
                    id: value.id,
                    label: value.name
                }
            })
            setModuleList(moduleLists);
        } else {
            createNotification("error", result?.message);
        }
    };

    const programModuleSteps = async () => {
        let result: any = await lookup.getProgramModuleSteps(selectedProgramModule);
        if (result.status) {
            let moduleSteps: any[] = result.data.filter((value: any) => {
                return value.name === "Engage";
            });
            setModuleStepList(moduleSteps);
        } else {
            createNotification("error", result?.message);
        }
    };

    //eslint-disable-next-line
    const programFaciltorGroup = async () => {
        let result: any = await lookup.getProgramsFaciltorGroup("facilitatorId");
        if (result?.status) {
            let moduleSteps: any[] = [];
            moduleSteps = result?.data?.map((value: any) => {
                return {
                    id: value.id,
                    label: value.name
                }
            })
            setModuleStepList(moduleSteps);
        } else {
            createNotification("error", result?.message);
        }
    };

    const handleMeetingTime = (newValue: any, type: string) => {
        if (type === "StartTime") {
            let startingHour = newValue?.getHours();
            let startingMinutes = newValue?.getMinutes();
            //eslint-disable-next-line
            let startingSeconds = newValue?.getSeconds();
            setStartTime(`${startingHour}:${startingMinutes}`);
            setValidateTime((states: any) => {
                return {
                    ...states,
                    startTimeHour: Number(startingHour),
                }
            });
        } else {
            let endingHour = newValue?.getHours();
            let endingMinutes = newValue?.getMinutes();
            let endingSeconds = newValue?.getSeconds();
            setEndTime(`${endingHour}:${endingMinutes}:${endingSeconds}`);
            setValidateTime((states: any) => {
                return {
                    ...states,
                    endTimeHour: Number(endingHour),
                }
            });
        }
    }

    const handleModuleAndName = (value: string) => {
        setSelectedProgramModule(parseInt(value));
        let tempModuleName = moduleList.filter((data: any) => data.id === parseInt(value));
        let tempMeetingName = `${tempModuleName[0].label} Meeting`;
        setMeetingName(tempMeetingName);
    }

    const validateForm = async () => {
        if (selectedProgram === 0) {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    selectedProgram: "Please select program"
                }
            })
            return false;
        } else {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    selectedProgram: ""
                }
            })
        }

        if (selectedProgramModule === 0) {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    selectedProgramModule: "Please select program module"
                }
            })
            return false;
        } else {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    selectedProgramModule: ""
                }
            })
        }

        if (selectedGroup === 0) {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    selectedGroup: "Please select group"
                }
            })
            return false;
        } else {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    selectedGroup: ""
                }
            })
        }

        if (startTime.length === 0 && dateMeetingStartTimeValue.length === 0) {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    startTime: "Please select start time"
                }
            })
            return false;
        } else {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    startTime: ""
                }
            })
        }

        if (endTime.length === 0 && dateMeetingEndTimeValue.length === 0) {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    endTime: "Please select end time"
                }
            })
            return false;
        } else {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    endTime: ""
                }
            })
        }

        if (validateTime?.startTimeHour > (validateTime?.endTimeHour)) {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    startTimeOrEndTime: "End hour must be greater than the start hour"
                }
            })
            return false;
        } else {
            /*  setErrorToggel((errStates: any) => {
                 return {
                     ...errStates,
                     startTimeOrEndTime: ""
                 }
             }) */
        }

        return true;
    }


    const isValueUpdated = async () => {
        if (valueUpdated.selectedProgram || valueUpdated.selectedProgramModuleStep || valueUpdated.selectedProgramModule || valueUpdated.selectedGroup || valueUpdated.meetingName || valueUpdated.meetingDescription || valueUpdated.location || valueUpdated.room || valueUpdated.meetingDate || valueUpdated.meetingStartTime || valueUpdated.meetingEndTime) {
            return true;
        } else {
            return false;
        }
    }


    const SubmitFormHandler = async (event: any) => {
        event.preventDefault();
        let dateMeetingStartTime: any = document.getElementById('dateMeetingStartTime');
        let dateMeetingEndTime: any = document.getElementById('dateMeetingEndTime');
        let timeIndicator = "";
        if (dateMeetingStartTime) {
            timeIndicator = dateMeetingStartTime.defaultValue.split(' ')[1];
            if (timeIndicator === 'PM') {
                let tempTime = dateMeetingStartTime.defaultValue.split(' ')[0];
                let tempHour: string = tempTime.split(':')[0];
                if (Number(tempTime.split(':')[0]) !== 12) {
                    tempHour = String(Number(tempTime.split(':')[0]) + 12);
                }

                //if (tempHour === '24') tempHour = '00';
                dateMeetingStartTimeValue = tempHour + ":" + tempTime.split(':')[1];
            } else {
                dateMeetingStartTimeValue = dateMeetingStartTime.defaultValue.split(' ')[0];
            }
        }

        if (dateMeetingEndTime) {
            timeIndicator = dateMeetingEndTime.defaultValue.split(' ')[1];
            if (timeIndicator === 'PM') {
                let tempTime = dateMeetingEndTime.defaultValue.split(' ')[0];
                let tempHour: string = tempTime.split(':')[0];
                if (Number(tempTime.split(':')[0]) !== 12) {
                    tempHour = String(Number(tempTime.split(':')[0]) + 12);
                }
                //if (tempHour === '24') tempHour = '00';
                dateMeetingEndTimeValue = tempHour + ":" + tempTime.split(':')[1];
            } else {
                dateMeetingEndTimeValue = dateMeetingEndTime.defaultValue.split(' ')[0];
            }
        }
        if (editData) {
            let tempDate: any = new Date(startDate);
            let year = tempDate.getFullYear();
            let month = (tempDate.getMonth() + 1).toString().padStart(2, "0");
            let date = tempDate.getDate().toString().padStart(2, "0");;
            let editDate = `${year}-${month}-${date}`;
            let editMeetingValue: any = {
                id: editMeetingData.meetingId,
                program: { id: selectedProgram },
                module: { id: selectedProgramModule },
                moduleStep: { id: selectedProgramModuleStep },
                group: { id: selectedGroup },
                name: meetingName,
                description: meetingDescription,
                location: location,
                room: room,
                meetingDate: editDate,
                startTime: startTime,
                endTime: endTime
            }
            if (await isValueUpdated()) {
                setShowSpinner(true);
                let response: any = await programMeeting.updateProgramMeeting(editMeetingValue);
                if (response?.status) {
                    setShowSpinner(false);
                    getTotalProgramMeetingData();
                    setEditData(false);
                    setModalState(false);
                    createNotification("success", response?.message);
                } else {
                    setShowSpinner(false);
                    setEditData(false);
                    setModalState(false);
                    createNotification("error", response?.message);
                }
            } else {
                setShowSpinner(false);
                setEditData(false);
                setModalState(false);
                createNotification("info", "You have not updated anything!");
            }
        } else {
            if (await validateForm()) {
                setShowSpinner(true);
                let tempDate: any = new Date(startDate);
                let year = tempDate.getFullYear();
                let month = (tempDate.getMonth() + 1).toString().padStart(2, "0");
                let date = tempDate.getDate().toString().padStart(2, "0");
                let createDate = `${year}-${month}-${date}`;
                let result: any;
                let createMeetingData: any = {
                    program: { id: selectedProgram },
                    module: { id: selectedProgramModule },
                    moduleStep: { id: moduleStepList[0]?.id },
                    group: { id: selectedGroup },
                    name: meetingName,
                    description: meetingDescription,
                    location: location,
                    room: room,
                    meetingDate: createDate,
                    startTime: dateMeetingStartTimeValue,
                    endTime: dateMeetingEndTimeValue
                }
                result = await programMeeting.createProgramMeeting(createMeetingData);
                if (result?.status) {
                    setShowSpinner(false);
                    getTotalProgramMeetingData();
                    setEditData(false);
                    setModalState(false);
                    createNotification("success", result.message);
                } else {
                    setShowSpinner(false);
                    setEditData(false);
                    setModalState(false);
                    createNotification("error", result.message);
                }
            }
        }
    }

    return (
        <>
            <div className="row justify-content-center">
                <div>
                    <section>
                        <form id="account" autoComplete="off">
                            {theme === "light" ? (
                                <>
                                    <Form.Group as={Col} controlId="formGridGroup" className="mb-3">
                                        <Form.Label>Select Program</Form.Label>
                                        <Form.Select value={selectedProgram}
                                            onChange={(e: any) => {
                                                setSelectedProgram(parseInt(e.target.value));
                                                setValueUpdated((states: any) => {
                                                    return {
                                                        ...states,
                                                        selectedProgram: true
                                                    }
                                                });
                                            }}
                                        >
                                            <option value={"0"}>{"Select Program"}</option>
                                            {programList?.map((programList: IprogramList) => <option key={programList.id} value={programList.id}>{programList.label}</option>)}
                                        </Form.Select>
                                        {errorToggel.selectedProgram && <ErrorInputComponent error={errorToggel.selectedProgram} />}
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridGroup" className="mb-3">
                                        <Form.Label>Select Module</Form.Label>
                                        <Form.Select value={selectedProgramModule}
                                            onChange={(e: any) => {
                                                handleModuleAndName(e.target.value);

                                                setValueUpdated((states: any) => {
                                                    return {
                                                        ...states,
                                                        selectedProgramModule: true
                                                    }
                                                });
                                            }}
                                        >
                                            <option value={"0"}>{"Select Program Module"}</option>
                                            {moduleList?.map((moduleList: ImoduleList) => <option key={moduleList.id} value={moduleList.id}>{moduleList.label}</option>)}
                                        </Form.Select>
                                        {errorToggel.selectedProgramModule && <ErrorInputComponent error={errorToggel.selectedProgramModule} />}
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Name of meeting</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={meetingName || ""}
                                            placeholder="Enter Meeting Name"
                                            onChange={(e) => {
                                                setMeetingName(e.target.value);
                                                setValueUpdated((states: any) => {
                                                    return {
                                                        ...states,
                                                        meetingName: true
                                                    }
                                                });
                                            }}
                                        />
                                        {errorToggel.meetingName && <ErrorInputComponent error={errorToggel.meetingName} />}
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Description of meeting</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={meetingDescription || ""}
                                            placeholder="Enter Meeting Description"
                                            onChange={(e) => {
                                                setMeetingDescription(e.target.value);
                                                setValueUpdated((states: any) => {
                                                    return {
                                                        ...states,
                                                        meetingDescription: true
                                                    }
                                                });
                                            }}
                                        />
                                        {errorToggel.meetingDescription && <ErrorInputComponent error={errorToggel.meetingDescription} />}
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridGroup" className="mb-3">
                                        <Form.Label>Select Group</Form.Label>
                                        <Form.Select value={selectedGroup}
                                            onChange={(e) => {
                                                setSelectedGroup(parseInt(e.target.value))
                                                e.preventDefault();
                                                setValueUpdated((states: any) => {
                                                    return {
                                                        ...states,
                                                        selectedGroup: true
                                                    }
                                                });
                                            }}
                                        >
                                            <option value={"0"}>{"Select Group"}</option>
                                            {groups?.map((group: Igroup) => <option key={group.id} value={group.id}>{group.name}</option>)}
                                        </Form.Select>
                                        {errorToggel.selectedGroup && <ErrorInputComponent error={errorToggel.selectedGroup} />}
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Enter Location</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={location || ""}
                                            placeholder="Enter Location"
                                            onChange={(e) => {
                                                setLocation(e.target.value);
                                                setValueUpdated((states: any) => {
                                                    return {
                                                        ...states,
                                                        location: true
                                                    }
                                                });
                                            }}
                                        />
                                        {errorToggel.location && <ErrorInputComponent error={errorToggel.location} />}
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Enter Room</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={room || ""}
                                            placeholder="Enter Room"
                                            onChange={(e) => {
                                                setRoom(e.target.value);
                                                setValueUpdated((states: any) => {
                                                    return {
                                                        ...states,
                                                        room: true
                                                    }
                                                });
                                            }}
                                        />
                                        {errorToggel.room && <ErrorInputComponent error={errorToggel.room} />}
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Select Meeting Date</Form.Label>
                                        <div className="date-picker-wrapper position-relative">
                                            <DatePicker
                                                selected={startDate}
                                                dateFormat="dd/MM/yyyy"
                                                className="date-picker-input w-100"
                                                placeholderText='Select Date'
                                                popperPlacement="top"
                                                strictParsing
                                                minDate={new Date()}
                                                showDisabledMonthNavigation
                                                shouldCloseOnSelect={false}
                                                closeOnScroll={true}
                                                onChange={(newValue: any) => {
                                                    setStartDate(newValue);
                                                    setValueUpdated((states: any) => {
                                                        return {
                                                            ...states,
                                                            meetingDate: true
                                                        }
                                                    });
                                                }}
                                                popperModifiers={[
                                                    {
                                                        name: "offset",
                                                        options: {
                                                            offset: [5, 5],
                                                        },
                                                    },
                                                    {
                                                        name: "preventOverflow",
                                                        options: {
                                                            rootBoundary: "viewport",
                                                            tether: false,
                                                            altAxis: true,
                                                        },
                                                    },
                                                ]}
                                            />
                                            <div className="text-center">
                                                <Form.Text id="timeDurationHelpBlock" muted style={{ fontSize: "12px" }}>
                                                    Please select future date
                                        </Form.Text>
                                            </div>
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <div className='d-flex justify-content-between w-100'>
                                            <div className='w-30 text-center'>
                                                <Form.Label>Select Start Time</Form.Label>
                                                <DatePicker
                                                    id={'dateMeetingStartTime'}
                                                    selected={startTimeDate}
                                                    placeholderText="Start Time"
                                                    className="date-picker-input"
                                                    onChange={(newValue: any) => {
                                                        handleMeetingTime(newValue, "StartTime");
                                                        setStartTimeDate(newValue);
                                                        setValueUpdated((states: any) => {
                                                            return {
                                                                ...states,
                                                                meetingStartTime: true
                                                            }
                                                        });
                                                    }}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    popperPlacement="top"
                                                    strictParsing
                                                    timeIntervals={5}
                                                    timeCaption="Start Time"
                                                    dateFormat="h:mm a"
                                                    popperModifiers={[
                                                        {
                                                            name: "offset",
                                                            options: {
                                                                offset: [5, 5],
                                                            },
                                                        },
                                                        {
                                                            name: "preventOverflow",
                                                            options: {
                                                                rootBoundary: "viewport",
                                                                tether: false,
                                                                altAxis: true,
                                                            },
                                                        },
                                                    ]}
                                                />
                                                {errorToggel.startTime && <ErrorInputComponent error={errorToggel.startTime} />}
                                            </div>
                                            <div className='w-30 text-center'>
                                                <Form.Label>Select End Time</Form.Label>
                                                <DatePicker
                                                    id={'dateMeetingEndTime'}
                                                    selected={endTimeDate}
                                                    placeholderText="End Time"
                                                    className="date-picker-input"
                                                    onChange={(newValue: any) => {
                                                        handleMeetingTime(newValue, "EndTime");
                                                        setEndTimeDate(newValue);
                                                        setValueUpdated((states: any) => {
                                                            return {
                                                                ...states,
                                                                meetingEndTime: true
                                                            }
                                                        });
                                                    }}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    popperPlacement="top"
                                                    strictParsing
                                                    timeIntervals={5}
                                                    timeCaption="End Time"
                                                    dateFormat="h:mm a"
                                                    popperModifiers={[
                                                        {
                                                            name: "offset",
                                                            options: {
                                                                offset: [5, 5],
                                                            },
                                                        },
                                                        {
                                                            name: "preventOverflow",
                                                            options: {
                                                                rootBoundary: "viewport",
                                                                tether: false,
                                                                altAxis: true,
                                                            },
                                                        },
                                                    ]}
                                                />
                                                {errorToggel.endTime && <ErrorInputComponent error={errorToggel.endTime} />}
                                            </div>
                                        </div>
                                    </Form.Group>
                                </>
                            ) : <>
                                    <Form.Group as={Col} controlId="formGridGroup" className="mb-3">
                                        <Form.Label>Select Program</Form.Label>
                                        <Form.Select className="userSearchBox" value={selectedProgram}
                                            onChange={(e: any) => {
                                                setSelectedProgram(parseInt(e.target.value));
                                                setValueUpdated((states: any) => {
                                                    return {
                                                        ...states,
                                                        selectedProgram: true
                                                    }
                                                });
                                            }}
                                        >
                                            <option value={"0"}>{"Select Program"}</option>
                                            {programList?.map((programList: IprogramList) => <option key={programList.id} value={programList.id}>{programList.label}</option>)}
                                        </Form.Select>
                                        {errorToggel.selectedProgram && <ErrorInputComponent error={errorToggel.selectedProgram} />}
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridGroup" className="mb-3">
                                        <Form.Label>Select Module</Form.Label>
                                        <Form.Select className="userSearchBox" value={selectedProgramModule}
                                            onChange={(e: any) => {
                                                handleModuleAndName(e.target.value);

                                                setValueUpdated((states: any) => {
                                                    return {
                                                        ...states,
                                                        selectedProgramModule: true
                                                    }
                                                });
                                            }}
                                        >
                                            <option value={"0"}>{"Select Program Module"}</option>
                                            {moduleList?.map((moduleList: ImoduleList) => <option key={moduleList.id} value={moduleList.id}>{moduleList.label}</option>)}
                                        </Form.Select>
                                        {errorToggel.selectedProgramModule && <ErrorInputComponent error={errorToggel.selectedProgramModule} />}
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Name of meeting</Form.Label>
                                        <Form.Control className="userSearchBox"
                                            type="text"
                                            value={meetingName || ""}
                                            placeholder="Enter Meeting Name"
                                            onChange={(e) => {
                                                setMeetingName(e.target.value);
                                                setValueUpdated((states: any) => {
                                                    return {
                                                        ...states,
                                                        meetingName: true
                                                    }
                                                });
                                            }}
                                        />
                                        {errorToggel.meetingName && <ErrorInputComponent error={errorToggel.meetingName} />}
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Description of meeting</Form.Label>
                                        <Form.Control
                                            className="userSearchBox"
                                            type="text"
                                            value={meetingDescription || ""}
                                            placeholder="Enter Meeting Description"
                                            onChange={(e) => {
                                                setMeetingDescription(e.target.value);
                                                setValueUpdated((states: any) => {
                                                    return {
                                                        ...states,
                                                        meetingDescription: true
                                                    }
                                                });
                                            }}
                                        />
                                        {errorToggel.meetingDescription && <ErrorInputComponent error={errorToggel.meetingDescription} />}
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridGroup" className="mb-3">
                                        <Form.Label>Select Group</Form.Label>
                                        <Form.Select value={selectedGroup}
                                            className="userSearchBox"
                                            onChange={(e) => {
                                                setSelectedGroup(parseInt(e.target.value))
                                                e.preventDefault();
                                                setValueUpdated((states: any) => {
                                                    return {
                                                        ...states,
                                                        selectedGroup: true
                                                    }
                                                });
                                            }}
                                        >
                                            <option value={"0"}>{"Select Group"}</option>
                                            {groups?.map((group: Igroup) => <option key={group.id} value={group.id}>{group.name}</option>)}
                                        </Form.Select>
                                        {errorToggel.selectedGroup && <ErrorInputComponent error={errorToggel.selectedGroup} />}
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Enter Location</Form.Label>
                                        <Form.Control
                                            type="text"
                                            className="userSearchBox"
                                            value={location || ""}
                                            placeholder="Enter Location"
                                            onChange={(e) => {
                                                setLocation(e.target.value);
                                                setValueUpdated((states: any) => {
                                                    return {
                                                        ...states,
                                                        location: true
                                                    }
                                                });
                                            }}
                                        />
                                        {errorToggel.location && <ErrorInputComponent error={errorToggel.location} />}
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Enter Room</Form.Label>
                                        <Form.Control
                                            className="userSearchBox"
                                            type="text"
                                            value={room || ""}
                                            placeholder="Enter Room"
                                            onChange={(e) => {
                                                setRoom(e.target.value);
                                                setValueUpdated((states: any) => {
                                                    return {
                                                        ...states,
                                                        room: true
                                                    }
                                                });
                                            }}
                                        />
                                        {errorToggel.room && <ErrorInputComponent error={errorToggel.room} />}
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Select Meeting Date</Form.Label>
                                        <div className="date-picker-wrapper position-relative">
                                            <DatePicker
                                                selected={startDate}
                                                dateFormat="dd/MM/yyyy"
                                                className="date-picker-input w-100 dateInputBox"
                                                placeholderText='Select Date'
                                                popperPlacement="top"
                                                strictParsing
                                                minDate={new Date()}
                                                showDisabledMonthNavigation
                                                shouldCloseOnSelect={false}
                                                closeOnScroll={true}
                                                onChange={(newValue: any) => {
                                                    setStartDate(newValue);
                                                    setValueUpdated((states: any) => {
                                                        return {
                                                            ...states,
                                                            meetingDate: true
                                                        }
                                                    });
                                                }}
                                                popperModifiers={[
                                                    {
                                                        name: "offset",
                                                        options: {
                                                            offset: [5, 5],
                                                        },
                                                    },
                                                    {
                                                        name: "preventOverflow",
                                                        options: {
                                                            rootBoundary: "viewport",
                                                            tether: false,
                                                            altAxis: true,
                                                        },
                                                    },
                                                ]}
                                            />
                                            <div className="text-center">
                                                <Form.Text id="timeDurationHelpBlock" muted style={{ fontSize: "12px", color: "#fff !important" }}>
                                                    Please select future date
                                        </Form.Text>
                                            </div>
                                        </div>
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <div className='d-flex justify-content-between w-100'>
                                            <div className='w-30 text-center'>
                                                <Form.Label>Select Start Time</Form.Label>
                                                <DatePicker
                                                    id={'dateMeetingStartTime'}
                                                    selected={startTimeDate}
                                                    placeholderText="Start Time"
                                                    className="date-picker-input dateInputBox"
                                                    onChange={(newValue: any) => {
                                                        handleMeetingTime(newValue, "StartTime");
                                                        setStartTimeDate(newValue);
                                                        setValueUpdated((states: any) => {
                                                            return {
                                                                ...states,
                                                                meetingStartTime: true
                                                            }
                                                        });
                                                    }}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    popperPlacement="top"
                                                    strictParsing
                                                    timeIntervals={5}
                                                    timeCaption="Start Time"
                                                    dateFormat="h:mm a"
                                                    popperModifiers={[
                                                        {
                                                            name: "offset",
                                                            options: {
                                                                offset: [5, 5],
                                                            },
                                                        },
                                                        {
                                                            name: "preventOverflow",
                                                            options: {
                                                                rootBoundary: "viewport",
                                                                tether: false,
                                                                altAxis: true,
                                                            },
                                                        },
                                                    ]}
                                                />
                                                {errorToggel.startTime && <ErrorInputComponent error={errorToggel.startTime} />}
                                            </div>
                                            <div className='w-30 text-center'>
                                                <Form.Label>Select End Time</Form.Label>
                                                <DatePicker
                                                    id={'dateMeetingEndTime'}
                                                    selected={endTimeDate}
                                                    placeholderText="End Time"
                                                    className="date-picker-input dateInputBox"
                                                    onChange={(newValue: any) => {
                                                        handleMeetingTime(newValue, "EndTime");
                                                        setEndTimeDate(newValue);
                                                        setValueUpdated((states: any) => {
                                                            return {
                                                                ...states,
                                                                meetingEndTime: true
                                                            }
                                                        });
                                                    }}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    popperPlacement="top"
                                                    strictParsing
                                                    timeIntervals={5}
                                                    timeCaption="End Time"
                                                    dateFormat="h:mm a"
                                                    popperModifiers={[
                                                        {
                                                            name: "offset",
                                                            options: {
                                                                offset: [5, 5],
                                                            },
                                                        },
                                                        {
                                                            name: "preventOverflow",
                                                            options: {
                                                                rootBoundary: "viewport",
                                                                tether: false,
                                                                altAxis: true,
                                                            },
                                                        },
                                                    ]}
                                                />
                                                {errorToggel.endTime && <ErrorInputComponent error={errorToggel.endTime} />}
                                            </div>
                                        </div>
                                    </Form.Group>
                                </>
                            }

                            <div className="d-flex justify-content-center">
                                {errorToggel.startTimeOrEndTime && <ErrorInputComponent error={errorToggel.startTimeOrEndTime} />}
                            </div>

                            <div className="d-grid gap-2 mt-1">
                                {
                                    showSpinner ?
                                        <Button variant="primary" className="text-secondary" disabled>
                                            <span className="mx-2 text-secondary">
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                            {editData ? 'Editing meeting data...' : 'Creating meeting...'}
                                        </Button>
                                        :
                                        <Button
                                            variant="primary"
                                            type="button"
                                            onClick={(e: any) => SubmitFormHandler(e)}
                                        >
                                            {editData ? 'Edit Meeting' : 'Create Meeting'}
                                        </Button>
                                }
                            </div>
                        </form>
                    </section>
                </div>
            </div>
        </>
    );

};

export default OrganisationMeeting;