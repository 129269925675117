import React from "react";
import { Headers } from "../../../Utils/Enums";
import HeaderComponent from "../Header/HeaderComponent";
import UserScoreTableComponent from "../UserScoreTableComponent/UserScoreTableComponent";
import "./UserScoreComponent.css";

interface Iprops {
    personalScoreData: any[];
    personalScore: boolean;
}
function PersonalScoreComponent(props: Iprops) {
    const { personalScoreData } = props;
    return (
        <>
            <div className="mt-5">
                <div className="d-flex flex-sm-row flex-column justify-content-between align-items">
                    <div className="d-flex">
                        <HeaderComponent title="PERSONAL SCORES" size={Headers.H3} />
                    </div>                     
                </div>
                <div>
                    <UserScoreTableComponent
                        personalScoreData={personalScoreData}
                        personalScore={true}
                    />
                </div>
            </div>
        </>
    );
}

export default PersonalScoreComponent;

