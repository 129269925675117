import React from 'react';
import CircleImageComponent from '../CircleImage/CircleImageComponent';
import PopoverSearchPracticeComponent from '../PopoverSearchPractice/PopoverSearchPracticeComponent';
import TextComponent from '../Text/TextComponent';
import './ContactPracticeItemComponent.css';

export interface IContactPracticeComponent {
    firstName: string;
    lastName: string;
    searchData?: string;
    profilePictureLocation: string;
    contactId: string;
    additionalParam: string;
}

const ContactPracticeItemComponent = (props: IContactPracticeComponent) => {

    return <>
        <div className="listItemConfig currentPractice w-100 border-bottom-EBEBEB-thin d-flex flex-column justify-content-center">
            <div className="d-flex justify-content-between">
                <div className="d-flex">
                    <div className="margin-left-8">
                        <CircleImageComponent size={'imageSize49'} image={"/file?fileName="+props.profilePictureLocation} />
                    </div>
                    <div className={`d-flex flex-column justify-content-center margin-left-8`}>
                        <TextComponent text={props.firstName + " " + props.lastName} />
                    </div>
                </div>
                <div className="d-flex">
                    <PopoverSearchPracticeComponent
                        url = {props.searchData?"/profile/" + props.contactId  + "/" + props.searchData + "/singlePractice/" + props.additionalParam:"/profile/" + props.contactId  + "/singlePractice/" + props.additionalParam}
                        canAddContacts={false}
                        item={{id:props.contactId,firstName:'',lastName:'',email:'',profilePictureLocation:'',isContact:false}}
                    />
                </div>
            </div>
        </div>
    </>
}
export default ContactPracticeItemComponent;