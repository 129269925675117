import React, { useRef, useState } from "react";
import isEmail from 'validator/lib/isEmail';
import ErrorInputComponent from "../ErrorInput/ErrorInputComponent";
import stylesFunctionalComponents from '../FunctionalComponents.module.css';

export interface IEmailData {
    value?: string;
    isEmailState?: boolean;
};

interface IEmailInputComponent {
    value?: string;
    error?: boolean;
    label?: string;
    onValidationHandler: (data: IEmailData) => void;
};

const EmailInputComponent = (props: IEmailInputComponent) => {
    const [emailError, setEmailError] = useState('');
    const nameInputRef = useRef<HTMLInputElement | null>(null);

    let data = {
        isEmailState: true,
        value: nameInputRef.current?.value
    }
    const ChangeEventHandler = (event: any) => {
        const enteredValue = String(nameInputRef.current?.value);
        data.isEmailState = isEmail(enteredValue);
        //eslint-disable-next-line
        const checkCondition = isEmail(enteredValue) ? setEmailError('') : setEmailError('Please, enter valid email!');
        data.value = enteredValue;
        props.onValidationHandler(data);
    }
    return <>
        <div className="form-group mb-3">
            <label className="display-none mb-1">{props.label}</label>
            <input
                role="textbox"
                ref={nameInputRef}
                placeholder="Email Address"
                className={`form-control input-field ${emailError.length > 0 && stylesFunctionalComponents.form_control_danger}`}
                onBlur={ChangeEventHandler} />
            <ErrorInputComponent error={emailError} />
        </div>
    </>
}

export default EmailInputComponent;


