import React, { useEffect, useState, useReducer, useContext } from 'react';
import HeaderComponent from '../../FunctionalComponents/Header/HeaderComponent';
import { Headers, IsInMyContactResponeEnum } from '../../../Utils/Enums';
import HorizontalShellComponent from '../../FunctionalComponents/HorizontalShell/HorizontalShellComponent';
import Profile from '../../../Services/Profile.service'
import LoaderComponent from '../../FunctionalComponents/Loader/LoaderComponent';
import ErrorInputComponent from '../../FunctionalComponents/ErrorInput/ErrorInputComponent';
import EasyCardComponent from '../../FunctionalComponents/EasyCard/EasyCardComponent';
import PracticeProfileItemComponent from '../../FunctionalComponents/PracticeProfileItem/PracticeProfileItemComponent';
import ContentShellComponent from '../../FunctionalComponents/ContentShell/ContentShellComponent';
import TextComponent from '../../FunctionalComponents/Text/TextComponent';
// import ProfileCharacteristicComponent from '../../FunctionalComponents/ProfileCharacteristic/ProfileCharacteristicComponent';
import PaginationComponent from '../../FunctionalComponents/Paginator/PaginationComponent';
import UserPractice from '../../../Services/UserPractice.service';
import { ceil } from 'lodash';
import Engage from '../../../Services/Engage.service';
import { createNotification } from '../../../Utils/Toast';
import { useHistory } from "react-router-dom";
import TEDModalComponent from '../../FunctionalComponents/TEDModal/TEDModalComponent';
import './ProfileComponent.css'
import ProfileCharacteristicComponent from '../../FunctionalComponents/ProfileCharacteristic/ProfileCharacteristicComponent';
import ProfileCharacteristicsModalComponent from '../../FunctionalComponents/ProfileCharacteristics/ProfileCharacteristicsModalComponent';
import General from '../../../Services/General.service';
import { logOutIfTokenExpire } from '../../../Utils/Utils';
import { UserContext } from '../../../Contexts/UserContext';
import { ThemeContext } from '../../../Contexts/ThemeContext';

const okReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'SetOKSuccess':
            return action.okSuccsess;
        case 'SetOKFailure':
            return action.okFailure;
        default:
            return { value: "loading", dataModule: null }
    }
}

const ProfileComponent = (props: any) => {
    const generalService = new General();
    const history = useHistory();
    let result: any;
    let profile = new Profile();
    let practice = new UserPractice();
    let engage = new Engage();
    const userId = props.match.params.user;
    const search = props.match.params.search;
    const [imageOk, setImageOK] = useState(true);
    const [currentUserId, setCurrentUserId] = useState('');
    const [chars, setChars] = useState<any[]>([]);
    const [okState, dispatchOK] = useReducer(okReducer, { value: "loading", dataModule: null });

    let rowsPerPage: number = 10;
    let parsedUserDetails: any;
    const [userPractice, setUserPractice] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [userInContact, setUserInContact] = useState<number>(IsInMyContactResponeEnum.USER_NOT_IN_CONTACT);
    const userContext = useContext(UserContext);
    const { theme } = useContext(ThemeContext);

    useEffect(() => {
        userContext.dispatch('');
    }, [])

    useEffect(() => {
        logOutIfTokenExpire();
        getData();
        getUserPractice();
        //eslint-disable-next-line
    }, [okState.value === 'success']);

    const getData = async () => {
        let intentionResult = await generalService.getProfileIntention();
        if (intentionResult.data.introCompleted) {
            generalService.getIcons();
        } else {
            let iconsPanel = document.getElementById('icons-panel');
            iconsPanel!!.classList.add("d-none");
        }

        let userDetailsLocalStorage = localStorage.getItem("userConfig");
        if (userDetailsLocalStorage) {
            parsedUserDetails = JSON.parse(userDetailsLocalStorage);
        }

        if (!parsedUserDetails) {
            return dispatchOK({ type: 'SetOKFailure', okFailure: { value: 'failed' } });
        }
        setCurrentUserId(parsedUserDetails?.id);

        if (parsedUserDetails?.id !== window.location.pathname.split("/")[2]) {
            checkInContact();
        }

        if (currentUserId !== userId) {
            //eslint-disable-next-line
            let charsResult = await profile.compareUserCharacteristics(userId);
            setChars(charsResult.data);
            result = await profile.getProfileById(userId);
        } else {
            //eslint-disable-next-line
            let charsResult = await profile.getUserCharacteristicsForProfile();
            setChars(charsResult.data);
            result = await profile.getProfile();
        }

        if (!parsedUserDetails) return dispatchOK({ type: 'SetOKFailure', okFailure: { value: 'failed' } });
        dispatchOK({ type: 'SetOKSuccess', okSuccsess: { value: 'success', data: result } });
    };

    const setWrongImageFormatError = async (isImageOK: boolean) => {
        setImageOK(isImageOK);
    }

    const setSuccsessUpload = () => {
        // dispatchOK({ type: 'SetOKSuccess', okSuccsess: { value: 'success', data: result } });
        getData();
    }

    const onPageHandler = (currentPageId: number) => {
        setCurrentPage(currentPageId);
        setUserPractice([...userPractice])
    }

    const getUserPractice = async () => {
        let response: any;
        if (currentUserId !== userId) {
            response = await practice.getUserPracticeForProfile(userId);
        } else {
            response = await practice.getUserPracticeForProfile(currentUserId);
        }
        if (response.length > 0) {
            let completedPracteces = response.filter((x: any) => x.stauts === 'Completed');
            let inProgress = response.filter((x: any) => x.stauts === 'InProgress');
            setUserPractice(inProgress.concat(completedPracteces));

            setTotalItems(response.length);
            if (response.length > rowsPerPage) {
                let tempCalc: number = (response.length / rowsPerPage);
                setTotalPages(ceil(tempCalc));
            }
        }
    }

    const addToNetwork = async () => {
        let response: any = await engage.addContact(userId);
        if (response.status) {
            setUserInContact(IsInMyContactResponeEnum.USER_IN_CONTACT);
            createNotification("success", response.message);
        } else {
            createNotification("error", response.message);
        }
    }

    const navigate = () => {
        if (!props.match.params.referrer && !search) {
            return window.history.back();
        }
        if (props.match.params.referrer === "engage") {
            if (search) {
                if (props.match.params.additionalparam) {
                    return history.push("/" + props.match.params.referrer + "/" + props.match.params.additionalparam + "/" + search);
                } else {
                    return history.push("/" + props.match.params.referrer + "/" + search)
                }
            } else {
                return history.push("/" + props.match.params.referrer)
            }
        } else {
            if (props.match.params.additionalparam) {
                return history.push("/" + props.match.params.referrer + "/" + props.match.params.additionalparam + "/" + search + "/");
            } else {
                return history.push("/" + search + "/" + props.match.params.referrer);
            }

        }


    }
    const addBackground = (event: any) => {
        let myCharacteristicsBlock = document.getElementById('myCharacteristicsBlock');
        let myprofileCharacteristics = document.getElementById('myprofileCharacteristics');

        if (myCharacteristicsBlock && myprofileCharacteristics) {
            if (event.target.checked) {
                myCharacteristicsBlock.classList.add("background-FFEAE5");
                myprofileCharacteristics.classList.remove("d-none");
            } else {
                myCharacteristicsBlock.classList.remove("background-FFEAE5");
                myprofileCharacteristics.classList.add("d-none");
            }
        }
    }

    const checkInContact = async () => {
        let response: any = await profile.IsInMyContact(userId);
        setUserInContact(response.data);
    }

    return <>
        {okState.value === 'loading' && <LoaderComponent />}
        {okState.value === 'failed' && <div>
            <div className="mb-4">
                <ErrorInputComponent error={"An error occurred"} />
            </div>
        </div>}

        {okState.value === 'success' &&
            <div className="margin-bottom-24">
                <div>
                    <div className="mb-4 height-24">
                        {!imageOk && <ErrorInputComponent error={"There is a Problem Uploading Your Image"} />}
                    </div>
                </div>
                <div className='d-flex flex-column'>
                    <div className='d-flex'>
                        <span onClick={() => navigate()} className="text-decoration-none cursor-pointer">
                            {theme === "light" ? (
                                <HeaderComponent
                                    title={currentUserId === userId ? 'My Profile' : 'Profile'}
                                    size={Headers.H1}
                                    icon={'chevron-left.svg'}
                                />
                            ) : <HeaderComponent
                                customClassName={"imageText"}
                                title={currentUserId === userId ? 'My Profile' : 'Profile'}
                                size={Headers.H1}
                                icon={'left-chevron-dark.svg'}
                            />}
                        </span>
                    </div>
                    <HorizontalShellComponent
                        onValidationHandler={setWrongImageFormatError}
                        onSuccessUpload={setSuccsessUpload}
                        firstName={okState?.data?.data?.user?.firstName}
                        lastName={okState?.data?.data?.user?.lastName}
                        email={okState?.data?.data?.user?.email}
                        picture={okState?.data?.data?.user?.profilePictureLocation}
                        currentUser={currentUserId === userId}
                        addToNetwork={addToNetwork}
                        userInContact={userInContact}
                    />
                </div>
                {/*Content part  */}
                <ContentShellComponent customClass={'d-flex justify-content-between mt-3 profile-wrap-config'}>
                    {/* Left part */}
                    <ContentShellComponent customClass={'contentShellConfig-half flex-column'}>
                        {/* Intention */}
                        <div className="margin-bottom-32">
                            <EasyCardComponent
                                currentUser={currentUserId === userId}
                                title={`${okState?.data?.data?.user?.firstName}'s Intention`}
                                text={okState?.data?.data?.userIntention === null ? "No intention found" : okState?.data?.data?.userIntention}
                                badge={
                                    theme === "light" ? (
                                        okState?.data?.data?.userIntention === null ? "" : "favourite-empty.svg"
                                    ) : okState?.data?.data?.userIntention === null ? "" : "favourite-empty-dark.svg"
                                } />
                        </div>
                        {/* Practicies */}
                        <div className='d-flex flex-column'>
                            <div className="margin-bottom-24">
                                <HeaderComponent
                                    title={'PRACTICES'}
                                    size={Headers.H3}
                                />
                            </div>
                            {userPractice.length > 0
                                ? userPractice.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage + rowsPerPage).map((data: any, index: number) => {
                                    return <div key={index}>
                                        <div className="margin-bottom-24">
                                            <HeaderComponent
                                                title={data.stauts === 'InProgress' ? 'In Progress' : 'Completed'}
                                                size={Headers.H4}
                                            />
                                        </div>
                                        <div className="margin-bottom-32">
                                            <PracticeProfileItemComponent
                                                title={data.title}
                                                text={data.summary}
                                                badge={`Module - ${data.module}`} />
                                        </div>
                                    </div>
                                })
                                : <TextComponent text={"No practice(s) found"} customClassName={"text-color-2D5362"} />
                            }
                        </div>
                        {
                            userPractice.length > 0 && <div className='d-flex justify-content-end'>
                                <PaginationComponent
                                    currentPage={currentPage}
                                    pageSize={rowsPerPage}
                                    totalItems={totalItems}
                                    totalPages={totalPages}
                                    onPageHandler={onPageHandler}
                                />
                            </div>
                        }
                    </ContentShellComponent>

                    {/* Right part */}
                    <ContentShellComponent customClass={'contentShellConfig-half flex-column'}>
                        <div className="margin-bottom-16">
                            <HeaderComponent
                                title={'CHARACTERISTICS'}
                                size={Headers.H3}
                            />
                        </div>
                        <div>
                            <TextComponent text={"Key characteristics created through the self-enquiry process."} customClassName={"text-color-2D5362"} />
                        </div>

                        {currentUserId !== userId && <div className="d-flex margin-top-24 margin-bottom-16">
                            <div>
                                {/* <input type="checkbox" id="check" onClick={(e: any) => handleTEDClick(e)} /> */}
                                <input
                                    type="checkbox" id="profileId"
                                    name="profileName"
                                    value="profileValue"
                                    disabled={false}
                                    onClick={(e: any) => addBackground(e)}
                                />
                            </div>
                            <label htmlFor="profileId" className="margin-left-8">
                                <TextComponent text="Compare with my profile" customClassName={'text-color-2D5362'} />
                            </label>
                        </div>}
                        {/* Characteristics */}
                        <ContentShellComponent>
                            {currentUserId !== userId &&
                                <div className='d-flex justify-content-between'>
                                    <div className="w-90">
                                        <ContentShellComponent customClass={'contentShellConfig-75 flex-column'}>
                                            {theme === "light" ? (
                                                <div className="padding-16-8" id="myCharacteristicsBlock">
                                                    <div className='margin-bottom-16 text-uppercase text-color-2D5362 align-self-center'>
                                                        <HeaderComponent
                                                            title={okState?.data?.data?.user?.firstName + ' ' + okState?.data?.data?.user?.lastName}
                                                            size={Headers.H4}
                                                            customClassName='text-capitalize text-color-2D5362 align-self-center'
                                                        />
                                                    </div>
                                                    {chars && chars.map((item: any, index: number) =>
                                                        !item.isOwnProfile && <div key={index}>
                                                            <div className="margin-bottom-8 text-uppercase">
                                                                <HeaderComponent
                                                                    title={item.title}
                                                                    size={Headers.H4}
                                                                    customClassName='textUppercase'
                                                                />
                                                            </div>
                                                            {item.characteristicCategory && item.characteristicCategory.map((item: any, index: number) =>
                                                                <div key={index}>
                                                                    {item.title && <div className="d-flex margin-bottom-8 text-color-2D5362 align-self-center text-capitalize">
                                                                        <TextComponent
                                                                            text={item.title}
                                                                        />
                                                                        <ProfileCharacteristicsModalComponent item={item} />
                                                                    </div>}
                                                                    {item.characteristicUserProfile && item.characteristicUserProfile.map((item: any, index: number) =>
                                                                        <div className="" key={index}>
                                                                            <ProfileCharacteristicComponent
                                                                                item={item}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>

                                                    )}
                                                </div>

                                            ) : <div style={{ backgroundColor: "#332725", color: "#fff" }} className="padding-16-8" id="myCharacteristicsBlock">
                                                <div className='margin-bottom-16 text-uppercase text-color-2D5362 align-self-center'>
                                                    <HeaderComponent
                                                        title={okState?.data?.data?.user?.firstName + ' ' + okState?.data?.data?.user?.lastName}
                                                        size={Headers.H4}
                                                        customClassName='text-capitalize text-color-2D5362 align-self-center'
                                                    />
                                                </div>
                                                {chars && chars.map((item: any, index: number) =>
                                                    !item.isOwnProfile && <div key={index}>
                                                        <div className="margin-bottom-8 text-uppercase">
                                                            <HeaderComponent
                                                                title={item.title}
                                                                size={Headers.H4}
                                                                customClassName='textUppercase'
                                                            />
                                                        </div>
                                                        {item.characteristicCategory && item.characteristicCategory.map((item: any, index: number) =>
                                                            <div key={index}>
                                                                {item.title && <div className="d-flex margin-bottom-8 text-color-2D5362 align-self-center text-capitalize">
                                                                    <TextComponent
                                                                        text={item.title}
                                                                    />
                                                                    <ProfileCharacteristicsModalComponent item={item} />
                                                                </div>}
                                                                {item.characteristicUserProfile && item.characteristicUserProfile.map((item: any, index: number) =>
                                                                    <div className="" key={index}>
                                                                        <ProfileCharacteristicComponent
                                                                            item={item}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>

                                                )}
                                            </div>
                                            }
                                        </ContentShellComponent>
                                    </div>
                                    <div className="w-90">

                                        <ContentShellComponent customClass={'contentShellConfig-75 flex-column'}>
                                            <div className="padding-16-0 d-none" id="myprofileCharacteristics">
                                                <div className='margin-bottom-16 text-uppercase text-color-2D5362 align-self-center'>
                                                    <HeaderComponent
                                                        title={'My characteristics'}
                                                        size={Headers.H4}
                                                        customClassName=''
                                                    />
                                                </div>
                                                {chars && chars.map((item: any, index: number) =>
                                                    item.isOwnProfile && <div key={index}>
                                                        <div className="margin-bottom-8 text-uppercase">
                                                            <HeaderComponent
                                                                title={item.title}
                                                                size={Headers.H4}
                                                                customClassName='textUppercase'
                                                            />
                                                        </div>
                                                        {item.characteristicCategory && item.characteristicCategory.map((item: any, index: number) =>
                                                            <div key={index}>
                                                                {item.title && <div className="d-flex margin-bottom-8 text-color-2D5362 align-self-center text-capitalize">
                                                                    <TextComponent
                                                                        text={item.title}
                                                                    />
                                                                    <ProfileCharacteristicsModalComponent item={item} />
                                                                </div>}
                                                                {item.characteristicUserProfile && item.characteristicUserProfile.map((item: any, index: number) =>
                                                                    <div className="" key={index}>
                                                                        <ProfileCharacteristicComponent
                                                                            item={item}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>

                                                )}
                                            </div>
                                        </ContentShellComponent>
                                    </div>

                                </div>}
                            {currentUserId === userId && <ContentShellComponent customClass={'w-100 flex-column'}>
                                <div className="padding-16-0">
                                    {chars && chars.map((item: any, index: number) =>
                                        <div key={index}>
                                            <div className="margin-bottom-8 text-uppercase">
                                                <HeaderComponent
                                                    title={item.title}
                                                    size={Headers.H4}
                                                    customClassName='textUppercase'
                                                />
                                            </div>
                                            {item.characteristicCategory && item.characteristicCategory.map((item: any, index: number) =>
                                                <div key={index}>
                                                    {item.title && <div className="d-flex margin-bottom-8 text-color-2D5362 align-self-center text-capitalize">
                                                        <TextComponent
                                                            text={item.title}
                                                        />
                                                        <ProfileCharacteristicsModalComponent item={item} />
                                                    </div>}
                                                    {item.characteristicUserProfile && item.characteristicUserProfile.map((item: any, index: number) =>
                                                        <div className="" key={index}>
                                                            <ProfileCharacteristicComponent
                                                                item={item}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>

                                    )}
                                </div>
                            </ContentShellComponent>}
                        </ContentShellComponent>
                    </ContentShellComponent>
                </ContentShellComponent>
            </div>
        }
        {currentUserId === userId &&
            <div>
                <TEDModalComponent />
            </div>
        }
    </>
}
export default ProfileComponent;