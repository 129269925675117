import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UserPractice from "../../../Services/UserPractice.service";
import { Headers } from "../../../Utils/Enums";
import { createNotification } from "../../../Utils/Toast";
import HeaderComponent from "../../FunctionalComponents/Header/HeaderComponent";
import PaginationComponent from "../../FunctionalComponents/Paginator/PaginationComponent";
import PracticeLibraryFilterComponent from "../../FunctionalComponents/UserPracticeComponents/PracticeLibraryFilterComponent";
import PracticeLibraryTableComponent from "../../FunctionalComponents/UserPracticeComponents/PracticeLibraryTableComponent";
import { useHistory } from "react-router";
import TEDModalComponent from "../../FunctionalComponents/TEDModal/TEDModalComponent";
import General from "../../../Services/General.service";
import { logOutIfTokenExpire } from '../../../Utils/Utils';
import { UserContext } from "../../../Contexts/UserContext";
import { ThemeContext } from "../../../Contexts/ThemeContext"

interface IpracticesLibraryData {
    practiceId: number
    title: string;
    summary: string;
    otherCurrent: number;
    otherCompleted: number;
    rating: number;
    practiceStatus: number;
    isAdded: boolean;
}

interface ImoduleData {
    id: number;
    title: string;
    heading: any;
    userModuleStatusId: number;
    programStageId: number;
    isActive: boolean;
    moduleSteps: any;
    concepts: any;
    additionalResources: any;
}

function PracticeLibraryComponent(props: any) {
    const generalService = new General();
    generalService.getIcons();
    const practice = new UserPractice();
    let history = useHistory();
    let redirection: any = props?.location?.state;
    const [userPracticeLibrary, setUserPracticeLibrary] = useState<IpracticesLibraryData[]>([]);
    const [moduleData, setModuleData] = useState<ImoduleData[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [selectedModule, setSelectedModule] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [isFilter, setIsFilter] = useState<boolean>(false);
    const [selectedPractice, setSelectedPractice] = useState<any>({
        id: 0,
        details: "",
        instructions: "",
        summary: "",
        title: "",
    });
    const userContext = useContext(UserContext);
    const { theme } = useContext(ThemeContext)
    useEffect(() => {
        getUserModule();
        userContext.dispatch('');
    }, []);

    useEffect(() => {
        logOutIfTokenExpire();
        if (isFilter) {
            getPracticeLibrary(true);
        }
        else {
            getPracticeLibrary(false);
        }
        //eslint-disable-next-line
    }, [currentPage, selectedModule]);

    const handleSearchText = (value: string) => {
        setSearchText(value);
    }

    // for pagination
    const onPageHandler = (currentPageId: number) => {
        setIsFilter(false);
        setCurrentPage(currentPageId);
    }

    const getPracticeLibrary = async (isFilter: boolean) => {
        let response: any = await practice.getUserPracticeLibrary(searchText, Number(selectedModule), isFilter ? 1 : currentPage, pageSize);
        if (response.status) {
            setTotalPages(response.data.pagination.totalPages);
            setTotalItems(response.data.pagination.totalItems);
            setCurrentPage(response.data.pagination.currentPage);
            setPageSize(response.data.pagination.pageSize);
            let tempPracticeData: IpracticesLibraryData[] = response.data.items.map((data: any) => {
                return {
                    title: data.title,
                    description: data.summary,
                    otherCurrent: data.otherCurrent,
                    otherCompleted: data.otherCompleted,
                    rating: data.rating,
                    practiceId: data.practiceId,
                    practiceStatus: data.practiceStatus,
                    isAdded: data.isAdded
                }
            });
            setUserPracticeLibrary(tempPracticeData);
        }
    }

    const getUserModule = async () => {
        let response: any = await practice.getUserModuleForPractice();
        if (response.status) {
            let moduleData: ImoduleData[] = response.data.map((value: any) => {
                return {
                    id: value.id,
                    title: value.title,
                    heading: value.heading,
                    userModuleStatusId: value.userModuleStatusId,
                    programStageId: value.programStageId,
                    isActive: value.isActive,
                    moduleSteps: value.moduleSteps,
                    concepts: value.concepts,
                    additionalResources: value.additionalResources,
                }
            });
            setModuleData(moduleData);
        }
    }

    const actionOnPractice = async (id: number) => {
        //eslint-disable-next-line
        let response: any = await practice.addUserPractice(id);
        if (response.status) {
            createNotification("success", response.message);
            getPracticeLibrary(false);
        } else {
            createNotification("error", response.message);
        }
    }

    const currentPractice = async (id: number) => {
        return;
    }

    const handleRedirection = () => {
        if (redirection === undefined) {
            history.push("/dashboard");
        } else {
            history.push("/mypractices");
        }
    }

    const handleSearch = async () => {
        setIsFilter(true);
        if (currentPage == 1) {
            await getPracticeLibrary(true);
        }
        else {
            setCurrentPage(1);
        }
    }

    const handleSelectedModule = async (value: number) => {
        setIsFilter(true);
        setSelectedModule(value);
    }

    const getClickedPractice = async (practiceId: number) => {
        let response: any = await practice.getClickedPractice(practiceId);
        if (response) {
            setSelectedPractice({
                id: response.id,
                details: response.details,
                instructions: response.instructions,
                summary: response.summary,
                title: response.title,
            });
        }
    }

    return (
        <>
            <div className="container-fluid row col-sm-12">
                {theme === "light" ? (
                    <div className="d-flex align-items-center">
                        <img onClick={() => handleRedirection()} className="cursor-pointer" alt="redirection-back" src={window.location.origin + "/images/chevron-left.svg"} />
                        <span onClick={() => handleRedirection()} className="cursor-pointer">
                            <HeaderComponent
                                title="Practice Library"
                                size={Headers.H2}
                            />
                        </span>
                    </div>
                ) : <div className="d-flex align-items-center">
                        <img onClick={() => handleRedirection()} className="cursor-pointer"
                            alt="redirection-back"
                            src={window.location.origin + "/images/left-chevron-dark.svg"} />
                        <span onClick={() => handleRedirection()} className="cursor-pointer">
                            <div style={{ marginLeft: "15px" }}>
                                <HeaderComponent
                                    title="Practice Library"
                                    size={Headers.H2}
                                />
                            </div>
                        </span>
                    </div>}

                <PracticeLibraryFilterComponent
                    searchText={searchText}
                    handleSearchText={handleSearchText}
                    handleSearch={handleSearch}
                    moduleData={moduleData}
                    handleSelectedModule={handleSelectedModule}
                />

                <div className="mt-3">
                    <div className="d-flex justify-content-between">
                        <HeaderComponent
                            title="All Practices"
                            size={Headers.H2}
                        />
                        {theme === "light" ? (<Link to={{ pathname: "/mypractices", state: { isFromLibrary: true } }} className="text-decoration-none cursor-pointer border-bottom border-dark">
                            <HeaderComponent
                                title="My Playlist"
                                size={Headers.H3}
                            />
                        </Link>
                        ) : <Link to={{ pathname: "/mypractices", state: { isFromLibrary: true } }} className="text-decoration-none cursor-pointer practiceLibraryTextColor">
                                <HeaderComponent
                                    title="My Playlist"
                                    size={Headers.H3}
                                />
                            </Link>}
                    </div>
                    <div>
                        <PracticeLibraryTableComponent
                            practiceData={userPracticeLibrary}
                            actionOnPractice={actionOnPractice}
                            currentPractice={currentPractice}
                            getClickedPractice={getClickedPractice}
                            selectedPractice={selectedPractice}
                            setSelectedPractice={setSelectedPractice}
                        />
                    </div>
                </div>
                {
                    userPracticeLibrary.length > 0 && (<div className='d-flex justify-content-end'>
                        <PaginationComponent
                            currentPage={currentPage}
                            pageSize={pageSize}
                            totalItems={totalItems}
                            totalPages={totalPages}
                            onPageHandler={onPageHandler}
                        />
                    </div>)
                }
            </div>
            <div>
                <TEDModalComponent />
            </div>
        </>
    );
}

export default PracticeLibraryComponent;