import React, { useContext } from "react";
import TextComponent from "../Text/TextComponent";
import "./PracticeProfileItemComponent.css";
import { ThemeContext } from "../../../Contexts/ThemeContext"

interface IPracticeProfileItemComponent {
    title: string;
    text: string;
    badge: string;
}
const PracticeProfileItemComponent = (props: IPracticeProfileItemComponent) => {
    const { theme } = useContext(ThemeContext);
    return <>
        <div className="border-bottom-EBEBEB-thin">
            <div className="margin-bottom-8">
                <TextComponent text={props.title} customClassName={"font-weight-600 text-color-212529 text-decoration-underline"} />
            </div>
            <div className="margin-bottom-8">
                <TextComponent text={props.text} customClassName={"text-color-2D5362"} />
            </div>
            <div className="margin-bottom-16 width-147">
                <div className="badge rounded-pill bg-secondary d-flex justify-content-center badge-width">
                    {theme === "light" ? (
                        <TextComponent text={props.badge} customClassName={"moduleText text-color-182C34 font-size-14 align-self-center"} />
                    ) : <TextComponent text={props.badge} customClassName={"moduleText text-color-182C34 font-size-14 align-self-center buttonText"} />}
                </div>
            </div>
        </div>
    </>
}
export default PracticeProfileItemComponent;