interface IRegisterUser {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
    code: string;
};

interface IConfirmEmail {
    userId: string;
    code: string;
    password:string;
    newPassword:string;
};

class RegisterUser {
    async registerUser(dataRegisterUser: IRegisterUser) {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Account`, {
                method: 'POST',
                body: JSON.stringify(dataRegisterUser),
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*'
                }
            });
            if (!response.ok) {
                return { ok: false };
            } else {
                return { ok: true };
            }
        } catch (error) {
            return { ok: false };
        }
    };
}
export default RegisterUser;