import React, { useRef, useState } from "react";
import ErrorInputComponent from "../ErrorInput/ErrorInputComponent";
import stylesFunctionalComponents from '../FunctionalComponents.module.css';
import isStrongPassword from 'validator/lib/isStrongPassword';

export interface IPasswordData {
    value?: string;
    isStrongPasswordState?: boolean;
};

interface IPasswordInputComponent {
    placeholder: string;
    label: string;
    error?: string;
    onValidationHandler: (data: IPasswordData) => void;
};

const PasswordInputComponent = (props: IPasswordInputComponent) => {
    const [passwordError, setPasswordError] = useState('');
    const nameInputRef = useRef<HTMLInputElement | null>(null);

    let data = {
        isStrongPasswordState: true,
        value: nameInputRef.current?.value
    }
    const ChangeEventHandler = (event: any) => {
        const enteredValue = String(nameInputRef.current?.value);
        data.isStrongPasswordState = isStrongPassword(enteredValue);
        //eslint-disable-next-line
        const checkCondition = isStrongPassword(enteredValue) ? setPasswordError('') : setPasswordError('Please, choose a stronger password!');
        data.value = enteredValue;
        props.onValidationHandler(data);

    }
    return <>
        <label className="display-none mb-1">{props.label}</label>
        <input type="password"
            role="textbox"
            ref={nameInputRef}
            placeholder={props.placeholder}
            className={`form-control input-field ${passwordError.length > 0 && stylesFunctionalComponents.form_control_danger}`}
            onBlur={ChangeEventHandler} />
        <ErrorInputComponent error={passwordError} />
    </>
}

export default PasswordInputComponent;
