import "./DashboardCardItemComponent.css";
import React, { useState, useContext } from 'react';
import HeaderComponent from "../Header/HeaderComponent";
import TextComponent from "../Text/TextComponent";
import { Headers, moduleStepsState } from '../../../Utils/Enums';
import ScorerModalComponent from "../ScorerMadal/ScorerModalComponent";
import { useHistory } from "react-router";
import DashboardCardModalComponent from "../DashbaordCardModalComponent/DashboardCardModalComponent";
import { ThemeContext } from "../../../Contexts/ThemeContext";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

export interface IStepData {
    id: number;
    userModuleStepStatusId: number;
}
export interface IDashboardCardItemComponent {
    item:
    {
        id: number;
        title: string;
        description: string;
        subtitle: string;
        userModuleStepStatus: moduleStepsState;
        userModuleStepStatusId: number;
        button: string[];
        dataObject: [{
            icon: string;
            title: string;
            description: string;
            link: string;
            id: number;
            redirectionType: number | string;
            dateTime: any;
        }]
    },
    moduleId: number;
    onChangeStatusHandler: (data: IStepData) => void;
    inProgress: any;
    completed: any;
    isPracticeLibraryHasData?: boolean;
};

const DashboardCardItemComponent = (props: IDashboardCardItemComponent) => {
    const { theme } = useContext(ThemeContext);
    let history = useHistory();

    const modalBody: string = props.isPracticeLibraryHasData
        ? "Are you sure you want to set a practice before exploring all of the practices for the module?"
        : "Please complete the Think concepts first to build your practice playlist";

    const [show, setShow] = useState<boolean>(false);
    const [engageClick, setEngageClick] = useState<boolean>(false);


    const handleClose = () => {
        setShow(false);
    }

    const handleLink = (title: string) => {
        if (title === 'Think') {
            history.push(`/concept/${props?.item?.dataObject[0]?.id}/${props?.item?.id}/${props?.moduleId}`);
        } else if (title === 'Engage') {
            history.push(`/engage/-1?status=complete&s=Meetings`);
        } else if (title === 'Do') {
            history.push(`/singlepractice/${props.item.dataObject[0]?.id}`);
        }
    }

    const handleRedirection = (title: string, button: string, userModuleStepStatusId: number) => {
        if (title === 'Think') {
            if (userModuleStepStatusId === 0) {
                history.push(`/module/${props.moduleId}/${props.item.id}`);
            } else if (userModuleStepStatusId === 1) {
                if (button.toLowerCase() === 'View All'.toLowerCase()) {
                    history.push(`/module/${props.moduleId}/${props.item.id}`);
                }
                else {
                    history.push(`/concept/${props?.item?.dataObject[0]?.id}/${props?.item?.id}/${props?.moduleId}`);
                }

            } else if (userModuleStepStatusId === 2) {
                // history.push(`/module/${props.moduleId}/${props.item.id}`);
                if (button.toLowerCase() === "Module resources".toLowerCase()) {
                    history.push(`/conceptsummary/${props.moduleId}`)
                } else if (button.toLowerCase() === "Revise Concepts".toLowerCase()) {
                    history.push(`/module/${props.moduleId}/${props.item.id}`)
                }
                // else {
                //     history.push(`/module/${props.moduleId}/${props.item.id}`);
                // }
            }
        } else if (title === 'Engage') {
            if (userModuleStepStatusId === 0) {
                history.push(`/engage/-1?status=complete`);
            } else if (userModuleStepStatusId === 1) {
                if (button.toLowerCase() === "Meetings".toLowerCase()) {
                    history.push(`/engage/-1?status=complete&s=Meetings`);
                } else if (button.toLowerCase() === "Network".toLowerCase()) {
                    history.push(`/engage/-1?status=complete`);
                }
            } else if (userModuleStepStatusId === 2) {
                if (button.toLowerCase() === "Network".toLowerCase()) {
                    history.push(`/engage/-1?status=complete`);
                }
            }
        } else if (title === 'Do') {
            if (userModuleStepStatusId === 0) {
                history.push({
                    pathname: `/mypractices`,
                });
            } else if (userModuleStepStatusId === 1) {
                history.push({
                    pathname: `/mypractices`,
                });
            } else if (userModuleStepStatusId === 2) {

            }
        }
        if (props.item.userModuleStepStatusId === 0) {
            let data = {
                id: props.item.id,
                userModuleStepStatusId: props.item.userModuleStepStatusId
            }
            props.onChangeStatusHandler(data);
        }
    }


    const popover = (
        <Popover placement="right" id="popover-basic">
            {props.item.title === "Think" && (
                <>
                    {props.item.description.split("\r\n").length >= 2 && (
                        <>
                            <Popover.Header as="h3">Step 1-Think</Popover.Header>
                            <Popover.Body className="popOver">
                                <div className="fw-bold" dangerouslySetInnerHTML={{ __html: String(props.item.description.split("\r\n").at(2)) }}></div>
                            </Popover.Body>
                        </>
                    )}
                </>
            )}
            {props.item.title === "Think" && (
                <>
                    {props.item.description.split("\r\n").length <= 2 &&
                        <>
                            <Popover.Header as="h3">Step 1-Think</Popover.Header>
                            <Popover.Body className="popOver">
                                <div className="fw-bold">{"No Data"}</div>
                            </Popover.Body>
                        </>
                    }
                </>
            )}
            {props.item.title === "Engage" && (
                <>
                    {props.item.description.split("\r\n").length >= 2 && (
                        <>
                            <Popover.Header as="h3">Step 2-Engage</Popover.Header>
                            <Popover.Body className="popOver">
                                <div className="fw-bold" dangerouslySetInnerHTML={{ __html: String(props.item.description.split("\r\n").at(2)) }}></div>
                            </Popover.Body>
                        </>
                    )}
                </>
            )}
            {props.item.title === "Engage" && (
                <>
                    {props.item.description.split("\r\n").length <= 2 &&
                        <>
                            <Popover.Header as="h3">Step 2-Engage</Popover.Header>
                            <Popover.Body className="popOver">
                                <div className="fw-bold">{"No Data"}</div>
                            </Popover.Body>
                        </>
                    }
                </>
            )}
            {props.item.title === "Do" && (
                <>
                    {props.item.description.split("\r\n").length >= 2 && (
                        <>
                            <Popover.Header as="h3">Step 3-Do</Popover.Header>
                            <Popover.Body className="popOver">
                                <div className="fw-bold" dangerouslySetInnerHTML={{ __html: String(props.item.description.split("\r\n").at(2)) }}></div>
                            </Popover.Body>
                        </>
                    )}
                </>
            )}
            {props.item.title === "Do" && (
                <>
                    {props.item.description.split("\r\n").length <= 2 &&
                        <>
                            <Popover.Header as="h3">Step 3-Do</Popover.Header>
                            <Popover.Body className="popOver">
                                <div className="fw-bold">{"No Data"}</div>
                            </Popover.Body>
                        </>
                    }
                </>
            )}
        </Popover >
    );
    return <>
        <div className="col">
            <div className=" h-100">
                <div>
                    <div className="dashboard-card card-body">
                        <div className="margin-bottom-16">
                            {props.item.title && <HeaderComponent
                                title={props.item.title}
                                size={Headers.H2}
                            />}
                            <OverlayTrigger trigger={["hover", "hover"]} placement="bottom" overlay={popover}>
                                <img style={{ float: "right", marginTop: "-30px" }} src={window.location.origin + '/images/info.svg'} alt="info" className={`cursor-pointer`} id={"info"} />
                            </OverlayTrigger>
                        </div>
                        <div className="header-config margin-bottom-16px">
                            {/* Conditional title for cards */}
                            <TextComponent text={props.item.description.split("\r\n").at(0)} />
                        </div>
                        <div className="fw-bold margin-bottom-8 margin-top-8 subtitle-config">
                            {/* Conditional title for Think card */}
                            {props.item.userModuleStepStatusId === 1 && props.item.title === "Think"
                                && props.item.dataObject[0] &&
                                <TextComponent text="Think" />
                            }
                            {/* Conditional title for Engage card */}
                            {props.item.title === "Engage" && props.item.dataObject[0] && props.item.userModuleStepStatusId !== 2 &&
                                <TextComponent text="Next Meeting"
                                />}
                        </div>
                        {/* Conditional Think link Not completed*/}
                        {props.item.title === "Think" && props.item.userModuleStepStatusId === 1 && <div className="border-top-color-EBEBEB border-bottom-color-EBEBEB content-config-borders content-config margin-bottom-32">
                            <div className="d-flex cursor-pointer" onClick={() => handleLink(props.item.title)}>
                                <div className="image-wrapper">
                                    {props.item.dataObject[0] && (props.item.dataObject[0].redirectionType === 'Concepts' || props.item.dataObject[0].redirectionType === 1) && <img src={`${process.env.REACT_APP_BASE_URL}/file?filename=` + props.item.dataObject[0].icon} alt="concept icon" />}
                                </div>
                                <div className="d-flex flex-column">
                                    <div className="mmm 1 card-text-spacing">
                                        {props.item.dataObject[0] && <TextComponent
                                            text={props.item.dataObject[0].title}
                                            customClassName={"fw-bold text-color-212529"} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        {/* Conditional engage link Not completed*/}
                        {props.item.title === "Engage" && props.item.dataObject[0] && props.item.userModuleStepStatusId !== 2 && <div className="border-top-color-EBEBEB border-bottom-color-EBEBEB content-config-borders content-config margin-bottom-32 ttt">
                            <div className="d-flex flex-column cursor-pointer" onClick={() => handleLink(props.item.title)}>
                                <div className="mmm 1">
                                    {props.item.dataObject[0] && <TextComponent
                                        text={props.item.dataObject[0].title}
                                        customClassName={"fw-bold text-color-212529"} />}
                                </div>
                                <div className="nnn">
                                    {props.item.dataObject[0] && <TextComponent
                                        text={props.item.title === "Engage" ? new Date(props.item.dataObject[0].dateTime).toLocaleDateString('en-GB') : ''}
                                    />}
                                </div>
                            </div>
                        </div>}
                        {/* Conditional Do link Not completed
                        && props.item.userModuleStepStatusId === 1 Remove this condition because even think not completed so current practice in do card*/}
                        {props.item.title === "Do" && props.item.dataObject[0] && <div className="border-top-color-EBEBEB border-bottom-color-EBEBEB content-config-borders content-config margin-bottom-32 ttt">
                            <div className="d-flex flex-column cursor-pointer" onClick={() => handleLink(props.item.title)}>
                                <div className="mmm 1">
                                    {props.item.dataObject[0] && <TextComponent
                                        text={props.item.dataObject[0].title}
                                        customClassName={"fw-bold text-color-212529"} />}
                                </div>
                            </div>
                        </div>}

                        {/*props.item.userModuleStepStatusId !== 1|| remove this condiion too*/}
                        {props.item.title === "Do" && (!props.item.dataObject[0]) && <div className="content-config margin-bottom-32 sss"></div>}
                        {props.item.title === "Think" && props.item.userModuleStepStatusId !== 1 && <div className="content-config margin-bottom-32 zzz"></div>}
                        {props.item.title === "Engage" && (props.item.userModuleStepStatusId === 2 || !props.item.dataObject[0]) && <div className="content-config margin-bottom-32 qqq"></div>}



                        {/* One button */}
                        {props.item.button.length === 1 &&
                            <>
                                {theme === "light" ? (
                                    <div className={`${props.item.button[0] !== 'Completed' ? "btn-primary btn" : "btn-secondary btn-secondary-disable"} w-100 config-btn-inprocess text-center`} onClick={() => handleRedirection(props.item.title, props.item.button[0], props.item.userModuleStepStatusId)}>

                                        <TextComponent
                                            text={props.item.button[0]}
                                            customClassName={"font-weight-600 text-color-182C34"}
                                        />
                                    </div>
                                ) : <div className={`${props.item.button[0] !== 'Completed' ? "btn-primary btn" : "btn-secondary btn-secondary-disable"} w-100 config-btn-inprocess text-center`} onClick={() => handleRedirection(props.item.title, props.item.button[0], props.item.userModuleStepStatusId)}>
                                    <TextComponent
                                        text={props.item.button[0]}
                                        customClassName={"font-weight-600 text-color-182C34 buttonText"}
                                    />
                                </div>}
                            </>
                        }

                        {/* Two buttons */}
                        {props.item.button.length > 1 &&
                            <div className="d-flex justify-content-between">
                                {props.item.button[0] &&
                                    <div className="btn btn-primary config-btn-inprocess" onClick={() => handleRedirection(props.item.title, props.item.button[0], props.item.userModuleStepStatusId)}>
                                        {theme === "light" ? (
                                            <TextComponent
                                                text={props.item.button[0]}
                                                customClassName={"font-weight-600 text-color-182C34"} />
                                        ) : <TextComponent
                                            text={props.item.button[0]}
                                            customClassName={"font-weight-600 text-color-182C34 buttonText"} />}
                                    </div>}
                                {props.item.button[1] === 'Score' &&
                                    <ScorerModalComponent
                                        title={props.item.button[1]}
                                        moduleStepId={props.item.id}
                                        isFromEngageCard={props.item.title === 'Engage' ? true : false}
                                        disabled={props.item.userModuleStepStatusId !== 1}
                                    />

                                }
                                {props.item.button[1] !== 'Score' &&
                                    <>
                                        {theme === "light" ? (
                                            <>
                                                {props.item.button[1] === 'Completed' ? (
                                                    <div className={`${props.item.button[1] === 'Completed' ? "btn-secondary btn-secondary-disable" : "btn-secondary btn-secondary-disable"} w-40 config-btn-inprocess text-center`} onClick={() => handleRedirection(props.item.title, props.item.button[1], props.item.userModuleStepStatusId)}>
                                                        <TextComponent
                                                            text={props.item.button[1]}
                                                            customClassName={"font-weight-600 text-color-182C34"} />
                                                    </div>
                                                ) :
                                                    <div className="btn btn-primary config-btn-inprocess" onClick={() => handleRedirection(props.item.title, props.item.button[1], props.item.userModuleStepStatusId)}>
                                                        <TextComponent
                                                            text={props.item.button[1]}
                                                            customClassName={"font-weight-600 text-color-182C34 "} />
                                                    </div>
                                                }
                                            </>
                                        ) :
                                            <>
                                                {props.item.button[1] === 'Completed' ? (
                                                    <>
                                                        <div className={`${props.item.button[1] === 'Completed' ? "btn-secondary btn-secondary-disable" : "btn-secondary btn-secondary-disable"} w-40 config-btn-inprocess text-center`} onClick={() => handleRedirection(props.item.title, props.item.button[1], props.item.userModuleStepStatusId)}>
                                                            <TextComponent
                                                                text={props.item.button[1]}
                                                                customClassName={"font-weight-600 text-color-182C34 buttonText"} />
                                                        </div>
                                                    </>
                                                ) :
                                                    <>
                                                        <div className="btn btn-primary config-btn-inprocess" onClick={() => handleRedirection(props.item.title, props.item.button[1], props.item.userModuleStepStatusId)}>
                                                            <TextComponent
                                                                text={props.item.button[1]}
                                                                customClassName={"font-weight-600 text-color-182C34 buttonText"} />
                                                        </div>
                                                    </>
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
        {
            show && <DashboardCardModalComponent
                modalBody={engageClick ? "Please complete the Think concepts first to score" : modalBody}
                handleClose={handleClose}
                show={show}
                hasPractices={props?.isPracticeLibraryHasData}
                cardName={props.item.title}
            />
        }
    </>
}

export default DashboardCardItemComponent;