import React from "react";
import { ExpanderComponentProps } from "react-data-table-component";
import TextComponent from "../Text/TextComponent";
import HeaderComponent from "../Header/HeaderComponent";
import { Headers } from "../../../Utils/Enums";
import "./UserPracticeComponentsStyle/PracticeTableComponent.css";

interface IpracticesData {
    practiceId: number
    title: string;
    summary: string;
    otherCurrent: number;
    otherCompleted: number;
    rating: number;
    isAdded: boolean;
    practiceStatus: string
}

interface Iprops {
    practiceData: any[];
    actionOnPractice: (id: number) => Promise<void>;
    currentPractice: (id: number) => Promise<void>;
    deletePractice?: any;
    getClickedPractice?: (practiceId: number) => Promise<void>
    selectedPractice?: any;
    setSelectedPractice?: any
}


interface Props extends ExpanderComponentProps<IpracticesData> {
    // currently, props that extend ExpanderComponentProps must be set to optional.
    url?: string;
}
const PracticeTableExpandableRowComponent: (theme: string, url: string, setShow: any, setPracticeTitleClicked: any, props: Iprops)
    => React.FC<Props> = (theme, url, setShow, setPracticeTitleClicked, props) => ({ data }) => {
        const {
            practiceData,
            actionOnPractice,
            currentPractice,
            deletePractice,
            getClickedPractice,
            selectedPractice,
            setSelectedPractice
        } = props;

        const handlePracticeTitleClick = (row: any) => {
            setPracticeTitleClicked(true);
            setShow(true);
            if (getClickedPractice) {
                getClickedPractice(row.practiceId);
            }
        }

        const handleActionClick = (row: any) => {
            actionOnPractice(row.practiceId);
        }

        const handleSetPractice = (row: any) => {
            currentPractice(row.practiceId);
        }

        const handleRemovePractice = (row: any) => {
            deletePractice(row.practiceId);
        }

        return (
            <>
                <div className="p-3 background-FAFAFA" >
                    <div><b>Description</b></div>
                    <div className="mt-2 mb-1">
                        <div className="title" onClick={() => handlePracticeTitleClick(data)}>
                            {data.title}
                        </div>
                        <div className="description" data-tag="allowRowEvents">
                            {data.summary !== ""
                                ? <div dangerouslySetInnerHTML={{ __html: data.summary }}></div>
                                : "No Summary Found"
                            }
                        </div>
                    </div>
                    <div className="mt-2"><b>Others-Current</b></div>
                    <div className="numbers">
                        {data.otherCurrent}
                    </div>
                    <div className="mt-2"><b>Others-Completed</b></div>
                    <div className="numbers">
                        {data.otherCompleted}
                    </div>
                    <div className="mt-2"><b>Rating</b></div>
                    <div className="numbers">
                        {data.rating === null ? "NA" : data.rating}
                    </div>
                    <div className="mt-2"><b>Status</b></div>
                    <div>{(
                        <>
                            {url === "/practicelibrary" && (
                                <div className="d-flex align-items-center justify-content-between w-100">
                                    <div onClick={() => handleActionClick(data)}>
                                        <img
                                            alt="favourite"
                                            src={
                                                theme === "light" ? (
                                                    data.isAdded ? window.location.origin + "/images/favourite-filled.svg"
                                                        : "/images/favourite-empty.svg"
                                                ) : data.isAdded ? window.location.origin + "/images/favourite-filled.svg"
                                                        : "/images/favourite-empty-dark.svg"
                                            }
                                            width={"16px"}
                                            height={"16px"}
                                        />
                                    </div>
                                    <div onClick={() => handleActionClick(data)}>
                                        <TextComponent text="Add" customClassName="font-weight-400 added-text text-black" />
                                    </div>
                                </div>
                            )}
                            {url === "/mypractices" && (
                                data.practiceStatus === "Completed" ?
                                    <div>
                                        <HeaderComponent title={"Completed"} size={Headers.H4} />
                                    </div>
                                    : data.practiceStatus === "InProgress"
                                        ? <div>
                                            <HeaderComponent title={"In Progress"} size={Headers.H4} /> </div> :
                                        <div>
                                            <HeaderComponent title={"Not Started"} size={Headers.H4} />
                                        </div>
                            )}
                        </>
                    )}
                    </div>
                    <div className="mt-2"><b>Set</b></div>
                    <div>
                        <>
                            {
                                url === "/mypractices" && (
                                    data.practiceStatus !== "InProgress" ?
                                        <div >
                                            <div data-bs-toggle="tooltip" data-bs-placement="bottom" title="Set as current practice" onClick={() => handleSetPractice(data)}>
                                                <TextComponent text="Set" customClassName="font-weight-400 text-dark" />
                                            </div>
                                        </div> : <div> -- </div>
                                )
                            }
                        </>
                    </div>
                    <div className="mt-2"><b>Remove</b></div>
                    <div>
                        <>
                            {url === "/mypractices" && (
                                <div>
                                    <div
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="bottom"
                                        title="Remove practice"
                                        onClick={() => handleRemovePractice(data)}>
                                        <TextComponent text="Remove" customClassName="font-weight-400 text-dark" />
                                    </div>
                                </div>
                            )}
                        </>
                    </div>
                </div>
            </>
        );
    };


export default PracticeTableExpandableRowComponent;