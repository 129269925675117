import { IModalData } from "../components/FunctionalComponents/Modal/ModalComponent";
import { IRole } from "../components/FunctionalComponents/Popover/PopoverComponent";
import constant from "../Utils/Config";
import {isTokenExpired,clearLocalStorageAndRedirect, getAuthToken} from "../Utils/Utils";
export interface IContact {
    isGroupMember?: boolean;
    user: {
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        isExternal?: boolean;
        profilePictureLocation: string;
    };
    roles: String[];
}
export interface ISearchItem {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    profilePictureLocation: string;
    isContact: boolean;
};

export interface IPagination {
    currentPage: number;
    pageSize: number;
    totalItems: number;
    totalPages: number;
}
export interface IContacts {
    user: IContact[];
    isGroupMember: false;
    roles: [];
    maxContacts: number;
    maxExternalContacts: number;
}
export interface ISearchResult {
    data: {
        result: {
            pagination: IPagination;
            items: ISearchItem[];
        },
        nextPageLocation: null;
        prevPageLocation: null;
    }
}

class Engage {

    async getContacts() {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Contact`, {
                method: 'GET',
                headers: {
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    }

    async getSearchByName(searchString: string, page: number) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Contact/searchByName?term=` + searchString + "&pageNumber=" + page + "&pageSize=5", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            });
            if (!response.ok) {
                return { data: "error", ok: false };
            } else {
                const result = await response.json();
                if (result) {
                    return { data: result, ok: true };
                } else {
                    return { data: "error", ok: false };
                }
            }
        } catch (error) {
            return { data: error, ok: false };
        }
    };

    async addRoleToContact(data: IRole) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Contact/role?contactUserId=` + data.id + "&roleName=" + data.role, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    };

    async deleteRoleToContact(data: IRole) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Contact/role?contactUserId=` + data.id + "&roleName=scorer", {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    };
    
    async addExternalUser(data: IModalData) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Contact/external?emailAddress=` + data.emailAddress + "&roleName=scorer&firstName=" + data.firstName + "&lastName=" + data.lastName, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    };

    async deleteContact(data: string) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Contact?contactUserId=` + data, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    };

    async deleteExternalUser(data: string) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Contact/external?emailAddress=` + data, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    };

    async addContact(data: string) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Contact?contactUserId=` + data, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    };
}
export default Engage;
