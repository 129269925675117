import React, { useState, useContext } from "react";
import { Card, Form, Spinner } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { IChangePassword } from "../../../interfaces/User/IUserAccount";
import { hasLowerCase, hasNumber, hasUpperCase, specialRgx } from "../../../Utils/Utils";
import ErrorInputComponent from "../../FunctionalComponents/ErrorInput/ErrorInputComponent";
import User from "../../../Services/UserDetails.service";
import { createNotification } from "../../../Utils/Toast";
import { ThemeContext } from "../../../Contexts/ThemeContext";
import "../../../darkcustom.css"

const UserSecurityCompnent = () => {
    let { theme } = useContext(ThemeContext)
    const userService = new User();
    const [isChangeingPassword, setIsChangeingPassword] = useState<boolean>(false);
    const [changePassword, setChangePassword] = useState<IChangePassword>({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    });

    const [changePasswordErrorToggel, setChangePasswordErrorToggel] = useState<any>({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    });

    const changeUserPassword = async () => {
        setIsChangeingPassword(true);
        if (validateChangePasswordFrm()) {
            const response = await userService.ChangePassword({
                currentPassword: changePassword.currentPassword,
                newPassword: changePassword.confirmPassword
            });
            if (response) {
                if (response.status) {
                    createNotification('success', response.message);
                }
                else {
                    if (response.data) {
                        createNotification('danger', response.data);
                    }
                    else {
                        createNotification('danger', response.message);
                    }
                }
            }
        }
        else {
            createNotification('danger', 'Please make you are following rules');
        }
        setIsChangeingPassword(false);
    }

    const validateChangePasswordFrm = () => {
        if (changePassword.newPassword.length >= 6 &&
            changePassword.currentPassword.length > 0 &&
            changePassword.newPassword === changePassword.confirmPassword) {
            return true;
        }
        return false;
    }

    return (
        <>
            {theme === "light" ? (
                <div className="row mt-5">
                    <div className="col-xl-12">
                        <Card>
                            <Card.Header as="h5">Change Password</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Password <span>*</span></Form.Label>
                                        <Form.Control type="password" placeholder="Current Password" className="input-font"
                                            onChange={(e) => {
                                                const val: string = e.target.value;
                                                setChangePassword((pre: IChangePassword) => ({ ...pre, currentPassword: val }));
                                                if (val.length <= 0) {
                                                    setChangePasswordErrorToggel((pre: any) => ({
                                                        ...pre, currentPassword: 'Plese enter current password'
                                                    }));
                                                }
                                                else {
                                                    setChangePasswordErrorToggel((pre: any) => ({
                                                        ...pre, currentPassword: '',
                                                    }));
                                                }

                                            }}
                                        >

                                        </Form.Control>
                                        {changePasswordErrorToggel.currentPassword && <ErrorInputComponent error={changePasswordErrorToggel.currentPassword} />}
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>New Password <span>*</span></Form.Label>
                                        <Form.Control type="password" placeholder="New Password" className="input-font"
                                            onChange={(e) => {
                                                const val: string = e.target.value;
                                                setChangePassword((pre: IChangePassword) => ({ ...pre, newPassword: val }));
                                                if (val.length === 0 && val.length < 6) {
                                                    setChangePasswordErrorToggel((pre: any) => ({
                                                        ...pre, newPassword: 'Password should 6 letter in length'
                                                    }));
                                                }
                                                else {
                                                    setChangePasswordErrorToggel((pre: any) => ({
                                                        ...pre, newPassword: '',
                                                    }));
                                                }

                                                if (!hasLowerCase(val) || !hasUpperCase(val)
                                                    || !hasNumber(val) || !specialRgx(val)) {
                                                    setChangePasswordErrorToggel((pre: any) => ({
                                                        ...pre, newPassword: 'Password should contain alphanumeric letters with atleast one upper case, lower case and special character',
                                                    }));
                                                }
                                                else {
                                                    setChangePasswordErrorToggel((pre: any) => ({
                                                        ...pre, newPassword: '',
                                                    }));
                                                }

                                                if (val !== changePassword.confirmPassword) {
                                                    setChangePasswordErrorToggel((pre: any) => ({
                                                        ...pre, confirmPassword: 'Confirm password does not match to new password'
                                                    }));
                                                }

                                                if (val === changePassword.confirmPassword) {
                                                    setChangePasswordErrorToggel((pre: any) => ({
                                                        ...pre, confirmPassword: '',
                                                    }));
                                                }
                                            }}
                                        >

                                        </Form.Control>
                                        {changePasswordErrorToggel.newPassword && <ErrorInputComponent error={changePasswordErrorToggel.newPassword} />}
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Confirm Password<span>*</span></Form.Label>
                                        <Form.Control type="password" placeholder="Confirm Password" className="input-font"
                                            onChange={(e) => {
                                                const val: string = e.target.value;
                                                setChangePassword((pre: IChangePassword) => ({ ...pre, confirmPassword: val }));

                                                if (val !== changePassword.newPassword) {
                                                    setChangePasswordErrorToggel((pre: any) => ({
                                                        ...pre, confirmPassword: 'Confirm password does not match to new password'
                                                    }));
                                                }

                                                if (val === changePassword.newPassword) {
                                                    setChangePasswordErrorToggel((pre: any) => ({
                                                        ...pre, confirmPassword: ''
                                                    }));
                                                }
                                            }}
                                        >

                                        </Form.Control>
                                        {changePasswordErrorToggel.confirmPassword && <ErrorInputComponent error={changePasswordErrorToggel.confirmPassword} />}
                                    </Form.Group>
                                </Form>
                                <div className="row">
                                    <div className="col-xl-10">
                                        <div className="small"><em><b>* are required fields.</b></em></div>
                                        <div className="small"><em><b>Rules: New password should contain alphanumeric, upper case, lower case and special character with minimum 6 character length</b></em></div>
                                    </div>
                                    <div className="col-xl-2">
                                        {
                                            isChangeingPassword ? <Button variant="primary" className="float-end text-secondary" disabled>
                                                <span className="mx-2 text-secondary">
                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                </span>
                                                Changing... </Button> : <Button variant="primary" className="float-end font-weight-600 text-color-182C34" type="button"
                                                    onClick={changeUserPassword}
                                                >
                                                    Change Password
                                    </Button>
                                        }
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            ) :
                <div className="row mt-5">
                    <div className="col-xl-12">
                        <Card className="cardBox">
                            <Card.Header as="h5">Change Password</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Password <span>*</span></Form.Label>
                                        <Form.Control type="password" placeholder="Current Password" className="input-font cardBox cardBorder"
                                            onChange={(e) => {
                                                const val: string = e.target.value;
                                                setChangePassword((pre: IChangePassword) => ({ ...pre, currentPassword: val }));
                                                if (val.length <= 0) {
                                                    setChangePasswordErrorToggel((pre: any) => ({
                                                        ...pre, currentPassword: 'Plese enter current password'
                                                    }));
                                                }
                                                else {
                                                    setChangePasswordErrorToggel((pre: any) => ({
                                                        ...pre, currentPassword: '',
                                                    }));
                                                }

                                            }}
                                        >

                                        </Form.Control>
                                        {changePasswordErrorToggel.currentPassword && <ErrorInputComponent error={changePasswordErrorToggel.currentPassword} />}
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>New Password <span>*</span></Form.Label>
                                        <Form.Control type="password" placeholder="New Password" className="input-font cardBox cardBorder"
                                            onChange={(e) => {
                                                const val: string = e.target.value;
                                                setChangePassword((pre: IChangePassword) => ({ ...pre, newPassword: val }));
                                                if (val.length === 0 && val.length < 6) {
                                                    setChangePasswordErrorToggel((pre: any) => ({
                                                        ...pre, newPassword: 'Password should 6 letter in length'
                                                    }));
                                                }
                                                else {
                                                    setChangePasswordErrorToggel((pre: any) => ({
                                                        ...pre, newPassword: '',
                                                    }));
                                                }

                                                if (!hasLowerCase(val) || !hasUpperCase(val)
                                                    || !hasNumber(val) || !specialRgx(val)) {
                                                    setChangePasswordErrorToggel((pre: any) => ({
                                                        ...pre, newPassword: 'Password should contain alphanumeric letters with atleast one upper case, lower case and special character',
                                                    }));
                                                }
                                                else {
                                                    setChangePasswordErrorToggel((pre: any) => ({
                                                        ...pre, newPassword: '',
                                                    }));
                                                }

                                                if (val !== changePassword.confirmPassword) {
                                                    setChangePasswordErrorToggel((pre: any) => ({
                                                        ...pre, confirmPassword: 'Confirm password does not match to new password'
                                                    }));
                                                }

                                                if (val === changePassword.confirmPassword) {
                                                    setChangePasswordErrorToggel((pre: any) => ({
                                                        ...pre, confirmPassword: '',
                                                    }));
                                                }
                                            }}
                                        >

                                        </Form.Control>
                                        {changePasswordErrorToggel.newPassword && <ErrorInputComponent error={changePasswordErrorToggel.newPassword} />}
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Confirm Password<span>*</span></Form.Label>
                                        <Form.Control type="password" placeholder="Confirm Password" className="input-font cardBox cardBorder"
                                            onChange={(e) => {
                                                const val: string = e.target.value;
                                                setChangePassword((pre: IChangePassword) => ({ ...pre, confirmPassword: val }));

                                                if (val !== changePassword.newPassword) {
                                                    setChangePasswordErrorToggel((pre: any) => ({
                                                        ...pre, confirmPassword: 'Confirm password does not match to new password'
                                                    }));
                                                }

                                                if (val === changePassword.newPassword) {
                                                    setChangePasswordErrorToggel((pre: any) => ({
                                                        ...pre, confirmPassword: ''
                                                    }));
                                                }
                                            }}
                                        >

                                        </Form.Control>
                                        {changePasswordErrorToggel.confirmPassword && <ErrorInputComponent error={changePasswordErrorToggel.confirmPassword} />}
                                    </Form.Group>
                                </Form>
                                <div className="row">
                                    <div className="col-xl-10">
                                        <div className="small"><em><b>* are required fields.</b></em></div>
                                        <div className="small"><em><b>Rules: New password should contain alphanumeric, upper case, lower case and special character with minimum 6 character length</b></em></div>
                                    </div>
                                    <div className="col-xl-2">
                                        {
                                            isChangeingPassword ? <Button variant="primary" className="float-end text-secondary" disabled>
                                                <span className="mx-2 text-secondary">
                                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                </span>
                                                Changing... </Button> : <Button variant="primary" className="float-end font-weight-600 text-color-182C34" type="button"
                                                    onClick={changeUserPassword}
                                                >
                                                    Change Password
                                    </Button>
                                        }
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>}
        </>
    );
}

export default UserSecurityCompnent;