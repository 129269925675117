import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Headers } from "../../../Utils/Enums";
import HeaderComponent from "../Header/HeaderComponent";
import PaginationComponent from "../Paginator/PaginationComponent";
import PracticeTableComponent from "./PracticeTableComponent";
import "./UserPracticeComponentsStyle/PracticeTableComponent.css";
import { ThemeContext } from "../../../Contexts/ThemeContext";
import "../../../darkcustom.css";
import PracticeLibraryFilterComponent from "../../FunctionalComponents/UserPracticeComponents/PracticeLibraryFilterComponent";
import UserPractice from "../../../Services/UserPractice.service";

interface ImoduleData {
    id: number;
    title: string;
    heading: any;
    userModuleStatusId: number;
    programStageId: number;
    isActive: boolean;
    moduleSteps: any;
    concepts: any;
    additionalResources: any;
}

interface Iprops {
    practiceData: any[];
    actionOnPractice: (id: number) => Promise<void>;
    setPracticeStatus: any;
    setCurrentPage: any;
    totalPages: number;
    totalItems: number;
    currentPage: number;
    pageSize: number;
    currentPractice: (id: number) => Promise<void>;
    deletePractice: (id: number) => Promise<void>;
    getClickedPractice?: (practiceId: number) => Promise<void>;
    selectedPractice?: any;
    setSelectedPractice?: any;
    getPracticeList:(isFilter:boolean,searchText:string,moduleId:number) => Promise<void>;
}

function PracticePlaylistComponent(props: Iprops) {
    let { theme } = useContext(ThemeContext)
    const practice = new UserPractice();
    const {
        practiceData,
        actionOnPractice,
        setPracticeStatus,
        setCurrentPage,
        totalItems,
        totalPages,
        currentPage,
        pageSize,
        currentPractice,
        deletePractice,
        getClickedPractice,
        selectedPractice,
        setSelectedPractice,
        getPracticeList
    } = props;

    let history = useHistory();
    const [searchText, setSearchText] = useState<string>("");
    const [selectedModule, setSelectedModule] = useState<number>(0);
    const [moduleData, setModuleData] = useState<ImoduleData[]>([]);

    useEffect(() => {
        getUserModule();
    }, []);

    const handleSearchText = (value: string) => {
        setSearchText(value);
    }

    const handleSearch = async () => {
        // setIsFilter(true);
        // if (currentPage == 1) {
        //     await getPracticeLibrary(true);
        // }
        // else {
        //     setCurrentPage(1);
        // }
        props.getPracticeList(true,searchText,selectedModule);
    }

    const handleSelectedModule = async (value: number) => {
        // setIsFilter(true);
        setSelectedModule(value);
        props.getPracticeList(true,searchText,value);
    }

    // for pagination
    const onPageHandler = (currentPageId: number) => {
        setCurrentPage(currentPageId);
    }

    const getUserModule = async () => {
        let response: any = await practice.getUserModuleForPractice();
        if (response.status) {
            let moduleData: ImoduleData[] = response.data.map((value: any) => {
                return {
                    id: value.id,
                    title: value.title,
                    heading: value.heading,
                    userModuleStatusId: value.userModuleStatusId,
                    programStageId: value.programStageId,
                    isActive: value.isActive,
                    moduleSteps: value.moduleSteps,
                    concepts: value.concepts,
                    additionalResources: value.additionalResources,
                }
            });
            setModuleData(moduleData);
        }
    }

    return (
        <>
            <div className="practice-selection">
                <div className="d-flex">
                    <HeaderComponent title="PLAYLIST" size={Headers.H3} />   
                </div>
                <div className="d-flex flex-row justify-content-between link-wrapper">
                    <div className="border-bottom border-dark cursor-pointer" onClick={() => history.push(
                        {
                            pathname: "/practicelibrary",
                            state: { isFromPractice: true }
                        }
                    )}>
                        {theme === "light" ? (
                            <HeaderComponent title="View Library" size={Headers.H3} />
                        ) : <div className="tableTextColor" >
                                <HeaderComponent title="View Library" size={Headers.H3} />
                            </div>
                        }
                    </div>


                    <div className="vr" />

                    <div className="border-bottom border-dark cursor-pointer" onClick={() => setPracticeStatus("Completed")}>
                        {theme === "light" ? (
                            <HeaderComponent title="Completed" size={Headers.H3} />
                        ) : <div className="tableTextColor"><HeaderComponent title="Completed" size={Headers.H3} /></div>}

                    </div>
                </div>
            </div>
            <div>
            <PracticeLibraryFilterComponent
                    searchText={searchText}
                    handleSearchText={handleSearchText}
                    handleSearch={handleSearch}
                    moduleData={moduleData}
                    handleSelectedModule={handleSelectedModule}
                />
            </div>
            <div>
                <PracticeTableComponent
                    practiceData={practiceData}
                    actionOnPractice={actionOnPractice}
                    currentPractice={currentPractice}
                    deletePractice={deletePractice}
                    selectedPractice={selectedPractice}
                    setSelectedPractice={setSelectedPractice}
                    getClickedPractice={getClickedPractice}
                />
            </div>
            {
                practiceData.length > 0 && (<div className='d-flex justify-content-end mb-3'>
                    <PaginationComponent
                        currentPage={currentPage}
                        pageSize={pageSize}
                        totalItems={totalItems}
                        totalPages={totalPages}
                        onPageHandler={onPageHandler}
                    />
                </div>)
            }

        </>
    );
}

export default PracticePlaylistComponent;