import React, { useEffect, useRef, useState, useContext } from "react";
import LoaderComponent from "../../FunctionalComponents/Loader/LoaderComponent";
import { Card, Form, Spinner } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import CircleImageComponent from "../../FunctionalComponents/CircleImage/CircleImageComponent";
import Profile from '../../../Services/Profile.service';
import Lookup from "../../../Services/Lookup.service";
import User from "../../../Services/UserDetails.service";
import { IUserAccountInfo } from "../../../interfaces/User/IUserAccount";
import { createNotification } from "../../../Utils/Toast";
import ErrorInputComponent from "../../FunctionalComponents/ErrorInput/ErrorInputComponent";
import { Types, UserContext, UserContextAction } from "../../../Contexts/UserContext";
import ConfirmationModal from "../../FunctionalComponents/ConfirmationModal/ConfirmationModal";
import Switch from "react-switch";
import Notification from '../../../Services/Notification.service';
import "../../../darkcustom.css"
import { ThemeContext } from "../../../Contexts/ThemeContext";

const UserProfileComponent = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [isSavingUserInfo, setIsSavingUserInfo] = useState<boolean>(false);
    const [changingProfilePic, setChangingProfilePic] = useState<boolean>(false);
    const inputFile = useRef<HTMLInputElement | null>(null);
    const profileService = new Profile();
    const lookupService = new Lookup();
    const userService = new User();
    const notificationService = new Notification();
    const [countries, setCountries] = useState<any[]>([]);
    const [states, setStates] = useState<any[]>([]);
    const [showRemovePhotoModal, setShowRemovePhotoModal] = useState<boolean>(false);
    const [disableNotificationSwitch, setDisableNotificationSwitch] = useState<boolean>(false);
    const [disableplayConceptInAudioSwitch, setDisableplayConceptInAudioSwitch] = useState<boolean>(false);
    const [userAccountInfo, setUserAccountInfo] = useState<IUserAccountInfo>({
        countryId: 0,
        stateId: 0,
        firstName: '',
        lastName: '',
        email: '',
        isNotificationOn: true,
        isConceptAudioOn:true,
    });

    const [userInfoErrorToggel, setUserInfoErrorToggel] = useState<any>({
        firstName: "",
        lastName: "",
    });

    const userContext = useContext(UserContext);
    let { toggleTheme, theme } = useContext(ThemeContext)

    useEffect(() => {
        let isCancelled = false;
        if (!isCancelled) {
            getUserAccountInfo();
            getCountries();
        }
        return () => {
            isCancelled = true;
        }
    }, []);

    useEffect(() => {
        let isCancelled = false;
        if (!isCancelled) {
            if (userAccountInfo.countryId > 0) {
                getStates();
            }
            else if (userAccountInfo.countryId <= 0) {
                setUserAccountInfo((pre) => ({ ...pre, stateId: 0 }));
                setStates([]);
            }
        }
        return () => {
            isCancelled = true;
        }
    }, [userAccountInfo.countryId]);

    const profilePicChangeHandler = () => {
        inputFile.current?.click();
    }

    const removeProfilePicHandler = () => {
        setShowRemovePhotoModal(true);
    }

    const removeProfile = async () => {
        setChangingProfilePic(true);
        const response = await profileService.removeProfilePhoto();
        if (response && response.status) {
            let updateUserImg: UserContextAction = {
                type: Types.REMOVE_PROFILE_IMG,
                value: null
            };
            userContext?.dispatch(updateUserImg);
        }
        setChangingProfilePic(false);
    }

    const onImageSelect = async (e: any) => {
        let files = e.target.files
        setChangingProfilePic(true);
        if (!files) return;
        if (files[0]['type'].split('/')[0] !== 'image') {
            createNotification('error', 'Plese select image file with .png or .jpeg');
        }
        else if (((files[0].size / (1024 * 1024))) > 5) {
            createNotification('error', 'File size should be no more then 5mb');
        }
        else {
            const uploadImage = await profileService.uploadPicture(files[0]);
            if (uploadImage.ok) {
                let updateUserImg: UserContextAction = {
                    type: Types.UPDATE_PROFILE_IMG,
                    value: uploadImage.data.imgUrl.replace('/api', '')
                };
                userContext?.dispatch(updateUserImg);
            }
        }
        e.target.value = null;
        setChangingProfilePic(false);
    }

    const getUserAccountInfo = async () => {
        const response = await userService.getUserAccountDetails();
        if (response) {
            if (response.status) {
                if (response.data) {
                    setUserAccountInfo(response.data);
                    setLoading(false);
                }
            }
        }
    }

    const saveUserAccountInfo = async () => {
        setIsSavingUserInfo(true);
        if (userAccountInfo.firstName.length > 0 && userAccountInfo.lastName.length > 0) {
            const response = await userService.saveUserAccountDetails({
                countryId: userAccountInfo.countryId,
                stateId: userAccountInfo.stateId,
                firstName: userAccountInfo.firstName,
                lastName: userAccountInfo.lastName,
            });
            if (response) {
                if (response.status) {
                    if (response.data) {
                        createNotification('success', response.message);
                    }
                    else {
                        createNotification('error', response.message);
                    }
                }
            }
        }
        else {
            createNotification('error', 'Please fill required field');
        }
        setIsSavingUserInfo(false);
    }

    const getCountries = async () => {
        const countryResult = await lookupService.getCountries();
        setCountries(countryResult);
    }

    const getStates = async () => {
        const stateResult = await lookupService.getStates(userAccountInfo.countryId);;
        setStates(stateResult);
    }

    const handleRemoveProfileImgClose = () => {
        setShowRemovePhotoModal(false);
    }

    const handleRemoveProfileImgConfirm = () => {
        handleRemoveProfileImgClose();
        removeProfile();
    }

    const handleChange = async (nextChecked: any) => {
        setDisableNotificationSwitch(true);
        const response = await userService.ChangeNotification();
        if (response && response.status) {
            if (response.data) {
                let obj: UserContextAction = {
                    type: Types.CHANGE_NOTIFICATION,
                    value: response.data.currentState
                }
                userContext.dispatch(obj);
                if (response.data.currentState) {
                    getNotificationCount();
                }
                else {
                    let obj: UserContextAction = {
                        type: Types.HAS_NOTIFICATION,
                        value: false
                    }
                    userContext.dispatch(obj);
                }
                setUserAccountInfo((pre: IUserAccountInfo) => ({ ...pre, isNotificationOn: response.data.currentState }));
            }
            else {
                setUserAccountInfo((pre: IUserAccountInfo) => ({ ...pre, isNotificationOn: !nextChecked }));
            }
        }
        else {
            setUserAccountInfo((pre: IUserAccountInfo) => ({ ...pre, isNotificationOn: !nextChecked }));
        }
        setDisableNotificationSwitch(false);
    };

    const handleConceptPreference = async (nextChecked: any) => {
        setDisableplayConceptInAudioSwitch(true);
        const response = await userService.ChangePlayModeForConcept();
        if (response && response.status) {
            if (response.data) {
                let obj: UserContextAction = {
                    type: Types.CHANGE_CONCEPT_AUDIO,
                    value: response.data.currentState
                }
                userContext.dispatch(obj);
                setUserAccountInfo((pre: IUserAccountInfo) => ({ ...pre, isConceptAudioOn: response.data.currentState }));
            }
            else {
                setUserAccountInfo((pre: IUserAccountInfo) => ({ ...pre, isConceptAudioOn: !nextChecked }));
            }
        }
        else {
            setUserAccountInfo((pre: IUserAccountInfo) => ({ ...pre, isConceptAudioOn: !nextChecked }));
        }
        setDisableplayConceptInAudioSwitch(false);
    }

    const getNotificationCount = async () => {
        const response = await notificationService.getUnReadNotificationCount();
        if (response && response.status) {
            if (response.data !== null) {
                let obj: UserContextAction = {
                    type: Types.HAS_NOTIFICATION,
                    value: response.data > 0 ? true : false
                }
                userContext.dispatch(obj);
            }
        }
    }

    return (
        <>
            {
                <ConfirmationModal
                    modalTitle={'Remove Profile Image'}
                    closeButtonText={'Cancel'}
                    successButtonText={'Confirm'}
                    handleClose={handleRemoveProfileImgClose}
                    processingText={'Processing...'}
                    modalBody={'Are you sure you want to remove profile image ?'}
                    show={showRemovePhotoModal}
                    isLoading={false}
                    handleConfirmClick={handleRemoveProfileImgConfirm}
                />
            }
            {theme === "light" ? (
                <div className="row mt-5">
                    <div className="col-xl-4">
                        <Card>
                            <Card.Header as="h5">Profile Picture</Card.Header>
                            <Card.Body className="text-center" >
                                <CircleImageComponent
                                    image={userContext?.state.currentUser.profileImage ? userContext?.state.currentUser.profileImage : ''}
                                    size={'imageSize152'}
                                    screenType={'profile'}
                                />
                                <input type='file' accept="image/png,image/jpeg" id='profileFile' ref={inputFile} style={{ display: 'none' }} onChange={(e) => onImageSelect(e)} />
                                <div className="mt-3 small font-italic text-muted mb-4">JPG or PNG no larger than 5 MB</div>
                                {
                                    changingProfilePic ? <Button variant="primary" className="text-secondary" disabled>
                                        <span className="mx-2 text-secondary">
                                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                        </span>
                                        Changing... </Button> : <div><Button variant="primary" className="font-weight-600 text-color-182C34" type="button" onClick={profilePicChangeHandler}>
                                            Change
                                        </Button>
                                        {
                                            userContext?.state.currentUser.profileImage &&
                                            <Button variant="secondary" className="font-weight-600 text-color-182C34 mx-2" type="button" onClick={removeProfilePicHandler}>
                                                Remove
                                            </Button>
                                        }
                                    </div>
                                }
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-xl-8">
                        <Card>
                            <Card.Header as="h5">Account Details</Card.Header>
                            <Card.Body>
                                {
                                    loading ? <LoaderComponent /> : <div>
                                        <Form>
                                            <Form.Group className="mb-3">
                                                <Form.Label>First Name <span>*</span></Form.Label>
                                                <Form.Control defaultValue={userAccountInfo.firstName} type="text" placeholder="First name" className="input-font" onChange={(e: any) => {
                                                    setUserAccountInfo((pre: IUserAccountInfo) => ({ ...pre, firstName: e.target.value }));
                                                    if (e.target.value === '') {
                                                        setUserInfoErrorToggel((pre: any) => ({
                                                            ...pre, firstName: 'First Name is required'
                                                        }));
                                                    }
                                                    else if (e.target.value.length > 0) {
                                                        setUserInfoErrorToggel((pre: any) => ({
                                                            ...pre, firstName: ''
                                                        }));
                                                    }
                                                }}>
                                                </Form.Control>
                                                {userInfoErrorToggel.firstName && <ErrorInputComponent error={userInfoErrorToggel.firstName} />}
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Last Name <span>*</span></Form.Label>
                                                <Form.Control defaultValue={userAccountInfo.lastName} type="text" placeholder="Last name" className="input-font" onChange={(e: any) => {
                                                    setUserAccountInfo((pre: IUserAccountInfo) => ({ ...pre, lastName: e.target.value }));
                                                    if (e.target.value === '') {
                                                        setUserInfoErrorToggel((pre: any) => ({
                                                            ...pre, lastName: 'Last Name is required'
                                                        }));
                                                    }
                                                    else if (e.target.value.length > 0) {
                                                        setUserInfoErrorToggel((pre: any) => ({
                                                            ...pre, lastName: ''
                                                        }));
                                                    }
                                                }}>
                                                </Form.Control>
                                                {userInfoErrorToggel.lastName && <ErrorInputComponent error={userInfoErrorToggel.lastName} />}
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control defaultValue={userAccountInfo.email} type="email" disabled={true} className="input-font"></Form.Control>
                                            </Form.Group>
                                            <div className="row">
                                                <div className="col-xl-6">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Country</Form.Label>
                                                        <Form.Select value={userAccountInfo.countryId} onChange={(e: any) => {
                                                            setUserAccountInfo((pre: IUserAccountInfo) => ({ ...pre, countryId: parseInt(e.target.value) }));
                                                        }}>
                                                            <option value={0}>Select Country</option>
                                                            {countries.map((country: any) => <option key={country.id} value={country.id}>{country.name}</option>)}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-xl-6">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>State</Form.Label>
                                                        <Form.Select value={userAccountInfo.stateId} onChange={(e: any) => {
                                                            setUserAccountInfo((pre: IUserAccountInfo) => ({ ...pre, stateId: parseInt(e.target.value) }));
                                                        }}>
                                                            <option value={0}>Select State</option>
                                                            {states.map((state: any) => <option key={state.id} value={state.id}>{state.name}</option>)}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </Form>
                                        <div className="row">
                                            <div className="col-xl-8">
                                                <div className="small"><em><b>* are required fields.</b></em></div>
                                            </div>
                                            <div className="col-xl-4">
                                                {
                                                    isSavingUserInfo ? <Button variant="primary" className="float-end text-secondary" disabled>
                                                        <span className="mx-2 text-secondary">
                                                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                        </span>
                                                        Saving... </Button> : <Button variant="primary" onClick={saveUserAccountInfo}
                                                            className="float-end font-weight-600 text-color-182C34"
                                                            disabled={userInfoErrorToggel.firstName || userInfoErrorToggel.lastName}
                                                            type="button">
                                                        Save Changes
                                                    </Button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-xl-12 mt-3">
                        <Card>
                            <Card.Header as="h5">Preferences</Card.Header>
                            <Card.Body>
                                <div className="row mb-3">
                                    <div className="col-10">
                                        <label>
                                            <span>Allow Notifications : </span>
                                        </label>
                                    </div>
                                    <div className="col-2">
                                        <Switch
                                            onChange={handleChange}
                                            checked={userAccountInfo.isNotificationOn}
                                            className={'align-middle mx-2'}
                                            onColor="#26cac1"
                                            disabled={disableNotificationSwitch}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                        />
                                        {
                                            disableNotificationSwitch && <span className="mx-2 text-secondary">
                                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                            </span>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-10">
                                        <label>
                                            <span>Default audio on in concept video : </span>
                                        </label>
                                    </div>
                                    <div className="col-2">
                                        <Switch
                                            onChange={handleConceptPreference}
                                            checked={userAccountInfo.isConceptAudioOn}
                                            className={'align-middle mx-2'}
                                            onColor="#26cac1"
                                            disabled={disableplayConceptInAudioSwitch}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                        />
                                        {
                                            disableplayConceptInAudioSwitch && <span className="mx-2 text-secondary">
                                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                            </span>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div style={{ marginTop: "15px" }} className="col-10">
                                        <label>
                                            <span>Dark Theme : </span>
                                        </label>
                                    </div>
                                    <div style={{ marginTop: "15px" }} className="col-2">
                                        <Switch
                                            onChange={toggleTheme}
                                            checked={theme !== 'light'}
                                            className={'align-middle mx-2'}
                                            onColor="#26cac1"
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                        />
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            ) : <div className="row mt-5">
                <div className="col-xl-4">
                    <Card className="cardBox">
                        <Card.Header as="h5">Profile Picture</Card.Header>
                        <Card.Body className="text-center " >
                            <CircleImageComponent
                                image={userContext?.state.currentUser.profileImage ? userContext?.state.currentUser.profileImage : ''}
                                size={'imageSize152'}
                                screenType={'profile'}
                            />
                            <input type='file' accept="image/png,image/jpeg" id='profileFile' ref={inputFile} style={{ display: 'none' }} onChange={(e) => onImageSelect(e)} />
                            <div className="mt-3 small font-italic text-muted mb-4">JPG or PNG no larger than 5 MB</div>
                            {
                                changingProfilePic ? <Button variant="primary" className="text-secondary" disabled>
                                    <span className="mx-2 text-secondary">
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                    </span>
                                    Changing... </Button> : <div><Button variant="primary" className="font-weight-600 text-color-182C34" type="button" onClick={profilePicChangeHandler}>
                                        Change
                                    </Button>
                                    {
                                        userContext?.state.currentUser.profileImage &&
                                        <Button variant="secondary" className="font-weight-600 text-color-182C34 mx-2" type="button" onClick={removeProfilePicHandler}>
                                            Remove
                                        </Button>
                                    }
                                </div>
                            }
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-xl-8">
                    <Card className="cardBox">
                        <Card.Header as="h5">Account Details</Card.Header>
                        <Card.Body>
                            {
                                loading ? <LoaderComponent /> : <div>
                                    <Form>
                                        <Form.Group className="mb-3">
                                            <Form.Label>First Name <span>*</span></Form.Label>
                                            <Form.Control style={{ color: "white" }} defaultValue={userAccountInfo.firstName} type="text" placeholder="First name" className="input-font cardBox cardBorder" onChange={(e: any) => {
                                                setUserAccountInfo((pre: IUserAccountInfo) => ({ ...pre, firstName: e.target.value }));
                                                if (e.target.value === '') {
                                                    setUserInfoErrorToggel((pre: any) => ({
                                                        ...pre, firstName: 'First Name is required'
                                                    }));
                                                }
                                                else if (e.target.value.length > 0) {
                                                    setUserInfoErrorToggel((pre: any) => ({
                                                        ...pre, firstName: ''
                                                    }));
                                                }
                                            }}>
                                            </Form.Control>
                                            {userInfoErrorToggel.firstName && <ErrorInputComponent error={userInfoErrorToggel.firstName} />}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Last Name <span>*</span></Form.Label>
                                            <Form.Control style={{ color: "white" }} defaultValue={userAccountInfo.lastName} type="text" placeholder="Last name" className="input-font cardBox cardBorder" onChange={(e: any) => {
                                                setUserAccountInfo((pre: IUserAccountInfo) => ({ ...pre, lastName: e.target.value }));
                                                if (e.target.value === '') {
                                                    setUserInfoErrorToggel((pre: any) => ({
                                                        ...pre, lastName: 'Last Name is required'
                                                    }));
                                                }
                                                else if (e.target.value.length > 0) {
                                                    setUserInfoErrorToggel((pre: any) => ({
                                                        ...pre, lastName: ''
                                                    }));
                                                }
                                            }}>
                                            </Form.Control>
                                            {userInfoErrorToggel.lastName && <ErrorInputComponent error={userInfoErrorToggel.lastName} />}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control style={{ backgroundColor: "#1C2930", color: "white" }} defaultValue={userAccountInfo.email} type="email" disabled={true} className="input-font cardBox cardBorder"></Form.Control>
                                        </Form.Group>
                                        <div className="row">
                                            <div className="col-xl-6">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Country</Form.Label>
                                                    <Form.Select style={{ color: "white" }} className="cardBox cardBorder" value={userAccountInfo.countryId} onChange={(e: any) => {
                                                        setUserAccountInfo((pre: IUserAccountInfo) => ({ ...pre, countryId: parseInt(e.target.value) }));
                                                    }}>
                                                        <option value={0}>Select Country</option>
                                                        {countries.map((country: any) => <option key={country.id} value={country.id}>{country.name}</option>)}
                                                    </Form.Select>
                                                </Form.Group>
                                            </div>
                                            <div className="col-xl-6">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>State</Form.Label>
                                                    <Form.Select style={{ color: "white" }} className="cardBox cardBorder" value={userAccountInfo.stateId} onChange={(e: any) => {
                                                        setUserAccountInfo((pre: IUserAccountInfo) => ({ ...pre, stateId: parseInt(e.target.value) }));
                                                    }}>
                                                        <option value={0}>Select State</option>
                                                        {states.map((state: any) => <option key={state.id} value={state.id}>{state.name}</option>)}
                                                    </Form.Select>
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </Form>
                                    <div className="row">
                                        <div className="col-xl-8">
                                            <div className="small"><em><b>* are required fields.</b></em></div>
                                        </div>
                                        <div className="col-xl-4">
                                            {
                                                isSavingUserInfo ? <Button variant="primary" className="float-end text-secondary" disabled>
                                                    <span className="mx-2 text-secondary">
                                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                    </span>
                                                    Saving... </Button> : <Button variant="primary" onClick={saveUserAccountInfo}
                                                        className="float-end font-weight-600 text-color-182C34"
                                                        disabled={userInfoErrorToggel.firstName || userInfoErrorToggel.lastName}
                                                        type="button">
                                                    Save Changes
                                                </Button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-xl-12 mt-3">
                    <Card className="cardBox">
                        <Card.Header as="h5">Preferences</Card.Header>
                        <Card.Body>
                            <div className="row mb-3">
                                <div className="col-10">
                                    <label>
                                        <span>Allow Notifications : </span>
                                    </label>
                                </div>
                                <div className="col-2">
                                    <Switch
                                        onChange={handleChange}
                                        checked={userAccountInfo.isNotificationOn}
                                        className={'align-middle mx-2'}
                                        onColor="#26cac1"
                                        disabled={disableNotificationSwitch}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                    />
                                    {
                                        disableNotificationSwitch && <span className="mx-2 text-secondary">
                                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                        </span>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-10">
                                    <label>
                                        <span>Default audio on/off in concept videos : </span>
                                    </label>
                                </div>
                                <div className="col-2">
                                    <Switch
                                        onChange={handleConceptPreference}
                                        checked={userAccountInfo.isConceptAudioOn}
                                        className={'align-middle mx-2'}
                                        onColor="#26cac1"
                                        disabled={disableplayConceptInAudioSwitch}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                    />
                                    {
                                        disableplayConceptInAudioSwitch && <span className="mx-2 text-secondary">
                                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                        </span>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div style={{ marginTop: "15px" }} className="col-10">
                                    <label>
                                        <span>Dark Theme : </span>
                                    </label>
                                </div>
                                <div style={{ marginTop: "15px" }} className="col-2">
                                    <Switch
                                        onChange={toggleTheme}
                                        checked={theme === 'dark'}
                                        className={'align-middle mx-2'}
                                        onColor="#26cac1"
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                    />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>}
        </>
    );
}

export default UserProfileComponent;