import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ContentShellComponent from "../../FunctionalComponents/ContentShell/ContentShellComponent";
import HeaderComponent from "../../FunctionalComponents/Header/HeaderComponent";
import { Headers } from '../../../Utils/Enums';
import conceptStyles from './QuizComponent.module.css';
import TextComponent from "../../FunctionalComponents/Text/TextComponent";
import GeneralChatMessagesComponent from "../../FunctionalComponents/GeneralChatMessages/GeneralChatMessagesComponent";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { IConceptItem } from "../../FunctionalComponents/ConceptItem/ConceptItemComponent";
import General from "../../../Services/General.service";
import { UserContext } from "../../../Contexts/UserContext";
import { ThemeContext } from "../../../Contexts/ThemeContext";
const QuizComponent = (props: any) => {
    const generalService = new General();
    generalService.getIcons();
    const [myState, setMyState] = useState({
        dropdownOpen: false
    });
    let tempString: string = String(sessionStorage.getItem("listOfConcepts"));
    let conceptsList: IConceptItem[] = JSON.parse(tempString);
    const [concepts, setConcepts] = useState<IConceptItem[]>(conceptsList);
    const userContext = useContext(UserContext);
    const { theme } = useContext(ThemeContext);
    const moduleStepId = props.match.params.moduleStepId;
    const moduleId = props.match.params.moduleId;
    const toggle = () => {
        setMyState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }
    const ChangeConcept = (event: any) => {
        setConcepts(conceptsList);
    }
    useEffect(() => {
        userContext.dispatch('');
    }, [])
    return <>
        <div className="concept-component">
            <div className='d-flex justify-content-between border-bottom-EBEBEB-thin'>
                <Link to={`/module/${moduleStepId}/${moduleId}`} className="text-decoration-none">
                    {theme === "light" ? (
                        <HeaderComponent
                            title={"Awareness"}
                            size={Headers.H1}
                            icon={'chevron-left.svg'}
                        />
                    ) : <HeaderComponent
                            customClassName={"imageText"}
                            title={"Awareness"}
                            size={Headers.H1}
                            icon={'left-chevron-dark.svg'}
                        />}
                </Link>
            </div>
            <Dropdown isOpen={myState.dropdownOpen} toggle={toggle} className="d-flex justify-content-between concept-select-config border-bottom-EBEBEB-thin margin-bottom-24">
                <div className="d-flex justify-content-start padding-8">
                    <img src={window.location.origin + '/images/stimulus.svg'} alt="stimulus" />
                    <div className="h5 align-self-center mb-0 margin-left-12">{'Awareness Quiz'}</div>
                </div>
                <DropdownToggle caret>
                </DropdownToggle>
                <DropdownMenu>
                    {concepts.map((item: IConceptItem, index: number) =>

                        <DropdownItem header key={index}>
                            <Link to={`${"/concept/" + item.id + "/" + props.match.params.moduleStepId + "/" + props.match.params.moduleId}`} className="text-decoration-none" onClick={ChangeConcept}>
                                <TextComponent text={item.title} />
                            </Link>
                        </DropdownItem>
                    )}

                </DropdownMenu>
            </Dropdown>
            <div className="margin-bottom-78">
                <ContentShellComponent>
                    <div className={`d-xl-inline-flex justify-content-between ${conceptStyles.contentShellLeftConfig}`}>
                        <ContentShellComponent customClass={'rightSideConfig'} >
                            <div className="margin-bottom-24" id='conceptQiz'>
                                <TextComponent text={'Selecting from this information, we foreground what we deem most relevant and background the rest.'} />
                            </div>
                            <div>
                                <HeaderComponent
                                    title={"STORY BOARD"}
                                    size={Headers.H3}
                                />
                            </div>
                            <div>
                                <div className="d-flex margin-top-16 margin-bottom-24">
                                    <div className='d-flex flex-column'>
                                        <TextComponent text={'This can be instructions, or the previous answer or stimulous if the user needs to compare to something. For instance, this is what you’ll be filling out:'} />
                                        <ul>
                                            <li>Stimulus</li>
                                            <li>Selection</li>
                                            <li>Interpretation</li>
                                            <li>Conclusion</li>
                                            <li>Position</li>
                                            <li>State and Response</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="padding-16 background-EFF8F7 border-radius-4">
                                <div className="margin-bottom-8">
                                    <HeaderComponent
                                        title={"Card title"}
                                        size={Headers.H3}
                                    />
                                </div>
                                <TextComponent text={'This is your previous answer, which you might need to view whilst replying in some use cases, like the Storyboard Exercise  - Awareness Module content outlier, or reaction to response exercises.'} />
                            </div>

                        </ContentShellComponent>
                        <ContentShellComponent customClass={'leftSideConfig'}>


                            <GeneralChatMessagesComponent
                                source={'quiz'}
                                moduleId={moduleStepId}
                                buttonTitle={'Continue'}
                                buttonClass={'w-50 float-end'}
                                messageLength={66}></GeneralChatMessagesComponent>
                        </ContentShellComponent>
                    </div>
                </ContentShellComponent>
            </div>
        </div>
    </>
}
export default QuizComponent;