import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import Survey from "../../../Services/Survey.service";
import HeaderComponent from "../../FunctionalComponents/Header/HeaderComponent";
import { Headers } from "../../../Utils/Enums";
import { createNotification } from "../../../Utils/Toast";
import { Button, ProgressBar } from "react-bootstrap";
import SurveyForm from "../../FunctionalComponents/Survey/SurveyForm";
import SurveySummary from "../../FunctionalComponents/Survey/SurveySummary";
import { useHistory } from "react-router";
import './Survey.css';
import {logOutIfTokenExpire} from '../../../Utils/Utils';
import { UserContext } from "../../../Contexts/UserContext";

interface ISelectedOption {
    ansId:any;
    options:any;
}

function TakeSurvey(props: any) {

    const survey = new Survey();
    let history = useHistory();
    const histroyData = props?.location?.state;
    const [surveyName, setSurveyName] = useState<string>("");
    const [selectedQuestion, setSelectedQuestion] = useState<any[]>([]);
    const [freeTextAnswer, setFreeTextAnswer] = useState<any>();
    const [, setSelectedOption] = useState<any[]>([]);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [showSummary, setShowSummary] = useState<boolean>(false);
    const [gotFeedback, setGotFeedback] = useState<boolean>(false);
    const [programSurveyAnswer,] = useState<any>();
    const [, setAnswerUpdated] = useState<boolean>(false);
    const [urlSurveyId, setUrlSurveyId] = useState<any>();
    const userContext = useContext(UserContext);

    useLayoutEffect(() => {
        userContext.dispatch('');
        let surveyUrl = window.location.href;
        let surveyCode = surveyUrl.split("?")[1].split("=")[1];
        setUrlSurveyId(surveyCode);
    }, []);

    useEffect(() => {
        logOutIfTokenExpire();
        if (urlSurveyId !== undefined) {
            getSurveyDetails();
            isSurveyCompleted();
        }
        //eslint-disable-next-line
    }, [urlSurveyId]);


    const isSurveyCompleted = async () => {
        let response: any = await survey.isSurveyCompleted(Number(urlSurveyId));
        if (response.status) {
            createNotification("info", "You have completed the survey!");
            history.push({
                pathname: "/dashboard"
            });
        }
    }

    const getSurveyDetails = async () => {
        let response: any;
        response = await survey.getProgramSurveyById(urlSurveyId);
        if (response.status) {
            let selectedQuestion: any[] = [];
            //eslint-disable-next-line
            response.data.questions.map((value: any) => {
                let tempData: any = {
                    id: value.id,
                    questionId: value.questionId,
                    questionType: value.surveyQuestionType.id,
                    question: value.question,
                    options: value.options?.map((data: any) => {
                        return {
                            id: data.id,
                            option: data.option,
                            checked: false
                        }
                    })
                }
                if (value?.answer && value?.answer?.id) {
                    if (value.answer.freeTextAnswer) {
                        tempData['answer'] = value.answer.freeTextAnswer
                        tempData['answerID'] = value.answer.id
                    } else {
                        tempData['answer'] = value.answer.optionsIds
                        tempData['answerID'] = value.answer.id
                    }
                }
                selectedQuestion.push(tempData);
            });
            setSurveyName(response?.data?.name);
            setSelectedQuestion(selectedQuestion);
        } else {
            createNotification("error", response?.message || "Something went wrong!")
        }
    }

    const handleTextAreaChange = (value: string, type: string, queIndex: number) => {
        if (type === "Update") {
            setAnswerUpdated(true);
            setFreeTextAnswer(value);
            if (Number.isInteger(currentIndex)) {
                let temp = selectedQuestion
                let currentTemp = temp[currentIndex]
                currentTemp.answer = value
                setSelectedQuestion(temp)
            }
        } else if (type === "Edit") {
            let sQuestion = [...selectedQuestion]
            let sQuestionCurrent = sQuestion[queIndex]
            sQuestionCurrent.answer = value
            setSelectedQuestion(sQuestion)
            setFreeTextAnswer(null)
        } else {
            setGotFeedback(true);
            if (value.length === 0) {
                setGotFeedback(false);
            }
            setFreeTextAnswer(value);
        }
    }

    const handleRadioClick = (value: any, type: string, queIndex: number) => {
        if (type === "Update") {
            if (selectedQuestion[currentIndex]?.answerID) {
                setFreeTextAnswer([value])
                updateSelectedQuestions([value],currentIndex);
            } else {
                setAnswerUpdated(true);
            }
        } else if (type === "Edit") {
            updateSelectedQuestions([value],queIndex);
            setFreeTextAnswer(null)
        } else {
            setFreeTextAnswer([value]);
            setGotFeedback(true);
        }
    }

    const handleMultipleClick = (value: any, type: string, queIndex: number) => {
        if (type === "Update") {
            if (selectedQuestion[currentIndex]?.answerID) {
                let tempFreeTextValues: any[] = freeTextAnswer || selectedQuestion[currentIndex]?.answer || []
                if (tempFreeTextValues.includes(value)) {
                    tempFreeTextValues = tempFreeTextValues.filter((item) => item !== value)
                } else {
                    tempFreeTextValues.push(value)
                }
                let tempValues: any = [...new Set(tempFreeTextValues)]
                setFreeTextAnswer(tempValues)
                updateSelectedQuestions(tempValues,currentIndex);    
            } else {
                setAnswerUpdated(true);
            }
        } else if (type === "Edit") {
             let sQuestion = [...selectedQuestion]
             let sQuestionCurrent = sQuestion[queIndex]       
            let tempFreeTextValues: any[] = sQuestionCurrent?.answer || []
            if (tempFreeTextValues.includes(value)) {
                tempFreeTextValues = tempFreeTextValues.filter((item) => item !== value)
            } else {
                tempFreeTextValues.push(value)
            }
            updateSelectedQuestions(tempFreeTextValues,queIndex);            
            setFreeTextAnswer(null)
        } else {
            let tempFreeTextValues: any[] = freeTextAnswer || selectedQuestion[currentIndex]?.answer || []
            tempFreeTextValues.push(value)
            let tempValues: any = [...new Set(tempFreeTextValues)]
            setFreeTextAnswer(tempValues)
            updateSelectedQuestions(tempFreeTextValues,currentIndex);   
            setGotFeedback(true);
        }
    }

    const handleSubmitClick = async (e: any) => {
        e.preventDefault();
        setGotFeedback(false);
        const sQuestion = selectedQuestion[currentIndex]
        if(sQuestion.questionType == 1 && sQuestion.answer.length <= 0){
            createNotification("error", 'Please enter valid text');
            return;
        }        
        else if((sQuestion.questionType == 2 || sQuestion.questionType == 3) && sQuestion.answer.length <= 0){
            createNotification("error", 'Please select option');
            return;
        }
        else{
            if (sQuestion?.answerID) {
                let submitData: any = {
                    id: sQuestion.answerID,
                    surveyProgramId: Number(urlSurveyId),
                    questionId: sQuestion.id,
                    freeTextAnswer: !Array.isArray(freeTextAnswer || selectedQuestion[currentIndex]?.answer) ? freeTextAnswer || selectedQuestion[currentIndex]?.answer || "" : "",
                    optionsIds: Array.isArray(freeTextAnswer || selectedQuestion[currentIndex]?.answer) ? freeTextAnswer || selectedQuestion[currentIndex]?.answer || [] : [],
                }
                let response: any = await survey.updateProgramSurveyAnswer(submitData);
                
                if (response?.status) {
                    if (selectedQuestion.length === currentIndex + 1) {
                        setShowSummary(true);
                        setCurrentIndex(0);
                        setSelectedOption([]);
                        setFreeTextAnswer("");
                    } else {
                        setCurrentIndex(currentIndex + 1);
                        setSelectedOption([]);
                        setFreeTextAnswer("");
                    }
                    createNotification("success", response?.message);
                } else {
                    createNotification("error", response?.message);
                }
            } else {
                let submitData: any = {};
    
                submitData = {
                    userId: histroyData?.userId,
                    surveyProgramId: Number(urlSurveyId),
                    questionId: selectedQuestion[currentIndex]?.id,
                    freeTextAnswer: freeTextAnswer || selectedQuestion[currentIndex]?.answer,
                    optionsIds: Array.isArray(freeTextAnswer) ? freeTextAnswer || [] : [],
                }
                let response: any;
                response = await survey.submitProgramSurveyAnswer(submitData.optionsIds);
                if (response?.status) {
                    setSelectedOption([]);
                    setFreeTextAnswer("");
                    if (selectedQuestion.length === currentIndex + 1) {
                        setShowSummary(true);
                        setCurrentIndex(0);
                        setSelectedOption([]);
                        setFreeTextAnswer("");
                    } else {
                        setCurrentIndex(currentIndex + 1);
                        setSelectedOption([]);
                        setFreeTextAnswer("");
                    }
                    createNotification("success", response?.message);
                } else {
                    createNotification("error", response?.message);
                }
            }
        }        
    }

    const handleCancelAnswer = (e: any, questionId: number, index: number) => {
        //eslint-disable-next-line
        programSurveyAnswer?.map((data: any) => {
            if (data?.optionsIds?.length > 0) {
                if (data?.questionId === questionId) {
                    //eslint-disable-next-line
                    selectedQuestion.map((value: any) => {
                        if (value?.id === questionId && value?.options.length > 0) {
                            //eslint-disable-next-line
                            value?.options?.map((item: any) => {
                                //eslint-disable-next-line
                                data?.optionsIds?.map((findOptions: any, index: number) => {
                                    if (item?.id === findOptions[index]) {
                                        item.checked = true;
                                    }
                                })
                            })
                        }
                    })
                }
            } else {
                if (data?.questionId === questionId) {
                    setFreeTextAnswer(data?.freeTextAnswer);
                }
            }
        })
    }

    const handleUpdateAnswer = async (index: number):Promise<boolean> => {
        const sQuestion = selectedQuestion[index]

        if(sQuestion.questionType == 1 && sQuestion.answer.length <= 0){
            createNotification("error", 'Please enter valid text');
            return false;
        }        
        else if((sQuestion.questionType == 2 || sQuestion.questionType == 3) && sQuestion.answer.length <= 0){
            createNotification("error", 'Please select option');
            return false;
        }
        else{
            let submitData: any = {
                id: sQuestion.answerID,
                surveyProgramId: Number(urlSurveyId),
                questionId: sQuestion.id,
                freeTextAnswer: !Array.isArray(selectedQuestion[index]?.answer) ? freeTextAnswer || selectedQuestion[index]?.answer || "" : "",
                optionsIds: Array.isArray(selectedQuestion[index]?.answer) ? selectedQuestion[index]?.answer || [] : [],
            }
            let response: any = await survey.updateProgramSurveyAnswer(submitData);
            if (response?.status) {
                setAnswerUpdated(false);
                createNotification("success", response?.message);
                return true;
            } else {
                createNotification("error", response?.message);
                return false;
            }
        }    
    }

    const handleSubmitSurvey = async () => {
        let response: any = await survey.submitSurvey(Number(urlSurveyId));
        if (response?.status) {
            localStorage.removeItem("SurveyId");
            window.location.href = "/dashboard";
            createNotification("success", "Thank you. " + response.message);
        } else {
            createNotification("error", response.message);
        }
    }

    const updateSelectedQuestions = (value:any,index:number) => {
        let questions = [...selectedQuestion]
        let currentQuestion = questions[index]
        currentQuestion.answer = value
        currentQuestion[index] = currentQuestion;
        setSelectedQuestion(questions);
    }

    return (
        <>
            <div className="d-flex justify-content-space-between">
                <div className="mb-3">
                    <HeaderComponent
                        title={`Survey Name: ${surveyName}`}
                        size={Headers.H3}
                    />
                </div>
            </div>
            <div className="container d-flex justify-content-center mb-3">
                {showSummary
                    ? null
                    : <HeaderComponent
                        title={`Question ${currentIndex + 1} / ${selectedQuestion.length}`}
                        size={Headers.H3}
                    />
                }
            </div>
            {showSummary
                ? null
                :
                <div className="mb-3">
                    <ProgressBar
                        now={currentIndex + 1}
                        max={selectedQuestion.length}
                    />
                </div>
            }
            <div className={"d-flex justify-content-center"}>
                {showSummary
                    ? <SurveySummary
                        questionDetail={selectedQuestion}
                        freeTextAnswer={freeTextAnswer}
                        handleRadioClick={handleRadioClick}
                        handleTextAreaChange={handleTextAreaChange}
                        handleMultipleClick={handleMultipleClick}
                        handleUpdateAnswer={handleUpdateAnswer}
                        handleCancelAnswer={handleCancelAnswer}
                        handleSubmitSurvey={handleSubmitSurvey}
                    />
                    : <SurveyForm
                        questionDetail={selectedQuestion[currentIndex]}
                        currentIndex={currentIndex}
                        freeTextAnswer={freeTextAnswer || selectedQuestion[currentIndex]?.answer}
                        handleRadioClick={handleRadioClick}
                        handleTextAreaChange={handleTextAreaChange}
                        handleMultipleClick={handleMultipleClick}
                    />
                }
            </div>
            {showSummary
                ? null
                : <div className="d-flex justify-content-end mt-3 mb-3">
                    <Button
                        variant="primary"
                        type="button"
                        disabled={!gotFeedback && selectedQuestion[currentIndex]?.answer === null}
                        onClick={(e: any) => handleSubmitClick(e)}
                    >
                        Submit Response
                    </Button>
                </div>
            }
        </>
    );
}

export default TakeSurvey
