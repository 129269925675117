import './ModalComponent.css';
import React, { useState, useContext } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import TextComponent from '../Text/TextComponent';
import HeaderComponent from '../Header/HeaderComponent';
import { Headers } from '../../../Utils/Enums';
import ErrorInputComponent from '../ErrorInput/ErrorInputComponent';
import { Form } from 'react-bootstrap';
import { ThemeContext } from "../../../Contexts/ThemeContext";
import "../../../darkcustom.css"

export interface IModalData {
    emailAddress: string;
    firstName: string;
    lastName: string
};

interface IModal {
    label: string;
    customClass?: string;
    title: string;
    text?: string;
    onModalHandler: (data: IModalData) => void;
};

interface IexternalUserData {
    firstName: string;
    lastName: string;
    emailAddress: string
}

const ModalComponent = (props: IModal) => {

    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [emailAddress, setEmailAddress] = useState<string>("");
    const [modal, setModal] = useState(false);
    const { theme } = useContext(ThemeContext)

    const [errorToggel, setErrorToggel] = useState<any>({
        firstName: "",
        lastName: "",
        emailAddress: "",
    });

    const toggle = () => setModal(!modal);

    const validateForm = async () => {
        //eslint-disable-next-line
        let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (firstName.length < 1 || firstName.length === 0) {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    firstName: "First name must be of atleast 1 character"
                }
            })
            return false;
        } else {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    firstName: ""
                }
            })
        }

        if (lastName.length < 1 || lastName.length === 0) {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    lastName: "Last name must be of atleast 1 character"
                }
            })
            return false;
        } else {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    lastName: ""
                }
            })
        }

        if (!emailAddress.match(mailFormat)) {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    emailAddress: "Please enter valid email address"
                }
            })
            return false;
        } else {
            setErrorToggel((errStates: any) => {
                return {
                    ...errStates,
                    emailAddress: ""
                }
            })
        }
        return true;
    }
    const SubmitHandler = async () => {
        if (await validateForm()) {
            let data: IexternalUserData = {
                emailAddress: emailAddress,
                firstName: firstName,
                lastName: lastName
            };
            props.onModalHandler(data);
            setModal(false);
        }
    };

    return <>
        {theme === "light" ? (
            <div>
                <Button
                    type="button"
                    className={`btn btn-secondary border-0 font-weight-600`}
                    data-toggle="modal" data-target="#exampleModal" onClick={toggle}>
                    <div><TextComponent text={props.label} customClassName="text-color-182C34" /></div>
                </Button>
                <Modal isOpen={modal} className={props.customClass} centered>
                    <ModalHeader>
                        <HeaderComponent
                            title={props.title}
                            size={Headers.H2}
                        />
                    </ModalHeader>
                    <ModalBody>

                        <div className="margin-bottom-16">
                            <TextComponent text={props.text} customClassName="text-color-2D5362" />
                        </div>
                        <div>
                            <form id="account" method="get" autoComplete="off">
                                <Form.Group className="mb-3">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Firstname"
                                        onChange={(e) => {
                                            setFirstName(e.target.value);
                                        }}
                                    />
                                    {errorToggel.firstName && <ErrorInputComponent error={errorToggel.firstName} />}
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Lastname"
                                        onChange={(e) => {
                                            setLastName(e.target.value);
                                        }}
                                    />
                                    {errorToggel.lastName && <ErrorInputComponent error={errorToggel.lastName} />}
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control
                                        placeholder="Enter email"
                                        onChange={(e) => {
                                            setEmailAddress(e.target.value);
                                        }}
                                    />
                                    {errorToggel.emailAddress && <ErrorInputComponent error={errorToggel.emailAddress} />}
                                </Form.Group>
                            </form>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary background-26CAC1 font-weight-600" onClick={SubmitHandler}>
                            <TextComponent text={'Add to network'} customClassName="text-color-182C34" />
                        </Button>
                        <Button color="secondary" className="font-weight-600" onClick={() => { setModal(false) }}>
                            <TextComponent text={'Cancel'} customClassName="text-color-182C34" />
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        ) : <div>
            <Button
                type="button"
                className={`btn btn-secondary border-0 font-weight-600`}
                data-toggle="modal" data-target="#exampleModal" onClick={toggle}>
                <div><TextComponent text={props.label} customClassName="buttonText" /></div>
            </Button>
            <Modal isOpen={modal} className={props.customClass} centered>
                <ModalHeader className="modelBox">
                    <HeaderComponent
                        title={props.title}
                        size={Headers.H2}
                    />
                </ModalHeader>
                <ModalBody className="modelBox">

                    <div className="margin-bottom-16">
                        <TextComponent text={props.text} customClassName="text-color-2D5362" />
                    </div>
                    <div>
                        <form id="account" method="get" autoComplete="off">
                            <Form.Group className="mb-3">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Firstname"
                                    className="modelSearchBox"
                                    onChange={(e) => {
                                        setFirstName(e.target.value);
                                    }}
                                />
                                {errorToggel.firstName && <ErrorInputComponent error={errorToggel.firstName} />}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    className="modelSearchBox"
                                    placeholder="Enter Lastname"
                                    onChange={(e) => {
                                        setLastName(e.target.value);
                                    }}
                                />
                                {errorToggel.lastName && <ErrorInputComponent error={errorToggel.lastName} />}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    placeholder="Enter email"
                                    className="modelSearchBox"
                                    onChange={(e) => {
                                        setEmailAddress(e.target.value);
                                    }}
                                />
                                {errorToggel.emailAddress && <ErrorInputComponent error={errorToggel.emailAddress} />}
                            </Form.Group>
                        </form>
                    </div>
                </ModalBody>
                <ModalFooter className="modelBox">
                    <Button color="primary background-26CAC1 font-weight-600" onClick={SubmitHandler}>
                        <TextComponent text={'Add to network'} customClassName="text-color-182C34" />
                    </Button>
                    <Button color="secondary" className="font-weight-600" onClick={() => { setModal(false) }}>
                        <div style={{ color: "#182C34" }}>
                            <TextComponent text={'Cancel'} customClassName="text-color-182C34" />
                        </div>
                    </Button>
                </ModalFooter>
            </Modal>
        </div>}
    </>
}
export default ModalComponent;