import {isTokenExpired,clearLocalStorageAndRedirect, getAuthToken, getQuerySringPreparedUrl} from "../Utils/Utils";
interface ICreateGroup {
    groupName: string;
    description: string;
    organisationId: number;
    maximumUsers: number;
    members: any[];
    groupFacilator: any;
    groupProgram: any
}

interface IUpdateGroup {
    id: number;
    groupName: string;
    description: string;
    organisationId: number;
    maximumUsers: number;
    members: any[];
    groupFacilator: any;
    groupProgram: any
}
class Group {

    async getTotalGroupsByOrganizationId(pageNumber: number, pageSize: number,searchTerm:string) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            let obj = {
                pageNumber:pageNumber,
                pageSize:pageSize,
                searchTerm:searchTerm,
            }
            const response = await fetch(getQuerySringPreparedUrl(`${process.env.REACT_APP_BASE_URL}/Group/OrgnisationGroups`,obj), {
                method: "GET",
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    }

    async getGroupsByGroupId(groupId: string) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Group/OrgnisationGroup/${groupId}`, {
                method: "GET",
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    }

    async deleteGroupsByGroupId(groupId: string) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Group/OrgnisationGroup/${groupId}`, {
                method: 'DELETE',
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    }

    async createGroup(createGroupData: ICreateGroup) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Group/OrgnisationGroup`, {
                method: 'POST',
                body: JSON.stringify(createGroupData),
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    }

    async updateGroup(updateGroupData: IUpdateGroup) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Group/OrganisationGroup`, {
                method: 'PUT',
                body: JSON.stringify(updateGroupData),
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    }
}


export default Group;