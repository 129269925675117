import React, { useEffect, useState, useContext } from "react";
import { TableColumn } from "react-data-table-component";
import FileUploadComponent from "./../../FunctionalComponents/FileUpload/FileUploadComponent";
import BulkUserUpload from "../../../Services/BulkUploadUser.service";
import { createNotification } from "../../../Utils/Toast";
import { logOutIfTokenExpire } from '../../../Utils/Utils';
import { ThemeContext } from "../../../Contexts/ThemeContext";

interface IselectedFiles {
    title: string
}

interface IdataRow {
    title: string;
};

interface Iprops {
    setLoading: (data: boolean) => void;
}

const BulkUploadComponent = (props: Iprops) => {
    const { theme } = useContext(ThemeContext);
    const { setLoading } = props;

    const [files, setFiles] = useState<any>([]);
    const [bulkUploadData, setBulkUploadData] = useState<string>("");
    const [selectedFileName, setSelectedFileName] = useState<IselectedFiles[]>([]);
    const [bulkUploadSuccess, setBulkUploadSuccess] = useState<boolean>(false);
    const [selectedDelemeter, setSelectedDelemeter] = useState<string>("")
    const fileUploadcolumns: TableColumn<IdataRow>[] = [
        {
            name: 'File Name',
            selector: row => row.title,
        },
        {
            name: "Action",
            cell: () => (
                <>
                    {theme === "light" ? (
                        <img alt="Close" src={window.location.origin + "/images/close.svg"} />
                    ) : <img alt="Close" src={window.location.origin + "/images/close-dark.svg"} />}
                </>
            ),
        },
    ];

    useEffect(() => {
        logOutIfTokenExpire();
        const getSelectedFileName = () => {
            let tempFileName: IselectedFiles[] = [];
            tempFileName = files.map((fileName: any) => {
                let title: string = "";
                title = fileName.name;
                return {
                    title
                };
            });
            setSelectedFileName(tempFileName);
        }
        getSelectedFileName();
    }, [files]);

    const bulkFileUpload = async () => {
        setBulkUploadSuccess(false);
        const bulkUpload = new BulkUserUpload();
        let response: any = await bulkUpload.uploadBulkUser(files, selectedDelemeter);
        setLoading(true);
        setBulkUploadData(response.data)
        if (response.status) {
            setLoading(false);
            setBulkUploadSuccess(true);
            setTimeout(() => {
                createNotification("success", response.message);
            }, 1850);
            while (files.length > 0) {
                files.length = 0;
                selectedFileName.length = 0;
            }
        } else {
            setLoading(false);
            createNotification("error", response.message);
        }
    }

    return (
        <div>
            <FileUploadComponent
                setFiles={setFiles}
                files={files}
                columns={fileUploadcolumns}
                data={selectedFileName}
                bulkFileUpload={bulkFileUpload}
                bulkUploadData={bulkUploadData}
                bulkUploadSuccess={bulkUploadSuccess}
                setSelectedDelemeter={setSelectedDelemeter}
            />
        </div>
    );
};
export default BulkUploadComponent;


