import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Button, Form, Spinner } from "react-bootstrap";
import { Headers } from "../../../Utils/Enums";
import { createNotification } from "../../../Utils/Toast";
import Survey from "../../../Services/Survey.service";
import HeaderComponent from "../../FunctionalComponents/Header/HeaderComponent";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Survey.css"
import General from "../../../Services/General.service";
import Lookup from "../../../Services/Lookup.service";
import { logOutIfTokenExpire, isMNFacilitator, isClientAdmin } from '../../../Utils/Utils';
import LoaderComponent from "../../FunctionalComponents/Loader/LoaderComponent";
import { UserContext } from "../../../Contexts/UserContext";
import { ThemeContext } from "../../../Contexts/ThemeContext";
import "../../../darkcustom.css";

function UserSurvey(props: any) {
    const generalService = new General();
    generalService.getIcons();

    let history = useHistory();
    const { theme } = useContext(ThemeContext);
    const userContext = useContext(UserContext);
    const survey = new Survey();
    const lookup = new Lookup();
    const [questions, setQuestions] = useState<any[]>([]);
    const [selectedQuestions, setSelectedQuestions] = useState<any[]>([]);
    const [startDate, setStartDate] = useState<any>(new Date());
    const [additionalQuestionText, setAdditionalQuestionText] = useState<string>("");
    const [programList, setProgramList] = useState<any[]>([]);
    const [userProgramStages, setUserProgramStages] = useState<any[]>([]);
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [editData, setEditData] = useState<any>(props?.location?.state);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [surveyData, setSurveyData] = useState<any>({
        surveyName: "",
        /* selectedGroup: 0, */
        selectedProgram: 0,
        selectedProgramStage: 0
    });

    const [valueUpdated, setValueUpdated] = useState<any>({
        surveyName: false,
        startDate: false,
        additionalQuestion: false,
        selectedProgram: false,
        selectedProgramStage: false
    })

    useEffect(() => {
        logOutIfTokenExpire();
        userContext.dispatch('');
        programsListOptions();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (surveyData.selectedProgram === 0) {
            return;
        } else {
            programStages();
        }
        //eslint-disable-next-line
    }, [surveyData.selectedProgram, editData.editData]);

    useEffect(() => {
        if (editData.editData) {
            if (editData.date) {
                let tempDate = editData.date.split("/");
                let currentDate = new Date(tempDate[2], tempDate[1] - 1, tempDate[0]);
                setStartDate(currentDate);
            }
            setSurveyData({
                surveyName: editData.name,
                selectedProgram: editData.program.id,
                selectedProgramStage: editData.programStage.id
            });
        }
        //eslint-disable-next-line
    }, [editData.editData]);

    const programsListOptions = async () => {
        let result: any = await lookup.getProgramsList();
        if (result.status) {
            let programLists: any[] = result.data.map((value: any) => {
                return {
                    id: value.id,
                    label: value.name
                }
            })
            setProgramList(programLists);
        }
        setIsLoading(false);
    }

    const programStages = async () => {
        let response: any = await lookup.getProgramStages(surveyData.selectedProgram);
        if (response.status) {
            let programStages: any = response.data.map((data: any) => {
                return {
                    id: data.id,
                    name: data.name
                }
            });
            setUserProgramStages(programStages);
        }
    }

    const validateSurveyForm = async () => {
        if (surveyData.surveyName.length === 0) {
            createNotification("error", "Please enter survey name!");
            return false;
        }
        if (surveyData.selectedProgram === 0) {
            createNotification("error", "Please select program!");
            return false;
        }
        if (surveyData.selectedProgramStage === 0) {
            createNotification("error", "Please select program stage!");
            return false;
        }
        if (startDate === 0) {
            createNotification("error", "Please select date!");
            return false;
        }
        return true;
    }

    const isValueUpdated = async () => {
        if (valueUpdated.surveyName || valueUpdated.startDate || valueUpdated.additionalQuestion || valueUpdated.selectedProgram || valueUpdated.selectedProgramStage) {
            return true;
        } else {
            return false;
        }
    }

    const handleSubmitSurvey = async (e: any) => {
        e.preventDefault();
        let response: any;
        if (await validateSurveyForm()) {
            setShowSpinner(true);
            let createSurveyData: any;
            let editSurveyData: any;

            if (editData.editData) {
                let tempDate: any = new Date(startDate);
                let year = tempDate.getFullYear();
                let month = (tempDate.getMonth() + 1).toString().padStart(2, "0");
                let date = tempDate.getDate().toString().padStart(2, "0");
                let editDate = `${year}-${month}-${date}`;
                editSurveyData = {
                    id: editData.id,
                    name: surveyData.surveyName,
                    openDate: editDate,
                    programId: surveyData.selectedProgram,
                    programStageId: surveyData.selectedProgramStage
                }
                if (await isValueUpdated()) {
                    response = await survey.editProgramSurvey(editSurveyData);
                    if (response?.status) {
                        setShowSpinner(false);
                        if (isMNFacilitator()) {
                            history.push("/mnfacilitatordashboard");
                        }
                        else {
                            history.push("/clientadmindashboard");
                        }
                        createNotification("success", response?.message);
                    } else {
                        setShowSpinner(false)
                        createNotification("error", response?.message);
                    }
                } else {
                    setShowSpinner(false)
                    setTimeout(() => {
                        if (isMNFacilitator()) {
                            history.push("/mnfacilitatordashboard");
                        }
                        else {
                            history.push("/clientadmindashboard");
                        }
                    }, 1000);
                    createNotification("info", "You have not updated anything!");
                }
            } else {
                let tempDate: any = new Date(startDate);
                let year = tempDate.getFullYear();
                let month = (tempDate.getMonth() + 1).toString().padStart(2, "0");
                let date = tempDate.getDate().toString().padStart(2, "0");;
                let createDate = `${year}-${month}-${date}`;
                createSurveyData = {
                    name: surveyData.surveyName,
                    openDate: createDate,
                    programId: surveyData.selectedProgram,
                    programStageId: surveyData.selectedProgramStage
                }
                response = await survey.createSurvey(createSurveyData);
                if (response?.status) {
                    setShowSpinner(false);
                    setTimeout(() => {
                        if (isMNFacilitator()) {
                            history.push("/mnfacilitatordashboard");
                        }
                        else {
                            history.push("/clientadmindashboard");
                        }
                    }, 1000);
                    createNotification("success", response?.message);
                } else {
                    setShowSpinner(false);
                    createNotification("error", response?.message);
                }
            }
        }
    }

    const handleRedirection = () => {
        if (isMNFacilitator()) {
            history.push("/mnfacilitatordashboard");
        }
        else if (isClientAdmin()) {
            history.push("/clientadmindashboard");
        }
        else {
            history.push("/facilitordashboard");
        }
    }

    return (
        <>
            <div className="d-flex">
                <div className='d-flex justify-content-between cursor-pointer' onClick={() => handleRedirection()}>
                    {theme === "light" ? (
                        <HeaderComponent
                            title={editData.editData ? "Edit a Survey" : "Create a Survey"}
                            size={Headers.H1}
                            icon={'chevron-left.svg'}
                        />
                    ) : <HeaderComponent
                            customClassName={"imageText"}
                            title={editData.editData ? "Edit a Survey" : "Create a Survey"}
                            size={Headers.H1}
                            icon={'left-chevron-dark.svg'}
                        />}
                </div>
            </div>

            {isLoading ? <LoaderComponent /> : <Form>
                <div className="col-md-6 d-flex flex-column">
                    {theme === "light" ? (
                        <>
                            <Form.Group
                                className="mb-3"
                                controlId="SurveyName"
                            >
                                <HeaderComponent
                                    title={"Survey Name"}
                                    size={Headers.H4}
                                />
                                <div className="date-picker-wrapper mt-3 position-relative">
                                    <Form.Control
                                        type="text"
                                        className="mt-3"
                                        value={surveyData.surveyName || ""}
                                        placeholder="Enter Survey Name"
                                        onChange={(e: any) => {
                                            setSurveyData((states: any) => {
                                                return {
                                                    ...states,
                                                    surveyName: e.target.value
                                                }
                                            });
                                            setValueUpdated((states: any) => {
                                                return {
                                                    ...states,
                                                    surveyName: true
                                                }
                                            });
                                        }}
                                    />
                                </div>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <HeaderComponent
                                    title={"Program Name"}
                                    size={Headers.H4}
                                />
                                <div className="date-picker-wrapper mt-3 position-relative">
                                    <Form.Select value={Number(surveyData.selectedProgram)} onChange={(e: any) => {
                                        setSurveyData((states: any) => {
                                            return {
                                                ...states,
                                                selectedProgram: Number(e.target.value)
                                            }
                                        });
                                        setValueUpdated((states: any) => {
                                            return {
                                                ...states,
                                                selectedProgram: true
                                            }
                                        });
                                    }}>
                                        <option value={0}>{"Select Program"}</option>
                                        {programList?.map((data: any) => <option key={data.id} value={data.id}>{data.label}</option>)}
                                    </Form.Select>
                                </div>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="ProgramStage">
                                <HeaderComponent
                                    title={"Program Stage"}
                                    size={Headers.H4}
                                />
                                <div className="date-picker-wrapper mt-3 position-relative">
                                    <Form.Select value={Number(surveyData.selectedProgramStage)} onChange={(e: any) => {
                                        setSurveyData((states: any) => {
                                            return {
                                                ...states,
                                                selectedProgramStage: Number(e.target.value)
                                            }
                                        });
                                        setValueUpdated((states: any) => {
                                            return {
                                                ...states,
                                                selectedProgramStage: true
                                            }
                                        });
                                    }}>
                                        <option value={0}>{"Select Program Stage"}</option>
                                        {userProgramStages?.map((data: any) => <option key={data.id} value={data.id}>{data.name}</option>)}
                                    </Form.Select>
                                </div>
                            </Form.Group>

                            <Form.Group
                                className="mb-3"
                                controlId="SurveyDate"
                            >
                                <HeaderComponent
                                    title={"Survey Date"}
                                    size={Headers.H4}
                                />
                                <div className="date-picker-wrapper mt-3 position-relative">
                                    <DatePicker
                                        dateFormat="dd/MM/yyyy"
                                        selected={startDate}
                                        className="date-picker-input w-100"
                                        placeholderText='Select Date'
                                        popperPlacement="bottom"
                                        strictParsing
                                        minDate={new Date()}
                                        showDisabledMonthNavigation
                                        shouldCloseOnSelect={false}
                                        closeOnScroll={true}
                                        onChange={(newValue: any) => {
                                            setStartDate(newValue);
                                            setValueUpdated((states: any) => {
                                                return {
                                                    ...states,
                                                    startDate: true
                                                }
                                            });
                                        }}
                                        popperModifiers={[
                                            {
                                                name: "offset",
                                                options: {
                                                    offset: [5, 5],
                                                },
                                            },
                                            {
                                                name: "preventOverflow",
                                                options: {
                                                    rootBoundary: "viewport",
                                                    tether: false,
                                                    altAxis: true,
                                                },
                                            },
                                        ]}
                                    />
                                </div>
                            </Form.Group>
                        </>
                    ) : <>
                            <Form.Group
                                className="mb-3"
                                controlId="SurveyName"
                            >
                                <HeaderComponent
                                    title={"Survey Name"}
                                    size={Headers.H4}
                                />
                                <div className="date-picker-wrapper mt-3 position-relative">
                                    <Form.Control
                                        type="text"
                                        className="mt-3 clientSearchBox"
                                        value={surveyData.surveyName || ""}
                                        placeholder="Enter Survey Name"
                                        onChange={(e: any) => {
                                            setSurveyData((states: any) => {
                                                return {
                                                    ...states,
                                                    surveyName: e.target.value
                                                }
                                            });
                                            setValueUpdated((states: any) => {
                                                return {
                                                    ...states,
                                                    surveyName: true
                                                }
                                            });
                                        }}
                                    />
                                </div>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <HeaderComponent
                                    title={"Program Name"}
                                    size={Headers.H4}
                                />
                                <div className="date-picker-wrapper mt-3 position-relative">
                                    <Form.Select className="clientSearchBox" value={Number(surveyData.selectedProgram)} onChange={(e: any) => {
                                        setSurveyData((states: any) => {
                                            return {
                                                ...states,
                                                selectedProgram: Number(e.target.value)
                                            }
                                        });
                                        setValueUpdated((states: any) => {
                                            return {
                                                ...states,
                                                selectedProgram: true
                                            }
                                        });
                                    }}>
                                        <option value={0}>{"Select Program"}</option>
                                        {programList?.map((data: any) => <option key={data.id} value={data.id}>{data.label}</option>)}
                                    </Form.Select>
                                </div>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="ProgramStage">
                                <HeaderComponent
                                    title={"Program Stage"}
                                    size={Headers.H4}
                                />
                                <div className="date-picker-wrapper mt-3 position-relative">
                                    <Form.Select className="clientSearchBox" value={Number(surveyData.selectedProgramStage)} onChange={(e: any) => {
                                        setSurveyData((states: any) => {
                                            return {
                                                ...states,
                                                selectedProgramStage: Number(e.target.value)
                                            }
                                        });
                                        setValueUpdated((states: any) => {
                                            return {
                                                ...states,
                                                selectedProgramStage: true
                                            }
                                        });
                                    }}>
                                        <option value={0}>{"Select Program Stage"}</option>
                                        {userProgramStages?.map((data: any) => <option key={data.id} value={data.id}>{data.name}</option>)}
                                    </Form.Select>
                                </div>
                            </Form.Group>

                            <Form.Group
                                className="mb-3"
                                controlId="SurveyDate"
                            >
                                <HeaderComponent
                                    title={"Survey Date"}
                                    size={Headers.H4}
                                />
                                <div className="date-picker-wrapper mt-3 position-relative">
                                    <DatePicker
                                        dateFormat="dd/MM/yyyy"
                                        selected={startDate}
                                        className="date-picker-input w-100 surveyDateBox"
                                        placeholderText='Select Date'
                                        popperPlacement="bottom"
                                        strictParsing
                                        minDate={new Date()}
                                        showDisabledMonthNavigation
                                        shouldCloseOnSelect={false}
                                        closeOnScroll={true}
                                        onChange={(newValue: any) => {
                                            setStartDate(newValue);
                                            setValueUpdated((states: any) => {
                                                return {
                                                    ...states,
                                                    startDate: true
                                                }
                                            });
                                        }}
                                        popperModifiers={[
                                            {
                                                name: "offset",
                                                options: {
                                                    offset: [5, 5],
                                                },
                                            },
                                            {
                                                name: "preventOverflow",
                                                options: {
                                                    rootBoundary: "viewport",
                                                    tether: false,
                                                    altAxis: true,
                                                },
                                            },
                                        ]}
                                    />
                                </div>
                            </Form.Group>
                        </>}
                </div>

                <div className="d-flex justify-content-end mb-3 mt-3">
                    {
                        showSpinner ?
                            <Button variant="primary" className="text-secondary" disabled>
                                <span className="mx-2 text-secondary">
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </span>
                                {editData.editData ? "Editing survey..." : "Creating survey..."}
                            </Button>
                            :
                            <Button onClick={(e: any) => handleSubmitSurvey(e)} variant="primary" className="font-weight-600 text-color-182C34">
                                {editData.editData ? "Edit Survey" : "Create Survey"}
                            </Button>
                    }
                    <Button role="button" variant="secondary" onClick={handleRedirection} className="mx-2 font-weight-600 text-color-182C34">
                        Cancel
                    </Button>
                </div>
            </Form>
            }
        </>
    );
}

export default UserSurvey;