import React, { Component, Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import { clearLocalStorage } from '../../Utils/Utils';
import { ThemeContext } from "../../Contexts/ThemeContext"
import "../../darkcustom.css"
export class LoginMenu extends Component {
    static contextType = ThemeContext;
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            userName: null,
        };
    }
    componentDidMount() {
        this.populateState();
    }

    async populateState() {
        const user = JSON.parse(localStorage.getItem("userConfig"));
        const token = localStorage.getItem("API_Token");
        this.setState({
            isAuthenticated: token?.length > 0 ? true : false,
            userName: `${user?.firstName} ${user?.lastName}`,
        });
    }
    handleLogout() {
        clearLocalStorage();
        window.location.href = "/login";
    }

    render() {
        const { isAuthenticated, userName } = this.state;
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
            return this.authenticatedView(userName, profilePath, logoutPath);
        }
    }

    authenticatedView(userName, profilePath, logoutPath) {
        const { theme } = this.context;
        return (<Fragment>
            <div>
                {theme === "light" ? (
                    <NavLink tag={Link} className="text-dark px-0 py-0" onClick={this.handleLogout} to={"/login"}>Logout</NavLink>
                ) :
                    <NavLink tag={Link} className="hamburgerMenuColor text-dark px-0 py-0" onClick={this.handleLogout} to={"/login"}>Logout</NavLink>}
            </div>
        </Fragment>);

    }

    anonymousView(registerPath, loginPath) {

        return (<Fragment>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={"/login"}>Login</NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={"/register"}>Registrer</NavLink>
            </NavItem>
        </Fragment>);
    }
}
