import { IAdminDashboardFilter, IProgramChartResponse } from "../interfaces/AdminDashboard/IAdminDashboardFilter";
import { IScoreChartResponse } from "../interfaces/Charts/IScoreChart";
import Config from "../Utils/Config";
import {isTokenExpired,clearLocalStorageAndRedirect, getAuthToken} from "../Utils/Utils";
import { IModuleChartResponse } from "../interfaces/Charts/IModuleChart";
import { IAdminDashboardPracticeResponse, IPracticeDataRequest } from "../interfaces/AdminDashboard/IAdminDashboardPracticeData";

class AdminDashboard {

   async getDashboardProgramStatus(filters:IAdminDashboardFilter){
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            try{
                const response = await fetch(Config.dashboardProgramStatus,{
                    method:'POST',
                    headers:{
                        "accept": "text/plain",
                        'Content-Type': 'application/json',
                        "Authorization": getAuthToken()
                    },
                    body: JSON.stringify(filters)
                });
                if(!response.ok){
                    return {data:null,message:"Some thing is wrong",status:false}
                }
                else{
                    const result = await response.json();
                    if(result){
                        return result as IProgramChartResponse;
                    }
                    else{
                        return {data:null,message:"Some thing is wrong",status:false}
                    }
                }
            }
            catch(error){
                return {data:null,message:"Some thing is wrong",status:false}
            }
        }
    }
    
    async getScoreChartData(programIds:number[]){
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            try{
                const response = await fetch(Config.scoreChartData,{
                    method:'POST',
                    headers:{
                        "accept": "text/plain",
                        'Content-Type': 'application/json',
                        "Authorization": getAuthToken()
                    },
                    body: JSON.stringify(programIds)
                });
                if(!response.ok){
                    return {data:null,message:"Some thing is wrong",status:false}
                }
                else{
                    const result = await response.json();
                    if(result){
                        return result as IScoreChartResponse;
                    }
                    else{
                        return {data:null,message:"Some thing is wrong",status:false}
                    }
                }
            }
            catch(error){
                return {data:null,message:"Some thing is wrong",status:false}
            }
        }
    }
    
    async getModuleChartData(programIds:number[]){
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            try{
                const response = await fetch(Config.moduleChartData,{
                    method:'POST',
                    headers:{
                        "accept": "text/plain",
                        'Content-Type': 'application/json',
                        "Authorization": getAuthToken()
                    },
                    body: JSON.stringify(programIds)
                });
                if(!response.ok){
                    return {data:null,message:"Some thing is wrong",status:false}
                }
                else{
                    const result = await response.json();
                    if(result){
                        return result as IModuleChartResponse;
                    }
                    else{
                        return {data:null,message:"Some thing is wrong",status:false}
                    }
                }
            }
            catch(error){
                return {data:null,message:"Some thing is wrong",status:false}
            }
        }
    } 

    async getPracticeData(request:IPracticeDataRequest){
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            try{
                const response = await fetch(Config.practiceData,{
                    method:'POST',
                    headers:{
                        "accept": "text/plain",
                        'Content-Type': 'application/json',
                        "Authorization": getAuthToken()
                    },
                    body: JSON.stringify(request)
                });
                if(!response.ok){
                    return {data:null,message:"Some thing is wrong",status:false}
                }
                else{
                    const result = await response.json();
                    if(result){
                        return result as IAdminDashboardPracticeResponse;
                    }
                    else{
                        return {data:null,message:"Some thing is wrong",status:false}
                    }
                }
            }
            catch(error){
                return {data:null,message:"Some thing is wrong",status:false}
            }
        }
    }
}

export default AdminDashboard