import React, { useContext } from "react";
import { ExpanderComponentProps } from "react-data-table-component";
import TextComponent from "../Text/TextComponent";
import "./UserPracticeComponentsStyle/PracticeTableComponent.css";

interface IpracticesData {
    practiceId: number
    title: string;
    summary: string;
    otherCurrent: number;
    otherCompleted: number;
    rating: number;
    isAdded: boolean;
    practiceStatus: string
}

interface Iprops {
    practiceData: any[];
    actionOnPractice: (id: number) => Promise<void>;
    currentPractice: (id: number) => Promise<void>;
    getClickedPractice?: (practiceId: number) => Promise<void>
}


interface Props extends ExpanderComponentProps<IpracticesData> {
    // currently, props that extend ExpanderComponentProps must be set to optional.
    url?: string;
}
const PracticeLibraryExpandableRowComponent: (theme: string, url: string, props: Iprops) =>
    React.FC<Props> = (theme, url, props) => ({ data }) => {

        const {
            actionOnPractice,
        } = props;
        const handleActionClick = (row: any) => {
            actionOnPractice(row.practiceId);
        }

        return (
            <>
                <div className="p-3 background-FAFAFA" >
                    <div ><b>Description</b></div>
                    <div>{data.title}</div>
                    <div className="mt-3"><b>Others-Current</b></div>
                    <div>{data.otherCurrent}</div>
                    <div className="mt-3"><b>Others-Completed</b></div>
                    <div>{data.otherCompleted}</div>
                    <div className="mt-3"><b>Rating</b></div>
                    <div>
                        <div className="numbers">
                            {data.rating === null ? "NA" : data.rating}
                        </div>
                    </div>
                    <div className="mt-3"><b>Action</b></div>
                    <div>{(
                        <>
                            {url === "/practicelibrary" && (
                                <div className="d-flex align-items-center justify-content-between w-100">
                                    <div onClick={() => handleActionClick(data)}>
                                        <img
                                            alt="favourite"
                                            src={theme === "light" ? (
                                                data.isAdded
                                                    ? window.location.origin + "/images/favourite-filled.svg"
                                                    : "/images/favourite-empty.svg"
                                            ) : data.isAdded
                                                    ? window.location.origin + "/images/favourite-filled.svg"
                                                    : "/images/favourite-empty-dark.svg"}
                                        />
                                    </div>
                                    <div onClick={() => handleActionClick(data)}>
                                        {/* {theme === "light" ? ( */}
                                        <TextComponent text="Add" customClassName="font-weight-600 added-text text-black" />
                                        {/* ) : <TextComponent text="Add" customClassName=" added-text text-#fff" />} */}
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                    </div>
                </div>
            </>
        );
    };


export default PracticeLibraryExpandableRowComponent;