import React from 'react';
import stylesCircleImage from './CircleImageComponent.module.css';

interface ICircleImageComponent {
    image: string;
    size: string;
    screenType?: string;
}

const CircleImageComponent = (props: ICircleImageComponent) => {

    let fileName = props.image;
    let checkFile: string = "";
    let isIncluded: boolean = false;
    let formats = ["jpg", "jpeg", "png", "svg"]
    if (props.image && props.image.length > 0) {
        checkFile = fileName.substring(fileName.length - 3);
        isIncluded = formats.includes(checkFile);
    }
    return <>
        {isIncluded && <div className="d-flex flex-column">
            {props.image !== 'Ted.png' && <img
                src={`${process.env.REACT_APP_BASE_URL}${props.image}`}
                alt=""
                className={`rounded-circle align-self-center ${stylesCircleImage[props.size]} ${props.screenType === 'profile' && stylesCircleImage['profileImageConfig']}`} />}
            {props.image === 'Ted.png' && <img
                src={window.location.origin + "/images/" + props.image}
                alt="person-image"
                className={`rounded-circle align-self-center ${stylesCircleImage[props.size]} ${props.screenType === 'profile' && stylesCircleImage['profileImageConfig']}`} />}
        </div>}
        {!isIncluded && <div className="d-flex flex-column">
            <div className={`d-flex justify-content-center align-self-center background-1E9F98  rounded-circle ${stylesCircleImage[props.size]} ${stylesCircleImage['profileImageConfig']}`}>
                <img src={window.location.origin + '/images/person.svg'} alt="person"/>
            </div>
        </div>}
    </>
}
export default CircleImageComponent;