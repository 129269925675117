import React, { useState, useContext } from 'react';
import './VideoPlayerComponent.css';
import '../../../custom.css'
import ReactPlayer from 'react-player';
import { ThemeContext } from "../../../Contexts/ThemeContext";

interface IVideoPlayerComponent {
    source?: string;
    local?: boolean;
    playing: boolean;
    volume?: number;
    muted?: boolean;
    onEndednHandler?: () => void;
};
const VideoPlayerComponent = (props: IVideoPlayerComponent) => {
    //eslint-disable-next-line
    const [playing, setPlaying] = useState(props.playing)
    const { theme } = useContext(ThemeContext)

    const OnEnded = () => {
        if (props.onEndednHandler) {
            //eslint-disable-next-line
            const onEndednHandler = props.onEndednHandler();
        }
    }
    return <>
        {!props.local && <ReactPlayer
            className='react-player margin-bottom-24 border-radius-4 video-player-config'
            url={props.source}
            playing={props.playing}
            volume={props.volume ? props.volume : 1}
            muted={props.muted}
            controls={true}
            allow={'autoplay; encrypted-media'}
            config={{
                file: {
                    attributes: {
                        controlsList: 'nodownload'
                    }
                }
            }}
        />}
        {<ReactPlayer
            className='react-player margin-bottom-24 border-radius-4 video-player-config'
            playing={props.playing}
            allow='autoplay; encrypted-media'
            url={props.source}
            volume={props.volume ? props.volume : 1}
            muted={props.muted}
            controls={true}
            config={{
                file: {
                    attributes: {
                        controlsList: 'nodownload'
                    }
                }
            }}
            onEnded={() => OnEnded()}
        />
        }
    </>
}
export default VideoPlayerComponent;