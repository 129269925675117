import React, { useEffect, useState, useContext } from "react";
import { TableColumn } from "react-data-table-component";
import { Link } from "react-router-dom";
import TableComponent from "../Table/TableComponent";
import TextComponent from "../Text/TextComponent";
import "./LibraryTableComponent.css";
import QuoteLibraryTableExpandableRowComponent from "./QuoteLibraryTableExpandableRowComponent";
import ResourceLibraryTableExpandableRowComponent from "./ResourceLibraryTableExpandableRowComponent";
import { isMobileOnly } from "react-device-detect";
import { ThemeContext } from "../../../Contexts/ThemeContext";
import "../../../darkcustom.css"

export interface IquotesData {
    quoteText: string;
    source: string;
    dateAdded: string;
    isFavourite: any;
    author: string;
}

export interface IresourceData {
    id: number;
    moduleId: number;
    name: string;
    description: string;
    link: string;
    fileName: string;
    originalFileName: string;
    type: string;
    isExternalResource: boolean;
    resourceContentId: number;
    resourceContent: string;
    isAdded: boolean;
    moduleName: string;
    dateAdded: string;
}

export interface Iprops {
    quoteTableData: any[];
    resourceTableData: any[];
    likeQuote?: (id: any, type: string, text: string) => Promise<void>;
    addResource?: (id: number) => Promise<void>;
}

function LibraryTableComponent(props: Iprops) {
    let { theme } = useContext(ThemeContext);
    const { quoteTableData, resourceTableData, likeQuote, addResource } = props;

    const [url, setUrl] = useState<string>("");
    const hideBreakPoint = 400; //This is screenSize in pixel below which columns will be hidded
    useEffect(() => {
        const getUrl: string = window.location.pathname;
        setUrl(getUrl);
        //eslint-disable-next-line
    }, []);

    const quotesTableColumns: TableColumn<IquotesData>[] = [
        {
            name: "Quote",
            selector: (row) => row.quoteText,
            sortable: true,
            width: isMobileOnly ? "auto" : "780px",
            wrap: true,
        },
        {
            name: "Author",
            selector: (row) => row.author,
            sortable: true,
            hide: hideBreakPoint,
            wrap: true,
        },
        {
            name: "Favourites",
            width: isMobileOnly ? "auto" : "180px",
            selector: (row) => row.isFavourite,
            hide: hideBreakPoint,
            cell: (row) => (
                <>
                    {url === "/myquotes" ? (
                        <>
                            {" "}
                            <div className="mx-2">
                                <img
                                    alt="favourite"
                                    src={window.location.origin + "/images/favourite-filled.svg"}
                                    width={"16px"}
                                    height={"16px"}
                                />
                            </div>
                            <div>
                                {theme === "light" ? (
                                    <TextComponent
                                        text="Added"
                                        customClassName="font-weight-600 added-text text-black"
                                    />
                                ) : (
                                        <TextComponent
                                            text="Added"
                                            customClassName="added-text textColor"
                                        />
                                    )}
                            </div>{" "}
                        </>
                    ) : (
                            <>
                                <div className="mx-2" onClick={() => addFavouritesQuote(row)}>
                                    <img
                                        alt="favourite"
                                        src={
                                            theme === "light" ? (
                                                row.isFavourite
                                                    ? window.location.origin + "/images/favourite-filled.svg"
                                                    : "/images/favourite-empty.svg"
                                            ) : row.isFavourite
                                                    ? window.location.origin + "/images/favourite-filled.svg"
                                                    : "/images/favourite-empty-dark.svg"
                                        }
                                        width={"16px"}
                                        height={"16px"}
                                    />
                                </div>
                                <div
                                    className="border-bottom border-dark"
                                    onClick={() => addFavouritesQuote(row)}
                                >
                                    {theme === "light" ? (<TextComponent
                                        text="Add"
                                        customClassName="font-weight-600 added-text text-black"
                                    />
                                    ) : <TextComponent
                                            text="Add"
                                            customClassName="font-weight-600 added-text practiceLibraryTextColor"
                                        />}

                                </div>
                            </>
                        )}
                </>
            ),
        },
    ];

    const resourceTableColumns: TableColumn<IresourceData>[] = [
        {
            name: "Resource",
            selector: (row) => row.name,
            sortable: true,
            width: isMobileOnly ? "auto" : "50%",
        },
        {
            name: "Module",
            selector: (row) => row.moduleName,
            hide: hideBreakPoint,
            width: isMobileOnly ? "auto" : "20%",
        },
        {
            name: "Link",
            selector: (row) => row.type,
            hide: hideBreakPoint,
            cell: (row) =>
                row.type === null ? (
                    "NA"
                ) :
                    row.type === "Webpage" ? (
                        !row.isExternalResource ? (
                            theme === "light" ? (
                                <>
                                    <Link
                                        className="cursor-pointer text-decoration-none text-black"
                                        to={`/content/${row.resourceContentId}`}
                                        target="_blank"
                                    >
                                        View More
                                    </Link>
                                </>) : (<>
                                    <Link style={{ color: "white" }}
                                        className="cursor-pointer text-decoration-none"
                                        to={`/content/${row.resourceContentId}`}
                                        target="_blank"
                                    >
                                        View More
                                    </Link>
                                </>)
                        ) : (
                                theme === "light" ? (<Link
                                    className="cursor-pointer text-decoration-none text-black"
                                    to={{ pathname: `${row.link}` }}
                                    target="_blank"
                                >
                                    View More
                                </Link>) : (
                                        <Link style={{ color: "white" }}
                                            className="cursor-pointer text-decoration-none"
                                            to={{ pathname: `${row.link}` }}
                                            target="_blank"
                                        >
                                            View More
                                </Link>
                                    )
                            )
                    ) : row.type === "Image" ? (
                        theme === "light" ? (
                            <Link
                                className="cursor-pointer text-decoration-none text-black"
                                to={`api/file?filename=${row.fileName}`}
                                target="_blank"
                            >
                                View More
                        </Link>
                        ) : (<Link style={{ color: "white" }}
                            className="cursor-pointer text-decoration-none"
                            to={`api/file?filename=${row.fileName}`}
                            target="_blank"
                        >
                            View More
                        </Link>)
                    ) : row.type === "Video" ? (
                        theme === "light" ? (<Link
                            className="cursor-pointer text-decoration-none text-black"
                            to={`api/file?filename=${row.fileName}`}
                            target="_blank"
                        >
                            View More
                       </Link>) : (
                                <Link style={{ color: "white" }}
                                    className="cursor-pointer text-decoration-none"
                                    to={`api/file?filename=${row.fileName}`}
                                    target="_blank"
                                >
                                    + View More
                       </Link>
                            )
                    ) : (
                                    row.type === "Audio" && (
                                        theme === "light" ? (
                                            <Link
                                                className="cursor-pointer text-decoration-none text-black"
                                                to={`api/file?filename=${row.fileName}`}
                                                target="_blank"
                                            >
                                                View More
                                        </Link>
                                        ) : (
                                                <Link style={{ color: "white" }}
                                                    className="cursor-pointer text-decoration-none"
                                                    to={`api/file?filename=${row.fileName}`}
                                                    target="_blank"
                                                >
                                                    View More
                                        </Link>
                                            )
                                    )
                                ),

            width: isMobileOnly ? "auto" : "15%",

        },
        {
            name: "Reading List",
            selector: (row) => row.isAdded,
            hide: hideBreakPoint,
            width: isMobileOnly ? "auto" : "20%",
            cell: (row) => (
                <>
                    {url === "/myresources" ? (
                        <>
                            {" "}
                            <div className="mx-2">
                                <img
                                    alt="favourite"
                                    src={window.location.origin + "/images/favourite-filled.svg"}
                                    width={"16px"}
                                    height={"16px"}
                                />
                            </div>
                            <div>
                                {theme === "light" ? (
                                    <TextComponent
                                        text="Added"
                                        customClassName="font-weight-600 added-text text-black"
                                    />
                                ) : (
                                        <TextComponent
                                            text="Added"
                                            customClassName="font-weight-600 added-text textColor"
                                        />
                                    )}
                            </div>{" "}
                        </>
                    ) : (
                            <>
                                <div className="mx-2" onClick={() => addFavouriteResource(row)}>
                                    <img
                                        alt="favourite"
                                        src={
                                            theme === "light" ? (
                                                row.isAdded
                                                    ? window.location.origin + "/images/favourite-filled.svg"
                                                    : "/images/favourite-empty.svg"
                                            ) : row.isAdded
                                                    ? window.location.origin + "/images/favourite-filled.svg"
                                                    : "/images/favourite-empty-dark.svg"
                                        }
                                        width={"16px"}
                                        height={"16px"}
                                    />
                                </div>
                                <div onClick={() => addFavouriteResource(row)}>
                                    {theme === "light" ? (
                                        <TextComponent
                                            text="Add"
                                            customClassName="font-weight-600 added-text text-black"
                                        />
                                    ) : (
                                            <TextComponent
                                                text="Add"
                                                customClassName="font-weight-600 added-text "
                                            />
                                        )}
                                </div>
                            </>
                        )}
                </>
            ),
        },
    ];

    const addFavouritesQuote = (row: any) => {
        if (likeQuote) {
            if (row.source === "Concept") {
                return likeQuote(row.id.split("$")[0], "Concept", row.quoteText);
            } else {
                return likeQuote(row.id.split("$")[0], "notConcept", row.quoteText);
            }
        }
    };

    const addFavouriteResource = (row: any) => {
        if (addResource) {
            return addResource(Number(row.id));
        }
    };

    const resourceLibraryTableExpandableRowComponent = ResourceLibraryTableExpandableRowComponent(
        url,
        theme,
        addResource,
        props
    );
    const quoteLibraryTableExpandableRowComponent = QuoteLibraryTableExpandableRowComponent(
        theme,
        url,
        likeQuote,
        props
    );
    return (
        <>
            <div>
                <TableComponent
                    data={
                        url === "/quoteslibrary" || url === "/myquotes"
                            ? quoteTableData
                            : resourceTableData
                    }
                    columns={
                        url === "/quoteslibrary" || url === "/myquotes"
                            ? quotesTableColumns
                            : resourceTableColumns
                    }
                    tableTitle={""}
                    totalRows={5}
                    rowsPerpage={10}
                    handlePageChange={<></>}
                    handleRowsPerPage={<></>}
                    pageAction={<></>}
                    isPagination={false}
                    expandableRows={isMobileOnly}
                    expandableRowsComponent={
                        url === "/quoteslibrary" || url === "/myquotes"
                            ? quoteLibraryTableExpandableRowComponent
                            : resourceLibraryTableExpandableRowComponent
                    }
                    expandableRowsComponentProps={{ url: url }}
                />
            </div>
        </>
    );
}

export default LibraryTableComponent;
