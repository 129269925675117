import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import HeaderComponent from '../../FunctionalComponents/Header/HeaderComponent';
import CardComponent from '../../FunctionalComponents/Card/CardComponent';
import './ProgramOverviewComponent.css';
import SubmitButtonComponent from '../../FunctionalComponents/SubmitButton/SubmitButtonComponent';
import { Headers } from '../../../Utils/Enums';
import { Link, useHistory } from 'react-router-dom';
import TextComponent from '../../FunctionalComponents/Text/TextComponent';
import BootstrapModal from '../../FunctionalComponents/BootstrapModal/BootstrapModal';
import TermNCondition from '../../../Services/Agreement.service';
import EULA from '../../../Utils/EULA';
import { createNotification } from '../../../Utils/Toast';
import {hasLowerCase, hasNumber, hasUpperCase, logOutIfTokenExpire, specialRgx} from '../../../Utils/Utils';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import ErrorInputComponent from '../../FunctionalComponents/ErrorInput/ErrorInputComponent';
import { UserContext } from '../../../Contexts/UserContext';

const ProgramOverview = (props: any) => {
    const agrrement = new TermNCondition();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [isUserEULAAccepted, setIsUserEULAAccepted] = useState<boolean>(false);
    const [showChangePwdModal, setShowChangePwdModal] = useState<boolean>(false);
    const userContext = useContext(UserContext);
    const [passwordData, setPasswordData] = useState<any>({
        password: "",
        confirmPassword: ""
    });
    const [errorToggel, setErrorToggel] = useState<any>({
        notMatched: "",
        password: "",
        confirmPassword: "",
    });
    const [showSpinner, setShowSpinner] = useState<boolean>(false);

    useLayoutEffect(() => {
        isEULAAccepted();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        logOutIfTokenExpire();
        userContext.dispatch('');          
        if (isUserEULAAccepted) {
            setShowModal(false);
        } else {
            if(props?.location !== undefined && !props?.location?.state?.isEmailConfirmed){
                    setShowChangePwdModal(true);
                }
                else{
                    if (props?.location?.state?.isIntroCompleted || props?.location?.state?.isAgreementAccepted) {
                        setShowModal(false);
                    } else {
                        if (Object.keys(props).length === 0) {
                            setShowModal(false);
                        } else {
                            setShowModal(true);
                    }
                    }
            }                     
        }
        //eslint-disable-next-line
    }, []);


    const isEULAAccepted = async () => {
        let response: any = await agrrement.isEULAAccepted();
        if (response.status) {
            setIsUserEULAAccepted(response.data);
            setShowModal(false);
        } else {
            setIsUserEULAAccepted(response.data);
            setShowModal(true);
        }
    }

    const handleModalClose = () => {
        setShowModal(false);
    }

    const handleChangePwdClose = () => {
        setShowChangePwdModal(false);
    }

    const acceptAgreement = async () => {
        let response: any = await agrrement.acceptEULA();
        if (response.status) {
            if (response.data) {
                setIsUserEULAAccepted(true);
                createNotification("success", "You have accepted End User License Agreement");
            } else {
                setIsUserEULAAccepted(false);
                createNotification("error", "Something went wrong!");
            }
        }
    }

    const validateForm = async () => {
        if (passwordData.password.length === 0) {
            setErrorToggel((states: any) => {
                return {
                    ...states,
                    password: "Please enter password"
                }
            });
            return false;
        } else {
            setErrorToggel((states: any) => {
                return {
                    ...states,
                    password: ""
                }
            });
        }

        if (passwordData.confirmPassword.length === 0) {
            setErrorToggel((states: any) => {
                return {
                    ...states,
                    confirmPassword: "Please enter confirm password"
                }
            });
            return false;
        } else {
            setErrorToggel((states: any) => {
                return {
                    ...states,
                    confirmPassword: ""
                }
            });
        }

        if (passwordData.password !== passwordData.confirmPassword) {
            setErrorToggel((states: any) => {
                return {
                    ...states,
                    notMatched: "Password and confirm password do not match"
                }
            });
            return false;
        } else {
            setErrorToggel((states: any) => {
                return {
                    ...states,
                    notMatched: ""
                }
            });
        }

        if(passwordData.password.length < 6){
            setErrorToggel((states: any) => {
                return {
                    ...states,
                    password: "Password length must be six character at least."
                }
            });
            return false;   
        }
        else {
            setErrorToggel((states: any) => {
                return {
                    ...states,
                    password: ""
                }
            });
        }

        if(!hasLowerCase(passwordData.password) || !hasUpperCase(passwordData.password)
        || !hasNumber(passwordData.password) || !specialRgx(passwordData.password)){
            setErrorToggel((states: any) => {
                return {
                    ...states,
                    password: "Use a mix of letters (uppercase and lowercase), numbers and symbols."
                }
            });
            return false;
        }
        else {
            setErrorToggel((states: any) => {
                return {
                    ...states,
                    password: ""
                }
            });
        }

        return true;
    }

    const handleChangePassword = async () => {
        if (await validateForm()) {
            setShowSpinner(true);
            let response = await agrrement.changeAndConfirmedEmail(passwordData.confirmPassword);
            if(response && response.status){
                setShowChangePwdModal(false);
                setShowSpinner(false);
                createNotification("success", response.message);
                if(isUserEULAAccepted){
                    window.location.href = "/dashboard";
                }
            }
        }
    }

    return <>
        {
            showChangePwdModal ? <Modal
            show={showChangePwdModal}
            onHide={handleChangePwdClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
            restoreFocus
            scrollable
            animation
            backdrop={'static'}
        >
            <Modal.Header>
                <Modal.Title className="header-text">Change Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="mb-5 title-font">
                <ul>
                    <li>Use at least 6 characters.</li>
                    <li>Use a mix of letters (uppercase and lowercase), numbers and symbols.</li>
                    <li>Don't use your name, username or account name.</li>
                    <li>Avoid predictable passwords such as "password", "12345" or "talkdesk"</li>
                </ul>
            </div>
                <Form>
                <Form.Group className="mb-3">
                    <Form.Label>Password</Form.Label>                    
                    <Form.Control
                        type="password"
                        placeholder="Password"
                        className="input-font"
                        autoFocus
                        onChange={(e: any) => setPasswordData((states: any) => {
                            return {
                                    ...states,
                                    password: e.target.value
                                }
                        })}
                        />
                    {errorToggel.password && <ErrorInputComponent error={errorToggel.password} />}
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Confirm Password</Form.Label>                    
                    <Form.Control
                        type="password"
                        placeholder="Confirm Password"
                        className="input-font"
                        onChange={(e: any) => setPasswordData((states: any) => {
                            return {
                                    ...states,
                                    confirmPassword: e.target.value
                                }
                        })}
                                />
                    {errorToggel.confirmPassword && <ErrorInputComponent error={errorToggel.confirmPassword} />}
                    {errorToggel.notMatched && <ErrorInputComponent error={errorToggel.notMatched} />}
                </Form.Group>                    
                </Form>
            </Modal.Body>
            <Modal.Footer>
               {
                showSpinner ? <Button variant="primary" className="text-secondary" disabled>
                                <span className="mx-2 text-secondary">
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                </span>
                                Changing Password... </Button> :
                             <Button role="button" variant="primary" className="font-weight-600 text-color-182C34" onClick={() => handleChangePassword()}>
                                Change Password
                             </Button> 
               } 
            </Modal.Footer>
            </Modal> : 
            (props?.location?.state?.isIntroCompleted || props?.location?.state?.isAgreementAccepted)
                ? null
                : <BootstrapModal
                    modalTitle="Mindnavigator End User License Agreement"
                    modalBody={EULA}
                    show={showModal}
                    handleClose={handleModalClose}
                    successButtonText={isUserEULAAccepted ? "Close" : "I Accept"}
                    isAgreementAccepted={isUserEULAAccepted}
                    acceptAgreement={acceptAgreement}
                />
        }
        {props?.location?.state?.isDashboard && <div className='d-flex justify-content-between'>
            <HeaderComponent
                title={"Navigating for success!"}
                size={Headers.H1}
            />
        </div>}
        <div className="row">
            <div className="col margin-bottom-8">
                <HeaderComponent
                    title={"OVERVIEW"}
                    size={Headers.H3}
                />
            </div>
        </div>
        <div className="margin-bottom-24">
            <TextComponent text={'Think, Engage and Do are the 3 essential steps in the process for navigating your mind for success.'} />
        </div>

        <div className="row row-cols flex-config">
            <CardComponent
                title={'Think'}
                body={'Gain access to new insights through self-enquiry and reflections'}
                image={'Overview_think.jpg'} />
            <CardComponent
                title={'Engage'}
                body={'Develop deeper connections and expand your perspectives by exploring insights with others'}
                image={'Overview_engage.jpg'} />
            <CardComponent
                title={'Do'}
                body={'Create new, meaningful experiences by experimenting with practices during the flow of your day.'}
                image={'Overview_do.jpg'} />
        </div>

        {props?.location?.pathname === '/programoverview' && !showChangePwdModal && isUserEULAAccepted && <div className="d-flex justify-content-end row button-config">
            <div className="col-md-4">
             <Link to={`/thinkintro`}>
                    <SubmitButtonComponent label={"Let's go"} customClassName={'btn-primary-custom'} />
                </Link>
            </div>
        </div>}
    </>
}

export default ProgramOverview;
