import React from 'react';
import './ContentShellComponent.css';

interface IContentShellComponent {
    customClass?: string;
    children: any;
}
const ContentShellComponent = (props: IContentShellComponent) => {
    return <>
        <div className={props.customClass}>{props.children}</div>
    </>
}
export default ContentShellComponent;