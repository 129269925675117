import React, { useEffect, useState, useContext } from "react";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { TableColumn } from "react-data-table-component";
import { Button } from "reactstrap";
import { Headers } from "../../../Utils/Enums";
import HeaderComponent from "../../FunctionalComponents/Header/HeaderComponent";
import TabsComponent from "../../FunctionalComponents/Tabs/TabsComponent";
import TableComponent from "../../FunctionalComponents/Table/TableComponent";
import User from "../../../Services/UserDetails.service";
import Lookup from "../../../Services/Lookup.service";
import CommonModalComponent from "../../FunctionalComponents/CommonModal/CommonModalComponent";
import TextComponent from "../../FunctionalComponents/Text/TextComponent";
import OrganisationalUserComponent from "../OrganisationUser/OrganisationUserComponent";
import RegisterOrganisationUser from "../../../Services/RegisterOrganisationUser.service"
import LoaderComponent from "../../FunctionalComponents/Loader/LoaderComponent";
import { createNotification } from "../../../Utils/Toast";
import { CLIENT_ADMIN_MAPPING, FACILITOR_MAPPING, SUB_CLIENT_ADMIN_MAPPING, CLIENT_ADMIN_DROPDOWN_MAPPING, SUB_CLIENT_ADMIN_DROPDOWN_MAPPING, FACILITOR_DROPDOWN_MAPPING, CLIENT_ADMIN_LABEL, SUB_CLIENT_ADMIN_LABEL, CLIENT_ADMIN, SUB_CLIENT_ADMIN, FACILITOR, FACILITOR_LABEL, PARTICIPANT, PARTICIPANT_LABEL, MNFACILITATOR } from "../../../Utils/Roles";
import GroupComponent from "./GroupsComponent";
import BulkUploadComponent from "./BulkUploadComponent";
import ProgramSurveyComponent from "../ProgramSurveyComponent/ProgramSurveyComponent";
import MeetingComponent from "./MeetingComponent";
import "./ClientAdminDashboard.module.css";
import ReportingComponent from "./ReportingComponent";
import General from '../../../Services/General.service';
import { useHistory } from 'react-router-dom';
import { OverlayTrigger, Tooltip, Form, Col } from "react-bootstrap";
import { logOutIfTokenExpire, getUserId, isMNFacilitator, getUserOrgnization, getUserRoles, isClientAdmin } from '../../../Utils/Utils';
import { IIdName } from "../../../interfaces/User/IUserOrgnisations";
import DashboardComponent from "./DashboardComponent";
import ChartsComponent from "./ChartsComponent";
import { UserContext } from "../../../Contexts/UserContext";
import { ThemeContext } from "../../../Contexts/ThemeContext";
import "../../../darkcustom.css";
import { error } from "console";

interface IUserInformation {
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    country: string;
    userId: string;
    groupId: number;
    groupName: string;
    roleId: string;
    userRoles: any;
    isEmailConfirmed: boolean
}

interface IUserColumnInformation {
    firstName: string;
    lastName: string;
    email: string;
    countryName: string;
    userId: string;
    groupId: number;
    groupName: string;
    role: string;
    roleId: string;
    userRoles: any;
    isEmailConfirmed: boolean;
    canProgramCompleted: boolean;
}

interface IRoleDetail {
    userRole: string;
    id: number;
};

const MNFacilitatorTabs = [
    {
        id: 17,
        title: "Dashboard",
        isActive: false,
    },
    {
        id: 18,
        title: "Surveys",
        isActive: false,
    },
    {
        id: 19,
        title: "Charts",
        isActive: false,
    },
    {
        id: 20,
        title: "Groups",
        isActive: false,
    },
    {
        id: 21,
        title: "Meeting",
        isActive: false,
    },
    {
        id: 22,
        title: "Users",
        isActive: true,
    },
    {
        id: 23,
        title: "Reporting",
        isActive: false,
    },
    {
        id: 24,
        title: "Bulk Upload",
        isActive: false,
    }
];

const clientAdminDashboardTabs = [
    {
        id: 17,
        title: "Dashboard",
        isActive: false,
    },
    {
        id: 18,
        title: "Surveys",
        isActive: false,
    },
    {
        id: 19,
        title: "Charts",
        isActive: false,
    },
    {
        id: 20,
        title: "Groups",
        isActive: false,
    },
    {
        id: 21,
        title: "Meeting",
        isActive: false,
    },
    {
        id: 22,
        title: "Users",
        isActive: true,
    },
    {
        id: 23,
        title: "Reporting",
        isActive: false,
    },
    {
        id: 24,
        title: "Bulk Upload",
        isActive: false,
    }
];

const facilitatorDashboardTabs = [
    {
        id: 18,
        title: "Surveys",
        isActive: false,
    },
    {
        id: 19,
        title: "Groups",
        isActive: false,
    },
    {
        id: 20,
        title: "Meeting",
        isActive: false,
    },
    {
        id: 22,
        title: "Reporting",
        isActive: false,
    },
];

const animatedComponents = makeAnimated();

const ClientAdminDashboardComponent = () => {
    let myhistory = useHistory();
    const generalService = new General();
    generalService.getIcons();
    const user = new User();
    const lookup = new Lookup();
    const registerOrganisationUserService = new RegisterOrganisationUser();
    const [userDetails, setUserDetails] = useState<any>({});
    const [editUserDetails, setEditUserDetails] = useState<any>({
        firstName: "",
        lastName: "",
        userEmail: "",
        countryId: 0,
        countryName: "",
        stateName: "",
        stateId: 0,
        userId: 0,
        groupId: 0,
        groupName: ""
    });
    const [selectedTab, setSelectedTab] = useState((isMNFacilitator() || isClientAdmin()) ? "Dashboard" : "Surveys");
    const [tableData, setTableData] = useState<IUserInformation[]>([]);
    const [modalState, setModalState] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerpage, setRowsPerpage] = useState(10);
    const [groups, setGroups] = useState([]);
    const [editData, setEditData] = useState<boolean>(false);
    const [groupName, setGroupName] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [role, setRole] = useState<string>("");
    const [options, setOptions] = useState<any[]>([]);
    const [userId, setUserId] = useState<string>("");
    const [userOrgs, setUserOrgs] = useState<IIdName[]>([]);
    const [reloadSurvey, setReloadSurvey] = useState<boolean>(false);
    const [selectedGroup, setselectedGroup] = useState<number>(0);
    const [searchStr, setSearchStr] = useState<string>("");
    const userContext = useContext(UserContext);
    const { theme } = useContext(ThemeContext);

    useEffect(() => {
        userContext.dispatch('');
    }, [])
    useEffect(() => {
        logOutIfTokenExpire();
        getUserByOrganisationId();
        //eslint-disable-next-line
    }, [selectedGroup]);

    useEffect(() => {
        if (isMNFacilitator()) {
            getUserOrganisations();
        }
    }, []);

    useEffect(() => {
        logOutIfTokenExpire();
        getUserData();
        //eslint-disable-next-line
    }, [role, currentPage, rowsPerpage]);
    const getUserData = () => {
        ;
        let userDetailsLocalStorage = localStorage.getItem("userConfig");
        if (userDetailsLocalStorage) {
            let parsedUserDetails = JSON.parse(userDetailsLocalStorage);

            setUserDetails(parsedUserDetails);
            setUserId(parsedUserDetails?.id);

            let roles: any = parsedUserDetails?.roles.split(",");

            if (roles.length === 1) {
                setRole(roles[0]);
                if (roles[0].includes(CLIENT_ADMIN_LABEL)) {
                    setRole(CLIENT_ADMIN);
                    setOptions(CLIENT_ADMIN_DROPDOWN_MAPPING);
                } else if (roles[0].includes(FACILITOR_LABEL)) {
                    setRole(FACILITOR);
                    setOptions(FACILITOR_DROPDOWN_MAPPING);
                } else if (roles[0].includes(SUB_CLIENT_ADMIN_LABEL)) {
                    setRole(SUB_CLIENT_ADMIN);
                    setOptions(SUB_CLIENT_ADMIN_DROPDOWN_MAPPING);
                } else if (roles[0].includes(MNFACILITATOR)) {
                    setRole(MNFACILITATOR);
                    setOptions(CLIENT_ADMIN_DROPDOWN_MAPPING);
                }
                else {
                    setRole(PARTICIPANT);
                }
            } else {
                if (roles.includes(CLIENT_ADMIN_LABEL) || (roles.includes(CLIENT_ADMIN_LABEL) && role.includes(PARTICIPANT_LABEL))) {
                    setRole(CLIENT_ADMIN);
                    setOptions(CLIENT_ADMIN_DROPDOWN_MAPPING);
                } else if (roles.includes(FACILITOR_LABEL) || (role.includes(FACILITOR_LABEL) && role.includes(PARTICIPANT_LABEL))) {
                    setRole(FACILITOR);
                    setOptions(FACILITOR_DROPDOWN_MAPPING);
                } else if (roles.includes(SUB_CLIENT_ADMIN_LABEL) || (role.includes(SUB_CLIENT_ADMIN_LABEL) && role.includes(PARTICIPANT_LABEL))) {
                    setRole(SUB_CLIENT_ADMIN);
                    setOptions(SUB_CLIENT_ADMIN_DROPDOWN_MAPPING);
                } else {
                    setRole(PARTICIPANT);
                }
            }

            if (!roles?.includes(CLIENT_ADMIN_LABEL) && !roles?.includes(FACILITOR_LABEL) && !roles?.includes(MNFACILITATOR)) {
                return myhistory.push('/pagenotfound');
            } else {
                if (roles?.includes(CLIENT_ADMIN_LABEL) || roles?.includes(MNFACILITATOR)) {
                    getGroups();
                    getUserByOrganisationId();
                } else if (roles.includes(FACILITOR_LABEL)) {
                    return myhistory.push('/facilitordashboard');
                }
            }
        }

    }

    const getGroups = async () => {
        let groupResult: any = await lookup.getGroups();
        if (groupResult) {
            setGroups(groupResult);
        }
    };

    const getUserByOrganisationId = async () => {
        setLoading(true);
        let result: any = await user.getUsersByOrganizationId(
            currentPage,
            rowsPerpage,
            searchStr,
            selectedGroup > 0 ? selectedGroup.toString() : ''
        );

        if (result.ok) {
            setLoading(false);
            setTotalPages(result.data.pagination.totalItems);
            let userInfoData: IUserInformation[] = result.data.items.map((value: any) => {
                let userRoles: any[] = [];
                let roleId: number[] = [];
                let groupName: any[] = [];
                //eslint-disable-next-line
                value.userRoles.map((roleDetail: IRoleDetail) => {
                    if (role === CLIENT_ADMIN) {
                        userRoles.push({
                            value: roleDetail.id,
                            label: roleDetail.userRole,
                            isFixed: !CLIENT_ADMIN_MAPPING.includes(role)
                        });
                    } else if (role === SUB_CLIENT_ADMIN) {
                        userRoles.push({
                            value: roleDetail.id,
                            label: roleDetail.userRole,
                            isFixed: !SUB_CLIENT_ADMIN_MAPPING.includes(role)
                        });
                    } else if (role === FACILITOR) {
                        userRoles.push({
                            value: roleDetail.id,
                            label: roleDetail.userRole,
                            isFixed: !FACILITOR_MAPPING.includes(role)
                        });
                    }
                    else if (role == MNFACILITATOR) {
                        userRoles.push({
                            value: roleDetail.id,
                            label: roleDetail.userRole,
                            isFixed: !CLIENT_ADMIN_MAPPING.includes(role)
                        });
                    }
                    roleId.push(roleDetail.id)
                });

                groupName.push(value.userGroup.name);

                return {
                    userId: value.id,
                    firstName: value.firstName,
                    lastName: value.lastName,
                    email: value.email,
                    countryName: value.country.name,
                    countryId: value.country.id,
                    stateName: value.state.name,
                    stateId: value.state.id,
                    groupName: value.userGroup.name,
                    groupId: value.userGroup.id,
                    isEmailConfirmed: value.isEmailConfirmed,
                    canProgramCompleted: value.canProgramCompleted,
                    userRoles,
                    roleId
                };
            });
            setGroupName(groupName);
            setTableData(userInfoData);
        }
    }

    const handlePageChange = (e: any): void => {
        setCurrentPage(e);
    };

    const handleRowsPerPage = (e: any): void => {
        setRowsPerpage(e);
    };

    const customStyles = {
        container: () => ({
            width: 200,
            zIndex: 10,
        }),
        multiValue: (base: any, state: any) => {
            return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
        },
        multiValueLabel: (base: any, state: any) => {
            return state.data.isFixed
                ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
                : base;
        },
        multiValueRemove: (base: any, state: any) => {
            return state.data.isFixed ? { ...base, display: "none" } : base;
        },
        menu: (base: any) => ({ ...base, zIndex: 9999 }),
        menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    };

    const columns: TableColumn<IUserColumnInformation>[] = [
        {
            name: "First Name",
            width: "130px",
            selector: (row) => row.firstName,
            sortable: true,
        },
        {
            name: "Last Name",
            width: "130px",
            selector: (row) => row.lastName,
            sortable: true,
        },
        {
            name: "Email",
            selector: (row) => row.email,
        },
        {
            name: "Group",
            width: "130px",
            selector: (row) => row.groupName,
        },
        {
            name: "Selected Role",
            cell: (row) => (
                <>
                    <Select
                        className="mutltiBox"
                        options={options}
                        isMulti={true}
                        isClearable={options.some(role => role.isFixed)}
                        styles={customStyles}
                        components={animatedComponents}
                        isDisabled={role === FACILITOR}
                        value={row.userRoles}
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'}
                        onChange={async (e: any, selectedOption: any) => { handleRoleChange(e, selectedOption, row) }}
                    />
                </>
            ),
            omit: role === FACILITOR,
        },
        {
            name: "Country",
            width: "130px",
            selector: (row) => row.countryName,
            cell: (row) => (
                <div className="text">
                    {row.countryName}
                </div>
            )
        },
        {
            name: "Action",
            width: "230px",
            cell: (row) => (
                <>
                    <OverlayTrigger
                        placement={"top"}
                        overlay={
                            <Tooltip id={`tooltip-right`}>
                                Edit user
                            </Tooltip>
                        }
                    >
                        {theme === "light" ? (
                            <img alt="edit" onClick={() => handleEditClick(row)} src={window.location.origin + "/images/edit.svg"} />
                        ) : <img alt="edit" onClick={() => handleEditClick(row)} src={window.location.origin + "/images/edit-dark.svg"} />}
                    </OverlayTrigger>

                    <OverlayTrigger
                        placement={"top"}
                        overlay={
                            <Tooltip id={`tooltip-right`}>
                                Delete user
                            </Tooltip>
                        }
                    >
                        {theme === "light" ? (
                            <img alt="close" onClick={() => handleDeleteClick(row)} src={window.location.origin + "/images/close.svg"} />
                        ) : <img alt="close" onClick={() => handleDeleteClick(row)} src={window.location.origin + "/images/close-dark.svg"} />}
                    </OverlayTrigger>

                    {
                        row.isEmailConfirmed
                            ?
                            <OverlayTrigger
                                placement={"top"}
                                overlay={
                                    <Tooltip id={`tooltip-right`} >
                                        Email confirmed
                                    </Tooltip>
                                }
                            >
                                <img alt="email_received" className="mx-1" height={"25px"} width={"25px"} src={window.location.origin + "/images/email-received.svg"} />
                            </OverlayTrigger>

                            :

                            <OverlayTrigger
                                placement={"top"}
                                overlay={
                                    <Tooltip id={`tooltip-right`}>
                                        Email not confirmed
                                    </Tooltip>
                                }
                            >
                                <img alt="email_not_received" className="mx-1" height={"25px"} width={"25px"} onClick={() => handleSendEmailClick(row)} src={window.location.origin + "/images/email-not-received.svg"} />
                            </OverlayTrigger>

                    }
                    {row.canProgramCompleted ? (
                        <Button color="secondary" className="font-weight-600" onClick={() => handleCompleteProgram(row)}>
                            {theme === "light" ? (
                                <TextComponent text={"Complete"} customClassName="text-color-182C34" />
                            ) : <TextComponent text={"Complete"} customClassName="buttonText" />}
                        </Button>
                    ) : null}
                </>
            ),
            omit: role === FACILITOR,
        },
    ];

    const handleRoleChange = async (e: any, selectedOption: any, row: any) => {
        let roleIds: string[] = [];
        if (selectedOption.removedValue) {
            let remRole: any;
            remRole = row.userRoles.filter((item: any) =>
                item.value !== selectedOption.removedValue.value
            );
            remRole.map((data: any) => {
                return roleIds.push(data.value);
            })

        } else {
            roleIds.push(selectedOption.option.value);
            row.userRoles.map((item: any) => {
                return roleIds.push(item.value);
            });
        }
        let editData: any = {
            firstName: row.firstName,
            lastName: row.lastName,
            email: row.email,
            userId: row.userId,
            groupId: row.groupId,
            country: { id: row.countryId },
            state: { id: row.stateId },
            roleIds: roleIds,
            licenseTypeIds: [1]
        }
        setLoading(true);
        let response: any = await registerOrganisationUserService.updateOrganisationUser(editData);
        if (response.status) {
            setLoading(false);
            createNotification("success", response.message)
            getUserByOrganisationId();
        } else {
            setLoading(false);
            createNotification("error", response.message)
        }
    }

    const handleEditClick = (row: any) => {
        setModalState(true)
        setEditData(true);
        setEditUserDetails({
            firstName: row.firstName,
            lastName: row.lastName,
            userEmail: row.email,
            countryId: row.countryId,
            countryName: row.countryName,
            stateName: row.stateName,
            stateId: row.stateId,
            userId: row.userId,
            groupId: row.groupId,
            groupName: row.groupName,
            roles: row.roleId
        });
    }

    const handleDeleteClick = async (row: any) => {
        let response: any = await registerOrganisationUserService.deleteOrganisationUser(row.userId);
        if (response.status) {
            createNotification("success", response.message);
            getUserByOrganisationId();
        } else {
            createNotification("error", response.message);
        }
    }

    const handleCompleteProgram = async (row: any) => {
        let response: any = await registerOrganisationUserService.completedProgram(row.userId);
        if (response.status) {
            createNotification("success", response.message);
            getUserByOrganisationId();
        } else {
            createNotification("error", response.message);
        }
    }

    const handleSendEmailClick = async (row: any) => {
        let response: any = await registerOrganisationUserService.sendEmailNotification(row.userId);
        if (response.status) {
            createNotification("success", response.message)
        } else {
            createNotification("error", response.message)
        }
    }

    const TabsClickHandler = (tab: string) => {
        if (tab === "Meeting") {
            getGroups();
        }
        setSelectedTab(tab);
    };

    const getUserOrganisations = async () => {
        const userId = getUserId();
        const result = await registerOrganisationUserService.getUserOrganisations(userId);
        if (result !== undefined) {
            if (result.status && result.data !== null) {
                let orgList: IIdName[] = result.data.map((x) => {
                    return {
                        Id: x.organisationId,
                        Name: x.organisationName
                    };
                });
                setUserOrgs(orgList);
            }
        }
    }

    const handleOrgChange = async (value: string) => {
        const response = await registerOrganisationUserService.updateUserSelectedOrganisation(Number(value))
        if (response !== undefined) {
            if (response?.status && response.data !== null) {
                localStorage.setItem("userConfig", JSON.stringify(response.data));
                localStorage.setItem("API_Token", response.data.token);
                setSelectedTab("Dashboard");
                setReloadSurvey(true);
                getUserData();
            }
        }
    }

    const handleUsersSearch = (event: any) => {
        setSearchStr(event.target.value.toString());
        if (event.keyCode === 13) {
            getUserByOrganisationId();
        }
    }

    return (
        <>
            <div className="loader w-100 d-flex justify-content-center">
                {loading && <LoaderComponent />}
            </div>
            <div>
                <div className="d-flex justify-content-between">
                    <div>
                        <HeaderComponent title={role === CLIENT_ADMIN ? "Client Admin Dashboard" : role == MNFACILITATOR ? "MN Facilitator Dashboard" :
                            "Facilitator Dashboard"} size={Headers.H1} />
                    </div>
                    {role === MNFACILITATOR && userOrgs.length > 0 &&
                        <div className="px-1">
                            <Form.Group as={Col} xs={12} md={4} lg={2} controlId="formGridGroup" className="mt-1 w-100">
                                <Form.Select onChange={(e: any) => {
                                    handleOrgChange(e.target.value);
                                }}>
                                    {userOrgs.map((data: IIdName) => (<option selected={getUserOrgnization() == data.Name} key={data.Id} value={data.Id}>{data.Name}</option>))}
                                </Form.Select>
                            </Form.Group>
                        </div>
                    }
                </div>
                <div className="margin-bottom-24">
                    <div className="custom-tab-overflow">
                        {role === CLIENT_ADMIN &&
                            <TabsComponent
                                tabs={clientAdminDashboardTabs}
                                customClass={"tabItemConfigBig"}
                                onTabSelectedHandler={TabsClickHandler}
                                myTab={selectedTab}
                            />
                        }
                        {role == MNFACILITATOR &&
                            <TabsComponent
                                tabs={MNFacilitatorTabs}
                                customClass={"tabItemConfigBig"}
                                onTabSelectedHandler={TabsClickHandler}
                                myTab={selectedTab}
                            />
                        }
                        {role === FACILITOR &&
                            <TabsComponent
                                tabs={facilitatorDashboardTabs}
                                customClass={"tabItemConfigBig"}
                                onTabSelectedHandler={TabsClickHandler}
                                myTab={selectedTab}
                            />
                        }
                    </div>
                </div>
                {
                    ((role === CLIENT_ADMIN || MNFACILITATOR) && selectedTab === "Dashboard") && (
                        <div style={{ position: "relative" }}>
                            <DashboardComponent />
                        </div>
                    )
                }
                {
                    ((role === CLIENT_ADMIN || MNFACILITATOR) && selectedTab === "Charts") && (
                        <div style={{ position: "relative" }}>
                            <ChartsComponent />
                        </div>
                    )
                }

                {selectedTab === "Surveys" && (
                    <div style={{ position: "relative" }}>
                        <ProgramSurveyComponent
                            role={role}
                            groups={groups}
                            userId={userId}
                            setLoading={setLoading}
                            reloadSurvey={reloadSurvey}
                            setReloadSurvey={setReloadSurvey}
                        />
                    </div>
                )}
                {(role === CLIENT_ADMIN || role === MNFACILITATOR) && selectedTab === "Users" && (
                    <div style={{ position: "relative" }}>
                        <TableComponent
                            data={tableData}
                            columns={columns}
                            tableTitle={"Users List"}
                            totalRows={totalPages}
                            rowsPerpage={rowsPerpage}
                            handlePageChange={(e: any) => handlePageChange(e)}
                            handleRowsPerPage={(e: any) => handleRowsPerPage(e)}
                            isPagination={true}
                            pageAction={
                                role !== FACILITOR ?
                                    <div className="d-flex">
                                        {theme === "light" ? (
                                            <>
                                                <div className="px-1">
                                                    <Form.Group as={Col} xs={12} md={4} lg={2} controlId="formGridGroup" className="mt-1 w-100">
                                                        <Form.Control type="text" placeholder="Email or name" onKeyUp={handleUsersSearch} />
                                                    </Form.Group>
                                                </div>
                                                <div className="px-1">
                                                    <Form.Group as={Col} xs={12} md={4} lg={2} controlId="formGridGroup" className="mt-1 w-100">
                                                        <Form.Select value={selectedGroup}
                                                            onChange={(e: any) => {
                                                                setselectedGroup(Number(e.target.value));
                                                            }}
                                                        >
                                                            <option value="0">{"Select Group"}</option>
                                                            {groups?.map((group: any) => <option key={group.id} value={group.id}>{group.name}</option>)}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </div>
                                            </>
                                        ) : <>
                                            <div className="px-1">
                                                <Form.Group as={Col} xs={12} md={4} lg={2} controlId="formGridGroup" className="mt-1 w-100">
                                                    <Form.Control className="clientSearchBox" type="text" placeholder="Email or name" onKeyUp={handleUsersSearch} />
                                                </Form.Group>
                                            </div>
                                            <div className="px-1">
                                                <Form.Group as={Col} xs={12} md={4} lg={2} controlId="formGridGroup" className="mt-1 w-100">
                                                    <Form.Select className="clientSearchBox" value={selectedGroup}
                                                        onChange={(e: any) => {
                                                            setselectedGroup(Number(e.target.value));
                                                        }}
                                                    >
                                                        <option value="0">{"Select Group"}</option>
                                                        {groups?.map((group: any) => <option key={group.id} value={group.id}>{group.name}</option>)}
                                                    </Form.Select>
                                                </Form.Group>
                                            </div>
                                        </>
                                        }
                                        <div className="px-1">
                                            <Button color="secondary" className="font-weight-600" onClick={() => {
                                                getUserByOrganisationId();
                                            }}>
                                                {theme === "light" ? (
                                                    <TextComponent text={"Search"} customClassName="text-color-182C34" />
                                                ) : <TextComponent text={"Search"} customClassName="buttonText" />}
                                            </Button>
                                        </div>
                                        <div className="px-1">
                                            <CommonModalComponent
                                                label={"Add user"}
                                                title={editData ? "Edit User" : "Add user"}
                                                onModalHandler={() => console.log("TEST")}
                                                setModalState={setModalState}
                                                modalState={modalState}
                                                modalBody={<OrganisationalUserComponent
                                                    userDetails={userDetails}
                                                    editUserDetails={editUserDetails}
                                                    editData={editData}
                                                    setEditData={setEditData}
                                                    getUserData={getUserData}
                                                    setModalState={setModalState}
                                                />}
                                                modalFooter={
                                                    <Button
                                                        color="secondary"
                                                        className="font-weight-600"
                                                        onClick={() => {
                                                            setModalState(false);
                                                            setEditData(false)
                                                        }}
                                                    >
                                                        {theme === "light" ? (
                                                            <TextComponent text={"Close"} customClassName="text-color-182C34" />
                                                        ) : <TextComponent text={"Close"} customClassName="buttonText" />}
                                                    </Button>
                                                }
                                            /></div></div> : null}
                        />
                    </div>
                )}
                {(role === CLIENT_ADMIN || role === MNFACILITATOR) && selectedTab === "Bulk Upload" && (
                    <div>
                        <BulkUploadComponent
                            setLoading={setLoading}
                        />
                    </div>
                )}
                {selectedTab === "Groups" && (
                    <div style={{ position: "relative" }}>
                        <GroupComponent
                            setLoading={setLoading}
                            role={role}
                        />
                    </div>
                )}
                {selectedTab === "Meeting" && (
                    <div style={{ position: "relative" }}>
                        <MeetingComponent
                            setLoading={setLoading}
                            userDetails={userDetails}
                            groups={groups}
                            userRole={role}
                        />
                    </div>
                )}
                {selectedTab === "Reporting" && (
                    <div style={{ position: "relative" }}>
                        <ReportingComponent
                        />
                    </div>
                )}
            </div>
        </>
    );
};
export default ClientAdminDashboardComponent;
