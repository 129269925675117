import React, { useContext } from "react";
import General from "../../../Services/General.service";
import "./TEDJournalEntries.css";
import constant from "../../../Utils/Config";
import { ThemeContext } from "../../../Contexts/ThemeContext";
import "../../../darkcustom.css"

interface Iprops {
    setStartDate: any;
    setEndDate: any;
    handleFilter: any;
}

function TEDJournalFilterCompopnent(props: Iprops) {

    const generalService = new General();
    generalService.getIcons();

    const { handleFilter } = props;
    const { theme } = useContext(ThemeContext);


    const handleSelectCategory = (value: string) => {
        handleFilter(Number(value));
    }

    return (
        <>
            <div className="d-flex flex-column">
                <p className="filter-text">Filters</p>
                <div className="d-flex col-sm-12 col-lg-4 col-xl-2">
                    {theme === "light" ? (
                        <select
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e: any) => handleSelectCategory(e.target.value)}
                        >
                            {constant.logBookFilter.map((data: any) => <option key={data.id} value={data.id}>{data.name}</option>)
                            }
                        </select>
                    ) : <select
                        className="form-select searchBox"
                        aria-label="Default select example"
                        onChange={(e: any) => handleSelectCategory(e.target.value)}
                    >
                            {constant.logBookFilter.map((data: any) => <option key={data.id} value={data.id}>{data.name}</option>)
                            }
                        </select>}
                </div>
            </div>
        </>
    );
}

export default TEDJournalFilterCompopnent;