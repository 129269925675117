import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import General from "../../../Services/General.service";
import Scores from "../../../Services/Scores.service";
import { Headers } from "../../../Utils/Enums";
import HeaderComponent from "../../FunctionalComponents/Header/HeaderComponent";
import TEDModalComponent from "../../FunctionalComponents/TEDModal/TEDModalComponent";
import PersonalScoreComponent from "../../FunctionalComponents/UserScoreComponents/PersonalSocreComponent";
import ProviderScoreComponent from "../../FunctionalComponents/UserScoreComponents/ProviderScoreComponent";
import { logOutIfTokenExpire } from '../../../Utils/Utils';
import { UserContext } from "../../../Contexts/UserContext";
import { ThemeContext } from "../../../Contexts/ThemeContext";


interface IscoreTabel {
    applicationScore: string | null;
    learningScore: string | null;
    moduleName: string;
    programStage: string;
}

interface IproviderScoreData {
    moduleName: string;
    programStage: string;
    scorer: any[];
}
function UserScoreComponent(props: any) {
    const generalService = new General();
    generalService.getIcons();
    const userScore = new Scores();
    let history = useHistory();
    let redirection: any = props?.location?.state;
    const [personalScoreData, setPersonalScoreData] = useState<IscoreTabel[]>([]);
    const [providerScoreData, setProviderScoreData] = useState<IproviderScoreData[]>([]);
    const userContext = useContext(UserContext);
    const { theme } = useContext(ThemeContext);

    useEffect(() => {
        let userDetailsLocalStorage = localStorage.getItem("userConfig");
        logOutIfTokenExpire();
        userContext.dispatch('');
        getPersonalScore();
        getProviderScoreData();
        //eslint-disable-next-line
    }, []);

    const getPersonalScore = async () => {
        let response: any = await userScore.getPersonalScore();
        if (response.status) {
            let tableData: IscoreTabel[] = response.data.moduleScores.map((data: any) => {
                return {
                    applicationScore: data.applicationScore,
                    learningScore: data.learningScore,
                    moduleName: data.moduleName,
                    programStage: data.programStage,
                }
            })
            setPersonalScoreData(tableData);
        } else {
            setPersonalScoreData([]);
        }
    }

    const getProviderScoreData = async () => {
        //get the score of the provider
        let response: any = await userScore.getProviderScore();
        if (response.status) {
            let providerScoreData: IproviderScoreData[] = response.data.providerScores.map((data: any) => {
                return {
                    moduleName: data.moduleName,
                    stage: data.programStage,
                    scorer: [...data.scorer]
                }
            });
            setProviderScoreData(providerScoreData)
        } else {
            setProviderScoreData([]);
        }
    }

    const handleRedirection = () => {
        if (redirection === undefined) {
            history.push("/dashboard");
        } else {
            window.history.back();
        }
    }

    return (
        <>
            {theme === "light" ? (
                <div className="d-flex align-items-center">
                    <img alt="redirection-back" onClick={() => handleRedirection()}
                        className="cursor-pointer" src={window.location.origin + "/images/chevron-left.svg"} />
                    <span onClick={() => handleRedirection()} className="cursor-pointer">
                        <HeaderComponent
                            title="My Scores"
                            size={Headers.H2}
                        />
                    </span>
                </div>
            ) : <div className="d-flex align-items-center">
                    <img alt="redirection-back" onClick={() => handleRedirection()}
                        className="cursor-pointer"
                        src={window.location.origin + "/images/left-chevron-dark.svg"} />
                    <span onClick={() => handleRedirection()} className="cursor-pointer">
                        <div style={{ marginLeft: "15px" }}>
                            <HeaderComponent
                                title="My Scores"
                                size={Headers.H2}
                            />
                        </div>
                    </span>
                </div>}

            <div>
                <PersonalScoreComponent
                    personalScoreData={personalScoreData}
                    personalScore={true}
                />
            </div>
            <div>
                <ProviderScoreComponent
                    providerScoreData={providerScoreData}
                    personalScore={false}
                />
            </div>
            <div>
                <TEDModalComponent />
            </div>
        </>
    );
}

export default UserScoreComponent;