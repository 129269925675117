import React, { useContext } from "react";
import { Card } from "react-bootstrap";
import { Headers } from "../../../Utils/Enums";
import HeaderComponent from "../Header/HeaderComponent";
import "./NotificationComponent.css";
import { ThemeContext } from "../../../Contexts/ThemeContext";

interface Iprops {
    notificationData: any[];
    handleDeleteNotification: any;
    handleReadNotifClick: any;
}

function NotificationComponent(props: Iprops) {

    const { theme } = useContext(ThemeContext);
    const { notificationData, handleDeleteNotification, handleReadNotifClick } = props;

    return (
        <>
            {theme === "light" ? (
                <>
                    {notificationData.length > 0
                        ? <div> {notificationData.map((data: any) => {
                            return <div className="d-flex flex-column w-100 font-style" key={data.id}>
                                <Card className="mb-3" >
                                    <Card.Header>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span>
                                                <img
                                                    src={
                                                        data.read
                                                            ? window.location.origin + '/images/Read-notification.svg'
                                                            : window.location.origin + '/images/Unread-notification.svg'}
                                                    alt="mail"
                                                    width={"18px"}
                                                    height={"18px"}
                                                />
                                            </span>
                                            <span className="header-time">{new Date(data.createdAt).toLocaleDateString('en-GB', { hour: '2-digit', minute: '2-digit' })}</span>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <Card.Title>
                                            <div className="d-flex flex-row justify-content-between align-items-start">
                                                <div>
                                                    <HeaderComponent
                                                        title={data.title}
                                                        size={Headers.H3}
                                                    />
                                                </div>
                                            </div>
                                        </Card.Title>
                                        <Card.Text className="notification-content mt-3 w-75" onClick={() => handleReadNotifClick(data.id)}>
                                            {data.text}
                                            {(data.link !== undefined || data.link !== null) && data.link.length > 0 && <a href={data.link}>Click here</a>}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        })}
                        </div>
                        : <div className="d-flex justify-content-center w-100 align-items-center h-100 flex-column">
                            <div className="d-flex justify-content-center w-100">
                                <img
                                    src={window.location.origin + '/images/no-notif.svg'}
                                    alt="No Notifications"
                                    width={"60px"}
                                    height={"60px"}
                                />
                            </div>
                            <div className="no-notifications-header d-flex justify-content-center mt-3 w-100">
                                No notifications yet
                            </div>
                            <div className="no-notifications-text d-flex justify-content-center mt-2 w-100">
                                Stay tuned! Notifications about your activity will show up here.
                            </div>
                        </div>
                    }
                </>
            ) : <>
                {notificationData.length > 0
                    ? <div> {notificationData.map((data: any) => {
                        return <div className="d-flex flex-column w-100 font-style" key={data.id}>
                            <Card style={{ background: "#28373E", color: "#fff" }} className="mb-3" >
                                <Card.Header>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <span>
                                            <img
                                                src={
                                                    data.read
                                                        ? window.location.origin + '/images/Read-notification.svg'
                                                        : window.location.origin + '/images/Unread-notification.svg'}
                                                alt="mail"
                                                width={"18px"}
                                                height={"18px"}
                                            />
                                        </span>
                                        <span className="header-time">{new Date(data.createdAt).toLocaleDateString('en-GB', { hour: '2-digit', minute: '2-digit' })}</span>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Title>
                                        <div className="d-flex flex-row justify-content-between align-items-start">
                                            <div>
                                                <HeaderComponent
                                                    title={data.title}
                                                    size={Headers.H3}
                                                />
                                            </div>
                                        </div>
                                    </Card.Title>
                                    <Card.Text className="notification-content mt-3 w-75" onClick={() => handleReadNotifClick(data.id)}>
                                        {data.text}
                                        {(data.link !== undefined || data.link !== null) && data.link.length > 0 && <a href={data.link}>Click here</a>}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    })}
                    </div>
                    : <div className="d-flex justify-content-center w-100 align-items-center h-100 flex-column">
                        <div className="d-flex justify-content-center w-100">
                            <img
                                src={window.location.origin + '/images/no-notif.svg'}
                                alt="No Notifications"
                                width={"60px"}
                                height={"60px"}
                            />
                        </div>
                        <div className="no-notifications-header d-flex justify-content-center mt-3 w-100">
                            No notifications yet
                        </div>
                        <div className="no-notifications-text d-flex justify-content-center mt-2 w-100">
                            Stay tuned! Notifications about your activity will show up here.
                        </div>
                    </div>
                }
            </>}
        </>
    );
}

export default NotificationComponent;