import React, { useState } from 'react';
import { Errors } from '../../../Utils/Enums';
import ChatMessageShellComponent from '../ChatMessageShell/ChatMessageShellComponent';
import ChatShellComponent from '../ChatShell/ChatShellComponent';
import InputGroupComponent from '../InputGroup/InputGroupComponent';
import TextComponent from '../Text/TextComponent';
import generalChatAnswersStyles from './GeneralChatAnswersComponent.module.css'
import SinglePractice from '../../../Services/SinglePractice.service';
import { isDesktop, isMobileOnly } from 'react-device-detect';
import {IUserPracticeResponse} from '../../../interfaces/GeneralChatAnswers/IUserPracticeResponse';

export interface IChatAnswer {
    practiceQuestionId: number;
    userResponse: string;
    isLeft: boolean;
    answerId: number;
    image?: string;
}

interface IGeneralChatAnswersComponent {
    practiceQuestionId: number;
    guId: string;
}

const GeneralChatAnswersComponent = (props: IGeneralChatAnswersComponent) => {
    const singlePractice = new SinglePractice();

    const [ok, setOK] = useState('loading');
    const [messageCounter, setMessageCounter] = useState(0);
    const [answer, setAnswer] = useState<IChatAnswer>();

    //eslint-disable-next-line
    const disableElement = (elName: string) => {
        let elToDisable = document.querySelectorAll(elName);
        if (elToDisable) elToDisable.forEach((x: any) => {
            x.style.pointerEvents = "none";
            x.classList.remove('cursor-pointer');
        })
    }

    /* Set dynamic height of inner window */
    const setinnerWindowHeight = (itemHeight: number | undefined) => {
        const innerWindow = document.getElementById('inner-window');
        let currentHeight = innerWindow?.offsetWidth;
        if (innerWindow && itemHeight && currentHeight) {
            if (currentHeight > 534)
                innerWindow.style.height = currentHeight + itemHeight * 16 + "px";
            setTimeout(function () {
                innerWindow.scrollIntoView({ behavior: "smooth", block: "end" });
            }, 2000);
        }
    }

    /* Crete free text node */
    const setTedFormNode = (className1: string) => {
        const tedFormElement = document.querySelector(className1);
        if (tedFormElement) {
            let newTedForm: Node = tedFormElement.cloneNode(true);
            let innerWindow: any;
            innerWindow = document.getElementById('inner-window');
            innerWindow.appendChild(newTedForm);
        }

    }

    /* Adding free text answer */
    const sendDataHandler = async (data: string) => {
        let tempAnswerItem: IChatAnswer = {
            isLeft: true,
            userResponse: data,
            practiceQuestionId: props.practiceQuestionId,
            answerId: messageCounter

        };
        let userResponse : IUserPracticeResponse = {
            PracticeQuestionId : props.practiceQuestionId,
            Guid : props.guId,
            UserResponse:data
        }
        if (data.length > 0) {
            let sentPracticeAnswer = await singlePractice.practiceQuestionResponse(userResponse);
            if (!sentPracticeAnswer.ok) setOK('failed');
        }
        const getUserDetails = async () => {
            let userDetails = localStorage.getItem("userConfig");
            if (userDetails) {
                let parsedUserDetails = JSON.parse(userDetails);
                let userPicture = parsedUserDetails.profile;
                tempAnswerItem.image = userPicture;
            }
        }

        getUserDetails();
        //eslint-disable-next-line
        let mindNavigatorStorage = localStorage.getItem("popoverSearchOpen");
        let userImage = localStorage.getItem("ProfileImg");
        tempAnswerItem.isLeft = true;
        tempAnswerItem.userResponse = data;
        tempAnswerItem.image = `${process.env.REACT_APP_BASE_URL}${userImage}`;
        setAnswer(tempAnswerItem);
        setTimeout(function () {
            if (tempAnswerItem) {

                setTedFormNode('.chatFormAnswer');
                var clonnedElement = document.querySelectorAll(".chatFormAnswer:last-child")[0];
                clonnedElement.classList.remove('d-none');
                clonnedElement.id = "chatFormAnswer" + messageCounter;
                let itemHeight = calculateFreeTextHight(data);
                let questionEl = document.getElementById("chatFormQuestion" + messageCounter);
                let elAnswer = document.getElementById("chatFormAnswer" + String(messageCounter));
                if (elAnswer) {
                    questionEl?.after(elAnswer)
                    let chileEl: any = elAnswer.children[0];// as HTMLElement;
                    if (chileEl) chileEl.style.height = itemHeight * 16 + 'px';
                };
                setinnerWindowHeight(itemHeight);
                setinnerWindowHeight(itemHeight);
                setMessageCounter(messageCounter + 1);
            }
        }, 1000);
    };

    /* Calculate free text hight */
    const calculateFreeTextHight = (text: string) => {
        const numberOfCharsPerLine = 36;
        const spacesHight = 48 //paddings from top and bottom (16*2) and one margin (16*1)
        const textLineHeight = 26;
        let textHeight: number = Math.ceil(text.length / numberOfCharsPerLine);
        return (textHeight * textLineHeight + spacesHight) / 16; //to make it em
    }

    return <>
        <ChatShellComponent>
            <div className={`position-relative  ${generalChatAnswersStyles.height400}`}>
            <div className={`${generalChatAnswersStyles.warningHeight} ${ok === 'failed' && "alert alert-warning"} position-absolute top-0 w-100`} role="alert">
                {ok === 'failed' && <TextComponent text={Errors.SomethingWentWrong} />}
            </div>
            <div className={`position-absolute w-100 overflow-y-auto ${generalChatAnswersStyles.marginTopShellMain}`}>
                <div id='inner-window'
                    className={`min-height-540 d-flex justify-content-end flex-column`}

                >
                    <div
                        className={`position-relative chat-screen-config context-quote chatFormAnswer d-none ${generalChatAnswersStyles.chatScreenConfigThinkIntro}`}
                    >
                        <div className="position-relative">
                            <div className={`position-absolute chatmessageShellConfig`}>
                                <ChatMessageShellComponent
                                    text={answer && answer.userResponse}
                                    icon={answer && answer.image}
                                    id={answer && answer.answerId}
                                    left={true} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="position-absolute bottom-0 w-100" id="shell-footer">
                {<InputGroupComponent
                    placeholder={'Answer'}
                    onValidationHandler={sendDataHandler} />}
            </div>
            </div>
        </ChatShellComponent>
    </>
}
export default GeneralChatAnswersComponent
