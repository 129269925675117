import constant from "../Utils/Config";
import {isTokenExpired,clearLocalStorageAndRedirect, getAuthToken} from "../Utils/Utils";

interface IquoteData {
    tedQuoteId: number;
    isLike: boolean
}

class Quotes {

    async saveQuote(quoteData: IquoteData) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Quote/SaveUserQuote`, {
                body: JSON.stringify(quoteData),
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    };


    async getLikedQuote(searchParam: string, pageNumber: number, pageSize: number) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            let param = "";
            if (searchParam !== "" && searchParam !== undefined) {
                param += "&searchText=" + searchParam
            }
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Quote/UserLikedQuotes?pageNumber=${pageNumber}&pageSize=${pageSize}${param}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    };

    async getQuoteLibraryList(searchParam: string, pageNumber: number, pageSize: number) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            let param = "";
            if (searchParam !== "" && searchParam !== undefined) {
                param += "&searchText=" + searchParam
            }
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Quote/GetQuoteLibraryList?pageNumber=${pageNumber}&pageSize=${pageSize}${param}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    };

    async saveConceptQuote(tedMessageId: number, userResponse: string) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Ted/TedUserResponse?tedMessageId=${tedMessageId}&userResponse=${userResponse}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    };
}
export default Quotes;