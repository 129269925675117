import React, { useEffect, useState, useContext } from 'react';
import { ThemeContext } from '../../../Contexts/ThemeContext';
import TextComponent from '../Text/TextComponent';
import tabsStyles from './TabsComponent.module.css';


export interface ITabsComponent {
    id: number;
    title: string;
    heading?: string;
    userModuleStatusId?: number;
    programStageId?: number;
    isActive: boolean;
}

interface ITabs {
    tabs: ITabsComponent[];
    customClass?: string;
    myTab?: string;
    onTabSelectedHandler?: (data: string) => void;
    shouldEnableMeetingTab?: boolean;
    //stageChanged?: boolean
}

const TabsComponent = (props: ITabs) => {
    const [tabs, setTabs] = useState(props.tabs);
    const [tabDark, setTabDark] = useState(props.tabs)
    const [shouldMeetingTabEnable,] = useState(props?.shouldEnableMeetingTab);
    const { theme } = useContext(ThemeContext);

    useEffect(() => {
        setTabs(props.tabs);
        setTabDark(props.tabs);
    }, [props.tabs]);

    useEffect(() => {
        checkForMeetingTab();
        //eslint-disable-next-line
    }, [shouldMeetingTabEnable]);

    const checkForMeetingTab = () => {
        if (shouldMeetingTabEnable) {
            iterateTabs("Meetings");

        }
    }


    const iterateTabs = (currentTab: string) => {
        let myTab: string = currentTab;
        //eslint-disable-next-line
        if (theme === "light") {
            tabs?.map(x => {
                let currentTab = document.getElementById(x.title);
                if (x.userModuleStatusId === 0) {
                    return currentTab?.classList.add("tab-disabled");
                }
                if (x.userModuleStatusId === 1 || x.userModuleStatusId === 2) {
                    if (myTab === x.title) {
                        return currentTab?.classList.add("tab-selected");
                    } else {
                        return currentTab?.classList.add("tab");
                    }
                }
                if (x.userModuleStatusId === undefined) {
                    if (myTab === x.title) {
                        return currentTab?.classList.add("tab-selected");
                    } else {
                        return currentTab?.classList.add("tab");
                    }
                }

            });
        }
        if (theme === "dark") {
            tabDark?.map(x => {
                let currentTab = document.getElementById(x.title)
                if (x.userModuleStatusId === 0) {
                    return currentTab?.classList.add("disableTab")
                }
                if (x.userModuleStatusId === 1 || x.userModuleStatusId === 2) {
                    if ((myTab === x.title)) {
                        return currentTab?.classList.add("tab-selected-dark");
                    }
                    else {
                        return currentTab?.classList.add("tabDark");
                    }
                }
                if (x.userModuleStatusId === undefined) {
                    if (myTab === x.title) {
                        return currentTab?.classList.add("tab-selected-dark");
                    } else {
                        return currentTab?.classList.add("tabDark");
                    }
                }
            })
        }
    };

    if (props.myTab) {
        Array.from(document.querySelectorAll('.tabs')).forEach((el) => {
            el.classList.remove('tab-selected');
            el.classList.remove('tab');
            el.classList.remove('tab-disabled');
            el.classList.remove("disableTab");
            el.classList.remove("tab-selected-dark");
            el.classList.remove("tabDark");
        });
        iterateTabs(props?.myTab)
    };

    const selectTab = (event: any) => {
        Array.from(document.querySelectorAll('.tabs')).forEach((el) => {
            el.classList.remove('tab-selected');
            el.classList.remove('tab');
            el.classList.remove('tab-disabled');
            el.classList.remove("disableTab")
            el.classList.remove("tab-selected-dark");
            el.classList.remove("tabDark");
        });
        iterateTabs(event.currentTarget.id);
        if (props.onTabSelectedHandler) props.onTabSelectedHandler(event.currentTarget.id);
    };

    return <>
        {theme === "light" ? (
            <div className="d-flex justify-content-start custom-overflow-tab">
                {tabs?.map((item, index) =>
                    <div
                        className={`${tabsStyles.tabItemConfig} ${props.customClass && tabsStyles[props.customClass]} 
                 ${(item.title === "Network" && !props.shouldEnableMeetingTab) && 'tab-selected'}
                  ${item.userModuleStatusId !== 0 && 'cursor-pointer'} border-bottom-EBEBEB tabs d-flex justify-content-center`
                        }
                        onClick={item.userModuleStatusId !== 0 ? selectTab : undefined}
                        id={item.title}
                        key={item.id.toString()}
                    >
                        {item.userModuleStatusId === 0 &&
                            <div className={`text-config text-color-2D5362 text-center`} data-url={item.title}
                                dangerouslySetInnerHTML={{ __html: item.title }}>

                            </div>}
                        {item.userModuleStatusId !== 0 &&
                            <div style={{ "width": "100px" }} className={"text-center"} data-url={item.title}
                                dangerouslySetInnerHTML={{ __html: item.title }}>

                            </div>}
                    </div>

                )}
                <div className={`border-bottom-EBEBEB w-100 ${tabsStyles?.lastTab}`} ></div>
            </div>
        ) :
            <div className="d-flex justify-content-start custom-overflow-tab">
                {tabDark?.map((item, index) => <div
                    style={{ borderBottom: "2px solid #2B404C" }}
                    className={`${tabsStyles.tabItemConfig} ${props.customClass && tabsStyles[props.customClass]} 
                 ${(item.title === "Network" && !props?.shouldEnableMeetingTab) && 'tab-selected-dark'}
                  ${item.userModuleStatusId !== 0 && 'cursor-pointer'} border-bottom-EBEBEB tabs d-flex justify-content-center`}
                    onClick={item.userModuleStatusId !== 0 ? selectTab : undefined}
                    id={item.title}
                    key={item.id.toString()}
                >
                    {item.userModuleStatusId === 0 &&
                        <div className={`text-config text-color-2D5362 text-center`} data-url={item.title}
                            dangerouslySetInnerHTML={{ __html: item.title }}>

                        </div>}
                    {item.userModuleStatusId !== 0 &&
                        <div style={{ "width": "100px" }} className={"text-center"} data-url={item.title}
                            dangerouslySetInnerHTML={{ __html: item.title }}>

                        </div>}
                </div>)}
                <div style={{ borderBottom: "2px solid #2B404C" }} className={`border-bottom-EBEBEB w-100 ${tabsStyles?.lastTab}`} ></div>
            </div>
        }
    </>
}

export default TabsComponent;