import React from "react";
import { Form } from "react-bootstrap";
import { IOpenSurveQuestion, IOpenSurveyAnswer, IQuestionOption } from "../../../interfaces/OpenSurvey/IOpenSurvey";

interface IProps {
    handleTextAreaChange?: any;
    handleMultipleClick?: any;
    handleRadioClick?: any;
    currentIndex: number;
    userAnswers:IOpenSurveyAnswer[],
    question:IOpenSurveQuestion
}
const OpenSurveyForm = (props:IProps) => {
    const {
        handleTextAreaChange,
        handleMultipleClick,
        handleRadioClick,
        currentIndex,
        question,
        userAnswers
    } = props
    

    return(
        <Form.Group
        className="mb-3 mt-3"
        controlId="formPlaintextEmail"
        >
            <Form.Label column>
                    {(currentIndex + 1) + "."} {question?.question}
            </Form.Label>
            {
                question.options.length > 0 && userAnswers ? 
                    <>
                        {
                            question.options.map((answer: IQuestionOption, index: number) => {
                                return <Form.Group
                                           key={index}
                                           className="mb-3 mx-4 mt-3"
                                           controlId={'formControl'}
                                        >
                                            {
                                                question.questionType.id === 3 ?
                                                    <Form.Check 
                                                        type="checkbox"
                                                        key={index}
                                                        id={answer.id.toString()}
                                                        name={`group${currentIndex}`}
                                                        label={answer.option}
                                                        value={answer.id}
                                                        checked={userAnswers.find(x => x.questionId == question.id)?.optionIds.includes(answer.id)}
                                                        onChange={(e: any) => handleMultipleClick(answer.id,question.id)}
                                                    /> 
                                                 : <Form.Check 
                                                        type="radio"
                                                        key={index}
                                                        id={answer.id.toString()}
                                                        label={answer.option}
                                                        value={answer.id}
                                                        checked={userAnswers.find(x => x.questionId == question.id)?.optionIds.includes(answer.id)}
                                                        onChange={(e: any) => handleRadioClick(answer.id,question.id)}
                                                    />
                                                        
                                            }
                                       </Form.Group>
                            })
                        }
                    </> 
                : 
                    <Form.Group className="mb-3 mx-4 mt-3" controlId="Textarea">
                        <Form.Control
                            as="textarea"                            
                            rows={6}
                            value={userAnswers.find(x => x.questionId == question.id)?.freeTextAnswer}
                            onChange={(e) => handleTextAreaChange(e.target.value,question.id)}
                            style={{ height: '100px' }}
                        />
                    </Form.Group>
            }                 
        </Form.Group>
        
    );
}

export default OpenSurveyForm