import React, { useContext, useEffect, useReducer } from 'react';
import HeaderComponent from '../../FunctionalComponents/Header/HeaderComponent';
import { Errors, Headers } from '../../../Utils/Enums';
import ContentShellComponent from '../../FunctionalComponents/ContentShell/ContentShellComponent';
import TextComponent from '../../FunctionalComponents/Text/TextComponent';
import LoaderComponent from '../../FunctionalComponents/Loader/LoaderComponent';
import conceptStyles from './ConceptSummaryComponent.module.css';
import ConceptSummary from '../../../Services/ConceptSummary.service';
import GeneralChatMessagesComponent from '../../FunctionalComponents/GeneralChatMessages/GeneralChatMessagesComponent';
import { Link, useHistory } from 'react-router-dom';
import VideoPlayerComponent from '../../FunctionalComponents/VideoPlayer/VideoPlayerComponent';
import General from '../../../Services/General.service';
import TEDModalComponent from '../../FunctionalComponents/TEDModal/TEDModalComponent';
import { isMobileOnly } from 'react-device-detect';
import { logOutIfTokenExpire } from '../../../Utils/Utils';
import { UserContext } from '../../../Contexts/UserContext';
import { ThemeContext } from '../../../Contexts/ThemeContext';

const okReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'SetOKSuccess':
            return action.okSuccsess;
        case 'SetOKFailure':
            return action.okFailure;
        default:
            return { value: "loading", dataModule: null }
    }
}
interface Resource {
    id: number;
    moduleId: number;
    name: string;
    description: string;
    link: string;
    fileName: string;
    originalFileName: string;
    type: string;
    savedToPlaylist: boolean;
    isExternalResource?: boolean;
    resourceContentId?: number;
}
interface IResult {
    ok: boolean;
    data: {
        moduleId: number;
        summary: string;
        resources: Resource[]
    }
};

const ConceptSummaryComponent = (props: any) => {
    let history = useHistory();
    const generalService = new General();
    generalService.getIcons();
    const handleRedirection = () => {
        window.history.back();
    }
    let result: IResult;
    // const conceptId = props.match.params.id;
    //eslint-disable-next-line
    const moduleStepId = props.match.params.moduleStepId;
    //eslint-disable-next-line
    const moduleId = props.match.params.moduleId;

    const conceptSummaryService = new ConceptSummary();
    const [okState, dispatchOK] = useReducer(okReducer, { value: "loading", dataModule: null });
    let userScore = okState?.data?.data?.isScoreGiven
    const saveToPlaylistHandler = async (event: any) => {
        let selectedHeart = document.getElementById(event.target.id) as HTMLImageElement;
        if (theme === "light") {
            if (selectedHeart.src === window.location.origin + '/images/favourite-empty.svg') {
                await conceptSummaryService.resourceUserResponse(Number(event.currentTarget.id.split('-')[1]), 'like');
                selectedHeart.src = window.location.origin + '/images/favourite-filled.svg';
            } else {
                await conceptSummaryService.resourceUserResponse(Number(event.currentTarget.id.split('-')[1]), '');
                selectedHeart.src = window.location.origin + '/images/favourite-empty.svg';
            }
        } else {
            if (selectedHeart.src === window.location.origin + '/images/favourite-empty-dark.svg') {
                await conceptSummaryService.resourceUserResponse(Number(event.currentTarget.id.split('-')[1]), 'like');
                selectedHeart.src = window.location.origin + '/images/favourite-filled.svg';
            } else {
                await conceptSummaryService.resourceUserResponse(Number(event.currentTarget.id.split('-')[1]), '');
                selectedHeart.src = window.location.origin + '/images/favourite-empty-dark.svg';
            }
        }

    }
    const userContext = useContext(UserContext);
    const { theme } = useContext(ThemeContext);
    useEffect(() => {
        logOutIfTokenExpire();
        userContext.dispatch('');
        getData();
        //eslint-disable-next-line
    }, []);

    const getData = async () => {
        result = await conceptSummaryService.getModuleSummaryWithResource(moduleId);
        if (!result) {
            return dispatchOK({ type: 'SetOKFailure', okFailure: { value: 'failed' } })
        };
        dispatchOK({ type: 'SetOKSuccess', okSuccsess: { value: 'success', data: result } });
        const conceptSummary = document.getElementById("conceptSummary");
        if (conceptSummary) conceptSummary.innerHTML = result.data.summary;
    }
    return <>
        {okState.value === 'loading' && <LoaderComponent />}
        <div>
            {okState.value === 'failed' &&
                <div className="mb-4">
                    <div className="alert alert-warning" role="alert">
                        <TextComponent text={Errors.SomethingWentWrong} />
                    </div>
                </div>}
        </div>
        {okState.value === 'success' && <div className="concept-component">
            <div className='d-flex justify-content-between'>
                <span onClick={() => handleRedirection()} className="text-decoration-none cursor-pointer">
                    {theme === "light" ? (
                        <HeaderComponent
                            title={okState.data.data.moduleHeading}
                            size={Headers.H1}
                            icon={'chevron-left.svg'}
                        />
                    ) : <HeaderComponent
                        customClassName="imageText"
                        title={okState.data.data.moduleHeading}
                        size={Headers.H1}
                        icon={'left-chevron-dark.svg'}
                    />}
                </span>
            </div>
            <div className="margin-bottom-78">
                <ContentShellComponent>
                    <div className={`d-xl-inline-flex justify-content-between ${conceptStyles.contentShellLeftConfig}`}>
                        <ContentShellComponent customClass={'leftSideConfig'} >
                            <div className="margin-bottom-32">
                                <TextComponent text={okState.data.data.shortDescription} />
                            </div>
                            <div className="margin-bottom-24 border-bottom-EBEBEB-thin">
                                <HeaderComponent
                                    title={"MODULE SUMMARY"}
                                    size={Headers.H3}
                                />
                            </div>
                            <div className="margin-bottom-40" id='conceptSummary'></div>
                            <div>
                                <HeaderComponent
                                    title={"Optional Additional Resources"}
                                    size={Headers.H3}
                                />
                            </div>
                            {okState.data.data.resources && <div>
                                {okState.data.data.resources.map((x: Resource, index: number) =>
                                    <div className="d-flex margin-top-24 border-bottom-EBEBEB-thin" key={index}>
                                        <div className="margin-right-20 margin-left-20"><img src={window.location.origin + '/images/' + x.type + '.svg'} alt="check" /></div>
                                        <div className='d-flex flex-column'>
                                            <div>
                                                <HeaderComponent
                                                    title={x.name}
                                                    size={Headers.H3}
                                                />
                                            </div>
                                            <div className="margin-bottom-19">
                                                <TextComponent text={x.description} />
                                            </div>
                                            {x.type === 'Webpage' &&
                                                <div className="d-flex justify-content-between margin-bottom-8">
                                                    {!x.isExternalResource && <Link className="cursor-pointer page-link" to={"/content/" + x.resourceContentId} target="_blank">
                                                        <div>More</div>
                                                    </Link>}
                                                    {x.isExternalResource && <a href={x.link} target="_blank" className="cursor-pointer page-link" rel="noreferrer">
                                                        More
                                                    </a>}
                                                    <div className="cursor-pointer">
                                                        {theme === "light" ? (
                                                            <img
                                                                src={window.location.origin + '/images/favourite-empty.svg'}
                                                                alt="favourite-empty" id={"empty-" + x.id}
                                                                onClick={saveToPlaylistHandler} />
                                                        ) : <img
                                                            src={window.location.origin + '/images/favourite-empty-dark.svg'}
                                                            alt="favourite-empty" id={"empty-" + x.id}
                                                            onClick={saveToPlaylistHandler} />}
                                                    </div>
                                                </div>}
                                            {x.type === 'Image' &&
                                                <div className="margin-bottom-16">
                                                    <img src={x.fileName} alt="file" />
                                                </div>}

                                            {x.type === 'Video' &&
                                                <div>
                                                    <VideoPlayerComponent
                                                        playing={false}
                                                        local={true}
                                                        source={x.fileName}
                                                    />
                                                </div>
                                            }
                                            {x.type === 'Audio' &&
                                                <div>
                                                    <VideoPlayerComponent
                                                        playing={false}
                                                        local={true}
                                                        source={x.fileName}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>)}
                            </div>}

                        </ContentShellComponent>
                        <ContentShellComponent customClass={'rightSideConfig'}>
                            <GeneralChatMessagesComponent
                                source={'summary'}
                                moduleId={okState.data.data.moduleId}
                                buttonTitle={!userScore ? 'Finish' : 'Completed'}
                                buttonClass={'w-100'}
                                messageLength={isMobileOnly ? 19 : 36}></GeneralChatMessagesComponent>
                        </ContentShellComponent>
                    </div>
                </ContentShellComponent>
            </div>
        </div>
        }
        <div>
            <TEDModalComponent />
        </div>
    </>
}
export default ConceptSummaryComponent;