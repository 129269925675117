import React, { useRef, useContext } from 'react';
import CircleImageComponent from '../CircleImage/CircleImageComponent';
import HeaderComponent from '../Header/HeaderComponent';
import stylesHorizontalShell from './HorizontalShellComponent.module.css'
import { Headers, IsInMyContactResponeEnum } from '../../../Utils/Enums';
import EmailLinkComponent from '../EmailLink/EmailLinkComponent';
import SubmitButtonComponent from '../SubmitButton/SubmitButtonComponent';
import TextComponent from '../Text/TextComponent';
import Profile from '../../../Services/Profile.service';
import { useHistory } from 'react-router';
import { Button } from 'react-bootstrap';
import { Types, UserContext, UserContextAction } from '../../../Contexts/UserContext';
import { ThemeContext } from "../../../Contexts/ThemeContext"
import "../../../darkcustom.css"

export interface IHorizontalShellComponent {
    firstName?: string;
    lastName?: string;
    email?: string;
    picture: string;
    currentUser: boolean;
    onValidationHandler: (isImageOK: boolean) => void;
    onSuccessUpload: () => void;
    addToNetwork: () => Promise<void>;
    userInContact: number;
}
const HorizontalShellComponent = (props: IHorizontalShellComponent) => {

    let profile = new Profile();
    let history = useHistory();
    const inputFile = useRef<HTMLInputElement | null>(null);
    const userContext = useContext(UserContext);
    const { theme } = useContext(ThemeContext)

    const onButtonClick = () => {
        inputFile.current?.click();
    }

    const onImageSelect = async (e: any) => {
        let files = e.target.files
        if (!files) return;
        if (files[0]['type'].split('/')[0] !== 'image') {
            props.onValidationHandler(false);
        } else {
            props.onValidationHandler(true);
            const uploadImage = await profile.uploadPicture(files[0]);
            if (uploadImage.ok) {
                let updateProfileImage: UserContextAction = {
                    type: Types.UPDATE_PROFILE_IMG,
                    value: uploadImage.data.imgUrl.replace('/api', '')
                };
                userContext.dispatch(updateProfileImage);
                props.onSuccessUpload();
            }
        }
        e.target.value = null;
    }

    const handleAddToNetwork = () => {
        if (!props.currentUser) {
            props.addToNetwork();
        }
    }

    return <><div className="d-flex">
        {theme === "light" ? (
            <div className={`d-flex justify-content-center background-EFF8F7 w-100 align-items-center ${stylesHorizontalShell['horizontalShellConfig']}`}>
                <div className="position-relative">
                    <CircleImageComponent
                        image={props.currentUser ? `${userContext?.state.currentUser.profileImage ? userContext.state.currentUser.profileImage : null}` :
                            `${props.picture ? props?.picture?.slice(4) : null}`}
                        size={'imageSize152'}
                        screenType={'profile'}
                    />
                    <input type='file' accept="image/*" id='profileFile' ref={inputFile} style={{ display: 'none' }} onChange={(e) => onImageSelect(e)} />
                    {props.currentUser && <img
                        src={window.location.origin + '/images/camera.svg'}
                        onClick={onButtonClick}
                        className={`position-absolute cursor-pointer ${stylesHorizontalShell['cameraImageConfig']}`}
                        alt="camera"
                    />
                    }
                    <div className="margin-top-8 text-transform-uppercase d-flex justify-content-center">
                        <HeaderComponent
                            title={`${props.firstName} ${props.lastName}`}
                            size={Headers.H3}
                        />

                    </div>
                    <div className="d-flex justify-content-center">
                        {props.email && <EmailLinkComponent mail={props.email} title={props.email} />}
                        {!props.email && <TextComponent text={'No data available'} />}
                    </div>
                    {
                        !props.currentUser && <div className='row'>
                            <div className='col-sm-12 text-center'>
                                {
                                    props.userInContact == IsInMyContactResponeEnum.USER_NOT_IN_CONTACT &&
                                    <Button variant="primary"
                                        className="font-weight-600 text-color-182C34"
                                        type="button" onClick={handleAddToNetwork}>
                                        Add to my network
                         </Button>
                                }
                                {
                                    props.userInContact == IsInMyContactResponeEnum.USER_IN_CONTACT && <Button
                                        variant="secondary"
                                        className="text-secondary"
                                        disabled={true}
                                        type="button"
                                    >
                                        Already in network
                            </Button>
                                }
                                {
                                    props.userInContact > IsInMyContactResponeEnum.USER_IN_CONTACT && <Button
                                        variant="secondary"
                                        className="text-secondary"
                                        disabled={true}
                                        type="button"
                                    >
                                        Contact limit reached
                                </Button>
                                }
                            </div>
                        </div>
                    }
                    {
                        props.currentUser ?
                            <div className="row">
                                <div className="col-sm-12 text-center">
                                    <Button variant="primary"
                                        className="font-weight-600 text-color-182C34 mx-2"
                                        type="button"
                                        onClick={() => history.push({ pathname: "/account", state: { isFromProfile: true } })}>
                                        Edit Profile
                                </Button>
                                    <Button variant="primary"
                                        className="font-weight-600 text-color-182C34"
                                        type="button" onClick={() => history.push({ pathname: "/userscore", state: { isFromProfile: true } })}>
                                        My Scores
                                </Button>
                                </div>
                            </div> : null
                    }
                </div>
            </div>
        ) : <div className={`d-flex justify-content-center horizontalShellCard w-100 align-items-center ${stylesHorizontalShell['horizontalShellConfig']}`}>
                <div className="position-relative">
                    <CircleImageComponent
                        image={props.currentUser ? `${userContext?.state.currentUser.profileImage ? userContext.state.currentUser.profileImage : null}` :
                            `${props.picture ? props?.picture?.slice(4) : null}`}
                        size={'imageSize152'}
                        screenType={'profile'}
                    />
                    <input type='file' accept="image/*" id='profileFile' ref={inputFile} style={{ display: 'none' }} onChange={(e) => onImageSelect(e)} />
                    {props.currentUser && <img
                        src={window.location.origin + '/images/camera.svg'}
                        onClick={onButtonClick}
                        className={`position-absolute cursor-pointer ${stylesHorizontalShell['cameraImageConfig']}`}
                        alt="camera"
                    />
                    }
                    <div className="margin-top-8 text-transform-uppercase d-flex justify-content-center">
                        <HeaderComponent
                            title={`${props.firstName} ${props.lastName}`}
                            size={Headers.H3}
                        />

                    </div>
                    <div className="d-flex justify-content-center">
                        {props.email && <EmailLinkComponent mail={props.email} title={props.email} />}
                        {!props.email && <TextComponent text={'No data available'} />}
                    </div>
                    {
                        !props.currentUser && <div className='row'>
                            <div className='col-sm-12 text-center'>
                                {
                                    props.userInContact == IsInMyContactResponeEnum.USER_NOT_IN_CONTACT &&
                                    <Button variant="primary"
                                        className="font-weight-600 text-color-182C34"
                                        type="button" onClick={handleAddToNetwork}>
                                        Add to my network
                         </Button>
                                }
                                {
                                    props.userInContact == IsInMyContactResponeEnum.USER_IN_CONTACT && <Button
                                        variant="secondary"
                                        className="text-secondary"
                                        disabled={true}
                                        type="button"
                                    >
                                        Already in network
                            </Button>
                                }
                                {
                                    props.userInContact > IsInMyContactResponeEnum.USER_IN_CONTACT && <Button
                                        variant="secondary"
                                        className="text-secondary"
                                        disabled={true}
                                        type="button"
                                    >
                                        Contact limit reached
                                </Button>
                                }
                            </div>
                        </div>
                    }
                    {
                        props.currentUser ?
                            <div className="row">
                                <div className="col-sm-12 text-center">
                                    <Button variant="primary"
                                        className="font-weight-600 text-color-182C34 mx-2"
                                        type="button"
                                        onClick={() => history.push({ pathname: "/account", state: { isFromProfile: true } })}>
                                        Edit Profile
                                </Button>
                                    <Button variant="primary"
                                        className="font-weight-600 text-color-182C34"
                                        type="button" onClick={() => history.push({ pathname: "/userscore", state: { isFromProfile: true } })}>
                                        My Scores
                                </Button>
                                </div>
                            </div> : null
                    }
                </div>
            </div>}
    </div>
    </>
}
export default HorizontalShellComponent;