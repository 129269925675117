import React, { useContext } from 'react';
import stylesHeader from './HeaderComponent.module.css';
import { Headers } from '../../../Utils/Enums';
import TextComponent from '../Text/TextComponent';
import { ThemeContext } from "../../../Contexts/ThemeContext";
export interface IHeader {
    title: string;
    size: Headers;
    image?: string;
    prefix?: string;
    customClassName?: string;
    icon?: string;
    localImage?: boolean;
}

const HeaderComponent = (props: IHeader) => {
    let { theme } = useContext(ThemeContext);
    return <>
        <div className='d-flex'>
            {props.image && <div className={`${stylesHeader[props.size]} d-flex flex-column justify-content-center justify-content-md-end me-1`}>
                {!props.localImage && <img src={props.image} alt={props.image} />}
                {props.localImage && <img src={window.location.origin + '/images/' + props.image} alt={props.image} />}
            </div>}
            {props.icon && <div className={`${stylesHeader[props.size]} d-flex flex        -column justify-content-center justify-content-md-end me-1 position-relative ${stylesHeader['iconShellConfig']}`}>
                <img src={window.location.origin + '/images/' + props.icon} className="position-absolute top-0 start-0" alt={props.icon} />
            </div>}
            <div className='d-flex flex-column'>
                {props.prefix && <TextComponent text={props.prefix} customClassName={'margin-bottom-4'} />}
                {theme === "light" ? (
                    <div
                        className={`${stylesHeader.headerconfig} ${stylesHeader[props.size]} ${props.customClassName && stylesHeader[props.customClassName]}`}
                    >{props.title}
                    </div>
                ) : <div
                    className={`${stylesHeader.darkHeaderconfig} ${stylesHeader[props.size]} ${props.customClassName && stylesHeader[props.customClassName]}`}
                >{props.title}
                    </div>
                }

            </div>
        </div>

    </>
}

export default HeaderComponent;