import React from 'react';
import { IContact } from '../../../Services/Engage.service';
import ContactItemComponent from '../ContactItem/ContactItemComponent';
import PaginationComponent, { IPaginationComponent } from '../Paginator/PaginationComponent';
import { IRole } from '../Popover/PopoverComponent';
import './ContactsListComponent.css';

export interface IContactsData {
    dataList: IContact[];
    filteredList: IContact[];
    pagination: IPaginationComponent;
};
interface IContactsListComponent {
    contactsDataStructure: IContactsData;
    onAddScorerHandler: (data: IRole) => void;
    onDeleteExternalHandler: (data: string) => void;
    onDeleteContactHandler: (data: string) => void;
    onContactsHandler: (data: number) => void;
    onPageHandler: (data: number) => void;
    search?: string;
    numOfScorers: number;
}
const ContactsListComponent = (props: IContactsListComponent) => {

    const onCallbackScorer = (data: IRole) => {
        props.onAddScorerHandler(data);
    }
    const onCallbackDeleteExternal = (data: string) => {
        props.onDeleteExternalHandler(data)
    }
    const onCallbackDeleteContact = (data: string) => {
        props.onDeleteContactHandler(data)
    }
    const pageHandler = (data: number) => {
        props.onPageHandler(data)
    };

    return <>
        {props.contactsDataStructure.filteredList.length > 0 && props.contactsDataStructure.filteredList.map((item: any, index: any) =>
            <div className="" key={index}>
                <ContactItemComponent
                    item={item}
                    onAddScorerHandler={onCallbackScorer}
                    onDeleteExternalHandler={onCallbackDeleteExternal}
                    onDeleteContactHandler={onCallbackDeleteContact}
                    roles={item.roles}
                    search={props.search} 
                    numOfScorers={props.numOfScorers}/>
            </div>)}
        <div className="margin-top-16 d-flex justify-content-end">
            {props.contactsDataStructure.dataList.length > 10 && <PaginationComponent
                currentPage={props.contactsDataStructure.pagination.currentPage}
                pageSize={props.contactsDataStructure.pagination.pageSize}
                totalItems={props.contactsDataStructure.pagination.totalItems}
                totalPages={props.contactsDataStructure.pagination.totalPages}
                onPageHandler={pageHandler} />}
        </div>
    </>
}
export default ContactsListComponent;