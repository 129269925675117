import React from "react";
import { ExpanderComponentProps } from "react-data-table-component";
import TextComponent from "../Text/TextComponent";
import { Iprops, IresourceData } from "./LibraryTableComponent"
import { Link } from "react-router-dom";
import "../../../darkcustom.css"

interface Props extends ExpanderComponentProps<IresourceData> {
    // currently, props that extend ExpanderComponentProps must be set to optional.
    url?: string;
}
const ResourceLibraryTableExpandableRowComponent: (theme: string, url: string, addResource: any, props: Iprops) =>
    React.FC<Props> = (theme, url, addResource, props) => ({ data }) => {

        const addFavouriteResource = (row: any) => {
            if (addResource) {
                return addResource(Number(data.id));
            }
        }

        return (
            <>
                <div className="p-3 background-FAFAFA" >
                    <div><b>Resource</b></div>
                    <div className="mt-2 mb-1">
                        <div>
                            {data.name}
                        </div>
                    </div>
                    <div className="mt-2"><b>Module</b></div>
                    <div className="numbers">
                        {data.moduleName}
                    </div>
                    <div className="mt-2"><b>Link</b></div>
                    <div className="numbers">
                        <>
                            {data.type === null
                                ? "NA"
                                : data.type === "Webpage"
                                    ? (!data.isExternalResource ? <Link className="cursor-pointer text-decoration-none text-black" to={`/content/${data.resourceContentId}`} target="_blank">
                                        View More
                        </Link>
                                        : <Link className="cursor-pointer text-decoration-none text-black" to={{ pathname: `${data.link}` }} target="_blank">
                                            View More
                        </Link>)
                                    : data.type === "Image"
                                        ? <Link className="cursor-pointer text-decoration-none text-black" to={`api/file?filename=${data.fileName}`} target="_blank">
                                            View More
                            </Link>
                                        : data.type === "Video"
                                            ? <Link className="cursor-pointer text-decoration-none text-black" to={`api/file?filename=${data.fileName}`} target="_blank">
                                                View More
                                </Link>
                                            : data.type === "Audio" && <Link className="cursor-pointer text-decoration-none text-black" to={`api/file?filename=${data.fileName}`} target="_blank">
                                                View More
                                </Link>}
                        </>
                    </div>
                    <div className="mt-2"><b>Reading List</b></div>
                    <div className="numbers">
                        <>
                            {url === "/myresources" ? <> <div className="mx-2">
                                <img
                                    alt="favourite"
                                    src={window.location.origin + "/images/favourite-filled.svg"}
                                    width={"16px"}
                                    height={"16px"}
                                />
                                <TextComponent text="Added" customClassName="font-weight-600 added-text text-black d-inline-block ps-2 align-middle" />
                            </div>
                            </> : <>
                                    <div onClick={() => addFavouriteResource(data)}>
                                        <img
                                            alt="favourite"
                                            src={
                                                theme === "light" ? (
                                                    data.isAdded ?
                                                        window.location.origin + "/images/favourite-filled.svg"
                                                        : "/images/favourite-empty.svg"
                                                ) : data.isAdded ?
                                                        window.location.origin + "/images/favourite-filled.svg"
                                                        : "/images/favourite-empty-dark.svg"
                                            }
                                            width={"16px"}
                                            height={"16px"}
                                        />
                                        <div>
                                            <TextComponent text="Add" customClassName="font-weight-600 added-text text-black d-inline-block ps-2 align-middle" />
                                        </div>

                                    </div>
                                </>}
                        </>
                    </div>
                </div>
            </>
        );
    };


export default ResourceLibraryTableExpandableRowComponent;