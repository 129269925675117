import React, { useEffect, useState, useContext } from 'react';
import HeaderComponent from '../../FunctionalComponents/Header/HeaderComponent';
import { Headers } from '../../../Utils/Enums';
import TextComponent from '../../FunctionalComponents/Text/TextComponent';
import ContentShellComponent from '../../FunctionalComponents/ContentShell/ContentShellComponent';
import ChatShellComponent from '../../FunctionalComponents/ChatShell/ChatShellComponent';
import VideoPlayerComponent from '../../FunctionalComponents/VideoPlayer/VideoPlayerComponent';
import InputGroupComponent from '../../FunctionalComponents/InputGroup/InputGroupComponent';
import SubmitButtonComponent from '../../FunctionalComponents/SubmitButton/SubmitButtonComponent';
import ChatMessageShellComponent from '../../FunctionalComponents/ChatMessageShell/ChatMessageShellComponent';
import CollapseComponent from '../../FunctionalComponents/Collapse/CollapseComponent';
import thinkIntroStyles from './ThinkIntroComponent.module.css';
import { Link, useHistory } from 'react-router-dom';
import Dashboard from '../../../Services/Dashboard.service';
import { createNotification } from '../../../Utils/Toast';
import Quotes from '../../../Services/Quotes.service';
import General from '../../../Services/General.service';
import { logOutIfTokenExpire } from '../../../Utils/Utils';
import { ThemeContext } from '../../../Contexts/ThemeContext';

interface IquoteData {
    tedQuoteId: number;
    isLike: boolean
}

interface IQuoteData {
    author: string;
    tedQuoteId: number;
    tedQuoteName: string;
    text: string;
}

const ThinkIntroComponent = (props: any) => {
    let history = useHistory();
    const generalService = new General();
    const dashboard = new Dashboard();
    const quote = new Quotes();
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [quoteDataInfo, setQuoteDataInfo] = useState<IQuoteData>({ author: "", tedQuoteId: 0, tedQuoteName: "", text: "" });
    const { theme } = useContext(ThemeContext);

    useEffect(() => {
        logOutIfTokenExpire();
        getQuote();
        let iconsPanel = document.getElementById('icons-panel');
        iconsPanel!!.classList.add("d-none");
        //eslint-disable-next-line
    }, []);

    const getQuote = async () => {
        if (window.location.pathname.slice(1) === "thinkintro") {
            let response: any = await dashboard.getQuote("Think");
            if (response.ok) {
                setQuoteDataInfo({ author: response.data.author, tedQuoteId: response.data.id, tedQuoteName: response.data.tedQuoteType.name, text: response.data.text })
            }
        }
    }
    const enableButton = () => {
        setIsButtonDisabled(false);
    }

    const likeQuote = async (id: number, userResponse: string, messageType: string) => {
        let quoteData: IquoteData = {
            tedQuoteId: id,
            isLike: true
        }
        let response: any = await quote.saveQuote(quoteData);
        if (response.status) {
            createNotification("success", response.message);
        } else {
            createNotification("error", response.message || "Something went wrong");
        }
    }

    return <>
        <div className='ThinkIntroComponent'>
            <div className='d-flex justify-content-between'>
                <Link to={"/programoverview"} className="text-decoration-none">
                    {theme === "light" ? (
                        <HeaderComponent
                            title={"Introducing Meaning Making"}
                            size={Headers.H1}
                            prefix={"Introduction: Think"}
                            localImage={true}
                            icon={'chevron-left.svg'}
                        />
                    ) : <HeaderComponent
                        customClassName="imageText"
                        title={"Introducing Meaning Making"}
                        size={Headers.H1}
                        prefix={"Introduction: Think"}
                        localImage={true}
                        icon={'left-chevron-dark.svg'}
                    />}
                </Link>
            </div>
            <TextComponent text={"How we experience reality is largely determined by what goes on inside our minds."} />
            <div className="margin-top-24 margin-bottom-78">
                <ContentShellComponent>
                    <div className={`d-xl-inline-flex justify-content-between ${thinkIntroStyles.contentShellLeftConfig}`}>
                        <ContentShellComponent customClass={'leftSideConfig'}>
                            <div className="margin-bottom-24">
                                <VideoPlayerComponent
                                    local={true}
                                    source={window.location.origin + "/videos/01-Meaning Making Day Sound.mp4"}
                                    playing={isButtonDisabled}
                                    onEndednHandler={enableButton} /></div>

                            <div className={`text-transform-uppercase margin-bottom-8 ${thinkIntroStyles.transcriptConfig}`}>
                                <HeaderComponent
                                    title={'Transcript'}
                                    size={Headers.H3}
                                />
                            </div>
                            <div className="margin-bottom-24">
                                <CollapseComponent text={`<p>The quality of our experiences is directly influenced by how we shape our sense of reality and share these experiences with others. </p>
                                                       <p>Imagine 3 people in a meeting.  Mia, John and their leader. </p> 
                                                       <p>Mia suggests an idea and her leader responds by pausing and saying ‘interesting’. Now Mia interprets the response as hesitation and concludes that her leader is avoiding saying he didn’t like the idea.  Mia decides the relationship will be better moving forwards if she keeps her ideas to herself.  Her face flushes and she retreats into her seat, arms folded.</p> 
                                                       <p>John also focuses on the leader’s response.  But he interprets it as thoughtful consideration and concludes that the leader is going to preference Mia’s idea over his own.  John believes he is undervalued.  He also retreats into his seat, arms folded.  ...And after the meeting he heads off to look online for another role.</p>
                                                       <p>One word.  2 very different interpretations.</p>
                                                       <p>We are meaning makers.And Meaning matters because consciously or unconsciously, the meanings we make influence the actions we take.</p>
                                                       <p>Yet relatively few of us try to understand our meaning making and even fewer of us have explored the possibility of changing it.</p>
                                                       <p>Welcome to MindNavigator!</p>`} customClassName={'margin-top-8'} onConfirm={enableButton} />
                            </div>
                        </ContentShellComponent>


                        <ContentShellComponent customClass={'rightSideConfig'}>
                            <ChatShellComponent>
                                <div className={`h-100 position-relative chat-screen-config ${thinkIntroStyles.chatScreenConfigThinkIntro}`}>
                                    <div className={`chatmessageShellConfig position-absolute`}>
                                        <ChatMessageShellComponent
                                            id={quoteDataInfo.tedQuoteId}
                                            text={quoteDataInfo.text}
                                            author={quoteDataInfo.author}
                                            badge={theme === "light" ? 'Heart.png' : 'favourite-empty-dark.svg'}
                                            icon={'Ted.svg'}
                                            onTedShellHeartResponseHandler={likeQuote}
                                            show={isButtonDisabled}
                                        />
                                    </div>
                                </div>
                                <InputGroupComponent disabled={true} />
                            </ChatShellComponent>
                            <Link to={`/engage/-1`}>
                                <SubmitButtonComponent label={"Continue"} customClassName={'btn-primary-custom'} id={'thinkIntroSubmit'} disabled={isButtonDisabled} />
                            </Link>
                        </ContentShellComponent>
                    </div>
                </ContentShellComponent>
            </div>
        </div>
    </>
}

export default ThinkIntroComponent;