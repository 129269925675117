import constant from "../Utils/Config";
import {isTokenExpired,clearLocalStorageAndRedirect, getAuthToken} from "../Utils/Utils";
class ResourceLibrary {

    async getResourceLibraryList(pageNumber: number, pageSize: number, searchParam: string) {
        let param = "";
        if (searchParam !== "" && searchParam !== undefined) {
            param += "&searchText=" + searchParam
        }
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Resource/GetAllResourceForUser?pageNumber=${pageNumber}&pageSize=${pageSize}${param}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    };

    async getUserResourceList(pageNumber: number, pageSize: number, searchParam: string) {
        let param = "";
        if (searchParam !== "" && searchParam !== undefined) {
            param += "&searchText=" + searchParam
        }
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Resource/GetUserResourceList?pageNumber=${pageNumber}&pageSize=${pageSize}${param}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    };

    async addResourceToFav(resourceId: number) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Resource/AddResource?resourceId=${resourceId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                }).catch((error: any) => {
                    return error;
                });
            return response;
        }        
    };
}

export default ResourceLibrary;