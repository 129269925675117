import {HubConnectionBuilder,HubConnection,HubConnectionState, LogLevel}  from '@microsoft/signalr';
import constant from './Config';
import { getUserId } from './Utils';

class Connector {
    public conection: HubConnection;
    private connectonId:string = '';
    private static _instance:Connector;
    constructor(){ 
        this.conection = new HubConnectionBuilder().withUrl(constant.notificationUserHub).withAutomaticReconnect().configureLogging(LogLevel.None).build();                  
    }
    
    static async startConnection() {        
        const userId = getUserId();
        if(userId){            
            let obj = this.getInstance();                     
            if(obj.conection){                 
              await obj.conection.start();  
              await obj.conection.invoke('GetConnectionId',userId).catch(err => console.log(err));   
            }
        }  
        else{
            console.log('userId not found');
        }                    
    }

    static isConnected():boolean {
        return this.getInstance().conection.state === HubConnectionState.Connected ? true : false;        
    }

    static currentConnectionStatus():HubConnectionState {
        return this.getInstance().conection.state;
    } 

    static async closeConnection() {
        this.getInstance().conection.off('sendToUser');
        await this.getInstance().conection.stop();
    }
    
    static getInstance(): Connector {
        if (!this._instance)
            this._instance = new this();
        return this._instance;
    }
}

export default Connector;