import { INotificationCount } from "../interfaces/notifications/INotification";
import constant from "../Utils/Config";
import {isTokenExpired,clearLocalStorageAndRedirect, getAuthToken} from "../Utils/Utils";

class Notification {

    async getNotification() {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Notification`, {
                method: 'GET',
                headers: {
                    'accept': 'text/plain',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                })
                .catch((error: any) => {
                    return error;
                })
            return response;
        }        
    }

    async deleteNotification(deleteNotifId: number) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Notification?id=${deleteNotifId}`, {
                method: 'DELETE',
                headers: {
                    'accept': '*/*',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                })
                .catch((error: any) => {
                    return error;
                })
            return response;
        }        
    }

    async unreadNotification() {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Notification/unread`, {
                method: 'GET',
                headers: {
                    'accept': 'text/plain',
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                })
                .catch((error: any) => {
                    return error;
                })
            return response;
        }        
    }

    async readNotification(readNotifId: any) {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Notification/read`, {
                method: 'POST',
                body: JSON.stringify(readNotifId),
                headers: {
                    'accept': 'text/plain',
                    "Content-Type": "application/json",
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                })
                .catch((error: any) => {
                    return error;
                })
            return response;
        }        
    }

    async getAllReadNotification() {
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Notification/getallread`, {
                method: 'GET',
                headers: {
                    'accept': 'text/plain',
                    "Content-Type": "application/json", 
                    "Authorization": getAuthToken()
                }
            }).then((response: any) => response.json())
                .then((response: any) => {
                    return response;
                })
                .catch((error: any) => {
                    return error;
                })
            return response;
        }        
    }

    async getUnReadNotificationCount(){
        if(isTokenExpired()){
            clearLocalStorageAndRedirect();
        }
        else{
            try{
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/Notification/unreadCount`, {
                    method: 'GET',
                    headers: {
                        'accept': 'text/plain',
                        "Content-Type": "application/json", 
                        "Authorization": getAuthToken()
                    }
                });
                if(!response.ok){
                    return {data:null,message:"Some thing is wrong",status:false}
                }
                else{
                    const result = await response.json();
                    if(result){
                        return result as INotificationCount;
                    }
                    else{
                        return {data:null,message:"Some thing is wrong",status:false}
                    }
                }
            }
            catch(error){
                return {data:null,message:"Some thing is wrong",status:false}
            }            
        }
    }
}
export default Notification;